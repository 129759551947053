import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import EdubbaImgDefault from './../../../assets/utils/images/file_not_previewable.jpg';
import EdubbaLoader from '../../../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../../../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Alert,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from 'reactstrap';
import {
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageUploadManager from './../../../services/imageUploadManager/ImageUploadManager';
import DatePicker from 'react-datepicker';
import $ from 'jquery';
import globalVars from './../../../globalVars.json';

// WS System
import WSSystem from './../../../services/webSocketSystem/webSocketSystem';
import {
    MetricOpCodes,
    MetricOBJGenerator
} from '../../../services/metricDataSkeletons/metricUserThirdParties';

import './ExtMaterialDetails.scss';

class ExtMaterialDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentMaterial: 0,
            materialLoading: false,
            viewAsEditor:    this.props.userCanOperate ? (
                this.props.setOpenAsViewer ? false : true
            ) : false,

            //---------------------------------------------------------------------| ENTITY

            p_material_content_owner:    null,
            p_material_creator:          null,
            p_material_event:            null,
            p_material_content_type:     null,
            p_material_content_info:     null,
            p_material_content_category: null,
            p_material_project:          null,
            p_material_file_type:        null,
            p_material_file_size:        null,
            p_material_location:         null,
            p_material_date:             null,
            p_material_flag:             null,
            p_media_uuid:                null,
            p_media_uuid_url:            null,
            p_ext_media_url:             null,

            //---------------------------------------------------------------------| UPLOAD

            modalUploadFile: false,

            //-------------------------------------------| CHECK METRIC LOADING

            isWSEnrichCard:         false,
            dispatchWSMetricEnable: false,
            dispatchWSEventEnable:  false
        };
    }

    //------------------------------------------------------------------------|
    //  METRIC LOGICS WS & LISTENERS
    //------------------------------------------------------------------------|

    // Translator mode
    GetWSMode() {
        // Get value
        return (
            this.props.userCanOperate
        ) ? MetricOpCodes.ActionMode.Editor :
        MetricOpCodes.ActionMode.Reader;
    }

    dispatchWSOpenMetric(_typeExtMaterial) {
        // Check for already loaded
        if (!this.state.dispatchWSMetricEnable) {
            // Get singleton instance of emitter
            const instanceWSEmitter = new WSSystem().getInstance();

            // Change state of component
            this.setState({
                dispatchWSMetricEnable:   true,
                dispatchWSMetricUniqueID: instanceWSEmitter.genWsMuid()
            },
            () => {
                // Get value
                const material_uuid = this.props.match.params.material_uuid;
                const metric_uid    = this.state.dispatchWSMetricUniqueID;

                // Get type send
                const opMetered = instanceWSEmitter.getDispatchType().Metered;

                // Send message
                instanceWSEmitter.dispatch(
                    metric_uid,
                    opMetered,
                    "ws-analyzer-stats",
                    MetricOBJGenerator(
                        material_uuid,
                        _typeExtMaterial,
                        this.GetWSMode(),
                        MetricOpCodes.EntityAction.Open
                    )
                );
            });
        }
    }

    dispatchWSCloseMetric(_typeExtMaterial) {
        // Check for failed load
        if (this.state.dispatchWSMetricEnable) {
            // Get singleton instance of emitter
            const instanceWSEmitter = new WSSystem().getInstance();

            // Get value
            const material_uuid = this.props.match.params.material_uuid;
            const metric_uid    = this.state.dispatchWSMetricUniqueID;

            // Get type send
            const opMetered = instanceWSEmitter.getDispatchType().Metered;

            // Send message
            instanceWSEmitter.dispatch(
                metric_uid,
                opMetered,
                "ws-analyzer-stats",
                MetricOBJGenerator(
                    material_uuid,
                    _typeExtMaterial,
                    this.GetWSMode(),
                    MetricOpCodes.EntityAction.Close
                )
            );
        }
    }

    dispatchWSListenerBeforeUnLoad(event) {
        // Block default
        event.preventDefault();

        // Force for some browser
        (event || window.event).returnValue = null;

        // Debug
        console.log("OnBeforeUnload: fired!");

        // Skip dialog from browser
        return null;
    }

    dispatchWSListenerCloseMetric(event) {
        // Block default
        event.preventDefault();

        // Debug
        console.log("OnUnload: fired!");

        // Delegate
        this.dispatchWSCloseMetric(
            MetricOpCodes.SourceType.Material
        );
    }

    dispatchWSAddListenerCloseMetric() {
        // Check for already loaded
        if (!this.state.dispatchWSEventEnable) {
            this.setState({
                dispatchWSEventEnable: true
            },
            () => {
                // Add listener to window
                /*$(window).on(
                    "beforeunload",
                    this.dispatchWSListenerBeforeUnLoad.bind(this)
                );*/

                // Add listener to window
                $(window).on(
                    "unload",
                    this.dispatchWSListenerCloseMetric.bind(this)
                );
            });
        }
    }

    dispatchWSDelListenerCloseMetric() {
        // Remove all trigger
        $(window).off("beforeunload");
        $(window).off("unload");
    }

    //------------------------------------------------------------------------|

    componentWillMount() {
        // Install metric analyzer
        this.dispatchWSAddListenerCloseMetric();
    }

    componentDidMount() {
        let setTitle = (
            this.props.setOpenAsViewer ?
            'Dashboard - Dettaglio Materiale per Terze-Parti' :
            'Dashboard - Creazione Nuovo Materiale per Terze-Parti'
        );
        this.props.setSectionTitle(setTitle);

        if (this.props.setOpenAsViewer) {
            this.loadDetail();
        }
    }

    componentWillUnmount() {
        // Uninstall metric analyzer
        this.dispatchWSDelListenerCloseMetric();
        this.dispatchWSCloseMetric(
            MetricOpCodes.SourceType.Material
        );
    }

    loadDetail() {
        let materialUUID = this.props.match.params.material_uuid;
        this.setState({
            materialLoading: true,
            // Save counter
            currentMaterial: 0,
            // Save data
            p_material_content_owner:    null,
            p_material_creator:          null,
            p_material_event:            null,
            p_material_content_type:     null,
            p_material_content_info:     null,
            p_material_content_category: null,
            p_material_project:          null,
            p_material_file_type:        null,
            p_material_file_size:        null,
            p_material_location:         null,
            p_material_date:             null,
            p_material_flag:             null,
            p_media_uuid:                null,
            p_media_uuid_url:            null,
            p_ext_media_url:             null,
            // Force modal closed
            modalUploadFile: false,
        }, () => {
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/third-parties/materials/" + materialUUID, {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                if (res.data && res.data.rowCount > 0) {
                    this.setState({
                        // Save counter
                        currentMaterial:          res.data.rowCount,
                        // Save data
                        p_material_content_owner:    res.data.rows[0]["material_content_owner"],
                        p_material_creator:          res.data.rows[0]["material_creator"],
                        p_material_event:            res.data.rows[0]["material_event"],
                        p_material_content_type:     res.data.rows[0]["material_content_type"],
                        p_material_content_info:     res.data.rows[0]["material_content_info"],
                        p_material_content_category: res.data.rows[0]["material_content_category"],
                        p_material_project:          res.data.rows[0]["material_project"],
                        p_material_file_type:        res.data.rows[0]["material_file_type"],
                        p_material_file_size:        res.data.rows[0]["material_file_size"],
                        p_material_location:         res.data.rows[0]["material_location"],
                        p_material_date:             res.data.rows[0]["material_date"],
                        p_material_flag:             res.data.rows[0]["material_flags"],
                        p_media_uuid:                res.data.rows[0]["material_file_uuid"],
                        p_media_uuid_url:            res.data.rows[0]["material_file_public_url"],
                        p_ext_media_url:             res.data.rows[0]["material_ext_media_url"],
                        // Save flag
                        materialLoading: false
                    },
                    () => {
                        // Delegate trigger
                        this.dispatchWSOpenMetric(
                            MetricOpCodes.SourceType.Material
                        );
                    });
                }
                else this.setState({
                    currentMaterial: 0,
                    materialLoading: false
                });
            }, err => {
                this.setState({
                    currentMaterial: 0,
                    materialLoading: false
                });
            });
        });
    }

    upsertMaterial(obj) {
        if (!this.props.userCanOperate) {
            return;
        }

        // Check mode
        if (this.props.setOpenAsViewer) this.updateMaterial(obj);
        else this.createMaterial(obj);
    }

    createMaterial(obj) {
        // Check mode
        if (this.props.setOpenAsViewer || !this.props.userCanOperate) return;
        else this.setState({ materialLoading: true });
        obj.disabled = true;

        // Prepare
        const backupData   = this.state;
        const materialBody = {
            material_content_owner:    this.emptyToNull(backupData.p_material_content_owner),
            material_creator:          this.emptyToNull(backupData.p_material_creator),
            material_event:            this.emptyToNull(backupData.p_material_event),
            material_content_type:     this.emptyToNull(backupData.p_material_content_type),
            material_content_info:     this.emptyToNull(backupData.p_material_content_info),
            material_content_category: this.emptyToNull(backupData.p_material_content_category),
            material_project:          this.emptyToNull(backupData.p_material_project),
            material_file_type:        this.emptyToNull(backupData.p_material_file_type),
            material_file_size:        this.emptyToNull(backupData.p_material_file_size),
            material_location:         this.emptyToNull(backupData.p_material_location),
            material_date:             this.emptyToNull(backupData.p_material_date),
            material_flags:            this.emptyToNull(backupData.p_material_flag),
            media_uuid:                this.emptyToNull(backupData.p_media_uuid),
            ext_media_url:             this.emptyToNull(backupData.p_ext_media_url)
        };

        // Call upsert
        axios.post(
            globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort +
            "/third-parties/materials/",
            materialBody,
            {
                auth: holdDataService.getAuthorization()
            }
        ).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.props.history.push("/third-parties/materials");
            }
            obj.disabled = false;
            this.setState({
                materialLoading: false
            });
        }, err => {
            obj.disabled = false;
            this.setState({
                materialLoading: false
            });
        });
    }

    updateMaterial(obj) {
        // Check mode
        if (!this.props.setOpenAsViewer || !this.props.userCanOperate) return;
        else this.setState({ materialLoading: true });
        obj.disabled = true;

        // Prepare
        const materialUUID = this.props.match.params.material_uuid;
        const backupData   = this.state;
        const materialBody = {
            material_content_owner:    this.emptyToNull(backupData.p_material_content_owner),
            material_creator:          this.emptyToNull(backupData.p_material_creator),
            material_event:            this.emptyToNull(backupData.p_material_event),
            material_content_type:     this.emptyToNull(backupData.p_material_content_type),
            material_content_info:     this.emptyToNull(backupData.p_material_content_info),
            material_content_category: this.emptyToNull(backupData.p_material_content_category),
            material_project:          this.emptyToNull(backupData.p_material_project),
            material_file_type:        this.emptyToNull(backupData.p_material_file_type),
            material_file_size:        this.emptyToNull(backupData.p_material_file_size),
            material_location:         this.emptyToNull(backupData.p_material_location),
            material_date:             this.emptyToNull(backupData.p_material_date),
            material_flags:            this.emptyToNull(backupData.p_material_flag),
            media_uuid:                this.emptyToNull(backupData.p_media_uuid),
            ext_media_url:             this.emptyToNull(backupData.p_ext_media_url)
        };

        // Call upsert
        axios.put(
            globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort +
            "/third-parties/materials/" + materialUUID,
            materialBody,
            {
                auth: holdDataService.getAuthorization()
            }
        ).then(res => {
            obj.disabled = false;
            this.setState({
                materialLoading: false
            },
            () => {
                this.props.history.push("/third-parties/materials/" + materialUUID);
            });
        }, err => {
            obj.disabled = false;
            this.setState({
                materialLoading: false
            });
        });
    }

    emptyToNull = (data) => {
        return (
            this.isEmpty(data) ?
            null : data
        );
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaImgDefault;
    }

    toggleEdit = (obj) => {
        this.setState({
            viewAsEditor: !this.state.viewAsEditor
        },
        () => {
            obj.innerHTML = (
                this.state.viewAsEditor ?
                "Salva Materiale" :
                "Modifica Materiale"
            );

            if (!this.state.viewAsEditor) {
                this.upsertMaterial(obj);
            }
        });
    }

    handleChangeCOwner = (material_content_owner) => {
        if (material_content_owner !== null) {
            this.setState({
                p_material_content_owner: material_content_owner
            });
        }
    }

    handleChangeCreator = (material_creator) => {
        if (material_creator !== null) {
            this.setState({
                p_material_creator: material_creator
            });
        }
    }

    handleChangeEvent = (material_event) => {
        if (material_event !== null) {
            this.setState({
                p_material_event: material_event
            });
        }
    }

    handleChangeCType = (material_content_type) => {
        if (material_content_type !== null) {
            this.setState({
                p_material_content_type: material_content_type
            });
        }
    }

    handleChangeCInfo = (material_content_info) => {
        if (material_content_info !== null) {
            this.setState({
                p_material_content_info: material_content_info
            });
        }
    }

    handleChangeProject = (material_project) => {
        if (material_project !== null) {
            this.setState({
                p_material_project: material_project
            });
        }
    }

    handleChangeFileType = (material_file_type) => {
        if (material_file_type !== null) {
            this.setState({
                p_material_file_type: material_file_type
            });
        }
    }

    handleChangeFileSize = (material_file_size) => {
        if (material_file_size !== null) {
            this.setState({
                p_material_file_size: material_file_size
            });
        }
    }

    handleChangeLocation = (material_location) => {
        if (material_location !== null) {
            this.setState({
                p_material_location: material_location
            });
        }
    }

    handleChangeCCategory = (material_category) => {
        if (material_category !== null) {
            this.setState({
                p_material_content_category: material_category
            });
        }
    }

    handleChangeDate = (material_date) => {
        if (material_date !== null) {
            this.setState({
                p_material_date: material_date.getTime()
            });
        }
    }

    handleChangeExtURL = (material_ext_url) => {
        if (material_ext_url !== null) {
            this.setState({
                p_ext_media_url: material_ext_url
            });
        }
    }

    toggleUploadFile = (value) => {
        this.setState({
            modalUploadFile: value
        });
    }

    setImageValueURL = (data) => {
        // Close modal
        this.setState({
            modalUploadFile:  false,
            p_media_uuid:     data.fileGuid,
            p_media_uuid_url: data.fileUrl
        });
    }

    clearMediaFile = () => {
        this.setState({
            modalUploadFile:  false,
            p_media_uuid_url: null,
            p_media_uuid:     null
        });
    }

    isEmpty(data) {
        if (data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    isFormValidate() {
        const backupData = this.state;
        if (!this.isEmpty(backupData.p_material_content_owner) &&
            !this.isEmpty(backupData.p_material_event)         &&
            !this.isEmpty(backupData.p_material_content_type)  &&
            !this.isEmpty(backupData.p_material_content_info)  &&
            !this.isEmpty(backupData.p_material_project)       &&
            !this.isEmpty(backupData.p_material_file_type)     &&
            !this.isEmpty(backupData.p_material_file_size)     &&
            !this.isEmpty(backupData.p_material_location)      &&
            (
                (
                    !this.isEmpty(backupData.p_media_uuid_url) &&
                    this.isValidURL(backupData.p_media_uuid_url)
                )
                ||
                this.isEmpty(backupData.p_media_uuid_url)
            )
            &&
            (
                (
                    !this.isEmpty(backupData.p_ext_media_url) &&
                    this.isValidURL(backupData.p_ext_media_url)
                )
                ||
                this.isEmpty(backupData.p_ext_media_url)
            )) {
            return true;
        }

        return false;
    }

    render() {
        // Render
        return (
            <Container className='MaterialDetail'>
                {
                    this.state.materialLoading ?
                        <EdubbaLoader />
                        : (
                            (!this.props.setOpenAsViewer) ||
                            (this.state.currentMaterial > 0 && this.props.setOpenAsViewer)
                        ) ?
                        <Fragment>
                            <Row key="body-stat-1">
                                <Col lg='12' md='12' sm='12'>
                                    <Form className="FormMaterial_Body">
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_ContentOwner">Detentore dei Diritti <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire un detentore dei diritti..."
                                                        value={
                                                            this.state.p_material_content_owner ?
                                                            this.state.p_material_content_owner :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_content_owner)}
                                                        invalid={this.isEmpty(this.state.p_material_content_owner)}
                                                        id="detailMaterial_ContentOwner"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeCOwner(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_Creator">Partner Creatore del Contenuto:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="[OPTIONAL] Inserire, se presente, un partner creatore..."
                                                        value={
                                                            this.state.p_material_creator ?
                                                            this.state.p_material_creator :
                                                            ""
                                                        }
                                                        valid={true}
                                                        id="detailMaterial_Creator"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeCreator(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form style={{marginTop: "30px"}}>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_Project">Progetto <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire un progetto..."
                                                        value={
                                                            this.state.p_material_project ?
                                                            this.state.p_material_project :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_project)}
                                                        invalid={this.isEmpty(this.state.p_material_project)}
                                                        id="detailMaterial_Project"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeProject(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_Event">Evento <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire un evento..."
                                                        value={
                                                            this.state.p_material_event ?
                                                            this.state.p_material_event :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_event)}
                                                        invalid={this.isEmpty(this.state.p_material_event)}
                                                        id="detailMaterial_Event"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeEvent(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form style={{marginTop: "30px"}}>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_Location">Luogo Evento <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire un luogo..."
                                                        value={
                                                            this.state.p_material_location ?
                                                            this.state.p_material_location :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_location)}
                                                        invalid={this.isEmpty(this.state.p_material_location)}
                                                        id="detailMaterial_Location"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeLocation(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_Date">Data Evento:</Label>
                                                    <DatePicker
                                                        placeholder="[OPTIONAL] Inserire una data..."
                                                        selected={this.state.p_material_date}
                                                        dateFormat={'dd/MM/yyyy'}
                                                        id="detailMaterial_Date"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={
                                                            "ExtMaterials-DatePicker " +
                                                            (!this.state.viewAsEditor ? "input-read-only " : "") +
                                                            "is-valid form-control"
                                                        }
                                                        onChange={this.handleChangeDate}
                                                    />
                                                    {
                                                        this.state.viewAsEditor &&
                                                        !this.isEmpty(this.state.p_material_date) ?
                                                        <Button
                                                        className="ExtMaterials-DatePicker-ClearBtn"
                                                            color="danger"
                                                            onClick={() => this.handleChangeDate(new Date(0))}>
                                                            Rimuovi Data
                                                        </Button> :
                                                        null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form style={{marginTop: "30px"}}>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_ContentCategory">Categoria Contenuto:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="[OPTIONAL] Inserire una categoria di contenuto..."
                                                        value={
                                                            this.state.p_material_content_category ?
                                                            this.state.p_material_content_category :
                                                            ""
                                                        }
                                                        valid={true}
                                                        id="detailMaterial_ContentCategory"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeCCategory(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_ContentType">Tipologia Contenuto <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire una tipologia di contenuto..."
                                                        value={
                                                            this.state.p_material_content_type ?
                                                            this.state.p_material_content_type :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_content_type)}
                                                        invalid={this.isEmpty(this.state.p_material_content_type)}
                                                        id="detailMaterial_ContentType"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeCType(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form style={{marginTop: "30px"}}>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_ContentInfo">Tema del Contenuto <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire un tema..."
                                                        value={
                                                            this.state.p_material_content_info ?
                                                            this.state.p_material_content_info :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_content_info)}
                                                        invalid={this.isEmpty(this.state.p_material_content_info)}
                                                        id="detailMaterial_ContentInfo"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeCInfo(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form style={{marginTop: "30px"}}>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_FileType">Tipologia del File <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire una tipologia di file..."
                                                        value={
                                                            this.state.p_material_file_type ?
                                                            this.state.p_material_file_type :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_file_type)}
                                                        invalid={this.isEmpty(this.state.p_material_file_type)}
                                                        id="detailMaterial_FileType"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeFileType(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="detailMaterial_FileSize">Dimensione/Durata del File <b>*</b>:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Inserire una durata/dimensione del file..."
                                                        value={
                                                            this.state.p_material_file_size ?
                                                            this.state.p_material_file_size :
                                                            ""
                                                        }
                                                        valid={!this.isEmpty(this.state.p_material_file_size)}
                                                        invalid={this.isEmpty(this.state.p_material_file_size)}
                                                        id="detailMaterial_FileSize"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeFileSize(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form style={{marginTop: "30px"}}>
                                            <Col lg='12' md='12' sm='12'>
                                                <FormGroup>
                                                    <Label for="detailMaterial_ExtURL">Allega un Media erogato esternamente alla Piattaforma:</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="[OPTIONAL] Inserire un indirizzo URL valido..."
                                                        value={
                                                            this.state.p_ext_media_url ?
                                                            this.state.p_ext_media_url :
                                                            ""
                                                        }
                                                        valid={
                                                            this.isEmpty(this.state.p_ext_media_url) ||
                                                            this.isValidURL(this.state.p_ext_media_url)
                                                        }
                                                        invalid={
                                                            !this.isEmpty(this.state.p_ext_media_url) &&
                                                            !this.isValidURL(this.state.p_ext_media_url)
                                                        }
                                                        id="detailMaterial_ExtURL"
                                                        readOnly={!this.state.viewAsEditor}
                                                        className={(!this.state.viewAsEditor ? "input-read-only" : "")}
                                                        onChange={(ev) => this.handleChangeExtURL(ev.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form style={{marginTop: "30px"}}>
                                            <Col lg='12' md='12' sm='12'>
                                                <FormGroup>
                                                    <div>
                                                        <Label for="detailMaterial_File">Allega un Media dalla propria Galleria di Piattaforma:</Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="[OPTIONAL] Selezionare/caricare un media dal proprio PC..."
                                                            value={
                                                                this.state.p_media_uuid_url ?
                                                                this.state.p_media_uuid_url :
                                                                ""
                                                            }
                                                            valid={
                                                                this.isEmpty(this.state.p_media_uuid_url) ||
                                                                this.isValidURL(this.state.p_media_uuid_url)
                                                            }
                                                            invalid={
                                                                !this.isEmpty(this.state.p_media_uuid_url) &&
                                                                !this.isValidURL(this.state.p_media_uuid_url)
                                                            }
                                                            id="detailMaterial_File"
                                                            readOnly={true}
                                                            className="input-read-only"
                                                        />
                                                        {
                                                            !this.isEmpty(this.state.p_media_uuid_url) ?
                                                            <div className="MaterialImgPreview">
                                                                <img src={
                                                                    this.isValidURL(
                                                                        this.state.p_media_uuid_url
                                                                    ) ? this.state.p_media_uuid_url
                                                                    : EdubbaImgDefault
                                                                }
                                                                onError={this.setDefaultImgSrc}
                                                                alt="" />
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.viewAsEditor ?
                                                        <Fragment>
                                                            <div style={{marginTop: "6px"}}>
                                                                <Button
                                                                    color="secondary"
                                                                    style={{width: "100%", borderRadius: "0.25rem"}}
                                                                    onClick={() => this.toggleUploadFile(true)}>
                                                                    Seleziona da Media Personali o Nuovo Upload...
                                                                </Button>
                                                            </div>
                                                            <div style={{marginTop: "6px"}}>
                                                                <Button
                                                                    color="danger"
                                                                    style={{width: "100%", borderRadius: "0.25rem"}}
                                                                    onClick={this.clearMediaFile}>
                                                                    Rimuovi Media selezionato dal Materiale
                                                                </Button>
                                                            </div>
                                                        </Fragment>
                                                        : null
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                            {
                                this.props.userCanOperate ?
                                    this.props.setOpenAsViewer ?
                                    <Row key="head-stat-1">
                                        <Col lg='12' md='12' sm='12'>
                                            <div className="FormMaterial_Btns">
                                                <Button
                                                    disabled={
                                                        this.state.viewAsEditor ?
                                                        !this.isFormValidate() :
                                                        false
                                                    }
                                                    onClick={(ev) => this.toggleEdit(ev.target)}>
                                                    Modifica Materiale
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row> :
                                    <Row key="foot-stat-1">
                                        <Col lg='12' md='12' sm='12'>
                                            <div className="FormMaterial_Btns">
                                                <Button
                                                    disabled={!this.isFormValidate()}
                                                    onClick={(ev) => this.upsertMaterial(ev.target)}>
                                                    Crea Materiale
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row> :
                                <Row key="foot-stat-1">
                                    <Col lg='12' md='12' sm='12'>
                                        <div className="FormMaterial_Btns Read-Only">
                                            Visualizzazione in sola lettura.
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {/* V MediaModal V */}
                            <Modal className='edubba-modal select-template-modal upload-file'
                                centered
                                size='lg'
                                aria-labelledby="contained-modal-title-vcenter"
                                isOpen={this.state.modalUploadFile}
                                onExit={() => this.toggleUploadFile(false)}
                                backdrop='static'>
                                <ModalHeader toggle={() => this.toggleUploadFile(false)}>
                                    <div className='widget-content p-0'>
                                        <div className='widget-content-wrapper'>
                                            <div className='widget-content-left mr-3'>
                                                <FontAwesomeIcon icon={faUpload} className='icon' />
                                            </div>
                                            <div className='widget-content-left mr-3 text-center w-100'>
                                                Upload File
                                            </div>
                                        </div>
                                    </div>
                                </ModalHeader>
                                <ModalBody>
                                    <Container className='help-text'>
                                        <Row>
                                            <Col lg='12' md='12' sm='12' className='inner-modal'>
                                                <ImageUploadManager selectCallback={this.setImageValueURL} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </ModalBody>
                            </Modal>
                        </Fragment>
                    : (
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <Alert color="light" className="mt-3">
                                    <h4 className="alert-heading">Nessun materiale trovato!</h4>
                                    <p>
                                        Attenzione il materiale potrebbe non essere disponibile per la visualizzazione.<br />
                                        Verificare che il materiale sia ancora presente e visibile per le terze-parti.
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        );
    }
};

export default withRouter(ExtMaterialDetails);
