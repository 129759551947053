import React, {Component} from 'react';
import ReactSVG from 'react-svg'
import logoEdubbaSVG from './../../assets/utils/images/edubba_squared_logo.svg';
import './EdubbaLoader.css';

export default class EdubbaLoader extends Component {

    render() {

        let small = this.props.small;
        return (
            <div className={`EdubbaLoader ${small ? 'small' : null}`}>
                <div className="edubba-loader-overlay">
                    <div className="edubba-loader-positioner">
                        <ReactSVG src={logoEdubbaSVG} />
                    </div>
                </div>
            </div>
        );
    }
}
