import React, { Component } from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import SocialRoomModalized from './../../SocialRooms/SocialRoomModalized';
import holdDataService from './../../services/holdDataService/holdDataService';
import globalVars from './../../globalVars.json';
import './NotificationTextHandler.scss';

export default class NotificationTextHandler extends Component {
    constructor(props) {
        super(props);
        // message: String

        this.state = {};
    }

    processString(options) {
        let key = 0;
        function processInputWithRegex(option, input) {
            if (!option.fn || typeof option.fn !== 'function')
                return input;
            if (!option.regex || !(option.regex instanceof RegExp))
                return input;
            if (typeof input === 'string') {
                let regex = option.regex;
                let result = null;
                let output = [];
                while ((result = regex.exec(input)) !== null) {
                    let index = result.index;
                    let match = result[0];
                    output.push(input.substring(0, index));
                    output.push(option.fn(++key, result));
                    input = input.substring(index + match.length, input.length + 1);
                    regex.lastIndex = 0;
                }
                output.push(input);
                return output;
            } else if (Array.isArray(input)) {
                return input.map(chunk => processInputWithRegex(option, chunk));
            } else return input;
        }
        return function (input) {
            if (!options || !Array.isArray(options) || !options.length)
                return input;
            options.forEach(option => input = processInputWithRegex(option, input));
            return input;
        };
    }

    random_key = () => {
        return (Math.random() * (9999999999999 - 1)) + 1;
    }

    parse = (text) => {
        return this.processString([
            {
                regex: /\[b\](.+?)\[\/b\]/igm, fn: (key, match) => {
                    return <b key={match[1] + "__" + Date.now()}>{match[1]}</b>;
                }
            },
            {
                regex: /\[b\scolor=(.+?)\](.+?)\[\/b\]/igm, fn: (key, match) => {
                    return <b
                        key={match[2] + "_" + this.random_key() + "__" + Date.now()}
                        style={{color: match[1]}}>{match[2]}</b>;
                }
            },
            {
                regex: /\[user=(.+?)\](.+?)\[\/user\]/igm, fn: (key, match) => {
                    return <User
                        key={match[1] + "_" + this.random_key() + "__" + Date.now()}
                        user_uuid={match[1]}
                        user_name={match[2]}
                    />;
                }
            },
            {
                regex: /\[room=(.+?)\sassign=(.+?)\](.+?)\[\/room\]/igm, fn: (key, match) => {
                    return <Room
                        key={match[1] + "_" + match[2] + "_" + this.random_key() + "__" + Date.now()}
                        assign_uuid={match[2]}
                        room_uuid={match[1]}
                        room_name={match[3]}
                    />;
                }
            }
        ])(text);
    }

    render() {
        return (
            <span className="NotificationTextHandler">
                {(this.parse(this.props.message))}
            </span>
        );
    }
}

class User extends Component {
    constructor(props) {
        super(props);
        // user_uuid: String
        // user_name: String

        this.state = {
            user: null,
            loadingUser: false,
            cacheUser: null
        };
    }

    getUserAttribute(user, attribute) {
        if (user) {
            return user.user_profile[attribute];
        }
        return "";
    }

    checkIfTutor(user) {
        if (user) {
            for (let i = 0; i < user.user_roles.length; i++) {
                if (user.user_roles[i].role_name === "Teacher") {
                    return true;
                }
            }
        }
        return false;
    }

    getUserInfo = () => {
        if (this.state.cacheUser === null &&
            !this.state.loadingUser) {
            this.setState({
                loadingUser: true
            },
            () => {
                let query = '/users/' + this.props.user_uuid;
                axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    if (res.data && res.data.rowCount > 0) {
                        let userData = res.data.rows[0];
                        let firstName = this.getUserAttribute(userData, "firstName");
                        let lastName = this.getUserAttribute(userData, "lastName");
                        let fnLetters = firstName.split(" ");
                        let lnLetters = lastName.split(" ");
                        let userAvatarLetters = '';

                        fnLetters.map(l => userAvatarLetters += l.slice(0, 1));
                        lnLetters.map(l => userAvatarLetters += l.slice(0, 1));

                        let userHtml = <span className={"user-logo" + (this.checkIfTutor(userData) ? " tutor-logo" : "")}>
                            {userAvatarLetters}
                        </span>;

                        this.setState({
                            user: userData,
                            loadingUser: false,
                            cacheUser: userHtml
                        },
                        () => ReactTooltip.rebuild());
                    }
                },
                err => {
                    this.setState({
                        user: null,
                        loadingUser: false,
                        cacheUser: null
                    });
                });
            });
        }
    }

    render() {
        return (
            <span className="User">
                <b data-tip data-for={this.props.user_uuid}
                    onMouseDown={this.getUserInfo}>
                    {this.props.user_name}
                </b>
                {
                    this.state.cacheUser ?
                    <ReactTooltip
                        ref={"TIPS_FOR_" + this.props.user_uuid}
                        id={this.props.user_uuid}
                        wrapper="span"
                        place="bottom"
                        effect="solid"
                        event="click"
                        eventOff="mouseleave"
                        delayHide={500}
                        className="tooltip-user">
                        { this.state.cacheUser }
                    </ReactTooltip>
                    : null
                }
            </span>
        );
    }
}

class Room extends Component {
    constructor(props) {
        super(props);
        // room_uuid: String
        // assign_uuid: String
        // room_name: String

        this.state = {
            maximizedChatOpen: false
        };
    }

    openMaximizedChat = () => {
        this.setState({
            maximizedChatOpen: true
        });
    }

    closeMaximizedChat = () => {
        this.setState({
            maximizedChatOpen: false
        });
    }

    render() {
        return <span className="Room">
            <b data-tip data-for={this.props.room_uuid}>
                {this.props.room_name}
            </b>
            <ReactTooltip
                id={this.props.room_uuid}
                wrapper="span"
                place="bottom"
                effect="solid"
                className="tooltip-room"
                event="click"
                eventOff="mouseleave"
                delayHide={500}
                clickable={true}>
                <span className="room-name" onClick={this.openMaximizedChat}>
                    Apri Social Room
                </span>
            </ReactTooltip>
            {
                this.state.maximizedChatOpen ? <SocialRoomModalized
                    room_uuid={this.props.room_uuid}
                    assign_uuid={this.props.assign_uuid}
                    closeSocialRoom={this.closeMaximizedChat}
                /> : null
            }
        </span>
    }
}
