import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Table,
    Alert
} from 'reactstrap';
import moment from 'moment';
import globalVars from '../../globalVars.json';

import './ServerPathStatsDetail.scss';

class ServerPathStatsDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maskLoading:         false,
            tableCounterHeaders: [],
            endpointTopTen:      [],
            endpointLastDays:    [],
            endpointCounters:    {}
        };
    }

    componentDidMount() {
        let setTitle = 'Dashboard - Statistiche HTTP';
        this.props.setSectionTitle(setTitle);

        this.setState({ maskLoading: true });
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/stats/endpoints", {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                // Prepare data
                let counterHeaders   = [];
                let counterEndpoints = {};

                // Extract counter from call
                res.data.rows[0].counters.map((item) => {
                    // Create unique name
                    const idName = (
                        item["stat_route_sec_scope"] + "|" +
                        item["stat_route_method"] + "|" +
                        item["stat_route_pattern"]
                    );

                    // Check if exists
                    if (!(counterEndpoints[idName])) {
                        // Create structure
                        counterEndpoints[idName] = {
                            statuses:  {},
                            last_call: 0
                        };
                    }

                    // Save value remapped
                    counterEndpoints[
                        idName
                    ]["statuses"][
                        item["stat_route_status"]
                    ] = item["stat_route_calls"];

                    // Check for header into headers
                    if (!counterHeaders.includes(
                        item["stat_route_status"]
                    )) {
                        // Save new header
                        counterHeaders.push(
                            item["stat_route_status"]
                        );
                    }

                    // Always false
                    return false;
                });

                // Sort array
                counterHeaders.sort();

                // Save new data
                this.setState({
                    tableCounterHeaders: counterHeaders,
                    endpointCounters:    counterEndpoints,
                    endpointTopTen:      res.data.rows[0]["topten"],
                    endpointLastDays:    res.data.rows[0]["lastdays"],
                    maskLoading:         false
                });
            }
            else this.setState({
                endpointCounters: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                endpointCounters: [],
                maskLoading: false
            });
        });
    }

    render() {
        // Get all indexes
        const __counterIndex = (
            this.state.endpointCounters ?
            Object.keys(this.state.endpointCounters) :
            []
        );

        // Sort indexes
        __counterIndex.sort();

        // Render all
        return (
            <Container className='SysPathStats'>
                {
                    this.state.maskLoading ? (
                        <EdubbaLoader />
                    ) : this.state.endpointCounters ? (
                        holdDataService.loggedUserIsAdmin() ||
                        holdDataService.loggedUserIsSupervisor() ? (
                            <Fragment>
                                <Row key="head-stat-1">
                                    <Col lg='12' md='12' sm='12' className="HeadStat">
                                        Counter Endpoints:
                                    </Col>
                                </Row>
                                <Row key="body-stat-1">
                                    <Col lg='12' md='12' sm='12'>
                                        <Table className="SysPathStats-Table" responsive striped>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th style={{width: "100%"}}>Call Pattern</th>
                                                    <th style={{minWidth: "150px"}}>Call Method</th>
                                                    <th style={{minWidth: "150px"}}>Auth Scope</th>
                                                    {
                                                        this.state.tableCounterHeaders &&
                                                        this.state.tableCounterHeaders.length > 0 ?
                                                        this.state.tableCounterHeaders.map((head, index) => {
                                                            return (
                                                                <th style={{minWidth: "60px"}} key={"head-counter-tr-" + index}>{head}</th>
                                                            );
                                                        }) : null
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    __counterIndex.map((idx, index) => {
                                                        const headParts = idx.split("|");
                                                        return (
                                                            <tr key={"body-counter-tr-" + index}>
                                                                <td>{index + 1}</td>
                                                                <td>{headParts[2]}</td>
                                                                <td>{headParts[1]}</td>
                                                                <td>{headParts[0]}</td>
                                                                {
                                                                    this.state.tableCounterHeaders &&
                                                                    this.state.tableCounterHeaders.length > 0 ?
                                                                    this.state.tableCounterHeaders.map((head, index2) => {
                                                                        let idxCount = this.state.endpointCounters[
                                                                            idx
                                                                        ]["statuses"][head];

                                                                        return (
                                                                            <td key={
                                                                                "body-counter-tr-" + index + "-td-" + index2
                                                                            }>{
                                                                                idxCount ? idxCount : 0
                                                                            }</td>
                                                                        );
                                                                    }) : null
                                                                }
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row key="head-stat-2">
                                    <Col lg='12' md='12' sm='12' className="HeadStat">
                                        Top-Ten Endpoints:
                                    </Col>
                                </Row>
                                <Row key="body-stat-2">
                                    <Col lg='12' md='12' sm='12'>
                                        <Table className="SysPathStats-Table" responsive striped>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Call Path</th>
                                                    <th>Call Pattern</th>
                                                    <th style={{minWidth: "110px"}}>Auth Scope</th>
                                                    <th style={{minWidth: "110px"}}>Auth Type</th>
                                                    <th style={{minWidth: "110px"}}>Total Calls</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.endpointTopTen.map((item, index) => {
                                                        return (
                                                            <tr key={"body-topten-tr-" + index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item["stat_route_path"]}</td>
                                                                <td>{item["stat_route_pattern"]}</td>
                                                                <td>{item["stat_route_sec_scope"]}</td>
                                                                <td>{item["stat_route_sec_type"]}</td>
                                                                <td>{item["stat_route_counter"]}</td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row key="head-stat-3">
                                    <Col lg='12' md='12' sm='12' className="HeadStat">
                                        Ultimi 7 Giorni:
                                    </Col>
                                </Row>
                                <Row key="body-stat-3">
                                    <Col lg='12' md='12' sm='12'>
                                        <Table className="SysPathStats-Table" responsive striped>
                                        <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Call Path</th>
                                                    <th>Call Pattern</th>
                                                    <th style={{minWidth: "110px"}}>Auth Scope</th>
                                                    <th style={{minWidth: "110px"}}>Total Calls</th>
                                                    <th style={{minWidth: "150px"}}>Last Call</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.endpointLastDays.map((item, index) => {
                                                        return (
                                                            <tr key={"body-lastday-tr-" + index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item["stat_route_path"]}</td>
                                                                <td>{item["stat_route_pattern"]}</td>
                                                                <td>{item["stat_route_sec_scope"]}</td>
                                                                <td>{item["stat_route_counter"]}</td>
                                                                <td>{
                                                                    moment(
                                                                        parseInt(item["stat_route_time_update"], 10)
                                                                    ).format('DD/MM/YY H:m:s')
                                                                }</td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : (
                            <Row>
                                <Col lg='12' md='12' sm='12'>
                                    <Alert color="light" className="mt-3">
                                        <h4 className="alert-heading">Ruolo non abilitato!</h4>
                                        <p>
                                            Attenzione il ruolo utente non ha tools con visibilità.
                                        </p>
                                    </Alert>
                                </Col>
                            </Row>
                        )
                    ) : (
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <Alert color="light" className="mt-3">
                                    <h4 className="alert-heading">Nessuna statistica trovata!</h4>
                                    <p>
                                        Attenzione la metrica potrebbe non essere attiva o non essere ancora registrata.<br />
                                        Al primo trigger di piattaforma con attività utile la metrica risulterà visibile.
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        );
    }
};

export default withRouter(ServerPathStatsDetail);
