import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import {
    faRocket,
    faBoxOpen,
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import holdDataService from '../services/holdDataService/holdDataService';

import './CA_Dashboard.scss';

class CA_Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listCADashboard: [
                {
                    id:    0,
                    title: "Gestione dei Materiali",
                    icon:  faBoxOpen,
                    href:  "/third-parties/materials",
                    can_view: (
                        holdDataService.loggedUserIsContentAdmin()
                    )
                }
            ]
        };
    }

    componentDidMount() {
        let setTitle = 'Dashboard di Amministrazione Contenuti';
        this.props.setSectionTitle(setTitle);
    }

    render() {
        return (
            <Container className='CADashboard'>
                <Row>
                    <Col lg='12' md='12' sm='12'>
                        <div className="list-cadashboard-grid">
                            {
                                this.state.listCADashboard.map((obj) => {
                                    if (!obj.can_view) {
                                        return null;
                                    }

                                    return (
                                        <Card key={obj.id}>
                                            <div className="cadashboard-table">
                                                <div className="cadashboard-table-cell">
                                                    <FontAwesomeIcon icon={obj.icon} />
                                                </div>
                                            </div>
                                            <CardBody>
                                                <CardTitle>
                                                    {obj.title}
                                                </CardTitle>
                                            </CardBody>
                                            <div className='bottom-icons'>
                                                <Link to={obj.href}>
                                                    <div
                                                        data-tip data-for={"TIPS_FOR_V_" + obj.id}>
                                                        <FontAwesomeIcon
                                                            className='icon'
                                                            icon={faRocket}
                                                        />
                                                        <ReactTooltip
                                                            id={"TIPS_FOR_V_" + obj.id}
                                                            wrapper="span"
                                                            place="top"
                                                            effect="solid"
                                                            className="tooltip-user">
                                                            Apri Strumento
                                                        </ReactTooltip>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Card>
                                    );
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default withRouter(CA_Dashboard);
