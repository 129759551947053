import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Badge,
    Collapse,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import {
    faSearch, faFilter, faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import ExportPctoPDF from './ExportPctoPDF/ExportPctoPDF';
import globalVars from './../../globalVars.json';

import './Metrics.scss';
import Input from 'reactstrap/lib/Input';

class StatMetrics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maskLoading: false,
            currentProjects: [],
            currentStats: [],
            isFilterOpen: false,
            today: new Date().getTime(),
            disableFilter: true,

            //----------------------------------------------------------------|

            startDate: null,
            endDate: null,
            projectFilter: null,
            nameMailFilter: null,

            //----------------------------------------------------------------|

            currentQueryPage: 1,
            currentQueryLimit: 25,
            currentQueryCount: 25,

            //----------------------------------------------| EXPORT PDF

            modalExportPDFPcto:    false,
            entityPctoPdfObject: null,

            //----------------------------------------------------------------|

            tableOptions: {
                "user_name":             "Email",
                "full_name":             "Utente",
                "session_user_id":       "Stato",
                "total_worked_time_str": () => {
                    return (
                        this.state.projectFilter
                    ) ? "Ore Progetto" :  "Ore Totali";
                },
                "total_num_session":     "Sessioni"
            }
        };
    }

    componentDidMount() {
        let setTitle = 'Dashboard - Statistiche per Utenti';
        this.props.setSectionTitle(setTitle);

        this.loadProjects();
        this.loadUserStats();
    }

    loadProjects() {
        this.setState({ maskLoading: true });

        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/projects", {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentProjects: res.data.rows,
                    maskLoading: false
                });
            }
            else this.setState({
                currentProjects: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                currentProjects: [],
                maskLoading: false
            });
        });
    }

    loadUserStats() {
        this.setState({ maskLoading: true });
        let query = "?page=" + this.state.currentQueryPage + "&limit=" + this.state.currentQueryLimit;

        // Inject filter
        if (this.state.nameMailFilter) {
            query += "&search=" + this.state.nameMailFilter;
        }
        if (this.state.projectFilter) {
            query += "&project_uuid=" + this.state.projectFilter;
        }
        if (this.state.startDate) {
            query += "&date_start=" + this.state.startDate;
        }
        if (this.state.endDate) {
            query += "&date_end=" + this.state.endDate;
        }

        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/stats/users" + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentQueryCount: res.data.rowCount,
                    currentStats: res.data.rows,
                    maskLoading: false
                });
            }
            else this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        });
    }

    prepareHeaders() {
        let rawData    = [<th key={"tr-head-th-" + 0}>#</th>];
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < tableOpts.length; i++) {
            let tmpHeaderLabel = rawOptions[tableOpts[i]];
            tmpHeaderLabel = (
                typeof rawOptions[tableOpts[i]] === "function" ?
                rawOptions[tableOpts[i]]() :
                rawOptions[tableOpts[i]]
            );

            rawData.push(
                <th key={"tr-head-th-" + (i + 1)}>{tmpHeaderLabel}</th>
            );
        }

        rawData.push(
            <th key={"tr-head-th-" + (tableOpts.length + 1)} styles={{
                minWidth: "90px"
            }}>Azioni</th>
        );

        return (<tr key={"tr-head-" + 0}>{rawData}</tr>);
    }

    prepareRows() {
        let rawData    = [];
        let rawRecords = this.state.currentStats;
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < rawRecords.length; i++) {
            let data   = rawRecords[i];
            let tmpRow = [<td key={"tr-body-" + i + "-td-" + 0}>{i + 1}</td>];

            if (!(data["user_uuid"])) {
                continue;
            }

            for (let n = 0; n < tableOpts.length; n++) {
                let tmpNodeValue = data[tableOpts[n]];

                if (tableOpts[n] === "session_user_id") {
                    tmpRow.push(
                        <td key={"tr-body-" + i + "-td-" + (n + 1)}>
                            <Badge color={
                                tmpNodeValue !== null ? "success" : "danger"
                            } pill>{
                                tmpNodeValue !== null ? "ONLINE" : "OFFLINE"
                            }</Badge>
                        </td>
                    );
                }
                else {
                    tmpNodeValue = tmpNodeValue ? tmpNodeValue : "- - -";
                    tmpRow.push(
                        <td key={"tr-body-" + i + "-td-" + (n + 1)}>{tmpNodeValue}</td>
                    );
                }
            }

            tmpRow.push(
                <td key={"tr-body-" + i + "-td-" + (tableOpts.length + 1)}>
                    <div className='bottom-icons'>
                        <Link to={"/admin/stats/users/" + data["user_uuid"]}>
                            <div
                                data-tip data-for={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={faSearch}
                                />
                                <ReactTooltip
                                    id={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}
                                    wrapper="span"
                                    place="left"
                                    effect="solid"
                                    className="tooltip-user">
                                    Visualizza Statistiche Utente
                                </ReactTooltip>
                            </div>
                        </Link>
                        <div
                            // eslint-disable-next-line no-loop-func
                            onClick={() => this.exportPctoToPDF(data["user_uuid"])}
                            data-tip data-for={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}>
                            <FontAwesomeIcon
                                className='icon'
                                icon={faFilePdf}
                            />
                            <ReactTooltip
                                id={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}
                                wrapper="span"
                                place="left"
                                effect="solid"
                                className="tooltip-user">
                                Scarica Certificato di Attività
                            </ReactTooltip>
                        </div>
                    </div>
                </td>
            );
            rawData.push(<tr key={"tr-body-" + i}>{tmpRow}</tr>);
        }

        return rawData;
    }

    preparePaginationItems() {
        // Prepare data
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);
        let items      = [];

        // Prepare limits
        let firstMin   = 1;
        let lastMax    = countPage;

        // Check range
        if (countPage > 8) {
            // Specifies range of items in the middle
            firstMin = Math.max(active - 2, 1);
            lastMax  = Math.min(active + 2, countPage);

            // Normalize
            if (active === 1) {
                lastMax += 2;
            }
            // Normalize
            if (active === 2) {
                lastMax += 1;
            }
            else if (active === (countPage - 1)) {
                firstMin-=1;
            }
            else if (active === countPage) {
                firstMin-=2;
            }
        }

        // Push forst separator
        if (firstMin > 1) {
            items.push(
                <PaginationItem key={"firstMinSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Loop on item
        for (let number = firstMin; number <= lastMax; number++) {
            // Save item
            items.push(
                <PaginationItem key={number} active={number === active}>
                    <PaginationLink onClick={() => this.changePage(number)}>
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Push last separator
        if (lastMax < countPage) {
            items.push(
                <PaginationItem key={"lastMaxSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Send back
        return items;
    }

    changePage(toPage) {
        this.setState({
            currentStats: [],
            currentQueryPage: toPage
        },
        () => {
            this.loadUserStats();
        });
    }

    changePageByStep(toPage) {
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);

        switch (toPage) {
            case "first":
                active = 1;
                break;

            case "back":
                active = ((active - 1) >= 1) ? (active - 1) : 1;
                break;

            case "next":
                active = ((active + 1) <= countPage) ? (active + 1) : active;
                break;

            case "last":
                active = countPage;
                break;

            default: break;
        }

        this.setState({
            currentStats: [],
            currentQueryPage: active
        },
        () => {
            this.loadUserStats();
        });
    }

    toggleFilter() {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen
        });
    }

    handleChangeNameMail= (ev) => {
        this.setState({
            nameMailFilter: ev.target && ev.target.value ? ev.target.value : null,
            currentQueryPage: 1
        });
    }

    handleChangeProject= (ev) => {
        this.setState({
            projectFilter: ev.target && ev.target.value ? ev.target.value : null,
            currentQueryPage: 1
        });
    }

    handleChangeStart = (startDate) => {
        if (startDate) {
            this.setState({
                startDate: startDate.getTime(),
                currentQueryPage: 1
            });
        }
    }

    handleChangeEnd = (endDate) => {
        if (endDate) {
            this.setState({
                endDate: endDate.getTime(),
                currentQueryPage: 1
            });
        }
    }

    clearFilter = () => {
        this.setState({
            isFilterOpen: false,
            currentQueryPage: 1,
            projectFilter: null,
            nameMailFilter: null,
            startDate: null,
            endDate: null,
            disableFilter: true
        },
        () => {
            this.loadUserStats();
        });
    }

    applyFilter = () => {
        this.setState({
            currentQueryPage: 1,
            disableFilter: false
        },
        () => {
            this.loadUserStats();
        });
    }

    exportPctoToPDF = (obj = null) => {
        this.setState({
            modalExportPDFPcto:  obj ? true : false,
            entityPctoPdfObject: obj
        });
    }

    render() {
        return (
            <Fragment>
                <Container className='UserStats' active-page={this.state.currentQueryPage}>
                    {
                        this.state.maskLoading ?
                            <EdubbaLoader />
                        : <Fragment>
                            <Row key="head-stat-1">
                                <Col lg='12' md='12' sm='12' className="HeadStat">
                                    Seleziona Utenza:
                                    <Button
                                        data-tip data-for={"TIPS_FOR_BTN_ACTION_FILTER"}
                                        color="secondary"
                                        onClick={() => this.toggleFilter()}
                                        style={{
                                            position: "relative",
                                            float: "right",
                                            marginTop: '12px'
                                        }}>
                                        <FontAwesomeIcon icon={faFilter} />
                                        <ReactTooltip
                                            id={"TIPS_FOR_BTN_ACTION_FILTER"}
                                            wrapper="span"
                                            place="left"
                                            effect="solid"
                                            className="tooltip-user">
                                            Mostra/Nascondi i filtri di visualizzazione
                                        </ReactTooltip>
                                    </Button>
                                    <Collapse isOpen={this.state.isFilterOpen}>
                                        <Container className="UserStats-Filter">
                                            <Row key="body-filter">
                                                <Col lg='6' md='6' sm='6'>
                                                    Cerca per Nome/Email:<br />
                                                    <Input
                                                        className="legacy-form-control"
                                                        name="nameEmail"
                                                        defaultValue={
                                                            this.state.nameMailFilter ?
                                                            this.state.nameMailFilter
                                                            : ""
                                                        }
                                                        onChange={this.handleChangeNameMail}>
                                                    </Input>
                                                </Col>
                                                <Col lg='6' md='6' sm='6'>
                                                    Filtra per Progetto:<br />
                                                    <select
                                                        className="legacy-form-control"
                                                        name="select"
                                                        defaultValue={
                                                            this.state.projectFilter ?
                                                            this.state.projectFilter
                                                            : "unkwn"
                                                        }
                                                        onChange={this.handleChangeProject}>
                                                        <option key="opt-0" value="unkwn" disabled>Seleziona un Progetto...</option>
                                                        {
                                                            this.state.currentProjects.length ?
                                                                this.state.currentProjects.map((project, index) => {
                                                                    return <option
                                                                    key={"opt-" + (index + 1)}
                                                                    value={
                                                                        project.project_uuid
                                                                    }>
                                                                    {project.project_name}
                                                                    </option>
                                                                })
                                                            : null
                                                        }
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row key="body-filter1" style={{paddingTop: '15px'}}>
                                                <Col lg='6' md='6' sm='6'>
                                                    Filtra per Data Inizio:<br />
                                                    <DatePicker
                                                        name="dataInizio"
                                                        className="UserStats-DatePicker"
                                                        selected={this.state.startDate}
                                                        selectsStart
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        onChange={this.handleChangeStart}
                                                        dateFormat={'dd/MM/yyyy'}
                                                    />
                                                </Col>
                                                <Col lg='6' md='6' sm='6'>
                                                    Filtra per Data Fine:<br />
                                                    <DatePicker
                                                        name="dataFine"
                                                        className="UserStats-DatePicker"
                                                        selected={this.state.endDate}
                                                        selectsEnd
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        onChange={this.handleChangeEnd}
                                                        dateFormat={'dd/MM/yyyy'}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row key="footer-filter2">
                                                <Col lg='12' md='12' sm='12'>
                                                    <br />
                                                    <Button
                                                        color="danger"
                                                        onClick={() => this.clearFilter()}
                                                        disabled={
                                                            this.state.startDate === null &&
                                                            this.state.endDate === null &&
                                                            this.state.projectFilter === null &&
                                                            this.state.nameMailFilter === null
                                                        }>
                                                        RIPRISTINA FILTRI
                                                    </Button> <Button
                                                        color="success"
                                                        onClick={() => this.applyFilter()}
                                                        disabled={
                                                            this.state.startDate === null &&
                                                            this.state.endDate === null &&
                                                            this.state.projectFilter === null &&
                                                            this.state.nameMailFilter === null
                                                        }>
                                                        APPLICA FILTRI
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row key="body-stat">
                                <Col lg='12' md='12' sm='12'>
                                    <Table className="UserStats-Table" responsive striped>
                                        <thead>{this.prepareHeaders()}</thead>
                                        <tbody>{this.prepareRows()}</tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row key="pages-stat">
                                <Col lg='12' md='12' sm='12' className="ToolStat-Pagination">
                                    <Pagination>
                                        <PaginationItem>
                                            <PaginationLink first onClick={() => this.changePageByStep("first")} />
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink previous onClick={() => this.changePageByStep("back")} />
                                        </PaginationItem>

                                        {this.preparePaginationItems()}

                                        <PaginationItem>
                                            <PaginationLink next onClick={() => this.changePageByStep("next")} />
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink last onClick={() => this.changePageByStep("last")} />
                                        </PaginationItem>
                                    </Pagination>
                                </Col>
                            </Row>
                        </Fragment>
                    }
                </Container>
                {/* v EXPORT PDF v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalExportPDFPcto}
                    toggle={() => this.exportPctoToPDF()}>
                    <ModalHeader toggle={() => this.exportPctoToPDF()}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Esportazione PDF
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            Stiamo generando il documento richiesto a partire dalle informazioni presenti per l'utenza. Una volta disponibile sarà possibile scaricarlo premendo sul seguente bottone:<br />
                            {this.state.entityPctoPdfObject ?
                                <div style={{textAlign: 'center', marginTop: '20px'}}>
                                    <ExportPctoPDF
                                        inlineView={false}
                                        entityData={this.state.entityPctoPdfObject}
                                    />
                                </div>
                            : null}
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={() => this.exportPctoToPDF()}>Chiudi</Button>
                    </ModalFooter>
                </Modal>
                {/* ^ EXPORT PDF ^ */}
            </Fragment>
        );
    }
};

export default withRouter(StatMetrics);
