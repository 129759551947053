import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import Ionicon from 'react-ionicons';
import ReactSVG from 'react-svg';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, ListGroup } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CardHeader, Card, ListGroupItem, Alert } from 'reactstrap';

import NotificationTextHandler from './../../../components/NotificationTextHandler/NotificationTextHandler';

import globalVars from './../../../globalVars.json';
import onlinePeopleSrc from './../../../assets/utils/images/network.svg';
import holdDataService from '../../../services/holdDataService/holdDataService';
import './HeaderDots.scss';

class HeaderDots extends Component {
    constructor(props) {
        super(props);
        // teams: Array
        // notifications: Array
        // checkNotificationSeen: Function

        this.state = {
            active: false,
            isTutor: false,
            loading: false,
            groups: [],

            notifications: [],

            data: '',
            dropdownOpen: false
        };
    }

    componentDidMount() {
        this.props.getNotifications();
        this.loadGroups();
    }

    componentWillMount() {
        let isTutor = holdDataService.loggedUserIsTutor();
        if (this.props.teams && this.props.teams.length !== 0)
            this.setState({
                isTutor: isTutor,
                groups: this.props.teams
            })
        this.loadGroups();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.teams !== this.state.teams)
            this.setState({
                groups: nextProps.teams
            })
    }

    loadGroups = () => {
        if (holdDataService.loggedUserIsAdmin()          ||
            holdDataService.loggedUserIsContentAdmin()   ||
            holdDataService.loggedUserIsOnlySupervisor() ||
            holdDataService.getLoggedUserUuid() === null) {
            return;
        }

        let query = '/groups';
        //this.setState({ loading: true })
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let groups = [];
                if (res.data.rowCount > 0) {
                    groups = res.data.rows;
                }
                this.setState({
                    groups: groups
                })//, () => this.setState({ loading: false }));
            }, err => {
            });
    }

    historyPush = (path) => {
        this.props.history.push(path);
    }

    getUnreadNotifications = (notifications) => {
        let count = 0;
        if (notifications) {
            for (let i = 0; i < notifications.length; i++) {
                if (!notifications[i].seen) {
                    count++;
                }
            }
        }
        return count;
    }

    render() {
        let unreadNotifications = this.getUnreadNotifications(this.props.notifications);
        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    {this.state.loading ? null :
                        <div className="header-dots">
                            <UncontrolledDropdown>
                                <DropdownToggle className="p-0 mr-2" color="link">
                                    <div className="icon-wrapper icon-wrapper-alt rounded-circle ml-0">
                                        <div className="icon-wrapper-bg" />
                                        <Ionicon beat={false} color="white" fontSize="23px" icon="md-notifications-outline" />
                                        <div
                                            className="badge badge-dot badge-danger badge-number"
                                            style={unreadNotifications === 0 ? { opacity: '0' } : { opacity: '1' }}
                                        >
                                            {unreadNotifications}
                                        </div>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xl rm-pointers p-0">
                                    {
                                        this.props.notifications.length > 0 ?
                                            <NotificationList
                                                notifications={this.props.notifications}
                                                checkNotificationSeen={this.props.checkNotificationSeen}
                                                isTutor={this.state.isTutor}
                                                historyPush={this.historyPush}
                                            />
                                        : (
                                            <Alert color="light" className="mt-3 ml-3 mr-3">
                                                <h5 className="alert-heading">Nessuna notifica!</h5>
                                                <hr />
                                                <p className="mb-0">
                                                    Non sono presenti attività da visionare nell'ultima settimana.
                                                </p>
                                            </Alert>
                                        )
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown>
                                <DropdownToggle className="p-0 mr-2" color="link">
                                    <div className="icon-wrapper icon-wrapper-alt rounded-circle ml-0">
                                        <div className="icon-wrapper-bg" />
                                        <ReactSVG className='svg-class' src={onlinePeopleSrc} />
                                        <div className="badge badge-dot badge-warning badge-number" style={
                                            this.state.groups.length === 0 ? { opacity: '0' } : { opacity: '1' }}>
                                            {this.state.groups.length}
                                        </div>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xl rm-pointers p-0">
                                    <GroupList groups={this.state.groups}
                                        isTutor={this.state.isTutor}
                                        historyPush={this.historyPush} />
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </div>}
                </div>
            </Fragment>
        )
    }
}

class GroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTutor: false,
            groups: [],
            dropdownOpen: false
        };
    }

    componentDidMount() {
        this.setState({
            isTutor: this.props.isTutor,
            groups: this.props.groups,
        })
    }

    getUsersHTML = (group) => {
        let usersHTML = [];
        group.map((user, index) => {
            usersHTML.push(
                <div key={index} className='d-inline user'>
                    {index < group.length - 1 ?
                        `${user.user_profile.firstName} ${user.user_profile.lastName}, `
                        :
                        user.user_profile.firstName + user.user_profile.lastName}
                </div>
            );
            return true;
        });
        return usersHTML;
    }

    getGroupsHTML = () => {
        let groupsHTML = [];
        this.state.groups.map((group, index) => {
            groupsHTML.push(
                <ListGroupItem key={`group-${index}`} onClick={event => {
                    event.preventDefault();
                    this.props.historyPush('/groups');
                }}>
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                                <div className="widget-heading text-edubba">
                                    {group.group_name}
                                </div>
                                <div className="widget-subheading font-italic users-list">
                                    {this.getUsersHTML(group.group_members)}
                                </div>
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            );
            return true;
        });
        if (groupsHTML.length === 0)
            return <ListGroupItem>
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                            <div className="widget-heading text-edubba">
                                {
                                    this.state.isTutor ? 'Non possiedi Gruppi' : 'Non sei in nessun Gruppo'
                                }
                            </div>
                            <div className="widget-subheading font-italic users-list">
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>;

        return <ListGroup flush>
            {groupsHTML}
        </ListGroup>;
    }

    render() {
        return (
            <Fragment>
                <Card className="HeaderDots card-hover-shadow-2x">
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <div className="icon-wrapper icon-wrapper-alt rounded-circle ml-0">
                                <div className="icon-wrapper-bg" />
                                <ReactSVG className='svg-class' src={onlinePeopleSrc} />
                            </div>
                            GRUPPI
                        </div>
                    </CardHeader>
                    <PerfectScrollbar>
                        {this.getGroupsHTML()}
                    </PerfectScrollbar>
                    {/* <CardFooter className="d-block text-center">
                        <Button className="btn btn-edubba-inverse">
                            Vedi tutti
                                        </Button>
                    </CardFooter> */}
                </Card>
            </Fragment>
        )
    }

}

class NotificationList extends Component {
    constructor(props) {
        super(props);
        // notifications: Array
        // checkNotificationSeen: Function
        // isTutor: Boolean
        // historyPush: Function

        this.state = {
            data: '',
            dropdownOpen: false
        };
    }

    render() {

        return (
            <Fragment>
                <Card className="HeaderDots notifications-list card-hover-shadow-2x">
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon pe-7s-bell"> </i>
                            NOTIFICHE
                        </div>
                    </CardHeader>
                    <PerfectScrollbar>
                        <ListGroup flush>
                            {this.props.notifications.map((obj, index) => {
                                let notificationType = ((notification_type) => {
                                    switch (notification_type) {
                                        case "ACTYPID_0001":
                                            return "Gruppi";
                                        case "ACTYPID_0002":
                                        case "ACTYPID_0003":
                                        case "ACTYPID_0004":
                                        case "ACTYPID_0005":
                                            return "Social";
                                        case "ACTYPID_0006":
                                            return "Compiti";
                                        default:
                                            return "Generale";
                                    }
                                })(obj.activity_priority);
                                return <ListGroupItem
                                    key={"NOTIFY_GRP_" + obj.activity_uuid + "_" + index}
                                    className={obj.seen ? "" : "new"}
                                    onClick={() => this.props.checkNotificationSeen(obj.activity_uuid)}
                                >
                                    <div className="widget-content p-0">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left mr-3">
                                                <div className='notification-text'>
                                                    <NotificationTextHandler
                                                        key={"NOTIFY_" + obj.activity_uuid + "_" + index}
                                                        message={obj.activity_message} />
                                                </div>
                                            </div>
                                            <div className="widget-content-right">
                                                <div
                                                    className={"badge badge-notification"}
                                                    style={{backgroundColor: obj.activity_priority_color}}
                                                >
                                                    {notificationType}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroupItem>;
                            })}
                        </ListGroup>
                    </PerfectScrollbar>
                    {/* <CardFooter className="d-block text-center">
                        <Button className="btn btn-edubba-inverse">
                            Vedi tutti
                                        </Button>
                    </CardFooter> */}
                </Card>
            </Fragment>
        )
    }
}

export default withRouter(HeaderDots);
