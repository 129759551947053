import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from './../globalVars.json';
import {
    Row, Col, Button, Container, Card,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import EdubbaLoader from './../components/EdubbaLoader/EdubbaLoader';
import moment from 'moment';
import 'moment/locale/it';
import holdDataService from './../services/holdDataService/holdDataService';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    faPlus,
    faTimes,
    faUsers,
    faSearch,
    faExclamationTriangle,
    faTrash,
    faArrowCircleLeft,
    faArrowCircleRight,
    faEdit,
    faEye,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupButtonDropdown from 'reactstrap/lib/InputGroupButtonDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Input from 'reactstrap/lib/Input';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Alert from 'reactstrap/lib/Alert';
import ReactTooltip from 'react-tooltip';
import CardText from 'reactstrap/lib/CardText';
import CardTitle from 'reactstrap/lib/CardTitle';
import CardBody from 'reactstrap/lib/CardBody';
import './Teams.scss';

class Teams extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTeams: [],
            classesAndUsers: [],
            teamsLoading: true,
            usersLoading: true,

            addUserSelectedTeam: -1,
            deleteSelectedTeam: -1,
            searchValue: '',
            filteredTeams: [],

            //Modali
            modalAddUser: false,
            modalAddTeam: false,
            modalDeleteTeam: false,

            tooltipOpen: false,
            toggleSkipNotificationUpdate: false,

            //----------------------------------------------| SEARCH

            searchTeamValue: "",

            //----------------------------------------------| PAGING

            teamSplitButtonOpen: false,
            teamTotalEntities: 0,
            teamPageLimit: 10,
            teamPageNumber: 1,

            //----------------------------------------------| MODAL CONFIRM
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
        };

    }

    componentWillMount() {
        let title = 'Anagrafica dei Gruppi';
        this.props.setSectionTitle(title);
        this.loadTeams();
    }

    loadTeams = (searchValue = null) => {
        this.setState({ teamsLoading: true })
        let query = '/groups?limit=' + this.state.teamPageLimit + '&page=' + this.state.teamPageNumber;
        if (searchValue) {
            query += '&searchBy=' + searchValue;
        }
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    teamTotalEntities: res.data.rowCount,
                    currentTeams:      res.data.rows,
                    teamsLoading:      false
                }, () => this.props.updateTeams(
                    res.data.rows
                ));
            }
            else {
                this.setState({
                    teamPageNumber:    1,
                    teamTotalEntities: 0,
                    currentTeams:      [],
                    teamsLoading:      false
                }, () => this.props.updateTeams([]));
            }
        },
        err => {
            this.setState({
                teamPageNumber:    1,
                teamTotalEntities: 0,
                currentTeams:      [],
                teamsLoading:      false
            }, () => this.props.updateTeams([]));
        });
    }

    toggleAddUser = (index) => {
        let team = !this.state.modalAddUser ? index : -1;

        this.setState({
            toggleSkipNotificationUpdate: !this.state.toggleSkipNotificationUpdate,
            modalAddUser: !this.state.modalAddUser,
            addUserSelectedTeam: team,
        },
        () => {
            this.props.notificationUpdate(
                this.state.toggleSkipNotificationUpdate
            )
        });
    }

    toggleDeleteTeam = (index) => {
        let team = !this.state.modalDeleteTeam ? index : -1;
        this.setState({
            modalDeleteTeam: !this.state.modalDeleteTeam,
            deleteSelectedTeam: team
        });
    }

    toggleAddTeam = () => {
        this.setState({
            toggleSkipNotificationUpdate: !this.state.toggleSkipNotificationUpdate,
            modalAddTeam: !this.state.modalAddTeam,
        },
        () => {
            this.props.notificationUpdate(
                this.state.toggleSkipNotificationUpdate
            )
        });
    }

    deleteTeam = (event) => {
        event.preventDefault();
        let groupUuid = this.state.currentTeams[this.state.deleteSelectedTeam].group_uuid;
        let query = "/groups/" + groupUuid;
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            // Check
            if (res && res.data && res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Gruppi",
                    modalConfirmToastMessage: "Gruppo selezionato rimosso con successo dal proprio istituto!",
                    modalDeleteTeam: false,
                    deleteSelectedTeam: null
                }, () => {
                    // Refresh
                    this.loadTeams();
                });
            } else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Gruppi",
                    modalConfirmToastMessage: "Impossibile completare la rimozione del gruppo selezionato: verificare che non sia associato a qualche attività o studente.",
                    modalDeleteTeam: false,
                    deleteSelectedTeam: null
                });
            }
        }, err => {
            // Error
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Rimozione Gruppi",
                modalConfirmToastMessage: err.message,
                modalDeleteTeam: false,
                deleteSelectedTeam: null
            });
        });
    }

    removeStudent = (groupUuid, userUuid) => {
        this.setState({ teamsLoading: true })
        let deleteBody = {
            group_members: [userUuid]
        }
        let query = '/groups/' + groupUuid + '/members';
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            data: deleteBody,
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.loadTeams();
        }, err => {
        });
    }

    updateGroup = (event) => {
        event.preventDefault();

    }

    creatingGroup = (flag) => {
        if (!flag) {
            // Aggiorno i gruppi
            this.loadTeams()
        }
        this.setState({
            teamsLoading: flag
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    goToTeamPage = (pageNumber) => {
        this.setState({
            teamPageNumber: pageNumber
        }, () => {
            this.loadTeams(
                this.state.searchTeamValue
            );
        });
    }

    teamToggleSplit = () => {
        this.setState({
            teamSplitButtonOpen: !this.state.teamSplitButtonOpen
        });
    }

    handleTeamSearch = (ev) => {
        this.setState({
            searchTeamValue: ev.target.value
        })
    }

    searchTeam = (ev) => {
        const keyCode = ev.which || ev.keyCode;
        if (keyCode === 13) {
            this.loadTeams(
                this.state.searchTeamValue
            );
        }
    }

    clearSearchTeams = () => {
        this.setState({
            searchTeamValue: "",
            teamPageNumber: 1
        }, () => {
            this.loadTeams();
        });
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    render() {
        let teamPages = Math.ceil(
            this.state.teamTotalEntities /
            this.state.teamPageLimit
        );

        teamPages = (teamPages <= 0) ? 1 : teamPages;

        let addedStudents = [];
        addedStudents = this.state.addUserSelectedTeam !== -1 ? [...this.state.currentTeams[this.state.addUserSelectedTeam].group_members] : [];

        return (
            <Fragment>
                <Container className='Teams'>
                    {
                        this.state.teamsLoading ?
                            <EdubbaLoader />
                        : null
                    }
                    <Fragment>
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <div className="task-container">
                                    <div className="task-container-title">I Miei Gruppi:</div>
                                    <div className="task-container-search">
                                        <InputGroup>
                                            <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.teamSplitButtonOpen} toggle={this.teamToggleSplit}>
                                                {
                                                    holdDataService.loggedUserIsTutor() ? (
                                                        <Button onClick={(event) => {
                                                            event.preventDefault();
                                                            this.toggleAddTeam();
                                                        }}>
                                                            <FontAwesomeIcon className='icon' icon={faPlus} />
                                                        </Button>
                                                    ) : null
                                                }
                                                <Button disabled={true}>
                                                    <b>Pagina: &nbsp;</b>{ this.state.teamPageNumber }
                                                </Button>
                                                { teamPages > 1 ? <DropdownToggle split /> : null }
                                                <DropdownMenu>
                                                    {
                                                        // Gen. pages
                                                        (() => {
                                                            let pages = [];
                                                            for (let i = 1; i <= teamPages; i++) {
                                                                if (this.state.teamPageNumber === i) {
                                                                    continue;
                                                                }
                                                                else pages.push(
                                                                    <DropdownItem
                                                                        key={"card-page-" + i}
                                                                        onClick={() => this.goToTeamPage(i)}
                                                                    >
                                                                        Vai alla Pagina: &nbsp;{i}
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            return pages;
                                                        })()
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                            <Input
                                                value={this.state.searchTeamValue}
                                                onChange={this.handleTeamSearch}
                                                onKeyUp={this.searchTeam}
                                                placeholder="Premere invio per cercare..."
                                            />
                                            {
                                                !this.isEmpty(this.state.searchTeamValue) ? (
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.clearSearchTeams}>
                                                            <FontAwesomeIcon className='icon' icon={faTrash} />
                                                        </Button>
                                                    </InputGroupAddon>
                                                ) : null
                                            }
                                        </InputGroup>
                                    </div>
                                    <div className="task-container-arrow-left">
                                        <Button disabled={this.state.teamPageNumber <= 1} onClick={() => this.goToTeamPage(
                                            this.state.teamPageNumber - 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                        </Button>
                                    </div>
                                    <div className="task-container-arrow-right">
                                        <Button disabled={this.state.teamPageNumber >= teamPages} onClick={() => this.goToTeamPage(
                                            this.state.teamPageNumber + 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                        </Button>
                                    </div>
                                    <div className="task-container-list">
                                    {
                                        this.state.currentTeams.length > 0 ?
                                            <div className="list-teams-grid">
                                                {
                                                    this.state.currentTeams.map((obj, index) => {
                                                        return (
                                                            <Card key={"team-idx-" + (index + 1)}>
                                                                <div className="tools-table">
                                                                    <div className="tools-table-cell">
                                                                        <FontAwesomeIcon icon={faUsers} />
                                                                    </div>
                                                                </div>
                                                                <div className="team-status" style={{
                                                                    backgroundColor: "#c67e00",
                                                                    color: "#ffffff"
                                                                }}>
                                                                    {
                                                                        "Gruppo di Lavoro"
                                                                    }
                                                                </div>
                                                                <CardBody>
                                                                    <CardTitle>
                                                                        {obj.group_name.toUpperCase()}
                                                                    </CardTitle>
                                                                    <CardText>
                                                                        <small className="text-muted">
                                                                            <b>Membri:</b> {obj.group_members.length} Student{
                                                                                obj.group_members.length === 1 ? "e" : "i"
                                                                            }
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Creato:</b> {this.getFromNowDate(obj.group_date_create)}
                                                                        </small>
                                                                    </CardText>
                                                                </CardBody>
                                                                <div className={'bottom-icons ' + (
                                                                    holdDataService.loggedUserIsTutor() ?
                                                                    'bottom-icons-two-cols' : ''
                                                                ) }>
                                                                    {
                                                                        holdDataService.loggedUserIsTutor() ? (
                                                                            <Fragment>
                                                                                <div
                                                                                    onClick={() => this.toggleAddUser(index)}
                                                                                    data-tip data-for={"TIPS_FOR_V_" + obj.group_uuid}>
                                                                                    <FontAwesomeIcon
                                                                                        className='icon'
                                                                                        icon={faEdit}
                                                                                    />
                                                                                    <ReactTooltip
                                                                                        id={"TIPS_FOR_V_" + obj.group_uuid}
                                                                                        wrapper="span"
                                                                                        place="top"
                                                                                        effect="solid"
                                                                                        className="tooltip-user">
                                                                                        Premere per modificare i componenti del gruppo.
                                                                                    </ReactTooltip>
                                                                                </div>
                                                                                <div className="red-action-btn"
                                                                                    onClick={() => this.toggleDeleteTeam(index)}
                                                                                    data-tip data-for={"TIPS_FOR_DELETE_" + obj.group_uuid}>
                                                                                    <FontAwesomeIcon
                                                                                        className='icon'
                                                                                        icon={faTrash}
                                                                                    />
                                                                                    <ReactTooltip
                                                                                        id={"TIPS_FOR_DELETE_" + obj.group_uuid}
                                                                                        wrapper="span"
                                                                                        place="top"
                                                                                        effect="solid"
                                                                                        className="tooltip-user">
                                                                                            ATTENZIONE: Permette la rimozione del gruppo!
                                                                                    </ReactTooltip>
                                                                                </div>
                                                                            </Fragment>
                                                                        ): (
                                                                            <div
                                                                                onClick={() => this.toggleAddUser(index)}
                                                                                data-tip data-for={"TIPS_FOR_V_" + obj.group_uuid}>
                                                                                <FontAwesomeIcon
                                                                                    className='icon'
                                                                                    icon={faEye}
                                                                                />
                                                                                <ReactTooltip
                                                                                    id={"TIPS_FOR_V_" + obj.group_uuid}
                                                                                    wrapper="span"
                                                                                    place="top"
                                                                                    effect="solid"
                                                                                    className="tooltip-user">
                                                                                    Premere per visualizzare i componenti del gruppo.
                                                                                </ReactTooltip>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : (
                                            <Row>
                                                <Col lg='12' md='12' sm='12'>
                                                    <Alert color="light" className="mt-3">
                                                        <h4 className="alert-heading">Nessun gruppo trovato!</h4>
                                                        <p>
                                                            Non è ancora stato creato un gruppo su cui lavorare.<br />
                                                            Appena creato il gruppo sarà visibile nella sezione corrente.
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0">
                                                            Premi sul tasto "+" presente a lato della barra di ricerca.
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
                {/* v CONFIRM: modalConfirmToast v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalConfirmToast}
                    toggle={this.closeModalConfirmToast}>
                    <ModalHeader toggle={this.closeModalConfirmToast}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    {this.state.modalConfirmToastTitle}
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            {this.state.modalConfirmToastMessage}
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                    </ModalFooter>
                </Modal>
                {/* ^ CONFIRM: modalConfirmToast ^ */}
                {/* Modale per la rimozione di un gruppo */}
                {holdDataService.loggedUserIsTutor() ?
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalDeleteTeam}
                        toggle={this.toggleDeleteTeam}>
                        <ModalHeader toggle={this.toggleDeleteTeam}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Rimozione di un Gruppo
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                Vuoi davvero rimuovere il gruppo selezionato? L'operazione non sarà possibile se il gruppo è assegnato a qualche attività!
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.toggleDeleteTeam}>Annulla</Button>
                            <Button
                                className='btn-edubba'
                                onClick={this.deleteTeam}
                                disabled={this.state.deleteSelectedTeam === -1}
                            >
                                Rimuovi
                            </Button>
                        </ModalFooter>
                    </Modal> : null
                }
                {/* Modale per l'aggiunta di studenti al gruppo */}
                {this.state.addUserSelectedTeam !== -1 ?
                    <AddStudentToTeamModal
                        group={this.state.currentTeams[this.state.addUserSelectedTeam]}
                        addedStudents={addedStudents}
                        toggleAddUser={this.toggleAddUser}
                        loadTeams={this.loadTeams}
                        modalAddUser={this.state.modalAddUser}
                        updateGroup={this.updateGroup}
                    /> : null
                }
                {/* Modale per la creazione di un nuovo gruppo */}
                {holdDataService.loggedUserIsTutor() ?
                    <CreateNewTeamModal
                        creatingGroup={this.creatingGroup}
                        modalAddTeam={this.state.modalAddTeam}
                        toggleAddTeam={this.toggleAddTeam}
                    /> : null
                }
            </Fragment>
        )
    }
}

class AddStudentToTeamModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groupName: '',
            searchValue: '',
            filteredStudents: [],
            originalGroupMembers: [],

            students: [], //per mockare degli studenti
            selectedStudents: [],
        };

    }

    componentDidMount() {
        let membersUuids = [];
        this.props.group.group_members.map(u => membersUuids.push(u.user_uuid));
        this.setState({ originalGroupMembers: membersUuids });
        this.loadStudents();
    }

    loadStudents = () => {
        let query = "/classrooms";
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let students = [];
                if (res.data.rowCount > 0) {
                    // students = res.data.rows;
                    res.data.rows.map(classrooms => {
                        classrooms.class_members.map(student => {
                            students.push(student);
                            return false;
                        });
                        return false;
                    });
                }
                this.setState({
                    students: students
                }, this.loadNotAddedStudents);
            }, err => {

            });
    }

    loadNotAddedStudents = () => {
        // Rimuovo gli studenti già aggiunti (rimuovo per stesso username)
        let groupName = this.props.group.group_name;
        let students = this.state.students;
        let addedStudents = this.props.addedStudents;
        let filteredStudents = [];
        students.map(s1 => {
            let addStudent = true;
            addedStudents.map(s2 => {
                if (s1.user_name === s2.user_name)
                    addStudent = false;
                return true;
            });
            if (addStudent)
                filteredStudents.push(s1);
            return true;
        })
        this.setState({
            groupName: groupName,
            students: filteredStudents,
            selectedStudents: addedStudents,
        });
    }

    handleChange = (event) => {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
        }, () => {
            if (name === 'searchValue')
                this.setFilteredStudents();
        })
    }

    setFilteredStudents = () => {
        let filteredStudents = [];

        if (this.state.students.length > 0) {
            filteredStudents = this.state.students.filter(student =>
                // Filtro per nome
                (student.user_profile.firstName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // Filtro per cognome del gruppo
                (student.user_profile.lastName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // filtro per nome e cognome insieme
                (`${student.user_profile.firstName.toLowerCase()} ${student.user_profile.lastName.toLowerCase()}`.indexOf(this.state.searchValue.toLowerCase()) !== -1)
                ||
                // Filtro anche in base alla classe
                (Array.isArray(student.user_classrooms) && student.user_classrooms.filter(
                    c => (c.class_name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                        (`classe ${c.class_name.toLowerCase()}`.indexOf(this.state.searchValue.toLowerCase()) !== -1)
                ).length > 0)
            );
        }
        this.setState({
            filteredStudents: filteredStudents
        });
    }

    getAllStudentsHTML = () => {
        let students = this.state.students;
        // Se il campo di ricerca è attivo allora mostro gli studenti filtrati
        if (this.state.searchValue !== '')
            students = this.state.filteredStudents;
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b>
                        <i>
                            {/* Classe {student.user_classrooms !== undefined ?
                                student.user_classrooms.length > 0 ? student.user_classrooms[0] : null
                                : 'non pervenuta'} */}
                        </i>
                    </p>
                    <span className='c-p' onClick={(event) => {
                        event.preventDefault();
                        this.addStudent(index)
                    }} >
                        <FontAwesomeIcon className='icon' icon={faPlus} />
                    </span>
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    {this.state.searchValue === '' ?
                        // Non sono presenti studenti
                        'Non ci sono studenti ...'
                        :
                        // Nessuno studente rispetta la ricerca
                        `Studente non trovato "${this.state.searchValue}"`
                    }
                </p>
                <span>
                    <FontAwesomeIcon className='icon text-warning' icon={faExclamationTriangle} />
                </span>
            </div>;

        return studentsHTML;
    }

    getSelectedStudentsHTML = () => {
        let students = this.state.selectedStudents;
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b>
                        <i>
                            {/* Classe {student..class_name} */}
                        </i>
                    </p>
                    {
                        holdDataService.loggedUserIsTutor() ? (
                            <span className='c-p' onClick={(event) => {
                                event.preventDefault();
                                this.removeStudent(index)
                            }}>
                                <FontAwesomeIcon className='icon' icon={faTimes} />
                            </span>
                        ) : null
                    }
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    Nessuno studente selezonato ...
                </p>
                <span />
            </div>;

        return studentsHTML;
    }

    sortStudents = (students) => {
        return students.sort((studA, studB) => {
            let a = studA.user_profile.lastName.toUpperCase();
            let b = studB.user_profile.lastName.toUpperCase();
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
    }

    addStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        if (this.state.searchValue !== '') {
            students = this.state.filteredStudents;
        }
        let student = students.splice(index, 1)[0];
        selectedStudents.push(student);
        this.setState({
            students: this.sortStudents(this.state.students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    removeStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        let student = selectedStudents.splice(index, 1)[0];
        students.push(student);
        this.setState({
            students: this.sortStudents(students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    updateGroup = () => {
        let students = this.state.selectedStudents;
        let groupUuid = this.props.group.group_uuid;
        let studentsUuids = [];
        students.map(s => studentsUuids.push(s.user_uuid));
        let query = '/groups/' + groupUuid + '/members';
        let postBody = {
            group_members: studentsUuids,
        }
        let originalGroupMembers = this.state.originalGroupMembers;
        let deleteBody = {
            group_members: originalGroupMembers
        };
        if (studentsUuids.length > 0) {
            //Elimino prima tutti i membri originali del gruppo per poi apportare le modifiche
            axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {
                    data: deleteBody,
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                        postBody,
                        {
                            auth: holdDataService.getAuthorization()
                        })
                        .then(res => {
                            this.props.loadTeams();
                            this.props.toggleAddUser();
                        }, err => {

                        });
                }, err => {

                });
        } else {
            // Elimino tutti gli utenti dal gruppo
            axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {
                    data: deleteBody,
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    this.props.loadTeams();
                    this.props.toggleAddUser();
                }, err => {

                });
        }
    }

    render() {
        return (
            <Modal className='edubba-modal add-user-modal'
                centered
                aria-labelledby="contained-modal-title-vcenter"
                size='lg'
                isOpen={this.props.modalAddUser}
                toggle={this.props.toggleAddUser}
                backdrop='static' >
                <ModalHeader toggle={this.props.toggleAddUser}>
                    <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                            <div className='widget-content-left mr-3'>
                                <FontAwesomeIcon className='icon' icon={faUsers} />
                            </div>
                            <div className='widget-content-left mr-3 text-center w-100'>
                                Dettaglio: {this.props.group.group_name}
                            </div>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row className='team-row'>
                            {
                                holdDataService.loggedUserIsTutor() ? (
                                    <Fragment>
                                        <Col lg='6' md='6' sm='6' className='text-center'>
                                            <div className='search-student mr-1 header'>
                                                <FontAwesomeIcon className='icon' icon={faSearch} />
                                                <input className=""
                                                    name='searchValue'
                                                    type="text"
                                                    placeholder='Cerca Studenti ...'
                                                    value={this.state.searchStudent}
                                                    onChange={this.handleChange} />
                                            </div>
                                            <div className='student-content'>
                                                <PerfectScrollbar>
                                                    {this.getAllStudentsHTML()}
                                                </PerfectScrollbar>
                                            </div>
                                        </Col>
                                        <Col lg='6' md='6' sm='6' className='text-center'>
                                            <div className='header'>
                                                STUDENTI SELEZIONATI
                                                </div>
                                            <div className='student-content'>
                                                <PerfectScrollbar>
                                                    {this.getSelectedStudentsHTML()}
                                                </PerfectScrollbar>
                                            </div>
                                        </Col>
                                    </Fragment>
                                ) : (
                                    <Col lg='12' md='12' sm='12' className='text-center'>
                                        <div className='header'>
                                            STUDENTI PRESENTI NEL GRUPPO
                                            </div>
                                        <div className='student-content'>
                                            <PerfectScrollbar>
                                                {this.getSelectedStudentsHTML()}
                                            </PerfectScrollbar>
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-edubba' onClick={this.props.toggleAddUser}>Chiudi</Button>
                    {
                        holdDataService.loggedUserIsTutor() ? (
                            <Button
                                className='btn-edubba'
                                onClick={this.updateGroup}>
                                Salva Modifiche
                            </Button>
                        ) : null
                    }
                </ModalFooter>
            </Modal>
        );
    }
}

class CreateNewTeamModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groupName: '',
            searchValue: '',
            filteredStudents: [],

            students: [], //per mockare degli studenti
            selectedStudents: [],
            // studentsLoading: true,
        };

    }

    componentDidMount() {
        //CHIAMRE API PER OTTENERE TUTTI GLI STUDENTI
        // this.setState({ students: this.props.students });
        this.loadStudents();
    }

    componentWillReceiveProps() {
        if (this.state.groupName !== '' || this.state.selectedStudents.length > 0) {
            // Resetto lo stato della modale
            this.setState({
                groupName: '',
                searchValue: '',
                filteredStudents: [],

                students: [], //per mockare degli studenti
                selectedStudents: [],
                studentsLoading: false,
            }, this.loadStudents)
        }
    }

    loadStudents = () => {
        let query = "/classrooms";
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let students = [];
                if (res.data.rowCount > 0) {
                    // students = res.data.rows;
                    res.data.rows.map(classrooms => {
                        classrooms.class_members.map(student => {
                            students.push(student);
                            return false;
                        });
                        return false;
                    });
                }
                this.setState({
                    students: students
                }, this.loadNotAddedStudents);
            }, err => {

            });
    }

    handleChange = (event) => {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
        }, () => {
            if (name === 'searchValue')
                this.setFilteredStudents();
        })
    }

    setFilteredStudents = () => {
        let filteredStudents = [];

        if (this.state.students.length > 0) {
            filteredStudents = this.state.students.filter(student =>
                // Filtro per nome
                (student.user_profile.firstName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // Filtro per cognome del gruppo
                (student.user_profile.lastName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // filtro per nome e cognome insieme
                (`${student.user_profile.firstName.toLowerCase()} ${student.user_profile.lastName.toLowerCase()}`.indexOf(this.state.searchValue.toLowerCase()) !== -1)
                //  ||
                // Filtro anche in base alla classe
                // (student.user_classrooms[0].class_name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1)
                // || (`Classe ${student.user_classrooms[0].class_name}`.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1)
            );
        }

        this.setState({
            filteredStudents: filteredStudents
        });
    }

    getAllStudentsHTML = () => {
        let students = this.state.students;
        // Se il campo di ricerca è attivo allora mostro gli studenti filtrati
        if (this.state.searchValue !== '') {
            students = this.state.filteredStudents;
        }
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b> <i>
                            {/* Classe {
                            student.user_classrooms[0].class_name
                            } */}
                        </i>
                    </p>
                    <span className='c-p' onClick={(event) => {
                        event.preventDefault();
                        this.addStudent(index)
                    }} >
                        <FontAwesomeIcon className='icon' icon={faPlus} />
                    </span>
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    {this.state.searchValue === '' ?
                        // Non sono presenti studenti
                        'Non ci sono studenti ...'
                        :
                        // Nessuno studente rispetta la ricerca
                        `Studente non trovato "${this.state.searchValue}"`
                    }
                </p>
                <span>
                    <FontAwesomeIcon className='icon text-warning' icon={faExclamationTriangle} />
                </span>
            </div>;

        return studentsHTML;
    }

    getSelectedStudentsHTML = () => {
        let students = this.state.selectedStudents;
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b> <i>
                            {/* Classe {student.user_classrooms[0].class_name} */}
                        </i>
                    </p>
                    <span className='c-p' onClick={(event) => {
                        event.preventDefault();
                        this.removeStudent(index)
                    }}>
                        <FontAwesomeIcon className='icon' icon={faTimes} />
                    </span>
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    Nessuno studente selezonato ...
                </p>
                <span />
            </div>;

        return studentsHTML;
    }

    sortStudents = (students) => {
        return students.sort((studA, studB) => {
            let a = studA.user_profile.lastName.toUpperCase();
            let b = studB.user_profile.lastName.toUpperCase();
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
    }

    addStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        if (this.state.searchValue !== '') {
            students = this.state.filteredStudents;
        }
        let student = students.splice(index, 1)[0];
        selectedStudents.push(student);
        this.setState({
            students: this.sortStudents(this.state.students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    removeStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        let student = selectedStudents.splice(index, 1)[0];
        students.push(student);
        this.setState({
            students: this.sortStudents(students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    createNewGroup = () => {
        let students = this.state.selectedStudents;
        let groupName = this.state.groupName;
        if (groupName !== '') {
            let studentsUuids = [];
            students.map(s => studentsUuids.push(s.user_uuid))
            let query = '/groups';
            let postBody = {
                group_name: groupName,
                group_members: studentsUuids,
            }
            this.props.toggleAddTeam();
            this.props.creatingGroup(true);
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                postBody,
                {
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    this.props.creatingGroup(false);
                }, err => {

                });
        }
    }

    render() {
        return (
            <Modal className='edubba-modal new-group-modal'
                centered
                aria-labelledby="contained-modal-title-vcenter"
                size='lg'
                isOpen={this.props.modalAddTeam}
                toggle={this.props.toggleAddTeam}
                backdrop='static'
            >
                <ModalHeader toggle={this.props.toggleAddTeam}>
                    <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                            <div className='widget-content-left mr-3'>
                                <FontAwesomeIcon className='icon' icon={faUsers} />
                            </div>
                            <div className='widget-content-left mr-3 text-center w-100'>
                                Creazione di un nuovo Gruppo
                            </div>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {this.state.studentsLoading ?
                        <EdubbaLoader />
                        :
                        <Container>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-center color-edubba'>
                                    <div className='name'>
                                        NOME GRUPPO
                                    </div>
                                    <input className="white-row"
                                        name='groupName'
                                        type="text"
                                        placeholder='Nome del Gruppo ...'
                                        autoComplete='off'
                                        value={this.state.groupName}
                                        onChange={this.handleChange} />
                                </Col>
                            </Row>
                            <Row className='team-row'>
                                <Col lg='6' md='6' sm='6' className='text-center'>
                                    <div className='search-student mr-1 header'>
                                        <FontAwesomeIcon className='icon' icon={faSearch} />
                                        <input className=""
                                            name='searchValue'
                                            type="text"
                                            placeholder='Cerca Studenti ...'
                                            value={this.state.searchValue}
                                            onChange={this.handleChange} />
                                    </div>
                                    <div className='student-content'>
                                        <PerfectScrollbar>
                                            {this.getAllStudentsHTML()}
                                        </PerfectScrollbar>
                                    </div>
                                </Col>
                                <Col lg='6' md='6' sm='6' className='text-center'>
                                    <div className='header'>
                                        STUDENTI SELEZIONATI
                                    </div>
                                    <div className='student-content'>
                                        <PerfectScrollbar>
                                            {this.getSelectedStudentsHTML()}
                                        </PerfectScrollbar>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-edubba' onClick={this.props.toggleAddTeam}>Annulla</Button>
                    <Button
                        className='btn-edubba'
                        onClick={this.createNewGroup}>
                        Crea Gruppo
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withRouter(Teams);
