import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './../News.scss';

class Monitoraggio_utilizzo_edubba_16062021 extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="ContentRoot_Data">
                <div className="ContentRoot_Subject">
                    OGGETTO: “Monitoraggio Utilizzo della Piattaforma”.
                </div>
                <div className="ContentRoot_Body">
                Si invitano le scuole partecipanti al <b>"Progetto Databenc Art"</b> a contribuire al monitoraggio del progetto, che prevede un questionario su modulo Google relativo ai dati della scuola (da compilare a cura dei referenti di progetto), un questionario Tutor (anonimo), un questionario studenti (anonimo), disponibili ai seguenti link:<br />
                <br />
                <ul>
                    <li>
                        <a href="https://forms.gle/1ZZcoQ7WFdtwRYBv8" target="_blank" rel="noopener noreferrer">
                            Questionario Dati Istituzione scolastica.
                        </a>
                    </li>
                    <li>
                        <a href="https://forms.gle/fXYZqdXXPwmpK9D86" target="_blank" rel="noopener noreferrer">
                            Questionario Tutor.
                        </a>
                    </li>
                    <li>
                        <a href="https://forms.gle/obihRnJEYSsLAbBc6" target="_blank" rel="noopener noreferrer">
                            Questionario Studenti.
                        </a>
                    </li>
                </ul>
                <br />
                La partecipazione al questionario non ha carattere valutativo ma concorre al miglioramento del progetto.<br />
                I link resteranno aperti fino a fine giugno.<br />
                <br />
                Si ringraziano docenti e studenti per la fattiva collaborazione!
                </div>
            </div>
        )
    }
}

export default withRouter(Monitoraggio_utilizzo_edubba_16062021);
