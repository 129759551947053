import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Collapse,
    Button,
    Input
} from 'reactstrap';
import {
    faSearch, faFilter
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import globalVars from '../../globalVars.json';

import './Metrics.scss';

class StatMetrics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maskLoading: false,
            currentProjects: [],
            currentStats: [],
            isFilterOpen: false,

            //----------------------------------------------------------------|

            startDate: null,
            endDate: null,
            istituteNameFilter: null,

            //----------------------------------------------------------------|

            currentQueryPage: 1,
            currentQueryLimit: 25,
            currentQueryCount: 25,

            //----------------------------------------------------------------|

            tableOptions: {
                "institute_name":      "Istituto",
                "total_work_time_str": "Tot. Ore Lavorate",
                "total_work_projects": "Tot. Progetti",
                "total_work_users":    "Tot. Utenti"
            }
        };
    }

    componentDidMount() {
        let setTitle = 'Dashboard - Statistiche per Attività';
        this.props.setSectionTitle(setTitle);

        this.loadWorkStats();
    }

    loadWorkStats() {
        this.setState({ maskLoading: true });
        let query = "?page=" + this.state.currentQueryPage + "&limit=" + this.state.currentQueryLimit;
        let body  = {};

        // Inject filter
        if (this.state.istituteNameFilter) {
            body = {
                ...body,
                ...{ "institute_name": this.state.istituteNameFilter}
            };
        }
        if (this.state.startDate) {
            body = {
                ...body,
                ...{ "year_start": this.state.startDate}
            };
        }
        if (this.state.endDate) {
            body = {
                ...body,
                ...{ "year_end": this.state.endDate}
            };
        }

        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/stats/activities" + query, body, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentQueryCount: res.data.rowCount,
                    currentStats: res.data.rows,
                    maskLoading: false
                });
            }
            else this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        });
    }

    prepareHeaders() {
        let rawData    = [<th key={"tr-head-th-" + 0}>#</th>];
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < tableOpts.length; i++) {
            let tmpHeaderLabel = rawOptions[tableOpts[i]];
            tmpHeaderLabel = (
                typeof rawOptions[tableOpts[i]] === "function" ?
                rawOptions[tableOpts[i]]() :
                rawOptions[tableOpts[i]]
            );

            rawData.push(
                <th key={"tr-head-th-" + (i + 1)}>{tmpHeaderLabel}</th>
            );
        }

        rawData.push(
            <th key={"tr-head-th-" + (tableOpts.length + 1)}>Azioni</th>
        );

        return (<tr key={"tr-head-" + 0}>{rawData}</tr>);
    }

    prepareRows() {
        let rawData    = [];
        let rawRecords = this.state.currentStats;
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < rawRecords.length; i++) {
            let data   = rawRecords[i];
            let tmpRow = [<td key={"tr-body-" + i + "-td-" + 0}>{i + 1}</td>];

            if (!(data["institute_uuid"])) {
                continue;
            }

            for (let n = 0; n < tableOpts.length; n++) {
                let tmpNodeValue = data[tableOpts[n]];
                tmpNodeValue = tmpNodeValue ? tmpNodeValue : "- - -";
                tmpRow.push(
                    <td key={"tr-body-" + i + "-td-" + (n + 1)}>{tmpNodeValue}</td>
                );
            }

            tmpRow.push(
                <td key={"tr-body-" + i + "-td-" + (tableOpts.length + 1)}>
                    <div className='bottom-icons'>
                        <Link to={"/admin/stats/workd/" + data["institute_uuid"]}>
                            <div
                                data-tip data-for={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={faSearch}
                                />
                                <ReactTooltip
                                    id={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}
                                    wrapper="span"
                                    place="left"
                                    effect="solid"
                                    className="tooltip-work">
                                    Visualizza Dettaglio di Istituto
                                </ReactTooltip>
                            </div>
                        </Link>
                    </div>
                </td>
            );
            rawData.push(<tr key={"tr-body-" + i}>{tmpRow}</tr>);
        }

        return rawData;
    }

    preparePaginationItems() {
        // Prepare data
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);
        let items      = [];

        // Prepare limits
        let firstMin   = 1;
        let lastMax    = countPage;

        // Check range
        if (countPage > 8) {
            // Specifies range of items in the middle
            firstMin = Math.max(active - 2, 1);
            lastMax  = Math.min(active + 2, countPage);

            // Normalize
            if (active === 1) {
                lastMax += 2;
            }
            // Normalize
            if (active === 2) {
                lastMax += 1;
            }
            else if (active === (countPage - 1)) {
                firstMin-=1;
            }
            else if (active === countPage) {
                firstMin-=2;
            }
        }

        // Push forst separator
        if (firstMin > 1) {
            items.push(
                <PaginationItem key={"firstMinSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Loop on item
        for (let number = firstMin; number <= lastMax; number++) {
            // Save item
            items.push(
                <PaginationItem key={number} active={number === active}>
                    <PaginationLink onClick={() => this.changePage(number)}>
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Push last separator
        if (lastMax < countPage) {
            items.push(
                <PaginationItem key={"lastMaxSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Send back
        return items;
    }

    changePage(toPage) {
        this.setState({
            currentStats: [],
            currentQueryPage: toPage
        },
        () => {
            this.loadWorkStats();
        });
    }

    changePageByStep(toPage) {
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);

        switch (toPage) {
            case "first":
                active = 1;
                break;

            case "back":
                active = ((active - 1) >= 1) ? (active - 1) : 1;
                break;

            case "next":
                active = ((active + 1) <= countPage) ? (active + 1) : active;
                break;

            case "last":
                active = countPage;
                break;

            default: break;
        }

        this.setState({
            currentStats: [],
            currentQueryPage: active
        },
        () => {
            this.loadWorkStats();
        });
    }

    toggleFilter() {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen
        });
    }

    handleChangeIstituteName= (ev) => {
        this.setState({
            istituteNameFilter: (ev.target && ev.target.value) ? ev.target.value : null
        });
    }

    handleChangeStart = (ev) => {
        this.setState({
            startDate: (ev.target && ev.target.value) ? ev.target.value : null
        });
    }

    handleChangeEnd = (ev) => {
        this.setState({
            endDate: (ev.target && ev.target.value) ? ev.target.value : null
        });
    }

    clearFilter = () => {
        this.setState({
            isFilterOpen: false,
            currentQueryPage: 1,
            istituteNameFilter: null,
            startDate: null,
            endDate: null
        },
        () => {
            this.loadWorkStats();
        });
    }

    applyFilter = () => {
        this.setState({
            isFilterOpen: false,
            currentQueryPage: 1
        },
        () => {
            this.loadWorkStats();
        });
    }

    render() {
        return (
            <Container className='WorkStats' active-page={this.state.currentQueryPage}>
                {
                    this.state.maskLoading ?
                        <EdubbaLoader />
                    : <Fragment>
                        <Row key="head-stat-1">
                            <Col lg='12' md='12' sm='12' className="HeadStat">
                                Seleziona Istituto:
                                <Button
                                    data-tip data-for={"TIPS_FOR_BTN_ACTION_FILTER"}
                                    color="secondary"
                                    onClick={() => this.toggleFilter()}
                                    style={{
                                        position: "relative",
                                        float: "right",
                                        marginTop: '12px'
                                    }}>
                                    <FontAwesomeIcon icon={faFilter} />
                                    <ReactTooltip
                                        id={"TIPS_FOR_BTN_ACTION_FILTER"}
                                        wrapper="span"
                                        place="left"
                                        effect="solid"
                                        className="tooltip-work">
                                        Mostra/Nascondi i filtri di visualizzazione
                                    </ReactTooltip>
                                </Button>
                                <Collapse isOpen={this.state.isFilterOpen}>
                                    <Container className="WorkStats-Filter">
                                        <Row key="body-filter">
                                            <Col lg='4' md='4' sm='4'>
                                                Cerca per nome Istituto:<br />
                                                <Input
                                                    type="text"
                                                    name="institute_name"
                                                    defaultValue={
                                                        this.state.istituteNameFilter ?
                                                        this.state.istituteNameFilter
                                                        : ""
                                                    }
                                                    onChange={this.handleChangeIstituteName}>
                                                </Input>
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Anno Inizio:<br />
                                                <Input
                                                    type="number"
                                                    name="dataInizio"
                                                    min="1900"
                                                    max="9999"
                                                    minLength="4"
                                                    maxLength="4"
                                                    size="4"
                                                    defaultValue={
                                                        this.state.startDate ?
                                                        this.state.startDate
                                                        : ""
                                                    }
                                                    onChange={this.handleChangeStart}
                                                    >
                                                </Input>
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Anno Fine:<br />
                                                <Input
                                                    type="number"
                                                    name="dataFine"
                                                    min="1900"
                                                    max="9999"
                                                    minLength="4"
                                                    maxLength="4"
                                                    size="4"
                                                    defaultValue={
                                                        this.state.endDate ?
                                                        this.state.endDate
                                                        : ""
                                                    }
                                                    onChange={this.handleChangeEnd}>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row key="footer-filter">
                                            <Col lg='4' md='4' sm='4'>
                                                <br />
                                                <Button
                                                    color="danger"
                                                    onClick={() => this.clearFilter()}
                                                    disabled={
                                                        this.state.startDate === null &&
                                                        this.state.endDate === null &&
                                                        this.state.istituteNameFilter === null
                                                    }>
                                                    RIMUOVI FILTRI
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    color="success"
                                                    onClick={() => this.applyFilter()}
                                                    disabled={(
                                                        this.state.startDate === null ||
                                                        this.state.startDate < 1900 ||
                                                        this.state.startDate > 9999) && (
                                                        this.state.endDate === null ||
                                                        this.state.endDate < 1900 ||
                                                        this.state.endDate > 9999) &&
                                                        this.state.istituteNameFilter === null
                                                    }>
                                                    APPLICA FILTRI
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row key="body-stat">
                            <Col lg='12' md='12' sm='12'>
                                <Table className="WorkStats-Table" responsive striped>
                                    <thead>{this.prepareHeaders()}</thead>
                                    <tbody>{this.prepareRows()}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row key="pages-stat">
                            <Col lg='12' md='12' sm='12' className="ToolStat-Pagination">
                                <Pagination>
                                    <PaginationItem>
                                        <PaginationLink first onClick={() => this.changePageByStep("first")} />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink previous onClick={() => this.changePageByStep("back")} />
                                    </PaginationItem>

                                    {this.preparePaginationItems()}

                                    <PaginationItem>
                                        <PaginationLink next onClick={() => this.changePageByStep("next")} />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink last onClick={() => this.changePageByStep("last")} />
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </Fragment>
                }
            </Container>
        );
    }
};

export default withRouter(StatMetrics);
