import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Alert
} from 'reactstrap';
import {
    faDownload,
    faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Help.scss';

class Help extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tutorials: [],
            activeStaticContent: null
        };

        this.staticContentCodes = [
            "pdf_guida_per_docenti_03032021",
            "pdf_guida_per_studenti_03032021"
        ];

        this.staticContentObjects = {
            "pdf_guida_per_docenti_03032021": {
                icon:     faQuestionCircle,
                title:    "Guida per Docenti",
                author:   "Amministrazione",
                url:      "/downloads/pdf/tutorials/EDUBBA_Teachers.pdf",
                date:     "03/03/2021"
            },
            "pdf_guida_per_studenti_03032021": {
                icon:     faQuestionCircle,
                title:    "Guida per Studenti",
                author:   "Amministrazione",
                url:      "/downloads/pdf/tutorials/EDUBBA_Students.pdf",
                date:     "03/03/2021"
            }
        };
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'visibilities';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';

        let setTitle = "Guide all'uso della Piattaforma";
        this.props.setSectionTitle(setTitle);
        this.loadTutorials();
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    loadTutorials = () => {
        let tmpHelp = this.staticContentCodes.map((code) => {
            return {
                "code": code,
                ...this.staticContentObjects[code]
            };
        });

        this.setState({
            tutorials: tmpHelp
        });
    }

    render() {
        return (
            <Fragment>
                <Container className='Help mt-5 mb-5'>
                    <Row>
                        <Col lg='12' md='12' sm='12'>
                            <Alert color="light">
                                <h4 className="alert-heading">Contatti per Supporto:</h4>
                                <hr />
                                <p>
                                    <br />
                                    <h5>Per mezzo Mail</h5>
                                    <ul>
                                        <li><b>Supporto/Segnalazioni: </b> <a className="support-link-footer" href="mailto:support.edubba@databenc.it">support.edubba@databenc.it</a></li>
                                    </ul>
                                    <br />
                                    <h5>Per mezzo Skype</h5>
                                    <ul>
                                        <li><b>Invito Skype: </b> <a className="support-link-footer" href="https://join.skype.com/invite/BYwqFomOKh9T" target="_blank" rel="noopener noreferrer">https://join.skype.com/invite/BYwqFomOKh9T</a></li>
                                        <li><b>Disponibilità: </b> Martedi e Giovedì dalle ore 10:00 alle 12:30</li>
                                    </ul>
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                    {
                        this.state.tutorials.length > 0 ?
                            <Fragment>
                                <Row>
                                    <Col lg='12' md='12' sm='12'>
                                        <div className="list-help-grid">
                                            {
                                                this.state.tutorials.map((obj) => {
                                                    return (
                                                        <Card key={obj.code}>
                                                            <div className="cadashboard-table">
                                                                <div className="cadashboard-table-cell">
                                                                    <FontAwesomeIcon icon={obj.icon} />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="card-status"
                                                                style={{
                                                                    backgroundColor: "#193d75",
                                                                    color: "#ffffff"
                                                                }}>
                                                                GUIDA ALL'USO
                                                            </div>
                                                            <CardBody>
                                                                <CardTitle>
                                                                    {obj.title}
                                                                </CardTitle>
                                                                <CardText>
                                                                    <small className="text-muted">
                                                                        <b>Autore:</b> {obj.author}
                                                                    </small>
                                                                    <br />
                                                                    <small className="text-muted">
                                                                        <b>Creata:</b> {obj.date}
                                                                    </small>
                                                                </CardText>
                                                            </CardBody>
                                                            <div className={'bottom-icons collapse-single-fr'}>
                                                                <Link to={obj.url} target="_blank" download>
                                                                    <div
                                                                        data-tip data-for={"TIPS_FOR_E_" + obj.code}>
                                                                        <FontAwesomeIcon
                                                                            className='icon'
                                                                            icon={faDownload}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_E_" + obj.code}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                            Premere per scaricare la guida allegata.
                                                                        </ReactTooltip>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </Card>
                                                    );
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        : (
                            <Row>
                                <Col lg='12' md='12' sm='12'>
                                    <Alert color="light" className="mt-5">
                                        <h4 className="alert-heading">Nessuna guida ancora disponibile!</h4>
                                        <p>
                                            Non sono ancora state create guide da poter visionare.<br />
                                            Appena saranno disponibili saranno elencate nella sezione corrente.
                                        </p>
                                    </Alert>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </Fragment>
        )
    }
}

export default withRouter(Help);
