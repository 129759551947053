import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from '../../globalVars.json';
import {
    Row,
    Col,
    Container,
    Input,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert
} from 'reactstrap';
import { faPlusCircle, faTrash, faClipboardList, faQuestionCircle, faUser, faUsers, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import holdDataService from '../../services/holdDataService/holdDataService';
import ReactTooltip from 'react-tooltip';
import RichTextEditor from './../../services/richTextEditor/RichTextEditor';
import ReactstrapTagsInput from './../../services/reactstrapTagsInput/ReactstrapTagsInput';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../../assets/utils/images/edubba_placeholder.png'
import moment from 'moment';

import {
    Map,
    TileLayer,
    LayerGroup,
    LatLng,
    Marker,
    Routing,
    LatLngBounds,
    Icon,
} from 'leaflet';
import 'leaflet-routing-machine';

import './../../../node_modules/leaflet/dist/leaflet.css';
import './../../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css';
import './TourEnrichment.scss';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';

//-------------------------------------------------------------------------------------------------------| HACK-FIX: MARKER ICON
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('./../../../node_modules/leaflet/dist/images/marker-icon-2x.png'),
    iconUrl:       require('./../../../node_modules/leaflet/dist/images/marker-icon.png'),
    shadowUrl:     require('./../../../node_modules/leaflet/dist/images/marker-shadow.png')
});
//-------------------------------------------------------------------------------------------------------| HACK-FIX: MARKER ICON

class TourEnrichment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingState: true,
            tourReadOnly: true,
            modalCreateLap: false,
            selectedTour: null,
            selectedEnrich: null,
            activeTourTab: "map",
            sortableItems: [],
            mapMarkers: [],
            tourNarration: "",
            tourNarrationBase: "",
            tourTags: [],
            lockedTags: [],
            tourTitle: null,
            tourImageUUID: null,
            tourImageURL: null,
            tourStatus: null,
            selectedLap: null,
            lockedCards: []
        };

        // Handler
        this.leafletMap    = null;
        this.leafletRouter = null;
        this.leafletMarker = [];
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'tour-enrichment-steps';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';
    }

    componentWillMount() {
        if (this.props.match.params.tour_uuid &&
            this.props.match.params.enrich_uuid) {
            this.setState({
                selectedTour:   this.props.match.params.tour_uuid,
                selectedEnrich: this.props.match.params.enrich_uuid
            }, () => {
                this.loadTourData();
            });
        }
        else this.props.history.push("/");
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    toggleTab = (tabId) => {
        if (this.state.activeTourTab !== tabId) {
            this.setState({
                activeTourTab: tabId
            });
        }
    }

    loadTourData = (lapUpdate = false) => {
        this.setState({loadingState: true});
        let query = '/tours/' + this.state.selectedTour + "/enrichments/" + this.state.selectedEnrich;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                // only laps
                if (lapUpdate) {
                    this.setState({
                        loadingState: false,
                        tourReadOnly: (
                            (
                                holdDataService.loggedUserIsStudent() &&
                                res.data.rows[0]["status_code"] === "FW_ATER_0001"
                            ) ? false :
                            true
                        ),
                        sortableItems: (
                            Array.isArray(res.data.rows[0]["enrich_laps"]) ?
                            res.data.rows[0]["enrich_laps"] : []
                        ),
                        mapMarkers: (
                            Array.isArray(res.data.rows[0]["enrich_laps"]) ?
                            res.data.rows[0]["enrich_laps"].map((lap) => {
                                return (
                                    lap["lap_data"] &&
                                    lap["lap_data"]["gps_location"] &&
                                    lap["lap_data"]["gps_location"]["latitude"] &&
                                    lap["lap_data"]["gps_location"]["longitude"]
                                ) ? [
                                    lap["lap_data"]["gps_location"]["latitude"],
                                    lap["lap_data"]["gps_location"]["longitude"]
                                ] : null
                            }).filter(x => x) : []
                        )
                    }, () => {
                        this.spawnMapMarkers(this.state.mapMarkers);
                        this.extractLockedCards(
                            this.state.sortableItems
                        );
                    });
                }
                // all tour
                else {
                    this.setState({
                        loadingState: false,
                        tourReadOnly: (
                            (
                                holdDataService.loggedUserIsStudent() &&
                                res.data.rows[0]["status_code"] === "FW_ATER_0001"
                            ) ? false :
                            true
                        ),
                        tourStatus:        res.data.rows[0]["status_code"],
                        tourTitle:         res.data.rows[0]["tour_title"],
                        tourNarration:     res.data.rows[0]["enrich_description"],
                        tourNarrationBase: res.data.rows[0]["tour_description"],
                        tourImageUUID:     res.data.rows[0]["tour_image_uuid"],
                        tourImageURL:      res.data.rows[0]["tour_image"],
                        tourTags: (
                            res.data.rows[0]["enrich_data"] &&
                            Array.isArray(res.data.rows[0]["enrich_data"].tags) ?
                            res.data.rows[0]["enrich_data"].tags : []
                        ),
                        lockedTags: (
                            res.data.rows[0]["tour_data"] &&
                            Array.isArray(res.data.rows[0]["tour_data"].tags) ?
                            res.data.rows[0]["tour_data"].tags : []
                        ),
                        sortableItems: (
                            Array.isArray(res.data.rows[0]["enrich_laps"]) ?
                            res.data.rows[0]["enrich_laps"] : []
                        ),
                        mapMarkers: (
                            Array.isArray(res.data.rows[0]["enrich_laps"]) ?
                            res.data.rows[0]["enrich_laps"].map((lap) => {
                                return (
                                    lap["lap_data"] &&
                                    lap["lap_data"]["gps_location"] &&
                                    lap["lap_data"]["gps_location"]["latitude"] &&
                                    lap["lap_data"]["gps_location"]["longitude"]
                                ) ? [
                                    lap["lap_data"]["gps_location"]["latitude"],
                                    lap["lap_data"]["gps_location"]["longitude"]
                                ] : null
                            }).filter(x => x) : []
                        )
                    }, () => {
                        // Reinit maps
                        this.initLeafLetLogic();
                        this.extractLockedCards(
                            this.state.sortableItems
                        );
                    });
                }
            }
            else {
                this.setState({
                    loadingState: false
                });
            }
        },
        err => {
            this.setState({
                loadingState: false
            });
        });
    }

    extractLockedCards = (laps) => {
        if (Array.isArray(laps)) {
            let locked = [];
            laps.map((lap) => {
                if (Array.isArray(lap.lap_cards)) {
                    lap.lap_cards.map((card) => {
                        locked.push(card.enrich_uuid);
                        return null;
                    });
                }

                return null;
            });

            this.setState({
                lockedCards: locked
            });
        }
    }

    modifyRemoteTour = (is_draft = true, is_rejected = false) => {
        this.setState({ loadingState: true });
        const query = '/tours/' + this.state.selectedTour + '/enrichments/' + this.state.selectedEnrich;
        const body = {
            enrich_descr: this.state.tourNarration,
            enrich_data: {
                tags: this.state.tourTags
            },
            enrich_is_draft: is_draft,
            enrich_is_rejected: is_rejected
        };
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, body,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data.rowCount > 0) {
                this.props.history.push("/tasks/todo");
            }
            else this.setState({
                loadingState: false
            });
        }, err => {
            this.setState({
                loadingState: false
            });
        });
    }

    deleteRemoteLap = (lap_uuid) => {
        this.setState({loadingState: true});
        let query = '/tours/' + this.state.selectedTour + "/enrichments/" + this.state.selectedEnrich + "/laps/" + lap_uuid;
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(
            res => {
                this.setState({
                    loadingState: false
                }, () => {
                    this.loadTourData(true);
                });
            },
            err => {
                this.setState({
                    loadingState: false
                }, () => {
                    this.loadTourData(true);
                });
            }
        );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE MAPPA
    ///////////////////////////////////////////////////////////////////////////////////////////////

    initLeafLetLogic = () => {
        this.leafletMap = new Map(
            this.refs.entrymap,
            {
                zoom: 10,
                inertia: true,
                inertiaDeceleration: 2000
            }
        );

        new TileLayer(
            globalVars.Protocol + '://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
            {
                attribution: 'CARTO'
            }
        )
        .addTo(this.leafletMap);

        // Add the group to the map
        this.leafletMarker = new LayerGroup();
        this.leafletMap.addLayer(this.leafletMarker);

        // Add router to the map
        this.leafletRouter = new Routing.control({
            waypoints: [],
            routeWhileDragging: false,
            draggable: false,
            addWaypoints: false,
            draggableWaypoints: false,
            show: false,
            collapsible: true
        })
        .addTo(this.leafletMap);

        // Spawn all
        this.spawnMapMarkers(this.state.mapMarkers);
    }

    spawnMapMarkers = (mapMarkers) => {
        this.leafletMarker.clearLayers();

        if (mapMarkers.length <= 0) {
            navigator.geolocation.getCurrentPosition(
                (location) => {
                    let latlng = new LatLng(
                        location.coords.latitude,
                        location.coords.longitude
                    );
                    this.leafletMap.setView(
                        latlng,
                        8,
                        {animation: true}
                    );
                },
                (err) => {
                    console.error(err);
                    this.leafletMap.setView(
                        [
                            40.83597000,
                            14.24868000
                        ],
                        8,
                        {animation: true}
                    );
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        }
        else {
            const iconMarker = new Icon({
                iconSize:    [25, 41],
                iconAnchor:  [12, 41],
                popupAnchor: [1, -34],
                shadowSize:  [41, 41],
                iconUrl:     globalVars.LeafLet.APIMarkerIcon,
                shadowUrl:   globalVars.LeafLet.APIMarkerShadow
            });

            let tmpWaypoints = mapMarkers.map((coordy) => {
                let marker = new Marker(coordy, { icon: iconMarker, draggable: false });
                this.leafletMarker.addLayer(marker);
                return new LatLng(coordy[0], coordy[1]);
            });

            if (tmpWaypoints.length > 1) {
                this.leafletRouter.setWaypoints(tmpWaypoints);
            }
            else {
                this.leafletRouter.setWaypoints([]);
            }

            const bounds = new LatLngBounds(mapMarkers);
            this.leafletMap.fitBounds(bounds);
            this.leafletMap.invalidateSize();
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE TOUR
    ///////////////////////////////////////////////////////////////////////////////////////////////

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getTourImage = (tour_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(tour_image)) {
            coreImage = tour_image;
        }

        return coreImage;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    handleEditorChange = (value) => {
        this.setState({
            tourNarration: value
        });
    }

    handleTagsChange = (value) => {
        this.setState({
            tourTags: value
        });
    }

    toggleModalCreateLap = (state, payload = null) => {
        this.setState({
            modalCreateLap: state,
            selectedLap: state ? payload : null
        });
    }

    handleLapSubmit = (newLapData) => {
        // reload only laps
        this.loadTourData(true);
    }

    upsertLockedCards = (cardUUID) => {
        if (cardUUID) {
            if (this.state.lockedCards.includes(cardUUID)) {
                let tmpArray = this.state.lockedCards;
                const index = tmpArray.indexOf(cardUUID);
                tmpArray.splice(index, 1);

                this.setState({
                    lockedCards: tmpArray
                });
            }
            else {
                this.setState({
                    lockedCards : [
                        ...this.state.lockedCards,
                        cardUUID
                    ]
                });
            }
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.loadingState ?
                    <EdubbaLoader /> : null
                }
                <Container className='step-navigator-container'>
                    <Row>
                        <Col lg='4' md='4' sm='4' className='step-navigator-left'>
                            <div className='form-sep-input-label'>
                                Percorso in Arricchimento:
                            </div>
                            <Input
                                type="text"
                                className=''
                                name='tourTitle'
                                value={this.state.tourTitle ? this.state.tourTitle : ""}
                                readOnly={true}
                                style={{backgroundColor: "#fff", cursor: "not-allowed"}}
                            />
                            <div className="TourImgPreview">
                                <img src={
                                    this.getTourImage(
                                        this.state.tourImageURL
                                    )
                                }
                                onError={this.setDefaultImgSrc}
                                alt="" />
                            </div>
                            <div className='form-sep-input-label'>
                                Tags da Associare come Arricchimento:
                            </div>
                            <ReactstrapTagsInput
                                className=''
                                onChange={this.handleTagsChange}
                                locked={this.state.lockedTags}
                                lockedIcon={faUser}
                                value={this.state.tourTags}
                                valueIcon={faUsers}
                                readOnly={this.state.tourReadOnly}
                            />
                            <div className='form-sep-input-label'>
                                Tappe del percorso in Arricchimento:
                            </div>
                            <ul style={{
                                padding: '0em 0em 0em 0em'
                            }}>{
                                this.state.sortableItems.length > 0 ||
                                !this.state.tourReadOnly ? (
                                    this.state.sortableItems.map((item, index) => {
                                        return (
                                            <li className="ListMovableItems" key={"li-" + index}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                    <div>
                                                        <span className="indexItem">{index + 1}</span>
                                                        {item.lap_title ? item.lap_title : "- - -"}
                                                    </div>
                                                    {' '}
                                                    <div>
                                                        <Button
                                                            onClick={() => this.toggleModalCreateLap(true, item)}>
                                                            <FontAwesomeIcon icon={faClipboardList} className='icon' />
                                                        </Button>
                                                        {
                                                            !this.state.tourReadOnly ?
                                                                <Button
                                                                    style={{marginLeft: "10px"}}
                                                                    onClick={() => this.deleteRemoteLap(item.lap_uuid)}>
                                                                    <FontAwesomeIcon icon={faTrash} className='icon' />
                                                                </Button>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                ) : (
                                    <div className="ListMovableItemsAdd" style={{cursor: "default"}}>
                                        <FontAwesomeIcon icon={faQuestionCircle} className='icon' /> &nbsp; Nessuna tappa presente nel percorso!
                                    </div>
                                )
                            }</ul>
                            {
                                !this.state.tourReadOnly ?
                                    <div className="ListMovableItemsAdd" onClick={() => this.toggleModalCreateLap(true, {
                                        lap_index: this.state.sortableItems.length > 0 ? (this.state.sortableItems.length + 1) : null
                                    })}>
                                        <FontAwesomeIcon icon={faPlusCircle} className='icon' /> &nbsp; Aggiungi nuova Tappa
                                    </div>
                                : null
                            }
                            {
                                holdDataService.loggedUserIsStudent() &&
                                !this.state.tourReadOnly ? (
                                    <Row className="form-sep-input-btns" style={{height: "initial"}}>
                                        <Col lg='12' md='12' sm='12'>
                                            <Button size="lg"
                                                color="secondary"
                                                style={{width: "100%"}}
                                                disabled={
                                                    this.isEmpty(this.state.tourNarration) ||
                                                    this.isEmpty(this.state.tourTags)
                                                }
                                                onClick={() => this.modifyRemoteTour(true, false)}>
                                                Salva Bozza del Percorso
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : null
                            }
                            <Row className={(
                                holdDataService.loggedUserIsStudent() &&
                                !this.state.tourReadOnly
                            ) ? "" : "form-sep-input-btns"} style={{height: "initial"}}>
                                <Col lg='6' md='6' sm='6'>
                                    {
                                        holdDataService.loggedUserIsTutor() &&
                                        this.state.tourStatus === "FW_ATER_0002" ? (
                                            <Button size="lg"
                                                color="danger"
                                                style={{width: "100%"}}
                                                onClick={() => this.modifyRemoteTour(false, true)}>
                                                Rifiuta il Percorso
                                            </Button>
                                        ) : (
                                            <Button size="lg"
                                                color="danger"
                                                style={{width: "100%"}}
                                                onClick={() => this.props.history.push("/tasks/todo")}>
                                                Annulla
                                            </Button>
                                        )
                                    }
                                </Col>
                                <Col lg='6' md='6' sm='6'>
                                    {
                                        holdDataService.loggedUserIsStudent() &&
                                        !this.state.tourReadOnly ? (
                                            <Button size="lg"
                                                color="success"
                                                style={{width: "100%"}}
                                                disabled={
                                                    this.isEmpty(this.state.tourNarration) ||
                                                    this.isEmpty(this.state.tourTags)
                                                }
                                                onClick={() => this.modifyRemoteTour(false, false)}>
                                                Salva e Invia al Docente
                                            </Button>
                                        )
                                        : holdDataService.loggedUserIsTutor() &&
                                          this.state.tourStatus === "FW_ATER_0002" ? (
                                            <Button size="lg"
                                                color="success"
                                                style={{width: "100%"}}
                                                onClick={() => this.modifyRemoteTour(false, false)}>
                                                Valida il Percorso
                                            </Button>
                                        )
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col lg='8' md='8' sm='8' className='step-navigator-right'>
                            <Nav tabs className="NavTabContent">
                                <NavItem>
                                    <NavLink
                                        className={(this.state.activeTourTab === 'map') ? 'active' : '' }
                                        onClick={() => this.toggleTab('map')}>
                                        Mappa delle Tappe
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={(this.state.activeTourTab === 'narration') ? 'active' : '' }
                                        onClick={() => this.toggleTab('narration')}>
                                        Traccia Docente
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={(this.state.activeTourTab === 'editor') ? 'active' : '' }
                                        onClick={() => this.toggleTab('editor')}>
                                        Narrazione Studente
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTourTab} className="MapSelectorContent">
                                <TabPane tabId="narration" className="RichEditorPane">
                                    <Row>
                                        <Col lg='12' md='12' sm='12'>
                                            <RichTextEditor
                                                name="tourNarrationBase"
                                                className="RichTextEditor_Fix"
                                                value={this.state.tourNarrationBase ? this.state.tourNarrationBase : ""}
                                                placeHolder="Nessuna traccia prevista per il percorso!"
                                                readOnly={true}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="map" className="MapSelectorPane">
                                    <div className="map-container">
                                        <div id="leafletmap" ref="entrymap"></div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="editor" className="RichEditorPane">
                                    <Row>
                                        <Col lg='12' md='12' sm='12'>
                                            <RichTextEditor
                                                name="tourNarration"
                                                className="RichTextEditor_Fix"
                                                value={this.state.tourNarration ? this.state.tourNarration : ""}
                                                onChange={this.handleEditorChange}
                                                placeHolder="Inserire la narrazione del percorso..."
                                                readOnly={this.state.tourReadOnly}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
                {/* v CONFIRM: modalCreateLap v */}
                <Modal
                    className='edubba-modal tour-enrich'
                    size="lg"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    isOpen={this.state.modalCreateLap}
                    toggle={() => this.toggleModalCreateLap(false)}>
                    <ModalCreateLap
                        lapData={this.state.selectedLap}
                        toggleModal={this.toggleModalCreateLap}
                        isEmpty={this.isEmpty}
                        lockedCards={this.state.lockedCards}
                        upsertLockedCards={this.upsertLockedCards}
                        getCardImage={this.getTourImage}
                        setDefaultImgSrc={this.setDefaultImgSrc}
                        selectedTour={this.state.selectedTour}
                        selectedEnrich={this.state.selectedEnrich}
                        handleSubmit={this.handleLapSubmit}
                        readOnly={this.state.tourReadOnly}
                    />
                </Modal>
                {/* ^ CONFIRM: modalCreateLap ^ */}
            </Fragment>
        )
    }
}

//----------------------------------------------------------------------------|

class ModalCreateLap extends Component {
    // lapData           | Object
    // toggleModal       | Function
    // isEmpty           | Function
    // lockedCards       | Array
    // upsertLockedCards | Function
    // getCardImage      | Function
    // setDefaultImgSrc  | Function
    // selectedTour      | Function
    // selectedEnrich    | Function
    // handleSubmit      | Function
    // readOnly          | Boolean

    constructor(props) {
        super(props);
        this.state = {
            createMode:   false,
            loadingState: false,
            suggestAddressEnabled: false,
            lapUUID: null,
            lapTitle: null,
            cards: [],
            selectedCards: [],
            tmpSelectedCards: [],
            lapNarration: "",
            lapAddress: "",
            addressResults: [],
            addressCoordy: [],
            isDescribeBy: null,
            lapIndex: 1,
            searchAddressEnabled: false
        };
    }

    componentWillMount() {
        this.loadFromProps();
    }

    loadFromProps = () => {
        const data = this.props.lapData;

        let gpsData = (
            data                                &&
            data.lap_data                       &&
            data.lap_data.gps_location          &&
            data.lap_data.gps_location.latitude &&
            data.lap_data.gps_location.longitude ?
            {
                lat: data.lap_data.gps_location.latitude,
                lon: data.lap_data.gps_location.longitude
            } : {}
        );

        let describeBy = (() => {
            if(data && Array.isArray(data.lap_cards)) {
                for (let i = 0; i < data.lap_cards.length; i++) {
                    if (data.lap_cards[i].is_describe_by) {
                        return data.lap_cards[i].enrich_uuid;
                    }
                }
            }

            return null;
        })();

        let assocCards = (() => {
            if(data && Array.isArray(data.lap_cards)) {
                return data.lap_cards.map((card) => {
                    return card.enrich_uuid;
                });
            }

            return [];
        })();

        this.setState({
            isDescribeBy:  describeBy,
            createMode:    data && data.lap_uuid           ? false              : true,
            lapUUID:       data && data.lap_uuid           ? data.lap_uuid      : null,
            lapTitle:      data && data.lap_title          ? data.lap_title     : "",
            lapNarration:  data && data.lap_narration      ? data.lap_narration : "",
            lapIndex:      data && data.lap_index !== null ? data.lap_index     : 1,
            selectedCards: assocCards
        }, () => {
            this.getAddressFromCoordy(gpsData);
            this.loadTourCards();
        });
    }

    loadTourCards = () => {
        this.setState({loadingState: true});
        let query = '/tours/' + this.props.selectedTour + "/enrichments/" + this.props.selectedEnrich + "/cards";
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    loadingState: false,
                    cards: res.data.rows
                });
            }
            else {
                this.setState({
                    loadingState: false
                });
            }
        },
        err => {
            this.setState({
                loadingState: false
            });
        });
    }

    getCardOwner = (obj) => {
       if (obj &&
           obj.card_owner &&
           obj.card_owner.user_profile &&
           obj.card_owner.user_profile.firstName &&
           obj.card_owner.user_profile.lastName) {
           return (
               obj.card_owner.user_profile.firstName[0] +
               ". " +
               obj.card_owner.user_profile.lastName
           );
       }

       return "Non disponible..."
    }

    upsertForLap = (cardUUID) => {
        if (cardUUID) {
            if (this.state.selectedCards.includes(cardUUID)) {
                let tmpArray = this.state.selectedCards;
                const index = tmpArray.indexOf(cardUUID);
                tmpArray.splice(index, 1);

                this.setState({
                    selectedCards: tmpArray
                }, () => {
                    this.tmpUpsertForLap(cardUUID);
                });
            }
            else {
                this.setState({
                    selectedCards : [
                        ...this.state.selectedCards,
                        cardUUID
                    ]
                }, () => {
                    this.tmpUpsertForLap(cardUUID);
                });
            }
        }
    }

    tmpUpsertForLap = (cardUUID) => {
        if (cardUUID) {
            if (this.state.tmpSelectedCards.includes(cardUUID)) {
                let tmpArray = this.state.tmpSelectedCards;
                const index = tmpArray.indexOf(cardUUID);
                tmpArray.splice(index, 1);

                this.setState({
                    tmpSelectedCards: tmpArray
                });
            }
            else {
                this.setState({
                    tmpSelectedCards : [
                        ...this.state.tmpSelectedCards,
                        cardUUID
                    ]
                });
            }
        }
    }

    sendLockedCards = () => {
        if (this.props.upsertLockedCards) {
            this.state.tmpSelectedCards.map((cardUUID) => {
                this.props.upsertLockedCards(cardUUID);
                return false;
            });
        }
    }

    upsertCardAsLapDescr = (card_uuid, location, title) => {
        if (this.state.isDescribeBy === card_uuid) {
            this.setState({
                isDescribeBy: null
            }, () => {
                this.upsertForLap(card_uuid);
            });
        }
        else {
            this.setState({
                isDescribeBy: card_uuid,
                lapTitle: (title ? title : this.state.lapTitle)
            }, () => {
                this.upsertForLap(card_uuid);

                if (Array.isArray(location)) {
                    let coordy = {
                        lat: null,
                        lon: null
                    }

                    location.map((xy) => {
                        if (xy.key === "ENRICH.LC.P_LAT") {
                            coordy.lat = xy.val;
                        }
                        else if (xy.key === "ENRICH.LC.P_LON") {
                            coordy.lon = xy.val;
                        }

                        return false;
                    });

                    this.getAddressFromCoordy(coordy);
                }
            });
        }
    }

    getCoordyFromAddressSearch(address) {
        if (address !== "") {
            axios.get(globalVars.LeafLet.APISearch + "?format=json&limit=15&q=" + address)
            .then(
                res => {
                    this.setState({
                        addressResults: res.data
                    });
                },
                err => {
                    this.setState({
                        addressResults: []
                    });
                }
            );
        }
        else {
            this.setState({
                addressResults: []
            });
        }
    }

    getAddressFromCoordy(coordy) {
        if (coordy.lat && coordy.lon) {
            axios.get(globalVars.LeafLet.APIReverse + "?format=json&lat=" + coordy.lat + "&lon=" + coordy.lon)
            .then(
                res => {
                    if (res.data && res.data.display_name) {
                        this.setState({
                            suggestAddressEnabled: false,
                            lapAddress: res.data.display_name,
                            addressCoordy: [
                                coordy.lat,
                                coordy.lon
                            ]
                        });
                    }
                    else {
                        this.setState({
                            suggestAddressEnabled: false,
                            lapAddress: "",
                            addressCoordy: []
                        });
                    }
                },
                err => {
                    this.setState({
                        suggestAddressEnabled: false,
                        lapAddress: "",
                        addressCoordy: []
                    });
                }
            );
        }
        else {
            this.setState({
                suggestAddressEnabled: false,
                lapAddress: "",
                addressCoordy: []
            });
        }
    }

    handleTitleChange = (ev) => {
        this.setState({
            lapTitle: ev.target.value
        });
    }

    handleAddressChange = (ev) => {
        this.setState({
            lapAddress: ev.target.value,
            addressCoordy: (
                ev.target.value !== ""
            ) ? [] : this.state.addressCoordy,
            searchAddressEnabled: (
                ev.target.value.length >= 3
            ) ? true : false
        });
    }

    onClickSearchAddress = (ev) => {
        this.setState({
            suggestAddressEnabled: true
        }, () => {
            this.getCoordyFromAddressSearch(
                this.state.lapAddress
            )
        });
    }

    onClickSearchAddressClose = (ev) => {
        this.setState({
            suggestAddressEnabled: false,
            addressResults: []
        });
    }

    handleEditorChange = (value) => {
        this.setState({
            lapNarration: value
        });
    }

    handleIndexChange = (ev) => {
        this.setState({
            lapIndex: parseInt(ev.target.value)
        });
    }

    selectAddressForLap = (address) => {
        this.setState({
            suggestAddressEnabled: false,
            lapAddress: address.display_name,
            addressCoordy: [
                address.lat,
                address.lon
            ]
        });
    }

    execRemoteSubmit = () => {
        let jsonData = {
            lap_uuid:         this.state.lapUUID,
            lap_title:        this.state.lapTitle,
            lap_cards:        this.state.selectedCards,
            lap_narration:    this.state.lapNarration,
            lap_data: {
                gps_location: {
                    latitude:  this.state.addressCoordy[0],
                    longitude: this.state.addressCoordy[1]
                }
            },
            lap_describe_by:  this.state.isDescribeBy,
            lap_index:        this.state.lapIndex
        };

        if (this.state.createMode) {
            this.createRemoteLap(jsonData);
        }
        else {
            this.updateRemoteLap(jsonData);
        }
    }

    createRemoteLap = (jsonData) => {
        this.setState({loadingState: true});
        let query = '/tours/' + this.props.selectedTour + "/enrichments/" + this.props.selectedEnrich + "/laps";
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, jsonData, {
            auth: holdDataService.getAuthorization()
        })
        .then(
            res => {
                this.setState({
                    loadingState: false
                }, () => {
                    if (this.props.handleSubmit) {
                        this.props.handleSubmit(jsonData);
                    }

                    this.props.toggleModal(false);
                });
            },
            err => {
                this.setState({
                    loadingState: false
                }, () => {
                    if (this.props.handleSubmit) {
                        this.props.handleSubmit(jsonData);
                    }

                    this.props.toggleModal(false);
                });
            }
        );
    }

    updateRemoteLap = (jsonData) => {
        this.setState({loadingState: true});
        let query = '/tours/' + this.props.selectedTour + "/enrichments/" + this.props.selectedEnrich + "/laps/" + jsonData.lap_uuid;
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, jsonData, {
            auth: holdDataService.getAuthorization()
        })
        .then(
            res => {
                this.setState({
                    loadingState: false
                }, () => {
                    if (this.props.handleSubmit) {
                        this.props.handleSubmit(jsonData);
                    }

                    this.props.toggleModal(false);
                });
            },
            err => {
                this.setState({
                    loadingState: false
                }, () => {
                    if (this.props.handleSubmit) {
                        this.props.handleSubmit(jsonData);
                    }

                    this.props.toggleModal(false);
                });
            }
        );
    }

    generateCardFromList = () => {
        let cards = this.state.cards.map((obj, index) => {
            if (this.props.lockedCards.includes(obj.enrich_uuid) &&
                !(
                    this.state.selectedCards.includes(
                        obj.enrich_uuid
                    )
                    || this.state.isDescribeBy === obj.enrich_uuid
                )) {
                return null;
            }
            else if (this.props.readOnly && !(
                this.state.selectedCards.includes(
                    obj.enrich_uuid
                ) || this.state.isDescribeBy === obj.enrich_uuid)) {
                return null;
            }
            else return (
                <Card key={obj.enrich_uuid + "-" + index}
                    className={(
                        this.state.selectedCards.includes(
                            obj.enrich_uuid
                        ) || this.state.isDescribeBy === obj.enrich_uuid ?
                        "" : "selectedForTour"
                    )}>
                    <CardImg top
                        width="100%"
                        src={
                            this.props.getCardImage(obj.card_image)
                        }
                        onError={this.props.setDefaultImgSrc}
                    />
                    <div className="card-status" style={{
                        backgroundColor: "#c67e00",
                        color: "#ffffff"
                    }}>
                    Scheda del Progetto
                    </div>
                    <CardBody>
                        <CardTitle>
                            {obj.card_title}
                        </CardTitle>
                        <CardText>
                            <small className="text-muted">
                                <b>Creatore:</b> {this.getCardOwner(obj)}
                            </small>
                        </CardText>
                    </CardBody>
                    {
                        !this.props.readOnly ?
                            <div className='bottom-icons'>
                                {
                                    !this.state.selectedCards.includes(
                                        obj.enrich_uuid
                                    ) || (
                                        this.state.isDescribeBy === obj.enrich_uuid &&
                                        this.state.selectedCards.includes(
                                            obj.enrich_uuid
                                        )
                                    ) ?
                                        <div
                                            onClick={() => this.upsertCardAsLapDescr(
                                                obj.enrich_uuid,
                                                obj.card_location,
                                                obj.card_title
                                            )}
                                            data-tip data-for={"TIPS_FOR_D_" + obj.enrich_uuid}>
                                            {
                                                (
                                                    this.state.isDescribeBy !== obj.enrich_uuid
                                                ) ? "Imposta come Tappa" : "Rimuovi come Tappa"
                                            }
                                            <ReactTooltip
                                                id={"TIPS_FOR_D_" + obj.enrich_uuid}
                                                wrapper="span"
                                                place="top"
                                                effect="solid"
                                                className="tooltip-user">
                                                Permette di utilizzare la scheda come Tappa del percorso, il titolo e
                                                l'indirizzo saranno estratti, se presenti, dalla scheda scelta.
                                            </ReactTooltip>
                                        </div>
                                    : null
                                }
                                {
                                    this.state.isDescribeBy !== obj.enrich_uuid ?
                                        <div
                                            onClick={() => this.upsertForLap(obj.enrich_uuid)}
                                            data-tip data-for={"TIPS_FOR_V_" + obj.enrich_uuid}>
                                            {
                                                this.state.selectedCards.includes(
                                                    obj.enrich_uuid
                                                ) ? "Escludi dalla Visita" : "Includi nella Visita"
                                            }
                                            <ReactTooltip
                                                id={"TIPS_FOR_V_" + obj.enrich_uuid}
                                                wrapper="span"
                                                place="top"
                                                effect="solid"
                                                className="tooltip-user">
                                                Permette di aggiungere la scheda come uno degli oggetti d'interesse nella tappa.
                                                Una tappa deve contenere almeno una schede per poter essere creata.
                                            </ReactTooltip>
                                        </div>
                                    : null
                                }
                            </div>
                        : null
                    }
                </Card>
            );
        }).filter(x => x);

        if (cards.length > 0) {
            return cards;
        }
        else return (
            <Alert color="light" className="mt-1">
                <h4 className="alert-heading">Nessuna Scheda Disponibile!</h4>
                <p>
                    Non sono presenti altre schede utilizzabili per la tappa.
                </p>
            </Alert>
        );
    }

    render() {
        return (
            <Fragment>
                <ModalHeader toggle={() => this.props.toggleModal(false)}>
                    <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                            <div className='widget-content-left mr-3 text-center w-100'>
                                {
                                    this.props.readOnly ? "Dettaglio della" :
                                    this.state.createMode ? "Creazione nuova" : "Modifica della"
                                } Tappa:
                            </div>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody style={{padding: "0px"}}>
                    {
                        this.state.loadingState ?
                        <EdubbaLoader /> : null
                    }
                    <Container className='step-navigator-container'>
                        <Row>
                            <Col lg='4' md='4' sm='4' className='step-navigator-left'>
                                <div className='form-sep-input-label'>
                                    Titolo della Tappa:
                                </div>
                                <Input
                                    type="text"
                                    className=''
                                    name='lapTitle'
                                    value={this.state.lapTitle ? this.state.lapTitle : ""}
                                    onChange={this.handleTitleChange}
                                    placeholder="Immettere un titolo..."
                                    readOnly={this.props.readOnly}
                                />
                                <div className='form-sep-input-label'>
                                    Indirizzo della Tappa: &nbsp; { /*this.state.addressCoordy.toString()*/ }
                                </div>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        className=''
                                        name='lapTitle'
                                        value={this.state.lapAddress ? this.state.lapAddress : ""}
                                        onChange={this.handleAddressChange}
                                        placeholder="Indirizzo di almeno 3 caratteri..."
                                        readOnly={this.props.readOnly || this.state.suggestAddressEnabled}
                                    />
                                    {!this.props.readOnly ?
                                        <Fragment>
                                            {this.state.suggestAddressEnabled ?
                                                <InputGroupAddon addonType="append">
                                                    <Button color="secondary" onClick={this.onClickSearchAddressClose}>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </Button>
                                                </InputGroupAddon> :
                                                <InputGroupAddon addonType="append">
                                                    <Button color="secondary" disabled={!this.state.searchAddressEnabled} onClick={this.onClickSearchAddress}>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </Button>
                                                </InputGroupAddon>
                                            }
                                        </Fragment>
                                    : null}
                                </InputGroup>
                                {
                                    this.state.suggestAddressEnabled ?
                                        this.state.addressResults.length > 0 ? (
                                            <div className="AssignGroupAddress">
                                                <div>
                                                {
                                                    this.state.addressResults.map((res, index) => {
                                                        return (
                                                            <div
                                                                className="Address"
                                                                key={"address-member-" + index}
                                                                onClick={() => this.selectAddressForLap(res)}>
                                                                {res.display_name}
                                                            </div>
                                                        );
                                                    })
                                                }
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="AssignGroupAddress">
                                                <div>
                                                    <div
                                                        className="Address"
                                                        key={"address-member-0"}>
                                                        Nessun indirizzo valido trovato...
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    : null
                                }
                                <div className='form-sep-input-label'>
                                    Numerazione della Tappa:
                                </div>
                                <Input
                                    type="number"
                                    className=''
                                    name='lapIndex'
                                    value={this.state.lapIndex ? this.state.lapIndex : 1}
                                    onChange={this.handleIndexChange}
                                    readOnly={this.props.readOnly}
                                />
                                <div className='form-sep-input-label'>
                                    Descrizione della Tappa:
                                </div>
                                <RichTextEditor
                                    name="lapNarration"
                                    className="RichTextEditor_Fix"
                                    value={this.state.lapNarration}
                                    onChange={this.handleEditorChange}
                                    placeHolder="Inserire la descrizione della tappa..."
                                    readOnly={this.props.readOnly}
                                />
                            </Col>
                            <Col lg='8' md='8' sm='8' className='step-navigator-right'>
                            {
                                this.state.cards.length > 0 ?
                                    <Fragment>
                                        <div className='form-sep-input-label'>
                                            Selezionare una o più Schede:
                                        </div>
                                        <div className="list-cards-grid">
                                            { this.generateCardFromList() }
                                        </div>
                                    </Fragment>
                                : (
                                    <Alert color="light" className="mt-1">
                                        <h4 className="alert-heading">Nessuna Scheda Presente!</h4>
                                        <p>
                                            Non è ancora possibile visualizzare le schede su cui lavorare.
                                        </p>
                                    </Alert>
                                )
                            }
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {
                        this.props.readOnly ? (
                            <Button className='btn-edubba' onClick={() => this.props.toggleModal(false)}>Chiudi</Button>
                        ) : (
                            <Fragment>
                                <Button className='btn-edubba' onClick={() => this.props.toggleModal(false)}>Annulla</Button>
                                <Button className='btn-edubba' onClick={() => {
                                    this.sendLockedCards();
                                    this.execRemoteSubmit();
                                }} disabled={
                                    this.props.isEmpty(this.state.lapTitle) ||
                                    this.props.isEmpty(this.state.selectedCards) ||
                                    this.props.isEmpty(this.state.lapNarration) ||
                                    this.state.addressCoordy.length <= 0
                                }>
                                    {this.state.createMode ? "Crea" : "Modifica"} Tappa
                                </Button>
                            </Fragment>
                        )
                    }
                </ModalFooter>
            </Fragment>
        )
    }
}

//----------------------------------------------------------------------------|

export default withRouter(TourEnrichment);
