import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from '../globalVars.json';
import {
    Row,
    Col,
    Container,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Alert,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
// import SearchBox from '../Layout/AppHeader/Components/SearchBox';
import holdDataService from '../services/holdDataService/holdDataService';
import {
    faPlus,
    faEdit,
    faReply,
    faTrash,
    faArrowCircleLeft,
    faArrowCircleRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import EdubbaLoader from '../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../assets/utils/images/edubba_placeholder.png'
import moment from 'moment';
import './Tours.scss';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

class Tours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            tours: [],
            tour_categories: [],
            today: new Date().getTime(),
            loadingTours: true,

            //----------------------------------------------| SEARCH

            searchTourValue: "",
            tourCatCodeFilter: "",
            tourCatNameFilter: "",

            //----------------------------------------------| PAGING

            tourCatSplitButtonOpen: false,
            tourSplitButtonOpen: false,
            tourTotalEntities: 0,
            tourPageLimit: 10,
            tourPageNumber: 1,

            //----------------------------------------------| MODAL DELETE

            modalDeleteTour: false,
            modalDeleteTourUUID: null,

            //----------------------------------------------| MODAL CONFIRM
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
        };

    }

    componentDidMount() {
        this.props.setSectionTitle('Catalogo dei Percorsi');
        this.loadTourCategories();
        this.loadTours();
    }

    toggleDeleteTourUUID = (tour_uuid = null) => {
        this.setState({
            modalDeleteTour: !this.state.modalDeleteTour,
            modalDeleteTourUUID: tour_uuid
        });
    }

    loadTourCategories = () => {
        let query = '/tours/categories';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    tour_categories: res.data.rows
                });
            }
            else {
                this.setState({
                    tour_categories: []
                });
            }
        },
        err => {
            this.setState({
                tour_categories: []
            });
        });
    }

    loadTours = (searchValue = null, filterByCategory = null) => {
        this.setState({ loadingTours: true });
        let query = '/tours?limit=' + this.state.tourPageLimit + '&page=' + this.state.tourPageNumber;
        if (filterByCategory) {
            query += '&filterCategory=' + filterByCategory;
        }
        if (searchValue) {
            query += '&searchBy=' + searchValue;
        }
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    tourTotalEntities: res.data.rowCount,
                    tours:             res.data.rows,
                    loadingTours:      false
                });
            }
            else {
                this.setState({
                    tourPageNumber:    1,
                    tourTotalEntities: 0,
                    tours:             [],
                    loadingTours:      false
                });
            }
        },
        err => {
            this.setState({
                tourPageNumber:    1,
                tourTotalEntities: 0,
                tours:             [],
                loadingTours:      false
            });
        });
    }

    deleteTourCard = (tour_uuid) => {
        let query = '/tours/' + tour_uuid;
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            // Check
            if (res && res.data && res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Percorsi",
                    modalConfirmToastMessage: "Percorso selezionato rimosso con successo dalla propria libreria!",
                    modalDeleteTour: false,
                    modalDeleteTourUUID: null
                }, () => {
                    // Refresh
                    this.loadTours();
                });
            } else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Percorsi",
                    modalConfirmToastMessage: "Impossibile completare la rimozione del percorso selezionato: verificare che non sia associato a qualche attività o pubblicato per la fruizione nella mobile-app.",
                    modalDeleteTour: false,
                    modalDeleteTourUUID: null
                });
            }
        }, err => {
            // Error
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Rimozione Percorsi",
                modalConfirmToastMessage: err.message,
                modalDeleteTour: false,
                modalDeleteTourUUID: null
            });
        });
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    goToTourPage = (pageNumber) => {
        this.setState({
            tourPageNumber: pageNumber
        }, () => {
            this.loadTours(
                this.state.searchTourValue,
                this.state.tourCatCodeFilter
            );
        });
    }

    setTourCatFilter = (categoryCode, categoryName) => {
        this.setState({
            tourCatCodeFilter: categoryCode,
            tourCatNameFilter: categoryName
        }, () => {
            this.loadTours(
                this.state.searchTourValue,
                this.state.tourCatCodeFilter
            );
        });
    }

    tourToggleSplit = () => {
        this.setState({
            tourSplitButtonOpen: !this.state.tourSplitButtonOpen
        });
    }

    tourCatToggleSplit = () => {
        this.setState({
            tourCatSplitButtonOpen: !this.state.tourCatSplitButtonOpen
        });
    }

    handleTourSearch = (ev) => {
        this.setState({
            searchTourValue: ev.target.value
        })
    }

    searchTour = (ev) => {
        const keyCode = ev.which || ev.keyCode;
        if (keyCode === 13) {
            this.loadTours(
                this.state.searchTourValue,
                this.state.tourCatCodeFilter
            );
        }
    }

    clearSearchTours = () => {
        this.setState({
            searchTourValue: "",
            tourPageNumber: 1
        }, () => {
            this.loadTours();
        });
    }

    getTourOwner = (obj) => {
        if (obj &&
            obj.tour_owner &&
            obj.tour_owner.user_profile &&
            obj.tour_owner.user_profile.firstName &&
            obj.tour_owner.user_profile.lastName) {
            return (
                obj.tour_owner.user_profile.firstName[0] +
                ". " +
                obj.tour_owner.user_profile.lastName
            );
        }

        return "Non disponible..."
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getTourImage = (tour_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(tour_image)) {
            coreImage = tour_image;
        }

        return coreImage;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    render() {
        let tourPages = Math.ceil(
            this.state.tourTotalEntities /
            this.state.tourPageLimit
        );

        tourPages = (tourPages <= 0) ? 1 : tourPages;

        return (
            <Fragment>
                <Container className='Tours'>
                    {/* v DELETE TOUR: modalDeleteTour v */}
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalDeleteTour}
                        toggle={this.toggleDeleteTourUUID}
                    >
                        <ModalHeader toggle={this.toggleDeleteTourUUID}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Rimozione di un Percorso
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                Vuoi davvero rimuovere il percorso selezionato? Eventuali arricchimenti saranno ancora fruibili,
                                ma l'operazione non sarà reversibile una volta completata!
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.toggleDeleteTourUUID}>Annulla</Button>
                            <Button
                                className='btn-edubba'
                                onClick={() => this.deleteTourCard(this.state.modalDeleteTourUUID)}
                                disabled={this.isEmpty(this.state.modalDeleteTourUUID)}
                            >
                                Rimuovi
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ DELETE TOUR: modalDeleteTour ^ */}
                    {/* v CONFIRM: modalConfirmToast v */}
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalConfirmToast}
                        toggle={this.closeModalConfirmToast}>
                        <ModalHeader toggle={this.closeModalConfirmToast}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        {this.state.modalConfirmToastTitle}
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                {this.state.modalConfirmToastMessage}
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ CONFIRM: modalConfirmToast ^ */}
                    {
                        this.state.loadingTours ?
                            <EdubbaLoader />
                        : null
                    }
                    <Fragment>
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <div className="task-container">
                                    <div className="task-container-title">I Miei Percorsi:</div>
                                    <div className="task-container-search">
                                        <InputGroup>
                                            <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.tourSplitButtonOpen} toggle={this.tourToggleSplit}>
                                                <Button onClick={(event) => {
                                                    event.preventDefault();
                                                    this.props.history.push('/edit_tour');
                                                }}>
                                                    <FontAwesomeIcon className='icon' icon={faPlus} />
                                                </Button>
                                                <Button disabled={true}>
                                                    <b>Pagina: &nbsp;</b>{ this.state.tourPageNumber }
                                                </Button>
                                                { tourPages > 1 ? <DropdownToggle split /> : null }
                                                <DropdownMenu>
                                                    {
                                                        // Gen. pages
                                                        (() => {
                                                            let pages = [];
                                                            for (let i = 1; i <= tourPages; i++) {
                                                                if (this.state.tourPageNumber === i) {
                                                                    continue;
                                                                }
                                                                else pages.push(
                                                                    <DropdownItem
                                                                        key={"card-page-" + i}
                                                                        onClick={() => this.goToTourPage(i)}
                                                                    >
                                                                        Vai alla Pagina: &nbsp;{i}
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            return pages;
                                                        })()
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                            <Input
                                                value={this.state.searchTourValue}
                                                onChange={this.handleTourSearch}
                                                onKeyUp={this.searchTour}
                                                placeholder="Premere invio per cercare..."
                                            />
                                            {
                                                !this.isEmpty(this.state.searchTourValue) ? (
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.clearSearchTours}>
                                                            <FontAwesomeIcon className='icon' icon={faTrash} />
                                                        </Button>
                                                    </InputGroupAddon>
                                                ) : null
                                            }
                                            <InputGroupButtonDropdown
                                                className="task-container-category-filter"
                                                addonType="append"
                                                isOpen={this.state.tourCatSplitButtonOpen}
                                                toggle={this.tourCatToggleSplit}>
                                                <DropdownToggle split>
                                                    {
                                                        this.state.tourCatCodeFilter !== "" &&
                                                        this.state.tourCatNameFilter !== "" ?
                                                        "Filtra: " + this.state.tourCatNameFilter :
                                                        "Tutte le Categoria"
                                                    } &nbsp;
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        key={"tour-category-" + 0}
                                                        onClick={() => this.setTourCatFilter("")}
                                                    >
                                                        Visualizza: &nbsp;Tutte
                                                    </DropdownItem>
                                                    {
                                                        this.state.tour_categories.map((item, index) => {
                                                            return (
                                                                <DropdownItem
                                                                    key={"tour-category-" + (index + 1)}
                                                                    onClick={() => this.setTourCatFilter(item.category_code, item.category_name)}
                                                                >
                                                                    Visualizza: &nbsp;{item.category_name}
                                                                </DropdownItem>
                                                            );
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </div>
                                    <div className="task-container-arrow-left">
                                        <Button disabled={this.state.tourPageNumber <= 1} onClick={() => this.goToTourPage(
                                            this.state.tourPageNumber - 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                        </Button>
                                    </div>
                                    <div className="task-container-arrow-right">
                                        <Button disabled={this.state.tourPageNumber >= tourPages} onClick={() => this.goToTourPage(
                                            this.state.tourPageNumber + 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                        </Button>
                                    </div>
                                    <div className="task-container-list">
                                    {
                                        this.state.tours.length > 0 ?
                                            <div className="list-tours-grid">
                                                {
                                                    this.state.tours.map((obj) => {
                                                        return (
                                                            <Card key={obj.tour_uuid}>
                                                                <div className="arrow-right">
                                                                    <i className={"fas " + obj.tour_category_data.icons.fa + " arrow-right-icon"} />
                                                                </div>
                                                                <CardImg
                                                                    top
                                                                    width="100%"
                                                                    src={
                                                                        this.getTourImage(obj.tour_image)
                                                                    }
                                                                    onError={this.setDefaultImgSrc}
                                                                />
                                                                <div className="tour-status" style={{
                                                                    backgroundColor: "#c67e00",
                                                                    color: "#ffffff"
                                                                }}>
                                                                {
                                                                    "Percorso Personale Edubba"
                                                                }
                                                                </div>
                                                                <CardBody>
                                                                    <CardTitle>
                                                                        {obj.tour_title}
                                                                    </CardTitle>
                                                                    <CardText>
                                                                        <small className="text-muted">
                                                                            <b>Categoria:</b> {obj.tour_category_name}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Creatore:</b> {this.getTourOwner(obj)}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Creato:</b> {this.getFromNowDate(obj.tour_date_create)}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Modificato:</b> {this.getFromNowDate(obj.tour_date_update)}
                                                                        </small>
                                                                    </CardText>
                                                                </CardBody>
                                                                <div className='bottom-icons'>
                                                                    <div
                                                                        onClick={() => this.props.history.push("/tours/" + obj.tour_uuid + "/assign")}
                                                                        data-tip data-for={"TIPS_FOR_E_" + obj.tour_uuid}>
                                                                        <FontAwesomeIcon
                                                                            className='icon reply'
                                                                            icon={faReply}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_E_" + obj.tour_uuid}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                            Premere per assegnare il percorso.
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <div
                                                                        onClick={() => this.props.history.push("/tours/" + obj.tour_uuid)}
                                                                        data-tip data-for={"TIPS_FOR_V_" + obj.tour_uuid}>
                                                                        <FontAwesomeIcon
                                                                            className='icon'
                                                                            icon={faEdit}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_V_" + obj.tour_uuid}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                            Premere per modificare la traccia del percorso.
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <div className="red-action-btn"
                                                                        onClick={() => this.toggleDeleteTourUUID(obj.tour_uuid)}
                                                                        data-tip data-for={"TIPS_FOR_DELETE_" + obj.tour_uuid}>
                                                                        <FontAwesomeIcon
                                                                            className='icon'
                                                                            icon={faTrash}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_DELETE_" + obj.tour_uuid}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                                ATTENZIONE: Permette la rimozione del percorso!
                                                                        </ReactTooltip>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : (
                                            <Row>
                                                <Col lg='12' md='12' sm='12'>
                                                    <Alert color="light" className="mt-3">
                                                        <h4 className="alert-heading">Nessun percorso trovato!</h4>
                                                        <p>
                                                            Non è ancora stato creato un percorso su cui lavorare.<br />
                                                            Appena creato il percorso sarà visibile nella sezione corrente.
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0">
                                                            Premi sul tasto "Nuovo Percorso" dal menù a lato.
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
            </Fragment>
        )
    }
}

export default withRouter(Tours);
