/* eslint-disable no-dupe-keys */
import React, { Component } from 'react';
import {
    Button,
    Badge,
    Input
} from 'reactstrap';
import {
    faTrash, faLock
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XRegExp from 'xregexp';

import './ReactstrapTagsInput.scss';

class ReactstrapTagsInput extends Component {
    // props.onChange         | Function
    // props.className        | String
    // props.value            | Array
    // props.locked           | Array
    // props.readOnly         | Boolean
    // props.lockedIcon       | Icon
    // props.valueIcon        | Icon

    constructor(props) {
        super(props);
        this.state = {
            editableTags: Array.isArray(props.value)  ? props.value  : [],
            lockedTags:   Array.isArray(props.locked) ? props.locked : []
        };
    }

    componentWillReceiveProps (nextProps) {
        if (this.state.editableTags !== nextProps.value ||
            this.state.lockedTags !== nextProps.locked) {
            this.setState({
                editableTags: nextProps.value,
                lockedTags: nextProps.locked
            });
        }
        return null;
    }

    addTags = (event) => {
        if (event.key === "Enter" &&
            event.target.value !== "") {
            let tmpData = this.sanitizeText(
                event.target.value
            );

            if (this.state.editableTags.includes(tmpData) ||
                this.state.lockedTags.includes(tmpData)) {
                event.target.value = "";
                return;
            }

            this.setState({
                editableTags: [
                    ...this.state.editableTags,
                    tmpData
                ]
            }, () => {
                this.props.onChange(
                    this.state.editableTags
                );
            });

            event.target.value = "";
        }
    }

    delTags = (index) => {
        this.setState({
            editableTags: this.state.editableTags.filter(
                tag => this.state.editableTags.indexOf(tag) !== index
            )
        }, () => {
            this.props.onChange(
                this.state.editableTags
            );
        });
    }

    sanitizeText = (s, opt = {}) => {
        // Prepare data
        s   = String(s);
        opt = Object(opt);

        // Default options
        let defaults = {
            'delimiter':     '-',
            'limit':         undefined,
            'lowercase':     true,
            'replacements':  {},
            'transliterate': (typeof (XRegExp) === 'undefined') ? true : false
        };

        // Merge options
        for (let k in defaults) {
            if (!Object.prototype.hasOwnProperty.call(opt, k)) {
                opt[k] = defaults[k];
            }
        }

        // Char map
        let char_map = {
            // Latin
            'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
            'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
            'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
            'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
            'ß': 'ss',
            'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
            'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
            'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
            'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
            'ÿ': 'y',

            // Latin symbols
            '©': '(c)',

            // Greek
            'Α': 'A', 'Β': 'B', 'Γ': 'G', 'Δ': 'D', 'Ε': 'E', 'Ζ': 'Z', 'Η': 'H', 'Θ': '8',
            'Ι': 'I', 'Κ': 'K', 'Λ': 'L', 'Μ': 'M', 'Ν': 'N', 'Ξ': '3', 'Ο': 'O', 'Π': 'P',
            'Ρ': 'R', 'Σ': 'S', 'Τ': 'T', 'Υ': 'Y', 'Φ': 'F', 'Χ': 'X', 'Ψ': 'PS', 'Ω': 'W',
            'Ά': 'A', 'Έ': 'E', 'Ί': 'I', 'Ό': 'O', 'Ύ': 'Y', 'Ή': 'H', 'Ώ': 'W', 'Ϊ': 'I',
            'Ϋ': 'Y',
            'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'h', 'θ': '8',
            'ι': 'i', 'κ': 'k', 'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': '3', 'ο': 'o', 'π': 'p',
            'ρ': 'r', 'σ': 's', 'τ': 't', 'υ': 'y', 'φ': 'f', 'χ': 'x', 'ψ': 'ps', 'ω': 'w',
            'ά': 'a', 'έ': 'e', 'ί': 'i', 'ό': 'o', 'ύ': 'y', 'ή': 'h', 'ώ': 'w', 'ς': 's',
            'ϊ': 'i', 'ΰ': 'y', 'ϋ': 'y', 'ΐ': 'i',

            // Turkish
            'Ş': 'S', 'İ': 'I', 'Ç': 'C', 'Ü': 'U', 'Ö': 'O', 'Ğ': 'G',
            'ş': 's', 'ı': 'i', 'ç': 'c', 'ü': 'u', 'ö': 'o', 'ğ': 'g',

            // Russian
            'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh',
            'З': 'Z', 'И': 'I', 'Й': 'J', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
            'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C',
            'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sh', 'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu',
            'Я': 'Ya',
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh',
            'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
            'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c',
            'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu',
            'я': 'ya',

            // Ukrainian
            'Є': 'Ye', 'І': 'I', 'Ї': 'Yi', 'Ґ': 'G',
            'є': 'ye', 'і': 'i', 'ї': 'yi', 'ґ': 'g',

            // Czech
            'Č': 'C', 'Ď': 'D', 'Ě': 'E', 'Ň': 'N', 'Ř': 'R', 'Š': 'S', 'Ť': 'T', 'Ů': 'U',
            'Ž': 'Z',
            'č': 'c', 'ď': 'd', 'ě': 'e', 'ň': 'n', 'ř': 'r', 'š': 's', 'ť': 't', 'ů': 'u',
            'ž': 'z',

            // Polish
            'Ą': 'A', 'Ć': 'C', 'Ę': 'e', 'Ł': 'L', 'Ń': 'N', 'Ó': 'o', 'Ś': 'S', 'Ź': 'Z',
            'Ż': 'Z',
            'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z',
            'ż': 'z',

            // Latvian
            'Ā': 'A', 'Č': 'C', 'Ē': 'E', 'Ģ': 'G', 'Ī': 'i', 'Ķ': 'k', 'Ļ': 'L', 'Ņ': 'N',
            'Š': 'S', 'Ū': 'u', 'Ž': 'Z',
            'ā': 'a', 'č': 'c', 'ē': 'e', 'ģ': 'g', 'ī': 'i', 'ķ': 'k', 'ļ': 'l', 'ņ': 'n',
            'š': 's', 'ū': 'u', 'ž': 'z'
        };

        // Make custom replacements
        for (let k in opt.replacements) {
            s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
        }

        // Transliterate characters to ASCII
        if (opt.transliterate) {
            for (let k in char_map) {
                s = s.replace(RegExp(k, 'g'), char_map[k]);
            }
        }

        // Replace non-alphanumeric characters with our delimiter
        let alnum = (typeof (XRegExp) === 'undefined') ? RegExp('[^a-z0-9]+', 'ig') : XRegExp('[^\\p{L}\\p{N}]+', 'ig');
        s = s.replace(alnum, opt.delimiter);

        // Remove duplicate delimiters
        s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

        // Truncate slug to max. characters
        s = s.substring(0, opt.limit);

        // Remove delimiter from ends
        s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');

        // Return slug
        return opt.lowercase ? s.toLowerCase() : s;
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className="ReactstrapTagsInput">
                    {
                        Array.isArray(this.state.lockedTags) ?
                            this.state.lockedTags.map((tagName, index) => (
                                <Button disabled={true} className="Tags" variant="secondary" key={index}>
                                    {this.props.lockedIcon ?
                                        <div className="leftSource">
                                            <FontAwesomeIcon icon={this.props.lockedIcon} className='icon' />
                                        </div>
                                    : null}
                                    <span className="TagName">{tagName}</span>
                                    <Badge variant="light">
                                        <FontAwesomeIcon icon={faLock} className='icon' />
                                    </Badge>
                                </Button>
                            ))
                        : null
                    }{
                        Array.isArray(this.state.editableTags) ?
                            this.state.editableTags.map((tagName, index) => (
                                <Button disabled={false} className="Tags" variant="secondary" key={index}>
                                    {this.props.valueIcon ?
                                        <div className="leftSource">
                                            <FontAwesomeIcon icon={this.props.valueIcon} className='icon' />
                                        </div>
                                    : null}
                                    <span className="TagName">{tagName}</span>
                                    {
                                        this.props.readOnly ? (
                                            <Badge variant="light">
                                                <FontAwesomeIcon icon={faLock} className='icon' />
                                            </Badge>
                                        ) : (
                                            <Badge variant="light" onClick={() => this.delTags(index)}>
                                                <FontAwesomeIcon icon={faTrash} className='icon' />
                                            </Badge>
                                        )
                                    }
                                </Button>
                            ))
                        : null
                    }
                </div>
                <Input
                    className="TagsInput"
                    type="text"
                    onKeyUp={this.addTags}
                    placeholder="Premi invio per aggiungere i tuoi tags"
                    readOnly={this.props.readOnly ? true : false}
                />
            </div>
        );
    }
}

//----------------------------------------------------------------------------|

export default ReactstrapTagsInput;
