import axios from 'axios';
import holdDataService from './../holdDataService/holdDataService';

axios.interceptors.response.use(null, function (err) {
    if (err.response) {
        switch (err.response.status) {
            case 403:
                //
                break;
            case 401:
                holdDataService.deleteAllData();
                if (window.location.pathname !== '/login')
                    window.location = '/login';
                break;
            case 500:
                //
                break;
            default:
                break;
        }
    }
    else if (axios.isCancel(err)) {
        // console.log('Request canceled', err.message);
    }
    else {
        console.log('Server error:', err, err.response);
    }
    return Promise.reject(err);
});
