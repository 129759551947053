import React, { Component } from 'react';
import DrafJSTextEditor from 'react-rte';

class RichTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: DrafJSTextEditor.createValueFromString(
                props.value,
                "markdown"
            )
        };
    }

    componentWillReceiveProps (nextProps) {
        if (this.state.value.toString('markdown').trim() !== nextProps.value.trim()) {
            this.setState({
                value: DrafJSTextEditor.createValueFromString(
                    nextProps.value,
                    "markdown"
                )
            });
        }
        return null;
    }

    onChange = (new_value) => {
        this.setState({
            value: new_value
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(
                    new_value.toString('markdown').trim()
                );
            }
        });
    };

    render() {
        const toolbarConfig = {
            display: [
                'BLOCK_TYPE_DROPDOWN',
                'INLINE_STYLE_BUTTONS',
                'BLOCK_TYPE_BUTTONS',
                // 'LINK_BUTTONS',
                'HISTORY_BUTTONS'
            ],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold',      style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic',    style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal',         style: 'unstyled' },
                { label: 'Heading Large',  style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small',  style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };

        return (
            <DrafJSTextEditor
                className={this.props.className}
                toolbarConfig={toolbarConfig}
                placeholder={this.props.placeHolder}
                readOnly={this.props.readOnly}
                value={this.state.value}
                onChange={this.onChange}
            />
        );
    }
}

//----------------------------------------------------------------------------|

export default RichTextEditor;
