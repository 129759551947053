// ------------------------------------------------------------------------- //
//
//  EDUBBA KERNEL: METRIC EXT MATERIALS SKELETON
//
// ------------------------------------------------------------------------- //
//  COMMON MODULE
// ------------------------------------------------------------------------- //

// ------------------------------------------------------------------------- //
//  COMMON ENUMS
// ------------------------------------------------------------------------- //

/**
 * @author:      lsvanzo
 * @version:     26/05/2020
 * @description: Genera gli opcodes da usare.
 * @type:        Sync Function
 */
const MetricOpCodes = Object.freeze({
    EntityType:   "METRIC_OPCODE_ENTITY_TYPE_THIRD_PARTIES",
    EntityAction: {
        Open:     "METRIC_OPCODE_ENTITY_ACTION_OPEN",
        Close:    "METRIC_OPCODE_ENTITY_ACTION_CLOSE"
    },
    ActionMode: {
        Editor:   "METRIC_OPCODE_ACTION_MODE_EDITOR",
        Reader:   "METRIC_OPCODE_ACTION_MODE_READER",
        Unknown:  "METRIC_OPCODE_ACTION_MODE_UNKNOWN"
    },
    SourceType: {
        Material: "METRIC_OPCODE_SOURCE_TYPE_EXT_MATERIAL"
    }
});

// ------------------------------------------------------------------------- //
//  COMMON SKELETON
// ------------------------------------------------------------------------- //

/**
 * @author:      lsvanzo
 * @version:     26/05/2020
 * @description: Genera la struttura JSON + verifiche sui dati.
 * @type:        Sync Function
 *
 * @param material_uuid: UUID del materiale
 * @param material_source_type: Tipologia di entità misurata (material)
 * @param material_view_status: Modalità di azione (lettura, scrittura, ...)
 * @param material_action: Azione eseguita come metrica (open, close)
 */
const MetricOBJGenerator = (
    material_uuid,
    material_source_type,
    material_view_status,
    material_action
) => {
    if (!(material_uuid      &&
        material_source_type &&
        material_view_status &&
        material_action)) {
        // Always false
        return false;
    }

    // Send JSON structure
    return Object.freeze({
        "entity-type":   MetricOpCodes.EntityType,
        "entity-action": material_action,
        "action-mode":   material_view_status,
        "source-type":   material_source_type,
        "entity-payload": {
            "material-uuid": material_uuid
        }
    });
};

// ------------------------------------------------------------------------- //
//  EXPORT MULTIPLE COMPONENTS
// ------------------------------------------------------------------------- //

export {
    MetricOpCodes,
    MetricOBJGenerator
};

// ------------------------------------------------------------------------- //
//  END: KERNEL
// ------------------------------------------------------------------------- //
