import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import classnames from 'classnames';
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Alert
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    faPlus,
    faTrash,
    faMap,
    faUpload,
    faQuestion,
    faFile,
    faCaretLeft,
    faCaretRight,
    faFileImage,
    faFileAlt,
    faFileArchive,
    faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import holdDataService from '../../services/holdDataService/holdDataService';
import MapSelectorManager from '../../services/mapSelectorManager/MapSelectorManager';
import ImageUploadManager from '../../services/imageUploadManager/ImageUploadManager';
import SelectTemplateModal from './../Modals/SelectTemplateModal';
import JSTemplateManager from '../../services/templateManager/JSTemplateManager';
import EdubbaDefaultImage from './../../assets/utils/images/edubba_placeholder.png'
import EdubbaImgDefault from './../../assets/utils/images/file_not_previewable.jpg';
import globalVars from './../../globalVars.json';
import './CardCreation.scss';

let ctxTemplateManager;

// FEATURE CHECK FLAGS
const assignTypeEnabled = [
    "STSC0001"
];

class CardCreation extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            core: {},
            customTemplate: [],
            visibilityData: null,
            formData: {},
            activeTab: '',

            projects: [],
            // Creazione scheda
            selectedFlow: '27d9f99e-3133-419a-8c20-a75c74406a85',
            // Enrichment
            //selectedFlow: '5847606e-2787-43fa-b616-c9f35c8149b6',

            selectedProject: "",
            selectedProjectBody: null,
            selectedAssignment: "",

            cardCompiling: false,

            modalAnnulla: false,
            modalUploadFile: false,
            modalSelectTemplate: false,
            modalSelectProject: false,
            modalMap: false,
            showMediaModal: false,

            templateToRender: null,
            headersToRender: [],
            htmlToRender: null,

            //-------------------------------------------| AUTOLOAD

            skipAllModalSelection: false,
            selectedTemplateUUID:  null,

            //-------------------------------------------| CATALOG SRC

            selectedCardFormData: null,
            selectedCatalogType:  null,
            selectedCatalogUUID:  null,
            selectedCardRefID:    null,

            //-------------------------------------------| REQ INPUTS

            requiredInputs: {},
            brokenRequiredInputs: [],
            modalCheckRequireAnnulla: false,
            infoSectionDescription: null,
            infoSectionExamples: [],

            //-------------------------------------------| MEDIA MODAL

            mediaModalComponentsArray: [],
            showMediaNodeObj: null
        };
    }

    componentWillMount() {
        // Get data if exists
        const locationData = (
            this.props.location.state ?
            this.props.location.state :
            null
        );

        // Set state
        this.setState({
            // Always try with template UUID
            ...((
                locationData              &&
                locationData.tpl_uuid     &&
                locationData.project_uuid &&
                locationData.assign_uuid
            ) ? {
                selectedTemplateUUID:  locationData.tpl_uuid,
                selectedProject:       locationData.project_uuid,
                selectedAssignment:    locationData.assign_uuid,
                skipAllModalSelection: true
            } : {}),
            // Check for third-parts catalog
            ...((
                locationData             &&
                locationData.src_type    &&
                locationData.src_uuid    &&
                locationData.card_ref_id &&
                locationData.card_data   &&
                locationData.src_type === "third-catalog"
            ) ? {
                selectedCardFormData: locationData.card_data,
                selectedCatalogType:  locationData.src_type,
                selectedCatalogUUID:  locationData.src_uuid,
                selectedCardRefID:    locationData.card_ref_id
            } : {})
        }, () => {
            // Check for skip modal
            if (this.state.selectedTemplateUUID &&
                this.state.selectedProject      &&
                this.state.selectedAssignment   &&
                this.state.skipAllModalSelection) {
                // Trigger auto flow...
                this.selectProject();
            }
        });
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'CardCreation';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';
        this.props.setSectionTitle('none');
        this.getProjects();
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    getProjects = () => {
        let query = '/projects';
        this.setState({ loading: true }, () => {
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    let projects = [];
                    if (res.data.rowCount > 0) {
                        projects = res.data.rows
                    }
                    this.setState({
                        projects: projects,
                        loading: false
                    }, () => {
                        this.toggleSelectProject();
                    });
                }, err => {
                    this.setState({ loading: false })
                });
        });
    }

    handleProjectSelect = (event) => {
        const selectedProject = event.target.value;
        let selectedAssignment = (() => {
            for (let i = 0; i < this.state.projects.length; i++) {
                let currentProject = this.state.projects[i];
                if (
                    currentProject.project_uuid === selectedProject &&
                    (currentProject.project_assignments && currentProject.project_assignments.length > 0)
                ) {
                    return currentProject.project_assignments[0].assign_uuid;
                }
            }
            return "";
        })();
        this.setState({
            selectedProject: selectedProject,
            selectedAssignment: selectedAssignment
        });
    }

    handleAssignmentSelect = (event) => {
        this.setState({
            selectedAssignment: event.target.value
        });
    }

    toggleSelectProject = () => {
        this.setState({
            modalSelectProject: !this.state.modalSelectProject
        });
    }

    selectProject = () => {
        let project = ((projects, project_uuid) => {
            for (let i = 0; i < projects.length; i++) {
                if (projects[i].project_uuid === project_uuid) {
                    return projects[i];
                }
            }
        })(this.state.projects, this.state.selectedProject);
        this.setState({
            selectedProjectBody: project
        }, () => {
            this.toggleSelectProject();
            if (this.state.selectedTemplateUUID) {
                this.getTemplate(
                    this.state.selectedTemplateUUID
                )
            }
            else this.toggleSelectTemplate();
        });
    }

    toggleSelectTemplate() {
        this.setState({
            modalSelectTemplate: !this.state.modalSelectTemplate
        });
    }

    selectTemplate = (template_uuid) => {
        this.toggleSelectTemplate();
        this.setState({
            loading: true
        }, () => this.getTemplate(template_uuid));
    }

    getTemplate = (template_uuid) => {
        // Query
        let query = '/templates/' + template_uuid;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            }).then(res_template => {
                let core;
                if (res_template.data.rowCount > 0) {
                    core = res_template.data.rows[0];
                }

                // Query
                let query = '/templates/' + template_uuid + '/visibilities';
                axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                    {
                        auth: holdDataService.getAuthorization()
                    }).then(res_visibilities => {
                        let existingVisibility = this.checkAssignments(this.state.selectedAssignment, res_visibilities.data.rows);
                        if (existingVisibility) {
                            let query = '/templates/' + template_uuid + '/visibilities/' + existingVisibility.visibility_uuid;
                            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                                {
                                    auth: holdDataService.getAuthorization()
                                }).then(res_visibility => {
                                    let visibility = res_visibility.data.rows ? res_visibility.data.rows[0] : null;
                                    this.loadTemplate(core, visibility ? visibility.visibility_data : null, true);
                                });
                        }
                        else {
                            this.redirectToTemplateCreation(template_uuid);
                        }
                    });

            }, err => {
                this.setState({ loading: false })
            });
    }

    checkAssignments = (assignment_uuid, visibilities) => {
        for (let i = 0; i < visibilities.length; i++) {
            if (visibilities[i].visibility_assignment === assignment_uuid) {
                return visibilities[i];
            }
        }
        return false;
    }

    redirectToTemplateCreation = (template_uuid = null) => {
        this.setState({
            selectedTemplateUUID: template_uuid
        }, () => {
            this.props.history.push(
                '/card-template/' + this.state.selectedAssignment + "/create",
                {
                    // Always try with template UUID
                    ...((
                        this.state.selectedTemplateUUID &&
                        this.state.selectedAssignment   &&
                        this.state.selectedProject
                    ) ? {
                        tpl_uuid:     this.state.selectedTemplateUUID,
                        project_uuid: this.state.selectedProject,
                        assign_uuid:  this.state.selectedAssignment
                    } : {}),
                    // Check for third-parts catalog
                    ...((
                        this.state.selectedCardFormData &&
                        this.state.selectedCatalogType  &&
                        this.state.selectedCatalogUUID  &&
                        this.state.selectedCardRefID
                    ) ? {
                        card_data:   this.state.selectedCardFormData,
                        src_type:    this.state.selectedCatalogType,
                        src_uuid:    this.state.selectedCatalogUUID,
                        card_ref_id: this.state.selectedCardRefID
                    } : {})
                }
            );
        });
    }

    continueCardCreation = () => {
        this.setState({
            modalCheckRequireAnnulla: false,
            brokenRequiredInputs: []
        },
            () => {
                let checkResults = this.checkRequiredInputs();

                if (checkResults.length <= 0) {
                    this.setState({
                        cardCompiling: true
                    }, () => {
                        this.loadTemplate(this.state.core, this.state.visibilityData, false);
                    })
                }
                else {
                    this.setState({
                        modalCheckRequireAnnulla: true,
                        brokenRequiredInputs: checkResults
                    });
                }
            });
    }

    loadTemplate = (core, visibility_data, required_only) => {
        // Init manager
        ctxTemplateManager = new JSTemplateManager((block, masked, origin) => {
            let skipRemoveNode = false;

            if (Array.isArray(block["field-tree-childs"]) &&
                block["field-tree-childs"].length > 0) {
                skipRemoveNode = !masked["field-is-mediamodal"];
            }
            else {
                skipRemoveNode = masked["field-is-visible"];
            }

            return {
                "keepNode": !required_only ? skipRemoveNode : masked["field-is-required"],
                "isMediaNode": (
                    block["field-tree-childs"].length <= 0 ? (
                        skipRemoveNode ||
                        masked["field-is-visible"]
                    )
                    : (masked["field-is-mediamodal"] !== false)
                )
            };
        });

        // Prepare storage
        let mediaModalComponentsArray = [];

        // Set visiblity mask for template if exists
        if (!required_only) {
            // Calculate with mask
            ctxTemplateManager.setVisibilityMask(visibility_data);
        }

        // Load template into manager
        ctxTemplateManager.parseTemplateJSON(core["template_data"]);

        // Get header from manager
        let headers = ctxTemplateManager.getAllHeaders();

        if (!required_only) {
            // Get mediamodal sections
            mediaModalComponentsArray = ctxTemplateManager.getTemplateSectionsOfType("mediamodal");
        }

        // Load FormData
        let isPhaseTwo   = this.state.cardCompiling;
        let checkCatalog = this.state.selectedCardFormData;
        let formData     = (
            checkCatalog && !isPhaseTwo
        ) ? checkCatalog : JSON.parse(JSON.stringify(this.state.formData));

        // Set Template
        if (!(formData["CD-CODICI"] &&
            formData["CD-CODICI"].TSK)) {
            formData["CD-CODICI"] = {};
        }

        // Auto-extract template code
        formData["CD-CODICI"].TSK = core.template_code;

        // Get require inputs
        let requiredInputs = this.generateRequiredInputs(
            headers,
            formData
        );

        // Save new state of component
        this.setState({
            loading: false,
            core: core,
            customTemplate: core["template_data"]["core_data"],
            formData: formData,
            visibilityData: visibility_data,

            //-------------------------------------------------| NEW

            requiredInputs: requiredInputs,
            activeTab: headers.length > 0 ? headers[0]["field-edusn-srvid"] : null,
            headersToRender: headers,
            templateToRender: ctxTemplateManager.getProcessedTemplate(),
            mediaModalComponentsArray: mediaModalComponentsArray
        });
    }

    checkIfHeaderIsPopulated = (headerNode, formData) => {
        const formDataNode = formData[headerNode['field-code']];
        return this.recursiveCheckForValue(formDataNode);
    }

    recursiveCheckForValue = (obj) => {
        if (!obj) {
            return undefined;
        }
        if (typeof obj === "object") {
            let arrayValues = [];
            for (let i = 0; i < Object.keys(obj).length; i++) {
                if (Object.keys(obj)[i].indexOf("_check") !== -1) {
                    // Skip
                }
                else {
                    let currentSubObj = obj[Object.keys(obj)[i]];
                    arrayValues.push(this.recursiveCheckForValue(currentSubObj));
                }
            }
            for (let j = 0; j < arrayValues.length; j++) {
                if (arrayValues[j] !== undefined) {
                    return arrayValues[j];
                }
            }
            // If the resultant object or array is empty
            return undefined;
        }
        else {
            return obj;
        }
    }

    scrollUp = () => {
        let div = document.getElementById("root");
        div.scrollTop = 0;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => this.scrollUp());
        }
    }

    toggleAnnulla = () => {
        this.setState({
            modalAnnulla: !this.state.modalAnnulla
        })
    }

    toggleCheckRequireAnnulla = () => {
        this.setState({
            modalCheckRequireAnnulla: !this.state.modalCheckRequireAnnulla
        })
    }

    setImageValueURL = (data) => {
        document.getElementById(this.state.lastIdFocussed).value = data.fileUrl;
        let path = '';
        this.state.lastIdFocussed.split('_').map(key => {
            if (key !== 'root') {
                path += '.' + key;
            }
            return true;
        });
        path = path.substr(1);
        let obj = this.state.formData;
        this.setValue(obj, path, data.fileUrl);
        this.setState({ formData: obj })
        this.toggleUploadFile();
    }

    setValue(obj, path, value) {
        var a = path.split('.')
        var o = obj
        while (a.length - 1) {
            var n = a.shift()
            if (!(n in o)) o[n] = {}
            o = o[n]
        }
        o[a[0]] = value
    }

    checkRequiredInputs = () => {
        let requiredInputs = JSON.parse(JSON.stringify(this.state.requiredInputs));
        let requiredInputsKeys = Object.keys(requiredInputs);

        return requiredInputsKeys.map((key) => {
            return requiredInputs[key].result ? null : requiredInputs[key].block;
        })
            .filter(x => x);
    }

    handleCreateCard = () => {
        this.setState({
            modalCheckRequireAnnulla: false,
            brokenRequiredInputs: []
        },
            () => {
                let checkResults = this.checkRequiredInputs();

                if (checkResults.length <= 0) {
                    let templateUuid = this.state.core.template_uuid;
                    let query = '/cards';
                    let postBody = {
                        "parent_uuid":         null,
                        "template_uuid":       templateUuid,
                        "site_uuid":           null,
                        "flow_uuid":           this.state.selectedFlow,
                        "status_code":         "FW_CDCR_0001",
                        "catalog_ref_uuid":    this.state.selectedCatalogUUID,
                        "catalog_ref_card_id": this.state.selectedCardRefID,
                        "card_data": {
                            "visibility_data": null /*this.state.core.template_data.visibility_data*/,
                            "form_data":       this.state.formData
                        }
                    };

                    this.setState({ loading: true }, () => {
                        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                            postBody,
                            {
                                auth: holdDataService.getAuthorization()
                            })
                            .then(res_card => {
                                let query = '/projects/' + this.state.selectedProject + '/cards';
                                let postBody = {
                                    "project_cards": [
                                        res_card.data.rowCount > 0 ? res_card.data.rows[0].card_uuid : null
                                    ]
                                };
                                axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                                    postBody,
                                    {
                                        auth: holdDataService.getAuthorization()
                                    })
                                    .then(res_project => {
                                        this.setState({
                                            loading: false,
                                        }, () => this.props.history.push('/cards'))
                                    }, err => {
                                        //
                                    });
                            }, err => {
                                //
                            });
                    });
                }
                else {
                    this.setState({
                        modalCheckRequireAnnulla: true,
                        brokenRequiredInputs: checkResults
                    });
                }
            });
    }

    userCanEdit = () => {
        return true;
    }

    //--------------------------------------------------------------------------------| REQ. INPUTS

    generateRequiredInputs = (headers, formData) => {
        // Prepare data
        let requiredInputsStorage = {};

        if (Array.isArray(headers)) {
            headers.map((head) => {
                return this.generateRequiredInputs_helper(
                    head["field-edusn-srvid"],
                    formData,
                    requiredInputsStorage,
                    head["field-edusn-srvid"]
                );
            });
        }

        // Send back results
        return requiredInputsStorage;
    }

    generateRequiredInputs_helper = (node, nodeData, requireData, activeTab, nodePath = "", nodeIndex = 0) => {
        // Prepare data
        let curSectionNode = ctxTemplateManager.getTemplateSection(node);
        let curSectionMask = ctxTemplateManager.getVisibilitySection(node);

        // Prepare component data
        let pathSep = ".";

        // Prepare partial path
        let partialNodeData = null;
        let supportNodeData = Object.keys(nodeData ? nodeData : {});

        // Check for partial path
        if (supportNodeData.includes(curSectionNode["field-code"])) {
            // Save new path
            partialNodeData = nodeData[curSectionNode["field-code"]];
        }

        // Check for not empty path
        if (nodePath === "") {
            // Concat separator
            pathSep = "";
        }

        // Prepare flag for separator
        let isArrayOfGroupFields = false;

        // Check for block type: PARENT-NODE
        if (curSectionNode &&
            !curSectionNode["field-last-level"]) {
            // Check for group of fields
            if (curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0) {
                // Set as multi field array
                isArrayOfGroupFields = true;
            }

            // Check if children exists
            if (Array.isArray(curSectionNode["field-tree-childs"]) &&
                curSectionNode["field-tree-childs"].length > 0) {
                // Check for array
                if (Array.isArray(partialNodeData)) {
                    // Loop depending on sub data
                    partialNodeData.map((subNodeData, index) => {
                        // Delegate to function
                        curSectionNode["field-tree-childs"].map((child) => {
                            return this.generateRequiredInputs_helper(
                                child,
                                subNodeData,
                                requireData,
                                activeTab,
                                nodePath + pathSep + curSectionNode["field-code"] + pathSep + index,
                                index
                            );
                        });

                        return true;
                    });
                }
                // Otherwise...
                else {
                    // Prepare sub blocks
                    curSectionNode["field-tree-childs"].map((child) => {
                        return this.generateRequiredInputs_helper(
                            child,
                            partialNodeData,
                            requireData,
                            activeTab,
                            nodePath + pathSep + curSectionNode["field-code"] + (
                                isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                            )
                        );
                    })
                }
            }
        }
        // Check for block type: LEAF-NODE
        else {
            // Check for array
            if (Array.isArray(partialNodeData) && (
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Loop depending on sub data
                partialNodeData.map((subNodeData, index) => {
                    requireData[
                        "FIELD_ID__" + curSectionNode["field-edusn-srvid"] + "__" + index
                    ] = {
                            "block": {
                                ...curSectionNode,
                                "field-real-path": nodePath + pathSep + curSectionNode["field-code"] + pathSep + index,
                                "field-tab-owner": activeTab
                            },
                            "result": (
                                curSectionMask &&
                                    curSectionMask["field-is-required"] ? (
                                        subNodeData !== undefined &&
                                        subNodeData !== null &&
                                        subNodeData !== ""
                                    ) : true
                            )
                        };

                    return true;
                });
            }
            // Check for unexpected array
            else if (Array.isArray(partialNodeData) && !(
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                requireData[
                    "FIELD_ID__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex
                ] = {
                    "block": {
                        ...curSectionNode,
                        "field-real-path": nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        ),
                        "field-tab-owner": activeTab
                    },
                    "result": (
                        curSectionMask &&
                            curSectionMask["field-is-required"] ? (
                                partialNodeData[0] !== undefined &&
                                partialNodeData[0] !== null &&
                                partialNodeData[0] !== ""
                            ) : true
                    )
                };

                // Fix value
                this.updateJsonData(
                    nodePath + pathSep + curSectionNode["field-code"] + (
                        isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                    ),
                    (
                        partialNodeData[0]             &&
                        partialNodeData[0]["value"]    &&
                        partialNodeData[0]["language"] &&
                        partialNodeData[0]["profile"]
                    ) ? partialNodeData[0]["value"] : partialNodeData[0]
                );
            }
            // Otherwise...
            else {
                requireData[
                    "FIELD_ID__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex
                ] = {
                    "block": {
                        ...curSectionNode,
                        "field-real-path": nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        ),
                        "field-tab-owner": activeTab
                    },
                    "result": (
                        curSectionMask &&
                        curSectionMask["field-is-required"] ? (
                            partialNodeData !== undefined &&
                            partialNodeData !== null &&
                            partialNodeData !== ""
                        ) : true
                    )
                };
            }
        }
    }

    //--------------------------------------------------------------------------------| NEW

    getTabBodiesHTML = (formData) => {
        // get active tab header
        if (this.state.activeTab &&
            this.state.activeTab !== "") {
            // Render section of tab
            let tmpHTML = this.getTabBodiesHTML_helper(
                this.state.activeTab,
                formData
            );

            // Check for empty section
            if (tmpHTML === null) {
                // Replace content with warning message
                tmpHTML = (
                    <Alert style={{ margin: "0px" }} color="light">
                        <h4 className="alert-heading">Nessun campo disponibile!</h4>
                        <p>
                            Non risultano campi, con visibilità, disponibili per la sezione di scheda selezionata.<br />
                            Le sezioni permettono di focalizzare una porzione specifica della schede per le attività nei progetti.<br />
                            Una volta definito, per sezione e visibilità, i campi saranno consultabili nella sezione corrente.
                        </p>
                        <hr />
                        <p className="mb-0">
                            La sezione corrente non presenta campi visibili o valorizzati da mostrare.
                        </p>
                    </Alert>
                );
            }

            return tmpHTML;
        }

        // Always null
        return <div></div>;
    }

    getTabBodiesHTML_helper = (node, nodeData, nodePath = "", nodeIndex = 0) => {
        // Prepare data
        let curSectionNode = ctxTemplateManager.getTemplateSection(node);
        let curSectionMask = ctxTemplateManager.getVisibilitySection(node);

        // Prepare component data
        let pathSep = ".";
        let isMulti = false;
        let nChilds = [];

        // Prepare partial path
        let partialNodeData = null;
        let supportNodeData = Object.keys(nodeData ? nodeData : {});

        // Check for partial path
        if (supportNodeData.includes(curSectionNode["field-code"])) {
            // Save new path
            partialNodeData = nodeData[curSectionNode["field-code"]];
        }

        // Check for not empty path
        if (nodePath === "") {
            // Concat separator
            pathSep = "";
        }

        // Prepare flag for separator
        let isArrayOfGroupFields = false;

        // Check for block type: PARENT-NODE
        if (curSectionNode &&
            !curSectionNode["field-last-level"]) {
            // Check for group of fields
            if (curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0) {
                // Set as multi field array
                isArrayOfGroupFields = true;
            }

            // Check if children exists
            if (Array.isArray(curSectionNode["field-tree-childs"]) &&
                curSectionNode["field-tree-childs"].length > 0) {
                // Check for array
                if (Array.isArray(partialNodeData)) {
                    // Loop depending on sub data
                    nChilds = partialNodeData.map((subNodeData, index) => {
                        // Delegate to function
                        let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                            return this.getTabBodiesHTML_helper(
                                child,
                                subNodeData,
                                nodePath + pathSep + curSectionNode["field-code"] + pathSep + index,
                                index
                            );
                        });

                        // Filters out null values
                        tmpSubBlocks = tmpSubBlocks.filter(x => x);

                        // Return filtered
                        return tmpSubBlocks;
                    });
                }
                // Otherwise...
                else {
                    // Prepare sub blocks
                    let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                        return this.getTabBodiesHTML_helper(
                            child,
                            partialNodeData,
                            nodePath + pathSep + curSectionNode["field-code"] + (
                                isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                            )
                        );
                    })

                    // Filters out null values
                    tmpSubBlocks = tmpSubBlocks.filter(x => x);

                    // Replace value with filtered version
                    nChilds = tmpSubBlocks.length > 0 ? [tmpSubBlocks] : [];
                }
            }
        }
        // Check for block type: LEAF-NODE
        else {
            // Check for array
            if (Array.isArray(partialNodeData) && (
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Loop depending on sub data
                nChilds = partialNodeData.map((subNodeData, index) => {
                    return this.getTabBodiesHTML_filter(subNodeData) ? (<TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + index}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={index}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + pathSep + index}
                        formData={subNodeData}
                        updateData={this.updateJsonData}
                        updateReport={this.updateReportData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        sendPFCComment={this.sendPFCComment}
                        userCanEdit={this.userCanEdit()}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />) : null;
                });
            }
            // Check for unexpected array
            else if (
                Array.isArray(partialNodeData) && !(
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Loop depending on sub data
                nChilds = [(this.getTabBodiesHTML_filter(partialNodeData[0])) ? (
                    <TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={nodeIndex}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        )}
                        formData={partialNodeData[0]}
                        updateData={this.updateJsonData}
                        updateReport={this.updateReportData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        sendPFCComment={this.sendPFCComment}
                        userCanEdit={this.userCanEdit()}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />
                ) : null];
            }
            // Otherwise...
            else {
                // Create leaf
                nChilds = [(this.getTabBodiesHTML_filter(partialNodeData)) ? (
                    <TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={nodeIndex}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        )}
                        formData={partialNodeData}
                        updateData={this.updateJsonData}
                        updateReport={this.updateReportData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        sendPFCComment={this.sendPFCComment}
                        userCanEdit={this.userCanEdit()}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />
                ) : null];
            }
        }

        // Check for array of component
        if (curSectionNode["field-max-occurs"] > 1 ||
            curSectionNode["field-max-occurs"] <= 0) {
            isMulti = true;
        }

        // Filters out null values
        nChilds = nChilds.filter(x => x);

        // Send back component
        return nChilds.length > 0 ? (<TemplateFieldGroup
            key={"JSX_GROUP__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
            fieldData={curSectionNode}
            fieldMask={curSectionMask}
            fieldChildren={nChilds}
            fieldIndex={nodeIndex}
            fieldRealPath={nodePath + pathSep + curSectionNode["field-code"]}
            formData={partialNodeData}
            isMultiGroup={isMulti}
            isArrayOfGroupFields={isArrayOfGroupFields}
            updateData={this.updateJsonData}
            updateReport={this.updateReportData}
            updateInfo={this.updateInfoData}
            userCanEdit={this.userCanEdit()}
        />) : null;
    }

    getTabBodiesHTML_filter = (/*nodeData*/) => {
        return true;
    }

    updateJsonData = (jsonPath, jsonValue, p_formData = null) => {
        let newJsonData = ctxTemplateManager.updateFormDataProperty(
            p_formData ? p_formData : this.state.formData,
            jsonPath,
            jsonValue
        );

        if (p_formData) {
            return newJsonData;
        }
        else this.setState({
            formData: newJsonData
        });
    }

    updateReportData = (reportPath, reportBlock, reportValue, asInsert = true) => {
        this.setState((prevState) => {
            // Prepare data
            let bakReportData = JSON.parse(JSON.stringify(prevState.requiredInputs));

            // Check action type
            if (asInsert) {
                // Inject data
                bakReportData[reportPath] = {
                    "block": reportBlock,
                    "result": reportValue
                };
            }
            // Otherwise check for Unmount or Delete...
            else if (!asInsert && reportBlock["field-tab-owner"] === this.state.activeTab) {
                // Remove data from state
                delete bakReportData[reportPath];
            }

            // Set new data
            return {
                requiredInputs: bakReportData
            };
        });
    }

    updateInfoData = (fieldData) => {
        // Prepare data
        let infoDesc = null;
        let infoExamples = [];

        // Check for info data
        if (fieldData && fieldData["field-description"]) {
            infoDesc = fieldData["field-description"];
            infoExamples = Array.isArray(
                fieldData["field-desc-example"]
            ) ? fieldData["field-desc-example"] : [];
        }

        // Set new data
        this.setState({
            infoSectionDescription: infoDesc,
            infoSectionExamples: infoExamples
        });
    }

    //--------------------------------------------------------------------------------| END

    toggleUploadFile = () => {
        if (document.activeElement !== document.body) document.activeElement.blur();
        this.setState({
            modalUploadFile: !this.state.modalUploadFile
        }, () => {
            if (!this.state.modalUploadFile)
                setTimeout(() => {
                    this.setState({ lastIdFocussed: '' })
                }, 2000);
        })
        document.getElementById("root").focus();

    }

    scrollNavRight = (event) => {
        let nav = $(event.currentTarget).parent().find("ul.nav");
        let previousScrollAmount = nav.scrollLeft();
        nav.animate({ scrollLeft: previousScrollAmount - 200 }, 200);
    }

    scrollNavLeft = (event) => {
        let nav = $(event.currentTarget).parent().find("ul.nav");
        let previousScrollAmount = nav.scrollLeft();
        nav.animate({ scrollLeft: previousScrollAmount + 200 }, 200);
    }

    toggleMediaModal = (parentObj = null) => {
        /*this.setState({ showMediaModal: !this.state.showMediaModal }, () => {
          if (this.state.showMediaModal) {
            this.getMediaModalData(parentObj);
          }
        });*/
        this.setState({
            showMediaModal: !this.state.showMediaModal,
            showMediaNodeObj: parentObj
        });
      }

      getMediaModalData = () => {
        // Generate JSON of media
        /*let mediaJSON = {};
        if (ctxTemplateManager) {
          // Get all input-file fields from TemplateManager
          let visibileInputFiles = ctxTemplateManager.getTemplateSectionsOfType("input-file");
          for (let i = 0; i < visibileInputFiles.length; i++) {
            let currentInput = visibileInputFiles[i]["field-edusn-srvid"];
            // Generate an entry in the JSON for every field
            mediaJSON[currentInput] = {};
            // Label
            mediaJSON[currentInput].label = visibileInputFiles[i]["field-name"];
            // Field to check field multeplicity
            mediaJSON[currentInput].parametrizedPath = visibileInputFiles[i]["field-edusn-xpath-p"];
            mediaJSON[currentInput].isArray = visibileInputFiles[i]["field-edusn-xpath-p"].includes("#");
            // Get data from xpath and TemplateManager
            let currentXPath = visibileInputFiles[i]["field-edusn-xpath"];
            let currentData = ctxTemplateManager.resolveFormDataProperty(this.state.formData, currentXPath.join("."));
            mediaJSON[currentInput].data = currentData;
          }
        }

        return mediaJSON;
        // Test delete
        /*if (visibileInputFiles.length > 0 && mediaJSON["OA-FTAR-S0001"].data.length === 2) {
          mediaJSON["OA-FTAR-S0001"].parametrizedPath.pop();
          mediaJSON["OA-FTAR-S0001"].parametrizedPath.pop();
          let paramPath = mediaJSON["OA-FTAR-S0001"].parametrizedPath.join(".");
          let index = 1;
          let asd = ctxTemplateManager.resolveFormDataProperty(this.state.formData, paramPath);
          if (asd) {
            if (Array.isArray(asd)) {
              asd.splice(index, 1);
            }
            else {
              delete asd[index];
            }
            this.updateJsonData(paramPath, asd);
          }
        }*/

        if (this.state.showMediaNodeObj) {
            let nodeToShow = this.state.showMediaNodeObj;
            let dataToShow = this.state.formData;
            let arPathBase = nodeToShow["field-edusn-xpath"].slice(0);

            if (arPathBase.length > 0) {
                arPathBase.pop()
            }

            arPathBase.map((curNode) => {
                if (dataToShow[curNode]) {
                    dataToShow = dataToShow[curNode];
                }
                return true;
            });

            if (nodeToShow &&
                nodeToShow["field-edusn-srvid"]) {
                let tmpHTML = this.getTabBodiesHTML_helper(
                    nodeToShow["field-edusn-srvid"],
                    dataToShow,
                    arPathBase.join(".")
                );

                // Check for empty section
                if (tmpHTML === null) {
                    // Replace content with warning message
                    tmpHTML = (
                        <Alert style={{ margin: "0px" }} color="light">
                            <h4 className="alert-heading">Nessun campo disponibile!</h4>
                            <p>
                                Non risultano campi, con visibilità, disponibili per la sezione di scheda selezionata.<br />
                                Le sezioni permettono di focalizzare una porzione specifica della schede per le attività nei progetti.<br />
                                Una volta definito, per sezione e visibilità, i campi saranno consultabili nella sezione corrente.
                            </p>
                            <hr />
                            <p className="mb-0">
                                La sezione corrente non presenta campi visibili o valorizzati da mostrare.
                            </p>
                        </Alert>
                    );
                }

                return tmpHTML;
            }
        }

        return <div></div>;
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?' +                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' +                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' +                            // query string
            '(\\#[-a-z\\d_]*)?$', 'i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardImage = () => {
        let coreImage = EdubbaDefaultImage;

        if (ctxTemplateManager) {
            let tmpCoreImage = ctxTemplateManager.resolveTemplateProperty(this.state.formData, "card_image");

            if (tmpCoreImage &&
                this.isValidURL(tmpCoreImage)) {
                coreImage = tmpCoreImage;
            }
        }

        return coreImage;
    }

    getBGCardImage = () => {
        let coreImage = null;

        if (ctxTemplateManager) {
            let tmpCoreImage = ctxTemplateManager.resolveTemplateProperty(this.state.formData, "card_image");

            if (tmpCoreImage &&
                this.isValidURL(tmpCoreImage)) {
                coreImage = tmpCoreImage;
            }
        }

        return coreImage;
    }

    getCardAttributeByPathArray = (card, pathArray) => {
        if (pathArray.length > 0) {
            if (card && card[pathArray[0]]) {
                let nestedCard = card[pathArray[0]];
                pathArray.shift();
                return this.getCardAttributeByPathArray(nestedCard, pathArray);
            }
            else return undefined;
        }
        return (typeof card === "object") ? undefined : card;
    }

    getCardTitle = () => {
        let title = ctxTemplateManager.resolveTemplateProperty(this.state.formData, "card_title");
        return title ? title : "- - -";
    }

    getCardProject = () => {
        //console.log(this.state)
    }

    getCardAttributeByPathArray = (card, pathArray) => {
        if (pathArray.length > 0) {
            if (card && card[pathArray[0]]) {
                let nestedCard = card[pathArray[0]];
                pathArray.shift();
                return this.getCardAttributeByPathArray(nestedCard, pathArray);
            }
            else return undefined;
        }
        return (typeof card === "object") ? undefined : card;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    render() {
        let modalConatiners = {
            "enrich": [],
            "iccd": []
        };

        if (Array.isArray(this.state.mediaModalComponentsArray)) {
            this.state.mediaModalComponentsArray.map((obj) => {
                // Get mask info for node
                let maskInfo = ctxTemplateManager.getVisibilitySection(
                    obj["field-edusn-srvid"]
                );

                // Extract flag enrichment
                let isEnrich = maskInfo["field-is-enrichment"];

                // Extract icon
                let maskIcon = maskInfo["field-is-mediamodal"];
                let maskText = "";

                // Normalize icon
                if (maskIcon === "fas fa-file-file") {
                maskIcon = faFile;
                maskText = "altri";
                }
                else if (maskIcon === "fas fa-file-image") {
                maskIcon = faFileImage;
                maskText = "foto";
                }
                else if (maskIcon === "fas fa-file-video") {
                maskIcon = faFileVideo;
                maskText = "video";
                }
                else if (maskIcon === "fas fa-file-archive") {
                maskIcon = faFileArchive;
                maskText = "fonti";
                }
                else if (maskIcon === "fas fa-file-alt") {
                maskIcon = faFileAlt;
                maskText = "rif.";
                }
                else return null;

                // Generate HTML
                let htmlIcon = (
                    <span key={"span_mediamodal_content__" + obj["field-edusn-srvid"]}>
                        <FontAwesomeIcon
                            key={"mediamodal_content__" + obj["field-edusn-srvid"]}
                            icon={maskIcon}
                            className='icon'
                            onClick={() => this.toggleMediaModal(obj)}
                            style={{fontSize: "2em"}}
                            data-tip data-for={"TIPS_FOR_mediamodal_content__" + obj["field-edusn-srvid"]}
                        />
                        <span>{maskText}</span>
                        <ReactTooltip
                            id={"TIPS_FOR_mediamodal_content__" + obj["field-edusn-srvid"]}
                            wrapper="div"
                            place="top"
                            effect="solid">
                            {(isEnrich ? "Arricchimento: " : "ICCD: ") + obj["field-name"]}
                        </ReactTooltip>
                    </span>
                );

                // Check for enrich
                if (isEnrich) {
                    // Save into enrich storage
                    modalConatiners.enrich.push(htmlIcon);
                }
                else {
                    // Save into iccd storage
                    modalConatiners.iccd.push(htmlIcon);
                }

                // Always return
                return true;
            });
        }

        return (
            <Fragment>
                {/* V MODALE INFO PHASE 1 (SELECT TEMPLATE): -- modalSelectProject -- V */}
                {!this.state.skipAllModalSelection ?
                    <Modal className='edubba-modal custom-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalSelectProject}
                        backdrop='static'
                    >
                        <ModalHeader toggle={this.toggleCreateNewAssignment}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3'>
                                        <i className='pe-7s-note2 icon pe' />
                                    </div>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Selezione dell'attività
                                </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row className='custom-row with-textarea'>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        <div className='name'>
                                            PROGETTO DI RIFERIMENTO
                                            </div>
                                        <span className='rowize-select'>
                                            <select
                                                className='legacy-form-control'
                                                name='assignmentType'
                                                value={this.state.selectedProject}
                                                onChange={this.handleProjectSelect}
                                            >
                                                <option key="key-0" value="" disabled={true}>Seleziona progetto...</option>
                                                {this.state.projects.map((obj, index) => {
                                                    return <option key={obj.project_uuid} value={obj.project_uuid}>
                                                        {obj.project_name}
                                                    </option>;
                                                })}
                                            </select>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        <div className='name'>
                                            SELEZIONARE L'ATTIVITÀ
                                        </div>
                                        <span className='white-row'>
                                            <select
                                                className='legacy-form-control'
                                                name='assignmentType'
                                                value={this.state.selectedAssignment}
                                                onChange={this.handleAssignmentSelect}
                                            >
                                                <option key="key-0" value="" disabled={true}>Seleziona attività...</option>
                                                {this.state.projects.map((obj, index) => {
                                                    if (obj.project_uuid === this.state.selectedProject) {
                                                        return obj.project_assignments.map(assignment => {
                                                            // Check if assign is not enabled for task
                                                            if (!assignTypeEnabled.includes(
                                                                assignment.assign_type
                                                            )) { return null; }
                                                            // Otherwise show
                                                            else return <option
                                                                key={assignment.assign_uuid}
                                                                value={assignment.assign_uuid}
                                                            >
                                                                {assignment.assign_descr}
                                                            </option>;
                                                        })
                                                    }
                                                    return false;
                                                })}
                                            </select>
                                        </span>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.toggleAnnulla}>
                                Annulla
                            </Button>
                            <Button
                                className='btn-edubba'
                                disabled={this.state.selectedAssignment === ""}
                                onClick={this.selectProject}
                            >Salva e Continua</Button>
                        </ModalFooter>
                    </Modal>
                : null}
                {/* ^ MODALE INFO PHASE 1 (SELECT PROJECT): -- modalSelectProject -- ^ */}
                {/* V MODALE INFO PHASE 2 (SELECT TEMPLATE): -- modalSelectTemplate -- V */}
                {this.state.modalSelectTemplate ?
                    <SelectTemplateModal
                        toggleSelectTemplate={this.toggleSelectTemplate}
                        selectTemplate={this.selectTemplate}
                        checkWithAssign={this.state.selectedAssignment}
                        singleSelect={true}
                    /> : null}
                {/* ^ MODALE INFO PHASE 2 (SELECT TEMPLATE): -- modalSelectTemplate -- ^ */}
                {/* */}
                {this.state.templateToRender ? <Container className='StudentView'>
                    <div className="top-header" style={{ backgroundImage: "url(" + this.getBGCardImage() + ")" }}>
                        <div className="top-header-transparency">
                            <div className="top-header-img-area">
                                <img className="top-header-img" src={this.getCardImage()} alt="" onError={this.setDefaultImgSrc} />
                            </div>
                            <div className="top-header-info-area">
                                <div className="top-header-woa-title">
                                    {this.getCardTitle()}
                                </div>
                                {/*<div className="top-header-woa-project">
                                    {this.getCardProject()}
                                </div>*/}
                                <div className="top-header-woa-media-buttons">
                                    {
                                        modalConatiners.enrich.length > 0 ?
                                        <div className="woa-enrich-container">
                                            <span>enrich</span>
                                            {
                                                modalConatiners.enrich.map((obj) => {
                                                    // Send back data
                                                    return obj;
                                                })
                                            }
                                        </div>
                                        : null
                                    }
                                    {
                                        modalConatiners.iccd.length > 0 ?
                                        <div className="woa-iccd-container">
                                            <span>iccd</span>
                                            {
                                                modalConatiners.iccd.map((obj) => {
                                                    // Send back data
                                                    return obj;
                                                })
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* V MediaModal V */}
                    <Modal className='edubba-modal media-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        isOpen={this.state.showMediaModal}
                        toggle={() => this.toggleMediaModal()}
                        backdrop='static'
                    >
                        <ModalHeader>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                            <div className='widget-content-left text-center w-100'>
                                Media allegati
                            </div>
                            </div>
                        </div>
                        </ModalHeader>
                        <ModalBody>
                            <PerfectScrollbar>
                                <Row className='tab-body RootCardSection'>
                                    <Col xs="8">
                                        <div className="CardContent">
                                            <div className="CardHeader">
                                            CONTENUTO SCHEDA
                                            </div>
                                            <div className="CardBody">
                                                {this.getMediaModalData()}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="4">
                                        <div className="InfoContent">
                                            <div className="InfoHeader">
                                            INFORMAZIONI
                                            </div>
                                            <div className="InfoOverflow">{
                                            this.state.infoSectionDescription ?
                                                <Fragment>
                                                <div className="InfoDesc">
                                                    <b>Descrizione:</b><br />
                                                    {this.state.infoSectionDescription}
                                                </div>
                                                {
                                                    this.state.infoSectionExamples.length > 0 ?
                                                    <div className="InfoExample">
                                                        <b>Esempi:</b><br />
                                                        {
                                                        this.state.infoSectionExamples.map((example) => {
                                                            return (
                                                            <div>{example}</div>
                                                            );
                                                        })
                                                        }
                                                    </div>
                                                    : null
                                                }
                                                </Fragment>
                                                : (
                                                <Alert style={{ margin: "0px" }} color="light">
                                                    <p>
                                                    Non risultano descrizioni disponibili per il campo della scheda selezionato.
                                                    Le descrizioni permettono di compilare le parti della scheda con maggior facilità e coerenza.
                                                        </p>
                                                    <hr />
                                                    <p className="mb-0">
                                                    Per visionare l'help dei campi fare click sul tasto
                                                            <FontAwesomeIcon icon={faQuestion} className="HelpQuestionIcon" />
                                                    posizionato a destra del titolo del campo.
                                                        </p>
                                                </Alert>
                                                )
                                            }</div>
                                        </div>
                                    </Col>
                                </Row>
                            </PerfectScrollbar>
                        </ModalBody>
                        <ModalFooter>
                        <Button className='btn-edubba' onClick={event => {
                            event.preventDefault();
                            this.toggleMediaModal();
                        }}>
                            Chiudi
                        </Button>
                        </ModalFooter>
                    </Modal>

                    <Row
                        className='tab-header'
                        style={this.state.card_image && false ? { backgroundImage: "url(" + this.state.card_image + ")" } : null}
                    >
                        <div className="tab-header-transparency">
                            <div className="">
                                <div className="nav-scroller-right" onClick={this.scrollNavRight}>
                                    <FontAwesomeIcon icon={faCaretLeft} />
                                </div>
                                <Nav justified>
                                    {
                                        (this.state.headersToRender.length > 0) ?
                                            this.state.headersToRender.map((tab) => {
                                                return (
                                                    <NavItem key={`header-${tab["field-edusn-srvid"]}`}>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === tab["field-edusn-srvid"] })}
                                                            onClick={() => {
                                                                this.toggle(tab["field-edusn-srvid"]);
                                                            }}
                                                        >
                                                            {tab["field-name"]}
                                                        </NavLink>
                                                    </NavItem>
                                                );
                                            })
                                            : null
                                    }
                                </Nav>
                                <div className="nav-scroller-left" onClick={this.scrollNavLeft}>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className='tab-body RootCardSection' style={{ padding: "40px 0px 30px 0px" }}>
                        <Col xs="8">
                            <div className="CardContent">
                                <div className="CardHeader">
                                    CONTENUTO SCHEDA
                                </div>
                                <div className="CardBody">
                                    <TabContent activeTab={this.state.activeTab}>
                                        {
                                            this.getTabBodiesHTML(this.state.formData)
                                        }
                                    </TabContent>
                                </div>
                            </div>
                        </Col>
                        <Col xs="4">
                            <div className="InfoContent">
                                <div className="InfoHeader">
                                INFORMAZIONI
                                </div>
                                <div className="InfoOverflow">{
                                    this.state.infoSectionDescription ?
                                    <Fragment>
                                        <div className="InfoDesc">
                                            <b>Descrizione:</b><br />
                                            {this.state.infoSectionDescription}
                                        </div>
                                        {
                                            this.state.infoSectionExamples.length > 0 ?
                                            <div className="InfoExample">
                                                <b>Esempi:</b><br />
                                                {
                                                this.state.infoSectionExamples.map((example) => {
                                                    return (
                                                    <div>{example}</div>
                                                    );
                                                })
                                                }
                                            </div>
                                            : null
                                        }
                                    </Fragment>
                                    : (
                                        <Alert style={{ margin: "0px" }} color="light">
                                            <p>
                                            Non risultano descrizioni disponibili per il campo della scheda selezionato.
                                            Le descrizioni permettono di compilare le parti della scheda con maggior facilità e coerenza.
                                                </p>
                                            <hr />
                                            <p className="mb-0">
                                            Per visionare l'help dei campi fare click sul tasto
                                                    <FontAwesomeIcon icon={faQuestion} className="HelpQuestionIcon" />
                                            posizionato a destra del titolo del campo.
                                                </p>
                                        </Alert>
                                    )
                                }</div>
                            </div>
                        </Col>
                    </Row>
                    <div className='footer-buttons'>
                        <button className='btn btn-edubba btn-edubba-red'
                            type="button"
                            onClick={event => {
                                event.preventDefault();
                                this.toggleAnnulla();
                            }}>
                            Annulla
                        </button>
                        <button className={'btn btn-edubba'}
                            type="submit"
                            disabled={this.state.cardCompiling ? false : false}
                            onClick={this.state.cardCompiling ? this.handleCreateCard : this.continueCardCreation}>
                            {this.state.cardCompiling ? "Crea Scheda" : "Avanti"}
                        </button>
                    </div>
                </Container> : null}
                {/* V MODAL ANNULLA: -- modalAnnulla -- V */}
                <Modal className='edubba-modal select-template-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalAnnulla}
                    toggle={this.toggleAnnulla}
                    backdrop='static'
                >
                    <ModalHeader toggle={this.toggleAnnulla}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon icon={faQuestion} className='icon' />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Attenzione
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='inner-modal'>
                                    Sicuro di voler annullare la creazione della Scheda?
                                    <br />
                                    Tutte le modifiche saranno perse.
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg='6' md='6' sm='12' className='inner-modal'>
                                    <Button className='btn-edubba' onClick={event => {
                                        event.preventDefault();
                                        // this.props.history.push('/cards');
                                        this.toggleAnnulla();
                                    }}>
                                        Annulla
                                    </Button>
                                </Col>
                                <Col lg='6' md='6' sm='12' className='inner-modal'>
                                    <Button className='btn-edubba' onClick={event => {
                                        event.preventDefault();
                                        this.props.history.push('/');

                                    }}>
                                        Conferma
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
                {/* ^ MODAL ANNULLA: -- modalAnnulla -- ^ */}
                <Modal className='edubba-modal select-template-modal check-required-inputs'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalCheckRequireAnnulla}
                    toggle={this.toggleCheckRequireAnnulla}
                    backdrop='static'>
                    <Alert color="danger">
                        <h4 className="alert-heading">Compilare campi obbligatori!</h4>
                        <hr />
                        <p>
                            Prima di procedere è necessario compilare tutti i campi obbligatori presenti nella scheda. Numero <b>{this.state.brokenRequiredInputs.length}</b> campi obbligatori non risultano compilati.
                        </p>
                        <p className="mb-0">
                            <Button className='btn-edubba' onClick={event => {
                                event.preventDefault();
                                this.toggleCheckRequireAnnulla();
                            }}>
                                Chiudi e Continua le Modifiche
                            </Button>
                        </p>
                    </Alert>
                </Modal>
            </Fragment >
        );
    }
}

//----------------------------------------------------------------------| TEMPLATE FIELDS

class TemplateFieldGroup extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldIndex: Number => Index array
        // fieldRealPath: String => Path calcolato con index
        // fieldChildren: Array [{}] => TemplateField
        // formData: Object {} => Data
        // isMultiGroup: Boolean
        // isArrayOfGroupFields: Boolean
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        this.state = {};
    }

    addNode = () => {
        let tmpValues = this.props.formData;

        if (!Array.isArray(tmpValues)) {
            tmpValues = [tmpValues];
        }

        tmpValues.push(null);

        this.props.updateData(
            this.props.fieldRealPath,
            tmpValues
        );
    }

    delNode = () => {
        let minOccurs = this.props.fieldData["field-min-occurs"];
        let tmpValues = this.props.formData;

        if (Array.isArray(tmpValues) &&
            tmpValues.length > minOccurs) {
            tmpValues.pop();

            this.props.updateData(
                this.props.fieldRealPath,
                tmpValues
            );
        }
    }

    infoNode = () => {
        this.props.updateInfo(
          this.props.fieldData
        );
      }

    render() {
        let isOdd = this.props.fieldData["field-tree-level"] % 2 !== 0;

        return (
            <div
                name={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                key={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                className={"TemplateFieldGroup" + (isOdd ? " TemplateFieldGroupOdd" : "")}>
                <div className={"SectionHeader" + (isOdd ? " SectionHeaderOdd" : "")}>
                    <div className="SectionName">
                        {this.props.fieldData["field-name"].toLowerCase()}
                    </div>
                    <div className="SectionMultiBtn">
                        <FontAwesomeIcon icon={faQuestion} className="MultiBtn Info" onClick={this.infoNode} />
                        {
                            this.props.isMultiGroup && this.props.userCanEdit ?
                                <Fragment>
                                    <FontAwesomeIcon icon={faPlus}
                                        className="MultiBtn Plus"
                                        onClick={this.addNode} />
                                    <FontAwesomeIcon icon={faTrash}
                                        className="MultiBtn Minus"
                                        onClick={this.delNode} />
                                </Fragment>
                                : null
                        }
                    </div>
                </div>
                <div className="SectionBody">
                    {this.props.fieldChildren.map((child, index) => {
                        // Check for group of fields and multigroup +/-
                        let isMultiWrapper = (
                            this.props.isMultiGroup &&
                            this.props.isArrayOfGroupFields
                        );

                        // Render childs
                        return (
                            <div
                                key={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex + "__CHILD_" + index}
                                className={(isMultiWrapper ? "SectionMultiEffect" : "") + (isMultiWrapper && isOdd ? " SectionMultiEffect-Odd" : "")}>
                                {child}
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    };
}

class TemplateField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldIndex: Number => Index array
        // fieldRealPath: String => Path calcolato con index
        // formData: Object {} => Data
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // pfcFetchedComments: Object {} => Data
        // userCanEdit: Boolean
        // toggleMapModal: Function
        // toggleUploadFile: Function

        this.state = {
            pfcFetchedComments: props.pfcFetchedComments,
            pfcCommentsInEdit: props.pfcCommentsInEdit
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.pfcFetchedComments) !== JSON.stringify(state.pfcFetchedComments) ||
            JSON.stringify(props.pfcCommentsInEdit) !== JSON.stringify(state.pfcCommentsInEdit)) {
            return {
                pfcFetchedComments: props.pfcFetchedComments,
                pfcCommentsInEdit: props.pfcCommentsInEdit
            };
        }
        return null;
    }

    render() {
        let inputTypeToRender = null;
        let isOdd = this.props.fieldData["field-tree-level"] % 2 !== 0;

        if (this.props.fieldData["field-input-descriptor"] &&
            this.props.fieldData["field-input-descriptor"]["input-type"]) {
            switch (this.props.fieldData["field-input-descriptor"]["input-type"]) {
                case "input-text":
                    // Delegate...
                    inputTypeToRender = <TemplateInputTextField
                        fieldData={this.props.fieldData}
                        fieldMask={this.props.fieldMask}
                        fieldIndex={this.props.fieldIndex}
                        fieldTab={this.props.fieldTab}
                        formData={this.props.formData}
                        updateData={this.props.updateData}
                        updateReport={this.props.updateReport}
                        fieldRealPath={this.props.fieldRealPath}
                        userCanEdit={this.props.userCanEdit}
                    />
                    break;

                case "textarea":
                    // Delegate...
                    inputTypeToRender = <TemplateTextAreaField
                        fieldData={this.props.fieldData}
                        fieldMask={this.props.fieldMask}
                        fieldIndex={this.props.fieldIndex}
                        fieldTab={this.props.fieldTab}
                        formData={this.props.formData}
                        updateData={this.props.updateData}
                        updateReport={this.props.updateReport}
                        fieldRealPath={this.props.fieldRealPath}
                        userCanEdit={this.props.userCanEdit}
                    />
                    break;

                case "input-file":
                    // Delegate...
                    inputTypeToRender = <TemplateInputFileField
                        fieldData={this.props.fieldData}
                        fieldMask={this.props.fieldMask}
                        fieldIndex={this.props.fieldIndex}
                        fieldTab={this.props.fieldTab}
                        formData={this.props.formData}
                        updateData={this.props.updateData}
                        updateReport={this.props.updateReport}
                        fieldRealPath={this.props.fieldRealPath}
                        userCanEdit={this.props.userCanEdit}
                        toggleUploadFile={this.props.toggleUploadFile}
                    />
                    break;

                case "input-map":
                    // Delegate...
                    inputTypeToRender = <TemplateInputMapField
                        fieldData={this.props.fieldData}
                        fieldMask={this.props.fieldMask}
                        fieldIndex={this.props.fieldIndex}
                        fieldTab={this.props.fieldTab}
                        formData={this.props.formData}
                        updateData={this.props.updateData}
                        updateReport={this.props.updateReport}
                        fieldRealPath={this.props.fieldRealPath}
                        userCanEdit={this.props.userCanEdit}
                        toggleMapModal={this.props.toggleMapModal}
                    />
                    break;

                // Nothing
                default: break;
            }
        }

        return (
            <div
                name={"INPUT__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                key={"INPUT__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                className={"TemplateField FieldRow" + (isOdd ? " FieldOdd FieldRowOdd" : "")}>
                {inputTypeToRender}
            </div>
        )
    };
}

//----------------------------------------------------------------------| TEMPLATE FIELDS: INPUT TYPE

class TemplateInputTextField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldTab: String => Header di appartenenza
        // formData: Object {} => Data
        // fieldRealPath: String => Path calcolato con index
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        // Get data from props
        let currentVal = props.formData;

        // Check if property with lang
        if (currentVal             &&
            currentVal["value"]    &&
            currentVal["language"] &&
            currentVal["profile"]) {
            // Get correct value
            currentVal = currentVal["value"];

            // Save new value into JSON
            props.updateData(
                props.fieldRealPath,
                currentVal
            );
        }

        this.state = {
            currentValue: currentVal ? currentVal : ""
        };
    }

    componentDidMount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                    : true
            )
        );
    }

    componentWillUnmount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                    : true
            ),
            false
        );
    }

    handleChange = (event) => {
        this.setState({
            currentValue: event.target.value
        },
            () => {
                this.props.updateData(
                    this.props.fieldRealPath,
                    this.state.currentValue
                );

                this.props.updateReport(
                    "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
                    {
                        ...this.props.fieldData,
                        "field-real-path": this.props.fieldRealPath,
                        "field-tab-owner": this.props.fieldTab
                    },
                    (
                        this.props.fieldMask["field-is-required"] ?
                            (this.state.currentValue !== "")
                            : true
                    )
                );
            });
    }

    render() {
        let isRequired = this.props.fieldMask["field-is-required"];
        let isErrored = this.state.currentValue === "" && isRequired;

        return (
            <div className={"FieldInputBaseRender" + (isRequired ? " RequiredField" : "") + (isErrored ? " RequiredErrored" : "")}>
                <div>
                    <input type="text"
                        id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                        value={this.state.currentValue}
                        onChange={this.handleChange}
                        readOnly={!this.props.fieldMask["field-is-editable"]}
                        disabled={!this.props.userCanEdit}
                    />
                </div>
            </div>
        )
    };
}

class TemplateTextAreaField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldTab: String => Header di appartenenza
        // formData: Object {} => Data
        // fieldRealPath: String => Path calcolato con index
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        // Get data from props
        let currentVal = props.formData;

        // Check if property with lang
        if (currentVal             &&
            currentVal["value"]    &&
            currentVal["language"] &&
            currentVal["profile"]) {
            // Get correct value
            currentVal = currentVal["value"];

            // Save new value into JSON
            props.updateData(
                props.fieldRealPath,
                currentVal
            );
        }

        this.state = {
            currentValue: currentVal ? currentVal : ""
        };
    }

    componentDidMount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                    : true
            )
        );
    }

    componentWillUnmount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                    : true
            ),
            false
        );
    }

    handleChange = (event) => {
        this.setState({
            currentValue: event.target.value
        },
            () => {
                this.props.updateData(
                    this.props.fieldRealPath,
                    this.state.currentValue
                );

                this.props.updateReport(
                    "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
                    {
                        ...this.props.fieldData,
                        "field-real-path": this.props.fieldRealPath,
                        "field-tab-owner": this.props.fieldTab
                    },
                    (
                        this.props.fieldMask["field-is-required"] ?
                            (this.state.currentValue !== "")
                            : true
                    )
                );
            });
    }

    render() {
        let isRequired = this.props.fieldMask["field-is-required"];
        let isErrored = this.state.currentValue === "" && isRequired;

        return (
            <div className={"FieldInputBaseRender" + (isRequired ? " RequiredField" : "") + (isErrored ? " RequiredErrored" : "")}>
                <div>
                    <textarea
                        id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                        onChange={this.handleChange}
                        disabled={!this.props.userCanEdit}
                        value={this.state.currentValue}>
                    </textarea>
                </div>
            </div>
        )
    };
}
class TemplateInputFileField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldTab: String => Header di appartenenza
        // formData: Object {} => Data
        // fieldRealPath: String => Path calcolato con index
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        // Get data from props
        let currentVal = props.formData;

        // Check if property with lang
        if (currentVal             &&
            currentVal["value"]    &&
            currentVal["language"] &&
            currentVal["profile"]) {
            // Get correct value
            currentVal = currentVal["value"];

            // Save new value into JSON
            props.updateData(
                props.fieldRealPath,
                currentVal
            );
        }

        this.state = {
            currentValue: currentVal ? currentVal : "",
            modalUploadFile: false,
            updating: false
        };
    }

    componentDidMount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                    : true
            )
        );
    }

    componentWillUnmount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                    : true
            ),
            false
        );
    }

    handleChange = (event) => {
        this.setState({
            currentValue: event.target.value,
            updating: true
        },
            () => {
                this.props.updateData(
                    this.props.fieldRealPath,
                    this.state.currentValue
                );

                this.props.updateReport(
                    "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
                    {
                        ...this.props.fieldData,
                        "field-real-path": this.props.fieldRealPath,
                        "field-tab-owner": this.props.fieldTab
                    },
                    (
                        this.props.fieldMask["field-is-required"] ?
                            (this.state.currentValue !== "")
                            : true
                    )
                );
            });
    }

    toggleUploadFile = () => {
        this.setState({
            modalUploadFile: !this.state.modalUploadFile
        });
    }

    setImageValueURL = (data) => {
        // Get ID of field
        let idField = "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex;

        // Get descriptor and prototype from DOM/OBJECT of value attribute
        let nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            "value"
        ).set;

        // Simulate React.setValue call from prototype
        nativeInputValueSetter.call($("#" + idField)[0], data.fileUrl);

        // Trigger prototype onChange event
        $("#" + idField)[0].dispatchEvent(
            new Event('input', { bubbles: true })
        );

        // Close modal
        this.setState({
            modalUploadFile: false
        });
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaImgDefault;
    }

    isEmpty(data) {
        if (data &&
            data !== "" &&
            data !== 0) {
            return false;
        }
        return true;
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    render() {
        let isRequired = this.props.fieldMask["field-is-required"];
        let isErrored = this.state.currentValue === "" && isRequired;

        return (
            <div className={"FieldInputBaseRender" + (isRequired ? " RequiredField" : "") + (isErrored ? " RequiredErrored" : "")}>
                <div style={{ marginBottom: "2px" }}>
                    <input type="text"
                        id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                        value={this.state.currentValue}
                        onChange={this.handleChange}
                        disabled={!this.props.userCanEdit}
                    />
                </div>
                {
                    !this.isEmpty(this.state.currentValue) ?
                    <div className="ContainerImgPreview">
                        <img src={
                            this.isValidURL(
                                this.state.currentValue
                            ) ? this.state.currentValue
                            : EdubbaImgDefault
                        }
                        onError={this.setDefaultImgSrc}
                        alt="" />
                    </div>
                    : null
                }
                <div>
                    <input type="button" value="Seleziona da Media Personali o Nuovo Upload..." onClick={this.toggleUploadFile} disabled={!this.props.userCanEdit} />
                </div>
                {/* V MediaModal V */}
                <Modal className='edubba-modal select-template-modal upload-file'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='lg'
                    isOpen={this.state.modalUploadFile}
                    toggle={this.toggleUploadFile}
                    backdrop='static'>
                    <ModalHeader toggle={this.toggleUploadFile}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon icon={faUpload} className='icon' />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Upload File
                            </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='inner-modal'>
                                    <ImageUploadManager selectCallback={this.setImageValueURL} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        )
    };
}

class TemplateInputMapField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // formData: Object {} => Data
        // fieldRealPath: String => Path calcolato con index
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        this.state = {
            modalMap: false,
            latitudeValue: 0,
            longitudeValue: 0
        };
    }

    componentDidMount() {
        // Get template reference for LON/LAT
        let refData = this.props.fieldData["field-input-descriptor"] ?
            this.props.fieldData["field-input-descriptor"]["data-payload"] ?
                this.props.fieldData["field-input-descriptor"]["data-payload"]
                : {}
            : {};

        // Prepare references
        let idLon = "FIELD_ID__" + (refData["ref-longitude"] ? refData["ref-longitude"] : "NULL__UNKNOWN") + "__0";
        let idLat = "FIELD_ID__" + (refData["ref-latitude"] ? refData["ref-latitude"] : "NULL__UNKNOWN") + "__0";

        // Set to readonly and get values or return null
        let valLon = ($("#" + idLon).length > 0) ? $("#" + idLon).attr('readonly', true).val() : null;
        let valLat = ($("#" + idLat).length > 0) ? $("#" + idLat).attr('readonly', true).val() : null;

        // Set new values
        this.setState({
            longitudeValue: (valLon && valLon.trim() !== "" && $.isNumeric(valLon.trim())) ? valLon : 0,
            latitudeValue: (valLat && valLat.trim() !== "" && $.isNumeric(valLat.trim())) ? valLat : 0
        });
    }

    setMapCoords = (data) => {
        // Check for data
        if (data) {
            // Set new values
            this.setState({
                latitudeValue: data.lat,
                longitudeValue: data.lon,
                modalMap: false
            });

            // Get template reference for LON/LAT
            let refData = this.props.fieldData["field-input-descriptor"] ?
                this.props.fieldData["field-input-descriptor"]["data-payload"] ?
                    this.props.fieldData["field-input-descriptor"]["data-payload"]
                    : {}
                : {};

            // Prepare references
            let idLon = "FIELD_ID__" + (refData["ref-longitude"] ? refData["ref-longitude"] : "NULL__UNKNOWN") + "__0";
            let idLat = "FIELD_ID__" + (refData["ref-latitude"] ? refData["ref-latitude"] : "NULL__UNKNOWN") + "__0";

            // Get descriptor and prototype from DOM/OBJECT of value attribute
            let nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
            ).set;

            // Check if elements exists
            if ($("#" + idLon).length > 0 &&
                $("#" + idLat).length > 0) {
                // LON: Simulate React.setValue call from prototype
                nativeInputValueSetter.call($("#" + idLon)[0], data.lon);

                // LON: Trigger prototype onChange event
                $("#" + idLon)[0].dispatchEvent(
                    new Event('input', { bubbles: true })
                );

                // LAT: Simulate React.setValue call from prototype
                nativeInputValueSetter.call($("#" + idLat)[0], data.lat);

                // LAT: Trigger prototype onChange event
                $("#" + idLat)[0].dispatchEvent(
                    new Event('input', { bubbles: true })
                );
            }
        }
    }

    toggleMapModal = () => {
        this.setState({
            modalMap: !this.state.modalMap,
        },
            () => {
                this.props.updateData(
                    this.props.fieldRealPath,
                    this.state.currentValue
                );
            });
    }

    render() {
        return (
            <div className="FieldInputBaseRender">
                <div>
                    <input type="button"
                        id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"]}
                        value="Seleziona Coordinate da Mappa..."
                        onClick={this.toggleMapModal}
                        disabled={!this.props.userCanEdit}
                    />
                </div>
                {/* ^ MapModal ^ */}
                <Modal className='edubba-modal select-template-modal upload-file'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='lg'
                    isOpen={this.state.modalMap}
                    toggle={this.toggleMapModal}
                    backdrop='static'
                >
                    <ModalHeader toggle={this.toggleMapModal}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon icon={faMap} className='icon' />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Seleziona sulla Mappa
                            </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='inner-modal'>
                                    <MapSelectorManager
                                        selectCallback={this.setMapCoords}
                                        registeredValue={[
                                            this.state.latitudeValue,
                                            this.state.longitudeValue
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        )
    };
}

//----------------------------------------------------------------------| EXPORT

export default withRouter(CardCreation);
