import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import EdubbaLoader from '../components/EdubbaLoader/EdubbaLoader';
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import {
    faPrint
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Disclaimer.scss';

class Disclaimer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeDisclaimerTab: 'DSCLMR-T01'
        };
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'visibilities';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';

        let setTitle = 'Disclaimer e Informative';
        this.props.setSectionTitle(setTitle);
        let getTypeOf = this.props.match.params.disclaimer_code;
        if (['DSCLMR-T01','DSCLMR-T02'].includes(getTypeOf)) {
            this.setState({
                activeDisclaimerTab: getTypeOf
            })
        }
    }

    componentWillReceiveProps (nextProps) {
        if (this.state.activeDisclaimerTab !== nextProps.match.params.disclaimer_code) {
            let getTypeOf = nextProps.match.params.disclaimer_code;
            if (['DSCLMR-T01','DSCLMR-T02'].includes(getTypeOf)) {
                this.setState({
                    activeDisclaimerTab: getTypeOf
                })
            }
        }

        return null;
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    setActiveTab = (activeTab) => {
        this.setState({
            activeDisclaimerTab: activeTab
        })
    }

    render() {
        return (
            <Fragment>
                {this.state.maskLoading ?
                    <EdubbaLoader />
                    :
                    <div className='Disclaimer'>
                        <div className="FloatPrintBtn" onClick={() => window.print()}>
                            <FontAwesomeIcon icon={faPrint} />
                        </div>
                        <Nav tabs className="DisclaimerTabNav">
                            <NavItem>
                                <NavLink
                                    className={"DisclaimerTabNavLink " + (this.state.activeDisclaimerTab === 'DSCLMR-T01' ? "active" : "")}
                                    onClick={() => { this.setActiveTab('DSCLMR-T01'); }}>
                                    DISCLAIMER TUTOR E CERTIFICATORI
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"DisclaimerTabNavLink " + (this.state.activeDisclaimerTab === 'DSCLMR-T02' ? "active" : "")}
                                    onClick={() => { this.setActiveTab('DSCLMR-T02'); }}>
                                    LIBERATORIA ALUNNI
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeDisclaimerTab} className="DisclaimerTabContent">
                            <TabPane tabId="DSCLMR-T01" className="DisclaimerTabPanel">
                                <Row>
                                    <Col xs="12" className="DisclaimerScroll">
                                        <div className="SectionBlock">
                                            <strong>Verifica del contenuto inserito e/o prodotto dagli Studenti – Responsabilità connesse:</strong>
                                            <p>Per quanto concerne il contenuto degli elaborati, dei commenti, delle immagini e dei video, prodotti o utilizzati dagli Studenti per Edubba, il Tutor ed il Certificatore sono obbligati a verificare attentamente i medesimi prima della pubblicazione.<br />
                                            <br />
                                            In particolare, questi non dovranno contenere materiale, dati e/o informazioni illegali ovvero contro la morale e/o l'ordine pubblico, e/o, comunque, osceni, volgari, diffamatori, abusivi, con contenuto e/o tenore discriminatorio con riferimento a razza, sesso e/o religione, scelte personali e/o politiche e, più in generale, non potranno contenere alcun elemento che possa, anche solo indirettamente o potenzialmente, violare diritti di terzi, e/o le disposizioni normative e regolamentari nazionali e sopranazionali vigenti, non potranno inoltre contenere alcun elemento, dato e/o materiale e/o messaggio che incoraggino terzi a mettere in atto condotte illecite e/o criminose passibili di responsabilità penale o civile.<br />
                                            <br />
                                            Inoltre, tutti i materiali pubblicati non devono violare diritti di proprietà intellettuale, diritti morali, diritti d'autore, o diritti di tutela dei dati personali di terzi, diritti di pubblicazione e, in generale, devono rispettare tutte le previsioni di cui alla legge sul Diritto d'Autore e successive modifiche ed integrazioni (Legge 22 aprile 1941 n. 633 a protezione del diritto d'autore e di altri diritti connessi al suo esercizio), e non devono essere lesivi di altri diritti di proprietà intellettuale e/o industriale.<br />
                                            <br />
                                            Fermo quanto sopra, il Certificatore è il soggetto responsabile del contenuto e della forma del materiale pubblicato.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="DSCLMR-T02" className="DisclaimerTabPanel">
                                <Row>
                                    <Col xs="12" className="DisclaimerScroll">
                                        <div className="SectionBlock">
                                            <strong>Rinuncia al Diritto d’Autore su testi, immagini, fotografie prodotte dagli Studenti:</strong>
                                            <p>In relazione al trasferimento a Databenc del materiale prodotto dagli Studenti in Edubba, fermo restante la conformità di tale materiale ai termini di legge suddetti, già verificata dal Certificatore, gli Studenti devono rinunciare definitivamente ai diritti sull'Opera/e da loro prodotte, in termini di testi, immagini, fotografie, al fine di contribuire al patrimonio di opere creative e culturali che DatabancArt produce e gestisce. A questo scopo ogni Studente, autore in Eduba del materiale suddetto, è tenuto ad associarvi la licenza CC0 (CreativeCommons 0) opportunamente firmata (in caso di Studente minorenne è richiesta la firma del Genitore o Legale Rappresentante). La firma verrà apposta su documento cartaceo riportante la licenza CC0, da parte del genitore. Sarà responsabilità del Docente, che effettua la creazione dell’utenza del singolo studente, assicurarsi di essere in possesso della firma di accettazione della licenza da parte del genitore.<br />
                                            <br />
                                            Di seguito è riportata la licenza <b>CreativeCommons 0</b>.</p>
                                        </div>
                                        <div className="SectionBlock">
                                            <strong>CreativeCommons 0 - Dichiarazione d'Intenti:</strong>
                                            <p>Le leggi della maggior parte degli stati nel mondo conferiscono automaticamente Diritti d'Autore e Connessi (definiti nel prosieguo) all'autore e/o costitutore ed ai suoi aventi causa (ciascuno dei quali, e collettivamente, il "titolare") di un'opera dell'ingegno originale e/o di una banca di dati (ciascuna, un'"Opera").<br />
                                            <br />
                                            Alcuni titolari desiderano rinunciare definitivamente a questi diritti sull'Opera al fine di contribuire ad un patrimonio comune di opere creative, culturali e scientifiche ("Commons"), che il pubblico può sviluppare ulteriormente, modificare, inserire in altre opere, riutilizzare e ridistribuire del tutto liberamente, in qualsiasi forma e per ogni finalità, inclusi senza alcun limite anche scopi commerciali, potendo fare affidamento sul fatto che non sarà intrapresa alcuna azione legale per violazione dei diritti da parte dei titolari. Questi titolari possono contribuire ai Commons per promuovere l'ideale di una cultura libera e la produzione ulteriore di opere creative, culturali e scientifiche, o per costruirsi una reputazione o favorire una maggiore distribuzione delle proprie Opere in parte attraverso l'utilizzo e l'attività di altre persone.<br />
                                            <br />
                                            Per questi e/o per altri scopi e motivazioni, e senza alcuna aspettativa di ulteriore remunerazione o indennizzo, la persona che associa CC0 ad un'Opera (il "Dichiarante") nella misura in cui è titolare di Diritti d'Autore e Connessi sull'Opera, volontariamente sceglie di applicare la licenza CC0 all'Opera e di distribuirla pubblicamente secondo i suoi termini, con la consapevolezza della propria titolarità di Diritti d'Autore e Connessi sull'Opera e del significato e degli effetti che, dal punto di vista giuridico, la licenza CC0 produrrà su tali diritti.<br />
                                            <br /><br />
                                            <b>1. Diritti d'Autore e Connessi.</b><br />
                                            Un'Opera messa a disposizione in base alla licenza CC0 può essere protetta in base al diritto d'autore ed ai diritti a questo connessi od affini ("Diritti d'Autore e Connessi"). I Diritti d'Autore e Connessi includono, ma non sono limitati a, i seguenti:<br />
                                            <br />
                                            </p>
                                            <ol type="I">
                                                <li>diritto di riprodurre, adattare, distribuire, eseguire, esporre, comunicare e tradurre l'Opera;</li>
                                                <li>diritti morali detenuti dall'autore o dagli autori originari e/o dall'artista o dagli artisti interpreti ed esecutori;</li>
                                                <li>diritti all'immagine ed alla riservatezza in riferimento all'immagine di una persona o alla sua raffigurazione in un'Opera;</li>
                                                <li>protezione contro la concorrenza sleale per quel che riguarda l'Opera, con le limitazioni contenute nel successivo par. 4(a);</li>
                                                <li>diritti che riguardano l'estrazione, la diffusione, l'utilizzazione ed il riutilizzo di dati contenuti nell'Opera;</li>
                                                <li>diritti sulle banche di dati (quali quelli risultanti dalla Direttiva 96/9/CE del Parlamento Europeo e del Consiglio del 11 marzo 1996 relativa alla tutela giuridica delle banche di dati e dalle leggi nazionali di attuazione, inclusa ogni modifica o successiva versione di tale direttiva); e</li>
                                                <li>ogni altro diritto simile, equivalente o corrispondente nel mondo che tragga origine da una legge o da un trattato o convenzione internazionale, e dalle rispettive leggi nazionali di attuazione.</li>
                                            </ol>
                                            <br />
                                            <p>
                                            <b>2. Rinuncia.</b><br />
                                            Nella più ampia misura permessa dalla legge applicabile, e senza contravvenire ad essa, il Dichiarante con la presente apertamente, pienamente, permanentemente, irrevocabilmente ed incondizionatamente rinuncia, abbandona e cede ogni proprio Diritto d'Autore e Connesso, ogni relativa pretesa, rivendicazione, causa ed azione, sia al momento nota o ignota (includendo espressamente le pretese presenti come quelle future) relativa all'Opera (i) in tutto il mondo, (ii) per la durata massima prevista dalla legge applicabile o dal diritto internazionale (includendo espressamente le future possibili estensioni del termine di protezione), (iii) per ogni possibile supporto e modalità di distribuzione presente o futura e senza limitazioni riguardanti il numero delle copie, e (iv) per qualunque scopo, incluse, senza limitazione alcuna, le finalità commerciali, pubblicitarie o promozionali (la "Rinuncia"). Il Dichiarante effettua la Rinuncia a beneficio di ogni membro del pubblico largamente inteso ed a discapito dei propri eredi legittimi o testamentari, avendo piena coscienza che tale Rinuncia non potrà essere soggetta a revoca, rescissione, annullamento, termine o ad ogni altra azione intrapresa in base alla legge o all'equità, volta ad interrompere il pacifico godimento dell'Opera da parte del pubblico come indicato espressamente dal Dichiarante nella Dichiarazione d'Intenti.<br />
                                            <br /><br />
                                            <b>3. Licenza Pubblica in Subordine.</b><br />
                                            Se una qualsiasi parte della Rinuncia dovesse per una qualunque ragione essere giudicata invalida od inefficace secondo la legge applicabile, la Rinuncia dovrà comunque essere preservata nella più ampia misura permessa, tenendo conto della Dichiarazione d'Intenti del Dichiarante. Inoltre, in tali casi, il Dichiarante garantisce ad ogni soggetto a cui sia derivato o possa derivare un pregiudizio una licenza gratuita, non trasferibile, non sub-licenziabile, non esclusiva, irrevocabile e non soggetta a condizioni per esercitare i Diritti d'Autore e Connessi del Dichiarante sull'Opera (i) in tutto il mondo, (ii) per la durata massima prevista dalla legge applicabile o dal diritto internazionale (includendo espressamente le future possibili estensioni del termine di protezione), (iii) per ogni possibile supporto e modalità di distribuzione presente o futura e senza limitazioni riguardanti il numero delle copie, e (iv) per qualunque scopo, incluse, senza limitazione alcuna, le finalità commerciali, pubblicitarie o promozionali (la "Licenza"). La Licenza sarà ritenuta valida ed efficace dalla data in cui CC0 fu applicata dal Dichiarante all'Opera. Se una qualsiasi parte della Licenza dovesse per qualunque ragione essere giudicata invalida od inefficace secondo la legge applicabile, tale invalidità parziale od inefficacia non invaliderà il resto della Licenza, ed in tal caso il Dichiarante afferma con la presente che (i) non eserciterà nessuno dei propri residui Diritti d'Autore e Connessi relativi all'Opera o (ii) non agirà in giudizio o in altro modo rivendicherà diritti sull'Opera, laddove ciò sia in contrasto con l'espressa Dichiarazione d'Intenti del Dichiarante.<br />
                                            <br /><br />
                                            <b>4. Limitazioni ed Esenzioni da Responsabilità.</b>
                                            </p>
                                            <ol type="a">
                                                <li>Nessun marchio o brevetto detenuto dal Dichiarante è fatto oggetto di rinuncia, abbandonato, ceduto, licenziato od altrimenti interessato da questo documento.</li>
                                                <li>Il Dichiarante offre l'Opera così com'è e non fornisce nessuna garanzia di alcun tipo sull'Opera, che sia espressa, implicita, derivante dalla legge o altrimenti, includendo, senza limitazione alcuna, garanzie sulla titolarità, commerciabilità, idoneità per un particolare scopo, non violazione di norme, assenza di difetti latenti o di altri difetti, esattezza, presenza od assenza di errori, rinvenibili o meno, nella più ampia misura permessa dalla legge applicabile.</li>
                                                <li>Il Dichiarante declina ogni responsabilità per diritti di proprietà o diritti a compenso che altre persone possano rivendicare sull'Opera o relativamente ad un suo particolare utilizzo, includendo senza limitazioni i Diritti d'Autore e Connessi che altre persone possano rivendicare sull'Opera. Il Dichiarante declina inoltre ogni responsabilità derivante dall' ottenimento di tutti i necessari consensi, permessi o altri diritti richiesti per l'utilizzo dell'Opera.</li>
                                                <li>Il Dichiarante comprende e riconosce che Creative Commons non è parte di questo documento e non ha alcun dovere o obbligazione relativamente alla licenza CC0 od all'utilizzo dell'Opera.</li>
                                            </ol>
                                            <Container>
                                                <Row>
                                                    <Col style={{textAlign: "left"}}>
                                                        <br />
                                                        <br />
                                                        <b style={{textTransform: "uppercase"}}>Dichiarante:</b><br />
                                                        <br />
                                                        <br />
                                                        ________________________________________________________________<br />
                                                        <i>Nome e Cognome dello Studente</i><br />
                                                        <br />
                                                        <br />
                                                        ________________________________________________________________<br />
                                                        <i>Data di Nascita dello Studente</i><br />
                                                    </Col>
                                                    <Col style={{textAlign: "left"}}>
                                                        <br />
                                                        <br />
                                                        <b style={{textTransform: "uppercase"}}>Genitore (o Legale Rappresentante):</b><br />
                                                        <br />
                                                        <br />
                                                        _________________________________________________________________<br />
                                                        <i>Nome e Cognome del Genitore (o del Legale Rappresentante)</i><br />
                                                        <br />
                                                        <br />
                                                        _________________________________________________________________<br />
                                                        <i>Firma del Genitore (o del Legale Rappresentante)</i>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            <br />
                                            <br />
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>}
            </Fragment>
        )
    }
}

export default withRouter(Disclaimer);
