import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Table,
    Alert,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import moment from 'moment';
import globalVars from './../../globalVars.json';

import './ServerStatsDetail.scss';

class ServerStatsDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maskLoading: false,
            currentStats: []
        };
    }

    componentDidMount() {
        let setTitle = (
            holdDataService.loggedUserIsAdmin() ?
            'Dashboard - Statistiche di Sistema' :
            'Dashboard - Statistiche di Piattaforma'
        );
        this.props.setSectionTitle(setTitle);

        this.setState({ maskLoading: true });
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/stats/sys/", {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentStats: res.data.rows[0],
                    maskLoading: false
                });
            }
            else this.setState({
                currentStats: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                currentStats: [],
                maskLoading: false
            });
        });
    }

    formatBytes (bytes, decimals = 2) {
        if (bytes === 0) { return '0 Bytes' };
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    formatDuration (duration) {
        // Calc base
        var sec_num = Math.floor(parseInt(duration, 10) / 1000);
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        // Normalize
        if (hours   < 10) { hours   = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }

        // Send back
        return hours + ':' + minutes + ':' + seconds;
    }

    render() {
        return (
            <Container className='ToolStats'>
                {
                    this.state.maskLoading ? (
                        <EdubbaLoader />
                    ) : this.state.currentStats ? (
                        holdDataService.loggedUserIsAdmin() ? (
                            <StatViewAdmin
                                statData={this.state.currentStats}
                                formatBytes={this.formatBytes}
                                formatDuration={this.formatDuration}
                            />
                        ) : (
                            holdDataService.loggedUserIsSupervisor() ? (
                                <StatViewSupervisor
                                    statData={this.state.currentStats}
                                />
                            ) : (
                                <Row>
                                    <Col lg='12' md='12' sm='12'>
                                        <Alert color="light" className="mt-3">
                                            <h4 className="alert-heading">Ruolo non abilitato!</h4>
                                            <p>
                                                Attenzione il ruolo utente non ha tools con visibilità.
                                            </p>
                                        </Alert>
                                    </Col>
                                </Row>
                            )
                        )
                    ) : (
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <Alert color="light" className="mt-3">
                                    <h4 className="alert-heading">Nessuna statistica trovata!</h4>
                                    <p>
                                        Attenzione la metrica potrebbe non essere attiva o non essere ancora registrata.<br />
                                        Al primo trigger di piattaforma con attività utile la metrica risulterà visibile.
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        );
    }
};

export default withRouter(ServerStatsDetail);

//---------------------------------------------------------| ADMIN VIEW

class StatViewAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container className='ToolStats'>
                <Row key="head-stat-1">
                    <Col lg='12' md='12' sm='12' className="HeadStat">
                        System Monitor:
                    </Col>
                </Row>
                <Row key="body-stat-1">
                    <Col lg='12' md='12' sm='12'>
                        <Table className="ToolStats-Table" responsive striped>
                            <thead>
                                <tr>
                                    <th>Utilizzo Processore</th>
                                    <th>Utilizzo Memoria RAM</th>
                                    <th>Versione NodeJS</th>
                                    <th>Versione PM2</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.monit &&
                                        this.props.statData.systemd.monit.cpu ?
                                        this.props.statData.systemd.monit.cpu :
                                        0
                                    } %</td>
                                    <td>{
                                        this.props.formatBytes(
                                            this.props.statData &&
                                            this.props.statData.systemd &&
                                            this.props.statData.systemd.monit &&
                                            this.props.statData.systemd.monit.memory ?
                                            this.props.statData.systemd.monit.memory :
                                            0
                                        )
                                    }</td>
                                    <td>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.node_version ?
                                        this.props.statData.systemd.pm2_env.node_version :
                                        "- - -"
                                    }</td>
                                    <td>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env._pm2_version ?
                                        this.props.statData.systemd.pm2_env._pm2_version :
                                        "- - -"
                                    }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row key="head-stat-2">
                    <Col lg='12' md='12' sm='12' className="HeadStat">
                        Process Monitor:
                    </Col>
                </Row>
                <Row key="body-stat-2">
                    <Col lg='12' md='12' sm='12'>
                        <Table className="ToolStats-Table" responsive striped>
                            <thead>
                                <tr>
                                    <th>Nome Processo</th>
                                    <th>Uptime Processo</th>
                                    <th>Stato Processo</th>
                                    <th>Modalità Esecuzione</th>
                                    <th>Data Creazione</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.name ?
                                        this.props.statData.systemd.name :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.formatDuration(
                                            this.props.statData &&
                                            this.props.statData.systemd &&
                                            this.props.statData.systemd.pm2_env &&
                                            this.props.statData.systemd.pm2_env.pm_uptime ?
                                            this.props.statData.systemd.pm2_env.pm_uptime :
                                            0
                                        )
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.status ?
                                        this.props.statData.systemd.pm2_env.status :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.exec_mode ?
                                        this.props.statData.systemd.pm2_env.exec_mode :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.created_at ?
                                        moment(
                                            this.props.statData.systemd.pm2_env.created_at
                                        ).format('DD/MM/YY H:m:s') :
                                        "- - -"
                                    }</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>ID Processo</th>
                                    <th>Restart Instabili</th>
                                    <th>Restart Stabili</th>
                                    <th>Auto-Restart</th>
                                    <th>Monitorato</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pid ?
                                        this.props.statData.systemd.pid :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.unstable_restarts !== null ?
                                        this.props.statData.systemd.pm2_env.unstable_restarts :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.restart_time !== null ?
                                        this.props.statData.systemd.pm2_env.restart_time :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.autorestart ?
                                        "Yes" :
                                        "No"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.systemd &&
                                        this.props.statData.systemd.pm2_env &&
                                        this.props.statData.systemd.pm2_env.watch ?
                                        "Yes" :
                                        "No"
                                    }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row key="head-stat-3">
                    <Col lg='12' md='12' sm='12' className="HeadStat">
                        Database Monitor:
                    </Col>
                </Row>
                <Row key="body-stat-3">
                    <Col lg='12' md='12' sm='12'>
                        <Table className="ToolStats-Table" responsive striped>
                            <thead>
                                <tr>
                                    <th>Sessioni Attive</th>
                                    <th>Utenze Totali</th>
                                    <th>Ruoli Totali</th>
                                    <th>Studenti Totali</th>
                                    <th>Docenti Totali</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_active_sessions !== null ?
                                        this.props.statData.entities.total_active_sessions :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_users !== null ?
                                        this.props.statData.entities.total_users :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_roles !== null ?
                                        this.props.statData.entities.total_roles :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_students !== null ?
                                        this.props.statData.entities.total_students :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_teachers !== null ?
                                        this.props.statData.entities.total_teachers :
                                        "- - -"
                                    }</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Certificatori Totali</th>
                                    <th>Supervisori Totali</th>
                                    <th>Amministratori Totali</th>
                                    <th>Team Totali</th>
                                    <th>Media Totali</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_certifiers !== null ?
                                        this.props.statData.entities.total_certifiers :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_supervisors !== null ?
                                        this.props.statData.entities.total_supervisors :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_admins !== null ?
                                        this.props.statData.entities.total_admins :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_groups !== null ?
                                        this.props.statData.entities.total_groups :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_media !== null ?
                                        this.props.statData.entities.total_media :
                                        "- - -"
                                    }</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Instituti Totali</th>
                                    <th>Classi Totali</th>
                                    <th>Chat Totali</th>
                                    <th>Commenti Totali</th>
                                    <th>Progetti Totali</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_institutes !== null ?
                                        this.props.statData.entities.total_institutes :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_classes !== null ?
                                        this.props.statData.entities.total_classes :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_social_rooms !== null ?
                                        this.props.statData.entities.total_social_rooms :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_comments !== null ?
                                        this.props.statData.entities.total_comments :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_projects !== null ?
                                        this.props.statData.entities.total_projects :
                                        "- - -"
                                    }</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Compiti Totali</th>
                                    <th>Attività Totali</th>
                                    <th>Schede Totali</th>
                                    <th>Enrichment Schede Totali</th>
                                    <th>Revisioni Schede Totali</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_tasks !== null ?
                                        this.props.statData.entities.total_tasks :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_assignments !== null ?
                                        this.props.statData.entities.total_assignments :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_cards !== null ?
                                        this.props.statData.entities.total_cards :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_card_enrichments !== null ?
                                        this.props.statData.entities.total_card_enrichments :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_card_enrichment_reviews !== null ?
                                        this.props.statData.entities.total_card_enrichment_reviews :
                                        "- - -"
                                    }</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Template Schede Totali</th>
                                    <th>Maschere Schede Totali</th>
                                    <th>Percorsi Totali</th>
                                    <th>Enrichment Percorsi Totali</th>
                                    <th>Tappe Percorsi Totali</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_card_templates !== null ?
                                        this.props.statData.entities.total_card_templates :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_card_template_visibilities !== null ?
                                        this.props.statData.entities.total_card_template_visibilities :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_tours !== null ?
                                        this.props.statData.entities.total_tours :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_tour_enrichments !== null ?
                                        this.props.statData.entities.total_tour_enrichments :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_tour_laps !== null ?
                                        this.props.statData.entities.total_tour_laps :
                                        "- - -"
                                    }</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Flussi Totali</th>
                                    <th>Regole Totali</th>
                                    <th>Materiali Est. Totali</th>
                                    <th>- - -</th>
                                    <th>- - -</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_workflows !== null ?
                                        this.props.statData.entities.total_workflows :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_workflow_rules !== null ?
                                        this.props.statData.entities.total_workflow_rules :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>{
                                        this.props.statData &&
                                        this.props.statData.entities &&
                                        this.props.statData.entities.total_external_materials !== null ?
                                        this.props.statData.entities.total_external_materials :
                                        "- - -"
                                    }</td>
                                    <td style={{width: "20%"}}>- - -</td>
                                    <td style={{width: "20%"}}>- - -</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
};

//---------------------------------------------------------| ADMIN VIEW

class StatViewSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                {/* STAT UTENZE */}

                <div className="head-object-grid-supervisor">
                    Utenti:
                </div>
                <div className="list-object-grid-supervisor">
                    <Card key={"card-item-0"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_active_sessions !== null ?
                                this.props.statData.entities.total_active_sessions :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Sessioni Attive</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-1"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_students !== null ?
                                this.props.statData.entities.total_students :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Studenti</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-2"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_teachers !== null ?
                                this.props.statData.entities.total_teachers :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Docenti</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-3"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_certifiers !== null ?
                                this.props.statData.entities.total_certifiers :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Certificatori</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-4"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_supervisors !== null ?
                                this.props.statData.entities.total_supervisors :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Supervisori</CardTitle>
                        </CardBody>
                    </Card>
                </div>
                <br />
                <br />

                {/* STAT CORSI */}

                <div className="head-object-grid-supervisor">
                    Didattica:
                </div>
                <div className="list-object-grid-supervisor">
                    <Card key={"card-item-0"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_institutes !== null ?
                                this.props.statData.entities.total_institutes :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Istituti</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-1"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_classes !== null ?
                                this.props.statData.entities.total_classes :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Classi</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-2"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_projects !== null ?
                                this.props.statData.entities.total_projects :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Progetti</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-3"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_assignments !== null ?
                                this.props.statData.entities.total_assignments :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Attività</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-4"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_groups !== null ?
                                this.props.statData.entities.total_groups :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Gruppi</CardTitle>
                        </CardBody>
                    </Card>
                </div>
                <br />
                <br />

                {/* STAT ATTIVITA' */}

                <div className="head-object-grid-supervisor">
                    Attività:
                </div>
                <div className="list-object-grid-supervisor">
                    <Card key={"card-item-0"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_cards !== null ?
                                this.props.statData.entities.total_cards :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Schede</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-1"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_card_enrichments !== null ?
                                this.props.statData.entities.total_card_enrichments :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Arricch. Schede</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-2"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_card_enrichment_reviews !== null ?
                                this.props.statData.entities.total_card_enrichment_reviews :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Revisioni</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-3"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_social_rooms !== null ?
                                this.props.statData.entities.total_social_rooms :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Chat Sociali</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-4"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_comments !== null ?
                                this.props.statData.entities.total_comments :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Commenti</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-5"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_tours !== null ?
                                this.props.statData.entities.total_tours :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Percorsi</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-6"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_tour_enrichments !== null ?
                                this.props.statData.entities.total_tour_enrichments :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Arricch. Percorsi</CardTitle>
                        </CardBody>
                    </Card>
                    <Card key={"card-item-7"}>
                        <div className="tools-table">
                            <div className="tools-table-cell">{
                                this.props.statData &&
                                this.props.statData.entities &&
                                this.props.statData.entities.total_tour_laps !== null ?
                                this.props.statData.entities.total_tour_laps :
                                "- - -"
                            }</div>
                        </div>
                        <CardBody style={{textAlign: "center"}}>
                            <CardTitle>Totale Tappe Percorsi</CardTitle>
                        </CardBody>
                    </Card>
                </div>
                <br />
            </Fragment>
        );
    }
};
