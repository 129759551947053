import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './../News.scss';

class Content_pdf_dii_02122019 extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="ContentRoot_Data">
                <div className="ContentRoot_Head">
                    Ai Dirigenti/Coordinatori<br />
                    delle istituzioni scolastiche<br />
                    di istruzione secondaria di II grado<br />
                    statali e paritarie della Campania
                </div>
                <div className="ContentRoot_Subject">
                    OGGETTO: “Desiderio D’Arte” - Mostra - Concorso Virtuale.
                </div>
                <div className="ContentRoot_Body">
                    Nell’ambito delle attività di didattica a distanza, che le scuole della Campania stanno realizzando con notevole impegno, questo Ufficio vuole ricordare l’importante concorso regionale “<b>Desiderio d’Arte</b>” (prot.n.2562 del 5-02-2020), realizzato in attuazione del Protocollo di intesa tra USR per la Campania e il distretto DATABENC, e le numero se attività implementate in virtù di tale accordo. A tal proposito, per fronteggiare la situazione attuale, regolata dai vincoli imposti dalla difesa contro il COVID-19, l’USR per la Campania, d’intesa con Databenc, provvederà alla realizzazione di una mostra virtuale delle opere prodotte dai giovani artisti delle scuole, che si terrà il prossimo 12 giugno con l’inaugurazione dell’esposizione e la proclamazione dei vincitori del concorso, attraverso una piattaforma di teleconferenza.<br />
                    <br />
                    Per permettere la realizzazione della mostra virtuale, resta confermato l’obbligo della trasmissione di una fotografia dell’opera in alta risoluzione con annessa descrizione della stessa. Tutte le opere saranno inserite in un catalogo, come avvenuto nella precedente edizione del concorso.<br />
                    <br />
                    Si rappresenta, inoltre, che il materiale da trasmettere dovrà essere inviato all’indirizzo <a href="mailto:edubba@databenc.it">edubba@databenc.it</a> e, per conoscenza, al prof. Angelo Chianese all’indirizzo <a href="mailto:angelo.chianese@unina.it">angelo.chianese@unina.it</a> entro e non il 1 giugno 2020. Informazioni sul concorso potranno trovarsi all’indirizzo <a href="www.desideriodarte.databenc.it" targte="_blank">www.desideriodarte.databenc.it</a>.<br />
                    <br />
                    Si invitano le SS.LL. a favorire la più ampia partecipazione all’iniziativa in oggetto.
                </div>
            </div>
        )
    }
}

export default withRouter(Content_pdf_dii_02122019);
