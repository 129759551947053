import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from '../../globalVars.json';
import {
    Row,
    Col,
    Container,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Alert
} from 'reactstrap';
import {
    faUpload, faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import holdDataService from '../../services/holdDataService/holdDataService';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../../assets/utils/images/edubba_placeholder.png'
import moment from 'moment';
import RichTextEditor from './../../services/richTextEditor/RichTextEditor';
import ReactstrapTagsInput from './../../services/reactstrapTagsInput/ReactstrapTagsInput';
import ImageUploadManager from './../../services/imageUploadManager/ImageUploadManager';

import './TourCreation.scss';
import 'draft-js/dist/Draft.css';

class TourCreation extends Component {
    // createMode   | Boolean => TRUE (new), FALSE (edit exist)

    constructor(props) {
        super(props);
        this.state = {
            currentStep: "create-base",
            createMode:  props.createMode !== null ? props.createMode : true,

            //------------------------------------------------| STEP 1

            tourNarration:         "",
            tourTitle:             "",
            tourCategory:          "",
            tourImageUUID:         null,
            tourImageURL:          null,
            tourTags:              [],
            lockedTags:            []
        };
    }

    componentWillMount() {
        this.changeStep("create-base");
    }

    setTourData = (attrName, attrVal) => {
        this.setState({
            [attrName]: attrVal
        })
    }

    getTourData = (attrName) => {
        return (
            this.state &&
            this.state[attrName] !== null
        ) ? this.state[attrName] : null;
    }

    changeStep = (newStep) => {
        this.setState({
            currentStep: newStep
        }, () => {
            const baseStr = (
                this.state.createMode ?
                "Creazione del Percorso" :
                "Modifica del Percorso"
            );
            switch (this.state.currentStep) {
                // Step 1
                case "create-base":
                    this.props.setSectionTitle(baseStr);
                    break;

                // Do nothing
                case "cancel":
                default:
                    this.props.history.push("/tours");
                    break;
            }
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardOwner = (obj) => {
       if (obj &&
           obj.card_owner &&
           obj.card_owner.user_profile &&
           obj.card_owner.user_profile.firstName &&
           obj.card_owner.user_profile.lastName) {
           return (
               obj.card_owner.user_profile.firstName[0] +
               ". " +
               obj.card_owner.user_profile.lastName
           );
       }

       return "Non disponible..."
   }

    getCardImage = (tour_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(tour_image)) {
            coreImage = tour_image;
        }

        return coreImage;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    render() {
        return (
            <Fragment>
                {
                    /* STEP 1 */
                    this.state.currentStep === "create-base" ? (
                        <CreationStepOne
                            createMode={this.state.createMode}
                            editEntity={
                                this.props.match.params.tour_uuid ?
                                this.props.match.params.tour_uuid :
                                null
                            }
                            changeStep={this.changeStep}
                            isValidURL={this.isValidURL}
                            getCardImage={this.getCardImage}
                            getCardOwner={this.getCardOwner}
                            setDefaultImgSrc={this.setDefaultImgSrc}
                            getFromNowDate={this.getFromNowDate}
                            setTourData={this.setTourData}
                            getTourData={this.getTourData}
                            isEmpty={this.isEmpty}
                        />
                    ) : (
                        <Container className='Tours'>
                            <Row>
                                <Col lg='12' md='12' sm='12'>
                                    <Alert color="light" className="mt-3">
                                        <h4 className="alert-heading">Errore nella creazione!</h4>
                                        <p>
                                            Non è stato possibile individuare la fase del processo indicata.<br />
                                            La fase evidenziata "{this.state.currentStep}" non trovata!
                                        </p>
                                    </Alert>
                                </Col>
                            </Row>
                        </Container>
                    )
                }
            </Fragment >
        )
    }
}

//----------------------------------------------------------------------------|

class CreationStepOne extends Component {
    // props.createMode         | Boolean
    // props.editEntity         | UUID
    // props.changeStep         | Function
    // props.isValidURL         | Function
    // props.getCardImage       | Function
    // props.getCardOwner       | Function
    // props.setDefaultImgSrc   | Function
    // props.getFromNowDate     | Function
    // props.setTourData        | Function
    // props.getTourData        | Function

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen:    false,
            modalUploadFile: false,

            //-------------------------------------------|

            tour_categories: [],

            //-------------------------------------------|

            tourNarration:   props.getTourData("tourNarration"),
            tourTitle:       props.getTourData("tourTitle"),
            tourCategory:    props.getTourData("tourCategory"),
            tourImageUUID:   props.getTourData("tourImageUUID"),
            tourImageURL:    props.getTourData("tourImageURL"),
            tourTags:        props.getTourData("tourTags"),
            lockedTags:      props.getTourData("lockedTags")
        };
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'tour-creation-steps';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';
    }

    componentWillMount() {
        this.loadTourCategories();

        if (this.props.editEntity &&
            !this.props.createMode) {
            this.loadTourData();
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    handleTitleChange = (event) => {
        this.setState({
            tourTitle: event.target.value
        }, () => {
            this.props.setTourData("tourTitle", this.state.tourTitle);
        });
    }

    handleEditorChange = (value) => {
        this.setState({
            tourNarration: value
        }, () => {
            this.props.setTourData("tourNarration", this.state.tourNarration);
        });
    }

    handleTagsChange = (value) => {
        this.setState({
            tourTags: value
        }, () => {
            this.props.setTourData("tourTags", this.state.tourTags);
        });
    }

    handleTourCategoriesSelect = (event) => {
        const selectedCategory = event.target.value;
        this.setState({
            tourCategory: selectedCategory
        }, () => {
            this.props.setTourData("tourCategory", this.state.tourCategory);
        });
    }

    toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleUploadFile = (value) => {
        this.setState({
            modalUploadFile: value
        });
    }

    setImageValueURL = (data) => {
        // Close modal
        this.setState({
            modalUploadFile: false,
            tourImageUUID:   data.fileGuid,
            tourImageURL:    data.fileUrl
        }, () => {
            this.props.setTourData("tourImageUUID", this.state.tourImageUUID);
            this.props.setTourData("tourImageURL", this.state.tourImageURL);
        });
    }

    clearMediaFile = () => {
        this.setState({
            modalUploadFile: false,
            tourImageUUID:   null,
            tourImageURL:    null
        }, () => {
            this.props.setTourData("tourImageUUID", this.state.tourImageUUID);
            this.props.setTourData("tourImageURL", this.state.tourImageURL);
        });
    }

    loadTourCategories = () => {
        let query = '/tours/categories';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    tour_categories: res.data.rows
                });
            }
            else {
                this.setState({
                    tour_categories: []
                });
            }
        },
        err => {
            this.setState({
                tour_categories: []
            });
        });
    }

    loadTourData = () => {
        let query = '/tours/' + this.props.editEntity;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    loadingState: false,
                    tourNarration: res.data.rows[0]["tour_description"],
                    tourTitle:     res.data.rows[0]["tour_title"],
                    tourCategory:  res.data.rows[0]["tour_category_code"],
                    tourImageUUID: res.data.rows[0]["tour_image_uuid"],
                    tourImageURL:  res.data.rows[0]["tour_image"],
                    tourTags:      (
                        res.data.rows[0]["tour_data"] &&
                        Array.isArray(res.data.rows[0]["tour_data"].tags) ?
                        res.data.rows[0]["tour_data"].tags : []
                    )
                }, () => {
                    this.props.setTourData("tourNarration", this.state.tourNarration);
                    this.props.setTourData("tourCategory", this.state.tourCategory);
                    this.props.setTourData("tourTitle", this.state.tourTitle);
                    this.props.setTourData("tourImageUUID", this.state.tourImageUUID);
                    this.props.setTourData("tourImageURL", this.state.tourImageURL);
                    this.props.setTourData("tourTags", this.state.tourTags);
                });
            }
            else {
                this.setState({
                    loadingState: false
                });
            }
        },
        err => {
            this.setState({
                loadingState: false
            });
        });
    }

    createRemoteTour = () => {
        this.setState({ loadingState: true });
        const query = '/tours';
        const body = {
            flow_uuid: "d0e1abdd-d237-4938-be2a-160c1695ac2e",
            tour_image: this.state.tourImageUUID,
            tour_title: this.state.tourTitle,
            tour_category: this.state.tourCategory,
            tour_descr: this.state.tourNarration,
            tour_data: {
                tags: this.state.tourTags
            },
            status_code: "FW_ATCR_0001",
            old_status_code: "FW_ATCR_0001"
        };
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, body,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data.rowCount > 0) {
                this.props.changeStep("cancel");
            }
            else this.setState({
                loadingState: false
            });
        }, err => {
            this.setState({
                loadingState: false
            });
        });
    }

    modifyRemoteTour = () => {
        this.setState({ loadingState: true });
        const query = '/tours/' + this.props.editEntity;
        const body = {
            tour_image: this.state.tourImageUUID,
            tour_title: this.state.tourTitle,
            tour_category: this.state.tourCategory,
            tour_descr: this.state.tourNarration,
            tour_data: {
                tags: this.state.tourTags
            },
            status_code: "FW_ATCR_0001",
            old_status_code: "FW_ATCR_0001"
        };
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, body,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data.rowCount > 0) {
                this.props.changeStep("cancel");
            }
            else this.setState({
                loadingState: false
            });
        }, err => {
            this.setState({
                loadingState: false
            });
        });
    }

    callRemoteUpsert = () => {
        if (this.props.createMode) {
            this.createRemoteTour();
        }
        else {
            this.modifyRemoteTour();
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.loadingState ?
                    <EdubbaLoader /> : null
                }
                <Container className='step-navigator-container'>
                    <Row>
                        <Col lg='4' md='4' sm='4' className='step-navigator-left'>
                            <div className='form-sep-input-label'>
                                Titolo da Associare al Percorso:
                            </div>
                            <Input
                                type="text"
                                className=''
                                name='tourTitle'
                                value={this.state.tourTitle}
                                onChange={this.handleTitleChange}
                            />
                            <div className='form-sep-input-label'>
                                Categoria da Associare al Percorso:
                            </div>
                            <select
                                className='legacy-form-control'
                                name='tourCategory'
                                onChange={this.handleTourCategoriesSelect}
                                value={this.state.tourCategory ? this.state.tourCategory : ""}>
                                <option value="" disabled={true}>Seleziona categoria...</option>
                                {this.state.tour_categories.map((obj) => {
                                    return <option key={obj.category_code} value={obj.category_code}>
                                        {obj.category_name}
                                    </option>;
                                })}
                            </select>
                            <div className='form-sep-input-label'>
                                Immagine da Associare al Percorso:
                            </div>
                            <div className="TourImgPreview">
                                <img src={
                                    this.props.getCardImage(
                                        this.state.tourImageURL
                                    )
                                }
                                onError={this.props.setDefaultImgSrc}
                                alt="" />
                            </div>
                            <div style={{marginTop: "6px"}}>
                                <Button
                                    color="secondary"
                                    style={{width: "100%", borderRadius: "0.25rem"}}
                                    onClick={() => this.toggleUploadFile(true)}>
                                    Seleziona da Media Personali o Nuovo Upload...
                                </Button>
                            </div>
                            <div style={{marginTop: "6px"}}>
                                <Button
                                    color="danger"
                                    disabled={this.state.tourImageUUID === null}
                                    style={{width: "100%", borderRadius: "0.25rem"}}
                                    onClick={this.clearMediaFile}>
                                    Rimuovi la copertina selezionata per il Percorso
                                </Button>
                            </div>
                            <div className='form-sep-input-label'>
                                Tags da Associare al Percorso:
                            </div>
                            <ReactstrapTagsInput
                                className=''
                                onChange={this.handleTagsChange}
                                locked={this.state.lockedTags}
                                lockedIcon={faUser}
                                value={this.state.tourTags}
                                valueIcon={faUser}
                            />
                            <Row className="form-sep-input-btns">
                                <Col lg='6' md='6' sm='6'>
                                    <Button size="lg"
                                        color="danger"
                                        style={{width: "100%"}}
                                        onClick={() => this.props.changeStep("cancel")}>
                                        Annulla
                                    </Button>
                                </Col>
                                <Col lg='6' md='6' sm='6'>
                                    <Button size="lg"
                                        color="success"
                                        style={{width: "100%"}}
                                        disabled={
                                            this.props.isEmpty(this.state.tourNarration) ||
                                            this.props.isEmpty(this.state.tourTitle)     ||
                                            this.props.isEmpty(this.state.tourImageUUID) ||
                                            this.props.isEmpty(this.state.tourImageURL)  ||
                                            this.props.isEmpty(this.state.tourTags)      ||
                                            this.props.isEmpty(this.state.tourCategory)
                                        }
                                        onClick={() => this.callRemoteUpsert()}>
                                        {this.props.createMode ? "Crea" : "Modifica"} Percorso
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg='8' md='8' sm='8' className='step-navigator-right'>
                            <RichTextEditor
                                name="tourNarration"
                                className="RichTextEditor_Fix"
                                value={this.state.tourNarration}
                                onChange={this.handleEditorChange}
                                placeHolder="Inserire la traccia del tour da..."
                                readOnly={false}
                            />
                        </Col>
                    </Row>
                </Container>
                {/* V MediaModal V */}
                <Modal className='edubba-modal select-template-modal upload-file'
                    centered
                    size='lg'
                    aria-labelledby="contained-modal-title-vcenter"
                    isOpen={this.state.modalUploadFile}
                    onExit={() => this.toggleUploadFile(false)}
                    backdrop='static'>
                    <ModalHeader toggle={() => this.toggleUploadFile(false)}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon icon={faUpload} className='icon' />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Upload File
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='inner-modal'>
                                    <ImageUploadManager selectCallback={this.setImageValueURL} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

//----------------------------------------------------------------------------|

export default withRouter(TourCreation);
