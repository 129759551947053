// ------------------------------------------------------------------------- //
//
//  EDUBBA KERNEL: METRIC CARD SKELETON
//
// ------------------------------------------------------------------------- //
//  COMMON MODULE
// ------------------------------------------------------------------------- //

import globalVars from './../../globalVars.json';

// ------------------------------------------------------------------------- //
//  COMMON ENUMS
// ------------------------------------------------------------------------- //

/**
 * @author:      lsvanzo
 * @version:     23/03/2020
 * @description: Genera gli opcodes da usare.
 * @type:        Sync Function
 */
const MetricOpCodes = Object.freeze({
    EntityType:  "METRIC_OPCODE_ENTITY_TYPE_CARD",
    EntityAction: {
        Open:    "METRIC_OPCODE_ENTITY_ACTION_OPEN",
        Close:   "METRIC_OPCODE_ENTITY_ACTION_CLOSE"
    },
    ActionMode: {
        Editor:  "METRIC_OPCODE_ACTION_MODE_EDITOR",
        Reviser: "METRIC_OPCODE_ACTION_MODE_REVISER",
        Reader:  "METRIC_OPCODE_ACTION_MODE_READER",
        Unknown: "METRIC_OPCODE_ACTION_MODE_UNKNOWN"
    },
    SourceType: {
        Card:    "METRIC_OPCODE_SOURCE_TYPE_CARD_CATALOG",
        Enrich:  "METRIC_OPCODE_SOURCE_TYPE_CARD_ENRICH"
    }
});

// ------------------------------------------------------------------------- //
//  COMMON SKELETON
// ------------------------------------------------------------------------- //

/**
 * @author:      lsvanzo
 * @version:     23/03/2020
 * @description: Genera la struttura JSON + verifiche sui dati.
 * @type:        Sync Function
 *
 * @param card_uuid: UUID della card
 * @param enrich_uuid: UUID dell'enrich della card
 * @param staging_state: Codice dello stato in cui si strova la card.
 * @param card_source_type: Tipologia di entità misurata (card, enrich)
 * @param card_view_status: Modalità di azione (lettura, scrittura, ...)
 * @param card_action: Azione eseguita come metrica (open, close)
 */
const MetricOBJGenerator = (
    card_uuid,
    enrich_uuid,
    staging_state,
    card_source_type,
    card_view_status,
    card_action
) => {
    if (!(card_uuid      &&
        card_source_type &&
        card_view_status &&
        card_action)) {
        // Always false
        return false;
    }

    // Normalize values
    const p_enrich_uuid   = enrich_uuid ? enrich_uuid : globalVars.UUIDNil;
    const p_staging_state = staging_state && staging_state !== "" ? staging_state : "FW_CDER_UNKWN";

    // Send JSON structure
    return Object.freeze({
        "entity-type":    MetricOpCodes.EntityType,
        "entity-action":  card_action,
        "action-mode":    card_view_status,
        "source-type":    card_source_type,
        "entity-payload": {
            "card-uuid":     card_uuid,
            "enrich-uuid":   p_enrich_uuid,
            "enrich-status": p_staging_state
        }
    });
};

// ------------------------------------------------------------------------- //
//  EXPORT MULTIPLE COMPONENTS
// ------------------------------------------------------------------------- //

export {
    MetricOpCodes,
    MetricOBJGenerator
};

// ------------------------------------------------------------------------- //
//  END: KERNEL
// ------------------------------------------------------------------------- //
