import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from './../globalVars.json';
import EdubbaLoader from '../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Badge,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Alert
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import JSXTemplateRender from '../services/templateManager/JSXTemplateRender';
import tplVisibilityImage from './../assets/utils/images/template_visibility_image.png';
import moment from 'moment';
import 'moment/locale/it';
import './Visibilities.scss';

class Visibilities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTutor: false,
            maskLoading: true,
            currentTemplateUUID: null,
            currentTemplateViewMode: null,
            currentVisibilityUUID: null,
            currentTemplates: [],
            currentVisibilities: []
        };
    }

    componentWillReceiveProps (nextProps) {
        if (this.state.currentTemplateUUID !== nextProps.match.params.template_uuid ||
            this.state.currentTemplateViewMode !== nextProps.match.params.template_view_mode ||
            this.state.currentVisibilityUUID !== nextProps.match.params.visibility_uuid) {
            let template_uuid = nextProps.match.params.template_uuid;
            let template_view_mode = nextProps.match.params.template_view_mode;
            let visibility_uuid = nextProps.match.params.visibility_uuid;

            this.setState({
                currentTemplateUUID: template_uuid,
                currentTemplateViewMode: template_view_mode,
                currentVisibilityUUID: visibility_uuid
            }, () => {
                this.loadTemplates();

                if (template_uuid) {
                    this.loadTemplateVisibilities();
                }
            });
        }

        return null;
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'visibilities';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';

        let template_uuid = this.props.match.params.template_uuid;
        let template_view_mode = this.props.match.params.template_view_mode;
        let visibility_uuid = this.props.match.params.visibility_uuid;
        let isTutor = holdDataService.loggedUserIsTutor();
        let setTitle = 'Gestione Maschere di Visibilità';
        this.props.setSectionTitle(setTitle);

        this.setState({
            currentTemplateUUID: template_uuid,
            currentTemplateViewMode: template_view_mode,
            currentVisibilityUUID: visibility_uuid,
            isTutor: isTutor
        }, () => {
            this.loadTemplates();

            if (template_uuid) {
                this.loadTemplateVisibilities();
            }
        });
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    loadTemplates = () => {
        this.setState({ maskLoading: true })
        let query = "/templates";
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let templates = [];
                if (res.data.rowCount > 0) {
                    templates = res.data.rows;
                }
                this.setState({
                    currentTemplates: templates,
                    maskLoading: false
                });
            }, err => {
                this.setState({
                    currentTemplates: [],
                    maskLoading: false
                });
            });
    }

    loadTemplateVisibilities = () => {
        this.setState({ maskLoading: true })
        let query = "/templates/" + this.state.currentTemplateUUID + "/visibilities";
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let visibilities = [];
                if (res.data.rowCount > 0) {
                    visibilities = res.data.rows;
                }
                this.setState({
                    currentVisibilities: visibilities,
                    maskLoading: false
                });
            }, err => {
                this.setState({
                    currentVisibilities: [],
                    maskLoading: false
                });
            });
    };

    openMaskLists = (tplUUID) => {
        this.props.history.push("/visibilities/" + tplUUID);
    }

    openVisibilityMask = (tplUUID, maskUUID) => {
        this.props.history.push("/visibilities/" + tplUUID + "/V/" + maskUUID);
    }

    getAuthor = (mask) => {
        if (mask &&
            mask.visibility_owner &&
            mask.visibility_owner.user_profile &&
            mask.visibility_owner.user_profile.firstName &&
            mask.visibility_owner.user_profile.lastName) {
            return (
                mask.visibility_owner.user_profile.firstName +
                " " +
                mask.visibility_owner.user_profile.lastName
            );
        }

        return "Non disponible..."
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    render() {
        return (
            <Fragment>
                {this.state.maskLoading ?
                    <EdubbaLoader />
                    :
                    <div className='Visibilities'>
                        <Row>
                            <Col xs="3" className="left-content">
                                <div className="scroll-container">
                                    <PerfectScrollbar>
                                        <ListGroup className="list-templates">
                                            {
                                                this.state.currentTemplates.map((tpl) => {
                                                    return (
                                                        <ListGroupItem
                                                            className="justify-content-between"
                                                            key={"tpl_" + tpl.template_uuid}
                                                            onClick={() => this.openMaskLists(tpl.template_uuid)}>
                                                            {tpl.template_name}
                                                            <Badge pill>{tpl.template_visibilities ? tpl.template_visibilities : 0}</Badge>
                                                        </ListGroupItem>
                                                    );
                                                })
                                            }
                                        </ListGroup>
                                    </PerfectScrollbar>
                                </div>
                            </Col>
                            <Col xs="9" className="right-content">
                                {
                                    this.state.currentVisibilityUUID &&
                                    this.state.currentTemplateViewMode === "V" ?
                                        (
                                            <div className="view-cards-detail">
                                                <JSXTemplateRender
                                                    key={"TPL_VISIBILITY_UUID_" + this.state.currentVisibilityUUID}
                                                    visibilityToRender={this.state.currentVisibilityUUID}
                                                    templateToRender={this.state.currentTemplateUUID}
                                                    viewMode={this.state.currentTemplateViewMode}
                                                />
                                            </div>
                                        )
                                    : this.state.currentVisibilities.length > 0 ?
                                        (
                                            <div className="list-cards-grid">
                                                {
                                                    this.state.currentVisibilities.map((mask) => {
                                                        return (
                                                            <Card
                                                                key={"MASK_UUID_" + mask.visibility_uuid}
                                                                onClick={() => this.openVisibilityMask(mask.visibility_template, mask.visibility_uuid)}>
                                                                <CardImg top width="100%" src={tplVisibilityImage} />
                                                                <CardBody>
                                                                    <CardTitle>
                                                                        {mask.project_name}:<br />{mask.assign_name}
                                                                    </CardTitle>
                                                                    <CardText>
                                                                        <small className="text-muted">
                                                                            <b>Autore: </b> {this.getAuthor(mask)}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Creato: </b> {this.getFromNowDate(mask.visibility_date_create)}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Modificato: </b> {this.getFromNowDate(mask.visibility_date_update)}
                                                                        </small>
                                                                    </CardText>
                                                                </CardBody>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </div>
                                        )
                                    : (
                                        <Alert color="light" className="mt-3">
                                            <h4 className="alert-heading">Nessuna maschera definita!</h4>
                                            <p>
                                                Non risultano maschere di visibilità disponibili per il template di scheda selezionato.<br />
                                                Le maschere permettono di definire una vista personalizzata delle schede per le attività dei progetti.<br />
                                                Una volta create le maschere, per un dato template, saranno consultabili nella sezione corrente.
                                            </p>
                                            <hr />
                                            <p className="mb-0">
                                                Non hai ancora definito una maschera per il template selezionato!
                                            </p>
                                        </Alert>
                                    )
                                }
                            </Col>
                        </Row>
                    </div>}
            </Fragment>
        )
    }
}

export default withRouter(Visibilities);
