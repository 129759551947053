import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Container
} from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import imgA from './../../assets/utils/images/news/platform_update_27042021/dashboard-ui.png';
import imgB from './../../assets/utils/images/news/platform_update_27042021/news-contacts-ui.png';
import imgC from './../../assets/utils/images/news/platform_update_27042021/sidenav-ui.png';
import imgD from './../../assets/utils/images/news/platform_update_27042021/groups-ui.png';
import imgE from './../../assets/utils/images/news/platform_update_27042021/students-ui.png';
import imgF from './../../assets/utils/images/news/platform_update_27042021/cards-delete-ui.png';
import './../News.scss';

class platform_update_27042021 extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="ContentRoot_Data">
                <Container>
                    <Row className="ContentRoot_Row">
                        <Col>
                            <h4>Aggiornamento di Piattaforma, v2.7.27042021</h4>
                            <i>Data di rilascio: 28/04/2021</i><br />
                            <br />
                            <h6>Le note di versione forniscono principalmente informazioni circa le nuove funzionalità; dettagli su correzioni e migliorie della piattaforma, eventualmente rilasciate nel packaging della corrente versione, sono riassunti per macro-gruppi nel changelog a fondo pagina.</h6>
                        </Col>
                    </Row>
                    <Row className="ContentRoot_Row ContentRoot_Row_Alter">
                        <Col>
                            <h5>Look &amp; Feel</h5>
                            Abbiamo avviato un progressivo rinnovamento delle componenti grafiche di piattaforma al fine di miglioare sia l'esperienza utente che l'intuitività nel suo utilizzo. Per questo motivo le singole sezioni di lavoro verrano man mano allineate uniformando, di conseguenza, anche la navigazione interna delle stesse.
                        </Col>
                        <Col>
                            <a href={imgA} target="_blank" rel="noopener noreferrer" title="Click per vedere a dimensione originale">
                                <img className="ContentRoot_Image" src={imgA} alt="" />
                            </a>
                        </Col>
                    </Row>
                    <Row className="ContentRoot_Row">
                        <Col>
                            <a href={imgB} target="_blank" rel="noopener noreferrer" title="Click per vedere a dimensione originale">
                                <img className="ContentRoot_Image" src={imgB} alt="" />
                            </a>
                        </Col>
                        <Col>
                            <h5>Supporto e Contatti</h5>
                            Hai bisogno di aiuto? Contattare il nostro supporto, per domande o problemi durante l'utilizzo della piattaforma, è semplice e diretto grazie alla sezione dedicata presente sia nelle "News" (Barra laterale &rarr; Avvisi e News) che nelle "Guide Ruolo" (Profilo &rarr; Click su Icona "?"): i nostri incaricati sono disponibili via email e contatto Skype!
                        </Col>
                    </Row>
                    <Row className="ContentRoot_Row ContentRoot_Row_Alter">
                        <Col>
                            <h5>Anagrafica</h5>
                            Al fine di migliorare ed uniformare le sezioni di piattaforma la barra laterale di navigazione presenta, ora, il macro gruppo "Anagrafica". Al suo interno sono riunite la gestione di Studenti, Classi e Gruppi di lavoro associati al proprio Istituto di appartenenza.
                        </Col>
                        <Col>
                            <a href={imgC} target="_blank" rel="noopener noreferrer" title="Click per vedere a dimensione originale">
                                <img className="ContentRoot_Image" src={imgC} alt="" />
                            </a>
                        </Col>
                    </Row>
                    <Row className="ContentRoot_Row">
                        <Col>
                            <a href={imgD} target="_blank" rel="noopener noreferrer" title="Click per vedere a dimensione originale">
                                <img className="ContentRoot_Image" src={imgD} alt="" />
                            </a>
                        </Col>
                        <Col>
                            <h5>UI per Gruppi e Classi</h5>
                            Le nuove sezioni di gestione delle anagrafiche d'istituto (Classi e Gruppi) sono state rinnovate uniformandole alle gemelle per la gestione di Schede e Percorsi. Scopo di tale intervento è condividere l'esperienza maturata, lato utente finale, nella navigazione della UI e intuitività di utilizzo nelle tre macro fasi, ovvero: creazione, paginazione e ricerca entità.
                        </Col>
                    </Row>
                    <Row className="ContentRoot_Row ContentRoot_Row_Alter">
                        <Col>
                            <h5>UI per Gestione Studenti</h5>
                            La nuova sezione per la "Gestione Studenti", presente in "Anagrafica", permette di avere a colpo d'occhio le informazioni circa email, nome, cognome e presenza dello studente in classi e gruppi di lavoro. Inoltre, se non associato ad attività e gruppi/classi, è possibile rimuovere le utenze con tale ruolo dal proprio istituto di appartenenza; in caso contrario la rimozione fallirà con un messaggio di errore.
                        </Col>
                        <Col>
                            <a href={imgE} target="_blank" rel="noopener noreferrer" title="Click per vedere a dimensione originale">
                                <img className="ContentRoot_Image" src={imgE} alt="" />
                            </a>
                        </Col>
                    </Row>
                    <Row className="ContentRoot_Row">
                        <Col>
                            <a href={imgF} target="_blank" rel="noopener noreferrer" title="Click per vedere a dimensione originale">
                                <img className="ContentRoot_Image" src={imgF} alt="" />
                            </a>
                        </Col>
                        <Col>
                            <h5>Rimozione di Schede e Percorsi</h5>
                            Similmente a quanto visto per la sezione "Studenti" è ora possibile rimuovere Schede e Percorsi dalla proprio libreria personale. Condizione per cui ciò avvenga è che la scheda, o Percorso, non sia coinvolta in qual si voglia attività di piattaforma; in caso contrario la rimozione fallirà con un messaggio di errore.
                        </Col>
                    </Row>
                    <hr />
                    <Row className="ContentRoot_Row">
                        <Col>
                            <h4>Changelog di Piattaforma</h4>
                            <i>Data di rilascio: 28/04/2021</i><br />
                            <br />
                            <ul style={{listStyle: "square"}}>
                                <li>
                                    <b>Novità Introdotte:</b>
                                    <ul style={{listStyle: "square"}}>
                                        <li>Introdotti templates per schede di tipo BIB e MODI, rivista grafica di selezione</li>
                                        <li>Introdotta rimozione delle schede dalla propria libreria</li>
                                        <li>Introdotta rimozione dei percorsi dalla propria libreria</li>
                                        <li>Introdotta sezione a UI unificata per la gestione degli studenti</li>
                                        <li>Introdotte funzionalità di paginazione e ricerca nella sezione studenti</li>
                                        <li>Introdotta sotto-sezione per "Contatti e Supporto" nelle sezioni "Avvisi e News" e "Guide"</li>
                                        <li>Introdotta anteprima dei media presenti nei campi dedicati della scheda</li>
                                        <li>Introdotta predisposizione UI per esportazione documenti</li>
                                        <li>Introdotta sezione a UI unificata per la gestione gruppi</li>
                                        <li>Introdotta sezione a UI unificata per la gestione classi</li>
                                        <li>Introdotta sezione per statistiche attività di istituto</li>
                                        <li>Introdotto advertising copyright in fase di upload media, migliorie grafiche della UI di upload</li>
                                        <li>Introdotti nuovi placeholder per schede e percorsi</li>
                                    </ul>
                                    <br />
                                </li>
                                <li>
                                    <b>Bugfixing:</b>
                                    <ul style={{listStyle: "square"}}>
                                        <li>Corretto il vincolo di altezza de "I Miei Progetti" nella dashboard</li>
                                        <li>Corretto il problema che causava il blocco delle scrollbar su alcuni PC</li>
                                        <li>Corretto un problema casuale durante la selezione degli arricchimenti nella sezione "Compiti" che causava l'apertura di schede vuote</li>
                                        <li>Corretti alcuni problemi di "input-lag" della piattaforma che rendeva estremamente lenta la piattaforma su alcuni PC</li>
                                        <li>Corretti alcuni problemi legati alla comparsa della chat che rendeva impossibile la navigazione delle sezioni</li>
                                        <li>Corretto un problema di visualizzazione dei contatti con news/avvisi attivi</li>
                                        <li>Corretta logica di auto-load nelle modali di dialogo in presenza di singolo istituto</li>
                                        <li>Corretto un problema casuale presente nella creazione delle classi</li>
                                        <li>Corretta la visualizzazione di alcune modali circa i tasti di salvataggio e chiusura</li>
                                        <li>Corretto un problema nel controllo dati durante la creazione di uno studente</li>
                                        <li>Corretti alcuni problemi di visualizzazione dei tooltip</li>
                                        <li>Corretti alcuni problemi di visualizzazione dei contatori post creazione/modifica/rimozione</li>
                                        <li>Corretti alcuni problemi di visibilità con utenze multi-ruolo</li>
                                    </ul>
                                    <br />
                                </li>
                                <li>
                                    <b>Migliorie ed Interventi:</b>
                                    <ul style={{listStyle: "square"}}>
                                        <li>Aggiunti feedback visuali durante la rimozione di classi</li>
                                        <li>Aggiunti feedback visuali durante la rimozione di gruppi</li>
                                        <li>Aggiunti feedback visuali durante la rimozione di studenti</li>
                                        <li>Aggiunti feedback visuali durante la rimozione di schede</li>
                                        <li>Aggiunti feedback visuali durante la rimozione di percorsi</li>
                                        <li>Nascosto temporaneamente le componenti UI di esportazione delle schede</li>
                                        <li>Codice non più necessario rimosso dalla sezione classi, gruppi e studenti</li>
                                        <li>Semplificata e migliorata la navigazione tra classi e gruppi mediante sezione Anagrafica, corretti permessi rotta</li>
                                        <li>Sezione footer della UI incluso nella barra di navigazione laterale per recupero dello spazio nel corpo centrale</li>
                                        <li>Ridotte notevolmente le chiamate necessarie al popolamento della vista di dettaglio delle schede in "Compiti"</li>
                                        <li>Rilassato il numero massimo di media caricabili in piattaforma per utente</li>
                                        <li>Il supervisore è ora abilitato alla login con ruolo unico</li>
                                        <li>Riscritta la barra azioni delle schede in "dettaglio"</li>
                                        <li>Avviato restyling UI di piattaforma a partire da dashboard, profilo, notifiche, barra laterale e messaggi.</li>
                                    </ul>
                                    <br />
                                </li>
                                <li><i>Numerose altre correzioni minori...</i></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default withRouter(platform_update_27042021);
