import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/it';

import {
    CardHeader,
    Card,
    ListGroup,
    ListGroupItem,
    Alert,
    Button
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import './AssignedCards.scss';

class AssignedCards extends Component {
    constructor(props) {
        super(props);
        //tasks
        this.state = {
            data: '',
            dropdownOpen: false,
            renderTypeCards: (() => {
                // Check if not empty
                if (Array.isArray(props.cardTasks) &&
                    props.cardTasks.length > 0) {
                    return true;
                }
                // Check if not empty
                else if(Array.isArray(props.cardTasks) &&
                    props.tourTasks.length > 0) {
                    return false;
                }
                // Always empty
                else return true;
            })()
        };
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            this.setState({
                renderTypeCards: (() => {
                    // Check if not empty
                    if (Array.isArray(nextProps.cardTasks) &&
                        nextProps.cardTasks.length > 0) {
                        return true;
                    }
                    // Check if not empty
                    else if(Array.isArray(nextProps.cardTasks) &&
                        nextProps.tourTasks.length > 0) {
                        return false;
                    }
                    // Always empty
                    else return true;
                })()
            })
        }
    }

    gotToEnrichment = (base_uuid, enrich_uuid, type) => {
        if (type === "tours") {
            this.props.history.push('/tours/'+ base_uuid +'/enrichments/'+ enrich_uuid);
        } else {
            this.props.history.push('/card-detail/S/' + base_uuid,
            {
                "src_type":    "assignedcards",
                "card_uuid":   base_uuid,
                "enrich_uuid": enrich_uuid
            });
        }
    }

    getStatusLabel = (status_code) => {
        switch (status_code) {
            // Draft
            case "FW_CDER_0001":
            case "FW_ATER_0001":
              return "In compilazione";
            // Draft-Comments
            case "FW_CDER_0002":
                return "In revisione da parte del Team";
            // Pre-Evaluating
            case "FW_CDER_0003":
                return "In revisione";
            // Evaluating
            case "FW_CDER_0004":
            case "FW_ATER_0002":
                return "In valutazione";
            // Validating
            case "FW_CDER_0005":
                return "In certificazione";
            // Validated
            case "FW_CDER_0006":
                return "Certificata";
            // Approved
            case "FW_ATER_0003":
                return "Approvata";
            default:
              break;
          }
    }

    toggleRenderType = () => {
        this.setState({
            renderTypeCards: !this.state.renderTypeCards
        })
    }

    render() {
        // Extract with auto switch
        let currentTasks = (
            this.state.renderTypeCards ?
            this.props.cardTasks :
            this.props.tourTasks
        )
        // Apply filters
        .map((obj) => {
            // Filter completed tour
            if ([
                    "FW_ATER_0002",
                    "FW_ATER_0003"
                ].includes(obj.status_code)) {
                return null;
            }

            // Filter completed card
            if ([
                    "FW_CDER_0004",
                    "FW_CDER_0005",
                    "FW_CDER_0006"
                ].includes(obj.status_code)) {
                return null;
            }

            // Filter completed card
            if (obj.enrich_is_reviewed === true &&
                obj.enrich_is_mine === false) {
                return null;
            }

            // Always OK
            return obj;
        })
        // Filter null values
        .filter(x => x);

        return (
            <Fragment>
                <Card className="AssignedCards card-hover-shadow-2x mb-3">
                    <CardHeader className="card-header-tab">
                        <div className="card-header-tab-toggle-type">
                            <Button color="primary" onClick={() => this.toggleRenderType()}>
                                <i className="pe-7s-repeat"> </i>
                                Passa {!this.state.renderTypeCards ? "alle Schede" : "ai Percorsi"}
                            </Button>
                        </div>
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon pe-7s-portfolio"> </i>
                            COMPITI
                        </div>
                    </CardHeader>
                    <PerfectScrollbar>
                        <div style={{scrollBehavior: 'smooth'}}>
                            {
                                currentTasks.length > 0 ?
                                    <ListGroup className="todo-list-wrapper">
                                        {
                                            currentTasks.map((obj) => {
                                                return <ListGroupItem key={obj.task_uuid} onClick={() => {
                                                    if (this.state.renderTypeCards) {
                                                        this.gotToEnrichment(obj.card_uuid, obj.enrich_uuid, "cards");
                                                    } else {
                                                        this.gotToEnrichment(obj.tour_uuid, obj.enrich_uuid, "tours");
                                                    }
                                                }}>
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <div
                                                                    className="widget-heading"
                                                                    style={obj.task_color ? {color: ((
                                                                        obj.task_color === "#ffffff" ||
                                                                        obj.task_color === "#F5F5F5"
                                                                    ) ? "#000" : obj.task_color)} : null}>
                                                                    {obj.task_name + " (" + this.getStatusLabel(obj.status_code) + ")"}
                                                                </div>
                                                                <div className="widget-subheading">
                                                                    {obj.task_owner.user_profile.firstName + " " + obj.task_owner.user_profile.lastName}
                                                                </div>
                                                                <div className="widget-heading">
                                                                    {this.state.renderTypeCards ? obj.card_title : obj.tour_title}
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right">
                                                                <div className="badge badge-danger">
                                                                    {moment(obj.task_date_end).fromNow()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ListGroupItem>;
                                            })
                                        }
                                    </ListGroup>
                                : (
                                    <Alert color="light" className="m-3 fixAlertTaskMT">
                                        <h5 className="alert-heading">Compiti terminati!</h5>
                                        <hr />
                                        <p className="mb-0">
                                            Non risultano compiti attivi da svolgere per il tuo profilo.
                                        </p>
                                    </Alert>
                                )
                            }
                        </div>
                    </PerfectScrollbar>
                </Card>
            </Fragment>
        )
    }

}

export default withRouter(AssignedCards);
