import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import classnames from 'classnames';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Alert
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import ReactHtmlParser from 'react-html-parser';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  faPlus,
  faTrash,
  faMap,
  faPaperPlane,
  faUpload,
  faQuestion,
  faFile,
  faCaretLeft,
  faCaretRight,
  faFileImage,
  faFileAlt,
  faFileArchive,
  faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapSelectorManager from './../services/mapSelectorManager/MapSelectorManager';
import ImageUploadManager from './../services/imageUploadManager/ImageUploadManager';
import JSTemplateManager from './../services/templateManager/JSTemplateManager';
import EdubbaLoader from './../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../assets/utils/images/edubba_placeholder.png'
import EdubbaImgDefault from './../assets/utils/images/file_not_previewable.jpg';
import holdDataService from './../services/holdDataService/holdDataService';
import RequiredProjectCertifier from './Modals/RequiredProjectCertifier';
import globalVars from './../globalVars.json';
import './CardDetail.scss';

// WS System
import WSSystem from './../services/webSocketSystem/webSocketSystem';
import {
    MetricOpCodes,
    MetricOBJGenerator
} from './../services/metricDataSkeletons/metricUserCards';

// Prepare storage
let ctxTemplateManager;

class CardDetail extends Component {
  constructor() {
    super();

    this.state = {
      core: {},
      activeTab: '',
      latitudeCode: '',
      longitudeCode: '',
      latitudeValue: 0,
      longitudeValue: 0,

      modalAnnulla: false,

      customTemplate: [],

      modalSelectFields: false,
      modalSelectFlow: false,
      modalUploadFile: false,

      selectedFlow: '',
      formData: {},
      reportData: {},

      lastIdFocussed: '',

      visibilityData: null,
      card_view_status: "",
      card_uuid: "",
      staging_state: "",
      enrich_uuid: "",
      pfcModal: false,
      pfcID: "",
      pfcFieldName: "",
      pfcCurrentFetchedComments: [],
      pfcCommentsInEdit: {},
      pfcComment: "",
      card_image: "",
      modalMap: false,

      //-------------------------------------------| NEW

      showMediaModal: false,
      mediaModalData: {},
      templateToRender: null,
      headersToRender: [],
      htmlToRender: null,
      pfcFetchedComments: null,
      infoSectionDescription: null,
      infoSectionExamples: [],
      mediaModalComponentsArray: null,

      //-------------------------------------------| REQ INPUTS

      requiredInputs: {},
      brokenRequiredInputs: [],
      modalCheckRequireAnnulla: false,

      //-------------------------------------------| MEDIA MODAL

      showMediaNodeObj: null,

      //-------------------------------------------| IS_CERTIFIABLE

      modalRequiredProjectCertifier: false,
      assignIsCertifiable: false,

      //-------------------------------------------| CHECK METRIC LOADING

      isWSEnrichCard:         false,
      dispatchWSMetricEnable: false,
      dispatchWSEventEnable:  false
    };
  }

    //------------------------------------------------------------------------|
    //  METRIC LOGICS WS & LISTENERS
    //------------------------------------------------------------------------|

    // Translator mode
    GetWSMode() {
        // Get value
        const card_view_status = this.state.card_view_status;

        // Translator
        switch (card_view_status) {
            case "E": return MetricOpCodes.ActionMode.Editor;
            case "S": return MetricOpCodes.ActionMode.Reviser;
            case "V": return MetricOpCodes.ActionMode.Reader;
            default:  return MetricOpCodes.ActionMode.Unknown;
        }
    }

    dispatchWSOpenMetric(_typeCard) {
        // Check for already loaded
        if (!this.state.dispatchWSMetricEnable) {
            // Get singleton instance of emitter
            const instanceWSEmitter = new WSSystem().getInstance();

            // Change state of component
            this.setState({
                dispatchWSMetricEnable:   true,
                dispatchWSMetricUniqueID: instanceWSEmitter.genWsMuid()
            },
            () => {
                // Get value
                const card_uuid     = this.state.card_uuid;
                const enrich_uuid   = this.state.enrich_uuid;
                const staging_state = this.state.staging_state;
                const metric_uid    = this.state.dispatchWSMetricUniqueID;

                // Get type send
                const opMetered = instanceWSEmitter.getDispatchType().Metered;

                // Send message
                instanceWSEmitter.dispatch(
                    metric_uid,
                    opMetered,
                    "ws-analyzer-stats",
                    MetricOBJGenerator(
                        card_uuid,
                        enrich_uuid,
                        staging_state,
                        _typeCard,
                        this.GetWSMode(),
                        MetricOpCodes.EntityAction.Open
                    )
                );
            });
        }
    }

    dispatchWSCloseMetric(_typeCard) {
        // Check for failed load
        if (this.state.dispatchWSMetricEnable) {
            // Get singleton instance of emitter
            const instanceWSEmitter = new WSSystem().getInstance();

            // Get value
            const card_uuid     = this.state.card_uuid;
            const enrich_uuid   = this.state.enrich_uuid;
            const staging_state = this.state.staging_state;
            const metric_uid    = this.state.dispatchWSMetricUniqueID;

            // Get type send
            const opMetered = instanceWSEmitter.getDispatchType().Metered;

            // Send message
            instanceWSEmitter.dispatch(
                metric_uid,
                opMetered,
                "ws-analyzer-stats",
                MetricOBJGenerator(
                    card_uuid,
                    enrich_uuid,
                    staging_state,
                    _typeCard,
                    this.GetWSMode(),
                    MetricOpCodes.EntityAction.Close
                )
            );
        }
    }

    dispatchWSListenerBeforeUnLoad(event) {
        // Block default
        event.preventDefault();

        // Force for some browser
        (event || window.event).returnValue = null;

        // Debug
        console.log("OnBeforeUnload: fired!");

        // Skip dialog from browser
        return null;
    }

    dispatchWSListenerCloseMetric(event) {
        // Block default
        event.preventDefault();

        // Debug
        console.log("OnUnload: fired!");

        // Get value
        const isEnrich = this.state.isWSEnrichCard;

        // Delegate
        this.dispatchWSCloseMetric(
            isEnrich ? MetricOpCodes.SourceType.Enrich : MetricOpCodes.SourceType.Card
        );
    }

    dispatchWSAddListenerCloseMetric() {
        // Check for already loaded
        if (!this.state.dispatchWSEventEnable) {
            this.setState({
                dispatchWSEventEnable: true
            },
            () => {
                // Add listener to window
                $(window).on(
                    "beforeunload",
                    this.dispatchWSListenerBeforeUnLoad.bind(this)
                );

                // Add listener to window
                $(window).on(
                    "unload",
                    this.dispatchWSListenerCloseMetric.bind(this)
                );
            });
        }
    }

    dispatchWSDelListenerCloseMetric() {
        // Remove all trigger
        $(window).off("beforeunload");
        $(window).off("unload");
    }

    //------------------------------------------------------------------------|

    componentWillMount() {
        // Get data if exists
        const card_uuid         = this.props.match.params.card_uuid;
        const card_view_status  = this.props.match.params.card_view_status;
        const card_view_payload = (
            this.props.location.state ?
            this.props.location.state :
            null
        );

        // Prepare state
        this.setState({
            loading: true
        }, () => {
            // Get correct status
            switch (card_view_status) {
                // VIEW
                case "V": this.getCard(card_uuid, card_view_status); break;
                // EDIT
                case "E": this.getCard(card_uuid, card_view_status); break;
                // STAGING
                case "S":
                    // Check data structure: staging
                    if (card_view_payload             &&
                        card_view_payload.src_type    &&
                        card_view_payload.card_uuid   &&
                        card_view_payload.enrich_uuid &&
                        card_view_payload.card_uuid === card_uuid) {
                        // Get card uuid
                        const enrich_uuid = card_view_payload.enrich_uuid;
                        // Delegate to opener
                        this.getStagingCard(card_uuid, enrich_uuid, card_view_status);
                    } else {
                        // Send back to home
                        this.props.history.push('/');
                    }
                    break;
                // UNKNOWN
                default: break;
            }

            // Install metric analyzer
            this.dispatchWSAddListenerCloseMetric();
        });
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'CardDetail';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';
        this.props.setSectionTitle('none');
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';

        // Get value
        const isEnrich = this.state.isWSEnrichCard;

        // Uninstall metric analyzer
        this.dispatchWSDelListenerCloseMetric();
        this.dispatchWSCloseMetric(
            isEnrich ? MetricOpCodes.SourceType.Enrich : MetricOpCodes.SourceType.Card
        );
    }

  getCard(card_uuid, card_view_status) {
    // Query
    let query = "/cards/" + card_uuid;
    axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
      auth: holdDataService.getAuthorization()
    }).then(res => {
      let cardRes = res.data && res.data.rowCount > 0 ? res.data.rows[0] : {};
      // Query
      let query = '/templates/' + cardRes.template_uuid;
      axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
          auth: holdDataService.getAuthorization()
        }).then(res => {
          let core;
          if (res.data.rowCount > 0) {
            core = res.data.rows[0];
          }

            ctxTemplateManager = new JSTemplateManager((block, masked, origin) => {
                let skipRemoveNode = false;

                if (Array.isArray(block["field-tree-childs"]) &&
                    block["field-tree-childs"].length > 0) {
                    skipRemoveNode = !masked["field-is-mediamodal"];
                }
                else {
                    skipRemoveNode = true;
                }

                return {
                    "keepNode": skipRemoveNode,
                    "isMediaNode": (
                        block["field-tree-childs"].length <= 0 ? (
                            skipRemoveNode
                        )
                        : (masked["field-is-mediamodal"] !== false)
                    )
                };
            });

          // Load template into manager
          ctxTemplateManager.parseTemplateJSON(core["template_data"]);

          // Get header from manager
          let headers = ctxTemplateManager.getAllHeaders();

          // Get mediamodal sections
          let mediaModalComponentsArray = ctxTemplateManager.getTemplateSectionsOfType("mediamodal");

          // Get require inputs
          let fieldsCalculated = this.generateRequiredInputs(
            headers,
            card_view_status,
            cardRes.card_data ? cardRes.card_data.form_data : {}
          );

          // Save new state of component
          this.setState({
            loading: false,
            core: core,
            customTemplate: core["template_data"]["core_data"],
            formData: cardRes.card_data ? cardRes.card_data.form_data : {},
            visibilityData: cardRes.card_data ? cardRes.card_data.visibility_data : {},
            card_uuid: card_uuid,
            card_view_status: card_view_status,
            card_image: cardRes.card_image,

            //-------------------------------------------------| NEW

            requiredInputs: fieldsCalculated.required,
            activeTab: headers.length > 0 ? headers[0]["field-edusn-srvid"] : null,
            headersToRender: fieldsCalculated.headers,
            templateToRender: ctxTemplateManager.getProcessedTemplate(),
            mediaModalComponentsArray: mediaModalComponentsArray,
            assignIsCertifiable: cardRes.assign_is_certifiable === true ? true : false,

            //-------------------------------------------------| WS METRIC

            isWSEnrichCard: false
          },
          () => {
            // Delegate trigger
            this.dispatchWSOpenMetric(
                MetricOpCodes.SourceType.Card
            );
          });
        }, err => {
          this.setState({ loading: false })
        });
    }, err => {
      //
    });
  }

    getStagingCard(card_uuid, enrich_uuid, card_view_status) {
        // GET PARENT CARD DATA
        let query = "/cards/" + card_uuid;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(cards_res => {
            // GET ENRICHMENT CARD DATA
            let cardRes = cards_res.data && cards_res.data.rowCount > 0 ? cards_res.data.rows[0] : {};
            let query = "/cards/" + card_uuid + "/enrichments/" + enrich_uuid;
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                auth: holdDataService.getAuthorization()
            }).then(enrich_res => {
                let cardEnrichmentRes = enrich_res.data && enrich_res.data.rowCount > 0 ? enrich_res.data.rows[0] : {};
                let assignUuid = cardEnrichmentRes.assign_uuid;
                let visibilityUuid = Array.isArray(cardEnrichmentRes.template_visibilities) ? ((visibilities, assignUuid) => {
                    for (let i = 0; i < visibilities.length; i++) {
                        if (visibilities[i].assign_uuid === assignUuid) {
                            return visibilities[i].visibility_uuid;
                        }
                    }
                    return false;
                })(cardEnrichmentRes.template_visibilities, assignUuid) : false;

                // Query
                let query = '/templates/' + cardRes.template_uuid;
                if (visibilityUuid) {
                    query += '/assignments/' + assignUuid + '/visibilities/' + visibilityUuid;
                }

                axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                    auth: holdDataService.getAuthorization()
                }).then(template_res => {
                    let core;
                    if (template_res.data.rowCount > 0) {
                        core = template_res.data.rows[0];
                    }

                    let correctUIFormData = (() => {
                        if (Object.keys(cardEnrichmentRes.enrich_data).length > 0) {
                            return cardEnrichmentRes.enrich_data;
                        }
                        else {
                            return cardRes.card_data.form_data;
                        }
                    })();

                    ctxTemplateManager = new JSTemplateManager((block, masked, origin) => {
                        let skipRemoveNode = false;

                        // Check ONLY FOR CHILDREN if they are visible or required
                        if (block["field-tree-childs"].length === 0) {
                            skipRemoveNode = masked["field-is-visible"]/* || masked["field-is-required"]*/;
                        }
                        // Parents are filtered automatically if children do not exist. But we also add the mediamodal filter
                        else {
                            skipRemoveNode = !masked["field-is-mediamodal"];
                        }

                        return {
                            "keepNode": skipRemoveNode,
                            "isMediaNode": (
                                (masked["field-is-mediamodal"] !== false) &&
                                (block["field-tree-childs"].length > 0)
                            )
                        };
                    });

                    // Set visiblity mask for template if exists
                    ctxTemplateManager.setVisibilityMask(JSON.stringify(core.visibility_data) !== "{}" ? core.visibility_data : {});

                    // Load template into manager
                    ctxTemplateManager.parseTemplateJSON(core["template_data"]);

                    // Get header from manager
                    let headers = ctxTemplateManager.getAllHeaders();

                    // Get processed template
                    let processedTemplate = ctxTemplateManager.getProcessedTemplate();

                    // Get mediamodal sections
                    let mediaModalComponentsArray = ctxTemplateManager.getTemplateSectionsOfType("mediamodal");

                    // Get require inputs
                    let fieldsCalculated = this.generateRequiredInputs(
                        headers,
                        card_view_status,
                        correctUIFormData ? correctUIFormData : {}
                    );

                    // Query
                    let query = "/cards/" + card_uuid + "/enrichments/" + enrich_uuid + "/reviews";
                    axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                        auth: holdDataService.getAuthorization()
                    }).then(reviews_res => {
                        let reviews = reviews_res.data && reviews_res.data.rowCount > 0 ? reviews_res.data.rows : [];
                        let pfcFetchedComments = {};

                        // Cicla per ogni user review
                        for (let i = 0; i < reviews.length; i++) {
                            let userReview = reviews[i];
                            // Controlla che per ogni user review esistano commenti
                            if (userReview.review_data && Object.keys(userReview.review_data).length > 0) {
                                // Cicla per ogni field
                                for (let j = 0; j < Object.keys(userReview.review_data).length; j++) {
                                    let currentField_srvid = Object.keys(userReview.review_data)[j];
                                    // Aggiunge i dati dell'owner
                                    let currentComment = userReview.review_data[currentField_srvid] ? userReview.review_data[currentField_srvid].map(obj => {
                                        obj.comment_owner = userReview.review_owner;
                                        return obj;
                                    }) : [];

                                    //
                                    if (pfcFetchedComments[currentField_srvid]) {
                                        pfcFetchedComments[currentField_srvid] = [
                                            ...pfcFetchedComments[currentField_srvid],
                                            ...currentComment
                                        ];
                                    }
                                    else {
                                        pfcFetchedComments[currentField_srvid] = currentComment;
                                    }
                                }
                            }
                        }

                        // Sort Comments
                        for (let i = 0; i < Object.keys(pfcFetchedComments).length; i++) {
                            let fieldComments = pfcFetchedComments[Object.keys(pfcFetchedComments)[i]];
                            fieldComments.sort((a, b) => {
                                if (a.comment_date_create < b.comment_date_create) {
                                    return -1;
                                }
                                return 1;
                            });
                        }

                        this.setState({
                            loading: false,
                            core: core,
                            customTemplate: core["template_data"]["core_data"],
                            formData: correctUIFormData ? correctUIFormData : {},
                            visibilityData: cardRes.card_data ? cardRes.card_data.visibility_data : {},
                            card_uuid: card_uuid,
                            enrich_uuid: enrich_uuid,
                            card_view_status: card_view_status,
                            staging_state: cardEnrichmentRes.status_code,
                            card_image: cardRes.card_image,

                            //-------------------------------------------------| NEW

                            requiredInputs: fieldsCalculated.required,
                            activeTab: headers.length > 0 ? headers[0]["field-edusn-srvid"] : null,
                            headersToRender: headers,
                            templateToRender: processedTemplate,
                            pfcFetchedComments: pfcFetchedComments,
                            mediaModalComponentsArray: mediaModalComponentsArray,
                            assignIsCertifiable: cardEnrichmentRes.assign_is_certifiable === true ? true : false,

                            //-------------------------------------------------| WS METRIC

                            isWSEnrichCard: true
                        }, () => {
                            // Delegate trigger
                            this.dispatchWSOpenMetric(
                                MetricOpCodes.SourceType.Enrich
                            );
                        });
                    });
                }, err => {
                    this.setState({ loading: false })
                });
            }, err => {
                //
            });
        });
    }

  checkIfHeaderIsPopulated = (headerNode, formData) => {
    const formDataNode = formData[headerNode['field-code']];
    return this.recursiveCheckForValue(formDataNode);
  }

  recursiveCheckForValue = (obj) => {
    if (!obj) {
      return undefined;
    }
    if (typeof obj === "object") {
      let arrayValues = [];
      for (let i = 0; i < Object.keys(obj).length; i++) {
        if (Object.keys(obj)[i].indexOf("_check") !== -1) {
          // Skip
        }
        else {
          let currentSubObj = obj[Object.keys(obj)[i]];
          arrayValues.push(this.recursiveCheckForValue(currentSubObj));
        }
      }
      for (let j = 0; j < arrayValues.length; j++) {
        if (arrayValues[j] !== undefined) {
          return arrayValues[j];
        }
      }
      // If the resultant object or array is empty
      return undefined;
    }
    else {
      return obj;
    }
  }

  setupPFC() {
    let query = "/cards/" + this.state.card_uuid + "/enrichments/" + this.state.enrich_uuid + "/reviews";
    axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
      auth: holdDataService.getAuthorization()
    }).then(reviews_res => {
      let reviews = reviews_res.data && reviews_res.data.rowCount > 0 ? reviews_res.data.rows : [];
      let pfcFetchedComments = {};
      // Cicla per ogni user review
      for (let i = 0; i < reviews.length; i++) {
        let userReview = reviews[i];
        // Controlla che per ogni user review esistano commenti
        if (userReview.review_data && Object.keys(userReview.review_data).length > 0) {
          // Cicla per ogni field
          for (let j = 0; j < Object.keys(userReview.review_data).length; j++) {
            let currentField_srvid = Object.keys(userReview.review_data)[j];
            // Aggiunge i dati dell'owner
            let currentComment = userReview.review_data[currentField_srvid] ? userReview.review_data[currentField_srvid].map(obj => {
              obj.comment_owner = userReview.review_owner;
              return obj;
            }) : [];

            //
            if (pfcFetchedComments[currentField_srvid]) {
              pfcFetchedComments[currentField_srvid] = [
                ...pfcFetchedComments[currentField_srvid],
                ...currentComment
              ];
            }
            else {
              pfcFetchedComments[currentField_srvid] = currentComment;
            }
          }
        }
      }

      // Sort Comments
      for (let i = 0; i < Object.keys(pfcFetchedComments).length; i++) {
        let fieldComments = pfcFetchedComments[Object.keys(pfcFetchedComments)[i]];
        fieldComments.sort((a, b) => {
          if (a.comment_date_create > b.comment_date_create) {
            return -1;
          }
          return 1;
        });
      }
      this.setState({ pfcFetchedComments: pfcFetchedComments });
    });
  }

  scrollUp = () => {
    let div = document.getElementById("root");
    div.scrollTop = 0;
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      }, () => this.scrollUp());
    }
  }

  toggleAnnulla = () => {
    this.setState({
      modalAnnulla: !this.state.modalAnnulla
    })
  }

  toggleCheckRequireAnnulla = () => {
    this.setState({
      modalCheckRequireAnnulla: !this.state.modalCheckRequireAnnulla
    })
  }

  toggleRequiredProjectCertifier = () => {
    this.setState({
      modalRequiredProjectCertifier: !this.state.modalRequiredProjectCertifier
    })
  }

  userCanEdit = () => {
    if (this.state.card_view_status === "V") {
      return false;
    }
    else if (this.state.card_view_status === "E") {
      return true;
    }
    else if (this.state.card_view_status === "S") {
      switch (this.state.staging_state) {
        // Draft
        case "FW_CDER_0001":
          if (holdDataService.loggedUserIsStudent() && true) {// TODO: Manca check holdDataService.getLoggedUserUuid() === owner del task
            return true;
          }
          return false;
        // Draft-Comments
        case "FW_CDER_0002":
          /*if (holdDataService.loggedUserIsStudent() && true) {// TODO: Manca check holdDataService.getLoggedUserUuid() === fa parte del gruppo
            return true;
          }*/
          return false;
        // Pre-Evaluating
        case "FW_CDER_0003":
          if (holdDataService.loggedUserIsStudent() && true) {// TODO: Manca check holdDataService.getLoggedUserUuid() === owner del task
            return true;
          }
          return false;
        // Evaluating
        case "FW_CDER_0004":
          if (holdDataService.loggedUserIsTutor() && true) {// TODO: Manca check holdDataService.getLoggedUserUuid() === assigner del task
            return true;
          }
          return false;
        // Validating
        case "FW_CDER_0005":
          if (holdDataService.loggedUserIsCertifier() && true) {// TODO: Manca check holdDataService.getLoggedUserUuid() === ?
            return true;
          }
          return false;
        default:
          break;
      }
    }
    return false;
  }

  userCanEditFC = () => {
    if (this.state.card_view_status === "V") {
      return false;
    }
    else if (this.state.card_view_status === "E") {
      return true;
    }
    else if (this.state.card_view_status === "S") {
      switch (this.state.staging_state) {
        // Draft
        case "FW_CDER_0001":
          return false;
        // Draft-Comments
        case "FW_CDER_0002":
        case "FW_CDER_0003":
          if (holdDataService.loggedUserIsStudent()) {
            return true;
          }
          return false;
        // Evaluating
        case "FW_CDER_0004":
          if (holdDataService.loggedUserIsTutor()) {
            return true;
          }
          return false;
        // Validating
        case "FW_CDER_0005":
          if (holdDataService.loggedUserIsCertifier()) {
            return true;
          }
          return false;
        default: break;
      }
    }
    return false;
  }

  //--------------------------------------------------------------------------------| REQ. INPUTS

    generateRequiredInputs = (headers, viewStatus, formData) => {
        // Prepare data
        let requiredInputsStorage = {};
        let headersFlag = null;

        // Check for array
        if (Array.isArray(headers)) {
            // Get headers data
            headersFlag = headers.map((head) => {
                return this.generateRequiredInputs_helper(
                    head["field-edusn-srvid"],
                    formData,
                    requiredInputsStorage,
                    head["field-edusn-srvid"]
                );
            });

            // Filters out null values
            headersFlag = headersFlag.filter(x => x);

            // Check for skipped headers
            if ((
                    headers[0] &&
                    headersFlag.length > 0 &&
                    headersFlag[0] &&
                    headersFlag[0]["field-edusn-srvid"] !== headers[0]["field-edusn-srvid"]
                )
                ||
                (
                    headers[0] &&
                    headersFlag.length <= 0
                )) {
                // Replace headers with merged version
                headersFlag = [
                    headers[0],
                    ...headersFlag
                ];
            }
        }

        // Send back results
        return {
            "required": requiredInputsStorage,
            "headers": (
                viewStatus === "V" &&
                headersFlag
             ) ? headersFlag : headers
        };
    }

    generateRequiredInputs_helper = (node, nodeData, requireData, activeTab, nodePath = "", nodeIndex = 0) => {
        // Prepare data
        let curSectionNode = ctxTemplateManager.getTemplateSection(node);
        let curSectionMask = ctxTemplateManager.getVisibilitySection(node);

        // Prepare component data
        let pathSep = ".";
        let nChilds = [];

        // Prepare partial path
        let partialNodeData = null;
        let supportNodeData = Object.keys(nodeData ? nodeData : {});

        // Check for partial path
        if (supportNodeData.includes(curSectionNode["field-code"])) {
            // Save new path
            partialNodeData = nodeData[curSectionNode["field-code"]];
        }

        // Check for not empty path
        if (nodePath === "") {
            // Concat separator
            pathSep = "";
        }

        // Prepare flag for separator
        let isArrayOfGroupFields = false;

        // Check for block type: PARENT-NODE
        if (curSectionNode &&
            !curSectionNode["field-last-level"]) {
            // Check for group of fields
            if (curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0) {
                // Set as multi field array
                isArrayOfGroupFields = true;
            }

            // Check if children exists
            if (Array.isArray(curSectionNode["field-tree-childs"]) &&
                curSectionNode["field-tree-childs"].length > 0) {
                // Check for array
                if (Array.isArray(partialNodeData)) {
                    // Loop depending on sub data
                    nChilds = partialNodeData.map((subNodeData, index) => {
                        // Delegate to function
                        let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                            return this.generateRequiredInputs_helper(
                                child,
                                subNodeData,
                                requireData,
                                activeTab,
                                nodePath + pathSep + curSectionNode["field-code"] + pathSep + index,
                                index
                            );
                        });

                        // Filters out null values
                        tmpSubBlocks = tmpSubBlocks.filter(x => x);

                        // Return filtered
                        return tmpSubBlocks;
                    });
                }
                // Otherwise...
                else {
                    // Prepare sub blocks
                    let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                        return this.generateRequiredInputs_helper(
                            child,
                            partialNodeData,
                            requireData,
                            activeTab,
                            nodePath + pathSep + curSectionNode["field-code"] + (
                                isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                            )
                        );
                    });

                    // Filters out null values
                    nChilds = tmpSubBlocks.filter(x => x);
                }
            }
        }
        // Check for block type: LEAF-NODE
        else {
            // Check for array
            if (Array.isArray(partialNodeData) && (
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Loop depending on sub data
                nChilds = partialNodeData.map((subNodeData, index) => {
                    requireData[
                        "FIELD_ID__" + curSectionNode["field-edusn-srvid"] + "__" + index
                    ] = {
                        "block": {
                            ...curSectionNode,
                            "field-real-path": nodePath + pathSep + curSectionNode["field-code"] + pathSep + index,
                            "field-tab-owner": activeTab
                        },
                        "result": (
                            curSectionMask &&
                            curSectionMask["field-is-required"] ? (
                                subNodeData !== undefined &&
                                subNodeData !== null &&
                                subNodeData !== ""
                            ) : true
                        )
                    };

                    return (
                        subNodeData &&
                        subNodeData !== ""
                    ) ? true : null;
                });
            }
            // Check for unexpected array
            else if (Array.isArray(partialNodeData) && !(
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                requireData[
                    "FIELD_ID__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex
                ] = {
                    "block": {
                        ...curSectionNode,
                        "field-real-path": nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        ),
                        "field-tab-owner": activeTab
                    },
                    "result": (
                        curSectionMask &&
                            curSectionMask["field-is-required"] ? (
                                partialNodeData[0] !== undefined &&
                                partialNodeData[0] !== null &&
                                partialNodeData[0] !== ""
                            ) : true
                    )
                };
            }
            // Otherwise...
            else {
                requireData[
                    "FIELD_ID__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex
                ] = {
                    "block": {
                        ...curSectionNode,
                        "field-real-path": nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        ),
                        "field-tab-owner": activeTab
                    },
                    "result": (
                        curSectionMask &&
                        curSectionMask["field-is-required"] ? (
                            partialNodeData !== undefined &&
                            partialNodeData !== null &&
                            partialNodeData !== ""
                        ) : true
                    )
                };

                // Flag child
                nChilds = [(
                    partialNodeData &&
                    partialNodeData !== ""
                ) ? true : null];
            }
        }

        // Filters out null values
        nChilds = nChilds.filter(x => x);

        // Return populate or not
        return (nChilds.length > 0) ? curSectionNode : null;
    }

  //--------------------------------------------------------------------------------| NEW

    getTabBodiesHTML = (formData) => {
        // Get active tab header
        if (this.state.activeTab &&
            this.state.activeTab !== "") {
            // Render section of tab
            let tmpHTML = this.getTabBodiesHTML_helper(
                this.state.activeTab,
                formData
            );

            // Check for empty section
            if (tmpHTML === null) {
                // Replace content with warning message
                tmpHTML = (
                    <Alert style={{ margin: "0px" }} color="light">
                        <h4 className="alert-heading">Nessun campo disponibile!</h4>
                        <p>
                            Non risultano campi, con visibilità, disponibili per la sezione di scheda selezionata.<br />
                            Le sezioni permettono di focalizzare una porzione specifica della schede per le attività nei progetti.<br />
                            Una volta definito, per sezione e visibilità, i campi saranno consultabili nella sezione corrente.
                        </p>
                        <hr />
                        <p className="mb-0">
                            La sezione corrente non presenta campi visibili o valorizzati da mostrare.
                        </p>
                    </Alert>
                );
            }

            return tmpHTML;
        }

        // Always null
        return <div></div>;
    }

    getTabBodiesHTML_helper = (node, nodeData, nodePath = "", nodeIndex = 0) => {
        // Prepare data
        let curSectionNode = ctxTemplateManager.getTemplateSection(node);
        let curSectionMask = ctxTemplateManager.getVisibilitySection(node);

        // Prepare component data
        let pathSep = ".";
        let isMulti = false;
        let nChilds = [];

        // Prepare partial path
        let partialNodeData = null;
        let supportNodeData = Object.keys(nodeData ? nodeData : {});

        // Check for partial path
        if (supportNodeData.includes(curSectionNode["field-code"])) {
            // Save new path
            partialNodeData = nodeData[curSectionNode["field-code"]];
        }

        // Check for not empty path
        if (nodePath === "") {
            // Concat separator
            pathSep = "";
        }

        // Prepare flag for separator
        let isArrayOfGroupFields = false;

        // Check for block type: PARENT-NODE
        if (curSectionNode &&
            !curSectionNode["field-last-level"]) {
            // Check for group of fields
            if (curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0) {
                // Set as multi field array
                isArrayOfGroupFields = true;
            }

            // Check if children exists
            if (Array.isArray(curSectionNode["field-tree-childs"]) &&
                curSectionNode["field-tree-childs"].length > 0) {
                // Check for array
                if (Array.isArray(partialNodeData)) {
                    // Loop depending on sub data
                    nChilds = partialNodeData.map((subNodeData, index) => {
                        // Delegate to function
                        let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                            return this.getTabBodiesHTML_helper(
                                child,
                                subNodeData,
                                nodePath + pathSep + curSectionNode["field-code"] + pathSep + index,
                                index
                            );
                        });

                        // Filters out null values
                        tmpSubBlocks = tmpSubBlocks.filter(x => x);

                        // Return filtered
                        return tmpSubBlocks;
                    });
                }
                // Otherwise...
                else {
                    // Prepare sub blocks
                    let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                        return this.getTabBodiesHTML_helper(
                            child,
                            partialNodeData,
                            nodePath + pathSep + curSectionNode["field-code"] + (
                                isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                            )
                        );
                    });

                    // Filters out null values
                    tmpSubBlocks = tmpSubBlocks.filter(x => x);

                    // Replace value with filtered version
                    nChilds = tmpSubBlocks.length > 0 ? [tmpSubBlocks] : [];
                }
            }
        }
        // Check for block type: LEAF-NODE
        else {
            // Check for array
            if (Array.isArray(partialNodeData) && (
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Loop depending on sub data
                nChilds = partialNodeData.map((subNodeData, index) => {
                    return this.getTabBodiesHTML_filter(subNodeData) ? (<TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + index}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={index}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + pathSep + index}
                        formData={subNodeData}
                        updateData={this.updateJsonData}
                        updateReport={this.updateReportData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        sendPFCComment={this.sendPFCComment}
                        userCanEdit={this.userCanEdit()}
                        userCanEditFC={this.userCanEditFC()}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />) : null;
                });
            }
            // Check for unexpected array
            else if (
                Array.isArray(partialNodeData) && !(
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Create leaf
                nChilds = [(this.getTabBodiesHTML_filter(partialNodeData[0])) ? (
                    <TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={nodeIndex}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        )}
                        formData={partialNodeData[0]}
                        updateData={this.updateJsonData}
                        updateReport={this.updateReportData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        sendPFCComment={this.sendPFCComment}
                        userCanEdit={this.userCanEdit()}
                        userCanEditFC={this.userCanEditFC()}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />
                ) : null];
            }
            // Otherwise...
            else {
                // Create leaf
                nChilds = [(this.getTabBodiesHTML_filter(partialNodeData)) ? (
                    <TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={nodeIndex}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        )}
                        formData={partialNodeData}
                        updateData={this.updateJsonData}
                        updateReport={this.updateReportData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        sendPFCComment={this.sendPFCComment}
                        userCanEdit={this.userCanEdit()}
                        userCanEditFC={this.userCanEditFC()}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />
                ) : null];
            }
        }

        // Check for array of component
        if (curSectionNode["field-max-occurs"] > 1 ||
            curSectionNode["field-max-occurs"] <= 0) {
            isMulti = true;
        }

        // Filters out null values
        nChilds = nChilds.filter(x => x);

        // Send back component
        return nChilds.length > 0 ? (<TemplateFieldGroup
            key={"JSX_GROUP__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
            fieldData={curSectionNode}
            fieldMask={curSectionMask}
            fieldChildren={nChilds}
            fieldIndex={nodeIndex}
            fieldRealPath={nodePath + pathSep + curSectionNode["field-code"]}
            formData={partialNodeData}
            isMultiGroup={isMulti}
            isArrayOfGroupFields={isArrayOfGroupFields}
            updateData={this.updateJsonData}
            updateReport={this.updateReportData}
            updateInfo={this.updateInfoData}
            userCanEdit={this.userCanEdit()}
            userCanEditFC={this.userCanEditFC()}
        />) : null;
    }

  getTabBodiesHTML_filter = (nodeData) => {
    if (this.state.card_view_status === "V") {
      if (nodeData) {
        return true;
      }
      else {
        return false;
      }
    }

    return true;
  }

    updateJsonData = (jsonPath, jsonValue) => {
        // Prepare data
        let newJsonData = ctxTemplateManager.updateFormDataProperty(
            this.state.formData,
            jsonPath,
            jsonValue
        );

        // Set new data
        this.setState({
            formData: newJsonData
        });
    }

    updateReportData = (reportPath, reportBlock, reportValue, asInsert = true) => {
        this.setState((prevState) => {
          // Prepare data
          let bakReportData = JSON.parse(JSON.stringify(prevState.requiredInputs));

          // Check action type
          if (asInsert) {
              // Inject data
              bakReportData[reportPath] = {
                  "block": reportBlock,
                  "result": reportValue
              };
          }
          // Otherwise check for Unmount or Delete...
          else if (!asInsert && reportBlock["field-tab-owner"] === this.state.activeTab) {
              // Remove data from state
              delete bakReportData[reportPath];
          }

          // Set new data
          return {
            requiredInputs: bakReportData
          };
        });
    }

    updateInfoData = (fieldData) => {
        // Prepare data
        let infoDesc = null;
        let infoExamples = [];

        // Check for info data
        if (fieldData && fieldData["field-description"]) {
            infoDesc = fieldData["field-description"];
            infoExamples = Array.isArray(
                fieldData["field-desc-example"]
            ) ? fieldData["field-desc-example"] : [];
        }

        // Set new data
        this.setState({
            infoSectionDescription: infoDesc,
            infoSectionExamples: infoExamples
        });
    }

  //--------------------------------------------------------------------------------| END

  /* V CRUD V */

  editCard = () => {
    let query = '/cards/' + this.state.card_uuid;
    let payload = {};
    payload.card_is_draft = true; // FALSE = status change, TRUE = current status
    payload.card_data = {};
    payload.card_data.form_data = this.state.formData;
    payload.card_data.visibility_data = this.state.visibilityData;
    axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
      {
        auth: holdDataService.getAuthorization()
      }).then(res => {
        this.props.history.push('/');
      }, err => {
        //
      });
  }

  fromDraftToDraftComments = (saveIsDraft) => {
    let query = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid;
    let payload = {};
    payload.enrich_data = this.state.formData;
    payload.enrich_is_draft = saveIsDraft;
    axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
      {
        auth: holdDataService.getAuthorization()
      }).then(res => {
        this.props.history.push('/');
      }, err => {
        //
      });
  }

  fromDraftComments = () => {
    let query = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid + '/reviews';
    let payload = {};
    let comments = {};
    if (Object.keys(this.state.pfcCommentsInEdit).length > 0) {
      comments = this.state.pfcCommentsInEdit;
    }
    payload.review_data = comments;
    axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
      {
        auth: holdDataService.getAuthorization()
      }).then(res => {
        this.props.history.push('/');
      }, err => {
        //
      });
  }

    fromPreEvaluatingToEvaluating = (saveIsDraft) => {
        let query = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid;
        let payload = {};
        payload.enrich_data = this.state.formData;
        payload.enrich_is_draft = true;
        payload.enrich_is_rejected = false;
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
        {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            let reviewQuery = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid + '/reviews';
            let reviewPayload = {};
            let comments = {};
            if (Object.keys(this.state.pfcCommentsInEdit).length > 0) {
                comments = this.state.pfcCommentsInEdit;
            }
            reviewPayload.enrich_is_draft = saveIsDraft;
            reviewPayload.enrich_is_rejected = false;
            reviewPayload.review_data = comments;
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + reviewQuery, reviewPayload,
            {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                this.props.history.push('/');
            }, err => {
                //
            });
        }, err => {
            //
        });
    }

    fromEvaluatingToValidating = (saveIsDraft) => {
        let query = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid;
        let payload = {};
        payload.enrich_data = this.state.formData;
        payload.enrich_is_draft = true;
        payload.enrich_is_rejected = false;
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
        {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            let reviewQuery = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid + '/reviews';
            let reviewPayload = {};
            let comments = {};
            if (Object.keys(this.state.pfcCommentsInEdit).length > 0) {
                comments = this.state.pfcCommentsInEdit;
            }
            reviewPayload.enrich_is_draft = saveIsDraft;
            reviewPayload.enrich_is_rejected = false;
            reviewPayload.review_data = comments;
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + reviewQuery, reviewPayload,
            {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                this.props.history.push('/');
            }, err => {
                //
            });
        }, err => {
            //
        });
    }

    fromEvaluatingToPreEvaluating = () => {
        let query = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid;
        let payload = {};
        payload.enrich_data = this.state.formData;
        payload.enrich_is_draft = true;
        payload.enrich_is_rejected = false;
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
        {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            let reviewQuery = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid + '/reviews';
            let reviewPayload = {};
            let comments = {};
            if (Object.keys(this.state.pfcCommentsInEdit).length > 0) {
                comments = this.state.pfcCommentsInEdit;
            }
            reviewPayload.review_data = comments;
            reviewPayload.enrich_is_draft = false;
            reviewPayload.enrich_is_rejected = true;
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + reviewQuery, reviewPayload,
            {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                this.props.history.push('/');
            }, err => {
                //
            });
        }, err => {
            //
        });
    }

  fromValidatingForward = (saveIsDraft) => {
    let query = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid;
    let payload = {};
    payload.enrich_data = this.state.formData;
    payload.enrich_is_draft = saveIsDraft;
    payload.enrich_is_rejected = false;
    axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
      {
        auth: holdDataService.getAuthorization()
      }).then(res => {
        this.props.history.push('/');
      }, err => {
        //
      });
  }

    fromValidatingToEvaluating = () => {
        let query = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid;
        let payload = {};
        payload.enrich_data = this.state.formData;
        payload.enrich_is_draft = true;
        payload.enrich_is_rejected = false;
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload,
        {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            let reviewQuery = '/cards/' + this.state.card_uuid + '/enrichments/' + this.state.enrich_uuid + '/reviews';
            let reviewPayload = {};
            let comments = {};
            if (Object.keys(this.state.pfcCommentsInEdit).length > 0) {
                comments = this.state.pfcCommentsInEdit;
            }
            reviewPayload.review_data = comments;
            reviewPayload.enrich_is_rejected = true;
            reviewPayload.enrich_is_draft = false;
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + reviewQuery, reviewPayload,
            {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                this.props.history.push('/');
            }, err => {
                //
            });
        }, err => {
            //
        });
    }

  checkRequiredInputs = () => {
    let requiredInputs     = JSON.parse(JSON.stringify(this.state.requiredInputs));
    let requiredInputsKeys = Object.keys(requiredInputs);

    return requiredInputsKeys.map((key) => {
        return requiredInputs[key].result ? null : requiredInputs[key].block;
    })
    .filter(x => x);
  }

  handleSubmit = (forward, saveIsDraft = false) => {
    this.setState({
      modalCheckRequireAnnulla: false,
      brokenRequiredInputs: []
    },
    () => {
      let checkResults = this.checkRequiredInputs();

      if (checkResults.length <= 0) {
        if (this.state.card_view_status === "E") {
          this.editCard();
        }
        else if (this.state.card_view_status === "S") {
          switch (this.state.staging_state) {
            // Draft
            case "FW_CDER_0001":
              this.fromDraftToDraftComments(saveIsDraft);
              break;
            // Draft-Comments
            case "FW_CDER_0002":
              this.fromDraftComments();
              break;
            // Pre-Evaluating
            case "FW_CDER_0003":
              this.fromPreEvaluatingToEvaluating(saveIsDraft);
              break;
            // Evaluating
            case "FW_CDER_0004":
              if (forward) {
                  if (saveIsDraft) {
                    this.fromEvaluatingToValidating(true);
                  }
                  else if (this.state.assignIsCertifiable) {
                    this.fromEvaluatingToValidating(false);
                  }
                  else {
                    this.toggleRequiredProjectCertifier();
                  }
              }
              else {
                this.fromEvaluatingToPreEvaluating();
              }
              break;
            // Validating
            case "FW_CDER_0005":
              if (forward) {
                this.fromValidatingForward(saveIsDraft)
              }
              else {
                this.fromValidatingToEvaluating()
              }
              break;
            default:
              break;
          }
        }
      }
      else {
        this.setState({
          modalCheckRequireAnnulla: true,
          brokenRequiredInputs: checkResults
        });
      }
    });
  }

  getCorrectForwardLabel = () => {
    if (this.state.card_view_status === "E") {
      return "Salva Scheda";
    }
    else if (this.state.card_view_status === "S") {
      switch (this.state.staging_state) {
        // Draft
        case "FW_CDER_0001":
          return "Invia al Team";
        // Draft-Comments
        case "FW_CDER_0002":
          return "Invia Revisione";
        // Pre-Evaluating
        case "FW_CDER_0003":
          return "Invia al Docente";
        // Evaluating
        case "FW_CDER_0004":
          return "Invia al Certificatore";
        // Validating
        case "FW_CDER_0005":
          return "Certifica Scheda";
        default:
          return "Salva e continua";
      }
    }
  }

  /* ^ CRUD ^ */

  sendPFCComment = (inputName, inputRefID, scrollCB) => {
    let commentsInEdit = JSON.parse(JSON.stringify(this.state.pfcCommentsInEdit));
    let pfcGetComment = document.getElementById(inputRefID);

    if (commentsInEdit[inputName]) {
      // Do nothing
    }
    else {
      commentsInEdit[inputName] = [];
    }

    if (pfcGetComment !== null &&
      pfcGetComment.value !== "") {
      let comment = {};
      comment.comment_message = pfcGetComment.value.trim();
      comment.comment_date_create = Date.now();

      commentsInEdit[inputName].push(comment);

      this.setState({
        pfcCommentsInEdit: commentsInEdit
      }, () => scrollCB());
    }
  }

  scrollNavRight = (event) => {
    let nav = $(event.currentTarget).parent().find("ul.nav");
    let previousScrollAmount = nav.scrollLeft();
    nav.animate({ scrollLeft: previousScrollAmount - 200 }, 200);
  }

  scrollNavLeft = (event) => {
    let nav = $(event.currentTarget).parent().find("ul.nav");
    let previousScrollAmount = nav.scrollLeft();
    nav.animate({ scrollLeft: previousScrollAmount + 200 }, 200);
  }

  toggleMediaModal = (parentObj = null) => {
    /*this.setState({ showMediaModal: !this.state.showMediaModal }, () => {
      if (this.state.showMediaModal) {
        this.getMediaModalData(parentObj);
      }
    });*/
    this.setState({
        showMediaModal: !this.state.showMediaModal,
        showMediaNodeObj: parentObj
    });
  }

  getMediaModalData = () => {
    // Generate JSON of media
    /*let mediaJSON = {};
    if (ctxTemplateManager) {
      // Get all input-file fields from TemplateManager
      let visibileInputFiles = ctxTemplateManager.getTemplateSectionsOfType("input-file");
      for (let i = 0; i < visibileInputFiles.length; i++) {
        let currentInput = visibileInputFiles[i]["field-edusn-srvid"];
        // Generate an entry in the JSON for every field
        mediaJSON[currentInput] = {};
        // Label
        mediaJSON[currentInput].label = visibileInputFiles[i]["field-name"];
        // Field to check field multeplicity
        mediaJSON[currentInput].parametrizedPath = visibileInputFiles[i]["field-edusn-xpath-p"];
        mediaJSON[currentInput].isArray = visibileInputFiles[i]["field-edusn-xpath-p"].includes("#");
        // Get data from xpath and TemplateManager
        let currentXPath = visibileInputFiles[i]["field-edusn-xpath"];
        let currentData = ctxTemplateManager.resolveFormDataProperty(this.state.formData, currentXPath.join("."));
        mediaJSON[currentInput].data = currentData;
      }
    }

    return mediaJSON;
    // Test delete
    /*if (visibileInputFiles.length > 0 && mediaJSON["OA-FTAR-S0001"].data.length === 2) {
      mediaJSON["OA-FTAR-S0001"].parametrizedPath.pop();
      mediaJSON["OA-FTAR-S0001"].parametrizedPath.pop();
      let paramPath = mediaJSON["OA-FTAR-S0001"].parametrizedPath.join(".");
      let index = 1;
      let asd = ctxTemplateManager.resolveFormDataProperty(this.state.formData, paramPath);
      if (asd) {
        if (Array.isArray(asd)) {
          asd.splice(index, 1);
        }
        else {
          delete asd[index];
        }
        this.updateJsonData(paramPath, asd);
      }
    }*/

    if (this.state.showMediaNodeObj) {
        let nodeToShow = this.state.showMediaNodeObj;
        let dataToShow = this.state.formData;
        let arPathBase = nodeToShow["field-edusn-xpath"].slice(0);

        if (arPathBase.length > 0) {
            arPathBase.pop()
        }

        arPathBase.map((curNode) => {
            if (dataToShow[curNode]) {
                dataToShow = dataToShow[curNode];
            }
            return true;
        });

        if (nodeToShow &&
            nodeToShow["field-edusn-srvid"]) {
            let tmpHTML = this.getTabBodiesHTML_helper(
                nodeToShow["field-edusn-srvid"],
                dataToShow,
                arPathBase.join(".")
            );

            // Check for empty section
            if (tmpHTML === null) {
                // Replace content with warning message
                tmpHTML = (
                    <Alert style={{ margin: "0px" }} color="light">
                        <h4 className="alert-heading">Nessun campo disponibile!</h4>
                        <p>
                            Non risultano campi, con visibilità, disponibili per la sezione di scheda selezionata.<br />
                            Le sezioni permettono di focalizzare una porzione specifica della schede per le attività nei progetti.<br />
                            Una volta definito, per sezione e visibilità, i campi saranno consultabili nella sezione corrente.
                        </p>
                        <hr />
                        <p className="mb-0">
                            La sezione corrente non presenta campi visibili o valorizzati da mostrare.
                        </p>
                    </Alert>
                );
            }

            return tmpHTML;
        }
    }

    return <div></div>;
  }

  updateMediaData = (realPath, value) => {
    this.updateJsonData(realPath, value);
  }

  addArrayMediaData = (paramPath, srvid, oldData) => {
    let realPath = paramPath.replace("#", oldData ? oldData.length : "0");
    this.updateJsonData(realPath, "");
  }

  removeArrayMediaData = (mediaObj) => {
    if (mediaObj.data && mediaObj.data.length > 0) {
      let mediaObjParametrizedPath = mediaObj.parametrizedPath.slice(0);
      mediaObjParametrizedPath.pop();
      mediaObjParametrizedPath.pop();
      let paramPath = mediaObjParametrizedPath.join(".");
      let index = mediaObj.data.length - 1;
      let parent = ctxTemplateManager.resolveFormDataProperty(this.state.formData, paramPath);
      if (parent) {
        if (Array.isArray(parent)) {
          parent.splice(index, 1);
        }
        else {
          delete parent[index];
        }
        this.updateJsonData(paramPath, parent);
      }
    }
  }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardImage = () => {
        let coreImage = EdubbaDefaultImage;

        if (ctxTemplateManager) {
            let tmpCoreImage = ctxTemplateManager.resolveTemplateProperty(this.state.formData, "card_image");

            if (tmpCoreImage &&
                this.isValidURL(tmpCoreImage)) {
                coreImage = tmpCoreImage;
            }
        }

        return coreImage;
    }

    getBGCardImage = () => {
        let coreImage = null;

        if (ctxTemplateManager) {
            let tmpCoreImage = ctxTemplateManager.resolveTemplateProperty(this.state.formData, "card_image");

            if (tmpCoreImage &&
                this.isValidURL(tmpCoreImage)) {
                coreImage = tmpCoreImage;
            }
        }

        return coreImage;
    }

  getCardAttributeByPathArray = (card, pathArray) => {
    if (pathArray.length > 0) {
      if (card && card[pathArray[0]]) {
        let nestedCard = card[pathArray[0]];
        pathArray.shift();
        return this.getCardAttributeByPathArray(nestedCard, pathArray);
      }
      else return undefined;
    }
    return (typeof card === "object") ? undefined : card;
  }

  getCardTitle = () => {
    if (ctxTemplateManager) {
      return ctxTemplateManager.resolveTemplateProperty(this.state.formData, "card_title");
    }

    return null;
  }

  getCardProject = () => {

  }

  getCardAttributeByPathArray = (card, pathArray) => {
    if (pathArray.length > 0) {
      if (card && card[pathArray[0]]) {
        let nestedCard = card[pathArray[0]];
        pathArray.shift();
        return this.getCardAttributeByPathArray(nestedCard, pathArray);
      }
      else return undefined;
    }
    return (typeof card === "object") ? undefined : card;
  }

    buttonsShouldShow = () => {
        if (this.state.card_view_status === "E") {
            if (holdDataService.loggedUserIsTutor() ||
                holdDataService.loggedUserIsCertifier()) {
                return true;
            }
            else return false;
        }
        else if (this.state.card_view_status === "S") {
            switch (this.state.staging_state) {
                // Draft
                // Draft-Comments
                // Pre-Evaluating
                case "FW_CDER_0001":
                case "FW_CDER_0002":
                case "FW_CDER_0003":
                    if (holdDataService.loggedUserIsStudent()) {
                        return true;
                    }
                    else return false;

                // Evaluating
                case "FW_CDER_0004":
                    if (holdDataService.loggedUserIsTutor()) {
                        return true;
                    }
                    else return false;

                // Validating
                case "FW_CDER_0005":
                    if (holdDataService.loggedUserIsCertifier()) {
                        return true;
                    }
                    else return false;

                // Certified
                case "FW_CDER_0006":
                default: return false;
            }
        }

        return false;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

  render() {
    let firstHeader = null;
    let tailHeaders = this.state.headersToRender.slice(0);
    let modalConatiners = {
        "enrich": [],
        "iccd": []
    };

    if (this.state.headersToRender &&
        this.state.headersToRender.length > 0) {
        firstHeader = tailHeaders[0];
        tailHeaders.splice(0, 1);
    }

    if (Array.isArray(this.state.mediaModalComponentsArray)) {
        this.state.mediaModalComponentsArray.map((obj) => {
            // Get mask info for node
            let maskInfo = ctxTemplateManager.getVisibilitySection(
                obj["field-edusn-srvid"]
            );

            // Extract flag enrichment
            let isEnrich = maskInfo["field-is-enrichment"];

            // Extract icon
            let maskIcon = maskInfo["field-is-mediamodal"];
            let maskText = "";

            // Normalize icon
            if (maskIcon === "fas fa-file-file") {
            maskIcon = faFile;
            maskText = "altri";
            }
            else if (maskIcon === "fas fa-file-image") {
            maskIcon = faFileImage;
            maskText = "foto";
            }
            else if (maskIcon === "fas fa-file-video") {
            maskIcon = faFileVideo;
            maskText = "video";
            }
            else if (maskIcon === "fas fa-file-archive") {
            maskIcon = faFileArchive;
            maskText = "fonti";
            }
            else if (maskIcon === "fas fa-file-alt") {
            maskIcon = faFileAlt;
            maskText = "rif.";
            }
            else return null;

            // Generate HTML
            let htmlIcon = (
                <span key={"span_mediamodal_content__" + obj["field-edusn-srvid"]}>
                    <FontAwesomeIcon
                        key={"mediamodal_content__" + obj["field-edusn-srvid"]}
                        icon={maskIcon}
                        className='icon'
                        onClick={() => this.toggleMediaModal(obj)}
                        style={{fontSize: "2em"}}
                        data-tip data-for={"TIPS_FOR_mediamodal_content__" + obj["field-edusn-srvid"]}
                    />
                    <span>{maskText}</span>
                    <ReactTooltip
                        id={"TIPS_FOR_mediamodal_content__" + obj["field-edusn-srvid"]}
                        wrapper="div"
                        place="top"
                        effect="solid">
                        {(isEnrich ? "Arricchimento: " : "ICCD: ") + obj["field-name"]}
                    </ReactTooltip>
                </span>
            );

            // Check for enrich
            if (isEnrich) {
                // Save into enrich storage
                modalConatiners.enrich.push(htmlIcon);
            }
            else {
                // Save into iccd storage
                modalConatiners.iccd.push(htmlIcon);
            }

            // Always return
            return true;
        });
    }

    return this.state.loading ? <EdubbaLoader /> : (
      <Fragment>
        <Container className='StudentView'>
          <div className="top-header" style={{ backgroundImage: "url(" + this.getBGCardImage() + ")" }}>
            <div className="top-header-transparency">
              <div className="top-header-img-area">
                <img className="top-header-img" src={this.getCardImage()} alt="" onError={this.setDefaultImgSrc} />
              </div>
              <div className="top-header-info-area">
                <div className="top-header-woa-title">
                  {this.getCardTitle()}
                </div>
                {/*this.state.card_view_status === "S" ? <div className="top-header-woa-project">
                  {this.getCardProject()}
                </div> : null*/}
                <div className="top-header-woa-media-buttons">
                    {
                        modalConatiners.enrich.length > 0 ?
                        <div className="woa-enrich-container">
                            <span>enrich</span>
                            {
                                modalConatiners.enrich.map((obj) => {
                                    // Send back data
                                    return obj;
                                })
                            }
                        </div>
                        : null
                    }
                    {
                        modalConatiners.iccd.length > 0 ?
                        <div className="woa-iccd-container">
                            <span>iccd</span>
                            {
                                modalConatiners.iccd.map((obj) => {
                                    // Send back data
                                    return obj;
                                })
                            }
                        </div>
                        : null
                    }
                </div>
              </div>
            </div>
          </div>
          {/* V MediaModal V */}
          <Modal className='edubba-modal media-modal'
            centered
            aria-labelledby="contained-modal-title-vcenter"
            isOpen={this.state.showMediaModal}
            toggle={() => this.toggleMediaModal()}
            backdrop='static'
          >
            <ModalHeader>
              <div className='widget-content p-0'>
                <div className='widget-content-wrapper'>
                  <div className='widget-content-left text-center w-100'>
                    Media allegati
                  </div>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <PerfectScrollbar>
                <Row className='tab-body RootCardSection'>
                    <Col xs="8">
                        <div className="CardContent">
                            <div className="CardHeader">
                            CONTENUTO SCHEDA
                            </div>
                            <div className="CardBody">
                                {this.getMediaModalData()}
                            </div>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="InfoContent">
                            <div className="InfoHeader">
                            INFORMAZIONI
                            </div>
                            <div className="InfoOverflow">{
                            this.state.infoSectionDescription ?
                                <Fragment>
                                <div className="InfoDesc">
                                    <b>Descrizione:</b><br />
                                    {this.state.infoSectionDescription}
                                </div>
                                {
                                    this.state.infoSectionExamples.length > 0 ?
                                    <div className="InfoExample">
                                        <b>Esempi:</b><br />
                                        {
                                        this.state.infoSectionExamples.map((example, index) => {
                                            return (
                                                <div key={"example-" + index}>{example}</div>
                                            );
                                        })
                                        }
                                    </div>
                                    : null
                                }
                                </Fragment>
                                : (
                                <Alert style={{ margin: "0px" }} color="light">
                                    <p>
                                    Non risultano descrizioni disponibili per il campo della scheda selezionato.
                                    Le descrizioni permettono di compilare le parti della scheda con maggior facilità e coerenza.
                                        </p>
                                    <hr />
                                    <p className="mb-0">
                                    Per visionare l'help dei campi fare click sul tasto
                                            <FontAwesomeIcon icon={faQuestion} className="HelpQuestionIcon" />
                                    posizionato a destra del titolo del campo.
                                        </p>
                                </Alert>
                                )
                            }</div>
                        </div>
                    </Col>
                </Row>
              </PerfectScrollbar>
            </ModalBody>
            <ModalFooter>
              <Button className='btn-edubba' onClick={event => {
                event.preventDefault();
                this.toggleMediaModal();
              }}>
                Chiudi
              </Button>
            </ModalFooter>
          </Modal>
          <Modal className='edubba-modal select-template-modal'
            centered
            aria-labelledby="contained-modal-title-vcenter"
            size='md'
            isOpen={this.state.modalAnnulla}
            toggle={this.toggleAnnulla}
            backdrop='static'
          >
            <ModalHeader toggle={this.toggleAnnulla}>
              <div className='widget-content p-0'>
                <div className='widget-content-wrapper'>
                  <div className='widget-content-left mr-3'>
                    <FontAwesomeIcon icon={faQuestion} className='icon' />
                  </div>
                  <div className='widget-content-left mr-3 text-center w-100'>
                    Attenzione
                    </div>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <Container className='help-text'>
                <Row>
                  <Col lg='12' md='12' sm='12' className='inner-modal'>
                    Sicuro di voler annullare la modifica della scheda ?
                      <br />
                    Le modifiche saranno perse
                    </Col>
                </Row>
                <br />
                <Row>
                  <Col lg='6' md='6' sm='12' className='inner-modal'>
                    <Button className='btn-edubba' onClick={event => {
                      event.preventDefault();
                      // this.props.history.push('/cards');
                      this.toggleAnnulla();
                    }}>
                      Annulla
                    </Button>
                  </Col>
                  <Col lg='6' md='6' sm='12' className='inner-modal'>
                    <Button className='btn-edubba' onClick={event => {
                      event.preventDefault();
                      this.props.history.push('/');

                    }}>
                      Conferma
                    </Button>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal className='edubba-modal select-template-modal check-required-inputs'
            centered
            aria-labelledby="contained-modal-title-vcenter"
            size='md'
            isOpen={this.state.modalCheckRequireAnnulla}
            toggle={this.toggleCheckRequireAnnulla}
            backdrop='static'>
            <Alert color="danger">
              <h4 className="alert-heading">Compilare campi obbligatori!</h4>
              <hr />
              <p>
                Prima di procedere è necessario compilare tutti i campi obbligatori presenti nella scheda. Numero <b>{this.state.brokenRequiredInputs.length}</b> campi obbligatori non risultano compilati.
              </p>
              <p className="mb-0">
                  <Button className='btn-edubba' onClick={event => {
                       event.preventDefault();
                       this.toggleCheckRequireAnnulla();
                    }}>
                    Chiudi e Continua le Modifiche
                  </Button>
              </p>
            </Alert>
          </Modal>
          <Row
            className='tab-header'
            style={this.state.card_image && false ? { backgroundImage: "url(" + this.state.card_image + ")" } : null}>
                {
                    firstHeader ?
                        <Col xs="2" style={{padding: "0px"}} className={
                            "FirstHeaderItemLink" + (this.state.activeTab === firstHeader["field-edusn-srvid"] ? " ActiveFirstItem" : "")
                        }>
                            <div
                              onClick={() => {
                                  this.toggle(this.state.headersToRender[0]["field-edusn-srvid"]);
                              }}
                            >
                              <div>{this.state.headersToRender[0]["field-name"]}</div>
                            </div>
                        </Col>
                    : null
                }
                <Col xs={firstHeader ? "10" : "12"} style={{padding: "0px"}}>
                    <div className="tab-header-transparency">
                        <div className="nav-scroller-right" onClick={this.scrollNavRight}>
                            <FontAwesomeIcon icon={faCaretLeft} />
                        </div>
                        <Nav justified>
                            {(tailHeaders.length > 0) ?
                                tailHeaders.map((tab) => {
                                    return (
                                    <NavItem key={`header-${tab["field-edusn-srvid"]}`}>
                                        <NavLink
                                          className={classnames({ active: this.state.activeTab === tab["field-edusn-srvid"] })}
                                          onClick={() => {
                                              this.toggle(tab["field-edusn-srvid"]);
                                          }}
                                        >
                                        {tab["field-name"]}
                                        </NavLink>
                                    </NavItem>
                                    );
                                })
                                : <NavItem key={`header-no-items`} />
                            }
                        </Nav>
                        <div className="nav-scroller-left" onClick={this.scrollNavLeft}>
                            <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                    </div>
                </Col>
          </Row>
          <Row className='tab-body RootCardSection' style={{ padding: "40px 0px 30px 0px" }}>
            <Col xs="8">
              <div className="CardContent">
                <div className="CardHeader">
                  CONTENUTO SCHEDA
                </div>
                <div className="CardBody">
                  <TabContent activeTab={this.state.activeTab}>
                    {
                      this.getTabBodiesHTML(this.state.formData)
                    }
                  </TabContent>
                </div>
              </div>
            </Col>
            <Col xs="4">
              <div className="InfoContent">
                <div className="InfoHeader">
                  INFORMAZIONI
                </div>
                <div className="InfoOverflow">{
                  this.state.infoSectionDescription ?
                    <Fragment>
                      <div className="InfoDesc">
                        <b>Descrizione:</b><br />
                        {this.state.infoSectionDescription}
                      </div>
                      {
                        this.state.infoSectionExamples.length > 0 ?
                          <div className="InfoExample">
                            <b>Esempi:</b><br />
                            {
                              this.state.infoSectionExamples.map((example, index) => {
                                return (
                                    <div key={"example-" + index}>{example}</div>
                                );
                              })
                            }
                          </div>
                          : null
                      }
                    </Fragment>
                    : (
                      <Alert style={{ margin: "0px" }} color="light">
                        <p>
                          Non risultano descrizioni disponibili per il campo della scheda selezionato.
                          Le descrizioni permettono di compilare le parti della scheda con maggior facilità e coerenza.
                            </p>
                        <hr />
                        <p className="mb-0">
                          Per visionare l'help dei campi fare click sul tasto
                                <FontAwesomeIcon icon={faQuestion} className="HelpQuestionIcon" />
                          posizionato a destra del titolo del campo.
                            </p>
                      </Alert>
                    )
                }</div>
              </div>
            </Col>
          </Row>
            {
                this.buttonsShouldShow() ?
                    <div className='footer-buttons'>
                        <button
                            className='btn btn-edubba btn-edubba-red'
                            type="button"
                            onClick={event => {
                                event.preventDefault();
                                this.toggleAnnulla();
                            }}>
                            Annulla
                        </button>
                        {
                            [
                                "FW_CDER_0004",
                                "FW_CDER_0005"
                            ]
                            .includes(this.state.staging_state) ?
                                <button
                                className={'btn btn-edubba'}
                                    type="button"
                                    onClick={() => this.handleSubmit(false)}>
                                    {'Rifiuta e Continua'}
                                </button>
                            : null
                        }
                        {
                            [
                                "FW_CDER_0001",
                                "FW_CDER_0003",
                                "FW_CDER_0004",
                                "FW_CDER_0005"
                            ]
                            .includes(this.state.staging_state) ?
                                <button
                                className={'btn btn-edubba'}
                                    type="button"
                                    onClick={() => this.handleSubmit(true, true)}>
                                    {'Salva in Bozza'}
                                </button>
                            : null
                        }
                        <button
                            className={'btn btn-edubba'}
                            type="submit"
                            onClick={() => this.handleSubmit(true)}>
                            {this.getCorrectForwardLabel()}
                        </button>
                    </div>
                : null
            }
        </Container>
        {
            this.state.modalRequiredProjectCertifier ?
                <RequiredProjectCertifier
                    toggleRequiredProjectCertifier={this.toggleRequiredProjectCertifier}
                />
            : null
        }
      </Fragment >
    );
  }
}

//----------------------------------------------------------------------| TEMPLATE FIELDS

class TemplateFieldGroup extends Component {
  constructor(props) {
    super(props);
    // fieldData: Object {} => Struttura
    // fieldMask: Object {} => Visibilità
    // fieldIndex: Number => Index array
    // fieldRealPath: String => Path calcolato con index
    // fieldChildren: Array [{}] => TemplateField
    // formData: Object {} => Data
    // isMultiGroup: Boolean
    // updateData: Function() => Salvataggio del parziale nel JSON root
    // userCanEdit: Boolean

    this.state = {};
  }

  addNode = () => {
    let maxOccurs = this.props.fieldData["field-max-occurs"];
    let tmpValues = this.props.formData;

    if (!Array.isArray(tmpValues)) {
      tmpValues = [tmpValues];
    }

    if (Array.isArray(tmpValues) &&
      (
        (maxOccurs > 1 && tmpValues.length < maxOccurs) ||
        (maxOccurs === 0)
      )) {
      tmpValues.push(null);

      this.props.updateData(
        this.props.fieldRealPath,
        tmpValues
      );
    }
  }

  delNode = () => {
    let minOccurs = this.props.fieldData["field-min-occurs"];
    let tmpValues = this.props.formData;

    if (Array.isArray(tmpValues) &&
      tmpValues.length > minOccurs) {
      tmpValues.pop();

      this.props.updateData(
        this.props.fieldRealPath,
        tmpValues
      );
    }
  }

  infoNode = () => {
    this.props.updateInfo(
      this.props.fieldData
    );
  }

  render() {
    let isOdd = this.props.fieldData["field-tree-level"] % 2 !== 0;

    return (
      <div
        name={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
        key={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
        className={"TemplateFieldGroup" + (isOdd ? " TemplateFieldGroupOdd" : "")}>
        <div className={"SectionHeader" + (isOdd ? " SectionHeaderOdd" : "")}>
          <div className="SectionName">
            {this.props.fieldData["field-name"].toLowerCase()}
          </div>
          <div className="SectionMultiBtn">
            <FontAwesomeIcon icon={faQuestion} className="MultiBtn Info" onClick={this.infoNode} />
            {
              this.props.isMultiGroup && this.props.userCanEdit ?
                <Fragment>
                  <FontAwesomeIcon icon={faPlus}
                    className="MultiBtn Plus"
                    onClick={this.addNode} />
                  <FontAwesomeIcon icon={faTrash}
                    className="MultiBtn Minus"
                    onClick={this.delNode} />
                </Fragment>
                : null
            }
          </div>
        </div>
        <div className="SectionBody">
          {this.props.fieldChildren.map((child, index) => {
            // Check for group of fields and multigroup +/-
            let isMultiWrapper = (
              this.props.isMultiGroup &&
              this.props.isArrayOfGroupFields
            );

            // Render childs
            return (
              <div
                key={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex + "__CHILD_" + index}
                className={(isMultiWrapper ? "SectionMultiEffect" : "") + (isMultiWrapper && isOdd ? " SectionMultiEffect-Odd" : "")}>
                {child}
              </div>
            );
          })}
        </div>
      </div>
    )
  };
}

class TemplateField extends Component {
  constructor(props) {
    super(props);
    // fieldData: Object {} => Struttura
    // fieldMask: Object {} => Visibilità
    // fieldTab: String => Header di appartenenza
    // fieldIndex: Number => Index array
    // fieldRealPath: String => Path calcolato con index
    // formData: Object {} => Data
    // updateData: Function() => Salvataggio del parziale nel JSON root
    // pfcFetchedComments: Object {} => Data
    // userCanEdit: Boolean
    // userCanEditFC: Boolean
    // card_view_status: String
    // staging_state: String

    this.state = {
      pfcFetchedComments: props.pfcFetchedComments,
      pfcCommentsInEdit: props.pfcCommentsInEdit
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.pfcFetchedComments) !== JSON.stringify(state.pfcFetchedComments) ||
      JSON.stringify(props.pfcCommentsInEdit) !== JSON.stringify(state.pfcCommentsInEdit)) {
      return {
        pfcFetchedComments: props.pfcFetchedComments,
        pfcCommentsInEdit: props.pfcCommentsInEdit
      };
    }
    return null;
  }

  render() {
    let inputTypeToRender = null;
    let isOdd = this.props.fieldData["field-tree-level"] % 2 !== 0;

    if (this.props.fieldData["field-input-descriptor"] &&
      this.props.fieldData["field-input-descriptor"]["input-type"]) {
      switch (this.props.fieldData["field-input-descriptor"]["input-type"]) {
        case "input-text":
          inputTypeToRender = <TemplateInputTextField
            fieldData={this.props.fieldData}
            fieldMask={this.props.fieldMask}
            fieldTab={this.props.fieldTab}
            fieldIndex={this.props.fieldIndex}
            formData={this.props.formData}
            updateData={this.props.updateData}
            updateReport={this.props.updateReport}
            fieldRealPath={this.props.fieldRealPath}
            userCanEdit={this.props.userCanEdit}
          />
          break;

        case "textarea":
          inputTypeToRender = <TemplateTextAreaField
            fieldData={this.props.fieldData}
            fieldMask={this.props.fieldMask}
            fieldTab={this.props.fieldTab}
            fieldIndex={this.props.fieldIndex}
            formData={this.props.formData}
            updateData={this.props.updateData}
            updateReport={this.props.updateReport}
            fieldRealPath={this.props.fieldRealPath}
            userCanEdit={this.props.userCanEdit}
          />
          break;

        case "input-file":
          inputTypeToRender = <TemplateInputFileField
            fieldData={this.props.fieldData}
            fieldMask={this.props.fieldMask}
            fieldTab={this.props.fieldTab}
            fieldIndex={this.props.fieldIndex}
            formData={this.props.formData}
            updateData={this.props.updateData}
            updateReport={this.props.updateReport}
            fieldRealPath={this.props.fieldRealPath}
            userCanEdit={this.props.userCanEdit}
          />
          break;

        case "input-map":
          inputTypeToRender = <TemplateInputMapField
            fieldData={this.props.fieldData}
            fieldMask={this.props.fieldMask}
            fieldTab={this.props.fieldTab}
            fieldIndex={this.props.fieldIndex}
            formData={this.props.formData}
            updateData={this.props.updateData}
            updateReport={this.props.updateReport}
            fieldRealPath={this.props.fieldRealPath}
            userCanEdit={this.props.userCanEdit}
          />
          break;

        default: break;
      }
    }

    return (
      <div
        name={"INPUT__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
        key={"INPUT__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
        className={"TemplateField FieldRow" + (isOdd ? " FieldOdd FieldRowOdd" : "")}>
        {inputTypeToRender}
        {
          this.props.card_view_status === "S" && this.props.staging_state !== "FW_CDER_0001" ? <PFCHandler
            srvid={this.props.fieldData["field-edusn-srvid"]}
            fieldIndex={this.props.fieldIndex}
            name={"PFC__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
            fieldComments={this.state.pfcFetchedComments ?
              this.state.pfcFetchedComments["PFC__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex]
              : null
            }
            draftComments={this.state.pfcCommentsInEdit ?
              this.state.pfcCommentsInEdit["PFC__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex]
              : null
            }
            sendPFCComment={this.props.sendPFCComment}
            userCanEdit={this.props.userCanEditFC}
          /> : null
        }
      </div>
    )
  };
}

//----------------------------------------------------------------------| TEMPLATE FIELDS: INPUT TYPE

class TemplateInputTextField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldTab: String => Header di appartenenza
        // formData: Object {} => Data
        // fieldRealPath: String => Path calcolato con index
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        // Get data from props
        let currentVal = props.formData;

        // Check if property with lang
        if (currentVal             &&
            currentVal["value"]    &&
            currentVal["language"] &&
            currentVal["profile"]) {
            // Get correct value
            currentVal = currentVal["value"];

            // Save new value into JSON
            props.updateData(
                props.fieldRealPath,
                currentVal
            );
        }

        this.state = {
            currentValue: currentVal ? currentVal : "",
        };
    }

    componentDidMount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                : true
            )
        );
    }

    componentWillUnmount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                : true
            ),
            false
        );
    }

    handleChange = (event) => {
        this.setState({
            currentValue: event.target.value
        },
        () => {
            this.props.updateData(
                this.props.fieldRealPath,
                this.state.currentValue
            );

            this.props.updateReport(
                "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
                {
                    ...this.props.fieldData,
                    "field-real-path": this.props.fieldRealPath,
                    "field-tab-owner": this.props.fieldTab
                },
                (
                    this.props.fieldMask["field-is-required"] ?
                        (this.state.currentValue !== "")
                    : true
                )
            );
        });
    }

    render() {
        let isRequired = this.props.fieldMask["field-is-required"];
        let isErrored  = this.state.currentValue === "" && isRequired;

        return (
            <div className={"FieldInputBaseRender" + (isRequired ? " RequiredField" : "") + (isErrored ? " RequiredErrored" : "")}>
                <div>
                <input type="text"
                    id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                    value={this.state.currentValue}
                    onChange={this.handleChange}
                    disabled={!this.props.userCanEdit}
                />
                </div>
            </div>
        )
    };
}

class TemplateTextAreaField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldTab: String => Header di appartenenza
        // formData: Object {} => Data
        // fieldRealPath: String => Path calcolato con index
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        // Get data from props
        let currentVal = props.formData;

        // Check if property with lang
        if (currentVal             &&
            currentVal["value"]    &&
            currentVal["language"] &&
            currentVal["profile"]) {
            // Get correct value
            currentVal = currentVal["value"];

            // Save new value into JSON
            props.updateData(
                props.fieldRealPath,
                currentVal
            );
        }

        this.state = {
            currentValue: currentVal ? currentVal : "",
        };
    }

    componentDidMount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                : true
            )
        );
    }

    componentWillUnmount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                : true
            ),
            false
        );
    }

    handleChange = (event) => {
        this.setState({
            currentValue: event.target.value
        },
        () => {
            this.props.updateData(
                this.props.fieldRealPath,
                this.state.currentValue
            );

            this.props.updateReport(
                "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
                {
                    ...this.props.fieldData,
                    "field-real-path": this.props.fieldRealPath,
                    "field-tab-owner": this.props.fieldTab
                },
                (
                    this.props.fieldMask["field-is-required"] ?
                        (this.state.currentValue !== "")
                    : true
                )
            );
        });
    }

    render() {
        let isRequired = this.props.fieldMask["field-is-required"];
        let isErrored  = this.state.currentValue === "" && isRequired;

        return (
            <div className={"FieldInputBaseRender" + (isRequired ? " RequiredField" : "") + (isErrored ? " RequiredErrored" : "")}>
                <div>
                <textarea
                    id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                    onChange={this.handleChange}
                    disabled={!this.props.userCanEdit}
                    value={this.state.currentValue}>
                </textarea>
                </div>
            </div>
        )
    };
}

class TemplateInputFileField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldTab: String => Header di appartenenza
        // formData: Object {} => Data
        // fieldRealPath: String => Path calcolato con index
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean

        // Get data from props
        let currentVal = props.formData;

        // Check if property with lang
        if (currentVal             &&
            currentVal["value"]    &&
            currentVal["language"] &&
            currentVal["profile"]) {
            // Get correct value
            currentVal = currentVal["value"];

            // Save new value into JSON
            props.updateData(
                props.fieldRealPath,
                currentVal
            );
        }

        this.state = {
            currentValue: currentVal ? currentVal : "",
            modalUploadFile: false,
            updating: false
        };
    }

    componentDidMount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                : true
            )
        );
    }

    componentWillUnmount() {
        this.props.updateReport(
            "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
            {
                ...this.props.fieldData,
                "field-real-path": this.props.fieldRealPath,
                "field-tab-owner": this.props.fieldTab
            },
            (
                this.props.fieldMask["field-is-required"] ?
                    (this.state.currentValue !== "")
                : true
            ),
            false
        );
    }

    handleChange = (event) => {
        this.setState({
            currentValue: event.target.value,
            updating: true
        },
        () => {
            this.props.updateData(
                this.props.fieldRealPath,
                this.state.currentValue
            );

            this.props.updateReport(
                "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex,
                {
                    ...this.props.fieldData,
                    "field-real-path": this.props.fieldRealPath,
                    "field-tab-owner": this.props.fieldTab
                },
                (
                    this.props.fieldMask["field-is-required"] ?
                        (this.state.currentValue !== "")
                    : true
                )
            );
        });
    }

    toggleUploadFile = () => {
        this.setState({
            modalUploadFile: !this.state.modalUploadFile
        });
    }

    setImageValueURL = (data) => {
        // Get ID of field
        let idField = "FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex;

        // Get descriptor and prototype from DOM/OBJECT of value attribute
        let nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            "value"
        ).set;

        // Simulate React.setValue call from prototype
        nativeInputValueSetter.call($("#" + idField)[0], data.fileUrl);

        // Trigger prototype onChange event
        $("#" + idField)[0].dispatchEvent(
            new Event('input', { bubbles: true })
        );

        // Close modal
        this.setState({
            modalUploadFile: false
        });
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaImgDefault;
    }

    isEmpty(data) {
        if (data &&
            data !== "" &&
            data !== 0) {
            return false;
        }
        return true;
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    render() {
        let isRequired = this.props.fieldMask["field-is-required"];
        let isErrored  = this.state.currentValue === "" && isRequired;

        return (
            <div className={"FieldInputBaseRender" + (isRequired ? " RequiredField" : "") + (isErrored ? " RequiredErrored" : "")}>
                <div style={{ marginBottom: "2px" }}>
                    <input type="text"
                        id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                        value={this.state.currentValue}
                        onChange={this.handleChange}
                        disabled={!this.props.userCanEdit}
                    />
                </div>
                {
                    !this.isEmpty(this.state.currentValue) ?
                    <div className="ContainerImgPreview">
                        <img src={
                            this.isValidURL(
                                this.state.currentValue
                            ) ? this.state.currentValue
                            : EdubbaImgDefault
                        }
                        onError={this.setDefaultImgSrc}
                        alt="" />
                    </div>
                    : null
                }
                <div>
                    <input type="button" value="Seleziona da Media Personali o Nuovo Upload..." onClick={this.toggleUploadFile} disabled={!this.props.userCanEdit} />
                </div>
                {/* V MediaModal V */}
                <Modal className='edubba-modal select-template-modal upload-file'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='lg'
                    isOpen={this.state.modalUploadFile}
                    toggle={this.toggleUploadFile}
                    backdrop='static'>
                    <ModalHeader toggle={this.toggleUploadFile}>
                        <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                            <div className='widget-content-left mr-3'>
                                <FontAwesomeIcon icon={faUpload} className='icon' />
                            </div>
                            <div className='widget-content-left mr-3 text-center w-100'>
                                Upload File
                            </div>
                        </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                        <Row>
                            <Col lg='12' md='12' sm='12' className='inner-modal'>
                                <ImageUploadManager selectCallback={this.setImageValueURL} />
                            </Col>
                        </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        )
    };
}

class TemplateInputMapField extends Component {
  constructor(props) {
    super(props);
    // fieldData: Object {} => Struttura
    // fieldMask: Object {} => Visibilità
    // fieldTab: String => Header di appartenenza
    // formData: Object {} => Data
    // fieldRealPath: String => Path calcolato con index
    // updateData: Function() => Salvataggio del parziale nel JSON root
    // userCanEdit: Boolean

    this.state = {
      modalMap: false,
      latitudeValue: 0,
      longitudeValue: 0
    };
  }

  componentDidMount() {
    // Get template reference for LON/LAT
    let refData = this.props.fieldData["field-input-descriptor"] ?
      this.props.fieldData["field-input-descriptor"]["data-payload"] ?
        this.props.fieldData["field-input-descriptor"]["data-payload"]
        : {}
      : {};

    // Prepare references
    let idLon = "FIELD_ID__" + (refData["ref-longitude"] ? refData["ref-longitude"] : "NULL__UNKNOWN") + "__0";
    let idLat = "FIELD_ID__" + (refData["ref-latitude"] ? refData["ref-latitude"] : "NULL__UNKNOWN") + "__0";

    // Set to readonly and get values or return null
    let valLon = ($("#" + idLon).length > 0) ? $("#" + idLon).attr('readonly', true).val() : null;
    let valLat = ($("#" + idLat).length > 0) ? $("#" + idLat).attr('readonly', true).val() : null;

    // Set new values
    this.setState({
      longitudeValue: (valLon && valLon.trim() !== "" && $.isNumeric(valLon.trim())) ? valLon : 0,
      latitudeValue: (valLat && valLat.trim() !== "" && $.isNumeric(valLat.trim())) ? valLat : 0
    });
  }

  setMapCoords = (data) => {
    // Check for data
    if (data) {
      // Set new values
      this.setState({
        latitudeValue: data.lat,
        longitudeValue: data.lon,
        modalMap: false
      });

      // Get template reference for LON/LAT
      let refData = this.props.fieldData["field-input-descriptor"] ?
        this.props.fieldData["field-input-descriptor"]["data-payload"] ?
          this.props.fieldData["field-input-descriptor"]["data-payload"]
          : {}
        : {};

      // Prepare references
      let idLon = "FIELD_ID__" + (refData["ref-longitude"] ? refData["ref-longitude"] : "NULL__UNKNOWN") + "__0";
      let idLat = "FIELD_ID__" + (refData["ref-latitude"] ? refData["ref-latitude"] : "NULL__UNKNOWN") + "__0";

      // Get descriptor and prototype from DOM/OBJECT of value attribute
      let nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      ).set;

      // Check if elements exists
      if ($("#" + idLon).length > 0 &&
        $("#" + idLat).length > 0) {
        // LON: Simulate React.setValue call from prototype
        nativeInputValueSetter.call($("#" + idLon)[0], data.lon);

        // LON: Trigger prototype onChange event
        $("#" + idLon)[0].dispatchEvent(
          new Event('input', { bubbles: true })
        );

        // LAT: Simulate React.setValue call from prototype
        nativeInputValueSetter.call($("#" + idLat)[0], data.lat);

        // LAT: Trigger prototype onChange event
        $("#" + idLat)[0].dispatchEvent(
          new Event('input', { bubbles: true })
        );
      }
    }
  }

  toggleMapModal = () => {
    this.setState({
      modalMap: !this.state.modalMap,
    },
      () => {
        this.props.updateData(
          this.props.fieldRealPath,
          this.state.currentValue
        );
      });
  }

  render() {
    return (
      <div className="FieldInputBaseRender">
        <div>
          <input type="button"
            id={"FIELD_ID__" + this.props.fieldData["field-edusn-srvid"]}
            value="Seleziona Coordinate da Mappa..."
            onClick={this.toggleMapModal}
            disabled={!this.props.userCanEdit}
          />
        </div>
        {/* ^ MapModal ^ */}
        <Modal className='edubba-modal select-template-modal upload-file'
          centered
          aria-labelledby="contained-modal-title-vcenter"
          size='lg'
          isOpen={this.state.modalMap}
          toggle={this.toggleMapModal}
          backdrop='static'
        >
          <ModalHeader toggle={this.toggleMapModal}>
            <div className='widget-content p-0'>
              <div className='widget-content-wrapper'>
                <div className='widget-content-left mr-3'>
                  <FontAwesomeIcon icon={faMap} className='icon' />
                </div>
                <div className='widget-content-left mr-3 text-center w-100'>
                  Seleziona sulla Mappa
                            </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <Container className='help-text'>
              <Row>
                <Col lg='12' md='12' sm='12' className='inner-modal'>
                  <MapSelectorManager
                    selectCallback={this.setMapCoords}
                    registeredValue={[
                      this.state.latitudeValue,
                      this.state.longitudeValue
                    ]}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    )
  };
}

class PFCHandler extends Component {
  constructor(props) {
    super(props);
    // srvid: String
    // fieldIndex: Number
    // name: String
    // fieldComments: Object
    // userCanEdit: Boolean

    this.state = {
      pfcComment: "",
      fieldComments: props.fieldComments,
      draftComments: props.draftComments
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.fieldComments) !== JSON.stringify(state.fieldComments) ||
      JSON.stringify(props.draftComments) !== JSON.stringify(state.draftComments)) {
      return {
        fieldComments: props.fieldComments,
        draftComments: props.draftComments
      };
    }
    return null;
  }

  handlePFCChange = (event) => {
    this.setState({ pfcComment: event.target.value });
  }

  sendComment = () => {
    this.props.sendPFCComment(
      this.props.name,
      "INPUT_MSG__" + this.props.name,
      () => {
        this.setState({
          pfcComment: ""
        });

        $("#SCROLL_MSGS__" + this.props.name).animate({
          scrollTop: $("#SCROLL_MSGS__" + this.props.name).height()
        }, 500);
      }
    );
  }

  render() {
    let mergedComments = [];

    if (Array.isArray(this.state.fieldComments)) {
      this.state.fieldComments.map((m, index) => {
        mergedComments.push(<Message
          key={`message-archive-${index}`}
          sentByMe={holdDataService.getLoggedUserUuid() === m.comment_owner.user_uuid}
          comment_message={m.comment_message}
          comment_owner={m.comment_owner}
          comment_date_create={m.comment_date_create}
          messageIndex={index}
        />);
        return true;
      });
    }

    if (Array.isArray(this.state.draftComments)) {
      this.state.draftComments.map((m, index) => {
        mergedComments.push(<Message
          key={`message-create-${index}`}
          sentByMe={true}
          comment_message={m.comment_message}
          comment_owner={holdDataService.getUserData()}
          comment_date_create={m.comment_date_create}
          messageIndex={index}
        />);
        return true;
      })
    }

    return (
      <div
        className="FieldCommentBaseRender"
        key={this.props.name}
        name={this.props.name}>
        <Container>
          <Row id={"SCROLL_MSGS__" + this.props.name} className="FieldCommentScrollCtx">
            {
              (mergedComments.length > 0) ?
                mergedComments.map((htmlNode) => {
                  return htmlNode;
                })
                : (
                  <div className="FieldCommentNoMsg">
                    Nessun commento disponibile per il campo corrente...<br />
                    Usa il campo di testo seguente per iniziare una conversazione!
                </div>
                )
            }
          </Row>
          {this.props.userCanEdit ? <Row>
            <div className="FieldCommentSendInput">
              <input
                id={"INPUT_MSG__" + this.props.name}
                type='text'
                placeholder='Scrivi un messaggio ...'
                value={this.state.pfcComment}
                onChange={this.handlePFCChange}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.sendComment();
                  }
                }} />
              <FontAwesomeIcon className='FieldCommentSendBtn' icon={faPaperPlane} onClick={() => this.sendComment()} />
            </div>
          </Row> : null}
        </Container>
      </div>
    )
  }
}

class Message extends Component {
  constructor(props) {
    super(props);
    // roomUUID: String

    this.state = {
      repliedMessage: null
    }
  }

  getUserAttribute(user, attribute) {
    return user.user_profile[attribute];
  }

  render() {
    let text = this.props.comment_message;
    let messageDate = this.props.comment_date_create;
    let messageTime = moment(messageDate).format('LT');
    let messagePastTime = moment(messageDate).fromNow();
    let comment_owner = this.props.comment_owner;
    let a = moment(new Date().getTime());
    let b = moment(messageDate);

    //Se ci sono più di due giorni di distanza allora formatto in base alla data
    if (a.diff(b, 'days') >= 2) {
      messagePastTime = moment(messageDate).format('DD/MM/YY')
    }

    return (
      <div className="FieldCommentMessageBody">
        <div className={"FieldCommentMessageWrapper" + (this.props.sentByMe ? " MessageSentByMe" : "")}>
          <div className='MessageSendText'>
            {ReactHtmlParser(text)}
          </div>
          <div className="MessageInfoBox">
            <div className="MessageSendTime">
              <i>{messageTime} | {messagePastTime}</i>
            </div>
            <div className='MessageSendOwner'>
              <b>{`${this.getUserAttribute(comment_owner, "firstName")} ${this.getUserAttribute(comment_owner, "lastName")}`}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*class ModalInputFileField extends Component {
  constructor(props) {
    super(props);
    // formData: String
    // fieldRealPath: String => Path calcolato con index
    // updateData: Function() => Salvataggio del parziale nel JSON root
    // userCanEdit: Boolean

    this.state = {
      currentValue: props.formData ? props.formData : "",
      modalUploadFile: false
    };
  }

  handleChange = (event) => {
    this.setState({
      currentValue: event.target.value
    },
      () => {
        this.props.updateData(
          this.props.fieldRealPath,
          this.state.currentValue
        );
      });
  }

  toggleUploadFile = () => {
    this.setState({
      modalUploadFile: !this.state.modalUploadFile
    });
  }

  setImageValueURL = (data) => {
    this.setState({
      currentValue: data.fileUrl,
      modalUploadFile: false
    },
      () => {
        this.props.updateData(
          this.props.fieldRealPath,
          this.state.currentValue
        );
      });
  }

  render() {
    return (
      <div className="FieldInputBaseRender">
        <div style={{ marginBottom: "2px" }}>
          <input type="text"
            id={"MODAL_FIELD__" + this.props.fieldRealPath}
            value={this.state.currentValue}
            onChange={this.handleChange}
            disabled={!this.props.userCanEdit}
          />
        </div>
        <div>
          <input type="button" value="Seleziona da Media Personali o Nuovo Upload..." onClick={this.toggleUploadFile} disabled={!this.props.userCanEdit} />
        </div>
        <Modal className='edubba-modal select-template-modal upload-file'
          centered
          aria-labelledby="contained-modal-title-vcenter"
          size='lg'
          isOpen={this.state.modalUploadFile}
          toggle={this.toggleUploadFile}
          backdrop='static'>
          <ModalHeader toggle={this.toggleUploadFile}>
            <div className='widget-content p-0'>
              <div className='widget-content-wrapper'>
                <div className='widget-content-left mr-3'>
                  <FontAwesomeIcon icon={faUpload} className='icon' />
                </div>
                <div className='widget-content-left mr-3 text-center w-100'>
                  Upload File
                            </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <Container className='help-text'>
              <Row>
                <Col lg='12' md='12' sm='12' className='inner-modal'>
                  <ImageUploadManager selectCallback={this.setImageValueURL} />
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    )
  };
}*/

//----------------------------------------------------------------------| EXPORT

export default withRouter(CardDetail);
