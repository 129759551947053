import React, { Fragment } from 'react';
import cx from 'classnames';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import globalVars from './../../globalVars.json';
import holdDataService from './../../services/holdDataService/holdDataService';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';

// import SearchBox from './Components/SearchBox';
// import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox';
import HeaderRightDrawer from "./Components/HeaderRightDrawer";

import HeaderDots from "./Components/HeaderDots";
import ReactSVG from 'react-svg';
import edubbaLogo from './../../assets/utils/images/edubba_squared_logo.svg';

class Header extends React.Component {
    constructor(props) {
        super(props);
        // sectionTitle: Function
        // userName: String
        // registerLogout: Function
        // notifications: Array
        // checkNotificationSeen: Function

        this.state = {
            loading: false,
            groups: 0,
            tasks: 0,
            cards: 0,
            socialRooms: []
        }
    }

    componentWillMount() {
        document.addEventListener("refresh-social-walls", this.getSocialRooms);
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            this.getCounters(() => this.getSocialRooms());
        });
    }
    componentWillUnmunt() {
        document.removeEventListener("refresh-social-walls", this.getSocialRooms);
    }

    getCounters = (callback) => {
        if (holdDataService.loggedUserIsAdmin() ||
            holdDataService.loggedUserIsContentAdmin() ||
            holdDataService.loggedUserIsOnlySupervisor() ||
            holdDataService.getLoggedUserUuid() === null) {
            return;
        }

        let query = '/counters';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let groups = 0;
                let tasks = 0;
                let cards = 0;
                if (res.data.rowCount > 0) {
                    let data = res.data.rows[0];
                    groups = data.groups;
                    tasks = data.tasks;
                    cards = data.cards;
                }
                this.setState({
                    groups: groups,
                    tasks: tasks,
                    cards: cards,
                }, () => callback());
            }, err => {
            });
    }

    getSocialRooms = () => {
        if (holdDataService.loggedUserIsAdmin() ||
            holdDataService.loggedUserIsContentAdmin() ||
            holdDataService.loggedUserIsOnlyCertifier() ||
            holdDataService.loggedUserIsOnlySupervisor() ||
            holdDataService.getLoggedUserUuid() === null) {
            return;
        }

        let query = '/social-rooms';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let socialRooms = res.data ? res.data.rows : [];
                this.setState({
                    socialRooms: socialRooms
                }, () => this.setState({ loading: false }));
            }, err => {
            });
    }

    render() {
        let {
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;

        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header", "bg-edubba", { 'header-shadow': enableHeaderShadow })}
                    transitionName="HeaderAnimation"
                    transitionAppear={false}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <HeaderLogo />
                    <div className={cx(
                        "app-header__content",
                        { 'header-mobile-open': enableMobileMenuSmall },
                    )}>
                        <div className="app-header-left">
                            <div className="logo-src">
                                <ReactSVG className='svg-component-class' src={edubbaLogo} />
                            </div>
                        </div>
                        <div className="app-header-right">
                            <UserBox
                                userName={this.props.userName}
                                registerLogout={this.props.registerLogout}
                                groups={this.state.groups}
                                cards={this.state.cards}
                            />
                            {
                                holdDataService.loggedUserIsAdmin() ||
                                holdDataService.loggedUserIsContentAdmin() ||
                                holdDataService.loggedUserIsOnlyCertifier() ||
                                holdDataService.loggedUserIsOnlySupervisor() ? null : <HeaderDots
                                teams={this.props.teams}
                                notifications={this.props.notifications}
                                getNotifications={this.props.getNotifications}
                                checkNotificationSeen={this.props.checkNotificationSeen}
                            />}
                            {
                                holdDataService.loggedUserIsAdmin() ||
                                holdDataService.loggedUserIsContentAdmin() ||
                                holdDataService.loggedUserIsOnlyCertifier() ||
                                holdDataService.loggedUserIsOnlySupervisor() ? null :
                                <HeaderRightDrawer sectionTitle={this.props.sectionTitle} socialRooms={this.state.socialRooms} />}
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
