import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Collapse,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import {
    faSearch, faFilter, faPlus, faTrash
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import globalVars from './../../globalVars.json';

import './ExtMaterials.scss';

class ExtMaterialsListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maskLoading: false,
            currentsExtMaterials: [],
            currentStats: [],
            isFilterOpen: false,
            today: new Date().getTime(),

            //----------------------------------------------------------------|

            filterContentOwner: null,
            filterEvent: null,
            filterProject: null,
            filterLocation: null,
            filterDateStart: null,
            filterDateEnd: null,

            //----------------------------------------------------------------|

            currentQueryPage: 1,
            currentQueryLimit: 25,
            currentQueryCount: 25,

            //----------------------------------------------------------------|

            tableOptions: {
                "material_content_info": "Tema",
                "material_project":      "Progetto",
                "material_event":        "Evento",
                "material_location":     "Luogo Evento",
                "material_date":         "Data Evento",
                "material_update_time":  "Ultima Modifica",
            },

            //---------------------------------------------------------------------| DELETE

            modalDeleteMaterial: false,
            selectedMaterialToDelete: null,

            //---------------------------------------------------------------------| TOAST

            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
        };
    }

    componentDidMount() {
        let setTitle = 'Dashboard - Materiali per Servizi di Terze-Parti';
        this.props.setSectionTitle(setTitle);

        this.loadMaterials();
    }

    loadMaterials() {
        this.setState({ maskLoading: true });
        let query = "?page=" + this.state.currentQueryPage + "&limit=" + this.state.currentQueryLimit;

        // Inject filter
        if (this.state.filterIsApplied &&
            this.state.filterContentOwner) {
            query += "&filter_content_owner=" + this.state.filterContentOwner;
        }
        if (this.state.filterIsApplied &&
            this.state.filterEvent) {
            query += "&filter_event=" + this.state.filterEvent;
        }
        if (this.state.filterIsApplied &&
            this.state.filterProject) {
            query += "&filter_project=" + this.state.filterProject;
        }
        if (this.state.filterIsApplied &&
            this.state.filterLocation) {
            query += "&filter_location=" + this.state.filterLocation;
        }
        if (this.state.filterIsApplied &&
            this.state.filterDateStart) {
            query += "&filter_date_start=" + this.state.filterDateStart;
        }
        if (this.state.filterIsApplied &&
            this.state.filterDateEnd) {
            query += "&filter_date_end=" + this.state.filterDateEnd;
        }

        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/third-parties/materials" + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentQueryCount: res.data.rowCount,
                    currentStats: res.data.rows,
                    maskLoading: false
                });
            }
            else this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        });
    }

    delExtMaterial = (evt) => {
        evt.preventDefault();
        this.setState({ maskLoading: true });
        const materialUUID = this.state.selectedMaterialToDelete;

        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/third-parties/materials/" + materialUUID, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            this.toggleDeleteMaterialUUID(null);
            this.clearFilter();
            if (res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione del Materiale",
                    modalConfirmToastMessage: "Materiale per servizi esterni rimosso con successo."
                });
            }
            else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Errore nella Rimozione!",
                    modalConfirmToastMessage: "Impossibile completare la rimozione del materiale selezionato."
                });
            }
        },
        err => {
            this.toggleDeleteProjectUUID(null);
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Errore nella Rimozione!",
                modalConfirmToastMessage: "Impossibile completare la rimozione del materiale selezionato. Errore: " + err.message
            });
        });
    }

    toggleDeleteMaterialUUID = (materialUUID) => {
        if (!this.state.modalDeleteMaterial) {
            this.setState({
                selectedMaterialToDelete: materialUUID,
                modalDeleteMaterial: !this.state.modalDeleteMaterial
            });
        }
        else {
            this.setState({
                selectedMaterialToDelete: null,
                modalDeleteMaterial: !this.state.modalDeleteMaterial
            });
        }
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    prepareHeaders() {
        let rawData    = [<th key={"tr-head-th-" + 0}>#</th>];
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < tableOpts.length; i++) {
            let tmpHeaderLabel = rawOptions[tableOpts[i]];
            tmpHeaderLabel = (
                typeof rawOptions[tableOpts[i]] === "function" ?
                rawOptions[tableOpts[i]]() :
                rawOptions[tableOpts[i]]
            );

            rawData.push(
                <th key={"tr-head-th-" + (i + 1)}>{tmpHeaderLabel}</th>
            );
        }

        rawData.push(
            <th key={"tr-head-th-" + (tableOpts.length + 1)}>Azioni</th>
        );

        return (<tr key={"tr-head-" + 0}>{rawData}</tr>);
    }

    prepareRows() {
        let rawData    = [];
        let rawRecords = this.state.currentStats;
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < rawRecords.length; i++) {
            let data   = rawRecords[i];
            let tmpRow = [<td key={"tr-body-" + i + "-td-" + 0}>{i + 1}</td>];

            if (!(data["material_uuid"])) {
                continue;
            }

            for (let n = 0; n < tableOpts.length; n++) {
                let tmpNodeValue = data[tableOpts[n]];

                if (tmpNodeValue && (
                    tableOpts[n] === "material_date" ||
                    tableOpts[n] === "material_update_time")) {
                    tmpNodeValue = moment(
                        parseInt(tmpNodeValue, 10)
                    ).format('DD/MM/YY H:m:s')
                }

                tmpNodeValue = tmpNodeValue ? tmpNodeValue : "- - -";

                tmpRow.push(
                    <td key={"tr-body-" + i + "-td-" + (n + 1)}>{tmpNodeValue}</td>
                );
            }

            tmpRow.push(
                <td key={"tr-body-" + i + "-td-" + (tableOpts.length + 1)} style={{minWidth: "90px"}}>
                    <div className={'bottom-icons ' + (!this.props.userCanOperate ? "single-bottom-icons" : "")}>
                        <Link to={"/third-parties/materials/" + data["material_uuid"]}>
                            <div
                                data-tip data-for={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={faSearch}
                                />
                                <ReactTooltip
                                    id={"TIPS_FOR_V_" + i + "-td-" + (tableOpts.length + 1)}
                                    wrapper="span"
                                    place="left"
                                    effect="solid"
                                    className="tooltip-user">
                                    Visualizza/Modifica il Materiale
                                </ReactTooltip>
                            </div>
                        </Link>
                        {
                            this.props.userCanOperate ?
                            <div
                                className="red-action-btn"
                                onClick={() => this.toggleDeleteMaterialUUID(data["material_uuid"])}
                                data-tip data-for={"TIPS_FOR_D_" + i + "-td-" + (tableOpts.length + 1)}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={faTrash}
                                />
                                <ReactTooltip
                                    id={"TIPS_FOR_D_" + i + "-td-" + (tableOpts.length + 1)}
                                    wrapper="span"
                                    place="left"
                                    effect="solid"
                                    className="tooltip-user">
                                    Rimuovi il Materiale
                                </ReactTooltip>
                            </div>
                            : null
                        }
                    </div>
                </td>
            );
            rawData.push(<tr key={"tr-body-" + i}>{tmpRow}</tr>);
        }

        return rawData;
    }

    preparePaginationItems() {
        // Prepare data
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);
        let items      = [];

        // Prepare limits
        let firstMin   = 1;
        let lastMax    = countPage;

        // Check range
        if (countPage > 8) {
            // Specifies range of items in the middle
            firstMin = Math.max(active - 2, 1);
            lastMax  = Math.min(active + 2, countPage);

            // Normalize
            if (active === 1) {
                lastMax += 2;
            }
            // Normalize
            if (active === 2) {
                lastMax += 1;
            }
            else if (active === (countPage - 1)) {
                firstMin-=1;
            }
            else if (active === countPage) {
                firstMin-=2;
            }
        }

        // Push forst separator
        if (firstMin > 1) {
            items.push(
                <PaginationItem key={"firstMinSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Loop on item
        for (let number = firstMin; number <= lastMax; number++) {
            // Save item
            items.push(
                <PaginationItem key={number} active={number === active}>
                    <PaginationLink onClick={() => this.changePage(number)}>
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Push last separator
        if (lastMax < countPage) {
            items.push(
                <PaginationItem key={"lastMaxSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Send back
        return items;
    }

    changePage(toPage) {
        this.setState({
            currentStats: [],
            currentQueryPage: toPage
        },
        () => {
            this.loadMaterials();
        });
    }

    changePageByStep(toPage) {
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);

        switch (toPage) {
            case "first":
                active = 1;
                break;

            case "back":
                active = ((active - 1) >= 1) ? (active - 1) : 1;
                break;

            case "next":
                active = ((active + 1) <= countPage) ? (active + 1) : active;
                break;

            case "last":
                active = countPage;
                break;

            default: break;
        }

        this.setState({
            currentStats: [],
            currentQueryPage: active
        },
        () => {
            this.loadMaterials();
        });
    }

    toggleFilter() {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen
        });
    }

    handleChangeContentOwner = (ev) => {
        this.setState({
            filterContentOwner: ev.target && ev.target.value ? ev.target.value : null,
            currentQueryPage: 1
        });
    }

    handleChangeProject = (ev) => {
        this.setState({
            filterProject: ev.target && ev.target.value ? ev.target.value : null,
            currentQueryPage: 1
        });
    }

    handleChangeEvent = (ev) => {
        this.setState({
            filterEvent: ev.target && ev.target.value ? ev.target.value : null,
            currentQueryPage: 1
        });
    }

    handleChangeLocation = (ev) => {
        this.setState({
            filterLocation: ev.target && ev.target.value ? ev.target.value : null,
            currentQueryPage: 1
        });
    }

    handleChangeStart = (pfilterDateStart) => {
        if (pfilterDateStart) {
            this.setState({
                filterDateStart: pfilterDateStart.getTime(),
                currentQueryPage: 1
            });
        }
    }

    handleChangeEnd = (pfilterDateEnd) => {
        if (pfilterDateEnd) {
            this.setState({
                filterDateEnd: pfilterDateEnd.getTime(),
                currentQueryPage: 1
            });
        }
    }

    applyFilter = () => {
        this.setState({
            currentQueryPage: 1,
            filterIsApplied: true
        },
        () => {
            this.loadMaterials();
        });
    }

    clearFilter = () => {
        this.setState({
            isFilterOpen: false,
            currentQueryPage: 1,
            filterContentOwner: null,
            filterEvent: null,
            filterProject: null,
            filterLocation: null,
            filterDateStart: null,
            filterDateEnd: null,
            filterIsApplied: false
        },
        () => {
            this.loadMaterials();
        });
    }

    render() {
        return (
            <Container className='ExtMaterials' active-page={this.state.currentQueryPage}>
                {
                    this.state.maskLoading ?
                        <EdubbaLoader />
                    : <Fragment>
                        <Row key="head-stat-1">
                            <Col lg='12' md='12' sm='12' className="HeadStat">
                                Seleziona Materiale:
                                {
                                    this.props.userCanOperate ?
                                    <Link to={"/third-parties/materials/create"}>
                                        <Button
                                            data-tip data-for={"TIPS_FOR_BTN_ACTION_ADD"}
                                            color="success"
                                            style={{
                                                position: "relative",
                                                float: "right",
                                                marginTop: '12px',
                                                marginLeft: '10px'
                                            }}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <ReactTooltip
                                                id={"TIPS_FOR_BTN_ACTION_ADD"}
                                                wrapper="span"
                                                place="left"
                                                effect="solid"
                                                className="tooltip-user">
                                                Crea un nuovo materiale per servizi di Terze-Parti
                                            </ReactTooltip>
                                        </Button>
                                    </Link>
                                    : null
                                }
                                <Button
                                    data-tip data-for={"TIPS_FOR_BTN_ACTION_FILTER"}
                                    color="secondary"
                                    onClick={() => this.toggleFilter()}
                                    style={{
                                        position: "relative",
                                        float: "right",
                                        marginTop: '12px'
                                    }}>
                                    <FontAwesomeIcon icon={faFilter} />
                                    <ReactTooltip
                                        id={"TIPS_FOR_BTN_ACTION_FILTER"}
                                        wrapper="span"
                                        place="left"
                                        effect="solid"
                                        className="tooltip-user">
                                        Mostra/Nascondi i filtri di visualizzazione
                                    </ReactTooltip>
                                </Button>
                                <Collapse isOpen={this.state.isFilterOpen}>
                                    <Container className="ExtMaterials-Filter">
                                        <Row key="body-filter-1" style={{marginBottom: "20px"}}>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Detentore:<br />
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.filterContentOwner}
                                                    onChange={this.handleChangeContentOwner}
                                                />
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Progetto:<br />
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.filterProject}
                                                    onChange={this.handleChangeProject}
                                                />
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Evento:<br />
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.filterEvent}
                                                    onChange={this.handleChangeEvent}
                                                />
                                            </Col>
                                        </Row>
                                        <Row key="body-filter-2">
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Luogo:<br />
                                                <Input
                                                    type="text"
                                                    defaultValue={this.state.filterLocation}
                                                    onChange={this.handleChangeLocation}
                                                />
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Data Inizio:<br />
                                                <DatePicker
                                                    name="ExtDataInizio"
                                                    className="ExtMaterials-DatePicker"
                                                    selected={this.state.filterDateStart}
                                                    selectsStart
                                                    filterDateStart={this.state.filterDateStart}
                                                    filterDateEnd={this.state.filterDateEnd}
                                                    onChange={this.handleChangeStart}
                                                    dateFormat={'dd/MM/yyyy'}
                                                />
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Data Fine:<br />
                                                <DatePicker
                                                    name="ExtDataFine"
                                                    className="ExtMaterials-DatePicker"
                                                    selected={this.state.filterDateEnd}
                                                    selectsEnd
                                                    filterDateStart={this.state.filterDateStart}
                                                    filterDateEnd={this.state.filterDateEnd}
                                                    onChange={this.handleChangeEnd}
                                                    dateFormat={'dd/MM/yyyy'}
                                                />
                                            </Col>
                                        </Row>
                                        <Row key="footer-filter">
                                            <Col lg='4' md='4' sm='4'>
                                                <br />
                                                <Button
                                                    color="danger"
                                                    onClick={() => this.clearFilter()}>
                                                    RIMUOVI FILTRI
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button
                                                    color="success"
                                                    onClick={() => this.applyFilter()}
                                                    disabled={
                                                        this.state.filterContentOwner === null &&
                                                        this.state.filterEvent === null        &&
                                                        this.state.filterProject === null      &&
                                                        this.state.filterLocation === null     &&
                                                        this.state.filterDateStart === null    &&
                                                        this.state.filterDateEnd === null
                                                    }>
                                                    APPLICA FILTRI
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row key="body-stat">
                            <Col lg='12' md='12' sm='12'>
                                <Table className="ExtMaterials-Table" responsive striped>
                                    <thead>{this.prepareHeaders()}</thead>
                                    <tbody>{this.prepareRows()}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row key="pages-stat">
                            <Col lg='12' md='12' sm='12' className="ToolStat-Pagination">
                                <Pagination>
                                    <PaginationItem>
                                        <PaginationLink first onClick={() => this.changePageByStep("first")} />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink previous onClick={() => this.changePageByStep("back")} />
                                    </PaginationItem>

                                    {this.preparePaginationItems()}

                                    <PaginationItem>
                                        <PaginationLink next onClick={() => this.changePageByStep("next")} />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink last onClick={() => this.changePageByStep("last")} />
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                        {/* v DELETE MATERIAL: modalDeleteMaterial v */}
                        <Modal className='edubba-modal'
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            size='md'
                            isOpen={this.state.modalDeleteMaterial}
                            toggle={this.toggleDeleteMaterialUUID}
                            // backdrop='static'
                        >
                            <ModalHeader toggle={this.toggleDeleteMaterialUUID}>
                                <div className='widget-content p-0'>
                                    <div className='widget-content-wrapper'>
                                        <div className='widget-content-left mr-3 text-center w-100'>
                                            Rimozione di un Materiale
                                        </div>
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Container className='help-text'>
                                    Vuoi davvero rimuovere il materiale per terze-parti selezionato?
                                    L'operazione non sarà reversibile una volta completata!
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button className='btn-edubba' onClick={this.toggleDeleteMaterialUUID}>Annulla</Button>
                                <Button
                                    className='btn-edubba'
                                    onClick={this.delExtMaterial}
                                    disabled={this.state.selectedMaterialToDelete === ""}
                                >
                                    Rimuovi
                                </Button>
                            </ModalFooter>
                        </Modal>
                        {/* ^ DELETE MATERIAL: modalDeleteMaterial ^ */}
                        {/* v CONFIRM: modalConfirmToast v */}
                        <Modal className='edubba-modal'
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            size='md'
                            isOpen={this.state.modalConfirmToast}
                            toggle={this.closeModalConfirmToast}>
                            <ModalHeader toggle={this.closeModalConfirmToast}>
                                <div className='widget-content p-0'>
                                    <div className='widget-content-wrapper'>
                                        <div className='widget-content-left mr-3 text-center w-100'>
                                            {this.state.modalConfirmToastTitle}
                                        </div>
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Container className='help-text'>
                                    {this.state.modalConfirmToastMessage}
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                        {/* ^ CONFIRM: modalConfirmToast ^ */}
                    </Fragment>
                }
            </Container>
        );
    }
};

export default withRouter(ExtMaterialsListing);
