import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
//import axios from 'axios';
import EdubbaLoader from '../components/EdubbaLoader/EdubbaLoader';
//import holdDataService from '../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Collapse,
    Button,
    Input
} from 'reactstrap';
import {
    faFilter, faDownload, faTrash
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ExportActivities.scss';
import Badge from 'reactstrap/lib/Badge';

class ExportActivities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maskLoading: false,
            currentProjects: [],
            currentStats: [],
            isFilterOpen: false,

            //----------------------------------------------------------------|

            startDate: null,
            endDate: null,
            istituteNameFilter: null,

            //----------------------------------------------------------------|

            currentQueryPage: 1,
            currentQueryLimit: 25,
            currentQueryCount: 25,

            //----------------------------------------------------------------|

            tableOptions: {
                "entity_name": {
                    size: "80%",
                    name: "Nome del Documento"
                },
                "entity_status": {
                    size: "20%",
                    name: "Stato Attività"
                }
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params &&
            nextProps.match.params.export_type &&
            this.props.match.params.export_type !== nextProps.match.params.export_type) {
            this.initSection(nextProps.match.params.export_type);
        }
    }

    componentDidMount() {
        if (this.props.match.params &&
            this.props.match.params.export_type) {
            this.initSection(this.props.match.params.export_type);
        }
        else this.props.history.push("/");
    }

    initSection(exportType) {
        let setTitle = 'Status Esportazioni';

        switch (exportType) {
            case 'projects':
                setTitle += ' per Progetti';
                break;

            case 'tasks':
                setTitle += ' per Compiti';
                break;

            case 'cards':
                setTitle += ' per Schede';
                break;

            case 'tours':
                setTitle += ' per Percorsi';
                break;

            default: break;
        }

        this.props.setSectionTitle(setTitle);
        this.loadExportActivities();
    }

    loadExportActivities() {
        /*this.setState({ maskLoading: true });
        let query = "?page=" + this.state.currentQueryPage + "&limit=" + this.state.currentQueryLimit;
        let body  = {};

        // Inject filter
        if (this.state.istituteNameFilter) {
            body = {
                ...body,
                ...{ "institute_name": this.state.istituteNameFilter}
            };
        }
        if (this.state.startDate) {
            body = {
                ...body,
                ...{ "year_start": this.state.startDate}
            };
        }
        if (this.state.endDate) {
            body = {
                ...body,
                ...{ "year_end": this.state.endDate}
            };
        }

        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/stats/activities" + query, body, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentQueryCount: res.data.rowCount,
                    currentStats: res.data.rows,
                    maskLoading: false
                });
            }
            else this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                currentQueryCount: this.state.currentQueryLimit,
                currentStats: [],
                maskLoading: false
            });
        });*/

        // TODO: MOCK
        this.setState({
            currentStats: [
                {
                    entity_uuid:      "e1152f61-d032-4292-af34-8546391da330",
                    entity_name:      "Lorem ipsum PDF 1",
                    entity_status:    "PENDING",
                    entity_ready:     false,
                    entity_url:       "https://filesamples.com/samples/document/pdf/sample3.pdf",
                    entity_create_at: 1619172307049,
                    entity_update_at: 1619172307049
                },
                {
                    entity_uuid:      "e1152f61-d032-4292-af34-8546391da331",
                    entity_name:      "Lorem ipsum PDF 2",
                    entity_status:    "PROGRESS",
                    entity_ready:     false,
                    entity_url:       "https://filesamples.com/samples/document/pdf/sample3.pdf",
                    entity_create_at: 1619172307049,
                    entity_update_at: 1619172307049
                },
                {
                    entity_uuid:      "e1152f61-d032-4292-af34-8546391da332",
                    entity_name:      "Lorem ipsum PDF 3",
                    entity_status:    "READY",
                    entity_ready:     true,
                    entity_url:       "https://filesamples.com/samples/document/pdf/sample3.pdf",
                    entity_create_at: 1619172307049,
                    entity_update_at: 1619172307049
                },
                {
                    entity_uuid:      "e1152f61-d032-4292-af34-8546391da333",
                    entity_name:      "Lorem ipsum PDF 4",
                    entity_status:    "FAIL",
                    entity_ready:     false,
                    entity_url:       null,
                    entity_create_at: 1619172307049,
                    entity_update_at: 1619172307049
                }
            ]
        });
    }

    prepareHeaders() {
        let rawData    = [<th key={"tr-head-th-" + 0}>#</th>];
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < tableOpts.length; i++) {
            let tmpHeaderLabel = rawOptions[tableOpts[i]];
            tmpHeaderLabel = (
                typeof rawOptions[tableOpts[i]] === "function" ?
                rawOptions[tableOpts[i]]() :
                rawOptions[tableOpts[i]]
            );

            rawData.push(
                <th key={"tr-head-th-" + (i + 1)} style={{width: tmpHeaderLabel.size}}>{tmpHeaderLabel.name}</th>
            );
        }

        rawData.push(
            <th key={"tr-head-th-" + (tableOpts.length + 1)} style={{minWidth: "100px"}}>Azioni</th>
        );

        return (<tr key={"tr-head-" + 0}>{rawData}</tr>);
    }

    prepareRows() {
        let rawData    = [];
        let rawRecords = this.state.currentStats;
        let rawOptions = this.state.tableOptions;
        let tableOpts  = Object.keys(rawOptions);

        for (let i = 0; i < rawRecords.length; i++) {
            let data   = rawRecords[i];
            let tmpRow = [<td key={"tr-body-" + i + "-td-" + 0}>{i + 1}</td>];

            if (!(data["entity_uuid"])) {
                continue;
            }

            for (let n = 0; n < tableOpts.length; n++) {
                let tmpNodeValue = data[tableOpts[n]];
                tmpNodeValue = tmpNodeValue ? tmpNodeValue : "- - -";

                if (tableOpts[n] === "entity_status") {
                    tmpRow.push(
                        <td key={"tr-body-" + i + "-td-" + (n + 1)}>
                            <Badge color={(() => {
                                switch (tmpNodeValue) {
                                    case 'PROGRESS': return "warning";
                                    case 'READY': return "success";
                                    case 'FAIL': return "danger";

                                    case 'PENDING':
                                    default: return "secondary";
                                }
                            })()}>{tmpNodeValue}</Badge>
                        </td>
                    );
                } else {
                    tmpRow.push(
                        <td key={"tr-body-" + i + "-td-" + (n + 1)}>{tmpNodeValue}</td>
                    );
                }
            }

            tmpRow.push(
                <td key={"tr-body-" + i + "-td-" + (tableOpts.length + 1)}>
                    <div className='bottom-icons'>
                        {
                            data["entity_ready"] ? (
                                <Link to={"/path/to/exported-doc/" + data["entity_uuid"]}>
                                    <div
                                        data-tip data-for={"TIPS_FOR_D_" + i + "-td-" + (tableOpts.length + 1)}>
                                        <FontAwesomeIcon
                                            className='icon'
                                            icon={faDownload}
                                        />
                                        <ReactTooltip
                                            id={"TIPS_FOR_D_" + i + "-td-" + (tableOpts.length + 1)}
                                            wrapper="span"
                                            place="left"
                                            effect="solid"
                                            className="tooltip-work">
                                            DISPONIBILE: Scarica il documento generato
                                        </ReactTooltip>
                                    </div>
                                </Link>
                            ): (
                                <div className="disabled-action-btn"
                                    data-tip data-for={"TIPS_FOR_D_" + i + "-td-" + (tableOpts.length + 1)}>
                                    <FontAwesomeIcon
                                        className='icon'
                                        icon={faDownload}
                                    />
                                    <ReactTooltip
                                        id={"TIPS_FOR_D_" + i + "-td-" + (tableOpts.length + 1)}
                                        wrapper="span"
                                        place="left"
                                        effect="solid"
                                        className="tooltip-work">
                                        Attendere il completamento prima di scaricare
                                    </ReactTooltip>
                                </div>
                            )
                        }
                        <div className="red-action-btn"
                            data-tip data-for={"TIPS_FOR_R_" + i + "-td-" + (tableOpts.length + 1)}
                            onClick={() => null}>
                            <FontAwesomeIcon
                                className='icon'
                                icon={faTrash}
                            />
                            <ReactTooltip
                                id={"TIPS_FOR_R_" + i + "-td-" + (tableOpts.length + 1)}
                                wrapper="span"
                                place="left"
                                effect="solid"
                                className="tooltip-work">
                                ATTENZIONE: Permette la rimozione del documento
                            </ReactTooltip>
                        </div>
                    </div>
                </td>
            );
            rawData.push(<tr key={"tr-body-" + i}>{tmpRow}</tr>);
        }

        return rawData;
    }

    preparePaginationItems() {
        // Prepare data
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);
        let items      = [];

        // Prepare limits
        let firstMin   = 1;
        let lastMax    = countPage;

        // Check range
        if (countPage > 8) {
            // Specifies range of items in the middle
            firstMin = Math.max(active - 2, 1);
            lastMax  = Math.min(active + 2, countPage);

            // Normalize
            if (active === 1) {
                lastMax += 2;
            }
            // Normalize
            if (active === 2) {
                lastMax += 1;
            }
            else if (active === (countPage - 1)) {
                firstMin-=1;
            }
            else if (active === countPage) {
                firstMin-=2;
            }
        }

        // Push forst separator
        if (firstMin > 1) {
            items.push(
                <PaginationItem key={"firstMinSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Loop on item
        for (let number = firstMin; number <= lastMax; number++) {
            // Save item
            items.push(
                <PaginationItem key={number} active={number === active}>
                    <PaginationLink onClick={() => this.changePage(number)}>
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Push last separator
        if (lastMax < countPage) {
            items.push(
                <PaginationItem key={"lastMaxSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Send back
        return items;
    }

    changePage(toPage) {
        this.setState({
            currentStats: [],
            currentQueryPage: toPage
        },
        () => {
            this.loadExportActivities();
        });
    }

    changePageByStep(toPage) {
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);

        switch (toPage) {
            case "first":
                active = 1;
                break;

            case "back":
                active = ((active - 1) >= 1) ? (active - 1) : 1;
                break;

            case "next":
                active = ((active + 1) <= countPage) ? (active + 1) : active;
                break;

            case "last":
                active = countPage;
                break;

            default: break;
        }

        this.setState({
            currentStats: [],
            currentQueryPage: active
        },
        () => {
            this.loadExportActivities();
        });
    }

    toggleFilter() {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen
        });
    }

    handleChangeIstituteName= (ev) => {
        this.setState({
            istituteNameFilter: (ev.target && ev.target.value) ? ev.target.value : null
        });
    }

    handleChangeStart = (ev) => {
        this.setState({
            startDate: (ev.target && ev.target.value) ? ev.target.value : null
        });
    }

    handleChangeEnd = (ev) => {
        this.setState({
            endDate: (ev.target && ev.target.value) ? ev.target.value : null
        });
    }

    clearFilter = () => {
        this.setState({
            isFilterOpen: false,
            currentQueryPage: 1,
            istituteNameFilter: null,
            startDate: null,
            endDate: null
        },
        () => {
            this.loadExportActivities();
        });
    }

    applyFilter = () => {
        this.setState({
            isFilterOpen: false,
            currentQueryPage: 1
        },
        () => {
            this.loadExportActivities();
        });
    }

    render() {
        return (
            <Container className='ExportActivities' active-page={this.state.currentQueryPage}>
                {
                    this.state.maskLoading ?
                        <EdubbaLoader />
                    : <Fragment>
                        <Row key="head-stat-1">
                            <Col lg='12' md='12' sm='12' className="HeadStat">
                                Esportazioni:
                                <Button
                                    disabled
                                    data-tip data-for={"TIPS_FOR_BTN_ACTION_FILTER"}
                                    color="secondary"
                                    onClick={() => this.toggleFilter()}
                                    style={{
                                        position: "relative",
                                        float: "right",
                                        marginTop: '12px'
                                    }}>
                                    <FontAwesomeIcon icon={faFilter} />
                                    <ReactTooltip
                                        id={"TIPS_FOR_BTN_ACTION_FILTER"}
                                        wrapper="span"
                                        place="left"
                                        effect="solid"
                                        className="tooltip-work">
                                        Mostra/Nascondi i filtri di visualizzazione
                                    </ReactTooltip>
                                </Button>
                                <Collapse isOpen={this.state.isFilterOpen}>
                                    <Container className="ExportActivities-Filter">
                                        <Row key="body-filter">
                                            <Col lg='4' md='4' sm='4'>
                                                Cerca per nome Istituto:<br />
                                                <Input
                                                    type="text"
                                                    name="institute_name"
                                                    defaultValue={
                                                        this.state.istituteNameFilter ?
                                                        this.state.istituteNameFilter
                                                        : ""
                                                    }
                                                    onChange={this.handleChangeIstituteName}>
                                                </Input>
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Anno Inizio:<br />
                                                <Input
                                                    type="number"
                                                    name="dataInizio"
                                                    min="1900"
                                                    max="9999"
                                                    minLength="4"
                                                    maxLength="4"
                                                    size="4"
                                                    defaultValue={
                                                        this.state.startDate ?
                                                        this.state.startDate
                                                        : ""
                                                    }
                                                    onChange={this.handleChangeStart}
                                                    >
                                                </Input>
                                            </Col>
                                            <Col lg='4' md='4' sm='4'>
                                                Filtra per Anno Fine:<br />
                                                <Input
                                                    type="number"
                                                    name="dataFine"
                                                    min="1900"
                                                    max="9999"
                                                    minLength="4"
                                                    maxLength="4"
                                                    size="4"
                                                    defaultValue={
                                                        this.state.endDate ?
                                                        this.state.endDate
                                                        : ""
                                                    }
                                                    onChange={this.handleChangeEnd}>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row key="footer-filter">
                                            <Col lg='4' md='4' sm='4'>
                                                <br />
                                                <Button
                                                    color="danger"
                                                    onClick={() => this.clearFilter()}
                                                    disabled={
                                                        this.state.startDate === null &&
                                                        this.state.endDate === null &&
                                                        this.state.istituteNameFilter === null
                                                    }>
                                                    RIMUOVI FILTRI
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    color="success"
                                                    onClick={() => this.applyFilter()}
                                                    disabled={(
                                                        this.state.startDate === null ||
                                                        this.state.startDate < 1900 ||
                                                        this.state.startDate > 9999) && (
                                                        this.state.endDate === null ||
                                                        this.state.endDate < 1900 ||
                                                        this.state.endDate > 9999) &&
                                                        this.state.istituteNameFilter === null
                                                    }>
                                                    APPLICA FILTRI
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row key="body-stat">
                            <Col lg='12' md='12' sm='12'>
                                <Table className="ExportActivities-Table" responsive striped>
                                    <thead>{this.prepareHeaders()}</thead>
                                    <tbody>{this.prepareRows()}</tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row key="pages-stat">
                            <Col lg='12' md='12' sm='12' className="ToolStat-Pagination">
                                <Pagination>
                                    <PaginationItem>
                                        <PaginationLink first onClick={() => this.changePageByStep("first")} />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink previous onClick={() => this.changePageByStep("back")} />
                                    </PaginationItem>

                                    {this.preparePaginationItems()}

                                    <PaginationItem>
                                        <PaginationLink next onClick={() => this.changePageByStep("next")} />
                                    </PaginationItem>
                                    <PaginationItem>
                                        <PaginationLink last onClick={() => this.changePageByStep("last")} />
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>
                    </Fragment>
                }
            </Container>
        );
    }
};

export default withRouter(ExportActivities);
