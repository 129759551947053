import React from 'react';
import axios from 'axios';
import globalVars from './../../globalVars.json';
import holdDataService from '../holdDataService/holdDataService';
import {
    Row,
    Col
} from 'reactstrap';
import {
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JSTemplateManager from '../templateManager/JSTemplateManager';
import './JSXTemplateRender.scss';

class JSXTemplateRender extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            response: null,
            error: null,
            visibilityHeaders: [],
            visibilityData: {},
            tabActive: null,
            htmlToRender: null
        }

        this.ctxTemplateManager = new JSTemplateManager();
    }

    componentDidMount() {
        this.loadVisibilityData();
    }

    loadVisibilityData = () => {
        this.setState({ isLoading: true })
        let query = "/templates/" + this.props.templateToRender + "/visibilities/" + this.props.visibilityToRender;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let visibilities = {};
                if (res.data.rowCount > 0) {
                    visibilities = res.data.rows[0];
                }

                // Load template into manager
                this.ctxTemplateManager.parseTemplateJSON(visibilities["template_data"]);

                // Set mask
                this.ctxTemplateManager.setVisibilityMask(visibilities["visibility_data"]);

                // Get header from manager
                let headers = this.ctxTemplateManager.getAllHeaders();
                let activeTab = headers.length > 0 ? headers[0]["field-edusn-srvid"] : null;

                this.setState({
                    visibilityData: visibilities,
                    visibilityHeaders: headers,
                    isLoading: false,
                    tabActive: activeTab
                }, () => {
                    this.updateHtmlToRender();
                });
            }, err => {
                this.setState({
                    visibilityData: {},
                    isLoading: false
                });
            });
    };

    updateHtmlToRender = () => {
        // Render section of tab
        this.setState({
            htmlToRender: this.getTabBodiesHTML_helper(
                this.state.tabActive
            )
        });
    }

    getTabBodiesHTML_helper = (node) => {
        // Prepare data
        let curSectionNode = this.ctxTemplateManager.getTemplateSection(node);
        let curSectionPure = this.ctxTemplateManager.getVisibilitySection(node);
        let curSectionMask = this.ctxTemplateManager.getMaskSection(node);
        let renderMask = {
            ...curSectionPure,
            ...curSectionMask
        };

        // Check for children
        if (curSectionNode &&
            Array.isArray(curSectionNode["field-tree-childs"]) &&
            curSectionNode["field-tree-childs"].length > 0) {
            return curSectionNode["field-tree-childs"].map((child) => {
                return this.getTabBodiesHTML_helper(child);
            });
        }

        return (
            <div className={"fieldBaseRender " + (renderMask["field-is-visible"] || renderMask["field-is-required"] ? "fieldIsVisible" : "")}>
                <div className="fieldBaseHeader">
                    <FontAwesomeIcon className="faFloatRight" icon={renderMask["field-is-visible"] || renderMask["field-is-required"] ? faEye : faEyeSlash} />
                    {curSectionNode["field-name"].toLowerCase()}
                </div>
                <div className="fieldBaseDescription">
                    {curSectionNode["field-descriptor"] ? curSectionNode["field-descriptor"] : "Descrizione non disponibile per il campo."}
                </div>
                <div className="fieldBaseExample">
                    {
                        curSectionNode["field-desc-example"] &&
                        curSectionNode["field-desc-example"].length > 0 ?
                            curSectionNode["field-desc-example"].map((example) =>{
                                return (
                                    <div>{example}</div>
                                );
                            })
                        : "Esempi non disponibili per il campo."
                    }
                </div>
            </div>
        );
    }

    setActiveTab = (index) => {
        this.setState({
            tabActive: index
        }, () => {
            this.updateHtmlToRender();
        });
    }

    render() {
        return (
            <div className="VisibilityRender">
                <Row>
                    <Col xs="4" className="left-content-jsx">
                        {
                            this.state.visibilityHeaders.map((head, index) => {
                                let isActive = (
                                    this.state.tabActive === null &&
                                    index === 0
                                ) ? true : false;

                                if (head["field-edusn-srvid"] === this.state.tabActive) {
                                    isActive = true;
                                }

                                return (
                                    <div
                                        key={"TAB_MASK_" + head["field-edusn-srvid"]}
                                        onClick={() => this.setActiveTab(head["field-edusn-srvid"])}
                                        className={"header-tab-jsx " + (isActive ? "active-tab-jsx" : "")}>
                                        {head["field-name"].toLowerCase()}
                                    </div>
                                );
                            })
                        }
                    </Col>
                    <Col xs="8" className="right-content-jsx" data-tab={this.state.tabActive}>
                        {this.state.htmlToRender}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default JSXTemplateRender;
