import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import cx from 'classnames';
import AppHeader from './Layout/AppHeader';
//import AppFooter from './Layout/AppFooter';
import AppSidebar from './Layout/AppSidebar';
import ResizeDetector from 'react-resize-detector';
import EdubbaLoader from './components/EdubbaLoader/EdubbaLoader';
import './Main.scss';
import './services/axiosInterceptor/axiosInterceptor';
import globalVars from './globalVars.json';
import { Switch, BrowserRouter as Router, Route, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// AUTH
import Login from './Login/Login';
import holdDataService from './services/holdDataService/holdDataService';

// COMMON
import Dashboard from './Dashboard/Dashboard';
import Teams from './Teams/Teams';
import Classrooms from './Classrooms/Classrooms';
import Students from './Students/Students';
import Tasks from './Tasks/Tasks';
import Cards from './Cards/Cards';
import Tours from './Tours/Tours';
import TourCreation from './Tours/Creation/TourCreation';
import TourAssignment from './Tours/Assignment/TourAssignment';
import TourEnrichment from './Tours/Enrichment/TourEnrichment';
import ThirdCatalogs from './ThirdCatalogs/ThirdCatalogs';
import CatalogCards from './ThirdCatalogs/CatalogCards/CatalogCards';
import SocialRoom from './SocialRooms/SocialRoom';
import CardCreation from './CardDetail/CardCreation/CardCreation';
import CardDetail from './CardDetail/CardDetail';
import CardTemplate from './CardDetail/CardTemplate/CardTemplate';
import Visibilities from './Visibilities/Visibilities';
import AssignmentDetail from './AssignmentDetail/AssignmentDetail';
import Disclaimer from './Disclaimer/Disclaimer';
import News from './News/News';
import Help from './Help/Help';
import ExportActivities from './ExportActivities/ExportActivities';

// ADMIN
import Administration from './Admin/Dashboard';
import BackOffice from './Admin/BackOffice/BackOffice';
import SysMetrics from './Admin/UserMetrics/Metrics';
import WorkMetrics from './Admin/WorkMetrics/Metrics';
import UserStatsDetail from './Admin/UserMetrics/UserStats/UserStatsDetail';
import InstituteStatsDetail from './Admin/WorkMetrics/InstituteStats/InstituteStatsDetail';
import ServerStatsDetail from './Admin/SysMetrics/ServerStatsDetail';
import ServerPathStatsDetail from './Admin/SysPathMetrics/ServerPathStatsDetail';

// CONTENT ADMIN
import CADashboard from './ContentAdmin/CA_Dashboard';
import CAExtMaterials from './ContentAdmin/ThirdParties/ExtMaterials';
import CAExtMaterialDetails from './ContentAdmin/ThirdParties/Details/ExtMaterialDetails';

// News
import NewsJSON from './News/News.json';

// START APP
class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDetailPage: false,
            headerButtons: [],
            footerButtons: [],
            goBackFunc: this.props.history.goBack,
            globalModal: false,
            sectionTitle: '',
            triggerUpdateSession: "waiting",
            toastIds: [],
            userName: '',
            lastName: '',

            projects: [],
            teams: [],

            loading: true,
            //
            notifications: [],
            skipNotificationUpdate: false
        };

        // this.getUser = this.getUser.bind(this);
        // this.setDetailPage = this.setDetailPage.bind(this);
        // this.setHeaderButtons = this.setHeaderButtons.bind(this);
        // this.setFooterButtons = this.setFooterButtons.bind(this);
        // this.backFuncSetter = this.backFuncSetter.bind(this);
        // this.toggleGlobalModal = this.toggleGlobalModal.bind(this);
        // this.toggleGlobalInfoModal = this.toggleGlobalInfoModal.bind(this);
        // this.registerLogout = this.registerLogout.bind(this);
        // this.cancelToastRegionalNotification = this.cancelToastRegionalNotification.bind(this);
    }

    componentDidMount() {
        let userInfo = holdDataService.pullData('userInfo');
        let userName = '';
        let lastName = '';

        if (userInfo) {

            userName = userInfo.user_profile.firstName;
            lastName = userInfo.user_profile.lastName;

            this.setState({
                triggerUpdateSession: "logged",
                userName: userName,
                lastName: lastName,
            });
        }
        else {
            holdDataService.deleteAllData();
            this.setState({ triggerUpdateSession: "unlogged" });
            this.props.history.push('/login');
        }

        // Enable keep-alive
        setInterval(this.sendKeepAlive, 30000);

        // Send activities update
        if (!holdDataService.loggedUserIsAdmin() &&
            !holdDataService.loggedUserIsContentAdmin() &&
            !holdDataService.loggedUserIsOnlySupervisor()) {
            setInterval(this.getNotifications, 10000);
        }
    }

    sendKeepAlive = () => {
        // Check session uuid
        if (holdDataService.getLoggedUserUuid() === null) {
            return;
        }

        // Send keep-alive
        axios.get(
            globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/auth",
            { auth: holdDataService.getAuthorization() }
        ).then(
            () => { /*console.info("Keep-Alive: ACK!");*/ },
            (err) => { console.error("Keep-Alive: NACK! -->> ERROR:  ", err); }
        );
    }

    setNotifcationUpdate = (stateUpdate) => {
        this.setState({
            skipNotificationUpdate: stateUpdate
        });
    }

    getNotifications = () => {
        if (holdDataService.loggedUserIsAdmin()          ||
            holdDataService.loggedUserIsContentAdmin()   ||
            holdDataService.loggedUserIsOnlySupervisor() ||
            holdDataService.getLoggedUserUuid() === null ||
            this.state.skipNotificationUpdate) {
            return;
        }

        let query = "/activities";
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                res.data.rows = res.data.rows.map((item) => {
                    if (item.activity_is_notified) {
                        item["seen"] = true;
                    }
                    else item["seen"] = false;

                    return item;
                });

                this.setState({
                    notifications: res.data.rows
                });
            }
            else this.setState({
                notifications: []
            });
        }, err => {
            //
        });
    }

    checkNotificationSeen = (notification_uuid) => {
        this.setState((prevState) => {
            let currentNotifications = prevState.notifications.slice(0);
            for (let i = 0; i < currentNotifications.length; i++) {
                if (currentNotifications[i].activity_uuid === notification_uuid &&
                    currentNotifications[i].seen !== true) {
                    currentNotifications[i].seen = true;
                    let query = "/activities/" + currentNotifications[i].activity_uuid;
                    axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {},{
                        auth: holdDataService.getAuthorization()
                    }).then(res => {
                        //
                    }, err => {
                        //
                    });
                }
            }
            return {
                notifications: currentNotifications
            };
        });
    }

    setSectionTitle = (val) => {
        let title = 'none';
        if (val)
            title = val;
        this.setState({
            sectionTitle: title
        })
    }

    jumpToNews = (newsData) => {
        toast.dismiss();
        if (newsData &&
            newsData.flash &&
            newsData.url) {
            this.props.history.push(newsData.url);
            window.location.reload(newsData.url);
        } else {
            this.props.history.push("/news");
            window.location.reload("/news");
        }
    }

    updateSession = () => {
        let userInfo = holdDataService.pullData('userInfo');
        if (userInfo) {
            this.setState({
                triggerUpdateSession: "logged",
                userName: userInfo.user_profile.firstName,
                lastName: userInfo.user_profile.lastName
            });

            const newsData = (
                Array.isArray(NewsJSON.OrderBy) &&
                NewsJSON.OrderBy.length > 0 &&
                NewsJSON.Content
            ) ? NewsJSON.Content[
                NewsJSON.OrderBy[0]
            ] : null;

            if ((newsData && newsData.flash) || !holdDataService.loggedUserIsStudent()) {
                toast.info(
                    (
                        <div onClick={() => this.jumpToNews(newsData)} className="NewsToast">
                            <div className="NewsToast-Title">
                                {
                                    (newsData && newsData.title) ?
                                        newsData.title :
                                        "Attenzione"
                                }:
                            </div>
                            <div className="NewsToast-Msg">
                                {
                                    (newsData && newsData.summary) ?
                                        newsData.summary :
                                        "Sono presenti novità e documenti scaricabili nella sezione \"Avvisi e News\""
                                }.
                            </div>
                        </div>
                    ),
                    {
                        autoClose: (
                            (newsData &&
                             newsData.duration !== null &&
                             newsData.duration > 0) ?
                                newsData.duration * 1000 :
                                3000
                        ),
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false
                    }
                );
            }
        }
    }

    registerLogout = () => {
        holdDataService.deleteAllData();
        this.setState({
            triggerUpdateSession: "unlogged"
        }, () => {
            window.location.reload("/");
        });
    }

    updateTeams = (newTeams) => {
        this.setState({
            teams: newTeams
        })
    }

    updateProjects = (projects) => {
        this.setState({
            projects: projects
        })
    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            //enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;

        colorScheme = 'default';

        return ["unlogged", "logged"].includes(this.state.triggerUpdateSession) ? (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable={false}
                            pauseOnHover
                        />
                        <Router>
                            <div className={cx(
                                "app-container app-theme-" + colorScheme,
                                { 'fixed-header': enableFixedHeader },
                                { 'fixed-sidebar': enableFixedSidebar || width < 1400 },
                                //{ 'fixed-footer': enableFixedFooter },
                                { 'closed-sidebar': enableClosedSidebar || width < 1400 },
                                { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1400 },
                                { 'sidebar-mobile-open': enableMobileMenu },
                                { 'body-tabs-shadow-btn': enablePageTabsAlt },
                            )}>
                                <AppHeader
                                    key={"session_status_" + this.state.triggerUpdateSession}
                                    userName={this.state.userName + " " + this.state.lastName}
                                    sectionTitle={this.state.sectionTitle}
                                    registerLogout={this.registerLogout}
                                    notifications={this.state.notifications}
                                    getNotifications={this.getNotifications}
                                    checkNotificationSeen={this.checkNotificationSeen}
                                />
                                <div id='app-main' className="app-main">
                                    <AppSidebar
                                        key={"session_status_" + this.state.triggerUpdateSession}
                                        projects={this.state.projects}
                                    />
                                    <div className="app-main__outer">
                                        {this.state.sectionTitle === 'none'? null : (
                                            <div className='main__top-bar'>
                                                {this.state.sectionTitle}
                                            </div>
                                        )}
                                        <div className="app-main__inner">
                                            <Switch>
                                                <Route
                                                    exact path="/"
                                                    render={(props) => (
                                                        // ONLY ADMIN: CUSTOM TOOLS DASHBOARD
                                                        holdDataService.loggedUserIsAdmin() ?
                                                        <Administration
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        // ONLY CONTENT ADMIN: CUSTOM TOOLS DASHBOARD
                                                        : holdDataService.loggedUserIsContentAdmin() ?
                                                        <CADashboard
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        // ONLY ADMIN: CUSTOM TOOLS DASHBOARD
                                                        : holdDataService.loggedUserIsOnlySupervisor() ?
                                                        <Administration
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        // OTHERWISE: COMMON WORKING DASHBOARD
                                                        : <Dashboard
                                                            setSectionTitle={this.setSectionTitle}
                                                            notifications={this.state.notifications}
                                                            updateProjects={this.updateProjects}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/exports/:export_type"
                                                    render={(props) => (
                                                        holdDataService.canOpenRoute("sub_project_exports") ||
                                                        holdDataService.canOpenRoute("sub_task_exports") ||
                                                        holdDataService.canOpenRoute("sub_card_exports") ||
                                                        holdDataService.canOpenRoute("sub_tour_exports") ?
                                                        <ExportActivities
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/third-parties/materials"
                                                    render={(props) => (
                                                        holdDataService.canOpenRoute("sub_admin_materials") ||
                                                        holdDataService.canOpenRoute("sub_content_admin_materials") ||
                                                        holdDataService.canOpenRoute("root_users_third_parties_materials") ?
                                                        <CAExtMaterials
                                                            setSectionTitle={this.setSectionTitle}
                                                            userCanOperate={
                                                                holdDataService.loggedUserIsAdmin() ||
                                                                holdDataService.loggedUserIsContentAdmin()
                                                            }
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/third-parties/materials/create"
                                                    render={(props) => (
                                                        holdDataService.canOpenRoute("sub_admin_materials") ||
                                                        holdDataService.canOpenRoute("sub_content_admin_materials") ?
                                                        <CAExtMaterialDetails
                                                            setSectionTitle={this.setSectionTitle}
                                                            setOpenAsViewer={false}
                                                            userCanOperate={
                                                                holdDataService.loggedUserIsAdmin() ||
                                                                holdDataService.loggedUserIsContentAdmin()
                                                            }
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/third-parties/materials/:material_uuid"
                                                    render={(props) => (
                                                        holdDataService.canOpenRoute("sub_admin_materials") ||
                                                        holdDataService.canOpenRoute("sub_content_admin_materials") ||
                                                        holdDataService.canOpenRoute("root_users_third_parties_materials") ?
                                                        <CAExtMaterialDetails
                                                            setSectionTitle={this.setSectionTitle}
                                                            setOpenAsViewer={true}
                                                            userCanOperate={
                                                                holdDataService.loggedUserIsAdmin() ||
                                                                holdDataService.loggedUserIsContentAdmin()
                                                            }
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin"
                                                    render={(props) => (
                                                        holdDataService.canOpenRoute("root_admin_dashboard") ||
                                                        holdDataService.canOpenRoute("root_supervisor_dashboard") ?
                                                        <Administration
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin/stats/users"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_admin_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_admin_stats_users")
                                                        )
                                                        ||
                                                        (
                                                            holdDataService.canOpenRoute("root_supervisor_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_supervisor_stats_users")
                                                        ) ?
                                                        <SysMetrics
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin/stats/users/:user_uuid"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_admin_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_admin_stats_users")
                                                        )
                                                        ||
                                                        (
                                                            holdDataService.canOpenRoute("root_supervisor_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_supervisor_stats_users")
                                                        ) ?
                                                        <UserStatsDetail
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin/stats/sysd"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_admin_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_admin_stats_sysd")
                                                        )
                                                        ||
                                                        (
                                                            holdDataService.canOpenRoute("root_supervisor_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_supervisor_stats_sysd")
                                                        ) ?
                                                        <ServerStatsDetail
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin/stats/workd"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_admin_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_admin_stats_workd")
                                                        )
                                                        ||
                                                        (
                                                            holdDataService.canOpenRoute("root_supervisor_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_supervisor_stats_workd")
                                                        ) ?
                                                        <WorkMetrics
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin/stats/workd/:institute_uuid"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_admin_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_admin_stats_workd")
                                                        )
                                                        ||
                                                        (
                                                            holdDataService.canOpenRoute("root_supervisor_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_supervisor_stats_workd")
                                                        ) ?
                                                        <InstituteStatsDetail
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin/stats/endpoints"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_admin_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_admin_stats_endpoints")
                                                        )
                                                        ||
                                                        (
                                                            holdDataService.canOpenRoute("root_supervisor_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_supervisor_stats_endpoints")
                                                        ) ?
                                                        <ServerPathStatsDetail
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/admin/entities"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_admin_dashboard") &&
                                                            holdDataService.canOpenRoute("sub_admin_backoffice")
                                                        ) ?
                                                        <BackOffice
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/third-catalogs"
                                                    render={(props) => (
                                                        <ThirdCatalogs
                                                            setSectionTitle={this.setSectionTitle} />
                                                    )}
                                                />
                                                <Route
                                                    exact path="/third-catalogs/:catalog_uuid/cards"
                                                    render={(props) => (
                                                        <CatalogCards
                                                            setSectionTitle={this.setSectionTitle} />
                                                    )}
                                                />
                                                <Route exact path="/groups" render={(props) => (
                                                    <Teams
                                                        setSectionTitle={this.setSectionTitle}
                                                        updateTeams={this.updateTeams}
                                                        notificationUpdate={this.setNotifcationUpdate} />
                                                )} />
                                                <Route exact path="/classrooms" render={(props) => (
                                                    (
                                                        holdDataService.canOpenRoute("sub_users_classes")
                                                    ) ?
                                                    <Classrooms
                                                        setSectionTitle={this.setSectionTitle}
                                                        notificationUpdate={this.setNotifcationUpdate} />
                                                    : <Redirect from="*" to="/" />
                                                )} />
                                                <Route exact path="/students" render={(props) => (
                                                    (
                                                        holdDataService.canOpenRoute("sub_users_students")
                                                    ) ?
                                                    <Students
                                                        setSectionTitle={this.setSectionTitle}
                                                        notificationUpdate={this.setNotifcationUpdate} />
                                                    : <Redirect from="*" to="/" />
                                                )} />
                                                <Route exact path="/socialroom/:idroom" render={(props) => (
                                                    <SocialRoom
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/cards" render={(props) => (
                                                    <Cards
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/tasks/:task_filter_view" render={(props) => (
                                                    <Tasks
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/new_card" render={(props) => (
                                                    <CardCreation
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/card-detail/:card_view_status/:card_uuid" render={(props) => (
                                                    <CardDetail
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/tours"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_tours") &&
                                                            holdDataService.canOpenRoute("sub_tours_personal")
                                                        ) ?
                                                        <Tours
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route exact path="/tours/:tour_uuid"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_tours") &&
                                                            holdDataService.canOpenRoute("sub_tours_create")
                                                        ) ?
                                                        <TourCreation
                                                            setSectionTitle={this.setSectionTitle}
                                                            createMode={false}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route exact path="/tours/:tour_uuid/assign"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_tours") &&
                                                            holdDataService.canOpenRoute("sub_tours_create")
                                                        ) ?
                                                        <TourAssignment
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route exact path="/tours/:tour_uuid/enrichments/:enrich_uuid"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.loggedUserIsStudent() ||
                                                            holdDataService.loggedUserIsTutor()
                                                        ) ?
                                                        <TourEnrichment
                                                            setSectionTitle={this.setSectionTitle}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route exact path="/edit_tour"
                                                    render={(props) => (
                                                        (
                                                            holdDataService.canOpenRoute("root_tours") &&
                                                            holdDataService.canOpenRoute("sub_tours_create")
                                                        ) ?
                                                        <TourCreation
                                                            setSectionTitle={this.setSectionTitle}
                                                            createMode={true}
                                                        />
                                                        : <Redirect from="*" to="/" />
                                                    )}
                                                />
                                                <Route exact path="/visibilities" render={(props) => (
                                                    <Visibilities
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/visibilities/:template_uuid" render={(props) => (
                                                    <Visibilities
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/visibilities/:template_uuid/:template_view_mode/:visibility_uuid" render={(props) => (
                                                    <Visibilities
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/help" render={(props) => (
                                                    <Help
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/news" render={(props) => (
                                                    <News
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/news/:content_code" render={(props) => (
                                                    <News
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/disclaimer" render={(props) => (
                                                    <Disclaimer
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/disclaimer/:disclaimer_code" render={(props) => (
                                                    <Disclaimer
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Route exact path="/assignments/:assign_uuid" render={(props) => (
                                                    <AssignmentDetail
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                {
                                                    // Hide login route for logged user
                                                    (!["logged"].includes(this.state.triggerUpdateSession)) ?
                                                        <Route exact path="/login" render={(props) => (
                                                            <Login updateSession={this.updateSession} />
                                                        )} />
                                                        : null
                                                }
                                                <Route exact path="/card-template/:assign_uuid/:continue_work" render={(props) => (
                                                    <CardTemplate
                                                        setSectionTitle={this.setSectionTitle} />
                                                )} />
                                                <Redirect from="*" to="/" />
                                            </Switch>
                                        </div>
                                    </div>
                                </div>
                                {/*<AppFooter />*/}
                            </div>
                        </Router>
                    </Fragment>
                )}
            />
        ) : <EdubbaLoader />;
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(Main));
