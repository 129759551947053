import React, { Component } from 'react';
import axios from 'axios';
import globalVars from './../../globalVars.json';

import {
    Map,
    TileLayer,
    LatLng,
    Marker,
    Icon,
} from 'leaflet';
import 'leaflet-routing-machine';

import './../../../node_modules/leaflet/dist/leaflet.css';
import './../../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css';
import './MapSelectorManager.css'
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import Button from 'reactstrap/lib/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

class MapSelectorManager extends Component {
    constructor(props) {
        super(props);
        /*
         *  PARAMETERS:
         * -------------------------------
         * selectCallback: function => (data-url)
         * registeredValue: Array [lat, lon]
         */

        this.state = {
            position: null,
            results: [],
            searchAddressEnabled: false,
            suggestAddressEnabled: false,
            addressToSearch: ""
        };

        // Handler
        this.leafletMap = null;
        this.leafletMarker = null;

        // Callback
        this.sendToCallback = this.sendToCallback.bind(this);

        // Init
        this.initLeafLetLogic = this.initLeafLetLogic.bind(this);

        // Change
        this.handleSearchAddressChange = this.handleSearchAddressChange.bind(this);
        this.findAddressFromOpenStreetClose = this.findAddressFromOpenStreetClose.bind(this);
        this.findAddressFromOpenStreet = this.findAddressFromOpenStreet.bind(this);
        this.setMapMarker = this.setMapMarker.bind(this);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE MOUNT
    ///////////////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {
        // Reinit maps
        this.initLeafLetLogic();
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE FINDER
    ///////////////////////////////////////////////////////////////////////////////////////////////

    handleSearchAddressChange(ev) {
        this.setState({
            addressToSearch: ev.target.value,
            searchAddressEnabled: (
                ev.target.value.length >= 3
            ) ? true : false
        });
    }

    findAddressFromOpenStreetClose(ev) {
        this.setState({
            suggestAddressEnabled: false,
            results: []
        });
    }

    findAddressFromOpenStreet(ev) {
        axios.get(globalVars.LeafLet.APISearch + "?format=json&limit=15&q=" + this.state.addressToSearch)
        .then(
            res => {
                this.setState({
                    suggestAddressEnabled: true,
                    results: res.data
                });
            },
            err => {
                this.setState({
                    suggestAddressEnabled: true,
                    results: []
                });
            }
        );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE INIT
    ///////////////////////////////////////////////////////////////////////////////////////////////

    initLeafLetLogic() {
        this.leafletMap = new Map(
            this.refs.entrymap,
            {
                zoom: 8,
                inertia: true,
                inertiaDeceleration: 2000
            }
        );

        new TileLayer(
            globalVars.Protocol + '://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
            {attribution: 'CARTO'}
        )
        .addTo(this.leafletMap);

        this.leafletMap.on('click',
            (e) => {
                let coord = e.latlng.toString().split(',');
                let lat = coord[0].split('(');
                let lng = coord[1].split(')');
                let pos = [lat[1], lng[0]];

                this.setMapMarker(pos);
            }
        );

        if (this.props.registeredValue) {
            this.setMapMarker(
                this.props.registeredValue
            );
        }
        else {
            navigator.geolocation.getCurrentPosition(
                (location) => {
                    let latlng = new LatLng(
                        location.coords.latitude,
                        location.coords.longitude
                    );
                    this.leafletMap.setView(
                        latlng,
                        8,
                        {animation: true}
                    );
                },
                (err) => {
                    console.error(err);
                    this.leafletMap.setView(
                        [
                            40.83597000,
                            14.24868000
                        ],
                        8,
                        {animation: true}
                    );
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE MARKER + POPUP
    ///////////////////////////////////////////////////////////////////////////////////////////////

    setMapMarker(coordy) {
        if (this.leafletMarker) {
            this.leafletMarker.closePopup();
            this.leafletMap.setView(coordy, 13, {animation: true});
            this.leafletMarker.setLatLng(coordy);
        }
        else {
            const iconMarker = new Icon({
                iconSize:    [25, 41],
                iconAnchor:  [10, 41],
                popupAnchor: [2, -40],
                iconUrl:     globalVars.LeafLet.APIMarkerIcon,
                shadowUrl:   globalVars.LeafLet.APIMarkerShadow
            });

            this.leafletMap.setView(coordy, 13, {animation: true});
            this.leafletMarker = new Marker(coordy, { icon: iconMarker }).addTo(this.leafletMap);
        }

        // Get coordy fixed
        let lat = this.leafletMarker.getLatLng().lat.toFixed(8);
        let lon = this.leafletMarker.getLatLng().lng.toFixed(8);

        // Gen. button trigger
        let docPopupBtn = document.createElement("button");
        docPopupBtn.innerHTML = "Seleziona";
        docPopupBtn.setAttribute("class", "confirm-popup-btn");
        docPopupBtn.onclick = () => {
            this.sendToCallback({
                "lat": lat,
                "lon": lon
            });
        };

        // Gen. button container plus info
        let docPopup = document.createElement("div");
        docPopup.innerHTML = "Latitude: " + lat + "<br />Longitude: " + lon + "<br />";
        docPopup.appendChild(docPopupBtn);

        // Set container to popup
        this.leafletMarker.bindPopup(docPopup).openPopup();
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE CALLBACKS
    ///////////////////////////////////////////////////////////////////////////////////////////////

    sendToCallback(data) {
        if (this.props.selectCallback) {
            this.props.selectCallback(data);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div className="MapSelectorManager">
                <div className="map-container">
                    <div id="leafletmap" ref="entrymap"></div>
                </div>
                <div className={"results-container " + (this.state.suggestAddressEnabled ? "expand" : "")}>
                    <InputGroup>
                        <Input ref="searchinput" type="text" className="search-address"
                            onChange={this.handleSearchAddressChange}
                            placeholder="Indirizzo di almeno 3 caratteri..."
                            value={this.state.addressToSearch ? this.state.addressToSearch : ""}
                            readOnly={this.state.suggestAddressEnabled}
                        />
                        {this.state.suggestAddressEnabled ?
                            <InputGroupAddon addonType="append">
                                <Button color="secondary" onClick={this.findAddressFromOpenStreetClose}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </InputGroupAddon> :
                            <InputGroupAddon addonType="append">
                                <Button color="secondary" disabled={!this.state.searchAddressEnabled} onClick={this.findAddressFromOpenStreet}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroupAddon>
                        }
                    </InputGroup>
                    {
                        this.state.suggestAddressEnabled ?
                            this.state.results.length > 0 ? (
                                <div className="address-list">
                                    {
                                        this.state.results.map((elem, index) => {
                                            return (
                                                <div key={index} className='res-address'
                                                    onClick={() => {
                                                        this.setMapMarker([elem.lat, elem.lon]);
                                                        this.refs.searchinput.value = "";
                                                        this.setState({
                                                            addressToSearch: elem.display_name,
                                                            suggestAddressEnabled: false,
                                                            results: []
                                                        });
                                                    }}>
                                                    {elem.display_name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="address-list">
                                    <div key={"0"} className='res-address'>
                                        Nessun indirizzo valido trovato...
                                    </div>
                                </div>
                            )
                        : null
                    }
                </div>
            </div>
        );
    }
};

export default MapSelectorManager;
