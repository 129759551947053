import React, { Component, Fragment } from 'react';
import {
    StyleSheet,
    PDFViewer,
    BlobProvider,
    Document,
    Page,
    View,
    Image,
    Text,
    Font
} from '@react-pdf/renderer';
import Button from 'reactstrap/lib/Button';
import axios from 'axios';
import { getOrientation } from "get-orientation";
import holdDataService from './../../../services/holdDataService/holdDataService';
import globalVars from './../../../globalVars.json';
import EdubbaLogo from './../../../assets/utils/images/banner_edubba.png';
import DatabencLogo from './../../../assets/utils/images/banner_databenc.png';
import EdubbaDefaultImage from './../../../assets/utils/images/edubba_placeholder.png';
import SignDatabencImage from './../../../assets/utils/images/certificates/timbro_distretto_databenc.png';
import SignPresidentImage from './../../../assets/utils/images/certificates/firma_diego_mascolo.png';
import OpenSans_Regular from './../../../assets/utils/fonts/opensans/OpenSans-Regular.ttf';
import OpenSans_Bold from './../../../assets/utils/fonts/opensans/OpenSans-Bold.ttf';

// Create fonts
const registerFont = () => {
    if (Font.getRegisteredFonts()["OpenSans"]) {
        return;
    }

    Font.register({
        family: 'OpenSans',
        fonts: [
            { src: OpenSans_Regular },
            { src: OpenSans_Bold, fontStyle: 'normal', fontWeight: 'bold' }
        ]
    });
};

// Create pctoPdfStyles
const pctoPdfStyles = StyleSheet.create({
    //----------------------------------------------------------| COMMON
    page: {
        padding: 20,
        flexDirection:   'column',
        backgroundColor: '#fff',
        fontFamily: 'OpenSans'
    },
    row: {
        flexDirection: 'row',
    },
    col: {
        flexDirection: 'column',
    },
    rotate90: {
        transform: 'rotate(90deg)'
    },
    rotate180: {
        transform: 'rotate(180deg)'
    },
    rotate270: {
        transform: 'rotate(270deg)'
    },
    //----------------------------------------------------------| FIRST PAGE
    pageEdubbaLogo: {
        flexDirection: 'column',
        textAlign: 'right',
        padding: '11px 10px 10px 10px',
        width: '30%',
        border: '1px solid #193d75',
        borderLeft: 0,
        margin: 0
    },
    pageUSRCenterLogo: {
        flexDirection: 'column',
        textAlign: 'center',
        padding: '32px 0 0 0',
        width: '40%',
        border: '1px solid #193d75',
        margin: 0
    },
    pageDatabencLogo: {
        flexDirection: 'column',
        textAlign: 'left',
        padding: 10,
        width: '30%',
        border: '1px solid #193d75',
        borderRight: 0,
        margin: 0
    },
    TitleLogo: {
        height: '100px',
        minHeight: '100px',
        maxHeight: '100px',
        objectFit: 'contain'
    },
    BannerTitleText: {
        textAlign: 'center',
        fontSize: '20px',
        textTransform: 'uppercase',
        fontFamily: 'OpenSans',
        fontWeight: 'bold',
        color: '#193d75'
    },
    TitleText: {
        flexDirection: 'column',
        textAlign: 'center',
        margin: '10px 0px 40px 0',
        padding: 10,
        width: '100%',
        backgroundColor: '#efefef',
        fontSize: '14px',
        textTransform: 'uppercase',
    },
    TitleSepText: {
        margin: '40px 0 40px 0',
        textAlign: 'center',
        padding: 6,
        width: '100%',
        backgroundColor: '#efefef',
        fontSize: '10px',
        fontFamily: 'OpenSans',
        fontWeight: 'bold',
        color: '#000',
        textTransform: 'uppercase',
    },
    SignBoldText: {
        textAlign: 'right',
        width: '100%',
        backgroundColor: '#fff',
        fontSize: '14px',
        fontFamily: 'OpenSans',
        fontWeight: 'bold',
        color: '#222',
    },
    SignNormalText: {
        textAlign: 'right',
        flexGrow: 1,
        backgroundColor: '#fff',
        fontSize: '12px',
        color: '#222'
    },
    NormalBoldText: {
        margin: '0 0 20px 0',
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#fff',
        fontSize: '12px',
        fontFamily: 'OpenSans',
        fontWeight: 'bold',
        color: '#222',
    },
    UpperText: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        color: '#000'
    },
    NormalText: {
        textAlign: 'left',
        flexGrow: 1,
        backgroundColor: '#fff',
        fontSize: '10px',
        color: '#222'
    },
    NormalTextR: {
        textAlign: 'left',
        flexGrow: 1,
        backgroundColor: '#fff',
        fontSize: '12px',
        lineHeight: '2px',
        color: '#222'
    },
    NormalTextLPadding: {
        textAlign: 'left',
        width: '100%',
        backgroundColor: '#fff',
        fontSize: '11px',
        lineHeight: '2px',
        color: '#222',
        padding: '0 0 0 25px'
    },
    TitleReportSepText: {
        margin: '0 0 3px 0',
        textAlign: 'center',
        padding: 6,
        width: '100%',
        backgroundColor: '#193d75',
        fontSize: '10px',
        fontFamily: 'OpenSans',
        fontWeight: 'bold',
        color: '#fff',
        textTransform: 'uppercase',
    },
    RowReportPreview: {
        padding: '6px',
        backgroundColor: '#f1f1f1',
        marginBottom: '3px'
    },
    ImageReportPreview: {
        height: '100px',
        minHeight: '100px',
        maxHeight: '100px',
        width: '100px',
        minWidth: '100px',
        maxWidth: '100px',
        borderRadius: '50%',
        objectFit: 'cover',
        border: '4px solid #fff',
        margin: '0 15px'
    },
    BodyReportPreview: {
        flexGrow: 1,
        flexDirection: 'column',
        padding: '6px',
        backgroundColor: '#fff',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    BodyReportPreviewSep: {
        height: '1px',
        minHeight: '1px',
        maxHeight: '1px',
        backgroundColor: '#f2f2f2',
        margin: '5px 0'
    },
    BodyReportPreviewBold: {
        textAlign: 'right',
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#193d75',
        marginBottom: '5px'
    },
    BodyReportPreviewNormal: {
        textAlign: 'right',
        fontSize: '12px'
    },
    SignDatabenc: {
        width: '140px',
        position: 'relative',
        marginTop: '-40px',
        marginLeft: '100px',
        objectFit: 'contain'
    },
    SignPresident: {
        width: '110px',
        position: 'relative',
        marginTop: '-5px',
        marginLeft: '140px',
        objectFit: 'contain'
    },
});

class ExportPctoPDF extends Component {
    // inlineView: Boolean
    // entityData: UUID

    constructor(props) {
        super(props);

        this.state = {
            currentData: null,
            inDelayedTime: true,

            //--------------------------------

            card_catalog: 0,
            card_enrich: 0,
            card_status: 0,
            project_card_catalog: 0,
            project_card_enrich: 0,
            arrCardsCatalog: [],
            arrCardsEnrich: [],
            arrCardsStatus: [],
            arrProjects: [],
            arrTPExtMaterials: [],
            renderedCardsCatalog: null,
            renderedCardsEnrich: null,
            renderedCardsStatus: null,
            renderedProjects: null,
            renderedExtMaterials: null
        };
    }

    componentDidMount() {
        registerFont();
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/stats/users/" + this.props.entityData, {
            auth: holdDataService.getAuthorization()
        }).then((res) => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentData: res.data.rows[0],
                    inDelayedTime: false    // REMOVE LATER
                }/*, () => this.calculateUserStats()*/);
            }
            else this.setState({
                currentData:   null,
                inDelayedTime: false
            });
        }, err =>{
            this.setState({
                currentData:   null,
                inDelayedTime: false
            });
        });
    }

    calculateUserStats = async () => {
        // Get stat card catalog
        const card_catalog = (
            this.state.currentData &&
            this.state.currentData.stat_data &&
            this.state.currentData.stat_data.cards &&
            this.state.currentData.stat_data.cards.catalog ?
            Object.keys(this.state.currentData.stat_data.cards.catalog).length :
            0
        );
        // Get stat card enrich
        const card_enrich = (
            this.state.currentData &&
            this.state.currentData.stat_data &&
            this.state.currentData.stat_data.cards &&
            this.state.currentData.stat_data.cards.enrich ?
            Object.keys(this.state.currentData.stat_data.cards.enrich).length :
            0
        );
        // Get stat card status
        const card_status = (
            this.state.currentData &&
            this.state.currentData.stat_data &&
            this.state.currentData.stat_data.cards &&
            this.state.currentData.stat_data.cards.status ?
            Object.keys(this.state.currentData.stat_data.cards.status).length :
            0
        );

        // Get stat details card
        const arrCardsCatalog = this.getListCards("catalog");
        const arrCardsEnrich  = this.getListCards("enrich");
        const arrCardsStatus  = this.getListCards("status");

        // Get stat details projects
        const arrProjects = this.getListProjects();

        // Get stat project card catalog
        const project_card_catalog = ((list) => {
            // Check data
            if (list.length > 0) {
                let count = 0;
                list.map((sitem) => {
                    if (sitem.catalog) {
                        count += Object.keys(sitem.catalog).length;
                    }
                    return null;
                });
                return count;
            }
            // Always zero
            else return 0;
        })(arrProjects);

        // Get stat project card enrich
        const project_card_enrich = ((list) => {
            // Check data
            if (list.length > 0) {
                let count = 0;
                list.map((sitem) => {
                    if (sitem.enrich) {
                        count += Object.keys(sitem.enrich).length;
                    }
                    return null;
                });
                return count;
            }
            // Always zero
            else return 0;
        })(arrProjects);

        // Get stat details third-parties materials
        const arrTPExtMaterials = this.getListThirdParties("ext_materials");

        // -----------------------------------| RENDER

        const funcHelpRenderer = async (arrData, titleLabel, withCounter = false) => (
            arrData.length > 0 ? await Promise.all(arrData.map(async (itemRecord, index) => {
                let imageUrl  = this.getCardImage(itemRecord.obj_image);
                let imageData = await axios({
                    url:          imageUrl,
                    crossDomain:  true,
                    responseType: "arraybuffer"
                });

                let imageExif = await getOrientation(
                    imageData.data
                ).catch((err) => {
                    console.error(err);
                    return 1;
                });

                return (
                    <View key={index} wrap={false} style={{...pctoPdfStyles.row, ...pctoPdfStyles.RowReportPreview}}>
                        <Image style={{
                            ...((exifOrientation) => {
                                switch (exifOrientation) {
                                    case 6: return pctoPdfStyles.rotate90;
                                    case 8: return pctoPdfStyles.rotate270;
                                    case 3: return pctoPdfStyles.rotate180;
                                    default: return {};
                                }
                            })(imageExif),
                            ...pctoPdfStyles.ImageReportPreview
                        }} src={imageUrl}></Image>
                        <View style={pctoPdfStyles.BodyReportPreview}>
                            <Text style={{...pctoPdfStyles.BodyReportPreviewBold, flexGrow: 1}}>{titleLabel}:</Text>
                            <Text style={{...pctoPdfStyles.BodyReportPreviewNormal, flexGrow: 1}}>{
                                itemRecord.obj_title ?
                                itemRecord.obj_title :
                                "Titolo/Nome non disponibile"
                            }</Text>
                            <Text style={pctoPdfStyles.BodyReportPreviewSep}> </Text>
                            <Text style={{...pctoPdfStyles.BodyReportPreviewBold, flexGrow: 1}}>{
                                withCounter ? "Informazioni Raccolte" : "Totale Ore Lavorate:"
                            }</Text>
                            <Text style={{...pctoPdfStyles.BodyReportPreviewNormal, flexGrow: 1}}>
                                {withCounter ? (
                                    "Ore Lavorate:  " + itemRecord.total_worked_time_str +
                                    ",  Schede:  "    + itemRecord.n_catalog +
                                    ",  Compiti:  "   + itemRecord.n_enrich
                                )
                                : itemRecord.total_worked_time_str}
                            </Text>
                        </View>
                    </View>
                );
            })) : (
                <Text style={{...pctoPdfStyles.NormalTextLPadding, margin: '15px 0 10px 0'}}>
                    Non sono presenti ore lavorate per questa categoria.
                </Text>
            )
        );

        // -----------------------------------| JSX DATA FROM RENDERER

        const renderedCardsCatalog = await funcHelpRenderer(arrCardsCatalog, "Titolo della Scheda");
        const renderedCardsEnrich  = await funcHelpRenderer(arrCardsEnrich, "Titolo dell'Arricchimento");
        const renderedCardsStatus  = await funcHelpRenderer(arrCardsStatus, "Nome dello Status");

        const renderedProjects     = await funcHelpRenderer(arrProjects, "Nome del Progetto", true);
        const renderedExtMaterials = await funcHelpRenderer(arrTPExtMaterials, "Nome del Materiale");

        // -----------------------------------| SAVE STATS

        this.setState({
            "card_catalog": card_catalog,
            "card_enrich": card_enrich,
            "card_status": card_status,
            "arrCardsCatalog": arrCardsCatalog,
            "arrCardsEnrich": arrCardsEnrich,
            "arrCardsStatus": arrCardsStatus,
            "arrProjects": arrProjects,
            "project_card_catalog": project_card_catalog,
            "project_card_enrich": project_card_enrich,
            "arrTPExtMaterials": arrTPExtMaterials,
            "renderedCardsCatalog": renderedCardsCatalog,
            "renderedCardsEnrich": renderedCardsEnrich,
            "renderedCardsStatus": renderedCardsStatus,
            "renderedProjects": renderedProjects,
            "renderedExtMaterials": renderedExtMaterials,
            inDelayedTime: false
        });
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardImage = (card_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(card_image)) {
            coreImage = card_image;
        }

        return coreImage;
    }

    // ------------------------------------------------------------------------------------| BODY PDF
    // {
    //     "user_uuid": "9de49a71-ca6e-4495-b6ce-c3cf819952f0",
    //     "user_name": "sorriento.anna2107@gmail.com",
    //     "user_profile": {
    //         "firstName": " Sorriento Anna",
    //         "lastName": " Sorriento Anna"
    //     },
    //     "full_name": " Sorriento Anna  Sorriento Anna",
    //     "user_date_create": "1617717008327",
    //     "user_is_enable": true,
    //     "user_disclaimer": true,
    //     "session_user_id": null,
    //     "total_worked_time": "627702",
    //     "total_worked_time_str": "00:10:27",
    //     "total_num_session": "1",
    //     "stat_data": {
    //         "total_worked_time": 627702,
    //         "total_worked_time_str": "00:10:27",
    //         "pcto": {
    //             "user_uuid": "9de49a71-ca6e-4495-b6ce-c3cf819952f0",
    //             "user_name": "sorriento.anna2107@gmail.com",
    //             "user_first_name": " Sorriento Anna",
    //             "user_last_name": " Sorriento Anna",
    //             "user_date_create": "06-04-2021",
    //             "user_is_enable": "true",
    //             "user_disclaimer": "true",
    //             "class_name": "Classe II C",
    //             "class_year": "2021/2022",
    //             "institute_name": "Liceo Classico Carducci - Nola (NA)",
    //             "user_num_groups": "1",
    //             "student_num_enrich_a": "---",
    //             "student_num_enrich_ma": "---",
    //             "student_num_enrich_oa": "---",
    //             "student_num_enrich_ra": "1",
    //             "student_num_enrich_modi": "---",
    //             "student_num_enrich_bib": "---",
    //             "student_num_enrich_eegn": "---",
    //             "student_num_tour_enrichs": "---",
    //             "student_num_sessions": "1",
    //             "student_total_worked_time": "627702",
    //             "student_total_worked_time_str": "00:10:27",
    //             "student_pcto_5h_all_status": "5",
    //             "student_pcto_6h_certificated": "0"
    //         },
    //         "lms": {
    //             "institutes": {
    //                 "4d3e5eb9-75c0-4287-9e8c-f90bb819c37a": {
    //                     "institute_name": "Liceo Classico Carducci - Nola (NA)",
    //                     "institute_address": null,
    //                     "classrooms": [
    //                         {
    //                             "class_uuid": "d68724ad-814c-4612-b304-d0fe020ef76e",
    //                             "class_name": "Classe II C",
    //                             "class_year": "2021/2022",
    //                             "class_is_current": true
    //                         }
    //                     ]
    //                 }
    //             },
    //             "groups": [
    //                 {
    //                     "group_uuid": "9c86d6c0-ab11-460f-9729-81ca5eca976d",
    //                     "group_date_create": "1617719501258",
    //                     "group_name": "GRUPPO 2 - IIC"
    //                 }
    //             ]
    //         }
    //     }
    // }

    fillValue = (data) => {
        if (typeof data === "string" &&
            data.trim() !== "---" &&
            data.trim().length > 0) {
            return data.trim();
        } else if (
            typeof data !== "string" &&
            !isNaN(data)
        ) {
            return data;
        }

        return "N.D.";
    }

    calcPctoHours = (free5h, totAll5h, totCert6h, totReview8h) => {
        try {
            // Convert to number
            const nFree5h = parseInt(free5h, 10);
            const nTot5h  = parseInt(totAll5h, 10);
            const nTot6h  = parseInt(totCert6h, 10);
            const nTot8h  = parseInt(totReview8h, 10);

            // Calc all base values
            const baseAllStatus = ((nTot5h >= 5) ? 5 : 0);
            const baseRevwHours = ((nTot8h >= 8) ? nTot8h : 0);
            const ownerTotHours = (baseAllStatus + nTot6h + baseRevwHours);

            // Calc final value
            const finalPcto = (baseAllStatus > 0) ? ownerTotHours : (nFree5h + baseRevwHours);

            // Return SUM
            return this.fillValue(finalPcto);
        }
        catch (err) {
            // ALWAYS ZERO
            return 0;
        }
    }

    calcTotalCards = (pctoData) => {
        try {
            // Calc total cards if not owner
            const nTotReviewd8h = parseInt(pctoData.student_pcto_8h_reviewed, 10);
            const nReviewdCards = (nTotReviewd8h >= 8) ? Math.round(nTotReviewd8h / 8) : 0;

            // Calc enrich type
            const student_num_enrich_a = (
                pctoData.student_num_enrich_a            &&
                pctoData.student_num_enrich_a !== "---"  &&
                pctoData.student_num_enrich_a !== "N.D." &&
                pctoData.student_num_enrich_a > 0
            ) ? parseInt(pctoData.student_num_enrich_a, 10) : 0;

            // Calc enrich type
            const student_num_enrich_ma = (
                pctoData.student_num_enrich_ma            &&
                pctoData.student_num_enrich_ma !== "---"  &&
                pctoData.student_num_enrich_ma !== "N.D." &&
                pctoData.student_num_enrich_ma > 0
            ) ? parseInt(pctoData.student_num_enrich_ma, 10) : 0;

            // Calc enrich type
            const student_num_enrich_oa = (
                pctoData.student_num_enrich_oa            &&
                pctoData.student_num_enrich_oa !== "---"  &&
                pctoData.student_num_enrich_oa !== "N.D." &&
                pctoData.student_num_enrich_oa > 0
            ) ? parseInt(pctoData.student_num_enrich_oa, 10) : 0;

            // Calc enrich type
            const student_num_enrich_ra = (
                pctoData.student_num_enrich_ra            &&
                pctoData.student_num_enrich_ra !== "---"  &&
                pctoData.student_num_enrich_ra !== "N.D." &&
                pctoData.student_num_enrich_ra > 0
            ) ? parseInt(pctoData.student_num_enrich_ra, 10) : 0;

            // Calc enrich type
            const student_num_enrich_modi = (
                pctoData.student_num_enrich_modi            &&
                pctoData.student_num_enrich_modi !== "---"  &&
                pctoData.student_num_enrich_modi !== "N.D." &&
                pctoData.student_num_enrich_modi > 0
            ) ? parseInt(pctoData.student_num_enrich_modi, 10) : 0;

            // Calc enrich type
            const student_num_enrich_bib = (
                pctoData.student_num_enrich_bib            &&
                pctoData.student_num_enrich_bib !== "---"  &&
                pctoData.student_num_enrich_bib !== "N.D." &&
                pctoData.student_num_enrich_bib > 0
            ) ? parseInt(pctoData.student_num_enrich_bib, 10) : 0;

            // Calc enrich type
            const student_num_enrich_eegn = (
                pctoData.student_num_enrich_eegn            &&
                pctoData.student_num_enrich_eegn !== "---"  &&
                pctoData.student_num_enrich_eegn !== "N.D." &&
                pctoData.student_num_enrich_eegn > 0
            ) ? parseInt(pctoData.student_num_enrich_eegn, 10) : 0;

            // Calculate totals
            const totEnrichValue = (
                student_num_enrich_a +
                student_num_enrich_ma +
                student_num_enrich_oa +
                student_num_enrich_ra +
                student_num_enrich_modi +
                student_num_enrich_bib +
                student_num_enrich_eegn
            );

            // Send back data
            return this.fillValue((totEnrichValue > 0) ? totEnrichValue : nReviewdCards);
        }
        catch (err) {
            // ALWAYS ZERO
            return 0;
        }
    }

    getDocumentCertPCTO = () =>{
        const PCTO = (
            this.state.currentData &&
            this.state.currentData.stat_data &&
            this.state.currentData.stat_data.pcto
        ) ? this.state.currentData.stat_data.pcto : {};

        return (
            <Document
                producer="Edubba Platform"
                creator="Edubba Platform"
                author="Edubba Platform"
                title={"certificate_user_id_" + this.props.entityData}>
                {/* PRIMA PAGINA */}
                <Page size="A4" style={pctoPdfStyles.page}>
                    <View style={pctoPdfStyles.row}>
                        <View style={pctoPdfStyles.pageDatabencLogo}>
                            <Image style={pctoPdfStyles.TitleLogo} src={DatabencLogo}></Image>
                        </View>
                        <View style={pctoPdfStyles.pageUSRCenterLogo}>
                            <Text style={pctoPdfStyles.BannerTitleText}>ATTESTATO DI</Text>
                            <Text style={pctoPdfStyles.BannerTitleText}>PARTECIPAZIONE</Text>
                        </View>
                        <View style={pctoPdfStyles.pageEdubbaLogo}>
                            <Image style={pctoPdfStyles.TitleLogo} src={EdubbaLogo}></Image>
                        </View>
                    </View>
                    <View style={{...pctoPdfStyles.row, marginTop: '30px'}}>
                        <View style={{flexGrow: 1, flexDirection: 'column'}}>
                            <Text style={pctoPdfStyles.NormalBoldText}>
                                Si attesta che l'alunno/a <Text style={pctoPdfStyles.UpperText}>{this.fillValue(this.state.currentData.full_name)}</Text>,
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                iscritto/a alla classe <Text style={pctoPdfStyles.UpperText}>{this.fillValue(PCTO.class_name)}</Text>
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                dell'Istituto scolastico <Text style={pctoPdfStyles.UpperText}>{this.fillValue(PCTO.institute_name)}</Text>
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                ha partecipato nell'anno scolastico <Text style={pctoPdfStyles.UpperText}>{this.fillValue(PCTO.class_year)}</Text>
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                ad un percorso per le competenze trasversali e per l'orientamento
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                presso la Piattaforma "Edubba – Progetto Databenc Art".
                            </Text>
                            <Text style={{...pctoPdfStyles.NormalText, marginTop: '20px'}}>
                                L'alunno/a ha maturato le seguenti:
                            </Text>
                            <Text style={{...pctoPdfStyles.NormalText, textDecoration: 'underline', marginBottom: '10px'}}>
                                Competenze tecnico specialistiche:
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di effettuare la ricerca bibliografica, iconografica e d'archivio, preliminare sul bene culturale individuato;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di reperire dati, informazioni e documentazione fotografica, grafica e scritta del bene culturale individuato per l'acquisizione dei dati tecnici (materie, misure, ecc.) e descrittivi;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di rielaborare i dati derivanti da ricerche bibliografiche e d'archivio in ordine alla produzione di testi e alla compilazione delle varie tipologie di schede di catalogo corredate da allegati grafici, iconografici, fotografici;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di gestire la versione informatica delle schede di catalogo, degli allegati grafici, iconografi e fotografici, in ragione delle caratteristiche della piattaforma sulla quale si sta operando.
                            </Text>
                            <Text style={{...pctoPdfStyles.NormalText, marginTop: '10px', textDecoration: 'underline', marginBottom: '10px'}}>
                                Competenze trasversali:
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di gestire efficacemente il tempo e le informazioni;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di imparare e di lavorare sia in modalità collaborativa sia in maniera autonoma;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di comunicare efficacemente in ambienti diversi;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di concentrarsi, di riflettere criticamente e di prendere decisioni;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di gestire il proprio apprendimento e la crescita professionale;
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                •  essere in grado di partecipare pienamente alla vita civica e sociale, attraverso la piena consapevolezza del patrimonio culturale e del suo valore, in una prospettiva di sviluppo sostenibile.
                            </Text>
                            <Text style={{...pctoPdfStyles.NormalText, marginTop: '20px'}}>
                                L'alunno/a ha completato N. <Text style={pctoPdfStyles.UpperText}>{this.calcTotalCards(PCTO)}</Text> schede catalogazione Beni Culturali
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                e ha realizzato N. <Text style={pctoPdfStyles.UpperText}>{this.fillValue(
                                    (
                                        PCTO.student_num_tour_enrichs            &&
                                        PCTO.student_num_tour_enrichs !== "---"  &&
                                        PCTO.student_num_tour_enrichs !== "N.D." &&
                                        PCTO.student_num_tour_enrichs > 0
                                    ) ? PCTO.student_num_tour_enrichs : 0
                                )}</Text> Tour
                            </Text>
                            <Text style={pctoPdfStyles.NormalText}>
                                per un totale di N. <Text style={pctoPdfStyles.UpperText}>{this.calcPctoHours(
                                    PCTO.student_pcto_learning_hours,
                                    PCTO.student_pcto_5h_all_status,
                                    PCTO.student_pcto_6h_certificated,
                                    PCTO.student_pcto_8h_reviewed
                                )}</Text> ore PCTO
                            </Text>
                            <Text style={{...pctoPdfStyles.NormalText, marginTop: '20px'}}>
                                L'alunno/a ha partecipato ad un percorso valido anche ai fini del raggiungimento del monte ore previsto per i "Percorsi per le competenze trasversali e per l'orientamento (PCTO)" ai sensi della legge n. 145/2018. Si rilascia il presente attestato in carta libera, per gli usi di Legge.
                            </Text>
                        </View>
                    </View>
                    <View style={pctoPdfStyles.row}>
                        <View style={{flexGrow: 1, paddingTop: '10px', flexDirection: 'column'}}>
                            <Text style={{...pctoPdfStyles.SignBoldText, paddingRight: '73px'}}>
                                Databenc SCARL
                            </Text>
                            <Text style={{...pctoPdfStyles.SignBoldText, paddingRight: '60px'}}>
                                Il Presidente del CdA
                            </Text>
                            <Text style={{...pctoPdfStyles.SignNormalText, paddingRight: '72px'}}>
                                Dott. Diego Mascolo
                            </Text>
                        </View>
                    </View>
                    <View style={pctoPdfStyles.row}>
                        <Image style={pctoPdfStyles.SignDatabenc} src={SignDatabencImage}></Image>
                        <Image style={pctoPdfStyles.SignPresident} src={SignPresidentImage}></Image>
                    </View>
                </Page>
            </Document>
        );
    }

    getListCards(type) {
        // Check data
        if (this.state.currentData &&
            this.state.currentData.stat_data &&
            this.state.currentData.stat_data.cards &&
            this.state.currentData.stat_data.cards[type]) {
            const keys = Object.keys(
                this.state.currentData.stat_data.cards[type]
            );
            // clone object arr
            const data = this.state.currentData.stat_data.cards;

            // Send back
            return keys.map((item) => {
                return {
                    "obj_uuid": item,
                    ...data[type][item]
                }
            });
        }
        // Send empty
        else return [];
    }

    getListProjects() {
        // Check data
        if (this.state.currentData &&
            this.state.currentData.stat_data &&
            this.state.currentData.stat_data.projects) {
            const keys = Object.keys(
                this.state.currentData.stat_data.projects
            );
            // clone object arr
            const data = this.state.currentData.stat_data.projects;

            // Send back
            return keys.map((item) => {
                return {
                    "obj_uuid": item,
                    "n_catalog": (
                        data[item].catalog ?
                        Object.keys(data[item].catalog).length
                        : 0
                    ),
                    "n_enrich": (
                        data[item].enrich ?
                        Object.keys(data[item].enrich).length
                        : 0
                    ),
                    ...data[item]
                }
            });
        }
        // Send empty
        else return [];
    }

    getListThirdParties(type) {
        // Check data
        if (this.state.currentData &&
            this.state.currentData.stat_data &&
            this.state.currentData.stat_data["third-parties"] &&
            this.state.currentData.stat_data["third-parties"][type]) {
            const keys = Object.keys(
                this.state.currentData.stat_data["third-parties"][type]
            );
            // clone object arr
            const data = this.state.currentData.stat_data["third-parties"];

            // Send back
            return keys.map((item) => {
                return {
                    "obj_uuid": item,
                    ...data[type][item]
                }
            });
        }
        // Send empty
        else return [];
    }

    getDocumentReportPCTO = () => {
        return (
            <Document
                producer="Edubba Platform"
                creator="Edubba Platform"
                author="Edubba Platform"
                title={"report_user_id_" + this.props.entityData}>
                {/* PRIMA PAGINA */}
                <Page size="A4" style={pctoPdfStyles.page}>
                    <View style={pctoPdfStyles.row}>
                        <View style={pctoPdfStyles.pageDatabencLogo}>
                            <Image style={pctoPdfStyles.TitleLogo} src={DatabencLogo}></Image>
                        </View>
                        <View style={pctoPdfStyles.pageUSRCenterLogo}>
                            <Text style={pctoPdfStyles.BannerTitleText}>ATTESTATO DI</Text>
                            <Text style={pctoPdfStyles.BannerTitleText}>PARTECIPAZIONE</Text>
                        </View>
                        <View style={pctoPdfStyles.pageEdubbaLogo}>
                            <Image style={pctoPdfStyles.TitleLogo} src={EdubbaLogo}></Image>
                        </View>
                    </View>
                    <View wrap={false} style={pctoPdfStyles.row}>
                        <Text style={{...pctoPdfStyles.TitleReportSepText, marginBottom: '40px'}}>SINTESI DEI LAVORI SVOLTI</Text>
                    </View>
                    <View style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.NormalTextLPadding}>
                            - Numero di Schede a Catalogo:  <Text style={pctoPdfStyles.UpperText}>{this.fillValue(this.state.card_catalog)}</Text>
                        </Text>
                    </View>
                    <View style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.NormalTextLPadding}>
                            - Numero di Schede in Arricchimento:  <Text style={pctoPdfStyles.UpperText}>{this.fillValue(this.state.card_enrich)}</Text>
                        </Text>
                    </View>
                    <View style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.NormalTextLPadding}>
                            - Numero di Stati di Lavorazione:  <Text style={pctoPdfStyles.UpperText}>{this.fillValue(this.state.card_status)}</Text>
                        </Text>
                    </View>
                    <View style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.NormalTextLPadding}>
                            - Numero di Progetto a Catalogo:  <Text style={pctoPdfStyles.UpperText}>{this.fillValue(this.state.project_card_catalog)}</Text>
                        </Text>
                    </View>
                    <View style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.NormalTextLPadding}>
                            - Numero di Progetto in Arricchimento:  <Text style={pctoPdfStyles.UpperText}>{this.fillValue(this.state.project_card_enrich)}</Text>
                        </Text>
                    </View>
                    <View wrap={false} style={pctoPdfStyles.row}>
                        <Text style={{...pctoPdfStyles.TitleReportSepText, marginTop: '40px'}}>Riepilogo per Scheda di Catalogo</Text>
                    </View>
                    {this.state.renderedCardsCatalog}
                    <View wrap={false} style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.TitleReportSepText}>Riepilogo per Scheda in Arricchimento</Text>
                    </View>
                    {this.state.renderedCardsEnrich}
                    <View wrap={false} style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.TitleReportSepText}>Riepilogo per Status della Scheda</Text>
                    </View>
                    {this.state.renderedCardsStatus}
                    <View wrap={false} style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.TitleReportSepText}>Riepilogo per Progetto</Text>
                    </View>
                    {this.state.renderedProjects}
                    <View wrap={false} style={pctoPdfStyles.row}>
                        <Text style={pctoPdfStyles.TitleReportSepText}>Riepilogo per Materiale Pubblico</Text>
                    </View>
                    {this.state.renderedExtMaterials}
                </Page>
            </Document>
        );
    }

    render() {
        // Hack fix
        if (this.state.inDelayedTime) {
            return <Button color="secondary" block disabled>Generazione in corso...</Button>;
        }

        // Normal statement
        return this.props.inlineView ? (
            <PDFViewer style={{
                width: "100%",
                heigth: "100%",
                minHeight: "300px"
            }} children={this.getDocumentCertPCTO()} />
        ) : (
            <Fragment>
                <BlobProvider document={this.getDocumentCertPCTO()}>
                    {({ blob, url /*, loading, error*/ }) =>
                        !(blob && url) ? (
                            <Button color="secondary" block disabled>Generazione in corso...</Button>
                        ) : (
                            <a href={url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                <Button color="secondary" block>Scarica il PDF:  "Attestato di Frequenza"</Button>
                            </a>
                        )
                    }
                </BlobProvider>{/*
                <hr style={{margin: '6px 0'}} />
                <BlobProvider document={this.getDocumentReportPCTO()}>
                    {({ blob, url }) =>
                        !(blob && url) ? (
                            <Button color="secondary" block disabled>Generazione in corso...</Button>
                        ) : (
                            <a href={url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                <Button color="secondary" block>Scarica il PDF:  "Report delle Attività"</Button>
                            </a>
                        )
                    }
                </BlobProvider>*/}
            </Fragment>
        );
    }
};

export default ExportPctoPDF;
