import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import EdubbaLoader from '../../../components/EdubbaLoader/EdubbaLoader';
import holdDataService from '../../../services/holdDataService/holdDataService';
import {
    Row,
    Col,
    Container,
    Table,
    Alert,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';
import moment from 'moment';
import EdubbaDefaultImage from './../../../assets/utils/images/edubba_placeholder.png';
import EdubbaDefaultPrjImage from './../../../assets/utils/images/template_visibility_image.png';
import globalVars from './../../../globalVars.json';

import './UserStatsDetail.scss';

class UserStatsDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maskLoading: false,
            currentStats: []
        };
    }

    componentDidMount() {
        let userUUID = this.props.match.params.user_uuid;
        let setTitle = 'Dashboard - Statistiche Utente';
        this.props.setSectionTitle(setTitle);

        this.setState({ maskLoading: true });
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/stats/users/" + userUUID, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    currentStats: res.data.rows[0],
                    maskLoading: false
                });
            }
            else this.setState({
                currentStats: [],
                maskLoading: false
            });
        }, err => {
            this.setState({
                currentStats: [],
                maskLoading: false
            });
        });
    }

    getListCards(type) {
        // Check data
        if (this.state.currentStats &&
            this.state.currentStats.stat_data &&
            this.state.currentStats.stat_data.cards &&
            this.state.currentStats.stat_data.cards[type]) {
            const keys = Object.keys(
                this.state.currentStats.stat_data.cards[type]
            );
            // clone object arr
            const data = this.state.currentStats.stat_data.cards;

            // Send back
            return keys.map((item) => {
                return {
                    "obj_uuid": item,
                    ...data[type][item]
                }
            });
        }
        // Send empty
        else return [];
    }

    getListProjects() {
        // Check data
        if (this.state.currentStats &&
            this.state.currentStats.stat_data &&
            this.state.currentStats.stat_data.projects) {
            const keys = Object.keys(
                this.state.currentStats.stat_data.projects
            );
            // clone object arr
            const data = this.state.currentStats.stat_data.projects;

            // Send back
            return keys.map((item) => {
                return {
                    "obj_uuid": item,
                    "n_catalog": (
                        data[item].catalog ?
                        Object.keys(data[item].catalog).length
                        : 0
                    ),
                    "n_enrich": (
                        data[item].enrich ?
                        Object.keys(data[item].enrich).length
                        : 0
                    ),
                    ...data[item]
                }
            });
        }
        // Send empty
        else return [];
    }

    getListThirdParties(type) {
        // Check data
        if (this.state.currentStats &&
            this.state.currentStats.stat_data &&
            this.state.currentStats.stat_data["third-parties"] &&
            this.state.currentStats.stat_data["third-parties"][type]) {
            const keys = Object.keys(
                this.state.currentStats.stat_data["third-parties"][type]
            );
            // clone object arr
            const data = this.state.currentStats.stat_data["third-parties"];

            // Send back
            return keys.map((item) => {
                return {
                    "obj_uuid": item,
                    ...data[type][item]
                }
            });
        }
        // Send empty
        else return [];
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardImage = (card_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(card_image)) {
            coreImage = card_image;
        }

        return coreImage;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    render() {
        // Get stat card catalog
        const card_catalog = (
            this.state.currentStats &&
            this.state.currentStats.stat_data &&
            this.state.currentStats.stat_data.cards &&
            this.state.currentStats.stat_data.cards.catalog ?
            Object.keys(this.state.currentStats.stat_data.cards.catalog).length :
            0
        );
        // Get stat card enrich
        const card_enrich = (
            this.state.currentStats &&
            this.state.currentStats.stat_data &&
            this.state.currentStats.stat_data.cards &&
            this.state.currentStats.stat_data.cards.enrich ?
            Object.keys(this.state.currentStats.stat_data.cards.enrich).length :
            0
        );
        // Get stat card status
        const card_status = (
            this.state.currentStats &&
            this.state.currentStats.stat_data &&
            this.state.currentStats.stat_data.cards &&
            this.state.currentStats.stat_data.cards.status ?
            Object.keys(this.state.currentStats.stat_data.cards.status).length :
            0
        );

        // Get stat details card
        const arrCardsCatalog = this.getListCards("catalog");
        const arrCardsEnrich  = this.getListCards("enrich");
        const arrCardsStatus  = this.getListCards("status");

        // Get stat details projects
        const arrProjects = this.getListProjects();

        // Get stat project card catalog
        const project_card_catalog = ((list) => {
            // Check data
            if (list.length > 0) {
                let count = 0;
                list.map((sitem) => {
                    if (sitem.catalog) {
                        count += Object.keys(sitem.catalog).length;
                    }
                    return null;
                });
                return count;
            }
            // Always zero
            else return 0;
        })(arrProjects);

        // Get stat project card enrich
        const project_card_enrich = ((list) => {
            // Check data
            if (list.length > 0) {
                let count = 0;
                list.map((sitem) => {
                    if (sitem.enrich) {
                        count += Object.keys(sitem.enrich).length;
                    }
                    return null;
                });
                return count;
            }
            // Always zero
            else return 0;
        })(arrProjects);

        // Get stat details third-parties materials
        const arrTPExtMaterials = this.getListThirdParties("ext_materials");

        // Render
        return (
            <Container className='ToolStats'>
                {
                    this.state.maskLoading ?
                        <EdubbaLoader />
                    : this.state.currentStats ?
                        <Fragment>
                            <Row key="head-stat-1">
                                <Col lg='12' md='12' sm='12' className="HeadStat">
                                    Statistiche Utente:
                                </Col>
                            </Row>
                            <Row key="body-stat-1">
                                <Col lg='12' md='12' sm='12'>
                                    <Table className="ToolStats-Table" responsive striped>
                                        <thead>
                                            <tr>
                                                <th>Nome Utente</th>
                                                <th>Email Utente</th>
                                                <th>UUID Utente</th>
                                                <th>Data Creazione</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.full_name ?
                                                    this.state.currentStats.full_name :
                                                    "- - -"
                                                }</td>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.user_name ?
                                                    this.state.currentStats.user_name :
                                                    "- - -"
                                                }</td>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.user_uuid !== null ?
                                                    this.state.currentStats.user_uuid :
                                                    "- - -"
                                                }</td>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.user_date_create !== null ?
                                                    moment(
                                                        parseInt(this.state.currentStats.user_date_create, 10)
                                                    ).format('DD/MM/YY H:m:s') :
                                                    "- - -"
                                                }</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th>Totale Sessioni</th>
                                                <th>Totale Lavorato</th>
                                                <th>Utente Abilitato</th>
                                                <th>Accettazione Policy</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.total_num_session ?
                                                    this.state.currentStats.total_num_session :
                                                    0
                                                }</td>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.total_worked_time_str ?
                                                    this.state.currentStats.total_worked_time_str :
                                                    "00:00:00"
                                                }</td>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.user_is_enable ?
                                                    "Abilitato" :
                                                    "Non Abilitato"
                                                }</td>
                                                <td style={{width: "25%"}}>{
                                                    this.state.currentStats &&
                                                    this.state.currentStats.user_disclaimer ?
                                                    "Accettato" :
                                                    "Rifiutato"
                                                }</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row key="head-stat-2">
                                <Col lg='12' md='12' sm='12' className="HeadStat">
                                    Quantità Lavorate:
                                </Col>
                            </Row>
                            <Row key="body-stat-2">
                                <Col lg='12' md='12' sm='12'>
                                    <Table className="ToolStats-Table" responsive striped>
                                        <thead>
                                            <tr>
                                                <th>Catalogo Schede</th>
                                                <th>Enrichment Schede</th>
                                                <th>Stati di Lavorazione</th>
                                                <th>Catalogo di Progetto</th>
                                                <th>Enrichment di Progetto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "20%"}}>{
                                                    card_catalog
                                                }</td>
                                                <td style={{width: "20%"}}>{
                                                    card_enrich
                                                }</td>
                                                <td style={{width: "20%"}}>{
                                                    card_status
                                                }</td>
                                                <td style={{width: "20%"}}>{
                                                    project_card_catalog
                                                }</td>
                                                <td style={{width: "20%"}}>{
                                                    project_card_enrich
                                                }</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th style={{width: "100%"}} colSpan="5">Dettaglio per Schede:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "100%"}} colSpan="5">
                                                    <div className="head-object-grid">
                                                        Lavorato per Catalogo:
                                                    </div>
                                                    <div className="list-object-grid">{
                                                        arrCardsCatalog.length > 0 ?
                                                        arrCardsCatalog.map((itemCard) => {
                                                            return (
                                                                <Card key={itemCard.obj_uuid}>
                                                                    <CardImg
                                                                        top
                                                                        width="100%"
                                                                        src={
                                                                            this.getCardImage(itemCard.obj_image)
                                                                        }
                                                                        onError={this.setDefaultImgSrc}
                                                                    />
                                                                    <CardBody>
                                                                        <CardTitle>{
                                                                            itemCard.obj_title ?
                                                                            itemCard.obj_title :
                                                                            "Titolo non disponibile"
                                                                        }</CardTitle>
                                                                        <CardText>
                                                                            <small className="text-muted">
                                                                                <b>Ore Lavorate:</b> {itemCard.total_worked_time_str}
                                                                            </small>
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            );
                                                        })
                                                        : "Non sono presenti ore lavorate per questa categoria."
                                                    }</div>
                                                    <br />
                                                    <div className="head-object-grid">
                                                        Lavorato per Enrichment:
                                                    </div>
                                                    <div className="list-object-grid">{
                                                        arrCardsEnrich.length > 0 ?
                                                        arrCardsEnrich.map((itemCard) => {
                                                            return (
                                                                <Card key={itemCard.obj_uuid}>
                                                                    <CardImg
                                                                        top
                                                                        width="100%"
                                                                        src={
                                                                            this.getCardImage(itemCard.obj_image)
                                                                        }
                                                                        onError={this.setDefaultImgSrc}
                                                                    />
                                                                    <CardBody>
                                                                        <CardTitle>{
                                                                            itemCard.obj_title ?
                                                                            itemCard.obj_title :
                                                                            "Titolo non disponibile"
                                                                        }</CardTitle>
                                                                        <CardText>
                                                                            <small className="text-muted">
                                                                                <b>Ore Lavorate:</b> {itemCard.total_worked_time_str}
                                                                            </small>
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            );
                                                        })
                                                        : "Non sono presenti ore lavorate per questa categoria."
                                                    }</div>
                                                    <br />
                                                    <div className="head-object-grid">
                                                        Lavorato per Stato Scheda:
                                                    </div>
                                                    <div className="list-object-grid">{
                                                        arrCardsStatus.length > 0 ?
                                                        arrCardsStatus.map((itemCard) => {
                                                            return (
                                                                <Card key={itemCard.obj_uuid}>
                                                                    <CardImg
                                                                        top
                                                                        width="100%"
                                                                        src={EdubbaDefaultPrjImage}
                                                                        onError={this.setDefaultImgSrc}
                                                                    />
                                                                    <CardBody>
                                                                        <CardTitle>{
                                                                            itemCard.obj_title ?
                                                                            itemCard.obj_title :
                                                                            "Titolo non disponibile"
                                                                        }</CardTitle>
                                                                        <CardText>
                                                                            <small className="text-muted">
                                                                                <b>Ore Lavorate:</b> {itemCard.total_worked_time_str}
                                                                            </small>
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            );
                                                        })
                                                        : ("Non sono presenti ore lavorate per questa categoria.")
                                                    }</div>
                                                    <br />
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th style={{width: "100%"}} colSpan="5">Dettaglio per Progetto:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "100%"}} colSpan="5">
                                                    <div className="list-object-grid">{
                                                        arrProjects.length > 0 ?
                                                        arrProjects.map((itemPrj) => {
                                                            return (
                                                                <Card key={itemPrj.obj_uuid}>
                                                                    <CardImg
                                                                        top
                                                                        width="100%"
                                                                        src={EdubbaDefaultPrjImage}
                                                                        onError={this.setDefaultImgSrc}
                                                                    />
                                                                    <CardBody>
                                                                        <CardTitle>{
                                                                            itemPrj.obj_title ?
                                                                            itemPrj.obj_title :
                                                                            "Titolo non disponibile"
                                                                        }</CardTitle>
                                                                        <CardText>
                                                                            <small className="text-muted">
                                                                                <b>Ore Lavorate:</b> {itemPrj.total_worked_time_str}
                                                                            </small>
                                                                            <br />
                                                                            <small className="text-muted">
                                                                                <b>Schede di Catalogo:</b> {itemPrj.n_catalog}
                                                                            </small>
                                                                            <br />
                                                                            <small className="text-muted">
                                                                                <b>Schede di Enrich:</b> {itemPrj.n_enrich}
                                                                            </small>
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            );
                                                        })
                                                        : "Non sono presenti ore lavorate per questa categoria."
                                                    }</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th style={{width: "100%"}} colSpan="5">Dettaglio per Materiale Pubblico:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{width: "100%"}} colSpan="5">
                                                    <div className="list-object-grid">{
                                                        arrTPExtMaterials.length > 0 ?
                                                        arrTPExtMaterials.map((itemPrj) => {
                                                            return (
                                                                <Card key={itemPrj.obj_uuid}>
                                                                    <CardImg
                                                                        top
                                                                        width="100%"
                                                                        src={EdubbaDefaultPrjImage}
                                                                        onError={this.setDefaultImgSrc}
                                                                    />
                                                                    <CardBody>
                                                                        <CardTitle>{
                                                                            itemPrj.obj_title ?
                                                                            itemPrj.obj_title :
                                                                            "Titolo non disponibile"
                                                                        }</CardTitle>
                                                                        <CardText>
                                                                            <small className="text-muted">
                                                                                <b>Ore Lavorate:</b> {itemPrj.total_worked_time_str}
                                                                            </small>
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            );
                                                        })
                                                        : "Non sono presenti ore lavorate per questa categoria."
                                                    }</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Fragment>
                    : (
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <Alert color="light" className="mt-3">
                                    <h4 className="alert-heading">Nessuna statistica trovata!</h4>
                                    <p>
                                        Attenzione l'utenza potrebbe non essere attiva o non essersi ancora autenticata.<br />
                                        Al primo accesso in piattaforma con attività utile l'utenza risulterà visibile.
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        );
    }
};

export default withRouter(UserStatsDetail);
