import React, { Component } from 'react';
import {
    StyleSheet,
    PDFViewer,
    BlobProvider,
    Document,
    Page,
    View,
    Image,
    Text,
    Svg,
    G,
    Polygon,
    Path,
    Link
} from '@react-pdf/renderer';
import { getOrientation } from "get-orientation";
import moment from 'moment';
import Button from 'reactstrap/lib/Button';
import axios from 'axios';
import JSTemplateManager from './../../services/templateManager/JSTemplateManager';
import EdubbaDefaultImage from './../../assets/utils/images/edubba_placeholder.png';
import holdDataService from './../../services/holdDataService/holdDataService';
import globalVars from './../../globalVars.json';

// Create cardPdfStyles
const cardPdfStyles = StyleSheet.create({
    //----------------------------------------------------------| COMMON
    page: {
        padding: 20,
        flexDirection:   'column',
        backgroundColor: '#193d75'
    },
    row: {
        flexDirection: 'row',
    },
    rotate90: {
        transform: 'rotate(90deg)'
    },
    rotate180: {
        transform: 'rotate(180deg)'
    },
    rotate270: {
        transform: 'rotate(270deg)'
    },
    //----------------------------------------------------------| FIRST PAGE
    pageLogo: {
        padding: 10,
        width: '30%'
    },
    pageTitle: {
        margin: '0px 0px 0px 20px',
        padding: 10,
        flexGrow: 1,
        backgroundColor: '#fff',
        color: '#222',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px'
    },
    titleCap: {
        marginBottom: '6px',
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#193d75'
    },
    titleSep: {
        fontSize: '14px',
        borderBottom: '2px solid #193d75',
        paddingBottom: '10px',
        marginBottom: '10px'
    },
    titleSkipSep: {
        fontSize: '14px'
    },
    cardStatus: {
        flexGrow: 1,
        margin: '20px 0px',
        padding: 10,
        backgroundColor: '#ffad00',
        color: '#222',
        fontSize: '14px',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px'
    },
    rowImage: {
        flexGrow: 1,
        flexDirection: 'row',
        backgroundColor: '#fff',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        margin: 0,
        padding: 4,
    },
    cardImage: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        width: '550px',
        minHeight: '550px',
        maxHeight: '550px',
        borderRadius: '13px',
        objectFit: 'cover',
        overflow: 'hidden'
    },
    //----------------------------------------------------------| PROJECT
    projectBodyContent: {
        margin: '0px 0px 20px 0px',
        padding: 10,
        flexGrow: 1,
        backgroundColor: '#fff',
        color: '#222',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px'
    },
    projectTitle: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#193d75'
    },
    projectDataSep: {
        marginTop: '6px',
        fontSize: '12px',
        color: '#333',
        borderBottom: '2px solid #193d75',
        paddingBottom: '10px',
        marginBottom: '10px'
    },
    projectDataNoSep: {
        marginTop: '6px',
        fontSize: '12px',
        color: '#333',
    },
    //----------------------------------------------------------| CARD
    rowCardContent: {
        flexDirection: 'column',
        padding: 0,
    },
    titleCardContent: {
        flexDirection: 'column',
        padding: 10,
        color: '#222',
        backgroundColor: '#ffad00',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        width: '100%',
        fontSize: '14px',
        marginBottom: '20px',
    },
    bodyCardContent: {
        margin: 0,
        padding: '0 10px 10px 10px',
        overflow: 'hidden',
        width: '100%',
        backgroundColor: '#fff',
        color: '#333',
        fontSize: '14px',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px'
    },
    TemplateFieldGroup: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#fff',
        color: '#333',
        fontSize: '14px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    TemplateFieldGroupOdd: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#f2f2f2',
        color: '#333',
        fontSize: '14px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    SectionHeader: {
        backgroundColor: '#fff',
        color: '#333',
        fontSize: '14px',
    },
    SectionHeaderOdd: {
        backgroundColor: '#f2f2f2',
        color: '#333',
        fontSize: '14px',
    },
    SectionName: {
        fontSize: '10px',
        fontWeight: 'bold',
        color: '#193d75',
        textTransform: 'capitalize'
    },
    /*SectionBody: {
        fontSize: '14px',
    },
    SectionMultiEffect: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#fff',
        color: '#333',
        fontSize: '14px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    SectionMultiEffectOdd: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#f2f2f2',
        color: '#333',
        fontSize: '14px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    FieldRow: {
        fontSize: '14px',
    },
    FieldRowOdd: {
        fontSize: '14px',
    },*/
    FieldTextData: {
        marginTop: '6px',
        fontSize: '12px',
        color: '#333',
    }
});

// Prepare storage
let ctxTemplateManager;

class CardExportPDF extends Component {
    // inlineView: Boolean
    // entityData: Object
    // entityType: String

    constructor(props) {
        super(props);

        this.state = {
            core: null,
            customTemplate: null,
            formData: null,
            visibilityData: null,
            headersToRender: [],
            templateToRender: null,
            pfcFetchedComments: [],
            inDelayedTime: true,

            //-------------------------------------| EXIF
            // {
            //     TOP_LEFT = 1,         // Horizontal (Default)
            //     TOP_RIGHT = 2,        // Mirror Horizontal
            //     BOTTOM_RIGHT  = 3,    // Rotate 180
            //     BOTTOM_LEFT = 4,      // Mirror vertical
            //     LEFT_TOP = 5,         // Mirror horizontal and rotate 270 CW
            //     RIGHT_TOP = 6,        // Rotate 90 CW
            //     RIGHT_BOTTOM = 7,     // Mirror horizontal and rotate 90 CW
            //     LEFT_BOTTOM = 8,      // Rotate 270 CW
            // }
            imageOrientation: 1,
            imageFirstPage:   EdubbaDefaultImage
        };
    }

    componentDidMount() {
        this.getStagingCard(
            this.props.entityData.card_uuid,
            this.props.entityData.enrich_uuid
        );
    }

    // ------------------------------------------------------------------------------------| HELPER PDF

    getUserFullName = (userInfo) => {
        return (
            userInfo                        &&
            userInfo.user_profile           &&
            userInfo.user_profile.firstName &&
            userInfo.user_profile.lastName
        ) ? (
            userInfo.user_profile.firstName + " " +
            userInfo.user_profile.lastName
        ) : "Non disponibile...";
    }

    // ------------------------------------------------------------------------------------| RENDERING CARD

    getStagingCard(card_uuid, enrich_uuid) {
        // GET ENRICHMENT CARD DATA
        let query = "/cards/" + card_uuid + "/enrichments/" + enrich_uuid;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(enrich_res => {
            let cardEnrichmentRes = enrich_res.data && enrich_res.data.rowCount > 0 ? enrich_res.data.rows[0] : {};
            let assignUuid = cardEnrichmentRes.assign_uuid;
            let visibilityUuid = Array.isArray(cardEnrichmentRes.template_visibilities) ? ((visibilities, assignUuid) => {
                for (let i = 0; i < visibilities.length; i++) {
                    if (visibilities[i].assign_uuid === assignUuid) {
                        return visibilities[i].visibility_uuid;
                    }
                }
                return false;
            })(cardEnrichmentRes.template_visibilities, assignUuid) : false;

            // Query
            let query = '/templates/' + cardEnrichmentRes.template_uuid;
            if (visibilityUuid) {
                query += '/assignments/' + assignUuid + '/visibilities/' + visibilityUuid;
            }

            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                auth: holdDataService.getAuthorization()
            }).then(template_res => {
                let core;
                if (template_res.data.rowCount > 0) {
                    core = template_res.data.rows[0];
                }

                ctxTemplateManager = new JSTemplateManager();

                // Load template into manager
                ctxTemplateManager.parseTemplateJSON(core["template_data"]);

                // Get header from manager
                let headers = ctxTemplateManager.getAllHeaders();

                // Get processed template
                let processedTemplate = ctxTemplateManager.getProcessedTemplate();

                // Query
                let query = "/cards/" + card_uuid + "/enrichments/" + enrich_uuid + "/reviews";
                axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                    auth: holdDataService.getAuthorization()
                }).then(reviews_res => {
                    let reviews = reviews_res.data && reviews_res.data.rowCount > 0 ? reviews_res.data.rows : [];
                    let pfcFetchedComments = {};

                    // Cicla per ogni user review
                    for (let i = 0; i < reviews.length; i++) {
                        let userReview = reviews[i];
                        // Controlla che per ogni user review esistano commenti
                        if (userReview.review_data && Object.keys(userReview.review_data).length > 0) {
                            // Cicla per ogni field
                            for (let j = 0; j < Object.keys(userReview.review_data).length; j++) {
                                let currentField_srvid = Object.keys(userReview.review_data)[j];
                                // Aggiunge i dati dell'owner
                                let currentComment = userReview.review_data[currentField_srvid] ? userReview.review_data[currentField_srvid].map(obj => {
                                    obj.comment_owner = userReview.review_owner;
                                    return obj;
                                }) : [];

                                //
                                if (pfcFetchedComments[currentField_srvid]) {
                                    pfcFetchedComments[currentField_srvid] = [
                                        ...pfcFetchedComments[currentField_srvid],
                                        ...currentComment
                                    ];
                                }
                                else {
                                    pfcFetchedComments[currentField_srvid] = currentComment;
                                }
                            }
                        }
                    }

                    // Sort Comments
                    for (let i = 0; i < Object.keys(pfcFetchedComments).length; i++) {
                        let fieldComments = pfcFetchedComments[Object.keys(pfcFetchedComments)[i]];
                        fieldComments.sort((a, b) => {
                            if (a.comment_date_create < b.comment_date_create) {
                                return -1;
                            }
                            return 1;
                        });
                    }

                    this.setState({
                        core: core,
                        customTemplate: core["template_data"]["core_data"],
                        formData: cardEnrichmentRes.enrich_data ? cardEnrichmentRes.enrich_data : {},

                        //-------------------------------------------------| NEW

                        headersToRender: headers,
                        templateToRender: processedTemplate,
                        pfcFetchedComments: pfcFetchedComments
                    }, () => {
                        // Hack fix
                        setTimeout(async () => {
                            if (this.props.entityData.card_image) {
                                let imageUrl  = this.props.entityData.card_image;
                                let imageData = await axios({
                                    url:          imageUrl,
                                    crossDomain:  true,
                                    responseType: "arraybuffer"
                                });
                                let imageExif = await getOrientation(
                                    imageData.data
                                ).catch((err) => {
                                    console.error(err);
                                    return 1;
                                });
                                this.setState({
                                    imageFirstPage:   imageUrl,
                                    imageOrientation: imageExif,
                                    inDelayedTime:    false
                                });
                            } else {
                                this.setState({
                                    inDelayedTime: false
                                });
                            }
                        }, 1000);
                    });
                });
            }, err => {
                // Hack fix
                setTimeout(() => {
                    this.setState({
                        inDelayedTime: false
                    });
                }, 1000);
            });
        });
    }

    getTabBodiesHTML = () => {
        // Check data
        if (!(Array.isArray(this.state.headersToRender) &&
            this.state.headersToRender.length > 0)) {
            return null;
        }

        // Render section of tab
        return this.state.headersToRender.map((header) => {
            // Render header tree
            return this.getTabBodiesHTML_helper(
                header["field-edusn-srvid"],
                this.state.formData
            );
        });
    }

    getTabBodiesHTML_helper = (node, nodeData, nodePath = "", nodeIndex = 0) => {
        // Prepare data
        let curSectionNode = ctxTemplateManager.getTemplateSection(node);
        let curSectionMask = ctxTemplateManager.getVisibilitySection(node);

        // Prepare component data
        let pathSep = ".";
        let isMulti = false;
        let nChilds = [];

        // Prepare partial path
        let partialNodeData = null;
        let supportNodeData = Object.keys(nodeData ? nodeData : {});

        // Check for partial path
        if (supportNodeData.includes(curSectionNode["field-code"])) {
            // Save new path
            partialNodeData = nodeData[curSectionNode["field-code"]];
        }

        // Check for not empty path
        if (nodePath === "") {
            // Concat separator
            pathSep = "";
        }

        // Prepare flag for separator
        let isArrayOfGroupFields = false;

        // Check for block type: PARENT-NODE
        if (curSectionNode &&
            !curSectionNode["field-last-level"]) {
            // Check for group of fields
            if (curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0) {
                // Set as multi field array
                isArrayOfGroupFields = true;
            }

            // Check if children exists
            if (Array.isArray(curSectionNode["field-tree-childs"]) &&
                curSectionNode["field-tree-childs"].length > 0) {
                // Check for array
                if (Array.isArray(partialNodeData)) {
                    // Loop depending on sub data
                    nChilds = partialNodeData.map((subNodeData, index) => {
                        // Delegate to function
                        let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                            return this.getTabBodiesHTML_helper(
                                child,
                                subNodeData,
                                nodePath + pathSep + curSectionNode["field-code"] + pathSep + index,
                                index
                            );
                        });

                        // Filters out null values
                        tmpSubBlocks = tmpSubBlocks.filter(x => x);

                        // Return filtered
                        return tmpSubBlocks;
                    });
                }
                // Otherwise...
                else {
                    // Prepare sub blocks
                    let tmpSubBlocks = curSectionNode["field-tree-childs"].map((child) => {
                        return this.getTabBodiesHTML_helper(
                            child,
                            partialNodeData,
                            nodePath + pathSep + curSectionNode["field-code"] + (
                                isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                            )
                        );
                    });

                    // Filters out null values
                    tmpSubBlocks = tmpSubBlocks.filter(x => x);

                    // Replace value with filtered version
                    nChilds = tmpSubBlocks.length > 0 ? [tmpSubBlocks] : [];
                }
            }
        }
        // Check for block type: LEAF-NODE
        else {
            // Check for array
            if (Array.isArray(partialNodeData) && (
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Loop depending on sub data
                nChilds = partialNodeData.map((subNodeData, index) => {
                    return subNodeData ? (<TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + index}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={index}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + pathSep + index}
                        formData={subNodeData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />) : null;
                });
            }
            // Check for unexpected array
            else if (
                Array.isArray(partialNodeData) && !(
                curSectionNode["field-max-occurs"] > 1 ||
                curSectionNode["field-max-occurs"] === 0
            )) {
                // Create leaf
                nChilds = [(partialNodeData[0]) ? (
                    <TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={nodeIndex}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        )}
                        formData={partialNodeData[0]}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />
                ) : null];
            }
            // Otherwise...
            else {
                // Create leaf
                nChilds = [(partialNodeData) ? (
                    <TemplateField
                        key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
                        fieldData={curSectionNode}
                        fieldMask={curSectionMask}
                        fieldTab={this.state.activeTab}
                        fieldIndex={nodeIndex}
                        fieldRealPath={nodePath + pathSep + curSectionNode["field-code"] + (
                            isArrayOfGroupFields ? (pathSep + nodeIndex) : ""
                        )}
                        formData={partialNodeData}
                        pfcFetchedComments={this.state.pfcFetchedComments}
                        pfcCommentsInEdit={this.state.pfcCommentsInEdit}
                        card_view_status={this.state.card_view_status}
                        staging_state={this.state.staging_state}
                    />
                ) : null];
            }
        }

        // Check for array of component
        if (curSectionNode["field-max-occurs"] > 1 ||
            curSectionNode["field-max-occurs"] <= 0) {
            isMulti = true;
        }

        // Filters out null values
        nChilds = nChilds.filter(x => x);

        // Send back component
        return nChilds.length > 0 ? (<TemplateFieldGroup
            key={"JSX_GROUP__" + curSectionNode["field-edusn-srvid"] + "__" + nodeIndex}
            fieldData={curSectionNode}
            fieldMask={curSectionMask}
            fieldChildren={nChilds}
            fieldIndex={nodeIndex}
            fieldRealPath={nodePath + pathSep + curSectionNode["field-code"]}
            formData={partialNodeData}
            isMultiGroup={isMulti}
            isArrayOfGroupFields={isArrayOfGroupFields}
        />) : null;
    }

    // ------------------------------------------------------------------------------------| RENDERING CARD

    //  EXAMPLE OF ENTITYDATA: {
    //     "enrich_uuid": "fa9792d6-9069-40f5-aafd-d6f07c9cfd9f",
    //     "card_uuid": "b3c09626-45ab-4ca3-806e-ac67312f137c",
    //     "card_title": "Duomo di Milano",
    //     "card_image": "https://v2.api.edubba.databencart.com:443/downloads/b5f4d819-acc0-4242-8fa1-23361813876c",
    //     "site_uuid": null,
    //     "catalog_ref_uuid": "",
    //     "catalog_ref_card_id": "",
    //     "template_uuid": "91f012b5-a83c-42a5-a302-af72cd9ab1c4",
    //     "status_code": "FW_CDER_0006",
    //     "old_status_code": "FW_CDER_0005",
    //     "card_parent_uuid": null,
    //     "card_date_create": 1618482650949,
    //     "card_date_update": 1618484215136,
    //     "assign_uuid": "823ea74d-6fc1-4ee7-a6e6-5c2791a4c076",
    //     "group_uuid": "12bdbe80-7a5f-4243-b755-8267402900c5",
    //     "assign_type": "STSC0001",
    //     "assign_date_create": 1617892668570,
    //     "assign_date_start": 1617892524219,
    //     "assign_date_end": 1617919200000,
    //     "assign_title": "Arricchimento del 08/04/2021",
    //     "project_uuid": "5538d08d-45e4-417b-871d-2b1caea9f93f",
    //     "project_name": "Percorso del 08/04/2021",
    //     "project_date_create": 1617892620688,
    //     "project_date_start": 1617892496785,
    //     "project_date_end": 1617919200000,
    //     "task_uuid": "a52edbc3-8db2-4c65-a677-d9a860eeca9a",
    //     "task_name": "Prova Check EDIT",
    //     "task_date_create": 1618482650914,
    //     "task_date_start": 1618482630137,
    //     "task_date_end": 1618524000000,
    //     "task_color": "#2b2424",
    //     "task_fg_color": "#d5d3d3",
    //     "assign_is_certifiable": true,
    //     "task_owner": {
    //         "user_uuid": "57cbc66c-9ffd-477c-9e98-b5764c30cfc4",
    //         "user_name": "demo.studente1@edubba.it",
    //         "user_profile": {
    //             "firstName": "Mario",
    //             "lastName": "Demo 2021"
    //         }
    //     },
    //     "task_tagname": "#prova-check-edit"
    // }
    //-------------------------------------------------------------------------------->
    getPdfDocument = () => {
        return (
            <Document
                producer="Edubba Platform"
                creator="Edubba Platform"
                author="Edubba Platform"
                title={this.props.entityData.card_title}>
                {/* PRIMA PAGINA */}
                <Page size="A4" style={cardPdfStyles.page}>
                    <View style={cardPdfStyles.row}>
                        <Svg style={cardPdfStyles.pageLogo} viewBox="0 0 130.11 130.11" preserveAspectRatio="meet">
                            <G fill="#fff">
                                <G fill="#fff">
                                    <Polygon fill="#fff" points="47.01 47.07 54.06 73.05 54.06 106.5 56.46 106.5 56.46 73.05 63.51 47.07 47.01 47.07"></Polygon>
                                    <Polygon fill="#fff" points="83.1 47.07 66.6 47.07 73.65 73.05 73.65 106.5 76.05 106.5 76.05 73.05 83.1 47.07"></Polygon>
                                    <Polygon fill="#fff" points="61.19 32.96 49.33 23.61 49.33 42.31 61.19 32.96"></Polygon>
                                    <Polygon fill="#fff" points="80.78 32.96 68.92 23.61 68.92 42.31 80.78 32.96"></Polygon>
                                    <Path fill="#fff" d="M130.11,130.11H0V0H130.11ZM4,126.12H126.12V4H4Z"></Path>
                                </G>
                            </G>
                        </Svg>
                        <View style={cardPdfStyles.pageTitle}>
                            <Text style={cardPdfStyles.titleCap}>Titolo Scheda:</Text><br />
                            <Text style={cardPdfStyles.titleSep}>{this.props.entityData.card_title}</Text><br />
                            <Text style={cardPdfStyles.titleCap}>Assegnatario del compito:</Text><br />
                            <Text style={cardPdfStyles.titleSep}>{this.getUserFullName(this.props.entityData.task_owner)}</Text><br />
                            <Text style={cardPdfStyles.titleCap}>Data esportazione:</Text><br />
                            <Text style={cardPdfStyles.titleSkipSep}>{moment(Date.now()).format("DD/MM/YYYY H:m:s")}</Text><br />
                        </View>
                    </View>
                    <View style={cardPdfStyles.row}>
                        <View style={cardPdfStyles.cardStatus}>
                            <Text>Stato del Compito:  {(() =>{
                                switch (this.props.entityData.status_code) {
                                    // Status codes
                                    case "FW_CDER_0001": return "IN COMPILAZIONE";
                                    case "FW_CDER_0002": return "IN REVISIONE AL GRUPPO";
                                    case "FW_CDER_0003": return "IN REVISIONE";
                                    case "FW_CDER_0004": return "IN VALUTAZIONE";
                                    case "FW_CDER_0005": return "IN CERTIFICAZIONE";
                                    case "FW_CDER_0006": return "CERTIFICATO";

                                    // Unknown
                                    default:             return "NON DISPONIBILE";
                                }
                            })()}</Text>
                        </View>
                    </View>
                    <View style={cardPdfStyles.rowImage}>
                        <Image style={{
                            ...((exifOrientation) => {
                                switch (exifOrientation) {
                                    case 6: return cardPdfStyles.rotate90;
                                    case 8: return cardPdfStyles.rotate270;
                                    case 3: return cardPdfStyles.rotate180;
                                    default: return {};
                                }
                            })(this.state.imageOrientation),
                            ...cardPdfStyles.cardImage
                        }} src={this.state.imageFirstPage}></Image>
                    </View>
                </Page>
                {/* PAGINA DEL PROGETTO */}
                <Page size="A4" style={cardPdfStyles.page}>
                    <View style={cardPdfStyles.rowCardContent}>
                        <View>
                            <Text style={cardPdfStyles.titleCardContent}>Dettagli del Progetto</Text>
                        </View>
                        <View style={cardPdfStyles.projectBodyContent}>
                            <Text style={cardPdfStyles.projectTitle}>Nome del Progetto:</Text>
                            <Text style={cardPdfStyles.projectDataSep}>{this.props.entityData.project_name}</Text>
                            <Text style={cardPdfStyles.projectTitle}>Data Creazione del Progetto</Text>
                            <Text style={cardPdfStyles.projectDataSep}>{moment(this.props.entityData.project_date_create).format("DD/MM/YYYY H:m:s")}</Text>
                            <Text style={cardPdfStyles.projectTitle}>Data di Avvio/Scadenza del Progetto:</Text>
                            <Text style={cardPdfStyles.projectDataNoSep}>Dal {
                                moment(this.props.entityData.project_date_start).format("DD/MM/YYYY")
                            } al {
                                moment(this.props.entityData.project_date_end).format("DD/MM/YYYY")
                            }</Text>
                        </View>
                        <View>
                            <Text style={cardPdfStyles.titleCardContent}>Dettagli dell'Attività</Text>
                        </View>
                        <View style={cardPdfStyles.projectBodyContent}>
                            <Text style={cardPdfStyles.projectTitle}>Nome dell'Attività:</Text>
                            <Text style={cardPdfStyles.projectDataSep}>{this.props.entityData.assign_title}</Text>
                            <Text style={cardPdfStyles.projectTitle}>Data Creazione dell'Attività</Text>
                            <Text style={cardPdfStyles.projectDataSep}>{moment(this.props.entityData.assign_date_create).format("DD/MM/YYYY H:m:s")}</Text>
                            <Text style={cardPdfStyles.projectTitle}>Data di Avvio/Scadenza dell'Attività:</Text>
                            <Text style={cardPdfStyles.projectDataNoSep}>Dal {
                                moment(this.props.entityData.assign_date_start).format("DD/MM/YYYY")
                            } al {
                                moment(this.props.entityData.assign_date_end).format("DD/MM/YYYY")
                            }</Text>
                        </View>
                        <View>
                            <Text style={cardPdfStyles.titleCardContent}>Dettagli del Compito</Text>
                        </View>
                        <View style={cardPdfStyles.projectBodyContent}>
                            <Text style={cardPdfStyles.projectTitle}>Nome del Compito:</Text>
                            <Text style={cardPdfStyles.projectDataSep}>{this.props.entityData.task_name}</Text>
                            <Text style={cardPdfStyles.projectTitle}>Data Creazione del Compito</Text>
                            <Text style={cardPdfStyles.projectDataSep}>{moment(this.props.entityData.task_date_create).format("DD/MM/YYYY H:m:s")}</Text>
                            <Text style={cardPdfStyles.projectTitle}>Data di Avvio/Scadenza del Compito:</Text>
                            <Text style={cardPdfStyles.projectDataNoSep}>Dal {
                                moment(this.props.entityData.task_date_start).format("DD/MM/YYYY")
                            } al {
                                moment(this.props.entityData.task_date_end).format("DD/MM/YYYY")
                            }</Text>
                        </View>
                    </View>
                </Page>
                {/* PAGINA DELLA SCHEDA */}
                <Page size="A4" style={cardPdfStyles.page}>
                    <View style={cardPdfStyles.rowCardContent}>
                        <View>
                            <Text style={cardPdfStyles.titleCardContent}>Contenuto della Scheda</Text>
                        </View>
                        <View style={cardPdfStyles.bodyCardContent}>
                            {this.getTabBodiesHTML()}
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }

    render() {
        // Hack fix
        if (this.state.inDelayedTime) {
            return <Button color="secondary" block disabled>Generazione in corso...</Button>;
        }

        // Normal statement
        return this.props.inlineView ? (
            <PDFViewer style={{
                width: "100%",
                heigth: "100%",
                minHeight: "300px"
            }} children={this.getPdfDocument()} />
        ) : (
            <BlobProvider document={this.getPdfDocument()}>
                {({ blob, url /*, loading, error*/ }) =>
                    !(blob && url) ? (
                        <Button color="secondary" block disabled>Generazione in corso...</Button>
                    ) : (
                        <a href={url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                            <Button color="secondary" block>Scarica il Documento PDF</Button>
                        </a>
                    )
                }
            </BlobProvider >
        );
    }
};

class TemplateFieldGroup extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldIndex: Number => Index array
        // fieldRealPath: String => Path calcolato con index
        // fieldChildren: Array [{}] => TemplateField
        // formData: Object {} => Data
        // isMultiGroup: Boolean
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // userCanEdit: Boolean
        this.state = {};
    }

    render() {
        const isOdd = this.props.fieldData["field-tree-level"] % 2 !== 0;

        return (
            <View style={isOdd ? cardPdfStyles.TemplateFieldGroupOdd : cardPdfStyles.TemplateFieldGroup}>
                <View style={isOdd ? cardPdfStyles.SectionHeaderOdd : cardPdfStyles.SectionHeader}>
                    <Text style={cardPdfStyles.SectionName}>
                        {this.props.fieldData["field-name"].toLowerCase()}:
                    </Text>
                </View>
                <View style={cardPdfStyles.SectionBody}>
                    {this.props.fieldChildren.map((child, index) => {
                        // Render childs
                        return (
                            <View key={"view-group-" + (index + 1)} style={isOdd ? cardPdfStyles.SectionMultiEffectOdd : cardPdfStyles.SectionMultiEffect}>
                                {child}
                            </View>
                        );
                    })}
                </View>
            </View>
        )
    };
}

class TemplateField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldTab: String => Header di appartenenza
        // fieldIndex: Number => Index array
        // fieldRealPath: String => Path calcolato con index
        // formData: Object {} => Data
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // pfcFetchedComments: Object {} => Data
        // userCanEdit: Boolean
        // userCanEditFC: Boolean
        // card_view_status: String
        // staging_state: String
        this.state={};
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    render() {
        const isOdd = this.props.fieldData["field-tree-level"] % 2 !== 0;

        return (
            <View style={isOdd ? cardPdfStyles.FieldRowOdd : cardPdfStyles.FieldRow}>
                {
                    this.isValidURL(this.props.formData) ? (
                        <Link style={cardPdfStyles.FieldTextData} src={this.props.formData}>
                            {this.props.formData}
                        </Link>
                    ): (
                        <Text style={cardPdfStyles.FieldTextData}>
                            {this.props.formData}
                        </Text>
                    )
                }
            </View>
        )
    };
    }

export default CardExportPDF;
