import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Crypto from 'crypto';
import { withRouter } from 'react-router-dom';
// import logoEdubba from './../imgs/logo_edubba.png';
import globalVars from './../globalVars.json';
import './Login.scss';
import holdDataService from './../services/holdDataService/holdDataService';

import { Col, Row, Button, Form, FormGroup } from 'reactstrap';
import edubbaLogoSrc from './../assets/utils/images/logo_edubba_redux.png';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            loginState: undefined,
            disclaimerAccepted: false,
            disclaimerSection: false,
            userSessionData: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createPasswordHash = this.createPasswordHash.bind(this);
    }

    handleChange(event) {
        event.preventDefault();
        const name = event.target.name;
        this.setState({ [name]: event.target.value, loginState: undefined });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.tryLogin();
    }

    createPasswordHash(email, pass) {
        let shasum = Crypto.createHash('sha1');
        shasum.update(email + ":" + pass);
        return shasum.digest('hex');
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'login';
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
    }

    tryLogin = () => {
        // Prepare basic-auth
        let loginJson = {
            "username": this.state.email,
            "password": this.createPasswordHash(this.state.email, this.state.password)
        };

        // Prepare query
        let userQuery = globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/auth/login';

        // Exec API call
        axios.post(userQuery, {
            user_name: loginJson.username,
            user_hash: loginJson.password
        }).then(res => {
            // Check results
            if (res.data.rowCount > 0) {
                // Prepare data
                let userData  = res.data.rows[0];
                let userRoles = [];

                // Replace data for token
                loginJson.username = userData.session_data.user_uuid;
                loginJson.password = userData.session_data.user_ssid;

                // Check for roles
                if (userData.session_data &&
                    userData.session_data.user_roles &&
                    Array.isArray(userData.session_data.user_roles)) {
                    // Loop on roles
                    userData.session_data.user_roles.map((role) => {
                        // Save redux version
                        userRoles.push({
                            "code": role.code,
                            "name": role.name
                        });

                        // Trick react
                        return true;
                    });
                }

                // Generate user session
                let userInfoJSON = {
                    "user_ssid":       userData.session_data.user_ssid,
                    "user_uuid":       userData.session_data.user_uuid,
                    "user_profile":    userData.session_data.user_profile,
                    "user_disclaimer": userData.session_data.user_disclaimer,
                    "email":           userData.session_data.user_email,
                    "auth":            loginJson,
                    "roles":           userRoles,
                    "read_only":       userData.session_data.user_read_only
                };

                // Save user data
                this.setState({
                    userSessionData: userInfoJSON,
                    disclaimerAccepted: userInfoJSON.user_disclaimer
                },
                () => {
                    // Set session section if disclaimer ok
                    if (this.state.userSessionData &&
                        this.state.userSessionData.user_disclaimer) {
                        this.setUserSession();
                    }
                    // Else show modal
                    else this.setState({
                        disclaimerSection: true
                    });
                });
            }
            // Check error
            else {
                this.setState({ loginState: "E-mail o Password errate!" });
            }
        })
        // Check network error
        .catch(error => {
            this.setState({ loginState: "E-mail o Password errate!" });
        })
    }

    setUserSession = () => {
        // Check for user data
        if (this.state.userSessionData) {
            // Save session
            holdDataService.pushData(
                'userInfo',
                this.state.userSessionData
            );

            // Update session
            this.props.updateSession();

            // Redirect
            this.props.history.push("/");
        }
        // Reset all
        else this.cancelDisclaimer();
    }

    acceptDisclaimer = () => {
        // Prepare basic-auth
        let loginJson = {
            "username": this.state.userSessionData.user_uuid,
            "password": this.state.userSessionData.user_ssid
        };

        // Prepare query
        let userQuery = globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/users/disclaimer/accept';

        // Exec API call
        axios.put(userQuery, {}, { auth: loginJson })
        .then(res => {
            // Check results
            if (res.data.rowCount > 0) {
                // Prepare data
                let userData  = res.data.rows[0];

                // Save and go to dashboard
                this.setState({
                    disclaimerAccepted: userData.user_disclaimer,
                    userSessionData: {
                        ...this.state.userSessionData,
                        "user_disclaimer": userData.user_disclaimer
                    }
                },
                // Delegate function
                () => this.setUserSession());
            }
        })
        // Check network error
        .catch(error => {
            this.setState({ loginState: "Errore durante l'accettazione del disclaimer!" });
        })
    }

    cancelDisclaimer = () => {
        // Save session
        holdDataService.deleteAllData();

        // Set session section
        this.setState({
            userSessionData: null,
            disclaimerSection: false,
            disclaimerAccepted: false
        });
    }

    render() {
        let showDisclaimer = (
            this.state.disclaimerSection &&
            !this.state.disclaimerAccepted
        );

        return (
            <div className="Login app-container">
                <div className='bg-sumeri' />
                <div className="h-100 bg-no-blur">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Col md="4" className="mx-auto app-login-box">
                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <div className={"modal-body " + (showDisclaimer ? "modal-no-padding" : "") }>
                                        {
                                            !showDisclaimer ? (
                                                <Fragment>
                                                    <img className="logo-edubba" alt='Edubba' src={edubbaLogoSrc} />
                                                    <Form onSubmit={this.handleSubmit}>
                                                        <Row form>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <input type="email" name="email" id="exampleEmail"
                                                                        placeholder="Email"
                                                                        onChange={this.handleChange} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <input type="password" name="password" id="examplePassword"
                                                                        placeholder="Password"
                                                                        onChange={this.handleChange} />
                                                                </FormGroup>
                                                            </Col>
                                                            {/*<Col md={12}>
                                                                <FormGroup>
                                                                    <div className="float-right">
                                                                        <a href="#/" className="btn-sm btn btn-link btn-login">Recupera Password</a>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>*/}
                                                            {this.state.loginState ? <Col md={12}>
                                                                <div className='text-center text-danger mb-2 error-message-login'>
                                                                    {this.state.loginState}
                                                                </div>
                                                            </Col>: null}
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Button type="submit" className='color-white bg-edubba btn-login' size="lg" value="Submit">
                                                                        Accedi alla Piattaforma
                                                                    </Button>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <div className="DisclaimerTitle">Termini e Condizioni d'Utilizzo</div>
                                                    <div className="DisclaimerText">
                                                        <p>Per quanto concerne il contenuto degli elaborati, dei commenti, delle immagini e dei video, prodotti o utilizzati dagli Studenti per Edubba, il Tutor ed il Certificatore sono obbligati a verificare attentamente i medesimi prima della pubblicazione.<br />
                                                        <br />
                                                        In particolare, questi non dovranno contenere materiale, dati e/o informazioni illegali ovvero contro la morale e/o l'ordine pubblico, e/o, comunque, osceni, volgari, diffamatori, abusivi, con contenuto e/o tenore discriminatorio con riferimento a razza, sesso e/o religione, scelte personali e/o politiche e, più in generale, non potranno contenere alcun elemento che possa, anche solo indirettamente o potenzialmente, violare diritti di terzi, e/o le disposizioni normative e regolamentari nazionali e sopranazionali vigenti, non potranno inoltre contenere alcun elemento, dato e/o materiale e/o messaggio che incoraggino terzi a mettere in atto condotte illecite e/o criminose passibili di responsabilità penale o civile.<br />
                                                        <br />
                                                        Inoltre, tutti i materiali pubblicati non devono violare diritti di proprietà intellettuale, diritti morali, diritti d'autore, o diritti di tutela dei dati personali di terzi, diritti di pubblicazione e, in generale, devono rispettare tutte le previsioni di cui alla legge sul Diritto d'Autore e successive modifiche ed integrazioni (Legge 22 aprile 1941 n. 633 a protezione del diritto d'autore e di altri diritti connessi al suo esercizio), e non devono essere lesivi di altri diritti di proprietà intellettuale e/o industriale.<br />
                                                        <br />
                                                        Fermo quanto sopra, il Certificatore è il soggetto responsabile del contenuto e della forma del materiale pubblicato.</p>
                                                    </div>
                                                    <div className="DisclaimerBtns">
                                                        <Button className='color-white bg-edubba btn-disclaimer' size="lg" onClick={this.cancelDisclaimer}>
                                                            Rifiuta
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                        <Button className='color-white bg-edubba btn-disclaimer' size="lg" onClick={this.acceptDisclaimer}>
                                                            Accetta
                                                        </Button>
                                                    </div>
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LoginPage);
