import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './../News.scss';

class Content_avviso_covid19_28022020 extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="ContentRoot_Data">
                <div className="ContentRoot_Subject">
                    OGGETTO: “Coronavirus - Ufficio Scolastico Regionale”.
                </div>
                <div className="ContentRoot_Body">
                Si comunica alle SS.LL che l’evento (prot.n. AOODRCA/RU/2562 del 05/02/2020) previsto per il giorno 2 marzo alle ore 10:00, presso l’aula magna del Centro Congressi Federico II, Via Partenope, 36, 80121 Napoli è stato rinviato a data da destinarsi.<br />
                <br />
                Sarà cura dell'USR per la Campania, con successiva comunicazione, trasmettere il programma della giornata con la relativa articolazione degli interventi.<br />
                <br />
                Si ringrazia per la collaborazione.
                </div>
            </div>
        )
    }
}

export default withRouter(Content_avviso_covid19_28022020);
