import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './../News.scss';

class Content_avviso_materiale_tp_databenc_15042020 extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="ContentRoot_Data">
                <div className="ContentRoot_Subject">
                    OGGETTO: Databenc Education - Disponibilità Materiale.
                </div>
                <div className="ContentRoot_Body">
                &Egrave; fissato per oggi 4 maggio il lancio di "<b><a href="https://education.databenc.it" target="_blank" rel="noopener noreferrer">Databenc Education</a></b>" una piattaforma
                di contenuti pensata per supportare le Scuole che hanno attivato forme di
                didattica a distanza nel periodo di chiusura legato all'emergenza Coronavirus.<br />
                <br />
                <b>Databenc Education</b> realizzata da Databenc in collaborazione con l’Ufficio
                Scolastico Regionale per la Campania è integrata con le potenzialità della
                piattaforma EDUBBA.<br />
                <br />
                <b>Databenc Education</b> offre articoli, video, lavori di ricerca realizzati dal
                Distretto Databenc nell’ambito dei suoi progetti di ricerca inerenti la
                conoscenza, tutela, valorizzazione e fruizione sostenibile del patrimonio
                culturale.<br />
                <br />
                Per poter accedere al portale <b>Databenc Education</b> è necessario <a href="https://education.databenc.it/form_registrazione/" target="_blank" rel="noopener noreferrer">registrarsi</a>.
                Gli utenti della piattaforma EDUBBA possono accedere al catalogo di <b>Databenc Education</b> direttamente da <a href="https://v2.edubba.databencart.com/login" target="_blank" rel="noopener noreferrer">EDUBBA</a>.
                </div>
            </div>
        )
    }
}

export default withRouter(Content_avviso_materiale_tp_databenc_15042020);
