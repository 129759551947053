import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import {
    Container
} from 'reactstrap';
import globalVars from './../../globalVars.json';
import SearchBox from './../../Layout/AppHeader/Components/SearchBox';

import EdubbaLoader from './../../components/EdubbaLoader/EdubbaLoader';
//import moment from 'moment';
import 'moment/locale/it';

import holdDataService from './../../services/holdDataService/holdDataService';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';

import './BackOffice.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CRUD_Opts = [
    {
        label: "Entità",
        value: "Entità",
        children: [
            {
                label: "Istituti",
                value: "Istituto"
            },
            {
                label: "Tutor",
                value: "Tutor"
            },
            {
                label: "Certificatori",
                value: "Certificatore"
            }
        ]
    },
    {
        label: "Relazioni",
        value: "Relazione",
        children: [
            {
                label: "Tutor-Istituto",
                value: "Tutor-Istituto"
            }
        ]
    }
];

class BO extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedConstructType: "",
            selectedObjectType: "",
            selectedList: [],
            loadingSelectedList: false,
            selectedDetailUUID: "",
            selectedDetail: null,
            listSearchValue: "",
            creationMode: false
        };

    }

    static getDerivedStateFromProps(props, state) {
        if (!holdDataService.loggedUserIsAdmin()) {
            //TODO: REINSERIRE//props.history.push("/");
        }
        return null;
    }

    componentDidMount() {
        let setTitle = 'Dashboard - Gestione Entità';
        this.props.setSectionTitle(setTitle);
    }

    handleSearch = (event) => {
        event.preventDefault();
        this.setState({
            listSearchValue: event.target.value
        });
    }

    handleCloseSearch = () => {
        this.setState({
            listSearchValue: ""
        });
    }

    handleTypeSelection = (construct_type, obj_type) => {
        if (construct_type === this.state.selectedConstructType && obj_type === this.state.selectedObjectType) {
            this.setState({
                selectedConstructType: "",
                selectedObjectType: "",
                selectedList: [],
                loadingSelectedList: false,
                selectedDetailUUID: "",
                selectedDetail: null,
                loadingSelectedDetail: false,
                creationMode: false
            });
        }
        else {
            this.loadTypeSelection(construct_type, obj_type);
        }
    }

    loadTypeSelection = (construct_type, obj_type) => {
        this.setState({
            selectedConstructType: construct_type,
            selectedObjectType: obj_type,
            selectedList: [],
            loadingSelectedList: false,
            selectedDetailUUID: "",
            selectedDetail: null,
            loadingSelectedDetail: false,
            creationMode: false
        }, () => {
            this.loadList(construct_type, obj_type);
        });
    }

    handleObjectSelection = (obj_uuid) => {
        if (this.state.selectedDetailUUID === obj_uuid) {
            this.setState({
                selectedDetailUUID: "",
                selectedDetail: null,
                loadingSelectedDetail: false,
                creationMode: false
            });
        }
        else {
            this.setState({
                selectedDetailUUID: obj_uuid,
                selectedDetail: null,
                loadingSelectedDetail: false,
                creationMode: false
            }, () => {
                this.loadDetail(this.state.selectedConstructType, this.state.selectedObjectType, this.state.selectedDetailUUID);
            });
        }
    }

    getGETQuery = (construct_type, obj_type, detailUUID) => {
        let query = ((construct_type, obj_type) => {
            switch (this.state.selectedObjectType) {
                case "Istituto":
                    return "/institutes";
                case "Tutor":
                case "Certificatore":
                    return "/users";
                default:
                    return "";
            }
        })(construct_type, obj_type);
        return detailUUID ? (query + "/" + detailUUID) : query;
    }

    loadList = (construct_type, obj_type) => {
        this.setState({ loadingSelectedList: true }, () => {
            let query = this.getGETQuery(construct_type, obj_type, null);
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                let list = ((data, obj_type) => {
                    let list = data && data.rowCount > 0 ? data.rows : [];
                    switch (obj_type) {
                        case "Tutor":
                        case "Certificatore":
                            const specifiedRole = obj_type === "Tutor" ? "RBAC-RLCD-N0000002" : obj_type === "Certificatore" ? "RBAC-RLCD-N0000004" : "";
                            list = list.filter((u, index, array) => {
                                if (u && Array.isArray(u.user_roles)) {
                                    for (let i = 0; i < u.user_roles.length; i++) {
                                        if (u.user_roles[i].role_code === specifiedRole) {
                                            return true;
                                        }
                                    }
                                }
                                return false;
                            });
                            break;
                        case "Istituto":
                        default:
                            break;
                    }
                    return list;
                })(res.data, this.state.selectedObjectType);
                this.setState({
                    selectedList: list,
                    loadingSelectedList: false
                });
            }, err => {
                this.setState({
                    selectedList: [],
                    loadingSelectedList: false
                });
            });
        });
    }

    getListItemAttribute(item, attribute) {
        let value = "";
        switch (this.state.selectedObjectType) {
            case "Istituto":
                switch (attribute) {
                    case "uuid":
                        value = item.institute_uuid;
                        break;
                    case "name":
                        value = item.institute_name;
                        break;
                    default:
                        break;
                }
                break;
            case "Tutor":
                break;
            case "Certificatore":
                break;
            default:
                break;
        }
        return value ? value : "";
    }

    loadDetail = (construct_type, obj_type, obj_uuid) => {
        this.setState({ loadingSelectedDetail: true }, () => {
            let query = this.getGETQuery(construct_type, obj_type, obj_uuid);
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                this.setState({
                    selectedDetail: res.data && res.data.rowCount > 0 ? res.data.rows[0] : null,
                    loadingSelectedDetail: false
                });
            }, err => {
                this.setState({
                    selectedDetail: null,
                    loadingSelectedDetail: false
                });
            });
        });
    }

    openCreationMode = () => {
        this.setState({
            creationMode: true,
            selectedDetailUUID: "",
            selectedDetail: null,
            loadingSelectedDetail: false
        });
    }

    render() {
        return (
            <Fragment>
                <Fragment>
                    <div className="flex-row">
                        <Container fluid id="accordion" className='main-list'>
                            <PerfectScrollbar>
                                <div className='widget-content first-row p-0'>
                                    <div className='widget-content-wrapper top-bar'>
                                        <div className='widget-content-left ml-3 mr-3 p-2 edubba-search text-left'>
                                        </div>
                                    </div>
                                    {CRUD_Opts.map((construct_type, index_c) => {
                                        return <div key={index_c} className="main-list-group">
                                            <div className="main-list-group-label">
                                                {construct_type.label}
                                            </div>
                                            {construct_type.children.map((obj_type, index_o) => {
                                                return <div
                                                    key={index_c + "_" + index_o}
                                                    className={"main-list-group-entry" +
                                                        ((this.state.selectedConstructType === construct_type.value &&
                                                            this.state.selectedObjectType === obj_type.value) ? " selected" : "")}
                                                    onClick={() => this.handleTypeSelection(construct_type.value, obj_type.value)}
                                                >
                                                    {obj_type.label}
                                                </div>;
                                            })}
                                        </div>;
                                    })}
                                </div>
                            </PerfectScrollbar>
                        </Container>
                        <Container fluid className='entity-list'>
                            {this.state.loadingSelectedList ?
                                <EdubbaLoader />
                                :
                                this.state.selectedList.length === 0 ? null :
                                    <PerfectScrollbar>
                                        <div className='widget-content first-row p-0'>
                                            <div className='widget-content-wrapper top-bar'>
                                                <div className='widget-content-left ml-3 mr-3 p-2 edubba-search text-left'>
                                                    <SearchBox
                                                        text={this.state.searchValue}
                                                        handleSearch={this.handleSearch}
                                                        handleClose={this.handleCloseSearch}
                                                        myPlaceholder='Cerca per Gruppo/Studente ...' />
                                                </div>
                                            </div>
                                            <div className='widget-content-right mr-3 text-left'>
                                                <span className='btn-edubba' onClick={this.openCreationMode}>
                                                    Crea {this.state.selectedConstructType === "Relazione" ? "Relazione" : ""} {this.state.selectedObjectType}
                                                    <FontAwesomeIcon className='icon' icon={faPlus} />
                                                </span>
                                            </div>
                                            <div className="">
                                                {this.state.selectedList.map((obj, index) => {
                                                    if (this.getListItemAttribute(obj, "name").toLowerCase().indexOf(this.state.listSearchValue.toLowerCase()) === -1) {
                                                        return false;
                                                    }
                                                    return <div
                                                        key={this.getListItemAttribute(obj, "uuid")}
                                                        className={"" + (this.state.selectedDetailUUID === this.getListItemAttribute(obj, "uuid") ? " selected" : "")}
                                                        onClick={() => this.handleObjectSelection(this.getListItemAttribute(obj, "uuid"))}
                                                    >
                                                        {this.getListItemAttribute(obj, "name")}
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </PerfectScrollbar>}
                        </Container>
                        <Container fluid className='main-detail'>
                            {this.state.loadingSelectedDetail ?
                                <EdubbaLoader />
                                :
                                this.state.selectedDetail || this.state.creationMode ?
                                    <PerfectScrollbar>
                                        {this.state.selectedObjectType === "Istituto" ?
                                            <InstituteDetail
                                                existingInstitute={this.state.selectedDetail}
                                                onSubmit={() => this.loadTypeSelection(
                                                    this.state.selectedConstructType,
                                                    this.state.selectedObjectType
                                                )}
                                            /> : null}
                                    </PerfectScrollbar> : null}
                        </Container>
                    </div>
                </Fragment>
            </Fragment>
        )
    }
}

class InstituteDetail extends Component {
    constructor(props) {
        super(props);
        // existingInstitute: JSON
        // onSubmit: Function

        this.state = {
            editMode: props.existingInstitute ? true : false,
            existingInstitute: props.existingInstitute,
            instituteName: props.existingInstitute ? props.existingInstitute.institute_name : "",
            instituteAddress: props.existingInstitute ? props.existingInstitute.institute_address : ""
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.existingInstitute) !== JSON.stringify(state.existingInstitute)) {
            return {
                editMode: props.existingInstitute ? true : false,
                existingInstitute: props.existingInstitute,
                instituteName: props.existingInstitute ? props.existingInstitute.institute_name : "",
                instituteAddress: props.existingInstitute ? props.existingInstitute.institute_address : ""
            }
        }
        return null
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    saveInstitute = () => {
        let instituteJSON = {};
        instituteJSON.institute_name = this.state.instituteName;
        instituteJSON.institute_address = this.state.instituteAddress;
        if (this.state.editMode) {
            let query = "/institutes/" + this.props.existingInstitute.institute_uuid;
            axios.put(
                globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                instituteJSON,
                {
                    auth: holdDataService.getAuthorization()
                }).then(res => {
                    this.props.onSubmit();
                }, err => {
                    this.props.onSubmit();
                });
        }
        else {
            let query = "/institutes";
            axios.post(
                globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                instituteJSON,
                {
                    auth: holdDataService.getAuthorization()
                }).then(res => {
                    this.props.onSubmit();
                }, err => {
                    this.props.onSubmit();
                });
        }
    }

    render() {
        return (
            <div className="InstituteDetail">
                <div className="detail-header">
                    <div className="detail-title">
                        {this.state.editMode ? ("Istututo " + this.state.existingInstitute.institute_name) : "Creazione nuovo Istituto"}
                    </div>
                    <div className="detail-submit-button-container">
                        <button type="button" className="btn-edubba" onClick={this.saveInstitute}>
                            Salva Istituto
                        </button>
                    </div>
                </div>
                <div className="detail-content">
                    <div className="institute-name">
                        <input
                            name="instituteName"
                            value={this.state.instituteName}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="institute-address">
                        <input
                            name="instituteAddress"
                            value={this.state.instituteAddress}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BO);
