import React, { Fragment } from 'react';

import Hamburger from 'react-hamburgers';

import ReactSVG from 'react-svg';
import chatRoomSrc from './../../../assets/utils/images/speech-bubble.svg';

import Drawer from 'react-motion-drawer';

import SocialRoomsSidebar from './TabsContent/SocialRoomsSidebar';

class HeaderRightDrawer extends React.Component {
    constructor(props) {
        super(props);
        // socialRooms: Array
        this.state = {
            active: false,
            openLeft: false,
            openRight: false,
            relativeWidth: false,
            width: 450,
            noTouchOpen: false,
            noTouchClose: false,
            socialRooms: props.socialRooms
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.socialRooms)
            this.setState({ socialRooms: nextProps.socialRooms });
    }

    close = () => {
        this.setState({ openRight: false, openLeft: false }, () => {
            let closedSocialSidebar = new Event("social-sidebar-closed");
            document.dispatchEvent(closedSocialSidebar);
        });
    }

    render() {

        const {
            openRight,
        } = this.state;

        return (
            <Fragment>
                <Drawer
                    right
                    className="drawer-content-wrapper p-0"
                    width={450}
                    open={openRight}
                    onChange={open => this.setState({ openRight: open })}
                    noTouchOpen={true}
                    noTouchClose={true}
                    handleWidth={0}
                >
                    <div className="drawer-nav-btn" onClick={this.close} style={{cursor: "pointer"}}>
                        <Hamburger
                            active={openRight}
                            type="elastic"
                        />
                    </div>
                    <SocialRoomsSidebar
                        sectionTitle={this.props.sectionTitle}
                        socialRooms={this.state.socialRooms}
                        closeSidebar={() => this.setState({ openRight: !openRight, openLeft: false })}
                    />
                </Drawer>
                <div className="header-btn-lg">
                    {/* <Hamburger
                        active={openRight}
                        type="elastic"
                        onClick={() => this.setState({openRight: !openRight, openLeft: false})}
                    /> */}
                    <ReactSVG className='svg-class pointer' src={chatRoomSrc}
                        // active={openRight}
                        // type="elastic"
                        onClick={() => this.setState({ openRight: !openRight, openLeft: false })}
                    />
                </div>
            </Fragment>
        )
    }
}

export default HeaderRightDrawer;
