import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from './../globalVars.json';
import {
    Row,
    Col,
    Container,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import holdDataService from '../services/holdDataService/holdDataService';
import EdubbaLoader from './../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../assets/utils/images/edubba_placeholder.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faClipboardList,
    faTrash,
    faMapPin,
    faArrowCircleLeft,
    faArrowCircleRight,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import './Tasks.scss';
import moment from 'moment';
import CardExportPDF from './../CardDetail/CardExportPDF/CardExportPDF';
import 'moment/locale/it';

class Tasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessageTime: "",
            selectedCard: null,
            loadingTasks: false,
            // confirm
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
            // delete assignment task
            modalDeleteAssignmentTask: false,

            //----------------------------------------------| SEARCH

            searchCardValue: "",
            searchTourValue: "",

            tourCatCodeFilter: "",
            tourCatNameFilter: "",

            //----------------------------------------------| PAGING

            cardStatusCode: "unknown",
            cardSplitButtonOpen: false,
            cardTotalEntities: 0,
            cardPageLimit: 5,
            cardPageNumber: 1,

            tourStatusCode: "unknown",
            tourCatSplitButtonOpen: false,
            tourSplitButtonOpen: false,
            tourTotalEntities: 0,
            tourPageLimit: 5,
            tourPageNumber: 1,

            //----------------------------------------------| EXPORT PDF

            modalExportPDFTask:    false,
            entityExportPdfObject: null,
            entityExportPdfType:   null,

            //----------------------------------------------| CONTENT

            taskCards: [],
            taskTours: [],
            tour_categories: [],
            taskStatus: null
        };
    }

    componentWillMount() {
        this.props.setSectionTitle('Catalogo dei Compiti');

        if (this.props.match.params &&
            this.props.match.params.task_filter_view) {
            if (![
                "todo",
                "evaluate",
                "tocertified",
                "certified",
                "completed"
            ].includes(this.props.match.params.task_filter_view)) {
                this.props.history.push("/");
            }
            else {
                this.setState({
                    taskStatus: this.props.match.params.task_filter_view
                }, () => {
                    this.initTasks();
                });
            }
        }
        else this.props.history.push("/");

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params &&
            nextProps.match.params.task_filter_view &&
            this.state.taskStatus !== nextProps.match.params.task_filter_view) {
            this.setState({
                taskStatus: nextProps.match.params.task_filter_view
            }, () => {
                this.initTasks();
            });
        }
    }

    initTasks = () => {
        this.setState({
            cardStatusCode: (() => {
                switch (this.props.match.params.task_filter_view) {
                    case "todo":        return ["FW_CDER_0001", "FW_CDER_0002", "FW_CDER_0003"];
                    case "evaluate":    return ["FW_CDER_0004"];
                    case "completed":   return ["FW_CDER_0005", "FW_CDER_0006"];
                    case "tocertified": return ["FW_CDER_0005"];
                    case "certified":   return ["FW_CDER_0006"];
                    default:            return ["unknown"];
                }
            })(),
            tourStatusCode: (() => {
                switch (this.props.match.params.task_filter_view) {
                    case "todo":        return ["FW_ATER_0001"];
                    case "evaluate":    return ["FW_ATER_0002"];
                    case "completed":   return ["FW_ATER_0003"];
                    case "tocertified": return ["unknown"];
                    case "certified":   return ["unknown"];
                    default:            return ["unknown"];
                }
            })()
        }, () => {
            this.loadTaskCards();

            if (holdDataService.loggedUserIsStudent() ||
                holdDataService.loggedUserIsTutor() ||
                holdDataService.loggedUserIsAdmin()) {
                this.loadTourCategories();
                this.loadTaskTours();
            }
        });
    }

    goToCardPage = (pageNumber) => {
        this.setState({
            cardPageNumber: pageNumber
        }, () => {
            this.loadTaskCards(
                this.state.searchCardValue
            );
        });
    }

    goToTourPage = (pageNumber) => {
        this.setState({
            tourPageNumber: pageNumber
        }, () => {
            this.loadTaskTours(
                this.state.searchTourValue
            );
        });
    }

    cardToggleSplit = () => {
        this.setState({
            cardSplitButtonOpen: !this.state.cardSplitButtonOpen
        });
    }

    tourToggleSplit = () => {
        this.setState({
            tourSplitButtonOpen: !this.state.tourSplitButtonOpen
        });
    }

    tourCatToggleSplit = () => {
        this.setState({
            tourCatSplitButtonOpen: !this.state.tourCatSplitButtonOpen
        });
    }

    handleCardSearch = (ev) => {
        this.setState({
            searchCardValue: ev.target.value
        })
    }

    handleTourSearch = (ev) => {
        this.setState({
            searchTourValue: ev.target.value
        })
    }

    searchCard = (ev) => {
        const keyCode = ev.which || ev.keyCode;
        if (keyCode === 13) {
            this.loadTaskCards(
                this.state.searchCardValue
            );
        }
    }

    searchTour = (ev) => {
        const keyCode = ev.which || ev.keyCode;
        if (keyCode === 13) {
            this.loadTaskTours(
                this.state.searchTourValue
            );
        }
    }

    setTourCatFilter = (categoryCode, categoryName) => {
        this.setState({
            tourCatCodeFilter: categoryCode,
            tourCatNameFilter: categoryName
        }, () => {
            this.loadTaskTours(
                this.state.searchTourValue,
                this.state.tourCatCodeFilter
            );
        });
    }

    clearSearchCards = () => {
        this.setState({
            searchCardValue: "",
            cardPageNumber: 1
        }, () => {
            this.loadTaskCards();
        });
    }

    clearSearchTours = () => {
        this.setState({
            searchTourValue: "",
            tourPageNumber: 1
        }, () => {
            this.loadTaskTours();
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    generateFilterStatus = (arrStatuses) => {
        if (Array.isArray(arrStatuses)) {
            return (arrStatuses.map((item) => {
                return "statusCode[]=" + item;
            })).join("&");
        }
        else return "statusCode[]=unknown";
    }

    loadTaskCards = (searchValue = null) => {
        this.setState({ loadingTasks: true });
        let query = '/tasks/cards?' + this.generateFilterStatus(
            this.state.cardStatusCode
        ) + '&limit=' + this.state.cardPageLimit + '&page=' + this.state.cardPageNumber;
        if (searchValue) {
            query += '&searchBy=' + searchValue;
        }
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    cardTotalEntities: res.data.rowCount,
                    taskCards:         res.data.rows,
                    loadingTasks:      false
                });
            }
            else this.setState({
                cardPageNumber:    1,
                cardTotalEntities: 0,
                taskCards:         [],
                loadingTasks:      false
            });
        }, err => {
            this.setState({
                cardPageNumber:    1,
                cardTotalEntities: 0,
                taskCards:         [],
                loadingTasks:      false
            });
        });
    }

    loadTourCategories = () => {
        let query = '/tours/categories';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    tour_categories: res.data.rows
                });
            }
            else {
                this.setState({
                    tour_categories: []
                });
            }
        },
        err => {
            this.setState({
                tour_categories: []
            });
        });
    }

    loadTaskTours = (searchValue = null, filterByCategory = null) => {
        this.setState({ loadingTasks: true });
        let query = '/tasks/tours?' + this.generateFilterStatus(
            this.state.tourStatusCode
        ) + '&limit=' + this.state.tourPageLimit + '&page=' + this.state.tourPageNumber;
        if (filterByCategory) {
            query += '&filterCategory=' + filterByCategory;
        }
        if (searchValue) {
            query += '&searchBy=' + searchValue;
        }
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    tourTotalEntities: res.data.rowCount,
                    taskTours:         res.data.rows,
                    loadingTasks:      false
                });
            }
            else this.setState({
                tourPageNumber:    1,
                tourTotalEntities: 0,
                taskTours:         [],
                loadingTasks:      false
            });
        }, err => {
            this.setState({
                tourPageNumber:    1,
                tourTotalEntities: 0,
                taskTours:         [],
                loadingTasks:      false
            });
        });
    }

    goToCardDetail = (obj_uuid, mode_or_enrich, type, enrich_uuid = null) => {
        if (type === "tour") {
            this.props.history.push(
                '/tours/' + obj_uuid + '/enrichments/' + mode_or_enrich,
                { /* EMPTY PAYLOAD */ }
            );
        }
        else {
            this.props.history.push(
                '/card-detail/' + mode_or_enrich + '/' + obj_uuid,
                {
                    "src_type":    "tasks",
                    "card_uuid":   obj_uuid,
                    "enrich_uuid": enrich_uuid
                }
            );
        }
    }

    getCardOwner = (obj) => {
        if (obj &&
            obj.task_owner &&
            obj.task_owner.user_profile &&
            obj.task_owner.user_profile.firstName &&
            obj.task_owner.user_profile.lastName) {
            return (
                obj.task_owner.user_profile.firstName[0] +
                ". " +
                obj.task_owner.user_profile.lastName
            );
        }

        return "Non disponible..."
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    getStatusLabel = (status_code) => {
        switch (status_code) {
            // Draft
            case "FW_CDER_0001":
            case "FW_ATER_0001":
              return "In compilazione";
            // Draft-Comments
            case "FW_CDER_0002":
                return "In revisione al Team";
            // Pre-Evaluating
            case "FW_CDER_0003":
                return "In revisione";
            // Evaluating
            case "FW_CDER_0004":
            case "FW_ATER_0002":
                return "In valutazione";
            // Validating
            case "FW_CDER_0005":
                return "In certificazione";
            // Validated
            case "FW_CDER_0006":
                return "Certificata";
            // Approved
            case "FW_ATER_0003":
                return "Approvato";
            default: break;
          }
    }

    getStatusBtn = (status_code, asIcon = false) => {
        if (asIcon) {
            switch (status_code) {
                // Evaluating
                case "FW_CDER_0004":
                case "FW_ATER_0002":
                    return faClipboardList;
                // Draft
                // Draft-Comments
                // Pre-Evaluating
                // Validating
                // Validated
                case "FW_CDER_0001":
                case "FW_CDER_0002":
                case "FW_CDER_0003":
                case "FW_CDER_0005":
                case "FW_CDER_0006":
                case "FW_ATER_0001":
                case "FW_ATER_0003":
                default:
                    return faEye;
            }
        }
        else {
            switch (status_code) {
                // Evaluating
                case "FW_CDER_0004":
                case "FW_ATER_0002":
                    return "valutare";
                // Draft
                // Draft-Comments
                // Pre-Evaluating
                // Validating
                // Validated
                case "FW_CDER_0001":
                case "FW_CDER_0002":
                case "FW_CDER_0003":
                case "FW_CDER_0005":
                case "FW_CDER_0006":
                case "FW_ATER_0001":
                case "FW_ATER_0003":
                default:
                    return "visualizzare";
            }
        }
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardImage = (card_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(card_image)) {
            coreImage = card_image;
        }

        return coreImage;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    toggleDeleteAssignmentTaskUUID = (assign_uuid = null, task_uuid = null) => {
        this.setState({
            assignmentUuid: assign_uuid,
            taskUuid: task_uuid,
            modalDeleteAssignmentTask: !this.state.modalDeleteAssignmentTask
        });
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    deleteAssignmentTask = () => {
        let assignmentUuid = this.state.assignmentUuid;
        let taskUuid = this.state.taskUuid;

        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort +
        "/assignments/" + assignmentUuid + "/tasks/" + taskUuid,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.toggleDeleteAssignmentTaskUUID();

            if (res.data.rowCount > 0) {
                let tmpArray1 = this.state.taskCards.map((item) => {
                    if (item.task_uuid === taskUuid) {
                        return null;
                    }
                    else return item;
                });

                let tmpArray2 = this.state.taskTours.map((item) => {
                    if (item.task_uuid === taskUuid) {
                        return null;
                    }
                    else return item;
                });

                this.setState({
                    taskCards: tmpArray1.filter(x => x),
                    taskTours: tmpArray2.filter(x => x),
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione del Compito",
                    modalConfirmToastMessage: "Contenuti associati e compito rimossi con successo."
                });
            }
            else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Errore nella Rimozione!",
                    modalConfirmToastMessage: "Impossibile completare la rimozione dei contenuti associati e del compito selezionato."
                });
            }
        },
        err => {
            this.toggleDeleteAssignmentTaskUUID();
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Errore nella Rimozione!",
                modalConfirmToastMessage: "Impossibile completare la rimozione del compito selezionato. Errore: " + err.message
            });
        });
    }

    publishToAPP = (tour_uuid, enrich_uuid) => {
        axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort +
        "/tours/" + tour_uuid + "/enrichments/" + enrich_uuid + "/publish", {},
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Pubblicazione del Percorso",
                    modalConfirmToastMessage: "Contenuti associati al percorso pubblicati con successo."
                }, () => this.loadTaskTours());
            }
            else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Errore nella Pubblicazione!",
                    modalConfirmToastMessage: "Impossibile completare la pubblicazione dei contenuti del percorso."
                });
            }
        },
        err => {
            this.toggleDeleteAssignmentTaskUUID();
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Errore nella Pubblicazione!",
                modalConfirmToastMessage: "Impossibile completare la pubblicazione del percorso selezionato. Errore: " + err.message
            });
        });
    }

    exportTaskToPDF = (obj = null, type = null) => {
        this.setState({
            modalExportPDFTask:    (obj && type) ? true : false,
            entityExportPdfObject: obj,
            entityExportPdfType:   type
        });
    }

    getCardHTML = (obj, index, type) => {
        const obj_uuid       = (type === "tour") ? obj.tour_uuid        : obj.card_uuid;
        const obj_title      = (type === "tour") ? obj.tour_title       : obj.card_title;
        const obj_image      = (type === "tour") ? obj.tour_image       : obj.card_image;
        const obj_dcreate    = (type === "tour") ? obj.tour_date_create : obj.card_date_create;
        const obj_uupdate    = (type === "tour") ? obj.tour_date_update : obj.card_date_update;
        const mode_or_enrich = (type === "tour") ? obj.enrich_uuid      : "S";

        return (
            <Card key={obj.card_uuid + "-" + index}>
                {(type === "tour") ?
                    <div className="arrow-right">
                        <i className={"fas " + obj.tour_category_data.icons.fa + " arrow-right-icon"} />
                    </div>
                : null}
                <CardImg
                    top
                    width="100%"
                    src={
                        this.getCardImage(obj_image)
                    }
                    onClick={() => this.goToCardDetail(
                        obj_uuid,
                        mode_or_enrich,
                        type,
                        obj.enrich_uuid
                    )}
                    onError={this.setDefaultImgSrc}
                />
                <div className="card-status" style={{
                    backgroundColor: obj.task_color,
                    color: obj.task_fg_color
                }}>
                    {this.getStatusLabel(obj.status_code)}
                </div>
                <div className="card-task-name">{obj.task_name}</div>
                <CardBody>
                    <CardTitle>{obj_title}</CardTitle>
                    <CardText>
                        {(type === "tour") ?
                            <small className="text-muted">
                                <b>Categoria:</b> {obj.tour_category_name}
                            </small>
                        : null}
                        <br />
                        <small className="text-muted">
                            <b>Assegnato:</b> {this.getCardOwner(obj)}
                        </small>
                        <br />
                        <small className="text-muted">
                            <b>Creato:</b> {this.getFromNowDate(obj_dcreate)}
                        </small>
                        <br />
                        <small className="text-muted">
                            <b>Modificato:</b> {this.getFromNowDate(obj_uupdate)}
                        </small>
                    </CardText>
                </CardBody>
                <div className={'bottom-icons' + (() => {
                    // Check for tour
                    if (type === "tour") {
                        // Send n. buttons
                        return (
                            holdDataService.loggedUserIsTutor() ?
                            ' bottom-icons-two-cols' :
                            ''
                        );
                    }
                    // ... Otherwise cards
                    else {
                        // Send n. buttons
                        return (
                            holdDataService.loggedUserIsTutor() ?
                            ' bottom-icons-three-cols' :
                            ' bottom-icons-two-cols'
                        );
                    }
                })()}>
                    <div
                        onClick={() => this.goToCardDetail(
                            obj_uuid,
                            mode_or_enrich,
                            type,
                            obj.enrich_uuid
                        )}
                        data-tip data-for={"TIPS_FOR_V_" + obj_uuid}>
                        <FontAwesomeIcon
                            className='icon'
                            icon={this.getStatusBtn(
                                obj.status_code,
                                true
                            )}
                        />
                        <ReactTooltip
                            id={"TIPS_FOR_V_" + obj_uuid}
                            wrapper="span"
                            place="top"
                            effect="solid"
                            className="tooltip-user">
                            Premere per {this.getStatusBtn(
                                obj.status_code,
                                false
                            )} il compito.
                        </ReactTooltip>
                    </div>
                    {
                        (type !== "tour") ?
                            <div
                                onClick={() => this.exportTaskToPDF(obj, type)}
                                data-tip data-for={"TIPS_FOR_PDF_" + obj_uuid}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={faFilePdf}
                                />
                                <ReactTooltip
                                    id={"TIPS_FOR_PDF_" + obj_uuid}
                                    wrapper="span"
                                    place="top"
                                    effect="solid"
                                    className="tooltip-user">
                                    Premere per esportare il compito in PDF.
                                </ReactTooltip>
                            </div>
                        : null
                    }
                    {
                        holdDataService.loggedUserIsTutor() ?
                            <div className="red-action-btn"
                                onClick={() => this.toggleDeleteAssignmentTaskUUID(
                                    obj.assign_uuid,
                                    obj.task_uuid
                                )}
                                data-tip data-for={"TIPS_FOR_DELETE_" + obj_uuid}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={faTrash}
                                />
                                <ReactTooltip
                                    id={"TIPS_FOR_DELETE_" + obj_uuid}
                                    wrapper="span"
                                    place="top"
                                    effect="solid"
                                    className="tooltip-user">
                                    ATTENZIONE: Permette la rimozione del compito selezionato!
                                </ReactTooltip>
                            </div>
                        : null
                    }
                </div>
                {
                    type === "tour" &&
                    obj.status_code === "FW_ATER_0003" &&
                    holdDataService.loggedUserIsTutor() ? (
                        <div className={'bottom-icons'}>
                            <div
                                className={(
                                    obj.tour_publish === obj.enrich_uuid
                                ) ? "red-action-btn" : ""}
                                style={(
                                    obj.tour_publish === obj.enrich_uuid
                                ) ? {cursor: "default"} : null}
                                onClick={() => {
                                    if (obj.tour_publish !== obj.enrich_uuid) {
                                        this.publishToAPP(obj.tour_uuid, obj.enrich_uuid);
                                    }
                                }}
                                data-tip data-for={"TIPS_FOR_P_" + obj_uuid}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={faMapPin}
                                /> &nbsp; {
                                    obj.tour_publish === obj.enrich_uuid ?
                                    "Fruibile via" : "Pubblica su"
                                } APP
                                <ReactTooltip
                                    id={"TIPS_FOR_P_" + obj_uuid}
                                    wrapper="span"
                                    place="top"
                                    effect="solid"
                                    className="tooltip-user">
                                    {
                                        (obj.tour_publish === obj.enrich_uuid) ?
                                        "Percorso fruibile mediante applicazione mobile dedicata!" :
                                        "Premere per pubblicare il compito su APP mobile per la fruizione."
                                    }
                                </ReactTooltip>
                            </div>
                        </div>
                    ) : null
                }
            </Card>
        );
    }

    render() {
        let tourPages = Math.ceil(
            this.state.tourTotalEntities /
            this.state.tourPageLimit
        );

        let cardPages = Math.ceil(
            this.state.cardTotalEntities /
            this.state.cardPageLimit
        );

        tourPages = (tourPages <= 0) ? 1 : tourPages;
        cardPages = (cardPages <= 0) ? 1 : cardPages;

        return (
            <Fragment>
                {
                    this.state.loadingTasks ?
                        <EdubbaLoader />
                    : null
                }
                <Container className='Tasks'>
                    <Row>
                        <Col lg='12' md='12' sm='12'>
                            <div className="task-container">
                                <div className="task-container-title">Arricchimenti Schede:</div>
                                <div className="task-container-search">
                                    <InputGroup>
                                        <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.cardSplitButtonOpen} toggle={this.cardToggleSplit}>
                                            <Button disabled={true}>
                                                <b>Pagina: &nbsp;</b>{ this.state.cardPageNumber }
                                            </Button>
                                            { cardPages > 1 ? <DropdownToggle split /> : null }
                                            <DropdownMenu>
                                                {
                                                    // Gen. pages
                                                    (() => {
                                                        let pages = [];
                                                        for (let i = 1; i <= cardPages; i++) {
                                                            if (this.state.cardPageNumber === i) {
                                                                continue;
                                                            }
                                                            else pages.push(
                                                                <DropdownItem
                                                                    key={"card-page-" + i}
                                                                    onClick={() => this.goToCardPage(i)}
                                                                >
                                                                    Vai alla Pagina: &nbsp;{i}
                                                                </DropdownItem>
                                                            )
                                                        }
                                                        return pages;
                                                    })()
                                                }
                                            </DropdownMenu>
                                        </InputGroupButtonDropdown>
                                        <Input
                                            value={this.state.searchCardValue}
                                            onChange={this.handleCardSearch}
                                            onKeyUp={this.searchCard}
                                            placeholder="Premere invio per cercare..."
                                        />
                                        {
                                            !this.isEmpty(this.state.searchCardValue) ? (
                                                <InputGroupAddon addonType="append">
                                                    <Button onClick={this.clearSearchCards}>
                                                        <FontAwesomeIcon className='icon' icon={faTrash} />
                                                    </Button>
                                                </InputGroupAddon>
                                            ) : null
                                        }
                                    </InputGroup>
                                </div>
                                <div className="task-container-arrow-left">
                                    <Button disabled={this.state.cardPageNumber <= 1} onClick={() => this.goToCardPage(
                                        this.state.cardPageNumber - 1
                                    )}>
                                        <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                    </Button>
                                </div>
                                <div className="task-container-arrow-right">
                                    <Button disabled={this.state.cardPageNumber >= cardPages} onClick={() => this.goToCardPage(
                                        this.state.cardPageNumber + 1
                                    )}>
                                        <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                    </Button>
                                </div>
                                <div className="task-container-list">{
                                    this.state.taskCards.length > 0 ?
                                        <div className="list-tasks-grid">{
                                            this.state.taskCards.map((obj, index) => {
                                                return this.getCardHTML(obj, index, "card");
                                            })
                                        }</div>
                                    : (
                                        <div style={{padding: "15px"}}>
                                            <Alert color="light" className="mt-3">
                                                <h4 className="alert-heading">Nessun compito trovato!</h4>
                                                <p>
                                                    Complimenti hai svolto tutti i compiti scheda assegnati.<br />
                                                    Appena sarà disponibile un nuovo compito sarà visibile nella sezione corrente.
                                                </p>
                                            </Alert>
                                        </div>
                                    )
                                }</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12' md='12' sm='12'>
                            <div className="task-container">
                                <div className="task-container-title">Creazioni Tours:</div>
                                <div className="task-container-search">
                                    <InputGroup>
                                        <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.tourSplitButtonOpen} toggle={this.tourToggleSplit}>
                                            <Button disabled={true}>
                                                <b>Pagina: &nbsp;</b>{ this.state.tourPageNumber }
                                            </Button>
                                            { tourPages > 1 ? <DropdownToggle split /> : null }
                                            <DropdownMenu>
                                                {
                                                    // Gen. pages
                                                    (() => {
                                                        let pages = [];
                                                        for (let i = 1; i <= tourPages; i++) {
                                                            if (this.state.tourPageNumber === i) {
                                                                continue;
                                                            }
                                                            else pages.push(
                                                                <DropdownItem
                                                                    key={"tour-page-" + i}
                                                                    onClick={() => this.goToTourPage(i)}
                                                                >
                                                                    Vai alla Pagina: &nbsp;{i}
                                                                </DropdownItem>
                                                            )
                                                        }
                                                        return pages;
                                                    })()
                                                }
                                            </DropdownMenu>
                                        </InputGroupButtonDropdown>
                                        <Input
                                            value={this.state.searchTourValue}
                                            onChange={this.handleTourSearch}
                                            onKeyUp={this.searchTour}
                                            placeholder="Premere invio per cercare..."
                                        />
                                        {
                                            !this.isEmpty(this.state.searchTourValue) ? (
                                                <InputGroupAddon addonType="append">
                                                    <Button onClick={this.clearSearchTours}>
                                                        <FontAwesomeIcon className='icon' icon={faTrash} />
                                                    </Button>
                                                </InputGroupAddon>
                                            ) : null
                                        }
                                        <InputGroupButtonDropdown addonType="append" isOpen={this.state.tourCatSplitButtonOpen} toggle={this.tourCatToggleSplit}>
                                            <DropdownToggle split>
                                                {
                                                    this.state.tourCatCodeFilter !== "" &&
                                                    this.state.tourCatNameFilter !== "" ?
                                                    "Filtra: " + this.state.tourCatNameFilter :
                                                    "Tutte le Categoria"
                                                } &nbsp;
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem
                                                    key={"tour-category-" + 0}
                                                    onClick={() => this.setTourCatFilter("")}
                                                >
                                                    Visualizza: &nbsp;Tutte
                                                </DropdownItem>
                                                {
                                                    this.state.tour_categories.map((item, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={"tour-category-" + (index + 1)}
                                                                onClick={() => this.setTourCatFilter(item.category_code, item.category_name)}
                                                            >
                                                                Visualizza: &nbsp;{item.category_name}
                                                            </DropdownItem>
                                                        );
                                                    })
                                                }
                                            </DropdownMenu>
                                        </InputGroupButtonDropdown>
                                    </InputGroup>
                                </div>
                                <div className="task-container-arrow-left">
                                    <Button disabled={this.state.tourPageNumber <= 1} onClick={() => this.goToTourPage(
                                        this.state.tourPageNumber - 1
                                    )}>
                                        <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                    </Button>
                                </div>
                                <div className="task-container-arrow-right">
                                    <Button disabled={this.state.tourPageNumber >= tourPages} onClick={() => this.goToTourPage(
                                        this.state.tourPageNumber + 1
                                    )}>
                                        <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                    </Button>
                                </div>
                                <div className="task-container-list">{
                                    this.state.taskTours.length > 0 ?
                                        <div className="list-tasks-grid">{
                                            this.state.taskTours.map((obj, index) => {
                                                return this.getCardHTML(obj, index, "tour");
                                            })
                                        }</div>
                                    : (
                                        <div style={{padding: "15px"}}>
                                            <Alert color="light" className="mt-3">
                                                <h4 className="alert-heading">Nessun compito trovato!</h4>
                                                <p>
                                                    Complimenti hai svolto tutti i compiti percorso assegnati.<br />
                                                    Appena sarà disponibile un nuovo compito sarà visibile nella sezione corrente.
                                                </p>
                                            </Alert>
                                        </div>
                                    )
                                }</div>
                            </div>
                        </Col>
                    </Row>
                    {/* v EXPORT PDF v */}
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalExportPDFTask}
                        toggle={() => this.exportTaskToPDF()}>
                        <ModalHeader toggle={() => this.exportTaskToPDF()}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Esportazione PDF
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                Stiamo generando il documento richiesto a partire dalle informazioni presenti nel compito. Una volta disponibile sarà possibile scaricarlo premendo sul seguente bottone:<br />
                                {this.state.entityExportPdfObject ?
                                    <div style={{textAlign: 'center', marginTop: '20px'}}>
                                        <CardExportPDF
                                            inlineView={false}
                                            entityData={this.state.entityExportPdfObject}
                                            entityType={this.state.entityExportPdfType}
                                        />
                                    </div>
                                : null}
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={() => this.exportTaskToPDF()}>Chiudi</Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ EXPORT PDF ^ */}
                    {/* v DELETE ASSIGNMENT: modalDeleteAssignment v */}
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalDeleteAssignmentTask}
                        toggle={this.toggleDeleteAssignmentTaskUUID}
                    // backdrop='static'
                    >
                        <ModalHeader toggle={this.toggleDeleteAssignmentTaskUUID}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Rimozione di un Compito
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                Vuoi davvero rimuovere il compito selezionato e i contenuti ad esso associati?
                                L'operazione non sarà reversibile una volta completata!
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.toggleDeleteAssignmentTaskUUID}>Annulla</Button>
                            <Button
                                className='btn-edubba'
                                onClick={this.deleteAssignmentTask}
                            >
                                Rimuovi
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ DELETE ASSIGNMENT: modalDeleteAssignment ^ */}
                    {/* v CONFIRM: modalConfirmToast v */}
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalConfirmToast}
                        toggle={this.closeModalConfirmToast}>
                        <ModalHeader toggle={this.closeModalConfirmToast}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        {this.state.modalConfirmToastTitle}
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                {this.state.modalConfirmToastMessage}
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ CONFIRM: modalConfirmToast ^ */}
                </Container>
            </Fragment >
        )
    }
}

export default withRouter(Tasks);
