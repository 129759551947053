import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Label,
    Input,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faClipboardList,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import EdubbaLoader from '../components/EdubbaLoader/EdubbaLoader';
import holdDataService from './../services/holdDataService/holdDataService';
import EdubbaDefaultImage from './../assets/utils/images/edubba_placeholder.png'
import globalVars from './../globalVars.json';
import './AssignmentDetail.scss';

class AssignmentDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            assignmentUuid: "",
            refProjectUuid: "",
            refGroupUuid: "",
            assignmentDescr: "",
            assignmentDateStart: null,
            assignmentDateEnd: null,
            assignmentTasks: [],
            errorMessage: "",
            // confirm
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
            // delete assignment
            modalDeleteAssignment: false
        };
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'assignment-detail';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';

        this.getAssignment();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.assign_uuid !== state.assignmentUuid) {
            return {
                assignmentUuid: props.match.params.assign_uuid
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.assignmentUuid !== this.state.assignmentUuid) {
            this.getAssignment();
        }
    }

    getAssignment = () => {
        let assign_uuid = this.props.match.params.assign_uuid;
        let query = '/assignments/' + assign_uuid;
        this.setState({ loading: true }, () => {
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {
                    auth: holdDataService.getAuthorization()
                }).then(assignment_res => {
                    let assignmentData = assignment_res.data.rowCount > 0 ? assignment_res.data.rows[0] : {};
                    let assignmentDescr = assignmentData.assign_descr;
                    let query = '/assignments/' + assign_uuid + '/tasks';
                    axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                        {
                            auth: holdDataService.getAuthorization()
                        }).then(tasks_res => {
                            let tasksData = tasks_res.data.rowCount > 0 ? tasks_res.data.rows : [];
                            this.setState({
                                loading: false,
                                assignmentUuid: assign_uuid,
                                refProjectUuid: assignmentData.project_uuid,
                                refGroupUuid: assignmentData.group_uuid,
                                assignmentDescr: assignmentDescr,
                                assignmentDateStart: assignmentData.assign_date_start,
                                assignmentDateEnd: assignmentData.assign_date_end,
                                assignmentTasks: tasksData,
                            }, () => {
                                this.props.setSectionTitle("Attività " + assignmentDescr);
                            });
                        }, err => {
                            this.setState({ loading: false });
                        });
                }, err => {
                    this.setState({ loading: false });
                });
        });
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDateStartChange = (startDate) => {
        if (startDate) {
            this.setState({
                assignmentDateStart: startDate.getTime(),
                errorMessage: ""
            });
        }
    }

    handleDateEndChange = (endDate) => {
        if (this.state.assignmentDateStart && endDate) {
            if (this.state.assignmentDateStart <= endDate.getTime()) {
                this.setState({
                    assignmentDateEnd: endDate.getTime(),
                    errorMessage: ""
                });
            }
            else {
                this.setState({
                    errorMessage: "La data di fine deve essere successiva a quella di inizio!"
                });

            }
        }
    }

    toggleDeleteAssignmentUUID = () => {
        this.setState({
            modalDeleteAssignment: !this.state.modalDeleteAssignment
        });
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    deleteAssignmentUUID = (evt) => {
        evt.preventDefault();
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/assignments/" + this.state.assignmentUuid,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.toggleDeleteAssignmentUUID();

            if (res.data.rowCount > 0) {
                this.props.history.push("/");
            }
            else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Errore nella Rimozione!",
                    modalConfirmToastMessage: "Impossibile completare la rimozione dei contenuti associati e dell'attività selezionata."
                });
            }
        },
        err => {
            this.toggleDeleteAssignmentUUID();
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Errore nella Rimozione!",
                modalConfirmToastMessage: "Impossibile completare la rimozione dell'attività selezionata. Errore: " + err.message
            });
        });
    }

    render() {
        return (
            <Fragment>
                {this.state.loading ?
                    <EdubbaLoader />
                    :
                    <div className="AssignmentDetail">
                        <Container>
                            <div className="data-field desc-field">
                                <Label for="assignmentDescr" className="textarea-label">
                                    DESCRIZIONE DELL'ATTIVITÀ:
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="trash-assignment-icon"
                                        data-tip data-for="trash-assignment"
                                        onClick={this.toggleDeleteAssignmentUUID}
                                    />
                                    <ReactTooltip
                                        id="trash-assignment"
                                        wrapper="span"
                                        place="top"
                                        effect="solid"
                                        className="tooltip-user"
                                    >
                                        ATTENZIONE: Permette la rimozione dell'attività in esame!
                                    </ReactTooltip>
                                </Label>
                                <Input
                                    type="textarea"
                                    id="assignmentDescr"
                                    className="textarea-Input"
                                    name="assignmentDescr"
                                    value={this.state.assignmentDescr}
                                    onChange={this.handleChange}
                                    disabled={true}
                                />
                            </div>
                            <div className="date-container datepicker-field">
                                <div className="data-field">
                                    <Label for="assignmentDateStart" className="datepicker-label">
                                        DATA DI INIZIO ATTIVITÀ:
                                    </Label>
                                    <DatePicker
                                        id="assignmentDateStart"
                                        className="datepicker"
                                        name="assignmentDateStart"
                                        selected={this.state.assignmentDateStart}
                                        selectsStart
                                        startDate={this.state.assignmentDateStart}
                                        endDate={this.state.assignmentDateEnd}
                                        dateFormat={'dd/MM/yyyy'}
                                        onChange={this.handleDateStartChange}
                                        disabled={true}
                                    />
                                </div>
                                <div className="data-field">
                                    <Label for="assignmentDateEnd" className="datepicker-label">
                                        DATA DI FINE ATTIVITÀ:
                                    </Label>
                                    <DatePicker
                                        id="assignmentDateEnd"
                                        className="datepicker"
                                        name="assignmentDateEnd"
                                        selected={this.state.assignmentDateEnd}
                                        selectsEnd
                                        startDate={this.state.assignmentDateStart}
                                        endDate={this.state.assignmentDateEnd}
                                        dateFormat={'dd-MM-yyyy'}
                                        onChange={this.handleDateEndChange}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="data-field tasks-field">
                                <Label className="tasks-label">
                                    LISTA COMPITI ASSEGNATI ALL'ATTIVITÀ:
                                </Label>
                                {this.state.assignmentTasks.length === 0 ? <div className="no-tasks">
                                    Nessun compito associato a questa Attività!
                                    </div> : <div className="tasks-container">
                                        {this.state.assignmentTasks.map(t => <TaskRenderer
                                            key={t.task_uuid}
                                            task={t}
                                            history={this.props.history}
                                        />)}
                                    </div>}
                            </div>
                            <div className='data-field'>
                                <div className="error-message">
                                    {this.state.errorMessage}
                                </div>
                            </div>
                        </Container>
                        {/* v DELETE ASSIGNMENT: modalDeleteAssignment v */}
                        <Modal className='edubba-modal'
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            size='md'
                            isOpen={this.state.modalDeleteAssignment}
                            toggle={this.toggleDeleteAssignmentUUID}
                        // backdrop='static'
                        >
                            <ModalHeader toggle={this.toggleDeleteAssignmentUUID}>
                                <div className='widget-content p-0'>
                                    <div className='widget-content-wrapper'>
                                        <div className='widget-content-left mr-3 text-center w-100'>
                                            Rimozione di un'attività
                                        </div>
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Container className='help-text'>
                                    Vuoi davvero rimuovere l'attività selezionata e i contenuti ad esso associati?
                                    L'operazione non sarà reversibile una volta completata!
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button className='btn-edubba' onClick={this.toggleDeleteAssignmentUUID}>Annulla</Button>
                                <Button
                                    className='btn-edubba'
                                    onClick={this.deleteAssignmentUUID}
                                    disabled={this.state.assignmentUuid === ""}
                                >
                                    Rimuovi
                                </Button>
                            </ModalFooter>
                        </Modal>
                        {/* ^ DELETE ASSIGNMENT: modalDeleteAssignment ^ */}
                        {/* v CONFIRM: modalConfirmToast v */}
                        <Modal className='edubba-modal'
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            size='md'
                            isOpen={this.state.modalConfirmToast}
                            toggle={this.closeModalConfirmToast}>
                            <ModalHeader toggle={this.closeModalConfirmToast}>
                                <div className='widget-content p-0'>
                                    <div className='widget-content-wrapper'>
                                        <div className='widget-content-left mr-3 text-center w-100'>
                                            {this.state.modalConfirmToastTitle}
                                        </div>
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Container className='help-text'>
                                    {this.state.modalConfirmToastMessage}
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                            </ModalFooter>
                        </Modal>
                        {/* ^ CONFIRM: modalConfirmToast ^ */}
                    </div>}
            </Fragment>
        )
    }
}

class TaskRenderer extends Component {
    constructor(props) {
        super(props);
        // task: JSON

        this.state = {};
    }

    __getName = (name, short) => {
        if (short) {
            return name[0] + ".";
        }
        else {
            return name;
        }
    }

    getCardOwner = (obj, short_name = false) => {
        if (obj &&
            obj.task_owner &&
            obj.task_owner.user_profile &&
            obj.task_owner.user_profile.firstName &&
            obj.task_owner.user_profile.lastName) {
            return (
                this.__getName(obj.task_owner.user_profile.firstName, short_name) +
                " " +
                obj.task_owner.user_profile.lastName
            );
        }

        return "Non disponibile"
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponibile";
    }

    getStatusBtn = (status_code, asIcon = false) => {
        if (asIcon) {
            switch (status_code) {
                // Evaluating
                case "FW_CDER_0004":
                case "FW_ATER_0002":
                    return faClipboardList;
                // Draft
                // Draft-Comments
                // Pre-Evaluating
                // Validating
                // Validated
                case "FW_CDER_0001":
                case "FW_CDER_0002":
                case "FW_CDER_0003":
                case "FW_CDER_0005":
                case "FW_CDER_0006":
                case "FW_ATER_0001":
                case "FW_ATER_0003":
                default:
                    return faEye;
            }
        }
        else {
            switch (status_code) {
                // Evaluating
                case "FW_CDER_0004":
                case "FW_ATER_0002":
                    return "valutare";
                // Draft
                // Draft-Comments
                // Pre-Evaluating
                // Validating
                // Validated
                case "FW_CDER_0001":
                case "FW_CDER_0002":
                case "FW_CDER_0003":
                case "FW_CDER_0005":
                case "FW_CDER_0006":
                case "FW_ATER_0001":
                case "FW_ATER_0003":
                default:
                    return "visualizzare";
            }
        }
    }

    userCanOperate = (status_code) => {
        if (holdDataService.loggedUserIsTutor()) {
            return true;
        }

        return false;
    }

    goToCardDetail = (card_uuid, mode, enrich_uuid) => {
        this.props.history.push('/card-detail/' + mode + '/' + card_uuid,
        {
            "src_type":    "assigneddetails",
            "card_uuid":   card_uuid,
            "enrich_uuid": enrich_uuid
        })
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    render() {
        const obj_uuid    = (this.props.cardType === "tour") ?this.props.task.tour_uuid   : this.props.task.card_uuid;
        const enrich_uuid = (this.props.cardType === "tour") ?this.props.task.enrich_uuid : this.props.task.enrich_uuid;
        const obj_title   = (this.props.cardType === "tour") ?this.props.task.tour_title  : this.props.task.card_title;
        const obj_image   = (this.props.cardType === "tour") ?this.props.task.tour_image  : this.props.task.card_image;

        return (
            <Card className="TaskCardRenderer">
                <CardImg top width="100%" src={obj_image} onError={this.setDefaultImgSrc} />
                <div className="task-status" style={{
                    backgroundColor: this.props.task.task_color,
                    color: this.props.task.task_fg_color
                }}>
                    {this.props.task.task_name}
                </div>
                <CardBody>
                    <CardTitle>{obj_title}</CardTitle>
                    <CardText>
                        <small className="text-muted">
                            <b>Assegnato a:</b> {this.getCardOwner(this.props.task, true)}
                        </small>
                        <br />
                        <small className="text-muted">
                            <b>Creato:</b> {this.getFromNowDate(this.props.task.task_date_create)}
                        </small>
                        <br />
                        <small className="text-muted">
                            <b>Modificato:</b> {this.getFromNowDate(this.props.task.task_date_update)}
                        </small>
                        <br />
                        <small className="text-muted">
                            <b>Data di Inizio:</b> {this.getFromNowDate(this.props.task.task_date_start)}
                        </small>
                        <br />
                        <small className="text-muted">
                            <b>Data di Fine:</b> {this.getFromNowDate(this.props.task.task_date_end)}
                        </small>
                    </CardText>
                </CardBody>
                {
                    this.userCanOperate(this.props.task.status_code) ?
                        <div className='bottom-icons'>
                            <div
                                onClick={() => this.goToCardDetail(obj_uuid, "S", enrich_uuid)}
                                data-tip data-for={"TIPS_FOR_V_" + obj_uuid}>
                                <FontAwesomeIcon
                                    className='icon'
                                    icon={this.getStatusBtn(
                                        this.props.task.status_code,
                                        true
                                    )}
                                />
                                <ReactTooltip
                                    id={"TIPS_FOR_V_" + obj_uuid}
                                    wrapper="span"
                                    place="top"
                                    effect="solid"
                                    className="tooltip-user">
                                    Premere per {this.getStatusBtn(
                                        this.props.task.status_code,
                                        false
                                    )} la scheda.
                                </ReactTooltip>
                            </div>
                        </div>
                    : null
                }
            </Card>
        )
    }
}

export default withRouter(AssignmentDetail);
