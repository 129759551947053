import React, { Fragment } from 'react';

import cx from 'classnames';

class SearchBox extends React.Component {
    constructor(props) {
        // handleSearch: Func
        // handleClose: Func
        // myPlaceholder: String

        super(props);

        this.state = {
            activeSearch: false
        };
    }

    render() {
        return (
            <Fragment>
                <div className={cx("search-wrapper", "edubba-search", {
                    'active': this.state.activeSearch
                })}>
                    <div className="input-holder">
                        <input id='search-input' autoComplete='off' type="text" className="search-input" value={this.props.text} placeholder={this.props.myPlaceholder} onChange={this.props.handleSearch} />
                        <button onClick={() => this.setState({
                            activeSearch: !this.state.activeSearch
                        }, () =>
                                document.getElementById("search-input").focus()
                        )}
                            className="search-icon"><span /></button>
                    </div>
                    <button onClick={() => this.setState({ activeSearch: !this.state.activeSearch }, this.props.handleClose)} className="close" />
                </div>
            </Fragment>
        )
    }
}

export default SearchBox;