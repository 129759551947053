import React, { Component,  } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Alert
} from 'reactstrap';

class RequiredProjectCertifier extends Component {
  constructor() {
    super();

    this.state = {
    };
  }

  render() {
    return (
      <Modal className='edubba-modal'
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size='md'
        isOpen={true}
        toggle={this.props.toggleRequiredProjectCertifier}
        backdrop='static'>
        <ModalHeader toggle={this.props.toggleRequiredProjectCertifier}>
          <div className='widget-content p-0'>
            <div className='widget-content-wrapper'>
              <div className='widget-content-left mr-3'>
                <i className='pe-7s-note2 icon pe' />
              </div>
              <div className='widget-content-left mr-3 text-center w-100'>
                Attenzione!
              </div>
            </div>
          </div>
        </ModalHeader>
          <span>
            <ModalBody>
                <Alert color="light" className="mt-3">
                    <h4 className="alert-heading">Progetto privo di Certificatori!</h4>
                    <p>
                        Non è ancora stato associato un Certificatore al corrente Progetto!<br />
                        Non sarà possibile inviare in certificazione la scheda fintanto che un utenza Certificatore non verrà associata al Progetto in lavorazione.
                    </p>
                    <hr />
                    <p className="mb-0">
                        Per associare un Certificatore al Progetto premere sul tasto dedicato, nella Dashboard, in corrispondenza del Progetto selezionato.
                    </p>
                </Alert>
            </ModalBody>
            <ModalFooter className='catalog-buttons'>
                <Button className='btn-edubba' onClick={this.props.toggleRequiredProjectCertifier}>
                    Torna alla Scheda
                </Button>
            </ModalFooter>
          </span>
      </Modal>
    );
  }
}

export default withRouter(RequiredProjectCertifier);
