import React, { Fragment } from 'react';
import { Col, Row, Button } from 'reactstrap';
import ReactSVG from 'react-svg';
import axios from 'axios';
import userLogoSrc from './../../assets/utils/images/user.svg';
import { ListGroup, ListGroupItem, Card } from 'reactstrap';
import holdDataService from './../../services/holdDataService/holdDataService';
import { withRouter } from 'react-router-dom';
import globalVars from './../../globalVars.json';

class UserCard extends React.Component {
    constructor(props) {
        super(props);
        // userName: String
        // userType: String
        // groups: Integer
        // cards: Integer
        // registerLogout: Func
        this.state = {
            groups: [],
        }

    }

    componentDidMount() {
        this.getGroups();
    }

    getGroups = () => {
        // let authorizationToken = holdDataService.getAuthorization();
        // if(authorizationToken)
        //     axios.get(globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/groups',
        //         {
        //             auth: holdDataService.getAuthorization()
        //         }
        //     ).then(res => {
        //         console.log(res);
        //         if (res.data.rowCount > 0) {
        //             this.setState({
        //                 groups: res.data.rows
        //             })
        //         }
        //     }).catch(error => {
        //         console.log(error);
        //     })
    }

    logout = () => {
        let userQuery = globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/auth/logout';
        axios.post(userQuery, {}, { auth: holdDataService.getAuthorization() }).then(res => {
            this.props.registerLogout();
            holdDataService.deleteAllData();
            this.props.history.push('/login');
        },
        err => {
            console.log(err);
        });
    }

    render() {
        let isTutor = holdDataService.loggedUserIsTutor();

        return (
            <Fragment>
                <div className="UserCard">
                    <Card className="card-shadow-primary profile-responsive card-border">
                        <div className="dropdown-menu-header">
                            <div className="dropdown-menu-header-inner">
                                <div className="container header" style={{padding: '0px 15px'}}>
                                    <div className="row">
                                        <div className="my-svg col-sm-3">
                                            <ReactSVG className='my-svg-inner' src={userLogoSrc} />
                                        </div>
                                        <div className='user-data col-sm-9'>
                                            <div className="name">{this.props.userName}</div>
                                            <div className="institute">{this.props.userType}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <Button className="btn bg-edubba text-white col-sm-3"
                                                onClick={this.logout}>
                                                Logout
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !holdDataService.loggedUserIsAdmin() &&
                            !holdDataService.loggedUserIsContentAdmin() &&
                            !holdDataService.loggedUserIsOnlyCertifier() &&
                            !holdDataService.loggedUserIsOnlySupervisor() ?
                            <ListGroup flush>
                                <ListGroupItem className="p-0">
                                    <div className="grid-menu grid-menu-2col">
                                        <Row className="no-gutters">
                                            <Col sm="6" style={{border: '0px', paddingBottom: '1px'}}>
                                                <div className="container-button-cta" onClick={(event) => {
                                                        event.preventDefault();
                                                        this.props.history.push('/cards')
                                                    }}>
                                                    <Button
                                                        className="btn-icon-vertical btn-square btn-transition"
                                                        outline color="link">
                                                        <i className="pe-7s-note2 btn-icon-wrapper btn-icon-lg mt-3"></i>
                                                        {isTutor ? 'Le mie Schede' : 'Schede Assegnate'}
                                                        <div className="badge badge-dot badge-number">Totali: {this.props.cards}</div>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col sm="6" style={{border: '0px', paddingBottom: '1px'}}>
                                                <div className="container-button-cta" onClick={(event) => {
                                                        event.preventDefault();
                                                        this.props.history.push('/groups')
                                                    }}>
                                                    <Button
                                                        className="btn-icon-vertical btn-square btn-transition"
                                                        outline color="link">
                                                        <i className="pe-7s-users btn-icon-wrapper btn-icon-lg mt-3"></i>
                                                        {isTutor ? 'I miei Gruppi' : 'Gruppi'}
                                                        <div className="badge badge-dot badge-number">Totali: {this.props.groups}</div>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                            : null
                        }
                        <div className="help-button">
                            <div className="container-button-cta" style={{padding: "5px 0px"}} onClick={(event) => {
                                    event.preventDefault();
                                    this.props.history.push('/help')
                                }}>
                                <Button
                                    className="btn-icon-vertical btn-square btn-transition"
                                    outline color="link">
                                    <i className="pe-7s-help1 btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    <span>Help</span>
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </Fragment >
        )
    }
}

export default withRouter(UserCard);
