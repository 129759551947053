import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
// import logoEdubba from './../imgs/logo_edubba.png';
import globalVars from './../globalVars.json';
import moment from 'moment';
import {
    Row, Col, Button, Container, Input,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Label
} from 'reactstrap';

import holdDataService from './../services/holdDataService/holdDataService';
import LastActivity from './LastActivity/LastActivity';
import MyTasks from './MyTasks/MyTasks';
import MyProjects from './MyProjects/MyProjects';
import MyCards from './MyCards/MyCards';
import AssignedCards from './AssignedCards/AssignedCards';

import './Dashboard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import EdubbaLoader from '../components/EdubbaLoader/EdubbaLoader.js';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        // notifications: Array
        // updateProjects: Function

        this.state = {
            data: '',
            dropdownOpen: false,

            projectName: '',
            modalCreateNewProject: false,

            assignmentType: '',
            assignTypes: [],
            assignmentDescription: '',
            groupUuid: '',
            modalCreateNewAssignment: false,

            today: new Date().getTime(),
            startDate: new Date().getTime(),
            endDate: null,

            groups: [],

            errorMessage: '',
            newCreatedProject: null,
            loading: false,
            cardTasks: [],
            tourTasks: [],
            projects: [],
            selectedProject: "",
            modalNewTask: false,
        };

    }

    componentWillMount() {
        let query = '/tasks';
        this.setState({ loading: true }, () => {
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    this.setState({
                        cardTasks: (
                            res.data &&
                            res.data.rowCount > 0
                        ) ? res.data.rows[0].cards : [],
                        tourTasks: (
                            res.data &&
                            res.data.rowCount > 0
                        ) ? res.data.rows[0].tours : []
                    });
                }, err => {
                    //
                });
        });
    }

    componentDidMount() {
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';
        this.props.setSectionTitle('none');

        this.loadGroups();
        this.loadProjects();
        this.loadAssignTypes();
    }

    componentWillUnmount() {
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    loadProjects = () => {
        let query = '/projects';
        this.setState({ loading: true })
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let projects = [];
                if (res.data.rowCount > 0) {
                    projects = res.data.rows
                }
                this.setState({
                    projects: projects,
                    loading: false
                }/*, () => {
                    this.props.updateProjects(projects);
                }*/);
            }, err => {
                this.setState({ loading: false })
            });
    }

    loadAssignTypes = () => {
        let query = '/assignments/types';
        this.setState({ loading: true })
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            let types = [];
            if (res.data.rowCount > 0) {
                types = res.data.rows
            }
            this.setState({
                assignTypes: types,
                loading: false
            });
        }, err => {
            this.setState({ loading: false })
        });
    }

    loadGroups = () => {
        let query = '/groups';
        this.setState({ loading: true }, () => {
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    let groups = [];
                    if (res.data.rowCount > 0) {
                        groups = res.data.rows;
                    }
                    this.setState({
                        groups: groups,
                    }, () => this.setState({ loading: false }));
                }, err => {
                    //
                });
        });
    }

    getDifferentHTML = () => {
        // ONLY TEACHERS
        if (holdDataService.loggedUserIsAdmin() ||
            holdDataService.loggedUserIsTutor()) {
            return <MyTasks
                cardTasks={this.state.cardTasks}
                tourTasks={this.state.tourTasks}
            />;
        }
        // ONLY CERTIFIERS
        else if (holdDataService.loggedUserIsCertifier()) {
            return <MyCards
                cardTasks={this.state.cardTasks}
                tourTasks={this.state.tourTasks}
            />;
        }
        // ONLY STUDENTS
        else {
            return <AssignedCards
                cardTasks={this.state.cardTasks}
                tourTasks={this.state.tourTasks}
            />;
        }
    }

    toggleCreateNewProject = () => {
        if (!this.state.modalCreateNewProject) {
            //Prima di mostrare la modale provvedo a resettare i campi
            this.setState({
                projectName: '',
                startDate: new Date().getTime(),
                endDate: null,
                newCreatedProject: null,
            })
        }
        this.setState({
            modalCreateNewProject: !this.state.modalCreateNewProject
        });
    }

    toggleCreateNewAssignment = () => {
        if (!this.state.modalCreateNewAssignment) {
            this.setState({
                assignmentDescription: '',
                groupUuid: '',
                startDate: new Date().getTime(),
                endDate: null,
            })
        }
        this.setState({
            modalCreateNewAssignment: !this.state.modalCreateNewAssignment
        });
    }

    handleChange = (event) => {
        event.preventDefault();
        let name = event.target.name;
        let errorMessage = this.state.errorMessage;
        if (name === 'projectName') {
            errorMessage = '';
        }
        this.setState({
            [name]: event.target.value,
            errorMessage: errorMessage
        })
    }

    handleChangeStart = (startDate) => {
        if (startDate) {
            if (moment(startDate.getTime()).isAfter(moment(this.state.today)))
                this.setState({ startDate: startDate.getTime(), errorMessage: '' })
            else
                this.setState({
                    errorMessage: 'Hai inserito una data inferiore a ' +
                        moment(new Date().getTime()).format('DD MMMM YY'),
                    startDate: new Date().getTime(),
                })
        }
    }

    handleChangeEnd = (endDate) => {
        if (this.state.startDate && endDate)
            if (this.state.startDate <= endDate.getTime())
                this.setState({ endDate: endDate.getTime(), errorMessage: '' })
            else
                this.setState({ errorMessage: 'Inserire una data di fine oltre quella di scadenza' })
    }

    createNewProject = () => {
        let projectName = this.state.projectName;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        if (projectName !== '' && startDate && endDate) {
            let query = '/projects';
            let postBody = {
                "project_name": projectName.trim(),
                "project_data": {},
                "project_date_start": startDate,
                "project_date_end": endDate
            }
            this.setState({ loading: true }, () => {
                axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                    postBody,
                    {
                        auth: holdDataService.getAuthorization()
                    })
                    .then(res => {
                        let newCreatedProject = null;
                        if (res.data.rowCount > 0) {
                            newCreatedProject = res.data.rows[0];
                        }
                        else {
                            newCreatedProject = null;
                        }
                        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                            {
                                auth: holdDataService.getAuthorization()
                            })
                            .then(res => {
                                let projects = [];
                                if (res.data.rowCount > 0) {
                                    projects = res.data.rows
                                }
                                this.setState({
                                    projects: projects,
                                    newCreatedProject: newCreatedProject,
                                    loading: false,
                                    modalCreateNewProject: false,
                                    selectedProject: newCreatedProject ? newCreatedProject.project_uuid : ""
                                }, () => {
                                    this.toggleCreateNewAssignment();
                                    let refreshProjectsEvent = new Event("refresh-projects-list");
                                    document.dispatchEvent(refreshProjectsEvent);
                                });
                            }, err => {
                                //
                            });
                    }, err => {
                        //
                    });
            });
        }
        else {
            this.setState({
                errorMessage: 'Inserire un nome per il Progetto'
            })
        }
    }

    createNewAssignment = () => {
        let justCreatedProject = this.state.newCreatedProject;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let projectUuid = justCreatedProject ? justCreatedProject.project_uuid : this.state.selectedProject;
        let assignmentType = this.state.assignmentType;
        let assignmentDescription = this.state.assignmentDescription;
        let groupUuid = this.state.groupUuid;
        if (assignmentDescription !== '' && startDate && endDate) {
            let query = '/assignments';
            let postBody = {
                "group_uuid": groupUuid,
                "project_uuid": projectUuid,
                "assign_type": assignmentType,
                "assign_descr": assignmentDescription,
                "assign_date_start": startDate,
                "assign_date_end": endDate,
            }

            this.setState({ loading: true }, () => {
                axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                    postBody,
                    {
                        auth: holdDataService.getAuthorization()
                    })
                    .then(res => {
                        let assignmentRes = res.data && res.data.rowCount > 0 ? res.data.rows[0] : {};
                        let socialRoomPayload = {};
                        socialRoomPayload.assign_uuid = assignmentRes.assign_uuid;
                        socialRoomPayload.room_name = assignmentRes.assign_descr;
                        socialRoomPayload.room_data = {};
                        let query = '/social-rooms/';
                        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, socialRoomPayload, {
                            auth: holdDataService.getAuthorization()
                        })
                        .then(res => {
                            this.setState({
                                loading: false
                            }, () => {
                                this.toggleCreateNewAssignment();
                                if (this.state.modalCreateNewProject) {
                                    this.toggleCreateNewProject();
                                }
                                let newSocialWallEvent = new Event("refresh-social-walls");
                                document.dispatchEvent(newSocialWallEvent);
                            });
                        }, err => {
                            //
                        });

                        // Force refresh
                        this.loadProjects();
                    }, err => {
                        //
                    });
            });
        }
        else {
            this.setState({
                errorMessage: "Inserire una descrizione per l'attività"
            })
        }
    }

    getGroupsOptions = () => {
        let optList = [];
        optList.push(<option key={`group-opt-default`} value="" disabled={true}>Seleziona gruppo...</option>)
        this.state.groups.map((g, index) => {
            optList.push(
                <option key={`group-opt-${index}`} value={g.group_uuid}>{g.group_name} ({g.group_members.length} membri)</option>
            );
            return true;
        })
        if (optList.length > 1)
            return optList;
        else {
            optList.push(<option key={`no-groups-opt`} value={-1} disabled={true}>Non possiedi nessun gruppo !</option>);
            return optList;
        }
    }

    handleProjectSelect = (event) => {
        this.setState({ selectedProject: event.target.value });
    }

    toggleNewTask = () => {
        this.setState({modalNewTask: !this.state.modalNewTask});
    }

    goToAssignmentPage = (action) => {
        switch (action) {
            case 'cards':
                this.setState(
                    { modalNewTask: false },
                    () => this.props.history.push('/cards')
                );
                break;

            case 'tours':
                this.setState(
                    { modalNewTask: false },
                    () => this.props.history.push('/tours')
                );
                break;

            default: break;
        }
    }

    render() {
        return (
            <Fragment>
                <div className='Dashboard'>
                    <Container>
                        {holdDataService.loggedUserIsTutor() ?
                            <Row className='btn-row mb-3 d-flex'>
                                <Col sm="12" lg="4" size="lg" className='ml-0'>
                                    <div className="ColButtonHint">
                                        <p className="HintColTitle"><span>1.</span>Definizione Progetti:</p>
                                        <p className="HintColDescr">
                                            Un Progetto permette la creazione di un set di attività utili a raggruppare i compiti assegnati a un gruppo.
                                        </p>
                                        <Button className='btn-edubba' onClick={this.toggleCreateNewProject}>
                                            CREA PROGETTO
                                        </Button>
                                    </div>
                                </Col>
                                <Col sm="12" lg="4" size="lg" className=''>
                                    <div className="ColButtonHint">
                                        <p className="HintColTitle"><span>2.</span>Definizione Attività:</p>
                                        <p className="HintColDescr">
                                            Un'attività permette di stabilire quali compiti assegnare a un gruppo insieme ad alcune impostazioni di base necessarie.
                                        </p>
                                        <Button className='btn btn-edubba' onClick={this.toggleCreateNewAssignment}>
                                            CREA ATTIVITÀ
                                        </Button>
                                    </div>
                                </Col>
                                <Col sm="12" lg="4" size="lg" className='mr-0'>
                                    <div className="ColButtonHint">
                                        <p className="HintColTitle"><span>3.</span>Definizione Compiti:</p>
                                        <p className="HintColDescr">
                                            Un Compito permette di assegnare delle attività da svolgere a singoli studenti di un gruppo.
                                        </p>
                                        <Button className='btn btn-edubba' onClick={this.toggleNewTask}>
                                            CREA COMPITO
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            : <br />}
                        {!holdDataService.loggedUserIsOnlyCertifier() ? <Row className='dashboard-row first-row'>
                            <Col className="dashboard-block" md="6" sm="12" lg="6">
                                <LastActivity notifications={this.props.notifications} />
                            </Col>
                            <Col className="dashboard-block" md="6" sm="12" lg="6">
                                {this.getDifferentHTML()}
                            </Col>
                        </Row> : <Row className='dashboard-row first-row'>
                                <Col className="dashboard-block" md="12" sm="12" lg="12">
                                    {this.getDifferentHTML()}
                                </Col>
                            </Row>}
                        {holdDataService.loggedUserIsOnlyCertifier() ? null : <Row className='second-row'>
                            <Col className="dashboard-block" sm="12" lg="12">
                                {this.state.loading ?
                                    <EdubbaLoader small={true} /> :
                                    <MyProjects
                                        projects={this.state.projects}
                                        createAssignment={project => {
                                            this.setState({ newCreatedProject: project },
                                                () => this.toggleCreateNewAssignment())
                                        }}
                                    />}
                            </Col>
                        </Row>}
                        {/* Modale per la creazione di un assignment */}
                        <Modal className='edubba-modal custom-modal'
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            size='md'
                            isOpen={this.state.modalCreateNewAssignment}
                            toggle={this.toggleCreateNewAssignment}
                            backdrop='static' >
                            <ModalHeader toggle={this.toggleCreateNewAssignment}>
                                <div className='widget-content p-0'>
                                    <div className='widget-content-wrapper'>
                                        <div className='widget-content-left mr-3'>
                                            <i className='pe-7s-note2 icon pe' />
                                        </div>
                                        <div className='widget-content-left mr-3 text-center w-100'>
                                            Creazione di un nuova attività
                                </div>
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Container>
                                    <Row className='custom-row'>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                            <div className='name'>
                                                SELEZIONA LA TIPOLOGIA DI ATTIVITÀ
                                            </div>
                                            <span className='white-row'>
                                                <select value={this.state.assignmentType} className='legacy-form-control white-select' name='assignmentType' onChange={this.handleChange}>
                                                    <option value="" disabled>Seleziona tipologia...</option>
                                                    {this.state.assignTypes.map((obj, index) => {
                                                        return <option key={"type_code_" + index} value={obj.type_code}>
                                                            {obj.type_literal}
                                                        </option>;
                                                    })}
                                                </select>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='custom-row with-textarea'>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                            <div className='name'>
                                                {/* SELEZIONA LA TIPOLOGIA DI ATTIVITÀ */}
                                                DESCRIZIONE DELL'ATTIVITÀ
                                        </div>
                                            <span className='white-row'>
                                                <Input type="textarea" value={this.state.assignmentDescription} className='' name='assignmentDescription' onChange={this.handleChange} />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='custom-row with-textarea'>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                            <div className='name'>
                                                PROGETTO DI RIFERIMENTO
                                            </div>
                                            <span className='rowize-select'>
                                                <select
                                                    className='legacy-form-control'
                                                    name='projectReference'
                                                    value={this.state.selectedProject}
                                                    onChange={this.handleProjectSelect}
                                                >
                                                    <option key="key-0" value="" disabled={true}>Seleziona progetto...</option>
                                                    {this.state.projects.map((obj, index) => {
                                                        return <option key={obj.project_uuid} value={obj.project_uuid}>
                                                            {obj.project_name}
                                                        </option>;
                                                    })}
                                                </select>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='custom-row'>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                            <div className='name'>
                                                SELEZIONA GRUPPO - <span className='create-new' onClick={event => {
                                                    event.preventDefault();
                                                    this.props.history.push('/groups');
                                                }}>CREA NUOVO <FontAwesomeIcon icon={faPlus} className='icon' /></span>
                                            </div>
                                            <span className='white-row'>
                                                <select value={this.state.groupUuid} className='legacy-form-control white-select' name='groupUuid' onChange={this.handleChange}>
                                                    {this.getGroupsOptions()}
                                                </select>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        </Col>
                                        <Col md={6}>
                                            <Label for="dataInizio" className="date-label">DATA INIZIO</Label>
                                            <DatePicker
                                                name="dataInizio"
                                                className="white-row "
                                                selected={this.state.startDate}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeStart}
                                                dateFormat={'dd/MM/yyyy'}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label for="dataFine" className="date-label">DATA FINE</Label>
                                            <DatePicker
                                                name="dataFine"
                                                selected={this.state.endDate}
                                                selectsEnd
                                                className="white-row"
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeEnd}
                                                dateFormat={'dd/MM/yyyy'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='error-message' style={this.state.errorMessage === '' ?
                                        { opacity: 0 } : { opacity: 1 }}>
                                        {this.state.errorMessage}
                                    </Row>
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button className='btn-edubba' onClick={this.toggleCreateNewAssignment}>Annulla</Button>
                                <Button
                                    className='btn-edubba'
                                    disabled={
                                        !this.state.startDate ||
                                        !this.state.endDate ||
                                        (this.state.groupUuid === -1 || this.state.groupUuid === "") ||
                                        this.state.assignmentType === "" ||
                                        this.state.selectedProject === "" ||
                                        this.state.assignmentDescription === ""
                                    }
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.createNewAssignment();
                                        // this.toggleCreateNewAssignment();
                                        // this.props.history.push('/cards');
                                    }}
                                >Salva e Continua</Button>
                            </ModalFooter>
                        </Modal>

                        {/* Modale per la creazione di un progetto */}
                        <Modal className='edubba-modal custom-modal'
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            size='md'
                            isOpen={this.state.modalCreateNewProject}
                            toggle={this.toggleCreateNewProject}
                            backdrop='static' >
                            <ModalHeader toggle={this.toggleCreateNewProject}>
                                <div className='widget-content p-0'>
                                    <div className='widget-content-wrapper'>
                                        <div className='widget-content-left mr-3'>
                                            <i className='pe-7s-note2 icon pe' />
                                        </div>
                                        <div className='widget-content-left mr-3 text-center w-100'>
                                            Creazione Nuovo Progetto
                                </div>
                                        {/* <div className='widget-content-right text-right'>
                                            <FontAwesomeIcon className='icon' icon={faSave} onClick={this.createNewGroup} />
                                        </div> */}
                                    </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Container>
                                    <Row className='custom-row'>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                            <div className='name'>
                                                NOME PROGETTO
                                        </div>
                                            <input className="white-row"
                                                name='projectName'
                                                type="text"
                                                placeholder='Nome del Progetto ...'
                                                autoComplete='off'
                                                value={this.state.projectName}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        </Col>
                                        <Col md={6}>
                                            <Label for="dataInizio" className="date-label">DATA INIZIO</Label>
                                            <DatePicker
                                                name="dataInizio"
                                                className="white-row "
                                                selected={this.state.startDate}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeStart}
                                                dateFormat={'dd/MM/yyyy'}
                                                placeholderText={'Data Inizio ...'}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label for="dataFine" className="date-label">DATA FINE</Label>
                                            <DatePicker
                                                name="dataFine"
                                                selected={this.state.endDate}
                                                selectsEnd
                                                className="white-row"
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleChangeEnd}
                                                dateFormat={'dd/MM/yyyy'}
                                                placeholderText={'Data Fine ...'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='error-message' style={this.state.errorMessage === '' ?
                                        { opacity: 0 } : { opacity: 1 }}>
                                        {this.state.errorMessage}
                                    </Row>
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button className='btn-edubba' onClick={this.toggleCreateNewProject}>Annulla</Button>
                                <Button
                                    className='btn-edubba'
                                    disabled={this.state.projectName === "" || !this.state.startDate || !this.state.endDate}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.createNewProject();
                                    }}
                                >
                                    Salva e Continua
                                    </Button>
                            </ModalFooter>
                        </Modal>
                        <Modal className='edubba-modal'
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            size='md'
                            isOpen={this.state.modalNewTask}
                            toggle={this.toggleNewTask}>
                            <ModalHeader toggle={this.toggleNewTask}>
                                <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                    Info
                                </div>
                                </div>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Container className='help-text'>
                                    Si verrà reindirizzati verso la pagina di Assegnamento delle Schede o Tour, dove sarà possibile visualizzare, modificare o assegnare compiti a uno o più studenti di un gruppo.
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <div style={{textAlign: "center", width: "100%"}}>
                                    <Button
                                        className='btn-edubba'
                                        onClick={() => this.goToAssignmentPage('cards')}>
                                        1) Compito per Schede
                                    </Button>
                                    <Button
                                        className='btn-edubba'
                                        onClick={() => this.goToAssignmentPage('tours')}>
                                        2) Compito per Percorsi
                                    </Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </Fragment>
        )
    }

}

export default withRouter(Dashboard);
