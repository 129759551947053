import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import globalVars from './../../globalVars.json';
import {
    Row,
    Col,
    Container,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Alert,
    Button,
    Collapse,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink
} from 'reactstrap';
import {
    faDownload,
    faFilter
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import holdDataService from './../../services/holdDataService/holdDataService';
import ReactTooltip from 'react-tooltip';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../../assets/utils/images/edubba_placeholder.png'
import './CatalogCards.scss';

class CatalogCards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingCards: false,
            catalogCards: [],

            //----------------------------------------------------------------|

            isFilterOpen: false,
            filterIsApplied: false,
            searchOgtdValue: null,

            //----------------------------------------------------------------|

            currentQueryPage: 1,
            currentQueryLimit: 15,
            currentQueryCount: 15,
        };
    }

    componentDidMount() {
        this.props.setSectionTitle('Catalogo schede Databenc-Art');
        this.loadCards();
    }

    loadCards = () => {
        this.setState({ loadingCards: true });
        if (this.state.filterIsApplied &&
            this.state.searchOgtdValue) {
            let query  = '/gateways/' + this.props.match.params.catalog_uuid + "/search/cards";
            let paging = '?page=' + this.state.currentQueryPage + '&limit=' + this.state.currentQueryLimit;
            let jsonBody = {
                "search_ogtd": this.state.searchOgtdValue
            };
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query + paging,
            jsonBody, {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                if (res.data && res.data.rowCount > 0) {
                    this.setState({
                        catalogCards:      res.data.rows,
                        currentQueryCount: res.data.rowCount,
                        loadingCards:      false
                    });
                }
                else this.setState({
                    catalogCards:      [],
                    currentQueryCount: 0,
                    loadingCards:      false
                });
            }, err => {
                this.setState({
                    catalogCards:      [],
                    currentQueryCount: 0,
                    loadingCards:      false
                });
            });
        }
        else {
            let query  = '/gateways/' + this.props.match.params.catalog_uuid + "/cards";
            let paging = '?page=' + this.state.currentQueryPage + '&limit=' + this.state.currentQueryLimit;
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query + paging, {
                auth: holdDataService.getAuthorization()
            }).then(res => {
                if (res.data && res.data.rowCount > 0) {
                    this.setState({
                        catalogCards:      res.data.rows,
                        currentQueryCount: res.data.rowCount,
                        loadingCards:      false
                    });
                }
                else this.setState({
                    catalogCards:      [],
                    currentQueryCount: 0,
                    loadingCards:      false
                });
            }, err => {
                this.setState({
                    catalogCards:      [],
                    currentQueryCount: 0,
                    loadingCards:      false
                });
            });
        }
    }

    downloadCard = (card_id) => {
        this.setState({ loadingCards: true });
        let query  = '/gateways/' + this.props.match.params.catalog_uuid + "/cards/" + card_id;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.props.history.push(
                    '/new_card',
                    {
                        src_type:    "third-catalog",
                        src_uuid:    this.props.match.params.catalog_uuid,
                        card_ref_id: res.data.rows[0]["card_ref_id"],
                        tpl_uuid:    res.data.rows[0]["tpl_info"]["tpl_uuid"],
                        card_data:   res.data.rows[0]["card_data"]
                    }
                );
            }

            this.setState({
                loadingCards: false
            });
        }, err => {
            this.setState({
                loadingCards: false
            });
        });
    }

    toggleFilter() {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen
        });
    }

    applyFilter = () => {
        this.setState({
            filterIsApplied: true
        },
        () => {
            this.loadCards();
        });
    }

    clearFilter = () => {
        this.setState({
            isFilterOpen:    false,
            filterIsApplied: false
        },
        () => {
            this.loadCards();
        });
    }

    preparePaginationItems() {
        // Prepare data
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);
        let items      = [];

        // Prepare limits
        let firstMin   = 1;
        let lastMax    = countPage;

        // Check range
        if (countPage > 8) {
            // Specifies range of items in the middle
            firstMin = Math.max(active - 2, 1);
            lastMax  = Math.min(active + 2, countPage);

            // Normalize
            if (active === 1) {
                lastMax += 2;
            }
            // Normalize
            if (active === 2) {
                lastMax += 1;
            }
            else if (active === (countPage - 1)) {
                firstMin-=1;
            }
            else if (active === countPage) {
                firstMin-=2;
            }
        }

        // Push forst separator
        if (firstMin > 1) {
            items.push(
                <PaginationItem key={"firstMinSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Loop on item
        for (let number = firstMin; number <= lastMax; number++) {
            // Save item
            items.push(
                <PaginationItem key={number} active={number === active}>
                    <PaginationLink onClick={() => this.changePage(number)}>
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Push last separator
        if (lastMax < countPage) {
            items.push(
                <PaginationItem key={"lastMaxSep"}>
                    <PaginationLink>
                        ...
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Send back
        return items;
    }

    changePage(toPage) {
        this.setState({
            catalogCards: [],
            currentQueryPage: toPage
        },
        () => {
            this.loadCards();
        });
    }

    changePageByStep(toPage) {
        let active     = this.state.currentQueryPage;
        let countRows  = this.state.currentQueryCount;
        let divisor    = this.state.currentQueryLimit;
        let countPage  = Math.ceil(countRows / divisor);

        switch (toPage) {
            case "first":
                active = 1;
                break;

            case "back":
                active = ((active - 1) >= 1) ? (active - 1) : 1;
                break;

            case "next":
                active = ((active + 1) <= countPage) ? (active + 1) : active;
                break;

            case "last":
                active = countPage;
                break;

            default: break;
        }

        this.setState({
            catalogCards: [],
            currentQueryPage: active
        },
        () => {
            this.loadCards();
        });
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardImage = (card_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(card_image)) {
            coreImage = card_image;
        }

        return coreImage;
    }

    handleSearchTitleValue = (ev) => {
        this.setState({
            searchOgtdValue: ev.target && ev.target.value ? ev.target.value : null,
            currentQueryPage: 1
        });
    }

    render() {
        return (
            <Fragment>
                <Container className='Databenc'>
                    {
                        this.state.loadingCards ?
                            <EdubbaLoader />
                            : this.state.catalogCards.length > 0 ?
                                <Fragment>
                                    <Row>
                                        <Col lg='12' md='12' sm='12' className="HeadStat">
                                        Seleziona una Scheda:
                                            <Button
                                                data-tip data-for={"TIPS_FOR_BTN_ACTION_FILTER"}
                                                color="secondary"
                                                onClick={() => this.toggleFilter()}
                                                style={{
                                                    position: "relative",
                                                    float: "right",
                                                    marginTop: '12px',
                                                    marginLeft: '10px'
                                                }}>
                                                <FontAwesomeIcon icon={faFilter} />
                                                <ReactTooltip
                                                    id={"TIPS_FOR_BTN_ACTION_FILTER"}
                                                    wrapper="span"
                                                    place="left"
                                                    effect="solid"
                                                    className="tooltip-user">
                                                    Mostra/Nascondi i filtri di visualizzazione
                                                </ReactTooltip>
                                            </Button>
                                            <Collapse isOpen={this.state.isFilterOpen}>
                                                <Container className="Databenc-Filter">
                                                    <Row key="body-filter-1" style={{marginBottom: "20px"}}>
                                                        <Col lg='12' md='12' sm='12'>
                                                            Filtra per Titolo di Scheda:<br />
                                                            <Input
                                                                type="text"
                                                                defaultValue={this.state.searchOgtdValue}
                                                                onChange={this.handleSearchTitleValue}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row key="footer-filter">
                                                        <Col lg='4' md='4' sm='4'>
                                                            <br />
                                                            <Button
                                                                color="danger"
                                                                onClick={() => this.clearFilter()}>
                                                                RIMUOVI FILTRI
                                                            </Button>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                color="success"
                                                                onClick={() => this.applyFilter()}
                                                                disabled={
                                                                    this.state.searchOgtdValue === null
                                                                }>
                                                                APPLICA FILTRI
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='12' md='12' sm='12'>
                                            <div className="list-cards-grid">
                                                {
                                                    this.state.catalogCards.map((obj, index) => {
                                                        return (
                                                            <Card key={index}>
                                                                <CardImg top width="100%" src={
                                                                    this.getCardImage(obj.card_image)
                                                                } />
                                                                <CardBody>
                                                                    <CardTitle>
                                                                        {obj.card_title}
                                                                    </CardTitle>
                                                                    <CardText>
                                                                        <span className='h-2'>{obj.address}</span>
                                                                    </CardText>
                                                                </CardBody>
                                                                <div className='bottom-icons'>
                                                                    <div
                                                                        onClick={() => this.downloadCard(obj.card_ref_id)}
                                                                        data-tip data-for={"TIPS_FOR_DB_" + index}>
                                                                        <FontAwesomeIcon
                                                                            className='icon reply'
                                                                            icon={faDownload}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_DB_" + index}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                            Premere per scaricare la scheda nel catalogo personale.
                                                                    </ReactTooltip>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row key="pages-stat">
                                        <Col lg='12' md='12' sm='12' className="Databenc-Pagination">
                                            <Pagination>
                                                <PaginationItem>
                                                    <PaginationLink first onClick={() => this.changePageByStep("first")} />
                                                </PaginationItem>
                                                <PaginationItem>
                                                    <PaginationLink previous onClick={() => this.changePageByStep("back")} />
                                                </PaginationItem>

                                                {this.preparePaginationItems()}

                                                <PaginationItem>
                                                    <PaginationLink next onClick={() => this.changePageByStep("next")} />
                                                </PaginationItem>
                                                <PaginationItem>
                                                    <PaginationLink last onClick={() => this.changePageByStep("last")} />
                                                </PaginationItem>
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Fragment>
                                : (
                                    <Row>
                                        <Col lg='12' md='12' sm='12'>
                                            <Alert color="light" className="mt-3">
                                                <h4 className="alert-heading">Nessuna scheda trovata!</h4>
                                                <p>
                                                    Non è stato possibile trovare schede disponibili dal catalogo.<br />
                                                    Appena disponibili saranno visibili nella sezione corrente.
                                                </p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )
                    }
                </Container>
            </Fragment >
        )
    }
}

export default withRouter(CatalogCards);
