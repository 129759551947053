import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import globalVars from './../globalVars.json';
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    Alert
} from 'reactstrap';
import {
    faArrowCircleLeft,
    faArrowCircleRight,
    faSearch,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import holdDataService from '../services/holdDataService/holdDataService';
import EdubbaLoader from './../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../assets/utils/images/edubba_placeholder.png'
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import InputGroupButtonDropdown from 'reactstrap/lib/InputGroupButtonDropdown';
import InputGroup from 'reactstrap/lib/InputGroup';
import CardText from 'reactstrap/lib/CardText';
import moment from 'moment';

import './ThirdCatalogs.scss';

class ThirdCatalogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            catalogs:        [],
            loadingCatalogs: false,

            //----------------------------------------------| PAGING

            catalogSplitButtonOpen: false,
            catalogTotalEntities: 0,
            catalogPageLimit: 10,
            catalogPageNumber: 1,
        };
    }

    componentDidMount() {
        let setTitle = 'Cataloghi Schede di Terze-Parti';
        this.props.setSectionTitle(setTitle);
        this.loadGateways();
    }

    loadGateways() {
        this.setState({ loadingCatalogs: true });
        let query = '/gateways';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    catalogs: res.data.rows,
                    loadingCatalogs: false
                });
            }
            else this.setState({
                catalogs: [],
                loadingCatalogs: false
            });
        }, err => {
            this.setState({
                catalogs: [],
                loadingCatalogs: false
            });
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    goTocatalogPage = (pageNumber) => {
        this.setState({
            catalogPageNumber: pageNumber
        }, () => {
            this.loadcatalogs(
                this.state.searchcatalogValue,
                this.state.catalogCatCodeFilter
            );
        });
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    render() {
        let catalogPages = Math.ceil(
            this.state.catalogTotalEntities /
            this.state.catalogPageLimit
        );

        catalogPages = (catalogPages <= 0) ? 1 : catalogPages;

        return (
            <Container className='Tools'>
                {
                    this.state.loadingcatalogs ?
                        <EdubbaLoader />
                    : null
                }
                <Row>
                    <Col lg='12' md='12' sm='12'>
                        <div className="task-container">
                            <div className="task-container-title">Cataloghi Disponibili:</div>
                            <div className="task-container-search">
                                <InputGroup>
                                    <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.catalogSplitButtonOpen} toggle={this.catalogToggleSplit}>
                                        <Button disabled={true}>
                                            <b>Pagina: &nbsp;</b>{ this.state.catalogPageNumber }
                                        </Button>
                                        { catalogPages > 1 ? <DropdownToggle split /> : null }
                                        <DropdownMenu>
                                            {
                                                // Gen. pages
                                                (() => {
                                                    let pages = [];
                                                    for (let i = 1; i <= catalogPages; i++) {
                                                        if (this.state.catalogPageNumber === i) {
                                                            continue;
                                                        }
                                                        else pages.push(
                                                            <DropdownItem
                                                                key={"card-page-" + i}
                                                                onClick={() => this.goTocatalogPage(i)}
                                                            >
                                                                Vai alla Pagina: &nbsp;{i}
                                                            </DropdownItem>
                                                        )
                                                    }
                                                    return pages;
                                                })()
                                            }
                                        </DropdownMenu>
                                    </InputGroupButtonDropdown>
                                    <Input
                                        disabled={true}
                                        value={this.state.searchcatalogValue}
                                        onChange={this.handlecatalogSearch}
                                        onKeyUp={this.searchcatalog}
                                        placeholder="Premere invio per cercare..."
                                    />
                                    {
                                        !this.isEmpty(this.state.searchcatalogValue) ? (
                                            <InputGroupAddon addonType="append">
                                                <Button onClick={this.clearSearchcatalogs}>
                                                    <FontAwesomeIcon className='icon' icon={faTrash} />
                                                </Button>
                                            </InputGroupAddon>
                                        ) : null
                                    }
                                </InputGroup>
                            </div>
                            <div className="task-container-arrow-left">
                                <Button disabled={this.state.catalogPageNumber <= 1} onClick={() => this.goTocatalogPage(
                                    this.state.catalogPageNumber - 1
                                )}>
                                    <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                </Button>
                            </div>
                            <div className="task-container-arrow-right">
                                <Button disabled={this.state.catalogPageNumber >= catalogPages} onClick={() => this.goTocatalogPage(
                                    this.state.catalogPageNumber + 1
                                )}>
                                    <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                </Button>
                            </div>
                            <div className="task-container-list">
                            {
                                this.state.catalogs.length > 0 ?
                                    <div className="list-catalogs-grid">
                                        {
                                            this.state.catalogs.map((obj) => {
                                                return (
                                                    <Card key={obj.gateway_uuid}>
                                                        <CardImg
                                                            top
                                                            width="100%"
                                                            src={obj.gateway_image}
                                                            onError={this.setDefaultImgSrc}
                                                        />
                                                        <div className="catalog-status" style={{
                                                            backgroundColor: "#c67e00",
                                                            color: "#ffffff"
                                                        }}>
                                                        {
                                                            "Catalogo di Terze-Parti"
                                                        }
                                                        </div>
                                                        <CardBody>
                                                            <CardTitle>
                                                                {obj.gateway_name}
                                                            </CardTitle>
                                                            <CardText>
                                                                <small className="text-muted">
                                                                    <b>Creato:</b> {this.getFromNowDate(obj.gateway_time_create)}
                                                                </small>
                                                                <br />
                                                                <small className="text-muted">
                                                                    <b>Modificato:</b> {this.getFromNowDate(obj.gateway_time_update)}
                                                                </small>
                                                            </CardText>
                                                        </CardBody>
                                                        <div className='bottom-icons'>
                                                            <Link to={"/third-catalogs/" + obj.gateway_uuid + "/cards"}>
                                                                <div
                                                                    data-tip data-for={"TIPS_FOR_V_" + obj.gateway_uuid}>
                                                                    <FontAwesomeIcon
                                                                        className='icon'
                                                                        icon={faSearch}
                                                                    />
                                                                    <ReactTooltip
                                                                        id={"TIPS_FOR_V_" + obj.gateway_uuid}
                                                                        wrapper="span"
                                                                        place="top"
                                                                        effect="solid"
                                                                        className="tooltip-user">
                                                                        Apri il Catalogo esterno
                                                                    </ReactTooltip>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </Card>
                                                );
                                            })
                                        }
                                    </div>
                                : (
                                    <Row>
                                        <Col lg='12' md='12' sm='12'>
                                            <Alert color="light" className="mt-3">
                                                <h4 className="alert-heading">Nessun catalogo trovato!</h4>
                                                <p>
                                                    Non sono ancora disponibili cataloghi di terze parti su cui lavorare.<br />
                                                    Appena disponibili saranno visibili nella sezione corrente.
                                                </p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )
                            }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default withRouter(ThirdCatalogs);
