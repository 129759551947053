import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import globalVars from './../globalVars.json';
import classnames from 'classnames';
import {
    Card, Nav, NavItem, NavLink,
    CardBody, CardHeader, TabContent, TabPane,
    ListGroup, ListGroupItem
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import 'moment/locale/it';

import cx from 'classnames';
import Hamburger from 'react-hamburgers';

import {
    faCalendarAlt,
    faPaperPlane,
    faChevronCircleDown,
    faReply,
    faTimes,
    faStar,
    faCommentAlt,
    faTasks,
    faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchBox from './../Layout/AppHeader/Components/SearchBox';
import holdDataService from './../services/holdDataService/holdDataService';
import './SocialRoom.scss';

class SocialRoom extends Component {
    constructor() {
        super();

        this.state = {
            active: false,
            activeTab: '1',
            message: '',
            allMessages: [],
            filteredMessages: [],
            filteredMessagesByTag: [],
            tasksMessage: [],
            tasks: [],
            tags: [],
            filteredTasks: [],
            activities: [],
            showScrollDown: false,
            searchValue: '',
            replying: false,
            replyingMessage: {},
            roomData: {},
            room_uuid: "",
            assign_uuid: ""
        };
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'SocialRoom';

        this.setupRoom(this.props.location.state.room_uuid, this.props.location.state.assign_uuid);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.state.room_uuid !== nextProps.location.state.room_uuid) {
            this.setupRoom(nextProps.location.state.room_uuid, nextProps.location.state.assign_uuid);
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
    }

    setupRoom = (room_uuid, assign_uuid) => {
        this.setState({
            room_uuid: room_uuid,
            assign_uuid: assign_uuid
        }, () => {
            let query = '/social-rooms/' + this.state.room_uuid;
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let roomData = res.data && res.data.rows ? res.data.rows[0] : {};
                this.setState({
                    roomData: roomData
                }, () => {
                    this.props.setSectionTitle(`Social Room - ${this.state.roomData.room_name}`);

                    this.loadTasks();
                    this.loadActivities();
                    this.loadAllMessages();
                });
            }, err => {
                //
            });
        });
    }

    loadActivities = () => {
        let query = '/activities?activity_by_socialroom=' + this.state.room_uuid;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            //
        }, err => {
            //
        });
    }

    loadTasks = () => {
        let query = '/assignments/' + this.state.assign_uuid + '/tasks';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            let tasks = res.data ? res.data.rows : [];
            let tags = [];
            let filteredTasks = [];
            tasks.map((t, i) => {
                tags.push(t.task_tagname.toLowerCase());
                filteredTasks.push(false);
                return true;
            })
            this.setState({
                tasks: tasks,
                tags: tags,
                filteredTasks: filteredTasks,
            });
        }, err => {
            //
        });
    }

    loadAllMessages = () => {
        let query = '/social-rooms/' + this.state.room_uuid + '/comments';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.setState({
                allMessages: res.data ? res.data.rows : []
            }, () => {
                setTimeout(this.scrollChatDown, 500);//purtroppo è necessario il timeout a causa della getMessagesHTML
                // è sempre meglio inserire l'html direttamente nel render e non nelle funzioni, così la callback della
                // setState dovrebbe catturare il momento esatto per la callback (this.scrollChatDown)
            });
        }, err => {
            //
        });
    }

    scrollChatDown = () => {
        let chatDiv = document.getElementById("chat");
        chatDiv.scrollTop = chatDiv.scrollHeight;
        document.getElementById("chat-input").focus();
    }

    handleScrollChat = (event) => {
        event.preventDefault();
        let chatDiv = document.getElementById("chat");
        //Mostro il pulsante per tornare in basso nella chat
        let h = (chatDiv.scrollHeight - chatDiv.scrollTop) / 2;
        let show = h > chatDiv.scrollTop && h > 0;
        this.setState({
            showScrollDown: show
        })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChangeMessage = (event) => {
        event.preventDefault();
        let message = event.target.value;
        this.setState({
            message: message
        }, () => this.checkTaskInMessage())
    }

    checkTaskInMessage = () => {
        let message = this.state.message;
        let words = message.split(' ');
        let tasks = [];
        let tempTags = [];
        words.map(w => {
            let task = '';
            //Controllo che ci sia u n # e di fianco almeno una lettera
            if (w.indexOf('#') !== -1 && w.length > 1) {
                // task = w.substr(1);
                task = w.toLowerCase();
                //Non inserisco il badge di un task più volte
                if (this.state.tags.indexOf(task) !== -1 && tempTags.indexOf(task) === -1) {
                    tasks.push(this.state.tasks[this.state.tags.indexOf(task)]);
                    tempTags.push(task);
                }
            }
            return false;
        })
        this.setState({
            tasksMessage: tasks,
        })
    }

    toggleTaskFilter = (index) => {
        let filteredTasks = this.state.filteredTasks;
        filteredTasks[index] = !filteredTasks[index];
        let filteredMessages = [];
        let allMessages = this.state.allMessages;
        //Se ci sono filtri attivi utilizzo i messaggi filtrati
        if (filteredTasks.indexOf(true) !== -1) {
            let activeTags = [];
            this.state.filteredTasks.map((val, i) => val ? activeTags.push(this.state.tags[i]) : null);
            allMessages.map((message, index) => {
                let takeMessage = false;
                if (
                    message.comment_data &&
                    Array.isArray(message.comment_data.linked_tasks) &&
                    message.comment_data.linked_tasks.length > 0
                ) {
                    message.comment_data.linked_tasks.map(t => {
                        if (activeTags.indexOf(t.task_tagname !== -1)) {
                            takeMessage = true;
                        }
                        return false;
                    });
                }
                if (takeMessage) {
                    filteredMessages.push(message);
                }
                return false;
            });
        }
        else {

        }
        this.setState({
            filteredTasks: filteredTasks,
            filteredMessagesByTag: filteredMessages,
            searchValue: '',
            filteredMessages: [],
        })
    }

    replyMessage = (index) => {
        let mess = this.state.allMessages[index];
        let replyMessage = {
            comment_uuid: mess.comment_uuid,
            comment_owner: mess.comment_owner,
            comment_message: mess.comment_message,
            messageIndex: index,
            comment_create_date: mess.comment_create_date,
        }
        this.setState({
            replying: true,
            replyingMessage: replyMessage,
        }, this.scrollChatDown);
        document.getElementById("chat-input").focus();
    }

    stopReply = () => {
        this.setState({
            replying: false,
            replyingMessage: {},
        })
    }

    handleSearch = (event) => {
        event.preventDefault();
        let searchValue = event.target.value;
        this.setState({
            searchValue: searchValue
        },
            () => this.setFilteredMessages()
        );
    }

    handleCloseSearch = () => {
        this.setState({
            searchValue: ''
        })
    }

    setFilteredMessages = () => {
        let filteredMessages = [];
        let allMessages = this.state.allMessages;
        let filteredTasks = [];
        this.state.filteredTasks.map(v => filteredTasks.push(false))

        if (allMessages.length > 0) {
            filteredMessages = allMessages.filter(mess =>
                // Filtro per testo del messaggio
                (mess.comment_message.toLowerCase().indexOf(this.state.searchValue.toLowerCase().trim()) !== -1) ||
                // Filtro per nome dell'utente
                (this.getUserAttribute(mess.comment_owner, "firstName").toLowerCase().indexOf(this.state.searchValue.toLowerCase().trim()) !== -1) ||
                (this.getUserAttribute(mess.comment_owner, "lastName").toLowerCase().indexOf(this.state.searchValue.toLowerCase().trim()) !== -1) ||
                ((`${this.getUserAttribute(mess.comment_owner, "firstName").toLowerCase()} ${this.getUserAttribute(mess.comment_owner, "lastName").toLowerCase()}`).indexOf(this.state.searchValue.toLowerCase().trim()) !== -1) ||
                ((`${this.getUserAttribute(mess.comment_owner, "lastName").toLowerCase()} ${this.getUserAttribute(mess.comment_owner, "firstName").toLowerCase()}`).indexOf(this.state.searchValue.toLowerCase().trim()) !== -1));

        }
        this.setState({
            filteredMessages: filteredMessages,
            filteredTasks: filteredTasks,
        });
    }

    createMessage = () => {
        let message = {};
        message.comment_ref = this.state.replyingMessage.comment_uuid;
        message.comment_message = this.state.message;
        if (this.state.tasksMessage.length > 0) {
            message.comment_data = {};
            message.comment_data.linked_tasks = [];
            for (let i = 0; i < this.state.tasksMessage.length; i++) {
                message.comment_data.linked_tasks.push(this.state.tasksMessage[i].task_uuid);
            }
        }
        return message;
    }

    sendMessage = (event) => {
        event.preventDefault();
        if (this.state.message !== '') {
            // Modifico i tag in grassetto
            let message = this.createMessage();

            let query = '/social-rooms/' + this.state.room_uuid + '/comments';
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, message, {
                auth: holdDataService.getAuthorization()
            })
                .then(res => {
                    this.setState({
                        tasksMessage: [],
                        message: '',
                        replyingMessage: {},
                        replying: false
                    }, this.loadAllMessages);
                }, err => {
                    //
                });
        }
    }

    getTasksHTML = () => {
        let taskList = [];
        this.state.tasks.map((t, index) => {
            let selected = this.state.filteredTasks[index];
            taskList.push(
                <ListGroupItem key={`task-${index}`} className={`task ${selected ? 'selected' : null}`}>
                    <div className={`widget-content p-0`}>
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <div className="widget-heading">
                                    <b>{t.task_name}</b>
                                </div>
                                <div className="widget-subheading ">
                                    <i>{this.getUserAttribute(t.task_owner, "firstName") + " " + this.getUserAttribute(t.task_owner, "lastName")}</i>
                                </div>
                            </div>
                            <div className="badge text-white" style={{ backgroundColor: t.task_color }}>{t.task_tagname}</div>
                            {/* <FontAwesomeIcon icon={faFilter} className={`c-p ${selected ? 'text-white' : 'text-edubba'}`} */}
                            <i className={`pe-7s-filter c-p ${selected ? 'text-white' : 'text-edubba'}`}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.toggleTaskFilter(index);
                                    // this.scrollChatDown();
                                }} />
                        </div>
                    </div>
                </ListGroupItem>
            )
            return true;
        });
        return <span>{taskList}</span>;
    }

    getMessagesHTML = () => {
        let messagesList = [];
        let allMessages = this.state.allMessages;
        // Se il campo di ricerca è attivo allora mostro i messaggi filtrati
        if (this.state.searchValue !== '')
            allMessages = this.state.filteredMessages;

        if (this.state.filteredTasks.indexOf(true) !== -1) {
            allMessages = this.state.filteredMessagesByTag;
        }

        allMessages.map((m, index) => {
            messagesList.push(
                <Message key={`message-${m.comment_uuid}`}
                    sent={holdDataService.getLoggedUserUuid() === m.comment_owner.user_uuid}
                    comment_message={m.comment_message}
                    comment_owner={m.comment_owner}
                    comment_date_create={m.comment_date_create}
                    messageIndex={index}
                    comment_ref={m.comment_ref}
                    replyMessage={this.replyMessage}
                    replying={false}
                    comment_owner_is_tutor={m.comment_owner_is_tutor}
                    tasks={m.comment_data}
                    getUserAttribute={this.getUserAttribute}
                    roomUUID={this.state.room_uuid}
                />
            );
            return true;
        })
        if (!messagesList.length > 0)
            return <div className='no-messages'>
                <div className='chat-box'>
                    Non ci sono messaggi
                    </div>
            </div>;

        return <span>{messagesList}</span>;
    }

    getActivitiesHTML = () => {
        let activityList = [];
        this.state.activities.map((t, index) => {
            let text = t.content.text;
            let user = `<b>${t.content.user.firstName} ${t.content.user.lastName}</b>`;
            if (t.type === 2) {
                text = t.content.text.replace(new RegExp('user', 'g'), user);
            } else if (t.type === 1) {
                text = `${user} ${text} <i><b>${t.content.task.taskName}</b></i>`;
            } else {
                text = user + ' ' + text;
            }
            activityList.push(
                <ListGroupItem key={`activity-${index}`}>
                    <div className="widget-content p-0 activity">
                        <div className='text'>
                            {ReactHtmlParser(text)}
                        </div>
                        <div className='icon'>
                            <FontAwesomeIcon icon={
                                t.type === 0 ?
                                    faCommentAlt
                                    : t.type === 1 ?
                                        faTasks
                                        : faChalkboardTeacher}
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.goToMessage(t.message);
                                }}
                            />
                        </div>
                    </div>
                </ListGroupItem>
            )
            return true;
        });
        return <span>{activityList}</span>;
    }

    goToMessage = (index) => {
        let messages = document.getElementsByClassName("chat-box-wrapper");
        if (messages.length <= 0) {
            return;
        }
        let chatDiv = document.getElementById("chat");
        chatDiv.scrollTop = messages[index].getBoundingClientRect().height * (index);
        messages[index].style.backgroundColor = '#ffefcc';
        setTimeout(() => messages[index].style.backgroundColor = '#ffffff', 1000);
        // console.table({
        //     'h calcolata': messages[index].getBoundingClientRect().height * (index),
        //     'elementHTML': messages[index].offsetHeight,
        //     'other': messages[index].getBoundingClientRect(),
        //     'scrollTop': chatDiv.scrollTop,
        //     'scrollHeight': chatDiv.scrollHeight,
        //     // 'chatdiv': chatDiv
        // });
        // chatDiv.scrollTop = messages[index].offsetHeight;

        // let height = messages[index].getBoundingClientRect().top;
        // let chatDiv = document.getElementById("chat");
        // chatDiv.scrollTop = height;
        // let h = (chatDiv.scrollHeight - chatDiv.scrollTop) / 2;

    }

    getTeamComponents(room) {
        let TCString = "";
        if (room.room_members) {
            for (let i = 0; i < room.room_members.length; i++) {
                let member = room.room_members[i];
                if (member.user_profile) {
                    if (TCString === "") {
                        TCString = member.user_profile.firstName + " " + member.user_profile.lastName;
                    }
                    else {
                        TCString += ", " + member.user_profile.firstName + " " + member.user_profile.lastName;
                    }
                }
            }
        }
        return TCString;
    }

    getTeamTutor(room) {
        let TCString = "";
        if (room.room_tutors) {
            for (let i = 0; i < room.room_tutors.length; i++) {
                let member = room.room_tutors[i];
                if (member.user_profile) {
                    if (TCString === "") {
                        TCString = member.user_profile.firstName + " " + member.user_profile.lastName;
                    }
                    else {
                        TCString += ", " + member.user_profile.firstName + " " + member.user_profile.lastName;
                    }
                }
            }
        }
        return TCString;
    }

    getUserAttribute(user, attribute) {
        return user.user_profile[attribute];
    }

    render() {
        let tasks = this.state.tasksMessage;
        let taskList = [];
        tasks.map((t, i) => {
            taskList.push(
                <div key={`task-${i}`}
                    className='badge badge-pill'
                    style={{ backgroundColor: t.task_color }}>
                    {t.task_tagname.toUpperCase()}
                </div>
            );
            return true;
        });

        return (
            <Fragment>
                <div className={cx("app-inner-layout chat-layout", {
                    'open-mobile-menu': this.state.active,
                })}>
                    <div className="app-inner-layout__wrapper">
                        <Card tabs="true" className="app-inner-layout__sidebar">
                            <CardHeader className='app-inner-layout__top-pane'>
                                <Nav justified>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggle('1'); }}
                                            style={this.state.activeTab === '1' ? { fontWeight: 'bold' } : null}>
                                            Tutti i Compiti
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }}
                                            style={this.state.activeTab === '2' ? { fontWeight: 'bold' } : null}>
                                            Attività Recenti
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className='tab-container'>
                                <PerfectScrollbar>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <Card className="card-hover-shadow-2x">
                                                <ListGroup flush>
                                                    {this.getTasksHTML()}
                                                </ListGroup>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Card className=" card-hover-shadow-2x">
                                                <ListGroup flush>
                                                    {this.getActivitiesHTML()}
                                                </ListGroup>
                                                {/* <CardFooter className="d-block text-center">
                        <Button className="btn btn-edubba-inverse">
                            Vedi tutti
                                        </Button>
                    </CardFooter> */}
                                            </Card>
                                        </TabPane>
                                    </TabContent>
                                </PerfectScrollbar>
                            </CardBody>
                        </Card>
                        <Card className="app-inner-layout__content">
                            <div className="app-inner-layout__top-pane">
                                <div className="pane-left">
                                    <div className='edubba-search'>
                                        <SearchBox
                                            text={this.state.searchValue}
                                            handleSearch={this.handleSearch}
                                            handleClose={this.handleCloseSearch}
                                            myPlaceholder='Cerca per Utente/Compito/Termine ...' />
                                    </div>
                                    <div className='room-header'>
                                        <div className={this.state.active ? "mobile-app-menu-btn activated-hamburger" : "mobile-app-menu-btn"}>
                                            <Hamburger
                                                active={this.state.active}
                                                // className={this.state.active ? 'mobile-app-menu-btn_open' : 'mobile-app-menu-btn_closed'}
                                                type="elastic"
                                                onClick={() => this.setState({ active: !this.state.active })}
                                            />
                                        </div>
                                        <h4 className="mb-0 text-nowrap">
                                            {this.state.roomData.room_name} - <i>{this.getTeamTutor(this.state.roomData)}</i>
                                            <div className="opacity-7">
                                                {this.getTeamComponents(this.state.roomData)}
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div id='chat' className="chat-wrapper" onScroll={this.handleScrollChat}>
                                {this.getMessagesHTML()}
                                <FontAwesomeIcon icon={faChevronCircleDown}
                                    className="icon c-p scroll-down"
                                    style={this.state.showScrollDown ? { opacity: 1 } : { opacity: 0 }}
                                    onClick={this.scrollChatDown} />
                                {this.state.replying ?
                                    <div className="replying">
                                        <Message key={`message-${-1}`}
                                            sent={undefined}
                                            comment_message={this.state.replyingMessage.comment_message}
                                            tasks={this.state.replyingMessage.tasks}
                                            comment_owner={this.state.replyingMessage.comment_owner}
                                            comment_date_create={this.state.replyingMessage.comment_date_create}
                                            comment_owner_is_tutor={this.state.replyingMessage.comment_owner_is_tutor}
                                            replyMessage={this.replyMessage}
                                            stopReply={this.stopReply}
                                            replying={true}
                                            getUserAttribute={this.getUserAttribute}
                                            roomUUID={this.state.room_uuid}
                                        />
                                    </div>
                                    : null}
                            </div>
                            {/* <div className="app-inner-layout__bottom-pane text-center">
                                    <FormGroup className="m-0" row>
                                        <Col sm={12}>
                                            <Input bsSize="lg" type="text" placeholder="Write here and hit enter to send..." />
                                        </Col>
                                    </FormGroup>
                                </div> */}
                            <div className="app-inner-layout__bottom-pane send-component">
                                <div className='tasks'>
                                    {taskList}
                                </div>
                                <input id='chat-input' autoFocus type='text' placeholder='Scrivi un messaggio ...' value={this.state.message}
                                    onChange={this.handleChangeMessage}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            this.sendMessage(event);
                                        }
                                    }} />
                                <FontAwesomeIcon className='icon c-p' icon={faPaperPlane} onClick={this.sendMessage} />
                            </div>
                        </Card>
                    </div>
                </div>
            </Fragment>
        );
    }
}

class Message extends Component {
    constructor(props) {
        super(props);
        // getUserAttribute: Function
        // roomUUID: String

        this.state = {
            repliedMessage: null
        }
    }

    componentWillMount() {
        if (this.props.comment_ref && this.props.comment_ref !== "") {
            let query = '/social-rooms/' + this.props.roomUUID + '/comments/' + this.props.comment_ref;
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                auth: holdDataService.getAuthorization()
            })
                .then(res => {
                    this.setState({ repliedMessage: res.data ? res.data.rows[0] : null });
                }, err => {
                    //
                });
        }
    }

    getUserAvatar = () => {
        let firstName = this.props.getUserAttribute(this.props.comment_owner, "firstName");
        let lastName = this.props.getUserAttribute(this.props.comment_owner, "lastName");
        let fnLetters = firstName.split(" ");
        let lnLetters = lastName.split(" ");
        let userAvatarLetters = '';
        fnLetters.map(l => userAvatarLetters += l.slice(0, 1));
        lnLetters.map(l => userAvatarLetters += l.slice(0, 1));

        return <div className={`user-logo ${this.props.comment_owner_is_tutor ? 'tutor-logo' : null}`}>
            {userAvatarLetters}
        </div>
    }

    getTasksHTML = () => {
        let tasks = this.props.tasks &&
            Array.isArray(this.props.tasks.linked_tasks) &&
            this.props.tasks.linked_tasks.length > 0 ? this.props.tasks.linked_tasks : [];
        let taskList = [];
        tasks.map((t, i) => {
            taskList.push(
                <div key={`task-${i}`} className={`badge badge-pill`} style={{ backgroundColor: t.task_color }}>
                    {t.task_tagname ? t.task_tagname.toUpperCase() : ""}
                </div>
            );
            return true;
        });

        return <div className='tasks'>
            {taskList}
        </div>;
    }

    render() {
        let text = this.props.comment_message;
        let messageDate = this.props.comment_date_create;
        let messageTime = moment(messageDate).format('LT');
        let messagePastTime = moment(messageDate).fromNow();
        let comment_owner = this.props.comment_owner;
        let repliedMessage = this.state.repliedMessage;
        let a = moment(new Date().getTime());
        let b = moment(messageDate);
        //Se ci sono più di due giorni di distanza allora formatto in base alla data
        if (a.diff(b, 'days') >= 2) {
            messagePastTime = moment(messageDate).format('DD/MM/YY')
        }
        let comment_owner_is_tutor = this.props.comment_owner_is_tutor;
        return (
            <span>
                {!this.props.replying ?
                    this.props.sent ?
                        <div className="chat-box-wrapper chat-box-wrapper-right">
                            <FontAwesomeIcon icon={faReply} className={repliedMessage ? 'reply with-messageToAnswer' : 'reply'} onClick={() => this.props.replyMessage(this.props.messageIndex)} />
                            <div className={repliedMessage ? 'with-messageToAnswer' : ''}>
                                {repliedMessage ?
                                    <div className="chat-box ans">
                                        <div className='message'>
                                            {ReactHtmlParser(repliedMessage.comment_message)}
                                        </div>
                                        <small className="opacity-6">
                                            <i><b>{`${this.props.getUserAttribute(repliedMessage.comment_owner, "firstName")} ${this.props.getUserAttribute(repliedMessage.comment_owner, "lastName")}`}</b></i>
                                        </small>
                                    </div>
                                    : null}
                                <div className="chat-box">
                                    {this.getTasksHTML()}
                                    <div className='message'>
                                        {ReactHtmlParser(text)}
                                    </div>
                                    <small className="opacity-6">
                                        <i><b>{`${this.props.getUserAttribute(comment_owner, "firstName")} ${this.props.getUserAttribute(comment_owner, "lastName")}`}</b></i>
                                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 ml-1" />
                                        {messageTime} | {messagePastTime}
                                    </small>
                                </div>
                            </div>
                            <div>
                                <div className="avatar-icon-wrapper ml-2">
                                    {/* <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" /> */}
                                    {comment_owner_is_tutor ? <FontAwesomeIcon icon={faStar} className='is-tutor' /> : null}
                                    {this.getUserAvatar()}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="chat-box-wrapper">
                            <div>
                                <div className="avatar-icon-wrapper mr-2">
                                    {/* <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" /> */}
                                    {comment_owner_is_tutor ? <FontAwesomeIcon icon={faStar} className='is-tutor' /> : null}
                                    {this.getUserAvatar()}
                                </div>
                            </div>
                            <div className={repliedMessage ? 'with-messageToAnswer' : ''}>
                                {repliedMessage ?
                                    <div className="chat-box ans">
                                        <div className='message'>
                                            {ReactHtmlParser(repliedMessage.comment_message)}
                                        </div>
                                        <small className="opacity-6">
                                            <i><b>{`${this.props.getUserAttribute(repliedMessage.comment_owner, "firstName")} ${this.props.getUserAttribute(repliedMessage.comment_owner, "lastName")}`}</b></i>
                                        </small>
                                    </div>
                                    : null}
                                <div className="chat-box">
                                    {this.getTasksHTML()}
                                    <div className='message'>
                                        {ReactHtmlParser(text)}
                                    </div>
                                    <small className="opacity-6">
                                        <i><b>{`${this.props.getUserAttribute(comment_owner, "firstName")} ${this.props.getUserAttribute(comment_owner, "lastName")}`}</b></i>
                                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 ml-1" />
                                        {messageTime} | {messagePastTime}
                                    </small>
                                </div>
                            </div>
                            <FontAwesomeIcon icon={faReply} className={repliedMessage ? 'reply with-messageToAnswer' : 'reply'} onClick={() => this.props.replyMessage(this.props.messageIndex)} />
                        </div>
                    :
                    <div className="chat-box-wrapper">
                        <div>
                            <div className="avatar-icon-wrapper mr-2">
                                {/* <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" /> */}
                                {comment_owner_is_tutor ? <FontAwesomeIcon icon={faStar} className='is-tutor' /> : null}
                                {this.getUserAvatar()}
                            </div>
                        </div>
                        <div>
                            <div className="chat-box">
                                <div className='message'>
                                    {ReactHtmlParser(text)}
                                </div>
                                <small className="opacity-6">
                                    <i><b>{`${this.props.getUserAttribute(comment_owner, "firstName")} ${this.props.getUserAttribute(comment_owner, "lastName")}`}</b></i>
                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 ml-1" />
                                    {messageTime} | {messagePastTime}
                                </small>
                            </div>
                        </div>
                        <FontAwesomeIcon icon={faTimes} className='c-p icon' onClick={this.props.stopReply} />
                    </div>
                }
            </span>
        );
    }
}

export default withRouter(SocialRoom);
