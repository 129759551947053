/**
 * @author:         lsvanzo
 * @class:          TemplateManager
 * @description:    Permette la gestione di template JSON
 */
export default class JSTemplateManager extends Object {
    //------------------------------------------------------------------------|
    //  BASE CLASS METHODS
    //------------------------------------------------------------------------|

    /**
     * @author:         lsvanzo
     * @description:    Crea un'istanza di TemplateManager.
     *
     * @param {Function} cbProcessor
     * @memberof TemplateManager
     */
    constructor(cbProcessor = null) {
        // Call parent constructor
        super();

        // Init resources (hard reset)
        this.__reset(true);

        // Check for custom callback
        if (typeof cbProcessor === "function") {
            // Save new logic
            this.__cb_processor = cbProcessor;
        }
    }

    //------------------------------------------------------------------------|
    //  CHECK METHODS
    //------------------------------------------------------------------------|

    /**
     * @author:         lsvanzo
     * @description:    Permette di verifica l'esistenza di una proprietà nel dizionario
     *
     * @param {String} enumValue
     * @returns Boolean
     * @memberof TemplateManager
     */
    hasDictionaryEnums(enumValue) {
        // Check for not null object
        if (enumValue &&
            this.__templateDictionary &&
            typeof this.__templateDictionary === "object") {
            // Get array of keys
            const tmpKeys = Object.keys(
                this.__templateDictionary
            );

            // Check if exists
            return tmpKeys.includes(enumValue);
        }

        // Always false
        return false;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di verifica l'esistenza di un index nella struttura
     *
     * @param {String} indexValue
     * @returns Boolean
     * @memberof TemplateManager
     */
    hasIndex(indexValue) {
        // Check for not null object
        if (indexValue &&
            this.__templateHashStructure &&
            typeof this.__templateHashStructure === "object") {
            // Get array of keys
            const tmpKeys = Object.keys(
                this.__templateHashStructure
            );

            // Check if exists
            return tmpKeys.includes(indexValue);
        }

        // Always false
        return false;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di verifica l'esistenza di un index nella struttura
     *
     * @param {String} visibilityValue
     * @returns Boolean
     * @memberof TemplateManager
     */
    hasVisibility(visibilityValue) {
        // Check for not null object
        if (visibilityValue &&
            this.__templateVisibility &&
            typeof this.__templateVisibility === "object") {
            // Get array of keys
            const tmpKeys = Object.keys(
                this.__templateVisibility
            );

            // Check if exists
            return tmpKeys.includes(visibilityValue);
        }

        // Always false
        return false;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di verifica l'esistenza di un index nella struttura
     *
     * @param {String} visibilityValue
     * @returns Boolean
     * @memberof TemplateManager
     */
    hasMask(visibilityValue) {
        // Check for not null object
        if (visibilityValue &&
            this.__templateMaskVisibility &&
            typeof this.__templateMaskVisibility === "object") {
            // Get array of keys
            const tmpKeys = Object.keys(
                this.__templateMaskVisibility
            );

            // Check if exists
            return tmpKeys.includes(visibilityValue);
        }

        // Always false
        return false;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di verifica l'esistenza di un index nella struttura
     *
     * @param {String} typeValue
     * @returns Boolean
     * @memberof TemplateManager
     */
    hasInputType(typeValue) {
        // Check for not null object
        if (typeValue &&
            this.__templateHashInputTypes &&
            typeof this.__templateHashInputTypes === "object") {
            // Get array of keys
            const tmpKeys = Object.keys(
                this.__templateHashInputTypes
            );

            // Check if exists
            return tmpKeys.includes(typeValue);
        }

        // Always false
        return false;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di verifica l'esistenza di un header nella struttura
     *
     * @param {String} headerValue
     * @returns Boolean
     * @memberof TemplateManager
     */
    hasHeader(headerValue) {
        // Check for not null array
        if (headerValue &&
            this.__templateHeaders &&
            Array.isArray(this.__templateHeaders)) {
            // Check if exists
            return this.__templateHeaders.includes(headerValue);
        }

        // Always false
        return false;
    }

    //------------------------------------------------------------------------|
    //  PUBLIC METHODS
    //------------------------------------------------------------------------|

    /**
     * @author:         lsvanzo
     * @description:    Permette di caricare un template JSON
     *
     * @param {JSON} tplJSON
     * @memberof TemplateManager
     */
    parseTemplateJSON(tplJSON) {
        // Call reset method (soft reset)
        this.__reset();

        // Init class state
        this.__init(tplJSON);

        // Elab. template data
        this.__processTemplate();
    }

    /**
     * @author:      lsvanzo
     * @description: Permette il parsing del body delle schede mediante path.
     *
     * @param {Object} jsonData
     * @param {String} searchPath
     * @returns String
     * @memberof TemplateManager
     */
    resolveFormDataProperty(jsonData, searchPath) {
        try {
            // Check for valid JSON
            jsonData = JSON.parse(
                JSON.stringify(
                    jsonData
                )
            );
        }
        // Otherwise...
        catch (err) {
            // Show error
            console.error(err);

            // Always null
            return null;
        }

        // Check if exists or default
        const formData = jsonData ? jsonData : {};

        // Get data or null
        let pathData = searchPath;
        let pathRslt = formData;

        // Check tpl path
        if (pathData) {
            // Get array from path
            pathData = pathData.split(".");

            // Loop on path
            for (let i = 0; i < pathData.length; i++) {
                if (typeof pathRslt === "object") {
                    // If pathRslt is an array
                    if (Array.isArray(pathRslt)) {
                        let pathRsltClone = pathRslt.filter(x => x);
                        pathRslt = [];
                        for (let j = 0; j < pathRsltClone.length; j++) {
                            // Push "" if the data is undefined to keep a coherent tree
                            pathRslt.push(pathRsltClone[j][pathData[i]] ? pathRsltClone[j][pathData[i]] : "");
                        }
                    }
                    // If pathRslt is an object
                    else {
                        // Check if exists
                        if (pathRslt[pathData[i]]) {
                            // Save new data
                            pathRslt = pathRslt[pathData[i]];
                        }
                        // Always null and break loop
                        else return null;
                    }
                }
            }

            // Send back value
            return pathRslt;
        }

        // Always null
        return null;
    }

    /**
     * @author:      lsvanzo
     * @description: Permette la modifica del body delle schede mediante path.
     *
     * @param {Object} jsonData
     * @param {String} searchPath
     * @param {Object} objValue
     * @returns String
     * @memberof TemplateManager
     */
    updateFormDataProperty(jsonData, searchPath, objValue) {
        try {
            // Check for valid JSON
            jsonData = JSON.parse(
                JSON.stringify(
                    jsonData
                )
            );
        }
        // Otherwise...
        catch (err) {
            // Show error
            console.error(err);

            // Always null
            return null;
        }

        // Check if exists or default
        const formData = jsonData ? jsonData : {};

        // Check tpl path
        if (searchPath) {
            // Get array from path
            let pathData = searchPath.split(".");

            // Reverse array
            pathData = pathData.reverse();

            // Send back value
            return this.__helper_updateJsonData(
                formData,
                pathData,
                objValue
            );
        }

        // Always null
        return formData;
    }

    /**
     * @author:      lsvanzo
     * @description: Permette il parsing delle schede mediante dizionario del template.
     *
     * @param {Object} jsonData
     * @param {String} searchProperty
     * @returns String
     * @memberof TemplateManager
     */
    resolveTemplateProperty(jsonData, searchProperty) {
        // Check if valid property
        if (!this.hasDictionaryEnums(searchProperty)) {
            // Send back null
            return null;
        }

        try {
            // Check for valid JSON
            jsonData = JSON.parse(
                JSON.stringify(
                    jsonData
                )
            );
        }
        // Otherwise...
        catch (err) {
            // Show error
            console.error(err);

            // Always null
            return null;
        }

        // Check if exists or default
        const tplData  = this.__templateDictionary ? this.__templateDictionary : {};
        const formData = jsonData ? jsonData : {};

        // Get data or null
        let pathData = tplData[searchProperty] ? tplData[searchProperty] : null;
        let pathRslt = formData;

        // Check tpl path
        if (pathData) {
            // Get array from path
            pathData = pathData.split(".");

            // Loop on path
            for (let i = 0; i < pathData.length; i++) {
                // Check if exists
                if (pathRslt[pathData[i]]) {
                    // Save new data
                    pathRslt = pathRslt[pathData[i]];
                }
                // Always null and break loop
                else return null;
            }
            // Check if property with lang
            if (Array.isArray(pathRslt) &&
                pathRslt.length > 0     &&
                pathRslt[0]["value"]    &&
                pathRslt[0]["language"] &&
                pathRslt[0]["profile"]) {
                // Get correct value
                pathRslt = pathRslt[0]["value"];
            }

            // Send back value
            return pathRslt;
        }

        // Always null
        return null;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere un blocco struttura a partire dal suo index
     *
     * @param {String} searchIndex
     * @returns Boolean
     * @memberof TemplateManager
     */
    getTemplateSection(searchIndex) {
        // Check if valid property
        if (!this.hasIndex(searchIndex)) {
            // Send back null
            return null;
        }

        // Get section from storage
        let tmpObject = this.__templateHashStructure[searchIndex];

        // Send as clone or null
        return tmpObject ? { ...tmpObject } : null;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere blocchi struttura a partire dal suo input-type
     *
     * @param {String} typeIndex
     * @returns Object
     * @memberof TemplateManager
     */
    getTemplateSectionsOfType(typeIndex) {
        // Check if exists
        if (!this.hasInputType(typeIndex)) {
            // Send back null
            return [];
        }

        // Get an array of objects
        let tmpBlocks = this.__templateHashInputTypes[typeIndex].map((block) => {
            // Always return block or null
            return this.getTemplateSection(block);
        });

        // Normalize blocks
        tmpBlocks = tmpBlocks.filter(x => x);

        // Send back results
        return [...tmpBlocks];
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere un blocco header a partire dal suo index
     *
     * @param {String} headerIndex
     * @returns Object
     * @memberof TemplateManager
     */
    getHeaderSection(headerIndex) {
        // Check if exists
        if (!this.hasHeader(headerIndex)) {
            // Send back null
            return null;
        }

        // Get index hash from storage
        const intHeaderIndex = this.__templateHeaders[headerIndex];

        // Always return block or null
        return this.getTemplateSection(intHeaderIndex);
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere i blocchi header presenti
     *
     * @param {String} headerIndex
     * @returns Array
     * @memberof TemplateManager
     */
    getAllHeaders() {
        // Get an array of objects
        let tmpBlocks = this.__templateHeaders.map((head) => {
            // Always return block or null
            return this.getTemplateSection(head);
        });

        // Normalize blocks
        tmpBlocks = tmpBlocks.filter(x => x);

        // Send back results
        return [...tmpBlocks];
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere un blocco visibilità a partire dal suo index
     *
     * @param {String} visibilityIndex
     * @returns Object
     * @memberof TemplateManager
     */
    getVisibilitySection(visibilityIndex) {
        // Check if exists
        if (!this.hasVisibility(visibilityIndex)) {
            // Send back null
            return null;
        }

        // Get section from storage
        let tmpObject = this.__templateVisibility[visibilityIndex];

        // Send as clone or null
        return tmpObject ? { ...tmpObject } : null;
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere i blocchi enrichment presenti
     *
     * @returns Array
     * @memberof TemplateManager
     */
    getEnrichemntFields() {
        // Get an array of objects
        let tmpBlocks = this.__templateEnrichFields.map((block) => {
            // Always return block or null
            return this.getTemplateSection(block);
        });

        // Normalize blocks
        tmpBlocks = tmpBlocks.filter(x => x);

        // Send back results
        return [...tmpBlocks];
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere i blocchi ICCD presenti
     *
     * @returns Array
     * @memberof TemplateManager
     */
    getStandardICCDFields() {
        // Get an array of objects
        let tmpBlocks = this.__templateStIccdFields.map((block) => {
            // Always return block or null
            return this.getTemplateSection(block);
        });

        // Normalize blocks
        tmpBlocks = tmpBlocks.filter(x => x);

        // Send back results
        return [...tmpBlocks];
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere il template elaborato
     *
     * @returns Array
     * @memberof TemplateManager
     */
    getProcessedTemplate() {
        // Send back as clone
        return [...this.__templateProcessed];
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di impostare una maschera di visualizzazione
     *
     * @param {JSON} visibilityMask
     * @memberof TemplateManager
     */
    setVisibilityMask(visibilityMask) {
        try {
            // Check for valid JSON
            this.__templateMaskVisibility = JSON.parse(
                JSON.stringify(
                    visibilityMask
                )
            );
        }
        // Otherwise...
        catch (err) {
            // Show error
            console.error(err);

            // Always null
            this.__templateMaskVisibility = null;
        }

        // Call reset method (soft reset)
        this.__reset();

        // Update template data
        this.__processTemplate();
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di impostare una maschera di visualizzazione
     *
     * @memberof TemplateManager
     */
    cleanVisibilityMask() {
        // Always null
        this.__templateMaskVisibility = null;

        // Call reset method (soft reset)
        this.__reset();

        // Update template data
        this.__processTemplate();
    }

    /**
     * @author:         lsvanzo
     * @description:    Permette di ottenere un blocco maschera a partire dal suo index
     *
     * @param {String} visibilityIndex
     * @returns Object
     * @memberof TemplateManager
     */
    getMaskSection(visibilityIndex) {
        // Check if exists
        if (!this.hasMask(visibilityIndex)) {
            // Send back null
            return null;
        }

        // Always return block or null
        return this.__templateMaskVisibility[visibilityIndex];
    }

    //------------------------------------------------------------------------|
    //  PRIVATE METHODS
    //------------------------------------------------------------------------|

    /**
     * @author:         lsvanzo
     * @description:    Inizializza lo stato della classe con un template JSON
     *
     * @param {JSON} tplJSON
     * @memberof TemplateManager
     */
    __init(tplJSON) {
        // Prepare support
        let tmpJson = null;

        try {
            // Check for valid JSON
            tmpJson = JSON.parse(
                JSON.stringify(
                    tplJSON
                )
            );

            // Check for sub-components
            if (tmpJson.core_data) {
                // Save the core part
                this.__templateCore = tmpJson.core_data;
            }

            // Check for sub-components
            if (tmpJson.visibility_data) {
                // Save the visibility part
                this.__templateVisibility = tmpJson.visibility_data;
            }

            // Check for sub-components
            if (tmpJson.dictionary_data) {
                // Save the dictionary part
                this.__templateDictionary = tmpJson.dictionary_data;
            }
        }
        // Otherwise...
        catch (err) {
            // Clear state
            this.__reset();

            // Show error
            console.error(err);
        }
    }

    /**
     * @author:         lsvanzo
     * @description:    Resetta lo stato della classe
     *
     * @memberof TemplateManager
     */
    __reset(hardReset = false) {
        // Check for hard reset
        if (hardReset) {
            // Init null storages
            this.__templateDictionary = null;
            this.__templateVisibility = null;
            this.__templateMaskVisibility = null;
            this.__templateCore = null;

            // Prepare default callback
            const cbCall = () => {
                return { "keepNode": true };
            };

            // Init base callback logic
            this.__cb_processor = cbCall;
        }

        // Init template empty hash
        this.__templateHashStructure = {};
        this.__templateHashInputTypes = {};

        // Init empty array storages
        this.__templateHeaders = [];
        this.__templateProcessed = [];
        this.__templateStIccdFields = [];
        this.__templateEnrichFields = [];
    }

    /**
     * @author:         lsvanzo
     * @description:    Elabora il template in gestione
     *
     * @memberof TemplateManager
     */
    __processTemplate() {
        // Prepare recursive storage
        let tmpStorage = [];

        // Check for valid entry point
        if (this.__templateCore &&
            Array.isArray(this.__templateCore) &&
            this.__templateCore.length > 0) {
            // Delegate single blocks to helper function
            tmpStorage = this.__templateCore.map((block) => {
                // Generate additional support properties: XPATH
                block["field-edusn-xpath"] = [
                    block["field-code"]
                ];

                // Generate additional support properties: XPATH (parametrized)
                block["field-edusn-xpath-p"] = [
                    block["field-code"]
                ];

                // Check for array
                if (block["field-max-occurs"] !== 1) {
                    block["field-edusn-xpath-p"].push("#");
                }

                // Generate additional support properties: LEVEL
                block["field-tree-level"] = block["field-edusn-xpath"].length;

                // Check for parentNode or leafNode
                block["field-last-level"] = (() => {
                    return (
                        Array.isArray(block["field-tree-childs"]) &&
                        block["field-tree-childs"].length <= 0
                    );
                })();

                // Delegate childs to helper
                let tmpBlocks = this.__helper_processTemplate(
                    block,
                    block["field-edusn-xpath"],
                    block["field-edusn-xpath-p"]
                );

                // Generate base logic
                if (!this.__helper_processing_block({ ...block }, true)) {
                    // Block saving of block
                    return null;
                }

                // Always send back blocks
                return tmpBlocks;
            });

            // Normalize blocks
            tmpStorage = tmpStorage.filter(x => x);
        }

        // Save result as processed template for usage
        this.__templateProcessed = tmpStorage;
    }

    /**
     * @author:         lsvanzo
     * @description:    Elabora il template in gestione mediante ricorsione (HELPER)
     *
     * @param {Object} tplStorage
     * @memberof TemplateManager
     */
    __helper_processTemplate(tplStorage, parentCode, parentCodeParametrized) {
        // Prepare recursive storage
        let tmpStorage = [];

        // Check for parentNode or leafNode
        tplStorage["field-last-level"] = (() => {
            return (
                !Array.isArray(tplStorage["field-tree-childs"]) ||
                tplStorage["field-tree-childs"].length <= 0
            );
        })();

        // Check for valid childs into block
        if (tplStorage["field-tree-childs"] &&
            Array.isArray(tplStorage["field-tree-childs"]) &&
            tplStorage["field-tree-childs"].length > 0) {
            // Delegate single childs to helper function
            tmpStorage = tplStorage["field-tree-childs"].map((block) => {
                // Generate additional support properties: XPATH
                block["field-edusn-xpath"] = [
                    ...parentCode,
                    block["field-code"]
                ];

                // Generate additional support properties: XPATH (parametrized)
                block["field-edusn-xpath-p"] = [
                    ...parentCodeParametrized,
                    block["field-code"]
                ];

                // Check for array
                if (block["field-max-occurs"] !== 1) {
                    block["field-edusn-xpath-p"].push("#");
                }

                // Generate additional support properties: LEVEL
                block["field-tree-level"] = block["field-edusn-xpath"].length;

                // Delegate childs to helper
                let tmpBlocks = this.__helper_processTemplate(
                    block,
                    block["field-edusn-xpath"],
                    block["field-edusn-xpath-p"]
                );

                // Generate base logic
                if (!this.__helper_processing_block({ ...block }, false)) {
                    // Block saving of block
                    return null;
                }

                // Always send back blocks
                return tmpBlocks;
            });

            // Normalize blocks
            tmpStorage = tmpStorage.filter(x => x);
        }

        // Save childs based to cb
        tplStorage["field-tree-childs"] = tmpStorage;

        // Send back results
        return tplStorage;
    }

    /**
     * @author:         lsvanzo
     * @description:    Elabora il template in gestione (HELPER)
     *
     * @param {Object} block
     * @param {Boolean} saveHeader
     * @memberof TemplateManager
     */
    __helper_processing_block(block, saveHeader = false) {
        // Get visibility block
        let tmpVisibility = this.getVisibilitySection(
            block["field-edusn-srvid"]
        );

        // Get visibility mask if exists
        let tmpMaskVisibility = this.getMaskSection(
            block["field-edusn-srvid"]
        );

        // Check for hidden block
        if (tmpVisibility === null ||
            !tmpVisibility["field-is-renderable"]) {
            // Always block render
            return false;
        }

        // Remove is-renderable from visibility
        delete tmpVisibility["field-is-renderable"];

        // Prepare shared visibility object
        let firstVisibilityParam = tmpVisibility;
        let secondVisibilityParam = null;

        // Check for mask to apply
        if (tmpMaskVisibility) {
            // Remove is-renderable from mask
            delete tmpMaskVisibility["field-is-renderable"];

            // Apply mask to visibility object
            firstVisibilityParam = {
                ...tmpVisibility,
                ...tmpMaskVisibility
            };

            // Set mask as backup of previous visibility
            secondVisibilityParam = tmpVisibility;
        }

        // Get block without childs, but references
        let sectionBlock = {
            ...block,
            "field-tree-childs": []
        };

        // Get childs if exists
        if (block["field-tree-childs"] &&
            Array.isArray(block["field-tree-childs"])) {
            // Redux childs as reference
            let tmpChilds = block["field-tree-childs"].map((child) => {
                // Send back unique code
                return child["field-edusn-srvid"];
            });

            // Replace child objects with reference
            sectionBlock["field-tree-childs"] = tmpChilds;
        }

        // Save structure block hash reference
        this.__templateHashStructure[
            block["field-edusn-srvid"]
        ] = sectionBlock;

        // Prepare default value
        const cdDefaultOpt = {
            "keepNode":    false,
            "isMediaNode": false
        };

        // Get options based to callback
        const cbNodeResult = {
            // Baseline
            ...cdDefaultOpt,
            // Callback options
            ...this.__cb_processor(
                sectionBlock,
                firstVisibilityParam,
                secondVisibilityParam
            )
        };

        // Check if media node
        if (cbNodeResult.isMediaNode) {
            // Insert into hash filter blocks flagged as mediamodal
            if (firstVisibilityParam["field-is-mediamodal"]) {
                if (!this.__templateHashInputTypes["mediamodal"]) {
                    this.__templateHashInputTypes["mediamodal"] = [];
                }

                this.__templateHashInputTypes["mediamodal"].push(
                    block["field-edusn-srvid"]
                );
            }
        }

        // Check if skip node
        if (!cbNodeResult.keepNode) {
            // Always block render
            return false;
        }

        // Check for header processing
        if (saveHeader) {
            // Save header reference
            this.__templateHeaders.push(
                block["field-edusn-srvid"]
            );
        }

        // Save ICCD/Enrichment fields
        if (tmpVisibility["field-is-enrichment"]) {
            // Save into enrichment storage
            this.__templateEnrichFields.push(
                block["field-edusn-srvid"]
            );
        }
        // Otherwise...
        else {
            // Save into iccd storage
            this.__templateStIccdFields.push(
                block["field-edusn-srvid"]
            );
        }

        // Generate input-type logic hash filter
        if (block["field-input-descriptor"] &&
            typeof block["field-input-descriptor"] === "object") {
            // Extract input type from current block
            let tmpType = block["field-input-descriptor"]["input-type"];

            // Check if type exists
            if (this.hasInputType(tmpType)) {
                // Save type as reference into correct group-types
                this.__templateHashInputTypes[tmpType].push(
                    block["field-edusn-srvid"]
                );
            }
            // Otherwise...
            else if (tmpType) {
                // Save type as reference into new group-types
                this.__templateHashInputTypes[tmpType] = [
                    block["field-edusn-srvid"]
                ];
            }
        }

        // Always true
        return true;
    }

    /**
     * @author:         lsvanzo
     * @description:    Elabora il json in gestione (HELPER)
     *
     * @param {Object} oldJsonData
     * @param {Object} newValue
     * @param {Array} arrayPath
     * @memberof TemplateManager
     */
    __helper_updateJsonData(oldJsonData, arrayPath, newValue) {
        // Check from 0 to (length - 1)
        if (arrayPath.length > 1) {
            // Get first from queue
            let curIndexPath = arrayPath.pop();

            // Check if exists
            if (oldJsonData[curIndexPath] == null) {
                // Check for not empty path with type number
                if (arrayPath.length > 0 &&
                    !isNaN(arrayPath[arrayPath.length - 1])) {
                    // Check for array
                    if (Array.isArray(oldJsonData)) {
                        // Insert empty object at new index
                        oldJsonData[curIndexPath] = {};
                    }
                    // Otherwise object
                    else {
                        // Create array of empty object
                        oldJsonData[curIndexPath] = [{}];
                    }
                }
                // Otherwise string path...
                else {
                    // Create empty object
                    oldJsonData[curIndexPath] = {};
                }
            }

            // Prepare next object
            let nextObj = {};

            // Check if object already exists
            if (typeof oldJsonData[curIndexPath] === "object") {
                // Replace object
                nextObj = oldJsonData[curIndexPath];
            }

            // Replace content with result JSON of recursion
            oldJsonData[curIndexPath] = this.__helper_updateJsonData(
                nextObj,
                arrayPath,
                newValue
            );
        }
        // Last path index
        else if (arrayPath.length === 1) {
            // Get last remain from queue
            let curIndexPath = arrayPath.pop();

            // Check if exists
            if (oldJsonData[curIndexPath] === null) {
                if (typeof oldJsonData !== "object") {
                    // Check type number
                    if (!isNaN(curIndexPath)) {
                        // Create empty object
                        oldJsonData = [];
                    }
                    // Otherwise string path...
                    else {
                        // Create empty object
                        oldJsonData = {};
                    }
                }
            }

            // Replace content with new value
            oldJsonData[curIndexPath] = newValue;
        }

        // Send back the modified JSON
        return oldJsonData;
    }
};
