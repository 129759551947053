import globarUserElements from './../../globalUserElements.json';

// service to holdData
export class holdDataService {
    //------------------------------------------------------------------------| ENUM ROLES

    getRoleTypes = Object.freeze({
        "Admin":            "RBAC-RLCD-N0000001",
        "Tutor":            "RBAC-RLCD-N0000002",
        "Student":          "RBAC-RLCD-N0000003",
        "Certifier":        "RBAC-RLCD-N0000004",
        "Supervisor":       "RBAC-RLCD-N0000005",
        "ContentAdmin":     "RBAC-RLCD-N0000006"
    });

    //------------------------------------------------------------------------| GENERIC: ROLE CHECK

    getRoles = () => {
        // Prepare data
        let usrInfo = this.pullData('userInfo') ? this.pullData('userInfo') : {};

        // Send back data
        return Array.isArray(usrInfo.roles) ? usrInfo.roles : [];
    }

    hasRole = (roleType) => {
        // Prepare data
        let usrRoles = this.getRoles();

        // Loop on user roles
        for (let i = 0; i < usrRoles.length; i++) {
            // Search for role
            if (usrRoles[i].code === roleType) {
                // Send back result
                return true;
            }
        }

        // Always false
        return false;
    };

    //------------------------------------------------------------------------| SHORTCUTS: ROLE CHECK

    loggedUserIsAdmin = () => {
        return this.hasRole(this.getRoleTypes.Admin);
    };

    loggedUserIsOnlyAdmin = () => {
        return (
            this.getRoles().length === 1 &&
            this.hasRole(this.getRoleTypes.Admin)
        );
    };

    loggedUserIsContentAdmin = () => {
        return this.hasRole(this.getRoleTypes.ContentAdmin);
    };

    loggedUserIsOnlyContentAdmin = () => {
        return (
            this.getRoles().length === 1 &&
            this.hasRole(this.getRoleTypes.ContentAdmin)
        );
    };

    loggedUserIsSupervisor = () => {
        return this.hasRole(this.getRoleTypes.Supervisor);
    };

    loggedUserIsOnlySupervisor = () => {
        return (
            this.getRoles().length === 1 &&
            this.hasRole(this.getRoleTypes.Supervisor)
        );
    };

    loggedUserIsTutor = () => {
        return this.hasRole(this.getRoleTypes.Tutor);
    };

    loggedUserIsOnlyTutor = () => {
        return (
            this.getRoles().length === 1 &&
            this.hasRole(this.getRoleTypes.Tutor)
        );
    };

    loggedUserIsStudent = () => {
        return this.hasRole(this.getRoleTypes.Student);
    };

    loggedUserIsOnlyStudent = () => {
        return (
            this.getRoles().length === 1 &&
            this.hasRole(this.getRoleTypes.Student)
        );
    };

    loggedUserIsCertifier = () => {
        return this.hasRole(this.getRoleTypes.Certifier);
    };

    loggedUserIsOnlyCertifier = () => {
        return (
            this.getRoles().length === 1 &&
            this.hasRole(this.getRoleTypes.Certifier)
        );
    };

    //------------------------------------------------------------------------| GET/SET USER DATA

    getLoggedUserUuid = () => {
        return this.pullData('userInfo') ? this.pullData('userInfo').user_uuid : null;
    };

    getAuthorization = () => {
        return this.pullData('userInfo') ? this.pullData('userInfo').auth : null;
    };

    getUserData = () => {
        return this.pullData('userInfo') ? this.pullData('userInfo') : null;
    };

    pushData = (access_key, Object) => {
        localStorage.setItem(access_key, JSON.stringify(Object));
    };

    pullData = (access_key) => {
        return localStorage.getItem(access_key) ? JSON.parse(localStorage.getItem(access_key)) : false;
    };

    deleteData = (access_key) => {
        localStorage.removeItem(access_key);
    };

    deleteAllData = () => {
        localStorage.clear();
    };

    // -----------------------------------------------------------------------| GET USER ROLES

    getRolesAsName = () => {
        // Prepare empty
        let userRole = '';

        // Check for student
        if (this.loggedUserIsStudent()) {
            userRole += 'Studente';
        }

        // Check for tutor
        if (this.loggedUserIsTutor()) {
            userRole += (userRole === "") ? "" : ", ";
            userRole += 'Docente';
        }

        // Check for certifier
        if (this.loggedUserIsCertifier()) {
            userRole += (userRole === "") ? "" : ", ";
            userRole += 'Certificatore';
        }

        // Check for supervisor
        if (this.loggedUserIsSupervisor()) {
            userRole += (userRole === "") ? "" : ", ";
            userRole += 'Supervisore';
        }

        // Check for supervisor
        if (this.loggedUserIsContentAdmin()) {
            userRole += (userRole === "") ? "" : ", ";
            userRole += 'Admin dei Contenuti';
        }

        // Check for admin
        if (this.loggedUserIsAdmin()) {
            userRole += (userRole === "") ? "" : ", ";
            userRole += 'Admin';
        }

        // Check for empty role => guest
        return (userRole === "") ? "Guest" : userRole;
    }

    // -----------------------------------------------------------------------| VISIBILITY CHECK

    canOpenRoute = (keyRouteName) => {
        // Prepare visibility map
        let __roleVisibility = [];

        // Replace map for: STUDENT
        if (this.loggedUserIsStudent()) {
            __roleVisibility = [
                ...__roleVisibility,
                ...globarUserElements.SidebarElements.Student
            ];
        }

        // Replace map for: TEACHER
        if (this.loggedUserIsTutor()) {
            __roleVisibility = [
                ...__roleVisibility,
                ...globarUserElements.SidebarElements.Tutor
            ];
        }

        // Replace map for: CERTIFIER
        if (this.loggedUserIsCertifier()) {
            __roleVisibility = [
                ...__roleVisibility,
                ...globarUserElements.SidebarElements.Certifier
            ];
        }

        // Replace map for: SUPERVISOR
        if (this.loggedUserIsSupervisor()) {
            __roleVisibility = [
                ...__roleVisibility,
                ...globarUserElements.SidebarElements.Supervisor
            ];
        }

        // Replace map for: CONTENT ADMIN
        if (this.loggedUserIsContentAdmin()) {
            __roleVisibility = [
                ...__roleVisibility,
                ...globarUserElements.SidebarElements.ContentAdmin
            ];
        }

        // Replace map for: ADMINISTRATOR
        if (this.loggedUserIsAdmin()) {
            __roleVisibility = [
                ...__roleVisibility,
                ...globarUserElements.SidebarElements.Administrator
            ];
        }

        // Check map for current user role
        if (__roleVisibility.includes(keyRouteName)) {
            // Show element
            return true;
        }
        // Always hide element
        else return false;
    }
}

export default (new holdDataService())
