import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Container,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col
} from 'reactstrap';
import {
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import axios from 'axios';
import globalVars from './../../globalVars.json';
import holdDataService from '../../services/holdDataService/holdDataService';

class SelectTemplateModal extends Component {
  constructor(props) {
    super(props);
    // toggleSelectTemplate
    // modalSelectTemplate
    // selectTemplate
    // selectTemplates
    // singleSelect
    // checkWithAssign

    this.state = {
      templates: [],
      loading: false,
      selectedTemplates: [],
      singleSelectedTemplate: ""
    };
  }

  componentDidMount() {
    this.loadTemplates();
  }

  loadTemplates = () => {
    let query = '/templates';
    if (this.props.checkWithAssign) {
        query += '?check_assign=' + this.props.checkWithAssign;
    }
    this.setState({ loading: true, innerLoading: false })
    axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
      {
        auth: holdDataService.getAuthorization()
      })
      .then(res => {
        let templates = [];
        if (res.data.rowCount > 0) {
          templates = res.data.rows
        }
        this.setState({
          templates: templates,
          loading: false
        })
      }, err => {
        this.setState({ loading: false })
      });
  }

  selectTemplate = (uuid) => {
    if (this.props.singleSelect) {
      this.setState({
        singleSelectedTemplate: uuid
      });
    }
    else {
      let selectedTemplates = this.state.selectedTemplates.slice(0);
      if (selectedTemplates.includes(uuid)) {
        selectedTemplates.splice(selectedTemplates.indexOf(uuid), 1);
      }
      else {
        selectedTemplates.push(uuid);
      }
      this.setState({
        selectedTemplates: selectedTemplates
      });
    }
  }

  selectTemplates = () => {
    if (this.props.singleSelect) {
      this.props.selectTemplate(this.state.singleSelectedTemplate);
    }
    else {
      this.props.selectTemplates(this.state.selectedTemplates);
    }
  }

  getTemplatesHTML = () => {
    let templates = this.state.templates;
    let templateList = [];
    templates.map((tmp, index) => {
        templateList.push(
            <Col
                key={`tmp-${index}`}
                lg='4' md='4' sm='12'
                className={'template-icon' +
                    ((
                        this.state.selectedTemplates.includes(tmp.template_uuid) ||
                        this.state.singleSelectedTemplate === tmp.template_uuid
                    ) ? ' selected' : '')
                }
                onClick={(event) => {
                    event.preventDefault();
                    this.selectTemplate(tmp.template_uuid);
                }}>
                <div className={'template-button' + (tmp.template_uuid === this.state.singleSelectedTemplate ? ' active' : '')}>
                    <i className={"fas " + (
                        tmp.template_icon ?
                        tmp.template_icon :
                        "fa-question-circle"
                    ) + " icon"} />
                    <div className='button-text'>
                    {tmp.template_name}
                    </div>
                    <div style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontSize: "0.7em",
                        backgroundColor: "#fff",
                        borderRadius: "2px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        padding: "2px",
                        borderTop: "3px solid " + (tmp.template_has_visibility ? "#009900" : "#990000"),
                    }}>
                    {tmp.template_has_visibility ? "maschera attiva" : "maschera non attiva"}
                    </div>
                </div>
            </Col>
        );
        return true;
    });
    if (templateList.length > 0)
      return templateList;
    else
      return (
        <Col key={`tmp`} lg='12' md='12' sm='12' className='template-icon'
          onClick={event => {
            event.preventDefault();
            this.props.history.push('/');
          }}>
          <div className='template-button'>
            <FontAwesomeIcon icon={faExclamationTriangle} className='icon text-danger' />
            <div className='button-text error'>
              Errore durante il caricamento dei templates
              <br />
              Clicca per tornare alla <b>Dashboard</b>
            </div>
          </div>
        </Col>
      )
  }

  render() {

    return (
      <Modal className='edubba-modal select-template-modal'
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size='lg'
        isOpen={true}
        toggle={this.props.toggleSelectTemplate}
        backdrop='static'>
        <ModalHeader toggle={this.toggleSelectTemplate}>
          <div className='widget-content p-0'>
            <div className='widget-content-wrapper'>
              <div className='widget-content-left mr-3'>
                <i className='pe-7s-note2 icon pe' />
              </div>
              <div className='widget-content-left mr-3 text-center w-100'>
                {this.props.singleSelect ? "Seleziona la Tipologia di Scheda" : "Seleziona le Tipologie di Schede che intendi utilizzare per l'attività"}
              </div>
            </div>
          </div>
        </ModalHeader>
        {this.state.loading || this.state.innerLoading ? <EdubbaLoader small={true} /> :
          <span>
            <ModalBody>
              <Container>
                <Row style={{padding: "0 15px"}}>
                  {this.getTemplatesHTML()}
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter className='catalog-buttons'>
              <Button
                className='btn-edubba'
                onClick={() => this.props.history.push("/cards")}
              >
                Annulla
              </Button>
              <Button
                className='btn-edubba'
                disabled={(!this.props.singleSelect && this.state.selectedTemplates.length === 0) ||
                  (this.props.singleSelect && this.state.singleSelectedTemplate === "")}
                onClick={this.selectTemplates}
              >
                Continua
              </Button>
            </ModalFooter>
          </span>}
      </Modal>

    );
  }
}

export default withRouter(SelectTemplateModal);
