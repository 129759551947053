import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from '../../globalVars.json';
import {
    Row,
    Col,
    Container,
    Input,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Button,
    Alert
} from 'reactstrap';
import holdDataService from '../../services/holdDataService/holdDataService';
import ReactTooltip from 'react-tooltip';
import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../../assets/utils/images/edubba_placeholder.png'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { ChromePicker } from 'react-color';
import ReactSVG from 'react-svg';
import userLogoSrc from '../../assets/utils/images/user.svg';
import $ from 'jquery';

import './TourAssignment.scss';

class TourAssignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: "assign-base",

            //------------------------------------------------| STEP 1

            selectedTour:          null,
            selectedProject:       null,
            selectedAssignment:    null,
            selectAssignmentValue: null,
            taskName:              null,
            taskColor:             "#F5F5F5",
            startDateAssignment:   new Date().getTime(),
            endDateAssignment:     null,
            selectedGroup:         [],
            selectedCards:         []
        };
    }

    componentWillMount() {
        this.changeStep("assign-base");

        if (this.props.match.params.tour_uuid) {
            this.setState({
                selectedTour: this.props.match.params.tour_uuid
            });
        }
        else this.changeStep("cancel");
    }

    setTourData = (attrName, attrVal) => {
        this.setState({
            [attrName]: attrVal
        })
    }

    getTourData = (attrName) => {
        return (
            this.state &&
            this.state[attrName] !== null
        ) ? this.state[attrName] : null;
    }

    changeStep = (newStep) => {
        this.setState({
            currentStep: newStep
        }, () => {
            const baseStr = "Assegnazione del Percorso";
            switch (this.state.currentStep) {
                // Step 1
                case "assign-base":
                    this.props.setSectionTitle(baseStr);
                    break;

                // Do nothing
                case "cancel":
                default:
                    this.props.history.push("/tours");
                    break;
            }
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardOwner = (obj) => {
       if (obj &&
           obj.card_owner &&
           obj.card_owner.user_profile &&
           obj.card_owner.user_profile.firstName &&
           obj.card_owner.user_profile.lastName) {
           return (
               obj.card_owner.user_profile.firstName[0] +
               ". " +
               obj.card_owner.user_profile.lastName
           );
       }

       return "Non disponible..."
   }

    getCardImage = (tour_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(tour_image)) {
            coreImage = tour_image;
        }

        return coreImage;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    render() {
        return (
            <Fragment>
                {
                    /* STEP 1 */
                    this.state.currentStep === "assign-base" ? (
                        <CreationStepOne
                            changeStep={this.changeStep}
                            isValidURL={this.isValidURL}
                            getCardImage={this.getCardImage}
                            getCardOwner={this.getCardOwner}
                            setDefaultImgSrc={this.setDefaultImgSrc}
                            getFromNowDate={this.getFromNowDate}
                            setTourData={this.setTourData}
                            getTourData={this.getTourData}
                            isEmpty={this.isEmpty}
                        />
                    ) : (
                        <Container className='Tours'>
                            <Row>
                                <Col lg='12' md='12' sm='12'>
                                    <Alert color="light" className="mt-3">
                                        <h4 className="alert-heading">Errore nell'assegnazione!</h4>
                                        <p>
                                            Non è stato possibile individuare la fase del processo indicata.<br />
                                            La fase evidenziata "{this.state.currentStep}" non trovata!
                                        </p>
                                    </Alert>
                                </Col>
                            </Row>
                        </Container>
                    )
                }
            </Fragment >
        )
    }
}

//----------------------------------------------------------------------------|

// FEATURE CHECK FLAGS
const assignTypeEnabled = [
    "STSC0002"
];

class CreationStepOne extends Component {
    // props.changeStep         | Function
    // props.isValidURL         | Function
    // props.getCardImage       | Function
    // props.getCardOwner       | Function
    // props.setDefaultImgSrc   | Function
    // props.getFromNowDate     | Function
    // props.setTourData        | Function
    // props.getTourData        | Function

    constructor(props) {
        super(props);
        this.state = {
            cards:           [],
            projects:        [],
            today:           new Date().getTime(),
            showColorPicker: false,
            group_uuid:      null,

            //----------------------------------------|

            selectedTour:          props.getTourData("selectedTour"),
            selectedProject:       props.getTourData("selectedProject"),
            selectedAssignment:    props.getTourData("selectedAssignment"),
            selectAssignmentValue: props.getTourData("selectAssignmentValue"),
            taskName:              props.getTourData("taskName"),
            taskColor:             props.getTourData("taskColor"),
            startDateAssignment:   props.getTourData("startDateAssignment"),
            endDateAssignment:     props.getTourData("endDateAssignment"),
            selectedGroup:         props.getTourData("selectedGroup"),
            selectedCards:         props.getTourData("selectedCards")
        };
    }

    componentWillMount() {
        this.loadProjects();
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'tour-creation-steps';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';

        if (this.state.selectedProject) {
            this.loadProjectCards();
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    loadProjects() {
        this.setState({ loadingState: true });
        const query = '/projects';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data.rowCount > 0) {
                this.setState({
                    projects: res.data.rows,
                    loadingState: false
                });
            }
            else this.setState({
                projects: [],
                loadingState: false
            });
        }, err => {
            this.setState({
                projects: [],
                loadingState: false
            });
        });
    }

    loadProjectCards() {
        this.setState({ loadingState: true });
        let query = "/projects/" + this.state.selectedProject + "/cards";
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                let tmpCards = (
                    this.state.selectedCards.length <= 0
                ) ? res.data.rows.map((obj) => {
                    return obj.card_uuid;
                }) : this.state.selectedCards;

                this.setState({
                    cards: res.data.rows,
                    selectedCards: tmpCards,
                    loadingState: false
                }, () => {
                    this.props.setTourData("selectedCards", this.state.selectedCards);
                });
            }
            else this.setState({
                cards: [],
                selectedCards: [],
                loadingState: false
            });
        }, err => {
            this.setState({
                cards: [],
                selectedCards: [],
                loadingState: false
            });
        });
    }

    loadGroupMembers() {
        let query = '/groups/' + this.state.group_uuid;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.setState({
                selectedGroup: (
                    res.data              &&
                    res.data.rowCount > 0 &&
                    Array.isArray(res.data.rows[0]["group_members"])
                ) ? res.data.rows[0]["group_members"] : []
            }, () => {
                this.props.setTourData("selectedGroup", this.state.selectedGroup);
            });
        }, err => {
            this.setState({
                selectedGroup: []
            }, () => {
                this.props.setTourData("selectedGroup", this.state.selectedGroup);
            });
        });
    }

    handleProjectSelect = (event) => {
        const selectedProject = event.target.value;
        this.setState({
            selectedProject:       selectedProject,
            selectedCards:         [],
            selectedGroup:         [],
            selectedAssignment:    null,
            group_uuid:            null,
            selectAssignmentValue: null
        }, () => {
            this.props.setTourData("selectedCards", this.state.selectedCards);
            this.props.setTourData("selectedProject", this.state.selectedProject);
            this.props.setTourData("selectedAssignment", this.state.selectedAssignment);
            this.props.setTourData("selectAssignmentValue", this.state.selectAssignmentValue);

            this.loadProjectCards();
        });
    }

    handleAssignmentSelect = (event) => {
        let assignmentAndGroup = event.target.value.split(' ');
        this.setState({
            selectedGroup:         [],
            selectedAssignment:    assignmentAndGroup[0],
            group_uuid:            assignmentAndGroup[1],
            selectAssignmentValue: event.target.value
        }, () => {
            this.props.setTourData("selectedAssignment", this.state.selectedAssignment);
            this.props.setTourData("selectAssignmentValue", this.state.selectAssignmentValue);

            if (this.state.group_uuid &&
                this.state.selectedAssignment) {
                this.loadGroupMembers();
            }
        });
    }

    handleTaskNameChange = (event) => {
        this.setState({
            taskName: event.target.value,
        }, () => {
            this.props.setTourData("taskName", this.state.taskName);
        });
    }

    toggleColorHandler = (event) => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        }, () => {
            let container = $(".step-navigator-container");
            $(".colorpicker-popover").css({
                top: (container.height() / 12),
                left: (container.width() / 14)
            });
        });
    };

    handleColorChange = (color) => {
        this.setState({
            taskColor: color.hex
        }, () => {
            this.props.setTourData("taskColor", this.state.taskColor);
        });
    };

    handleChangeStartAssignment = (startDate) => {
        if (moment(startDate.getTime()).isAfter(moment(this.state.today))) {
            this.setState({
                startDateAssignment: startDate.getTime(),
                errorMessageTime: ''
            }, () => {
                this.props.setTourData("startDateAssignment", this.state.startDateAssignment);
            });
        }
        else {
            this.setState({
                errorMessageTime: 'Hai inserito una data inferiore a ' +
                    moment(new Date().getTime()).format('DD MMMM YY'),
                startDate: new Date().getTime(),
            }, () => {
                this.props.setTourData("startDateAssignment", null);
            });
        }
    }

    handleChangeEndAssignment = (endDate) => {
        if (this.state.startDateAssignment) {
            if (this.state.startDateAssignment <= endDate.getTime()) {
                this.setState({
                    endDateAssignment: endDate.getTime(),
                    errorMessageTime: ''
                }, () => {
                    this.props.setTourData("endDateAssignment", this.state.endDateAssignment);
                });
            }
            else {
                this.setState({
                    errorMessageTime: 'Inserire una data di fine oltre quella di scadenza'
                }, () => {
                    this.props.setTourData("endDateAssignment", null);
                })
            }
        }
    }

    upsertForTour = (cardUUID) => {
        if (cardUUID) {
            if (this.state.selectedCards.includes(cardUUID)) {
                let tmpArray = this.state.selectedCards;
                const index = tmpArray.indexOf(cardUUID);
                tmpArray.splice(index, 1);

                this.setState({
                    selectedCards: tmpArray
                }, () => {
                    this.props.setTourData("selectedCards", this.state.selectedCards);
                })
            }
            else {
                this.setState({
                    selectedCards : [
                        ...this.state.selectedCards,
                        cardUUID
                    ]
                }, () => {
                    this.props.setTourData("selectedCards", this.state.selectedCards);
                });
            }
        }
    }

    getMemberName = (obj) => {
       if (obj &&
           obj.user_profile &&
           obj.user_profile.firstName &&
           obj.user_profile.lastName) {
           return (
                <Fragment>
                    <span>{obj.user_profile.firstName}</span><br />
                    <span>{obj.user_profile.lastName}</span>
                </Fragment>
           );
       }

       return "Non disponible..."
   }

   assignToRemoteTour = () => {
       this.setState({ loadingState: true });
       const query = '/tours/' + this.state.selectedTour + '/assign';
       const body = {
            flow_uuid:       "660b0080-6dd6-4e8f-8288-c19dd38524e8",
            status_code:     "FW_ATER_0001",
            project_uuid:    this.state.selectedProject,
            assign_uuid:     this.state.selectedAssignment,
            task_name:       this.state.taskName,
            task_color:      this.state.taskColor,
            task_date_start: this.state.startDateAssignment,
            task_date_end:   this.state.endDateAssignment,
            task_cards:      this.state.selectedCards,
            task_members:    this.state.selectedGroup.map((member) => {
                return member.user_uuid;
            })
       };
       axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, body,
       {
           auth: holdDataService.getAuthorization()
       })
       .then(res => {
           if (res.data.rowCount > 0) {
               this.props.changeStep("cancel");
           }
           else this.setState({
               loadingState: false
           });
       }, err => {
           this.setState({
               loadingState: false
           });
       });
   }

    render() {
        return (
            <Fragment>
                {
                    this.state.loadingState ?
                    <EdubbaLoader /> : null
                }
                <Container className='step-navigator-container'>
                    <Row>
                        <Col lg='4' md='4' sm='4' className='step-navigator-left'>
                            <div className='form-sep-input-label'>
                                Progetto a cui Associare il Compito:
                            </div>
                            <select
                                className='legacy-form-control'
                                name='SelectProjects'
                                onChange={this.handleProjectSelect}
                                value={this.state.selectedProject ? this.state.selectedProject : ""}>
                                <option value="" disabled={true}>Seleziona progetto...</option>
                                {this.state.projects.map((obj) => {
                                    return <option key={obj.project_uuid} value={obj.project_uuid}>
                                        {obj.project_name}
                                    </option>;
                                })}
                            </select>
                            <div className='form-sep-input-label'>
                                Attività a cui Associare il Compito:
                            </div>
                            <select
                                className='legacy-form-control'
                                name='selectAssignments'
                                onChange={this.handleAssignmentSelect}
                                value={this.state.selectAssignmentValue ? this.state.selectAssignmentValue : ""}>
                                <option value="" disabled={true}>Seleziona attività...</option>
                                {
                                    this.state.projects.map((obj) => {
                                        if (obj.project_uuid === this.state.selectedProject) {
                                            return obj.project_assignments.map(assignment => {
                                                // Check if assign is not enabled for task
                                                if (!assignTypeEnabled.includes(
                                                    assignment.assign_type
                                                )) { return null; }
                                                // Otherwise show
                                                else {
                                                    return <option
                                                        key={assignment.assign_uuid}
                                                        value={assignment.assign_uuid + " " + assignment.group_uuid}>
                                                        {assignment.assign_descr}
                                                    </option>;
                                                }
                                            })
                                        }
                                        return false;
                                    })
                                }
                            </select>
                            {
                                this.state.selectedGroup.length > 0 ? (
                                    <div className="AssignGroupMembers">
                                        {
                                            this.state.selectedGroup.map((member, index) => {
                                                return (
                                                    <div className="CardMember" key={"card-member-" + index}>
                                                        <div className="CardContentLeft">
                                                            <ReactSVG className='CardMemberLogo' src={userLogoSrc} />
                                                        </div>
                                                        <div className="CardContentRight">{
                                                            this.getMemberName(member)
                                                        }</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                ) : null
                            }
                            <div className='form-sep-input-label'>
                                Nome Associato al Compito:
                            </div>
                            <Input
                                type="text"
                                name='projectName'
                                placeholder='Nome Compito'
                                autoComplete='off'
                                value={this.state.taskName ? this.state.taskName : ""}
                                onChange={this.handleTaskNameChange}
                            />
                            <div className='form-sep-input-label'>
                                Colore Associato al Compito:
                            </div>
                            <span>
                                <Input
                                    type="text"
                                    name='colorHex'
                                    placeholder='Colore del Compito'
                                    autoComplete='off'
                                    readOnly={true}
                                    value={this.state.taskColor}
                                    style={{
                                        backgroundColor: "#fff",
                                        cursor: "pointer"
                                    }}
                                    onClick={this.toggleColorHandler}
                                />
                                <div
                                    className="colorpicker-shower"
                                    style={{ backgroundColor: this.state.taskColor }}
                                />
                            </span>
                            <div className='form-sep-input-label'>
                                Data Inizio del Compito:
                            </div>
                            <DatePicker
                                name="startTourDate"
                                className="datepicker-input"
                                selected={this.state.startDateAssignment}
                                selectsStart
                                startDate={this.state.startDateAssignment}
                                endDate={this.state.endDateAssignment}
                                onChange={this.handleChangeStartAssignment}
                                dateFormat={'dd/MM/yyyy'}
                                placeholderText={'Data Inizio ...'}
                            />
                            <div className='form-sep-input-label'>
                                Data Fine del Compito:
                            </div>
                            <DatePicker
                                name="endTourDate"
                                selected={this.state.endDateAssignment}
                                selectsEnd
                                className="datepicker-input"
                                startDate={this.state.startDateAssignment}
                                endDate={this.state.endDateAssignment}
                                onChange={this.handleChangeEndAssignment}
                                dateFormat={'dd/MM/yyyy'}
                                placeholderText={'Data Fine ...'}
                            />
                            {
                                this.state.showColorPicker ?
                                    <div className="colorpicker-popover">
                                        <div
                                            className="colorpicker-cover"
                                            onClick={this.toggleColorHandler}
                                        />
                                        <ChromePicker
                                            color={this.state.taskColor}
                                            onChange={this.handleColorChange}
                                        />
                                    </div>
                                : null
                            }
                            <Row className="form-sep-input-btns">
                                <Col lg='6' md='6' sm='6'>
                                    <Button size="lg"
                                        color="danger"
                                        style={{width: "100%"}}
                                        onClick={() => this.props.changeStep("cancel")}>
                                        Annulla
                                    </Button>
                                </Col>
                                <Col lg='6' md='6' sm='6'>
                                    <Button size="lg"
                                        color="success"
                                        style={{width: "100%"}}
                                        disabled={
                                            this.props.isEmpty(this.state.selectedProject)       ||
                                            this.props.isEmpty(this.state.selectedAssignment)    ||
                                            this.props.isEmpty(this.state.selectAssignmentValue) ||
                                            this.props.isEmpty(this.state.taskName)              ||
                                            this.props.isEmpty(this.state.taskColor)             ||
                                            this.props.isEmpty(this.state.startDateAssignment)   ||
                                            this.props.isEmpty(this.state.endDateAssignment)     ||
                                            this.props.isEmpty(this.state.selectedCards)         ||
                                            this.props.isEmpty(this.state.selectedGroup)
                                        }
                                        onClick={() => this.assignToRemoteTour()}>
                                        Assegna Percorso
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg='8' md='8' sm='8' className='step-navigator-right'>
                        {
                            this.state.cards.length > 0 ?
                                <Fragment>
                                    <div className='form-sep-input-label'>
                                        Selezionare una o più Schede di Progetto:
                                    </div>
                                    <div className="list-cards-grid">
                                        {
                                            this.state.cards.map((obj) => {
                                                return (
                                                    <Card key={obj.card_uuid}
                                                    className={this.state.selectedCards.includes(
                                                        obj.card_uuid
                                                    ) ? "" : "selectedForTour"}>
                                                        <CardImg top
                                                            width="100%"
                                                            src={
                                                                this.props.getCardImage(obj.card_image)
                                                            }
                                                            onError={this.props.setDefaultImgSrc}
                                                        />
                                                        <div className="card-status" style={{
                                                            backgroundColor: "#c67e00",
                                                            color: "#ffffff"
                                                        }}>
                                                        Scheda del Progetto
                                                        </div>
                                                        <CardBody>
                                                            <CardTitle>
                                                                {obj.card_title}
                                                            </CardTitle>
                                                            <CardText>
                                                                <small className="text-muted">
                                                                    <b>Creatore:</b> {this.props.getCardOwner(obj)}
                                                                </small>
                                                                <br />
                                                                <small className="text-muted">
                                                                    <b>Creata:</b> {this.props.getFromNowDate(obj.card_date_create)}
                                                                </small>
                                                                <br />
                                                                <small className="text-muted">
                                                                    <b>Modificata:</b> {this.props.getFromNowDate(obj.card_date_update)}
                                                                </small>
                                                            </CardText>
                                                        </CardBody>
                                                        <div className='bottom-icons'>
                                                            <div
                                                                onClick={() => this.upsertForTour(obj.card_uuid)}
                                                                data-tip data-for={"TIPS_FOR_V_" + obj.card_uuid}>
                                                                {
                                                                    this.state.selectedCards.includes(
                                                                        obj.card_uuid
                                                                    ) ? "Escludi Arricchimenti" : "Includi Arricchimenti"
                                                                }
                                                                <ReactTooltip
                                                                    id={"TIPS_FOR_V_" + obj.card_uuid}
                                                                    wrapper="span"
                                                                    place="top"
                                                                    effect="solid"
                                                                    className="tooltip-user">
                                                                    Premere per includere o escludere gli arricchimenti presenti nella scheda dal tour.
                                                                </ReactTooltip>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                );
                                            })
                                        }
                                    </div>
                                </Fragment>
                            : (
                                <Alert color="light" className="mt-1">
                                    <h4 className="alert-heading">Seleziona un Progetto!</h4>
                                    <p>
                                        Non è ancora possibile visualizzare le schede su cui lavorare.<br />
                                        Per iniziare selezionare un progetto dalla colonna dedicata a lato, la selezione <br />
                                        caricherà tutte le schede associate al progetto per l'associazione con l'Art-Tour.
                                    </p>
                                </Alert>
                            )
                        }
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

//----------------------------------------------------------------------------|

export default withRouter(TourAssignment);
