import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
    CardHeader,
    Card,
    Alert
} from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import 'moment/locale/it';

import NotificationTextHandler from './../../components/NotificationTextHandler/NotificationTextHandler';

import './LastActivity.scss';

class LastActivity extends Component {
    constructor(props) {
        super(props);
        // notifications: Array

        this.state = {
            data: '',
            dropdownOpen: false
        };
    }

    render() {
        return (
            <Fragment>
                <Card className="ActivityList card-hover-shadow-2x mb-3">
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon pe-7s-clock"> </i>
                            ULTIME ATTIVITÀ
                        </div>
                    </CardHeader>
                    <PerfectScrollbar>
                        <div className="p-4" style={{ scrollBehavior: 'smooth' }}>
                            {Array.isArray(this.props.notifications) &&
                                this.props.notifications.length > 0 ? <VerticalTimeline layout="1-column">
                                    {this.props.notifications.map(obj => {
                                        let notificationType = ((notification_type) => {
                                            switch (notification_type) {
                                                case "ACTYPID_0001":
                                                    return "Gruppi";
                                                case "ACTYPID_0002":
                                                case "ACTYPID_0003":
                                                case "ACTYPID_0004":
                                                case "ACTYPID_0005":
                                                    return "Social";
                                                case "ACTYPID_0006":
                                                    return "Compiti";
                                                default:
                                                    return "Generale";
                                            }
                                        })(obj.activity_priority);
                                        let date = moment(obj.activity_date_create).format("DD-MM-YY HH:ss");
                                        // L'icona DEVE avere lo spazio altrimenti prende una classe che la nasconde
                                        return <VerticalTimelineElement
                                            key={obj.activity_uuid}
                                            className="vertical-timeline-item"
                                            icon={<i
                                                className={"badge badge-dot badge-dot-xl"}
                                                style={{ backgroundColor: obj.activity_priority_color }}
                                            > </i>}
                                            date={date}
                                        >

                                            <h4 className="timeline-title">{notificationType}</h4>
                                            <p>
                                                <NotificationTextHandler message={obj.activity_message} />
                                            </p>
                                        </VerticalTimelineElement>;
                                    })
                                    }
                                </VerticalTimeline>
                                : (
                                    <Alert color="light" className="mt-3">
                                        <h5 className="alert-heading">Nessuna attività recente!</h5>
                                        <hr />
                                        <p className="mb-0">
                                            Non sono presenti attività da visionare nell'ultima settimana.
                                    </p>
                                    </Alert>
                                )
                            }
                        </div>
                    </PerfectScrollbar>
                </Card>

            </Fragment>
        )
    }

}

export default withRouter(LastActivity);
