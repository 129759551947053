import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './../News.scss';

class Concorso_desiderio_arte_edubba_17052021 extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="ContentRoot_Data">
                <div className="ContentRoot_Head">
                    Ai Dirigenti/Coordinatori<br />
                    delle istituzioni scolastiche<br />
                    di istruzione secondaria di II grado<br />
                    statali e paritarie della Campania
                </div>
                <div className="ContentRoot_Subject">
                    OGGETTO: “Mostra-Concorso Desiderio D’Arte – III edizione”.
                </div>
                <div className="ContentRoot_Body">
                    Con il patrocinio del Comune di Procida, l’Ufficio Scolastico Regionale per la Campania, d’intesa con Databenc, promuove la III edizione del Concorso regionale “Desiderio D’Arte”. L’Ufficio, in collaborazione con Databenc, provvederà alla realizzazione di una mostra virtuale delle opere prodotte dai giovani artisti delle scuole con l’inaugurazione dell’esposizione e la proclamazione dei vincitori del concorso, attraverso una piattaforma dedicata. Tutte le opere inviate resteranno a disposizione dei promotori dell’iniziativa. L’USR per la Campania, DATABENC e il Comune di Procida si riservano la possibilità̀ di un loro utilizzo a fini espositivi e/o per l’ideazione di campagne di comunicazione istituzionale. Informazioni sul concorso potranno trovarsi all’indirizzo <a href="https://www.desideriodarte.it" target="_blank" rel="noopener noreferrer">www.desideriodarte.it</a><br />
                    <br />
                    Si invitano le SS.LL. a favorire la più ampia partecipazione all’iniziativa in oggetto.<br />
                    Confidando nella consueta collaborazione, si inviano distinti saluti.<br />
                    <br />
                    <br />
                    Allegati:<br />
                     - <i>Regolamento</i><br />
                     - <i>Scheda di presentazione</i><br />
                     - <i>Liberatoria</i>
                </div>
            </div>
        )
    }
}

export default withRouter(Concorso_desiderio_arte_edubba_17052021);
