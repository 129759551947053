import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
    ListGroupItem, ListGroup,
    Button

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    faUsers,
    faArrowRight,
    faCaretLeft,
    faWindowMaximize,
    faPaperPlane,
    faCalendarAlt,
    faStar,

} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/it';
import ReactHtmlParser from 'react-html-parser';
import holdDataService from './../../../../services/holdDataService/holdDataService';
import SocialRoomModalized from './../../../../SocialRooms/SocialRoomModalized';
import globalVars from './../../../.././globalVars.json';
import './SocialRoomsSidebar.scss';

class SocialRoomsSidebar extends Component {
    constructor(props) {
        super(props);
        // socialRooms: Array

        this.state = {
            // Modal Chat
            maximizedChatOpen: false,
            // Selected data
            selectedRoomUuid: "",
            selectedAssignUuid: "",
            selectedRoom: null,
            // Current messages for Room
            currentMessages: [],
            // Current tasks for Room
            currentTasks: [],
            tasksExpanded: false,
            // Current message in writing
            currentMessage: "",
            // Current tasks assigned to message in writing
            currentMessageTasks: [],
            // Array of true/false to keep trace of Tasks which active the filter
            filteredTasks: [],
            // Current messages for Room filtered by Tasks
            filteredMessages: [],
        };
    }

    interval = null;

    componentDidMount() {
        document.addEventListener("social-sidebar-closed", this.closeChatDetail);
    }

    componentWillUnmount() {
        document.removeEventListener("social-sidebar-closed", this.closeChatDetail);
    }

    getRoomUUID = (room) => {
        return room.room_uuid;
    }

    getRoomName = (room) => {
        return room.room_name;
    }

    getTeam = (room) => {
        return room.room_group ? room.room_group.group_name : "";
    }

    getTeamComponents = (room) => {
        let TCString = "";
        if (room.room_members) {
            for (let i = 0; i < room.room_members.length; i++) {
                let member = room.room_members[i];
                if (member.user_profile) {
                    if (TCString === "") {
                        TCString = member.user_profile.firstName + " " + member.user_profile.lastName;
                    }
                    else {
                        TCString += ", " + member.user_profile.firstName + " " + member.user_profile.lastName;
                    }
                }
            }
        }
        return TCString;
    }

    openMaximizedChat = () => {
        this.setState({
            maximizedChatOpen: true
        }, this.props.closeSidebar);
    }

    closeMaximizedChat = () => {
        this.setState({
            maximizedChatOpen: false,
            selectedRoomUuid: "",
            selectedAssignUuid: "",
            selectedRoom: null,
            currentMessages: [],
            currentMessageTasks: [],
            currentTasks: [],
            tasksExpanded: false,
            currentMessage: "",
            filteredMessages: [],
        }, this.stopPolling);
    }

    expandChatDetail = (room_uuid, assign_uuid, room) => {
        this.setState({
            selectedRoomUuid: room_uuid,
            selectedAssignUuid: assign_uuid,
            selectedRoom: room
        }, () => {
            this.intervalFunction(true);
            this.setupPolling();
        });
    }

    intervalFunction = (scrollBottom) => {
        this.loadTasks();
        this.loadAllMessages(scrollBottom);
    }

    setupPolling = () => {
        this.interval = setInterval(this.intervalFunction, 5000);
    }

    stopPolling = () => {
        clearInterval(this.interval);
    }

    closeChatDetail = () => {
        this.setState({
            selectedRoomUuid: "",
            selectedAssignUuid: "",
            selectedRoom: null,
            currentMessages: [],
            currentMessageTasks: [],
            tasksExpanded: false,
            currentTasks: [],
            currentMessage: "",
            filteredMessages: [],
        }, this.stopPolling);
    }

    getUserAttribute(user, attribute) {
        return user.user_profile[attribute];
    }

    loadAllMessages = (scrollBottom) => {
        let query = '/social-rooms/' + this.state.selectedRoomUuid + '/comments';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            this.setState({
                currentMessages: res.data.rowCount > 0 ? res.data.rows : [],
                filteredMessages: res.data.rowCount > 0 ? res.data.rows : []
            }, () => {
                if (scrollBottom) {
                    this.scrollChatDown();
                }
            });
        }, err => {
            //
        });
    }

    loadTasks = () => {
        let query = '/assignments/' + this.state.selectedAssignUuid + '/tasks';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            let tasks = res.data ? res.data.rows : [];
            let filteredTasks = Array(tasks.length).fill(false);
            this.setState({
                currentTasks: tasks,
                filteredTasks: filteredTasks
            });
        }, err => {
            //
        });
    }

    toggleExpandTasks = () => {
        this.setState({
            tasksExpanded: !this.state.tasksExpanded
        });
    }

    handleChangeMessage = (event) => {
        event.preventDefault();
        let message = event.target.value;
        this.setState({
            currentMessage: message
        }, this.checkTaskInMessage);
    }

    checkTaskInMessage = () => {
        let messageWords = this.state.currentMessage.split(' ');
        let messageTasks = [];
        let currentTasks = [];
        messageWords.map(w => {
            let possibleTask = '';
            //Controllo che ci sia u n # e di fianco almeno una lettera
            if (w.indexOf('#') !== -1 && w.length > 1) {
                possibleTask = w.toLowerCase();
                this.state.currentTasks.map(t => {
                    if (t.task_tagname.toLowerCase() === possibleTask && currentTasks.indexOf(possibleTask) === -1) {
                        messageTasks.push(t);
                        currentTasks.push(possibleTask);
                    }
                    return false;
                });
            }
            return false;
        });
        this.setState({
            currentMessageTasks: messageTasks
        });
    }

    toggleTaskFilter = (taskIndex) => {
        let filteredTasks = this.state.filteredTasks.slice(0);
        filteredTasks[taskIndex] = !filteredTasks[taskIndex];
        let filteredMessages = [];

        if (filteredTasks.indexOf(true) !== -1) {
            this.state.currentMessages.map(message => {
                if (
                    message.comment_data &&
                    Array.isArray(message.comment_data.linked_tasks) &&
                    message.comment_data.linked_tasks.length > 0
                ) {
                    message.comment_data.linked_tasks.map(linked_task => {
                        this.state.currentTasks.map((current_task, index) => {
                            if (current_task.task_tagname === linked_task.task_tagname && filteredTasks[index]) {
                                filteredMessages.push(message);
                            }
                            return false;
                        });
                        return false;
                    });
                }
                return false;
            });
        }
        else {
            filteredMessages = this.state.currentMessages.slice(0);
        }
        this.setState({
            filteredTasks: filteredTasks,
            filteredMessages: filteredMessages,
        });
    }

    createMessage = () => {
        let message = {};
        //message.comment_ref = this.state.replyingMessage.comment_uuid;
        message.comment_message = this.state.currentMessage;
        if (this.state.currentMessageTasks.length > 0) {
            message.comment_data = {};
            message.comment_data.linked_tasks = [];
            for (let i = 0; i < this.state.currentMessageTasks.length; i++) {
                message.comment_data.linked_tasks.push(this.state.currentMessageTasks[i].task_uuid);
            }
        }
        return message;
    }

    sendMessage = (event) => {
        event.preventDefault();
        if (this.state.currentMessage !== '') {
            // Modifico i tag in grassetto
            let message = this.createMessage();

            let query = '/social-rooms/' + this.state.selectedRoomUuid + '/comments';
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, message, {
                auth: holdDataService.getAuthorization()
            })
                .then(res => {
                    this.setState({
                        currentMessageTasks: [],
                        currentMessage: "",
                        //replyingMessage: null,
                        //replying: false
                    }, this.loadAllMessages);
                }, err => {
                    //
                });
        }
    }

    scrollChatDown = () => {
        let chatDiv = document.getElementById("sidebar-chat").childNodes[0];
        chatDiv.scrollTop = chatDiv.scrollHeight;
        document.getElementById("chat-input").focus();
    }

    render() {

        let collapsedDetail = this.state.selectedRoomUuid === "" && this.state.selectedAssignUuid === "" && this.state.selectedRoom === null;

        return (
            <Fragment>
                <div className="main-drawer">
                    <div className="drawer-heading">
                        SOCIAL ROOMS
                    </div>
                    <div className="drawer-section">
                        <PerfectScrollbar>
                            <div className="drawer-section p-0">
                                <div className="todo-box">
                                    <ListGroup className="todo-list-wrapper" flush>
                                        {this.props.socialRooms.map((obj, index) => {
                                            return <ListGroupItem key={this.getRoomUUID(obj)} onClick={() => this.expandChatDetail(
                                                this.getRoomUUID(obj),
                                                obj.assign_uuid,
                                                obj
                                            )}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <FontAwesomeIcon className='icon' icon={faUsers} />
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                {this.getRoomName(obj)} - <i>{this.getTeam(obj)}</i>
                                                            </div>
                                                            <div className="widget-subheading">
                                                                <i>{this.getTeamComponents(obj)}</i>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right">
                                                            <Button
                                                                className="border-0 btn-transition"
                                                                outline
                                                                color="transparent">
                                                                <FontAwesomeIcon className='icon c-p ' icon={faArrowRight} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>;
                                        })}
                                    </ListGroup>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
                <div className={"drawer-buttons" + (collapsedDetail ? " collapsed" : "")}>
                    <div className="drawer-buttons-heading">
                        <div className="drawer-buttons-heading-content">
                            <div className="drawer-button" onClick={this.closeChatDetail}>
                                <FontAwesomeIcon
                                    icon={faCaretLeft}
                                />
                            </div>
                            <div className="drawer-button">
                                {this.state.selectedRoom ? this.getRoomName(this.state.selectedRoom) : ""}
                            </div>
                            <div className="drawer-button" onClick={this.openMaximizedChat}>
                                <FontAwesomeIcon
                                    icon={faWindowMaximize}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="drawer-buttons-content">
                        <div className={"messages-container" + (this.state.tasksExpanded ? " expanded" : "")}>
                            <div className="task-area">
                                <div className="task-container">
                                    <PerfectScrollbar>
                                        {this.state.currentTasks.map((t, index) => {
                                            let selected = this.state.filteredTasks[index];
                                            return <ListGroupItem key={`task-${index}`} className={`task ${selected ? 'selected' : null}`}>
                                                <div className={`widget-content p-0`}>
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                <b>{t.task_name}</b>
                                                            </div>
                                                            <div className="widget-subheading ">
                                                                <i>{this.getUserAttribute(t.task_owner, "firstName") + " " + this.getUserAttribute(t.task_owner, "lastName")}</i>
                                                            </div>
                                                        </div>
                                                        <div className="badge text-white" style={{ backgroundColor: t.task_color }}>{t.task_tagname}</div>
                                                        {/* <FontAwesomeIcon icon={faFilter} className={`c-p ${selected ? 'text-white' : 'text-edubba'}`} */}
                                                        <i className={`pe-7s-filter c-p ${selected ? 'text-white' : 'text-edubba'}`}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                this.toggleTaskFilter(index);
                                                            }} />
                                                    </div>
                                                </div>
                                            </ListGroupItem>;
                                        })}
                                    </PerfectScrollbar>
                                </div>
                                <div className="task-container-expander" onClick={this.toggleExpandTasks}>
                                    {this.state.tasksExpanded ? "Nascondi" : "Mostra"} Task
                                </div>
                            </div>
                            <div id="sidebar-chat" className="messages-area chat-wrapper">
                                <PerfectScrollbar>
                                    {
                                        this.state.filteredMessages.map((m, index) => {
                                            return <Message key={`message-${m.comment_uuid}`}
                                                sent={holdDataService.getLoggedUserUuid() === m.comment_owner.user_uuid}
                                                comment_message={m.comment_message}
                                                comment_owner={m.comment_owner}
                                                comment_date_create={m.comment_date_create}
                                                messageIndex={index}
                                                comment_ref={m.comment_ref}
                                                replyMessage={null}
                                                replying={false}
                                                comment_owner_is_tutor={m.comment_owner_is_tutor}
                                                tasks={m.comment_data}
                                                getUserAttribute={this.getUserAttribute}
                                                roomUUID={this.state.selectedRoomUuid}
                                            />
                                        })
                                    }
                                </PerfectScrollbar>
                            </div>
                            <div className="messages-sender-area">
                                <div className="app-inner-layout__bottom-pane send-component">
                                    <div className='tasks'>
                                        {this.state.currentMessageTasks.map(t => {
                                            return <div key={`task-${t.task_uuid}`}
                                                className='badge badge-pill'
                                                style={{ backgroundColor: t.task_color }}>
                                                {t.task_tagname.toUpperCase()}
                                            </div>;
                                        })}
                                    </div>
                                    <input
                                        id='chat-input'
                                        autoFocus
                                        type='text'
                                        placeholder='Scrivi un messaggio...'
                                        value={this.state.currentMessage}
                                        onChange={this.handleChangeMessage}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                this.sendMessage(event);
                                            }
                                        }} />
                                    <FontAwesomeIcon className='icon c-p' icon={faPaperPlane} onClick={this.sendMessage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.maximizedChatOpen ? <SocialRoomModalized
                        room_uuid={this.state.selectedRoomUuid}
                        assign_uuid={this.state.selectedAssignUuid}
                        closeSocialRoom={this.closeMaximizedChat}
                    /> : null
                }
            </Fragment>
        )
    }
}

class Message extends Component {
    constructor(props) {
        super(props);
        // getUserAttribute: Function
        // roomUUID: String

        this.state = {
            repliedMessage: null
        }
    }

    componentWillMount() {
        if (this.props.comment_ref && this.props.comment_ref !== "") {
            let query = '/social-rooms/' + this.props.roomUUID + '/comments/' + this.props.comment_ref;
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
                auth: holdDataService.getAuthorization()
            })
                .then(res => {
                    this.setState({ repliedMessage: res.data ? res.data.rows[0] : null });
                }, err => {
                    //
                });
        }
    }

    getUserAvatar = () => {
        let firstName = this.props.getUserAttribute(this.props.comment_owner, "firstName");
        let lastName = this.props.getUserAttribute(this.props.comment_owner, "lastName");
        let fnLetters = firstName.split(" ");
        let lnLetters = lastName.split(" ");
        let userAvatarLetters = '';
        fnLetters.map(l => userAvatarLetters += l.slice(0, 1));
        lnLetters.map(l => userAvatarLetters += l.slice(0, 1));

        return <div className={`user-logo ${this.props.comment_owner_is_tutor ? 'tutor-logo' : null}`}>
            {userAvatarLetters}
        </div>
    }

    getTasksHTML = () => {
        let tasks = this.props.tasks &&
            Array.isArray(this.props.tasks.linked_tasks) &&
            this.props.tasks.linked_tasks.length > 0 ? this.props.tasks.linked_tasks : [];
        let taskList = [];
        tasks.map((t, i) => {
            taskList.push(
                <div key={`task-${i}`} className={`badge badge-pill`} style={{ backgroundColor: t.task_color }}>
                    {t.task_tagname ? t.task_tagname.toUpperCase() : ""}
                </div>
            );
            return true;
        });

        return <div className='tasks'>
            {taskList}
        </div>;
    }

    render() {
        let text = this.props.comment_message;
        let messageDate = this.props.comment_date_create;
        let messageTime = moment(messageDate).format('LT');
        let messagePastTime = moment(messageDate).fromNow();
        let comment_owner = this.props.comment_owner;
        let repliedMessage = this.state.repliedMessage;
        let a = moment(new Date().getTime());
        let b = moment(messageDate);
        //Se ci sono più di due giorni di distanza allora formatto in base alla data
        if (a.diff(b, 'days') >= 2) {
            messagePastTime = moment(messageDate).format('DD/MM/YY')
        }
        let comment_owner_is_tutor = this.props.comment_owner_is_tutor;
        return (
            <span>
                {this.props.sent ?
                    <div className="chat-box-wrapper chat-box-wrapper-right">
                        <div className={''}>
                            <div className="chat-box">
                                {this.getTasksHTML()}
                                <div className='message'>
                                    {ReactHtmlParser(text)}
                                </div>
                                <small className="opacity-6">
                                    <i><b>{`${this.props.getUserAttribute(comment_owner, "firstName")} ${this.props.getUserAttribute(comment_owner, "lastName")}`}</b></i>
                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 ml-1" />
                                    {messageTime} | {messagePastTime}
                                </small>
                            </div>
                        </div>
                        <div>
                            <div className="avatar-icon-wrapper ml-2">
                                {/* <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" /> */}
                                {comment_owner_is_tutor ? <FontAwesomeIcon icon={faStar} className='is-tutor' /> : null}
                                {this.getUserAvatar()}
                            </div>
                        </div>
                    </div>
                    :
                    <div className="chat-box-wrapper">
                        <div>
                            <div className="avatar-icon-wrapper mr-2">
                                {/* <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" /> */}
                                {comment_owner_is_tutor ? <FontAwesomeIcon icon={faStar} className='is-tutor' /> : null}
                                {this.getUserAvatar()}
                            </div>
                        </div>
                        <div className={repliedMessage ? 'with-messageToAnswer' : ''}>
                            {repliedMessage ?
                                <div className="chat-box ans">
                                    <div className='message'>
                                        {ReactHtmlParser(repliedMessage.comment_message)}
                                    </div>
                                    <small className="opacity-6">
                                        <i><b>{`${this.props.getUserAttribute(repliedMessage.comment_owner, "firstName")} ${this.props.getUserAttribute(repliedMessage.comment_owner, "lastName")}`}</b></i>
                                    </small>
                                </div>
                                : null}
                            <div className="chat-box">
                                {this.getTasksHTML()}
                                <div className='message'>
                                    {ReactHtmlParser(text)}
                                </div>
                                <small className="opacity-6">
                                    <i><b>{`${this.props.getUserAttribute(comment_owner, "firstName")} ${this.props.getUserAttribute(comment_owner, "lastName")}`}</b></i>
                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 ml-1" />
                                    {messageTime} | {messagePastTime}
                                </small>
                            </div>
                        </div>
                    </div>}
            </span>
        );
    }
}

export default SocialRoomsSidebar;
