import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import {
    faRocket,
    faBoxOpen,
    faCodeBranch,
    faGlobeAmericas,
    faUserClock,
    faServer
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import holdDataService from './../services/holdDataService/holdDataService';

import './Dashboard.scss';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listTools: [
                /*{ ------------------------------------------| TODO: Backoffice
                    id:    0,
                    title: "Gestione Del Database",
                    icon:  faDatabase,
                    href:  "/admin/entities",
                    can_view: (
                        holdDataService.loggedUserIsAdmin()
                    )
                },*/
                {
                    id:    1,
                    title: "Statistiche Sistema",
                    icon:  faServer,
                    href:  "/admin/stats/sysd",
                    can_view: (
                        holdDataService.loggedUserIsAdmin() ||
                        holdDataService.loggedUserIsSupervisor()
                    )
                },
                {
                    id:    2,
                    title: "Statistiche Attività",
                    icon:  faCodeBranch,
                    href:  "/admin/stats/workd",
                    can_view: (
                        holdDataService.loggedUserIsAdmin() ||
                        holdDataService.loggedUserIsSupervisor()
                    )
                },
                {
                    id:    3,
                    title: "Statistiche Utenti",
                    icon:  faUserClock,
                    href:  "/admin/stats/users",
                    can_view: (
                        holdDataService.loggedUserIsAdmin() ||
                        holdDataService.loggedUserIsSupervisor()
                    )
                },
                {
                    id:    4,
                    title: "Statistiche Endpoints",
                    icon:  faGlobeAmericas,
                    href:  "/admin/stats/endpoints",
                    can_view: (
                        holdDataService.loggedUserIsAdmin() ||
                        holdDataService.loggedUserIsSupervisor()
                    )
                },
                {
                    id:    5,
                    title: "Gestione dei Materiali",
                    icon:  faBoxOpen,
                    href:  "/third-parties/materials",
                    can_view: (
                        holdDataService.loggedUserIsAdmin()
                    )
                }
            ]
        };
    }

    componentDidMount() {
        let setTitle = (
            holdDataService.loggedUserIsAdmin() ?
            'Dashboard di Amministrazione' :
            'Dashboard di Supervisione'
        );
        this.props.setSectionTitle(setTitle);
    }

    render() {
        return (
            <Container className='Tools'>
                <Row>
                    <Col lg='12' md='12' sm='12'>
                        <div className="list-tools-grid">
                            {
                                this.state.listTools.map((obj) => {
                                    if (!obj.can_view) {
                                        return null;
                                    }

                                    return (
                                        <Card key={obj.id}>
                                            <div className="tools-table">
                                                <div className="tools-table-cell">
                                                    <FontAwesomeIcon icon={obj.icon} />
                                                </div>
                                            </div>
                                            <CardBody>
                                                <CardTitle>
                                                    {obj.title}
                                                </CardTitle>
                                            </CardBody>
                                            <div className='bottom-icons'>
                                                <Link to={obj.href}>
                                                    <div
                                                        data-tip data-for={"TIPS_FOR_V_" + obj.id}>
                                                        <FontAwesomeIcon
                                                            className='icon'
                                                            icon={faRocket}
                                                        />
                                                        <ReactTooltip
                                                            id={"TIPS_FOR_V_" + obj.id}
                                                            wrapper="span"
                                                            place="top"
                                                            effect="solid"
                                                            className="tooltip-user">
                                                            Apri Strumento
                                                        </ReactTooltip>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Card>
                                    );
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default withRouter(Dashboard);
