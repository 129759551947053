import React, { Fragment } from 'react';

import {
    DropdownToggle, DropdownMenu, UncontrolledButtonDropdown
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

import ReactSVG from 'react-svg';
import userLogoSrc from '../../../assets/utils/images/user.svg';
import UserCard from '../../../components/UserCard/UserCard';

import holdataService from './../../../services/holdDataService/holdDataService';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        // userName: String
        // registerLogout: Function

        this.state = {
            userName: '',
            userType: '',
            userRole: '',
            active: false,
            counters: {},
        };

    }

    componentDidMount() {
        let userRole = holdataService.getRolesAsName();
        this.setState({
            userRole: userRole,
            userType: this.props.userType
        });
    }

    render() {
        return (
            <Fragment>
                <div className=" pr-0">
                    <div className="widget-content p-0">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="link" className="p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <ReactSVG className='svg-class user-logo' src={userLogoSrc} />
                                    </div>
                                    <div className="widget-content-left  ml-3 header-user-info">
                                        <div className="widget-heading">
                                            {this.props.userName}
                                        </div>
                                        <div className="widget-subheading">
                                            {this.state.userRole}
                                        </div>
                                    </div>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu right className='rm-pointers dropdown-menu-lg'>
                                <UserCard
                                    userName={this.props.userName}
                                    userType={this.state.userRole}
                                    groups={this.props.groups}
                                    cards={this.props.cards}
                                    registerLogout={this.props.registerLogout}
                                />
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default withRouter(UserBox);
