import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './assets/base.scss';
import Main from './Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { isMobile, isTablet, isIOS, /*isSafari,*/ isMobileSafari } from "react-device-detect";

import './Print.scss';

// Prepare app
const store = configureStore();
const rootElement = document.getElementById('root');

// Make React resilient to DOM Mutation (google translator)
rootElement.setAttribute('notranslate', true);

// Render app
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            {
                (
                    isMobile ||
                    isTablet ||
                    isIOS ||
                    //isSafari ||
                    isMobileSafari
                )
                    ? <Main /> /*<ServicePage />*/ : <Main />
            }
        </BrowserRouter>
    </Provider>,
    rootElement
);

// if (module.hot) {
//     module.hot.accept('./DemoPages/Main', () => {
//         const NextApp = require('./DemoPages/Main').default;
//         renderApp(NextApp);
//     });
// }
registerServiceWorker();
