import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './../News.scss';

class Content_pdf_dii_02122019 extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="ContentRoot_Data">
                <div className="ContentRoot_Head">
                    Ai Dirigenti/Coordinatori<br />
                    delle istituzioni scolastiche<br />
                    di istruzione secondaria di II grado<br />
                    statali e paritarie della Campania
                </div>
                <div className="ContentRoot_Subject">
                    OGGETTO: “DATABENC ART per le scuole” II Edizione.<br />
                    Formazione ed educazione per la conoscenza, la conservazione e la valorizzazione dei Beni Culturali.<br />
                    Incontri informativi.
                </div>
                <div className="ContentRoot_Body">
                    Nell'ambito della II edizione del progetto “Databenc Art per le scuole - Formazione ed
                    educazione per la conoscenza, la conservazione e la valorizzazione dei Beni Culturali”, questa
                    Direzione Generale ha organizzato due incontri con gli esperti di Databenc, i quali
                    presenteranno la nuova piattaforma Edubba per la catalogazione dei Beni Culturali, arricchita
                    nella nuova versione 2.0 e finalizzata all’implementazione delle attività previste dal progetto.
                    Gli incontri, rivolti a tutor e certificatori del progetto, si terranno dalle ore 15.00 alle ore
                    19:00 nei giorni e nella sede di seguito indicati:<br />
                    <br />
                    <ul>
                        <li>11 dicembre 2019 presso il Laboratorio Icaro, Centro ICT di Ateneo per i Beni Culturali -
                        Università di Salerno- Piano Interrato Edificio E1 - Campus di Fisciano,Via Giovanni Paolo II,
                        132 84084 Fisciano (SA) per le province di Salerno, Avellino e Benevento</li>
                        <li>12 dicembre 2019 presso Università degli Studi di Napoli Federico II, Facoltà di Ingegneria
                        Via Nuova Agnano (Napoli) per le province di Napoli e Caserta.</li>
                    </ul>
                    <br />
                    La domanda di partecipazione dovrà essere presentata, entro il 9 dicembre 2019,
                    esclusivamente attraverso il modulo presente al seguente indirizzo:<br />
                    <a href="https://forms.gle/UZCLeaaESRFZxkxK9" target="_blank" rel="noopener noreferrer">
                        https://forms.gle/UZCLeaaESRFZxkxK9
                    </a><br />
                    <br />
                    (per accedere, premere il tasto CTRL + clic del mouse utilizzando esclusivamente il browser Google Chrome)<br />
                    Si invitano le SS.LL. a favorire la partecipazione dei docenti interessati.<br />
                    Per ulteriori informazioni contattare: <b>081/5576530 – 650</b>.
                </div>
            </div>
        )
    }
}

export default withRouter(Content_pdf_dii_02122019);
