import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from './../globalVars.json';
import {
    Row, Col, Button, Container, Card, Input,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import EdubbaLoader from './../components/EdubbaLoader/EdubbaLoader';
import moment from 'moment';
import 'moment/locale/it';
import holdDataService from './../services/holdDataService/holdDataService';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    faPlus,
    faEdit,
    faChalkboardTeacher,
    faTimes,
    faUsers,
    faSearch,
    faExclamationTriangle,
    faTrash,
    faArrowCircleLeft,
    faArrowCircleRight,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupButtonDropdown from 'reactstrap/lib/InputGroupButtonDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import CardBody from 'reactstrap/lib/CardBody';
import CardTitle from 'reactstrap/lib/CardTitle';
import CardText from 'reactstrap/lib/CardText';
import ReactTooltip from 'react-tooltip';
import Alert from 'reactstrap/lib/Alert';
import './Classrooms.scss';

class Classrooms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentInstitutes: [],
            currentClassrooms: [],
            classesAndUsers: [],
            classroomsLoading: true,
            usersLoading: true,

            addUserSelectedClassroom: -1,
            deleteSelectedClassroom: null,

            selectedInstitute: '',
            editSelectedClassroom: null,

            searchValue: '',
            filteredClassrooms: [],
            filteredInstitutes: [],

            //Modali
            modalAddUser: false,
            modalAddClassroom: false,
            modalDeleteClassroom: false,
            modalEditClassroom: false,

            tooltipOpen: false,
            toggleSkipNotificationUpdate: false,

            //----------------------------------------------| SEARCH

            searchClassValue: "",
            classInstituteCodeFilter: "",
            classCatNameFilter: "",

            //----------------------------------------------| PAGING

            classCatSplitButtonOpen: false,
            classSplitButtonOpen: false,
            classTotalEntities: 0,
            classPageLimit: 10,
            classPageNumber: 1,

            //----------------------------------------------| MODAL CONFIRM
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
        };

    }

    componentWillMount() {
        this.props.setSectionTitle('Anagrafica delle Classi');
        this.loadInstitutes();
        this.loadClassrooms();
    }

    loadInstitutes = () => {
        let query = '/institutes';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data.rowCount > 0) {
                this.setState({
                    currentInstitutes: res.data.rows
                });
            } else {
                this.setState({
                    currentInstitutes: []
                });
            }
        }, err => {
            this.setState({
                currentInstitutes: []
            });
        });
    }

    loadClassrooms = (searchValue = null, filterByCategory = null) => {
        this.setState({ classroomsLoading: true })
        let query = '/classrooms?limit=' + this.state.classPageLimit + '&page=' + this.state.classPageNumber;
        if (filterByCategory) {
            query += '&filterCategory=' + filterByCategory;
        }
        if (searchValue) {
            query += '&searchBy=' + searchValue;
        }
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    classTotalEntities: res.data.rowCount,
                    currentClassrooms:  res.data.rows,
                    classroomsLoading:  false
                });
            }
            else {
                this.setState({
                    classPageNumber:    1,
                    classTotalEntities: 0,
                    currentClassrooms:  [],
                    classroomsLoading:  false
                });
            }
        }, err => {
            this.setState({
                classPageNumber:    1,
                classTotalEntities: 0,
                currentClassrooms:  [],
                classroomsLoading:  false
            });
        });
    }

    toggleAddUser = (classroom) => {
        this.setState({
            modalAddUser: !this.state.modalAddUser,
            modalAddClassroom: this.state.modalAddClassroom,
            addUserSelectedClassroom: classroom,
        });
    }

    toggleDeleteClassroom = (classroom) => {
        this.setState({
            modalDeleteClassroom: !this.state.modalDeleteClassroom,
            deleteSelectedClassroom: classroom
        });
    }

    toggleAddClassroom = (institute) => {
        this.setState({
            toggleSkipNotificationUpdate: !this.state.toggleSkipNotificationUpdate,
            modalAddClassroom: !this.state.modalAddClassroom,
            selectedInstitute: institute
        },
        () => {
            this.props.notificationUpdate(
                this.state.toggleSkipNotificationUpdate
            )
        });
    }

    toggleEditClassroom = (classroom, institute) => {
        this.setState({
            toggleSkipNotificationUpdate: !this.state.toggleSkipNotificationUpdate,
            modalEditClassroom: !this.state.modalEditClassroom,
            editSelectedClassroom: classroom,
            selectedInstitute: institute
        },
        () => {
            this.props.notificationUpdate(
                this.state.toggleSkipNotificationUpdate
            )
        });
    }

    deleteClassroom = (event) => {
        event.preventDefault();
        let classroomUuid = this.state.deleteSelectedClassroom;
        let query = "/classrooms/" + classroomUuid;
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            // Check
            if (res && res.data && res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Classe",
                    modalConfirmToastMessage: "Classe selezionata rimossa con successo dalla proprio istituto!",
                    modalDeleteClassroom: false,
                    deleteSelectedClassroom: null
                }, () => {
                    // Refresh
                    this.loadClassrooms();
                });
            } else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Classe",
                    modalConfirmToastMessage: "Impossibile completare la rimozione della classe selezionata: verificare che non sia associata a qualche attività, gruppi o studenti.",
                    modalDeleteClassroom: false,
                    deleteSelectedClassroom: null
                });
            }
        }, err => {
            // Error
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Rimozione Classe",
                modalConfirmToastMessage: err.message,
                modalDeleteClassroom: false,
                deleteSelectedClassroom: null
            });
        });
    }

    updateClassroom = () => {
        // Refresh
        this.loadClassrooms(
            this.state.searchClassValue,
            this.state.classInstituteCodeFilter
        );
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    creatingClassroom = (flag) => {
        if (!flag) {
            // Aggiorno gli istituti
            this.loadClassrooms()
        }
        this.setState({
            classroomsLoading: flag
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    classCatToggleSplit = () => {
        this.setState({
            classCatSplitButtonOpen: !this.state.classCatSplitButtonOpen
        });
    }

    setClassCatFilter = (categoryCode, categoryName) => {
        this.setState({
            classInstituteCodeFilter: categoryCode,
            classCatNameFilter: categoryName
        }, () => {
            this.loadClassrooms(
                this.state.searchClassValue,
                this.state.classInstituteCodeFilter
            );
        });
    }

    goToClassPage = (pageNumber) => {
        this.setState({
            classPageNumber: pageNumber
        }, () => {
            this.loadClassrooms(
                this.state.searchClassValue,
                this.state.classInstituteCodeFilter
            );
        });
    }

    classToggleSplit = () => {
        this.setState({
            classSplitButtonOpen: !this.state.classSplitButtonOpen
        });
    }

    handleClassSearch = (ev) => {
        this.setState({
            searchClassValue: ev.target.value
        })
    }

    searchClass = (ev) => {
        const keyCode = ev.which || ev.keyCode;
        if (keyCode === 13) {
            this.loadClassrooms(
                this.state.searchClassValue,
                this.state.classInstituteCodeFilter
            );
        }
    }

    clearSearchClasss = () => {
        this.setState({
            searchClassValue: "",
            classPageNumber: 1
        }, () => {
            this.loadClassrooms();
        });
    }

    render() {
        let classPages = Math.ceil(
            this.state.classTotalEntities /
            this.state.classPageLimit
        );

        classPages = (classPages <= 0) ? 1 : classPages;

        return (
            <Fragment>
                <Container className='Classrooms'>
                    {
                        this.state.classroomsLoading ?
                            <EdubbaLoader />
                        : null
                    }
                    <Fragment>
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <div className="task-container">
                                    <div className="task-container-title">Le Mie Classi:</div>
                                    <div className="task-container-search">
                                        <InputGroup>
                                            <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.classSplitButtonOpen} toggle={this.classToggleSplit}>
                                                {
                                                    holdDataService.loggedUserIsTutor() ? (
                                                        <Button onClick={(event) => {
                                                            event.preventDefault();
                                                            this.toggleAddClassroom()
                                                        }}>
                                                            <FontAwesomeIcon className='icon' icon={faPlus} />
                                                        </Button>
                                                    ) : null
                                                }
                                                <Button disabled={true}>
                                                    <b>Pagina: &nbsp;</b>{ this.state.classPageNumber }
                                                </Button>
                                                { classPages > 1 ? <DropdownToggle split /> : null }
                                                <DropdownMenu>
                                                    {
                                                        // Gen. pages
                                                        (() => {
                                                            let pages = [];
                                                            for (let i = 1; i <= classPages; i++) {
                                                                if (this.state.classPageNumber === i) {
                                                                    continue;
                                                                }
                                                                else pages.push(
                                                                    <DropdownItem
                                                                        key={"card-page-" + i}
                                                                        onClick={() => this.goToClassPage(i)}>
                                                                        Vai alla Pagina: &nbsp;{i}
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            return pages;
                                                        })()
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                            <Input
                                                value={this.state.searchClassValue}
                                                onChange={this.handleClassSearch}
                                                onKeyUp={this.searchClass}
                                                placeholder="Premere invio per cercare..."
                                            />
                                            {
                                                !this.isEmpty(this.state.searchClassValue) ? (
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.clearSearchClasss}>
                                                            <FontAwesomeIcon className='icon' icon={faTrash} />
                                                        </Button>
                                                    </InputGroupAddon>
                                                ) : null
                                            }
                                            <InputGroupButtonDropdown
                                                className="task-container-category-filter"
                                                addonType="append"
                                                isOpen={this.state.classCatSplitButtonOpen}
                                                toggle={this.classCatToggleSplit}>
                                                <DropdownToggle split>
                                                    {
                                                        this.state.classInstituteCodeFilter !== "" &&
                                                        this.state.classCatNameFilter !== "" ?
                                                        "Filtra: " + this.state.classCatNameFilter :
                                                        "Tutti gli Istituti"
                                                    } &nbsp;
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        key={"class-category-" + 0}
                                                        onClick={() => this.setClassCatFilter("")}
                                                    >
                                                        Visualizza: &nbsp;Tutte
                                                    </DropdownItem>
                                                    {
                                                        this.state.currentInstitutes.map((item, index) => {
                                                            return (
                                                                <DropdownItem
                                                                    key={"class-category-" + (index + 1)}
                                                                    onClick={() => this.setClassCatFilter(item.institute_uuid, item.institute_name)}>
                                                                    Visualizza: &nbsp;{item.institute_name}
                                                                </DropdownItem>
                                                            );
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </div>
                                    <div className="task-container-arrow-left">
                                        <Button disabled={this.state.classPageNumber <= 1} onClick={() => this.goToClassPage(
                                            this.state.classPageNumber - 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                        </Button>
                                    </div>
                                    <div className="task-container-arrow-right">
                                        <Button disabled={this.state.classPageNumber >= classPages} onClick={() => this.goToClassPage(
                                            this.state.classPageNumber + 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                        </Button>
                                    </div>
                                    <div className="task-container-list">
                                    {
                                        this.state.currentClassrooms.length > 0 ?
                                            <div className="list-classs-grid">
                                                {
                                                    this.state.currentClassrooms.map((obj, index) => {
                                                        return (
                                                            <Card key={"class-idx-" + (index + 1)}>
                                                                <div className="tools-table">
                                                                    <div className="tools-table-cell">
                                                                        <FontAwesomeIcon icon={faChalkboardTeacher} />
                                                                    </div>
                                                                </div>
                                                                <div className="class-status" style={{
                                                                    backgroundColor: "#c67e00",
                                                                    color: "#ffffff"
                                                                }}>
                                                                    {
                                                                        "CLASSE SCOLASTICA"
                                                                    }
                                                                </div>
                                                                <CardBody>
                                                                    <CardTitle>
                                                                        {obj.class_name.toUpperCase()}
                                                                    </CardTitle>
                                                                    <CardText>
                                                                        <small className="text-muted">
                                                                            <b>Membri:</b> {obj.class_members.length} Student{
                                                                                obj.class_members.length === 1 ? "e" : "i"
                                                                            }
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Anno:</b> {obj.class_year}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Istituto:</b> {obj.institute_name}
                                                                        </small>
                                                                    </CardText>
                                                                </CardBody>
                                                                <div className={'bottom-icons ' + (
                                                                    holdDataService.loggedUserIsTutor() ?
                                                                    'bottom-icons-two-cols' : ''
                                                                ) }>
                                                                    {
                                                                        holdDataService.loggedUserIsTutor() ? (
                                                                            <Fragment>
                                                                                <div
                                                                                    onClick={() => this.toggleEditClassroom(obj, obj.institute_uuid)}
                                                                                    data-tip data-for={"TIPS_FOR_V_" + obj.class_uuid}>
                                                                                    <FontAwesomeIcon
                                                                                        className='icon'
                                                                                        icon={faEdit}
                                                                                    />
                                                                                    <ReactTooltip
                                                                                        id={"TIPS_FOR_V_" + obj.class_uuid}
                                                                                        wrapper="span"
                                                                                        place="top"
                                                                                        effect="solid"
                                                                                        className="tooltip-user">
                                                                                        Premere per modificare i componenti della classe.
                                                                                    </ReactTooltip>
                                                                                </div>
                                                                                <div className="red-action-btn"
                                                                                    onClick={() => this.toggleDeleteClassroom(obj.class_uuid)}
                                                                                    data-tip data-for={"TIPS_FOR_DELETE_" + obj.class_uuid}>
                                                                                    <FontAwesomeIcon
                                                                                        className='icon'
                                                                                        icon={faTrash}
                                                                                    />
                                                                                    <ReactTooltip
                                                                                        id={"TIPS_FOR_DELETE_" + obj.class_uuid}
                                                                                        wrapper="span"
                                                                                        place="top"
                                                                                        effect="solid"
                                                                                        className="tooltip-user">
                                                                                            ATTENZIONE: Permette la rimozione della classe!
                                                                                    </ReactTooltip>
                                                                                </div>
                                                                            </Fragment>
                                                                        ): (
                                                                            <div
                                                                                onClick={() => this.toggleEditClassroom(obj, obj.institute_uuid)}
                                                                                data-tip data-for={"TIPS_FOR_V_" + obj.class_uuid}>
                                                                                <FontAwesomeIcon
                                                                                    className='icon'
                                                                                    icon={faEye}
                                                                                />
                                                                                <ReactTooltip
                                                                                    id={"TIPS_FOR_V_" + obj.class_uuid}
                                                                                    wrapper="span"
                                                                                    place="top"
                                                                                    effect="solid"
                                                                                    className="tooltip-user">
                                                                                    Premere per visualizzare i componenti della classe.
                                                                                </ReactTooltip>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : (
                                            <Row>
                                                <Col lg='12' md='12' sm='12'>
                                                    <Alert color="light" className="mt-3">
                                                        <h4 className="alert-heading">Nessuna classe trovata!</h4>
                                                        <p>
                                                            Non è ancora stata creata una classe su cui lavorare.<br />
                                                            Appena creata la classe sarà visibile nella sezione corrente.
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0">
                                                            Premi sul tasto "+" presente a lato della barra di ricerca.
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
                {/* v CONFIRM: modalConfirmToast v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalConfirmToast}
                    toggle={this.closeModalConfirmToast}>
                    <ModalHeader toggle={this.closeModalConfirmToast}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    {this.state.modalConfirmToastTitle}
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            {this.state.modalConfirmToastMessage}
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                    </ModalFooter>
                </Modal>
                {/* ^ CONFIRM: modalConfirmToast ^ */}
                {/* Modale per l'aggiunta di studenti alla classe */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalDeleteClassroom}
                    toggle={this.toggleDeleteClassroom}>
                    <ModalHeader toggle={this.toggleDeleteClassroom}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon className='icon' icon={faUsers} />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Cancellazione della Classe
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            Vuoi davvero rimuovere la classe selezionata? L'operazione non sarà possibile se la classe contiene qualche attività!
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={(event) => {
                            event.preventDefault();
                            this.toggleDeleteClassroom(this.state.deleteSelectedClassroom)
                        }}>Annulla</Button>
                        <Button className='btn-edubba' onClick={this.deleteClassroom}>Elimina Classe</Button>
                    </ModalFooter>
                </Modal>
                {/* Modale per la modifica di una classe */}
                {this.state.editSelectedClassroom !== '' ?
                    <AddStudentToClassroomModal
                        toggleAddUser={this.toggleAddUser}
                        modalAddUser={this.state.modalAddUser}
                        updateClassroom={this.updateClassroom}
                        classroom={this.state.addUserSelectedClassroom}
                        institute={this.state.selectedInstitute}
                    />
                    : null}
                {/* Modale per la creazione di una nuova classe */}
                {this.state.selectedInstitute !== '' ?
                    <CreateNewClassroomModal
                        institutes={this.state.currentInstitutes}
                        creatingClassroom={this.creatingClassroom}
                        modalAddClassroom={this.state.modalAddClassroom}
                        toggleAddClassroom={this.toggleAddClassroom} />
                    : null}
                {/* Modale che consente di apportare modifiche ad una classe */}
                {this.state.editSelectedClassroom ?
                    <EditClassroom
                        // classroom={this.state.classrooms[this.state.editSelectedClassroom].classroom_uuid}
                        modalEditClassroom={this.state.modalEditClassroom}
                        toggleEditClassroom={this.toggleEditClassroom}
                        toggleAddUser={this.toggleAddUser}
                        classroom={this.state.editSelectedClassroom}
                        updateClassroom={this.updateClassroom}
                    />
                    : null}
            </Fragment>
        )
    }
}

class EditClassroom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classroom: {},
            students: [],
            loading: false,

            modalAddStudent: false,
        };
    }

    componentDidMount() {
        this.setState({
            classroom: this.props.classroom
        }, this.loadStudents)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalEditClassroom)
            this.setState({
                classroom: nextProps.classroom
            }, () => this.loadStudents())
        else
            this.setState({
                // classroom: {},
                students: [],
            })
    }

    loadStudents = () => {
        // console.log(this.state.classroom)
        if (this.state.classroom.class_uuid !== undefined) {
            this.setState({ loading: true })
            let query = "/classrooms/" + this.state.classroom.class_uuid;
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {
                    auth: holdDataService.getAuthorization()
                })
                .then(res => {
                    // console.log(res);
                    let students = [];
                    if (res.data.rowCount > 0) {
                        students = res.data.rows[0].class_members;
                    }
                    this.setState({
                        students: students,
                        loading: false
                    });
                }, err => {

                });
        }
    }

    removeStudent = (userUuid) => {
        let allUuids = [];
        this.state.students.map(s => {
            if (userUuid !== s.user_uuid) {
                allUuids.push(s.user_uuid);
            }
            return false;
        });
        let query = '/classrooms/' + this.state.classroom.class_uuid + '/members';
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            data: {
                class_members: [userUuid]
            },
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.loadStudents();
            this.props.updateClassroom();
        }, err => {

        });
    }

    getAllStudentsHTML = () => {
        let students = this.state.students;
        let studentList = [];
        students.map((s, index) => {
            studentList.push(
                <div key={`student-row-${index}`} className='student-row'>
                    <div className='name'>
                        {`${s.user_profile.firstName} ${s.user_profile.lastName}`}
                    </div>
                    <div className='mail'>
                        {s.user_name}
                    </div>
                    <div className='icons'>
                        {/* <FontAwesomeIcon className='icon' icon={faEdit} /> */}
                        <FontAwesomeIcon className='icon' icon={faTimes} onClick={event => {
                            event.preventDefault();
                            this.removeStudent(s.user_uuid);
                        }} />
                    </div>
                </div>
            );
            return true;
        });
        if (studentList.length > 0)
            return studentList;
        return <div className='student-row zero-students'>
            <div className='name'>
                Non ci sono studenti in questa Classe
            </div>
        </div>

    }

    toggleAddStudents = () => {
        this.setState({
            modalAddStudent: !this.state.modalAddStudent,
        });

    }

    render() {
        let classroom = this.props.classroom;
        return (
            <Modal className='edubba-modal add-user-modal'
                centered
                aria-labelledby="contained-modal-title-vcenter"
                size='lg'
                isOpen={this.props.modalEditClassroom}
                toggle={this.props.toggleEditClassroom}
                backdrop='static' >
                <ModalHeader toggle={this.props.toggleEditClassroom}>
                    <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                            <div className='widget-content-left mr-3'>
                                <FontAwesomeIcon className='icon' icon={faChalkboardTeacher} />
                            </div>
                            <div className='widget-content-left mr-3 text-center w-100'>
                                Classe {classroom.class_name} - Anno Scolastico {classroom.class_year}
                            </div>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody style={this.state.loading ? { backgroundColor: '#00000030' } : null}>
                    <Container>
                        {this.state.loading ?
                            <EdubbaLoader small={true} />
                            :
                            <Row className='classroom-row student-container'>
                                <PerfectScrollbar>
                                    <Col lg='12' md='12' sm='12' className='text-center'>
                                        {this.getAllStudentsHTML()}
                                    </Col>
                                </PerfectScrollbar>
                            </Row>
                        }
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-edubba' onClick={(event) => {
                        event.preventDefault();
                        this.props.toggleEditClassroom();
                    }}>Chiudi</Button>
                    <Button className='btn-edubba' onClick={(event) => {
                        event.preventDefault();
                        this.props.toggleAddUser(classroom);
                    }}>Aggiungi Studenti</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

class AddStudentToClassroomModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groupName: '',
            searchValue: '',
            filteredStudents: [],
            originalClassMembers: [],

            classroom: props.classroom,
            students: [],
            selectedStudents: [],

            modalCreateStudent: false,
            institute: props.institute,

            loading: false,
        };
    }

    componentDidMount() {
        this.loadStudents();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modalAddUser) {
            this.setState({
                classroom: nextProps.classroom,
                institute: nextProps.institute,
            }, () => {
                this.loadStudents();
            });
        } else {
            this.setState({
                groupName: '',
                searchValue: '',
                filteredStudents: [],
                originalClassMembers: [],

                firstName: '',
                lastName: '',
                userName: '',

                classroom: {},
                students: [],
                selectedStudents: [],
            });
        }
    }

    loadStudents = () => {
        if (this.state.institute !== '' && typeof this.state.institute === 'string') {
            let query = "/institutes/" + this.state.institute + "/users?userType=RBAC-RLCD-N0000003";
            this.setState({ loading: true })
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let students = [];
                if (res.data.rowCount > 0) {
                    res.data.rows.map(s => {
                        if (s.user_classrooms.length > 0) {
                            if (!s.user_classrooms[0].class_is_current)
                                students.push(s);
                        } else students.push(s);
                        return true;
                    })
                }
                this.setState({
                    students: students,
                    loading: false,
                });
            }, err => {
                this.setState({
                    students: [],
                    loading: false,
                });
            });
        }
    }

    loadNotAddedStudents = () => {
        // Rimuovo gli studenti già aggiunti (rimuovo per stesso username)
        // let groupName = this.props.group.group_name;
        let groupName = 'Classe';
        let students = this.state.students;
        // let addedStudents = this.props.addedStudents;
        let addedStudents = [];
        let filteredStudents = [];
        students.map(s1 => {
            let addStudent = true;
            addedStudents.map(s2 => {
                if (s1.user_name === s2.user_name)
                    addStudent = false;
                return true;
            });
            if (addStudent)
                filteredStudents.push(s1);
            return true;
        })
        this.setState({
            groupName: groupName,
            students: filteredStudents,
            selectedStudents: addedStudents,
        });
    }

    handleChange = (event) => {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
            errorMessage: ''
        }, () => {
            if (name === 'searchValue') {
                this.setFilteredStudents();
            }
        })
    }

    setFilteredStudents = () => {
        let filteredStudents = [];

        if (this.state.students.length > 0) {
            filteredStudents = this.state.students.filter(student =>
                // Filtro per nome
                (student.user_profile.firstName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // Filtro per cognome della classe
                (student.user_profile.lastName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // filtro per nome e cognome insieme
                (`${student.user_profile.firstName.toLowerCase()} ${student.user_profile.lastName.toLowerCase()}`.indexOf(this.state.searchValue.toLowerCase()) !== -1)
                ||
                // Filtro anche in base alla classe
                (student.user_classrooms.filter(
                    c => (c.class_name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                        (`classe ${c.class_name.toLowerCase()}`.indexOf(this.state.searchValue.toLowerCase()) !== -1)
                ).length > 0)
            );
        }
        this.setState({
            filteredStudents: filteredStudents
        });
    }

    getAllStudentsHTML = () => {
        let students = this.state.students;
        // Se il campo di ricerca è attivo allora mostro gli studenti filtrati
        if (this.state.searchValue !== '')
            students = this.state.filteredStudents;
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b>
                        <i>
                            {/* Classe {student.user_classrooms !== undefined ?
                                student.user_classrooms.length > 0 ? student.user_classrooms[0] : null
                                : 'non pervenuta'} */}
                        </i>
                    </p>
                    <span className='c-p' onClick={(event) => {
                        event.preventDefault();
                        this.addStudent(index)
                    }} >
                        <FontAwesomeIcon className='icon' icon={faPlus} />
                    </span>
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    {this.state.searchValue === '' ?
                        // Non sono presenti studenti
                        'Non ci sono studenti ...'
                        :
                        // Nessuno studente rispetta la ricerca
                        `Studente non trovato "${this.state.searchValue}"`
                    }
                </p>
                <span>
                    <FontAwesomeIcon className='icon text-warning' icon={faExclamationTriangle} />
                </span>
            </div>;

        return studentsHTML;
    }

    getSelectedStudentsHTML = () => {
        let students = this.state.selectedStudents;
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b>
                        <i>
                            {/* Classe {student..class_name} */}
                        </i>
                    </p>
                    <span className='c-p' onClick={(event) => {
                        event.preventDefault();
                        this.removeStudent(index)
                    }}>
                        <FontAwesomeIcon className='icon' icon={faTimes} />
                    </span>
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    Nessuno studente selezonato ...
                </p>
                <span />
            </div>;

        return studentsHTML;
    }

    sortStudents = (students) => {
        return students.sort((studA, studB) => {
            let a = studA.user_profile.lastName.toUpperCase();
            let b = studB.user_profile.lastName.toUpperCase();
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
    }

    addStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        if (this.state.searchValue !== '') {
            students = this.state.filteredStudents;
        }
        let student = students.splice(index, 1)[0];
        selectedStudents.push(student);
        this.setState({
            students: this.sortStudents(this.state.students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    removeStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        let student = selectedStudents.splice(index, 1)[0];
        students.push(student);
        this.setState({
            students: this.sortStudents(students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    updateClassroom = () => {
        let students = this.state.selectedStudents;
        let classUuid = this.props.classroom.class_uuid;
        let studentsUuids = [];
        students.map(s => studentsUuids.push(s.user_uuid));
        let query = '/classrooms/' + classUuid + '/members';
        let postBody = {
            class_members: studentsUuids,
        }
        this.setState({ loading: true })
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        postBody,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            // setto i flag is-current
            axios.all(studentsUuids.map((sUuid) => {
                query = '/classrooms/' + classUuid + '/members/' + sUuid + '/set-current';
                return axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                {},
                {
                    auth: holdDataService.getAuthorization()
                })
                .then((res) => {
                    return res.data;
                });
            }))
            .then(() => {
                this.setState({
                    loading: false
                }, () => {
                    this.props.toggleAddUser()
                    this.props.updateClassroom();
                })
            })
        }, err => {
            this.setState({
                loading: false
            })
        });
    }

    render() {
        return (
            this.state.classroom && this.state.classroom.class_name ?
                <Modal className='edubba-modal add-user-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='lg'
                    isOpen={this.props.modalAddUser}
                    toggle={this.props.toggleAddUser}
                    backdrop='static' >
                    <ModalHeader toggle={this.props.toggleAddUser}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon className='icon' icon={faUsers} />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Aggiunta Studenti: {this.state.classroom.class_name}
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            {this.state.loading ?
                                <EdubbaLoader small={true} />
                                : <Row className='classroom-row'>
                                    <Col lg='6' md='6' sm='6' className='text-center'>
                                        <div className='search-student mr-1 header'>
                                            <FontAwesomeIcon className='icon' icon={faSearch} />
                                            <input className=""
                                                name='searchValue'
                                                type="text"
                                                placeholder='Cerca Studenti ...'
                                                value={this.state.searchStudent}
                                                onChange={this.handleChange} />
                                        </div>
                                        <div className='student-content'>
                                            <PerfectScrollbar>
                                                {this.getAllStudentsHTML()}
                                            </PerfectScrollbar>
                                        </div>
                                    </Col>
                                    <Col lg='6' md='6' sm='6' className='text-center'>
                                        <div className='header'>
                                            STUDENTI SELEZIONATI
                                </div>
                                        <div className='student-content'>
                                            <PerfectScrollbar>
                                                {this.getSelectedStudentsHTML()}
                                            </PerfectScrollbar>
                                        </div>
                                    </Col>
                                </Row>}
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={(event) => {
                            event.preventDefault();
                            this.props.toggleAddUser();
                        }}>Annulla</Button>
                        <Button className='btn-edubba' onClick={() => this.updateClassroom()}>Salva Aggiunte</Button>
                    </ModalFooter>
                </Modal>
                : null
        );
    }
}

class CreateNewClassroomModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classroomName: '',
            classroomYear: '',
            searchValue: '',
            institutes: [],
            selectedInstitute: null,
            filteredStudents: [],

            students: [], //per mockare degli studenti
            selectedStudents: [],
            // studentsLoading: true,
            loading: false,
        };

    }

    componentDidMount() {
        let classroomYear  = moment(new Date().getTime()).format('YYYY');
        let classroomYear2 = moment(new Date().getTime()).add(1, "years").format('YYYY');
        classroomYear = classroomYear + "/" + classroomYear2;
        this.setState({
            classroomYear: classroomYear,
            institutes: this.props.institutes,
            selectedInstitute: (() => {
                // If only ONE, auto-select
                if (Array.isArray(this.props.institutes) &&
                    this.props.institutes.length === 1 &&
                    this.props.institutes[0].institute_uuid) {
                    return this.props.institutes[0].institute_uuid;
                }
                // Delegate user
                else {
                    return null;
                }
            })()
        }, () => {
            if (this.state.selectedInstitute) {
                this.loadStudents();
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        // Resetto lo stato della modale
        this.setState({
            classroomName: '',
            searchValue: '',
            filteredStudents: [],
            selectedInstitute: (() => {
                // If only ONE, auto-select
                if (Array.isArray(nextProps.institutes) &&
                    nextProps.institutes.length === 1 &&
                    nextProps.institutes[0].institute_uuid) {
                    return nextProps.institutes[0].institute_uuid;
                }
                // Delegate user
                else {
                    return null;
                }
            })(),
            institutes: nextProps.institutes,
            students: [], //per mockare degli studenti
            selectedStudents: [],
            // studentsLoading: false,
        }, () => {
            if (this.state.selectedInstitute) {
                this.loadStudents();
            }
        });
    }

    loadStudents = () => {
        if (this.state.selectedInstitute !== '' && typeof this.state.selectedInstitute === 'string') {
            this.setState({ loading: true })
            let query = "/institutes/" + this.state.selectedInstitute + "/users?userType=RBAC-RLCD-N0000003";
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                // console.log(res, query);
                let students = [];
                if (res.data.rowCount > 0) {
                    res.data.rows.map(s => {
                        if (s.user_classrooms.length > 0) {
                            if (!s.user_classrooms[0].class_is_current)
                                students.push(s);
                        } else students.push(s);
                        return true;
                    })
                }
                this.setState({
                    students: students,
                    loading: false,
                });
            }, err => {
                this.setState({
                    students: [],
                    loading: false,
                });
            });
        }
    }

    handleChangeInstitute = (event) => {
        this.setState({
            selectedInstitute: event.target.value,
        }, () => this.loadStudents());
    }

    handleChange = (event) => {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
        }, () => {
            if (name === 'searchValue')
                this.setFilteredStudents();
        })
    }

    setFilteredStudents = () => {
        let filteredStudents = [];

        if (this.state.students.length > 0) {
            filteredStudents = this.state.students.filter(student =>
                // Filtro per nome
                (student.user_profile.firstName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // Filtro per cognome della classe
                (student.user_profile.lastName.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) ||
                // filtro per nome e cognome insieme
                (`${student.user_profile.firstName.toLowerCase()} ${student.user_profile.lastName.toLowerCase()}`.indexOf(this.state.searchValue.toLowerCase()) !== -1)
                //  ||
                // Filtro anche in base alla classe
                // (student.user_classrooms[0].class_name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1)
                // || (`Classe ${student.user_classrooms[0].class_name}`.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1)
            );
        }
        this.setState({
            filteredStudents: filteredStudents
        });
    }

    getAllStudentsHTML = () => {
        let students = this.state.students;
        // Se il campo di ricerca è attivo allora mostro gli studenti filtrati
        if (this.state.searchValue !== '')
            students = this.state.filteredStudents;
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b> <i>
                            {/* Classe {
                            student.user_classrooms[0].class_name
                            } */}
                        </i>
                    </p>
                    <span className='c-p' onClick={(event) => {
                        event.preventDefault();
                        this.addStudent(index)
                    }} >
                        <FontAwesomeIcon className='icon' icon={faPlus} />
                    </span>
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    {this.state.searchValue === '' ?
                        // Non sono presenti studenti
                        'Non ci sono studenti ...'
                        :
                        // Nessuno studente rispetta la ricerca
                        `Studente non trovato "${this.state.searchValue}"`
                    }
                </p>
                <span>
                    <FontAwesomeIcon className='icon text-warning' icon={faExclamationTriangle} />
                </span>
            </div>;

        return studentsHTML;
    }

    getSelectedStudentsHTML = () => {
        let students = this.state.selectedStudents;
        let studentsHTML = [];
        students.map((student, index) => {
            studentsHTML.push(
                <div key={`student-row-${index}`} className='white-row student'>
                    <p id={`student-row-${index}`}>
                        <b>{student.user_profile.firstName} {student.user_profile.lastName}</b> <i>
                            {/* Classe {student.user_classrooms[0].class_name} */}
                        </i>
                    </p>
                    <span className='c-p' onClick={(event) => {
                        event.preventDefault();
                        this.removeStudent(index)
                    }}>
                        <FontAwesomeIcon className='icon' icon={faTimes} />
                    </span>
                </div>
            );
            return true;
        });
        if (studentsHTML.length === 0)
            return <div className='white-row student'>
                <p>
                    Nessuno studente selezonato ...
                </p>
                <span />
            </div>;

        return studentsHTML;
    }

    sortStudents = (students) => {
        return students.sort((studA, studB) => {
            let a = studA.user_profile.lastName.toUpperCase();
            let b = studB.user_profile.lastName.toUpperCase();
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
    }

    addStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        if (this.state.searchValue !== '') {
            students = this.state.filteredStudents;
        }
        let student = students.splice(index, 1)[0];
        selectedStudents.push(student);
        this.setState({
            students: this.sortStudents(students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    removeStudent = (index) => {
        let selectedStudents = this.state.selectedStudents;
        let students = this.state.students;
        let student = selectedStudents.splice(index, 1)[0];
        students.push(student);
        this.setState({
            students: this.sortStudents(students),
            selectedStudents: this.sortStudents(selectedStudents)
        })
    }

    createNewClassroom = () => {
        let students = this.state.selectedStudents;
        let classroomName = this.state.classroomName;
        let classroomYear = this.state.classroomYear;
        if (classroomName !== '' && classroomYear !== '') {
            let studentsUuids = [];
            students.map(s => studentsUuids.push(s.user_uuid))
            let query = '/classrooms';
            let postBody = {
                institute_uuid: this.state.selectedInstitute,
                class_name: classroomName,
                class_year: classroomYear,
            }
            this.props.toggleAddClassroom();
            this.props.creatingClassroom(true);
            this.setState({ loading: true })
            axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            postBody,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                if (res.data.rowCount > 0) {
                    if (students.length > 0) {
                        let classUuid = res.data.rows[0].class_uuid;
                        query = query + "/" + classUuid + "/members";
                        let newPostBody = {
                            class_members: studentsUuids
                        }
                        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                        newPostBody,
                        {
                            auth: holdDataService.getAuthorization()
                        })
                        .then(res => {
                            axios.all(studentsUuids.map((sUuid) => {
                                query = '/classrooms/' + classUuid + '/members/' + sUuid + '/set-current';
                                return axios.put(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                                {},
                                {
                                    auth: holdDataService.getAuthorization()
                                })
                                .then((res) => {
                                    // console.log('axios.all', res);
                                    return res.data;
                                });
                            }))
                            .then((list) => {
                                this.setState({
                                    loading: false
                                })
                                this.props.creatingClassroom(false);
                            })
                        }, err => {
                            this.setState({
                                loading: false
                            })
                        });
                    } else this.props.creatingClassroom(false);
                } else this.props.creatingClassroom(false);
            }, err => {
                this.setState({
                    loading: false
                })
            });
        }
    }

    render() {
        return (
            <Modal className='edubba-modal new-group-modal'
                centered
                aria-labelledby="contained-modal-title-vcenter"
                size='lg'
                isOpen={this.props.modalAddClassroom}
                toggle={(event) => {
                    event.preventDefault();
                    this.props.toggleAddClassroom(this.state.selectedInstitute)
                }}
                backdrop='static'
            >
                <ModalHeader toggle={(event) => {
                    event.preventDefault();
                    this.props.toggleAddClassroom(this.state.selectedInstitute)
                }}>
                    <div className='widget-content p-0'>
                        <div className='widget-content-wrapper'>
                            <div className='widget-content-left mr-3'>
                                <FontAwesomeIcon className='icon' icon={faChalkboardTeacher} />
                            </div>
                            <div className='widget-content-left mr-3 text-center w-100'>
                                Creazione Nuova Classe
                            </div>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {this.state.studentsLoading ?
                        <EdubbaLoader />
                        :
                        <Container>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-center color-edubba'>
                                    <div className='name'>
                                        SELEZIONA ISTITUTO DELLA CLASSE
                                    </div>
                                    <select
                                        className='legacy-form-control'
                                        name='instituteName'
                                        value={this.state.selectedInstitute ? this.state.selectedInstitute : ""}
                                        onChange={this.handleChangeInstitute}>
                                        <option key={"inst-0"} value="" disabled={true}>Selezionare un istituto...</option>
                                        {
                                            this.state.institutes.map((item, index) => {
                                                return (
                                                    <option key={"inst-" + index} value={item.institute_uuid}>{item.institute_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                            {
                                this.state.selectedInstitute ? (
                                    <Fragment>
                                        <Row>
                                            <Col lg='6' md='6' sm='6' className='text-center color-edubba'>
                                                <div className='name'>
                                                    NOME CLASSE
                                                </div>
                                                <input className="white-row"
                                                    name='classroomName'
                                                    type="text"
                                                    placeholder='Nome della Classe ...'
                                                    autoComplete='off'
                                                    value={this.state.classroomName}
                                                    onChange={this.handleChange} />
                                            </Col>
                                            <Col lg='6' md='6' sm='6' className='text-center color-edubba'>
                                                <div className='name'>
                                                    ANNO SCOLASTICO
                                                </div>
                                                <input className="white-row"
                                                    name='classroomYear'
                                                    type="text"
                                                    placeholder='Anno scolastico ...'
                                                    autoComplete='off'
                                                    value={this.state.classroomYear}
                                                    onChange={this.handleChange} />
                                            </Col>
                                        </Row>
                                        <Row className='classroom-row'>
                                            <Col lg='6' md='6' sm='6' className='text-center'>
                                                <div className='search-student mr-1 header'>
                                                    <FontAwesomeIcon className='icon' icon={faSearch} />
                                                    <input className="white-row"
                                                        name='searchValue'
                                                        type="text"
                                                        placeholder='Cerca Studenti ...'
                                                        value={this.state.searchValue}
                                                        onChange={this.handleChange} />
                                                </div>
                                                <div className='student-content'>
                                                    {this.state.loading ?
                                                        <EdubbaLoader small={true} /> :
                                                        <PerfectScrollbar>
                                                            {this.getAllStudentsHTML()}
                                                        </PerfectScrollbar>}
                                                </div>
                                            </Col>
                                            <Col lg='6' md='6' sm='6' className='text-center'>
                                                <div className='header'>
                                                    STUDENTI NELLA CLASSE
                                                </div>
                                                <div className='student-content'>
                                                    <PerfectScrollbar>
                                                        {this.getSelectedStudentsHTML()}
                                                    </PerfectScrollbar>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ) : null
                            }
                        </Container>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-edubba' onClick={(event) => {
                        event.preventDefault();
                        this.props.toggleAddClassroom(this.state.selectedInstitute)
                    }}>Annulla</Button>
                    <Button className='btn-edubba' onClick={this.createNewClassroom}>Crea Classe</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withRouter(Classrooms);
