import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link';
import holdDataService from '../../services/holdDataService/holdDataService';
import axios from 'axios';
import globalVars from './../../globalVars.json';

class Nav extends Component {
    // -----------------------------------------------------------------------| CLASS CONSTRUCTOR

    constructor(props) {
        super(props);
        // projects: Array

        this.state = {
            lastContentUpdate: 0,
            sideBarElements:   this.getUserSideBar(),
            supportProjects:   [],
        }
    }

    // -----------------------------------------------------------------------| REACT LIFECYCLE

    componentDidMount() {
        this.loadProjects();
        document.addEventListener('refresh-projects-list', this.loadProjects);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.supportProjects !== nextProps.projects) {
            this.setState({
                supportProjects: nextProps.projects
            },
            () => {
                if (this.state.supportProjects.length > 0) {
                    this.expandProjects();
                }
            });
        }
    }

    // -----------------------------------------------------------------------| MENU' STRUCTURE GENERATOR

    getUserSideBar = () => {
        return [
            // ---------------------------------------------------------------| DASHBOARD (ROOT)
            ...(holdDataService.canOpenRoute("root_dashboard") ? [{
                code:  'root_dashboard',
                icon:  'pe-7s-home',
                label: 'Dashboard',
                to: '/',
            }] : []),
            // ---------------------------------------------------------------| THIRD-PARTIES (ROOT)
            ...(holdDataService.canOpenRoute("root_content_admin_tparties") ? [{
                code:  'root_content_admin_tparties',
                icon:  'pe-7s-display2',
                label: 'Contenuti Terze-Parti',
                content: [
                    // -------------------------------------------------------| EXT MATERIALS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_content_admin_materials") ? [{
                        label: 'Materiale Pubblico',
                        to: '/third-parties/materials'
                    }] : [])
                ]
            }] : []),
            // ---------------------------------------------------------------| BACKOFFICE (ROOT)
            ...(holdDataService.canOpenRoute("root_admin_dashboard") ? [{
                code:  'root_admin_dashboard',
                icon:  'pe-7s-tools',
                label: 'Tools Amministrativi',
                content: [
                    // -------------------------------------------------------| EXT MATERIALS (ROOT)
                    /*...(holdDataService.canOpenRoute("sub_admin_backoffice") ? [{
                        label: 'Gestione del Database',
                        to: '/admin/entities'
                    }] : []),*/
                    // -------------------------------------------------------| EXT MATERIALS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_admin_stats_sysd") ? [{
                        label: 'Statistiche Sistema',
                        to: '/admin/stats/sysd'
                    }] : []),
                    // -------------------------------------------------------| EXT MATERIALS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_admin_stats_workd") ? [{
                        label: 'Statistiche Attività',
                        to: '/admin/stats/workd'
                    }] : []),
                    // -------------------------------------------------------| EXT MATERIALS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_admin_stats_users") ? [{
                        label: 'Statistiche Utenti',
                        to: '/admin/stats/users'
                    }] : []),
                    // -------------------------------------------------------| EXT MATERIALS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_admin_stats_endpoints") ? [{
                        label: 'Statistiche Endpoints',
                        to: '/admin/stats/endpoints'
                    }] : []),
                    // -------------------------------------------------------| EXT MATERIALS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_admin_materials") ? [{
                        label: 'Materiale Pubblico',
                        to: '/third-parties/materials'
                    }] : [])
                ]
            }] : []),
            // ---------------------------------------------------------------| USERS (ROOT)
            ...(holdDataService.canOpenRoute("root_users") ? [{
                code:  'root_users',
                icon:  'pe-7s-users',
                label: 'Anagrafica',
                content: [
                    // -------------------------------------------------------| STUDENTS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_users_students") ? [{
                        label: 'Studenti',
                        to: '/students',
                    }] : []),
                    // -------------------------------------------------------| CLASSROOMS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_users_classes") ? [{
                        label: 'Classi',
                        to: '/classrooms',
                    }] : []),
                    // -------------------------------------------------------| GROUPS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_users_groups") ? [{
                        label: 'Gruppi',
                        to: '/groups',
                    }] : []),
                ]
            }] : []),
            // ---------------------------------------------------------------| PROJECTS (ROOT)
            ...(holdDataService.canOpenRoute("root_projects") ? [{
                code:  'root_projects',
                icon:  'pe-7s-note2',
                label: 'Progetti',
                to: '/'
            }] : []),
            // ---------------------------------------------------------------| TASKS (ROOT)
            ...(holdDataService.canOpenRoute("root_tasks") ? [{
                code:  'root_tasks',
                icon:  'pe-7s-note',
                label: 'Compiti',
                content: [
                    // -------------------------------------------------------| MY TASKS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_tasks_in_progress") ? [{
                        label: 'Compiti in Corso',
                        to: '/tasks/todo',
                    }] : []),
                    // -------------------------------------------------------| MY TASKS COMPLETED (ROOT)
                    ...(holdDataService.canOpenRoute("sub_tasks_to_evaluate") ? [{
                        label: 'Compiti da Valutare',
                        to: '/tasks/evaluate',
                    }] : []),
                    // -------------------------------------------------------| MY TASKS COMPLETED (ROOT)
                    ...(holdDataService.canOpenRoute("sub_tasks_completed") ? [{
                        label: 'Compiti Completati',
                        to: '/tasks/completed',
                    }] : []),
                    // -------------------------------------------------------| TASKS TO BE CERTIFIED (ROOT)
                    ...(holdDataService.canOpenRoute("sub_tasks_to_be_certified") ? [{
                        label: 'Compiti da Certificare',
                        to: '/tasks/tocertified',
                    }] : []),
                    // -------------------------------------------------------| CERTIFIED TASKS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_tasks_certified") ? [{
                        label: 'Compiti Certificati',
                        to: '/tasks/certified',
                    }] : []),
                    // -------------------------------------------------------| EXPORT
                    /*...(holdDataService.canOpenRoute("sub_task_exports") ? [{
                        label: 'Compiti Esportati',
                        to: '/exports/tasks',
                    }] : [])*/
                ]
            }] : []),
            // ---------------------------------------------------------------| CARDS (ROOT)
            ...(holdDataService.canOpenRoute("root_cards") ? [{
                code:  'root_cards',
                icon:  'pe-7s-albums',
                label: 'Area Schede',
                content: [
                    // -------------------------------------------------------| MY CARDS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_cards_personal") ? [{
                        label: 'Le Mie Schede',
                        to: '/cards',
                    }] : []),
                    // -------------------------------------------------------| EXPORT
                    /*...(holdDataService.canOpenRoute("sub_card_exports") ? [{
                        label: 'Schede Esportate',
                        to: '/exports/cards',
                    }] : []),*/
                    // -------------------------------------------------------| ALL CARDS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_cards_catalog") ? [{
                        label: 'Cataloghi Esterni',
                        to: '/third-catalogs',
                    }] : []),
                    // -------------------------------------------------------| CREATE CARDS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_cards_create") ? [{
                        label: 'Nuova Scheda',
                        to: '/new_card',
                    }] : [])
                ]
            }] : []),
            // ---------------------------------------------------------------| TOURS (ROOT)
            ...(holdDataService.canOpenRoute("root_tours") ? [{
                code:  'root_tours',
                icon:  'pe-7s-map-marker',
                label: 'Area Percorsi',
                content: [
                    // -------------------------------------------------------| MY TOURS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_tours_personal") ? [{
                        label: 'I Miei Percorsi',
                        to: '/tours',
                    }] : []),
                    // -------------------------------------------------------| EXPORT
                    /*...(holdDataService.canOpenRoute("sub_tour_exports") ? [{
                        label: 'Percorsi Esportati',
                        to: '/exports/tours',
                    }] : []),*/
                    // -------------------------------------------------------| CREATE TOURS (ROOT)
                    ...(holdDataService.canOpenRoute("sub_tours_create") ? [{
                        label: 'Nuovo Percorso',
                        to: '/edit_tour',
                    }] : [])
                ]
            }] : []),
            // ---------------------------------------------------------------| BACKOFFICE (ROOT)
            ...(holdDataService.canOpenRoute("root_visibilities") ? [{
                code:  'root_visibilities',
                icon:  'pe-7s-news-paper',
                label: 'Maschere di Visibilità',
                to: '/visibilities'
            }] : []),
            // ---------------------------------------------------------------| SOCIALS (ROOT)
            /*...(holdDataService.canOpenRoute("root_socials") ? [{
                code:  'root_socials',
                icon:  'pe-7s-global',
                label: 'Social',
                content: [
                    // -------------------------------------------------------| FORUM (ROOT)
                    ...(holdDataService.canOpenRoute("sub_socials_forum") ? [{
                        label: 'Forum',
                        to: '/forum',
                    }] : []),
                    // -------------------------------------------------------| BLOG (ROOT)
                    ...(holdDataService.canOpenRoute("sub_socials_blog") ? [{
                        label: 'Blog',
                        to: '/blog',
                    }] : [])
                ]
            }] : []),*/
            // ---------------------------------------------------------------| SETTINGS (ROOT)
            /*...(holdDataService.canOpenRoute("root_settings") ? [{
                code:  'root_settings',
                icon:  'pe-7s-settings',
                label: 'Impostazioni',
                to: '/settings',
            }] : []),*/
            // ---------------------------------------------------------------| DASHBOARD (ROOT)
            ...(holdDataService.canOpenRoute("root_users_third_parties_materials") ? [{
                code:  'root_users_third_parties_materials',
                icon:  'pe-7s-display2',
                label: 'Contenuti Databenc',
                to: '/third-parties/materials',
            }] : []),
            // ---------------------------------------------------------------| SETTINGS (ROOT)
            ...(holdDataService.canOpenRoute("root_news") ? [{
                code:  'root_news',
                icon:  'pe-7s-mail-open-file',
                label: 'Avvisi e News',
                to: '/news',
            }] : []),
            // ---------------------------------------------------------------| SETTINGS (ROOT)
            ...(holdDataService.canOpenRoute("root_policies") ? [{
                code:  'root_policies',
                icon:  'pe-7s-info',
                label: 'Disclaimer',
                to: '/disclaimer',
            }] : []),
            // ---------------------------------------------------------------| METRICS (ROOT)
            ...(!holdDataService.loggedUserIsOnlySupervisor() &&
                holdDataService.canOpenRoute("root_supervisor_dashboard") ? [{
                code:  'root_supervisor_dashboard',
                icon:  'pe-7s-display1',
                label: 'Statistiche Piattaforma',
                to: '/admin'
            }] : []),
        ];
    }

    // -----------------------------------------------------------------------| LOADING: PROJECTS

    loadProjects = () => {
        if (holdDataService.loggedUserIsAdmin()        ||
            holdDataService.loggedUserIsContentAdmin() ||
            holdDataService.loggedUserIsOnlySupervisor() ||
            holdDataService.getLoggedUserUuid() === null) {
            return;
        }

        this.setState({ loading: true }, () => {
            let query = '/projects';
            axios.get(
                globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                { auth: holdDataService.getAuthorization()}
            )
            .then(
                res => {
                    let __projects = [];

                    if (res.data.rowCount > 0) {
                        __projects = res.data.rows
                    }

                    this.setState({
                        supportProjects: __projects,
                        loading: false
                    },
                    () => this.expandProjects());
                },
                err => {
                    this.setState({ loading: false });
                }
            );
        });
    }

    expandProjects = () => {
        let __projectsList    = [];
        let __sideBarElements = this.state.sideBarElements;
        let __indexOfProjects = -1;
        let __bodyOfProjects  = null;

        this.state.supportProjects.map(p => {
            let __currentProject = {};
            __currentProject.label = p.project_name;
            if (Array.isArray(p.project_assignments) && p.project_assignments.length > 0) {
                let __assignmentList = [];
                p.project_assignments.map(a => {
                    __assignmentList.push({
                        label: a.assign_descr,
                        to: '/assignments/' + a.assign_uuid
                    });
                    return false;
                });
                __currentProject.content = __assignmentList;
            }
            else {
                __currentProject.to = "/#";
            }
            __projectsList.push(__currentProject);
            return false;
        });

        __sideBarElements.map((elem, index) => {
            if (elem.code === "root_projects") {
                __indexOfProjects = index;
                __bodyOfProjects  = elem;
            }
            return false;
        });

        if (__bodyOfProjects         &&
            __indexOfProjects !== -1 &&
            __projectsList.length > 0) {
            // Remove trick for empty array
            delete __bodyOfProjects["to"];

            // Inject projects
            __bodyOfProjects["content"] = [
                ...[
                    {
                        label:   'I Miei Progetti',
                        content: __projectsList
                    }
                ],
                /*...(holdDataService.canOpenRoute("sub_project_exports") ? [
                    {
                        label: 'Progetti Esportati',
                        to:    '/exports/projects'
                    }
                ] : [])*/
            ];

            // Replace element on sidebar
            __sideBarElements[__indexOfProjects] = __bodyOfProjects;
        }

        this.setState({
            sideBarElements:   __sideBarElements,
            lastContentUpdate: Date.now()
        });
    }

    // -----------------------------------------------------------------------| REACT RENDER

    render() {
        return (
            // Force update of MetisMenù
            <Fragment key={"sidebar_at_time_" + this.state.lastContentUpdate}>
                <h5 className="app-sidebar__heading">MENU</h5>
                <MetisMenu
                    content={this.state.sideBarElements}
                    LinkComponent={RouterLink}
                    className="vertical-nav-menu"
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    activeLinkFromLocation
                />
            </Fragment>
        );
    }
}

export default withRouter(Nav);
