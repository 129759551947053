import React, { Component } from 'react';
import globalVars from './../../globalVars.json';
import FormData from 'form-data';
import axios from 'axios';
import { BarLoader } from "react-spinners";
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'reactstrap/lib/Button';
import { faTimes, faCloudUploadAlt, faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import holdDataService from './../holdDataService/holdDataService';

import previewPdf from './../../assets/utils/images/file-types-preview/file-pdf.png';
import previewAudio from './../../assets/utils/images/file-types-preview/file-audio.png';
import previewVideo from './../../assets/utils/images/file-types-preview/file-video.png';
import previewDoc from './../../assets/utils/images/file-types-preview/file-document.png';
import previewImage from './../../assets/utils/images/file-types-preview/file-image.png';

import './ImageUploadManager.css'

class ImageUploadManager extends Component {
    constructor(props) {
        super(props);
        /*
         *  PARAMETERS:
         * -------------------------------
         * selectCallback: function => (data-url)
         */

        this.state = {
            // Modal ctrls
            showWarning: false,

            // Preview
            imageUrl: "",

            // Upload files
            filesToUpload: [],
            imageUrls: [],
            uploaded: false,
            uploaded_msg: "",
            uploaded_color: "",
            uploading: false,
            dowloading: false,

            // My files
            imgsList: [],

            // Focus
            selectedItemGUID: null,
            selectedTabContent: "manage"
        };

        // Preview file by types
        this.imageExtensions    = ["jpeg", "jpg", "gif", "png", "bmp"];
        this.audioExtensions    = ["mp3", "wav", "ogg"];
        this.videoExtensions    = ["avi", "mkv", "mp4", "mpeg", "3gp", "wmv"];
        this.documentExtensions = ["rtf", "txt", "doc", "docx", "ppt", "pptx", "xls", "xlsx", "odt", "ods", "odp", "xml"];
        this.pdfExtensions      = ["pdf"];

        // Static file permissions
        this.permittedExtensions = [
            ...this.imageExtensions,
            ...this.audioExtensions,
            ...this.videoExtensions,
            ...this.documentExtensions,
            ...this.pdfExtensions
        ];

        // Ctrl modal state
        this.toggleTabAction = this.toggleTabAction.bind(this);

        // Upload
        this.getImagesFromObjectStorage = this.getImagesFromObjectStorage.bind(this);
        this.uploadImage = this.uploadImage.bind(this);

        // Preview
        this.imgPreview = this.imgPreview.bind(this);
        this.previewOnDropFile = this.previewOnDropFile.bind(this);

        // Delete
        this.deleteSelectedItem = this.deleteSelectedItem.bind(this);

        // Update
        this.updateSelectedItem = this.updateSelectedItem.bind(this);

        // Focus item
        this.setFocusOnItem = this.setFocusOnItem.bind(this);

        // Callback
        this.sendToCallback = this.sendToCallback.bind(this);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE MOUNT
    ///////////////////////////////////////////////////////////////////////////////////////////////

    componentWillMount() {
        this.getImagesFromObjectStorage();
    }

    componentDidMount() {
        [
            'drag',
            'dragstart',
            'dragend',
            'dragover',
            'dragenter',
            'dragleave',
            'drop'
        ].forEach((event) => {
            document.addEventListener(event, function (e) {
                // preventing the unwanted behaviours
                e.preventDefault();
                e.stopPropagation();
            });
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE TOGGLE
    ///////////////////////////////////////////////////////////////////////////////////////////////

    toggleTabAction(type) {
        switch (type) {
            case "upload":
                break;

            case "manage":
            default:
                break;
        }

        this.setState({
            selectedTabContent: type
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE DOWNLOAD
    ///////////////////////////////////////////////////////////////////////////////////////////////

    setFocusOnItem(event, imgObj, guid) {
        if (this.state.selectedItemGUID === null || (
            this.state.selectedItemGUID &&
            this.state.selectedItemGUID.guid !== guid)) {
            this.setState({ selectedItemGUID: imgObj });
        }
        else {
            this.setState({ selectedItemGUID: null });
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE DOWNLOAD
    ///////////////////////////////////////////////////////////////////////////////////////////////

    getImagesFromObjectStorage() {
        //this.setState({ dowloading: true });
        axios.get(
            globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/media',
            {
                auth: holdDataService.getAuthorization()
            }
        )
            .then(
                res => {
                    let data = res.data.rows;
                    Promise.all(
                        data.map((elem, index) => {
                            return axios.get(
                                globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/media/' + elem.media_uuid,
                                {
                                    auth: holdDataService.getAuthorization()
                                }
                            )
                                .then(
                                    resSub => {
                                        let type = resSub.data.rows[0]["media_data"]["name"].split('.')[1];
                                        return {
                                            "index": index,
                                            "guid": elem.media_uuid,
                                            "type": type,
                                            "name": resSub.data.rows[0]["media_data"]["name"],
                                            // "desc": resSub.data.rows[0]["image-desc"],
                                            "desc": '',
                                            "private": resSub.data.rows[0]["media_is_public"],
                                            "orphan": true,
                                        };
                                    }
                                );
                        })
                    )
                        .then((newImgs) => {
                            this.setState({
                                imgsList: newImgs,
                                dowloading: false
                            });
                        });
                },
                err => {
                    this.setState({
                        imgsList: [],
                        dowloading: false
                    });
                }
            );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE UPLOAD
    ///////////////////////////////////////////////////////////////////////////////////////////////

    previewOnDropFile = (ev) => {
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    this.imgPreview(ev, i)
                }
            }
        }
        else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                this.imgPreview(ev, i)
            }
        }

        // clean
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to remove the drag data
            ev.dataTransfer.items.clear();
        }
        else {
            // Use DataTransfer interface to remove the drag data
            ev.dataTransfer.clearData();
        }
    };

    uploadImage() {
        let formData = new FormData();
        this.setState({
            uploading: true,
            selectedItemGUID: null
        });
        this.state.filesToUpload.map(
            (obj, index) => formData.append('edubba_file_' + index, obj.file)
        );

        axios.post(
            globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/media',
            formData,
            {
                auth: holdDataService.getAuthorization()
            }
        ).then(
            res => {
                const uploadedOk    = (res && res.data && res.data.rowCount > 0);
                const uploadMessage = (res && res.data && res.data.message) ? res.data.message : "Impossibile completare l'upload!";

                this.setState({
                    uploading: false,
                    uploaded: true,
                    uploaded_color: uploadedOk ? "green" : "red",
                    uploaded_msg: uploadedOk ? "Upload effettuato con successo!" : uploadMessage
                });

                setTimeout(() => {
                    // clear queue
                    this.setState(
                        {
                            uploaded: false,
                            filesToUpload: [],
                            imageUrls: []
                        },
                        () => {
                            this.getImagesFromObjectStorage();
                            this.toggleTabAction("manage");
                        }
                    );
                }, 3000);
            },
            err => {
                console.log('Error', err)

                this.setState({
                    uploading: false,
                    uploaded: true,
                    uploaded_color: "red",
                    uploaded_msg: err.message
                });

                setTimeout(() => {
                    // clear queue
                    this.setState(
                        {
                            uploaded: false,
                            filesToUpload: [],
                            imageUrls: []
                        },
                        () => {
                            this.getImagesFromObjectStorage();
                            this.toggleTabAction("manage");
                        }
                    );
                }, 3000);
            }
        );
    }

    cancelUpload = (id) => {
        let tmpSelectedImages = this.state.imageUrls;
        let tmpFilesToUpload = this.state.filesToUpload;

        let index = this.state.imageUrls.map(function (x) {
            return x.id;
        }).indexOf(id);

        if (index !== -1) {
            tmpSelectedImages.splice(index, 1);
        }

        index = this.state.filesToUpload.map(function (x) {
            return x.id;
        }).indexOf(id);

        if (index !== -1) {
            tmpFilesToUpload.splice(index, 1);
        }

        this.setState({ imageUrls: tmpSelectedImages, filesToUpload: tmpFilesToUpload });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE DELETE
    ///////////////////////////////////////////////////////////////////////////////////////////////

    deleteSelectedItem = () => {
        if (this.state.selectedItemGUID &&
            this.state.selectedItemGUID.guid) {
            this.setState({
                dowloading: true
            });
            axios.delete(
                globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/media-srv/remove/' + this.state.selectedItemGUID.guid,
                {
                    auth: holdDataService.getAuthorization()
                }
            ).then(
                res => {
                    setTimeout(() => {
                        // clear queue
                        this.setState(
                            {
                                dowloading: false,
                                selectedItemGUID: null
                            },
                            () => {
                                this.getImagesFromObjectStorage();
                            }
                        );
                    }, 1000);
                },
                err => {
                    this.setState({ dowloading: false });
                }
            );
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE UPDATE
    ///////////////////////////////////////////////////////////////////////////////////////////////

    updateSelectedItem = () => {
        if (this.state.selectedItemGUID &&
            this.state.selectedItemGUID.guid) {
            let jsonBody = {
                "image-name": $("#input-media-title").val().trim(),
                "image-desc": $("#input-media-desc").val().trim(),
            };
            this.setState({
                dowloading: true
            });
            axios.put(
                globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/media-srv/update/' + this.state.selectedItemGUID.guid,
                jsonBody,
                {
                    auth: holdDataService.getAuthorization()
                }
            ).then(
                res => {
                    setTimeout(() => {
                        // clear queue
                        this.setState(
                            {
                                dowloading: false,
                                selectedItemGUID: null
                            },
                            () => {
                                this.getImagesFromObjectStorage();
                            }
                        );
                    }, 1000);
                },
                err => {
                    this.setState({ dowloading: false });
                }
            );
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE PREVIEW
    ///////////////////////////////////////////////////////////////////////////////////////////////

    imgPreview(ev, index) {
        ev.preventDefault();

        const file = this.uploadInput.files[0] || (ev.dataTransfer ? ev.dataTransfer.items[index].getAsFile() : null);
        if (!file) {
            return;
        }

        const fileExt = file.name.split('.').pop();

        if (!this.permittedExtensions.includes(fileExt.toLowerCase())) {
            this.setState({ showWarning: true });
            return false;
        }
        else {
            this.setState({ showWarning: false });
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                imageUrls: this.state.imageUrls.concat({
                    imgSrc: reader.result,
                    extSrc: fileExt,
                    id: (this.state.imageUrls.length + 1)
                }),
                filesToUpload: this.state.filesToUpload.concat({
                    id: (this.state.filesToUpload.length + 1),
                    file: file
                })
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
        else {
            this.setState({
                imageUrl: ""
            })
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE PARTIAL-RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////

    getTabContentFromFocus() {
        switch (this.state.selectedTabContent) {
            case "upload":
                return (
                    this.state.uploaded === true ?
                        <div className="padding">
                            <div className="upload-progress">
                                <FontAwesomeIcon icon={
                                    this.state.uploaded_color === "green" ? faCheck : faExclamationTriangle
                                } className="fa-3x" style={{ 'color': this.state.uploaded_color }} />
                                <br />
                                <span style={{ 'color': this.state.uploaded_color }}>{this.state.uploaded_msg}</span>
                            </div>
                        </div>
                        : (this.state.uploaded !== true && this.state.uploading === true) ?
                            <div className="padding">
                                <div className="upload-progress">
                                    Upload materiale in corso...<br />
                                    <BarLoader
                                        heigth={8}
                                        width={200}
                                        color={'#083d76'}
                                        loading={true}
                                    />
                                </div>
                            </div>
                            :
                            <div className="padding">
                                <div id="dropArea" className="tab-pane container" onDrop={this.previewOnDropFile}>
                                    <div className="vertical_align row mb-3">
                                        {this.state.imageUrls.length > 0 ?
                                            this.state.imageUrls.map((img, index) => {
                                                return (
                                                    <div key={index} className="preview_input">
                                                        <span onClick={() => this.cancelUpload(img.id)}>
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </span>
                                                        <img onError={(evt) => {
                                                            evt.target.src = previewImage;
                                                        }} className="upload_image_preview" src={
                                                            this.documentExtensions.includes(img.extSrc) ? previewDoc :
                                                            this.audioExtensions.includes(img.extSrc) ? previewAudio :
                                                            this.pdfExtensions.includes(img.extSrc) ? previewPdf :
                                                            this.videoExtensions.includes(img.extSrc) ? previewVideo :
                                                            img.imgSrc
                                                        }
                                                        alt="Anteprima immagine" />
                                                    </div>
                                                );
                                            })
                                            : null
                                        }
                                    </div>
                                    <div className="vertical_align row">
                                        <div className="col-lg-12 text-center">
                                            <div className="d-block">
                                                <FontAwesomeIcon icon={faCloudUploadAlt} />
                                            </div>
                                            Trascina il materiale qui o premi sul pulsante per avviarne l'upload!
                                            <form className="input_form" onSubmit={this.uploadImage}>
                                                <input id="input_file" className="input_file" ref={(ref) => {
                                                    this.uploadInput = ref;
                                                }} type="file" accept="*" onChange={this.imgPreview} />
                                                <Button onClick={() => {this.uploadInput.click()}} style={{ cursor: "pointer" }} role="button"
                                                    className="btn pointer long-text-btn btn-edubba-four">
                                                    Scegli il materiale da caricare
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="vertical_align row">
                                        <div className="col-lg-12 text-center">
                                            <i>L'utente non deve (e non può altresì consentire a soggetti terzi) intraprendere condotte dirette a caricare, distribuire o agevolare la distribuzione di qualsiasi contenuto, attraverso il Servizio, che comporti la violazione di copyright o altro diritto che violi obblighi contrattuali di erogazione!</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                );

            case "manage":
            default:
                return (
                    this.state.dowloading === true ?
                        <div className="padding">
                            <div className="upload-progress">
                                Aggiornamento media in corso...<br />
                                <BarLoader
                                    heigth={8}
                                    width={200}
                                    color={'#083d76'}
                                    loading={true}
                                />
                            </div>
                        </div>
                        :
                        <div id="browse" className="tab-pane container active">
                            {
                                this.state.imgsList.length > 0 ?
                                    <div className="row">
                                        <div className="image_list col-sm-12 media-tab">
                                            {
                                                this.state.imgsList.map((imgObj, index) => {
                                                    return (
                                                        <div id={index} key={index} ref={imgObj.guid}
                                                            onClick={(ev) => this.setFocusOnItem(ev, imgObj, imgObj.guid)}
                                                            className={"img_div float-left " + (this.state.selectedItemGUID && this.state.selectedItemGUID.guid === imgObj.guid ? "selected_img" : "")}>
                                                            <img className="img_list_item" src={
                                                                this.documentExtensions.includes(imgObj.type) ? previewDoc :
                                                                this.audioExtensions.includes(imgObj.type) ? previewAudio :
                                                                this.pdfExtensions.includes(imgObj.type) ? previewPdf :
                                                                this.videoExtensions.includes(imgObj.type) ? previewVideo :
                                                                (
                                                                    globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/downloads/' + imgObj.guid
                                                                )
                                                            } alt="img_list_item" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                      {/*  <div className="col-sm-5 action_tab float-right details-tab">
                                            {
                                                this.state.selectedItemGUID !== null &&
                                                    this.state.selectedItemGUID !== undefined ?
                                                    <div>
                                                        <div className="detail-title">Nome Media:</div>
                                                        <div className="detail-value">
                                                            <input type="text" id="input-media-title"
                                                                defaultValue={this.state.selectedItemGUID.name}
                                                                placeholder="Inserire un nome..." />
                                                        </div>
                                                        <div className="detail-title">Descrizione:</div>
                                                        <div className="detail-value">
                                                            <input type="text" id="input-media-desc"
                                                                defaultValue={this.state.selectedItemGUID.desc}
                                                                placeholder="Inserire un breve testo..." />
                                                        </div>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <span
                                                                className="modify-item disabled"
                                                                //onClick={this.updateSelectedItem}>
                                                                >
                                                                Salva le modifiche
                                                    </span>
                                                            <br />
                                                            <span
                                                                className="delete-item disabled"
                                                                // onClick={this.deleteSelectedItem}>
                                                                >
                                                                Elimina definitivamente
                                                    </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <label>Seleziona un media a lato per visualizzare i
                                                            dettagli...</label>
                                                    </div>
                                            }
                                        </div>*/}
                                        </div>
                                    :
                                    <div className="row">
                                        <div style={{ textAlign: "center" }} className="image_list col-12 mt-5 mb-5">
                                            <i>Non hai ancora aggiunto materiale da gestire.</i>
                                        </div>
                                    </div>
                            }
                        </div>
                );
        }
    }

    getTabFooterFromFocus() {
        switch (this.state.selectedTabContent) {
            case "upload":
                return (
                    <Button disabled={this.state.uploading || this.state.filesToUpload.length === 0}
                        onClick={this.uploadImage}
                        className="btn pointer float-right mt-2 btn-edubba">
                        Carica Materiale
                    </Button>
                );

            case "manage":
            default:
                return (
                    <Button disabled={this.props.selectCallback && this.state.selectedItemGUID ? false : true}
                        className="btn pointer float-right mt-2 btn-edubba"
                        onClick={this.sendToCallback}>
                        Seleziona Materiale
                    </Button>
                );
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE CALLBACKS
    ///////////////////////////////////////////////////////////////////////////////////////////////

    sendToCallback() {
        if (this.props.selectCallback) {
            if (this.state.selectedItemGUID &&
                this.state.selectedItemGUID.guid) {
                this.props.selectCallback({
                    "fileUrl": (
                        globalVars.Protocol + '://' + globalVars.BEHost + ':' + globalVars.BEPort + '/downloads/' + this.state.selectedItemGUID.guid
                    ),
                    "fileType": this.state.selectedItemGUID.type,
                    "fileGuid": this.state.selectedItemGUID.guid
                });
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //  GESTIONE RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div className="ImageUploadManager">
                <div id="image_upload_container" className="image_upload_container">
                    <div id="header_menu">
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <div
                                    className={"nav-link " + (this.state.selectedTabContent === "upload" ? "active" : "")}
                                    onClick={() => this.toggleTabAction("upload")}>
                                    Carica Nuovo
                                </div>
                            </li>
                            <li className="nav-item">
                                <div
                                    className={"nav-link " + (this.state.selectedTabContent === "manage" ? "active" : "")}
                                    onClick={() => this.toggleTabAction("manage")}>
                                    I Miei Media
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        {this.getTabContentFromFocus()}
                    </div>
                    <div className="footer_carica_action">
                        {this.getTabFooterFromFocus()}
                    </div>
                </div>
            </div>
        );
    }
};

export default ImageUploadManager;
