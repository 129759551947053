import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import {
    Nav, NavItem, NavLink,
    TabContent,
    Button, Container, Collapse,
    Modal, ModalHeader, ModalBody,
    Row, Col
} from 'reactstrap';
import {
    faQuestion,
    faCheck,
    faMinus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import holdDataService from '../../services/holdDataService/holdDataService';

import SelectTemplateModal from './../Modals/SelectTemplateModal';

import axios from 'axios';

import globalVars from './../../globalVars.json';

import './CardTemplate.scss';
//import EdubbaLoader from '../../components/EdubbaLoader/EdubbaLoader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import JSTemplateManager from '../../services/templateManager/JSTemplateManager';
import ModalFooter from 'reactstrap/lib/ModalFooter';

const ctxTemplateManager = new JSTemplateManager((block, masked, origin) => {
    return {
        "keepNode": masked["field-is-enrichment"]
    };
});

class CardTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            core: {},
            tabHeaders: [],
            activeTab: '',

            flows: [],

            modalAnnulla: false,

            customTemplate: [],

            modalSelectFields: false,

            selectFieldsMode: false,

            // Creazione scheda
            selectedFlow: '27d9f99e-3133-419a-8c20-a75c74406a85',
            // Enrichment
            //selectedFlow: '5847606e-2787-43fa-b616-c9f35c8149b6',
            formData: {},

            showEnrichmentPhaseModal: false,
            assign_uuid: props.match.params.assign_uuid,
            templateCreationStack: [],
            iccdFilter: "",
            customVisibility: null,
            templateToRender: null,
            headersToRender: [],
            htmlToRender: null,
            promptICCDPhaseModal: false,
            selectICCDModal: false,
            showRecapModal: false,
            recapFilter: "",
        };

        this.resetState = this.resetState.bind(this);
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'CardTemplate';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';
        this.props.setSectionTitle('none');

        // Check data structure: catalog
        if (this.props.location.state &&
            this.props.location.state.tpl_uuid) {
            // Force selection
            this.selectTemplates(
                [this.props.location.state.tpl_uuid]
            )
        }
        // Otherwise...
        else this.setState({
            modalSelectTemplate: true
        });
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    onTemplateCreation = () => {
        let newTemplateCreationStack = this.state.templateCreationStack.slice(0);
        newTemplateCreationStack.splice(0, 1);
        if (newTemplateCreationStack.length === 0) {
            // FROM CREATE CARD
            if (this.props.match.params.continue_work === "create") {
                // Get data if exists
                const catalogData = (
                    this.props.location.state ?
                    this.props.location.state :
                    {}
                );
                // Send back all data
                this.props.history.push("/new_card", catalogData);
            }
            // FROM ASSIGN CARD
            else if (this.props.match.params.continue_work === "assign") {
                // Send back all data
                this.props.history.push("/cards");
            }
            // ALL, EXCEPT BEFORE
            else {
                // Reset
                this.props.history.push("/");
            }
            return;
        }
        else {
            this.resetState(() => this.selectTemplates(newTemplateCreationStack));
        }
    }

    resetState(callback) {
        this.setState({
            loading: false,
            core: {},
            tabHeaders: [],
            activeTab: '',

            flows: [],

            modalAnnulla: false,

            customTemplate: [],

            modalSelectFields: false,

            selectFieldsMode: false,

            // Creazione scheda
            selectedFlow: '27d9f99e-3133-419a-8c20-a75c74406a85',
            // Enrichment
            //selectedFlow: '5847606e-2787-43fa-b616-c9f35c8149b6',
            formData: {},

            showEnrichmentPhaseModal: false,
            assign_uuid: this.state.assign_uuid,
            templateCreationStack: [],
            iccdFilter: "",
            customVisibility: null,
            templateToRender: null,
            headersToRender: [],
            htmlToRender: null,
            promptICCDPhaseModal: false,
            selectICCDModal: false,
            showRecapModal: false,
            recapFilter: "",
        }, callback);
    }

    selectTemplates = (uuid_list) => {
        this.setState({
            modalSelectTemplate: false,
            showRecapModal: false,
            templateCreationStack: Array.isArray(uuid_list) ? uuid_list : [uuid_list]
        }, () => {
            let currentUuid = this.state.templateCreationStack[0];
            let query = '/templates/' + currentUuid;
            this.toggleEnrichmentPhaseInfoModal();
            this.setState({
                loading: true
            }, () => {
                axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
                    {
                        auth: holdDataService.getAuthorization()
                    })
                    .then(res => {
                        let core;
                        if (res.data.rowCount > 0) {
                            core = res.data.rows[0];
                        }

                        // Load template into manager
                        ctxTemplateManager.parseTemplateJSON(core["template_data"]);

                        // Generate template from JSON
                        const processedTemplate = ctxTemplateManager.getProcessedTemplate();

                        // Get header from manager
                        const headers = ctxTemplateManager.getAllHeaders();

                        this.setState({
                            core: core,
                            customTemplate: core["template_data"]["core_data"],
                            customVisibility: core["template_data"]["visibility_data"],
                            modalSelectTemplate: false,
                            selectFieldsMode: true,
                            loading: false,
                            activeTab: headers.length > 0 ? headers[0]["field-edusn-srvid"] : null,
                            headersToRender: headers,
                            templateToRender: processedTemplate,
                        }, () => {
                            //console.log(this.state)
                        })
                    }, err => {
                        this.setState({ loading: false })
                    });
            });

            /*query = '/workflows';
            this.toggleEnrichmentPhaseInfoModal();
            this.setState({
              loading: true
            })
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
              {
                auth: holdDataService.getAuthorization()
              })
              .then(res => {
                let flows = [];
                if (res.data.rowCount > 0) {
                  flows = res.data.rows;
                }
                this.setState({
                  flows: flows,
                  selectedFlow: flows[0].flow_uuid,
                  loading: false
                });
              }, err => {
                this.setState({ loading: false })
              });*/
        });
    }

    scrollUp = () => {
        let div = document.getElementById("root");
        div.scrollTop = 0;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => this.scrollUp());
        }
    }

    toggleAnnulla = () => {
        this.setState({
            modalAnnulla: !this.state.modalAnnulla
        })
    }

    toggleSelectTemplate = () => {
        this.setState({
            modalSelectTemplate: !this.state.modalSelectTemplate,
        });

    }

    toggleSelectFields = () => {
        this.setState({
            modalSelectFields: !this.state.modalSelectFields
        });
    }

    toggleSelectionDone = () => {
        this.setState({
            modalSelectionDone: !this.state.modalSelectionDone
        })
    }

    checkFieldArrayVisible = () => {
        var fieldArrays = document.querySelectorAll('.field-array');
        if (fieldArrays) {
            for (let i = 0; i < fieldArrays.length; i++) {
                let items = fieldArrays[i].querySelectorAll('.array-item');
                let notVisibleCount = 0;
                let allFieldsCount = 0;
                //?//let allEnrichments = 0;

                for (let j = 0; j < items.length; j++) {
                    let fields = items[j].querySelectorAll('.field-string');

                    allFieldsCount += fields.length;
                    let notVisibleFields = items[j].querySelectorAll('.not-visible');
                    //?//let enrichmentFields = items[j].querySelectorAll('.enrichment');
                    notVisibleCount += notVisibleFields.length;
                    //?//allEnrichments += enrichmentFields.length;
                }
                if (allFieldsCount === notVisibleCount) {
                    fieldArrays[i].classList.add('not-visible')
                }
            }
        }
    }

    toggleEnrichmentPhaseInfoModal = () => {
        this.setState({
            showEnrichmentPhaseModal: !this.state.showEnrichmentPhaseModal,
        })
    }

    ICCDCheckTemplateParse = (template, parentShouldRenderByFilter) => {
        return template.map(obj => {
            // Never render not renderable fields
            if (!this.state.core.template_data["visibility_data"][obj["field-edusn-srvid"]]["field-is-renderable"]) {
                return false;
            }
            // No Enrichment fields render
            if (this.state.core.template_data["visibility_data"][obj["field-edusn-srvid"]]["field-is-enrichment"]) {
                return false;
            }
            let children = obj["field-tree-childs"];
            let content = null;
            // If the current field has children
            if (children && Array.isArray(children)) {
                // Define if renderable
                let shouldRender = obj["field-name"].toLowerCase().indexOf(this.state.iccdFilter.toLowerCase()) !== -1;
                content = this.ICCDCheckTemplateParse(children, (shouldRender || parentShouldRenderByFilter));
                // Check if field children will render or not due to filter
                let childrenRendered = ((content) => {
                    let rendered = false;
                    for (let i = 0; i < content.length; i++) {
                        if (content[i]) {
                            rendered = true;
                            break;
                        }
                    }
                    return rendered;
                })(content);
                // Render field only if it has renderable children
                if (childrenRendered || shouldRender || parentShouldRenderByFilter) {
                    return <CustomAccordion key={obj["field-edusn-srvid"]} header={obj["field-name"]} content={content} />;
                }
                return null;
            }
            // If the current field is a child
            else {
                if (obj["field-name"].toLowerCase().indexOf(this.state.iccdFilter.toLowerCase()) !== -1 || parentShouldRenderByFilter) {
                    return <CustomNodeSelector
                        key={obj["field-edusn-srvid"]}
                        obj={obj}
                        visibilityObj={this.state.customVisibility[obj["field-edusn-srvid"]]}
                        onFieldVisibilityToggle={(newValue) => this.toggleFieldVisibility(obj["field-edusn-srvid"], newValue)}
                    />;
                }
                return null;
            }
        });
    }

    simpleTemplateParse = (template, customVisibility, parentShouldRenderByFilter) => {
        return template.map(obj => {
            // Never render not renderable fields
            if (!customVisibility[obj["field-edusn-srvid"]]["field-is-renderable"]) {
                return false;
            }

            // Check if field is visible by visibility
            let fieldIsVisible = true;
            if (!customVisibility[obj["field-edusn-srvid"]]["field-is-visible"] && !customVisibility[obj["field-edusn-srvid"]]["field-is-required"]) {
                fieldIsVisible = false;
            }

            // Check if field is visible by filter
            let couldRenderByFilter = obj["field-name"].toLowerCase().indexOf(this.state.recapFilter.toLowerCase()) !== -1;

            let children = obj["field-tree-childs"];
            let content = null;
            // If the current field has children
            if (children && Array.isArray(children)) {
                content = this.simpleTemplateParse(children, customVisibility, (parentShouldRenderByFilter || (couldRenderByFilter && fieldIsVisible)));
                // Check if field children will render or not due to filter
                let childrenRendered = ((content) => {
                    let rendered = false;
                    for (let i = 0; i < content.length; i++) {
                        if (content[i]) {
                            rendered = true;
                            break;
                        }
                    }
                    return rendered;
                })(content);
                // Render field only if it has renderable children
                if (childrenRendered || (fieldIsVisible && (couldRenderByFilter || parentShouldRenderByFilter))) {
                    return <CustomAccordion key={obj["field-edusn-srvid"]} header={obj["field-name"]} content={content} />;
                }
                return null;
            }
            // If the current field is a child
            else {
                if (fieldIsVisible && (couldRenderByFilter || parentShouldRenderByFilter)) {
                    return <CustomNodeRenderer
                        key={obj["field-edusn-srvid"]}
                        obj={obj}
                    />;
                }
                return null;
            }
        });
    }

    toggleFieldVisibility = (srvid, newValue) => {
        let customVisibility = JSON.parse(JSON.stringify(this.state.customVisibility));
        customVisibility[srvid]["field-is-visible"] = newValue;
        this.setState({ customVisibility: customVisibility });
    }

    onICCDFilterChange = (event) => {
        this.setState({
            iccdFilter: event.target.value
        });
    }

    onRecapFilterChange = (event) => {
        this.setState({
            recapFilter: event.target.value
        });
    }

    createNewVisibility = () => {
        let query = "/templates/" + this.state.templateCreationStack[0] + "/assignments/" + this.state.assign_uuid + "/visibilities";
        let postBody = {};
        postBody.visibility_data = this.state.customVisibility;
        postBody.visibility_support = {};
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            postBody,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                this.onTemplateCreation();
            }, err => {
                //
            });
    }

    getTabBodiesHTML = () => {
        // get active tab header
        if (this.state.activeTab &&
            this.state.activeTab !== "") {
            // Render section of tab
            return this.getTabBodiesHTML_helper(
                this.state.activeTab
            );
        }

        // Always null
        return null;
    }

    getTabBodiesHTML_helper = (node, nodePath = "") => {
        // Prepare data
        let curSectionNode = ctxTemplateManager.getTemplateSection(node);

        // Prepare component data
        let pathSep = ".";
        let isMulti = false;
        let nChilds = [];

        // Check for not empty path
        if (nodePath === "") {
            // Concat separator
            pathSep = "";
        }

        // Check for children
        if (curSectionNode &&
            Array.isArray(curSectionNode["field-tree-childs"]) &&
            curSectionNode["field-tree-childs"].length > 0) {
            // Check for array
            // Delegate to function
            nChilds = curSectionNode["field-tree-childs"].map((child) => {
                return this.getTabBodiesHTML_helper(
                    child,
                    nodePath + pathSep + curSectionNode["field-code"]
                );
            });
        }
        // Last node of tree...
        else {
            // Create leaf
            nChilds = [(
                <TemplateField
                    key={"JSX_INPUT__" + curSectionNode["field-edusn-srvid"]}
                    fieldData={curSectionNode}
                    fieldMask={null}
                    fieldIndex={0}
                    fieldRealPath={nodePath + pathSep + curSectionNode["field-code"]}
                    formData={null}
                    updateData={this.updateJsonData}
                    visibilityObj={this.state.customVisibility[curSectionNode["field-edusn-srvid"]]}
                    onFieldVisibilityToggle={(newValue) => this.toggleFieldVisibility(curSectionNode["field-edusn-srvid"], newValue)}
                />
            )];
        }

        // Send back component
        return (<TemplateFieldGroup
            key={"JSX_GROUP__" + curSectionNode["field-edusn-srvid"]}
            fieldData={curSectionNode}
            fieldMask={null}
            fieldChildren={nChilds}
            fieldIndex={0}
            fieldRealPath={nodePath + pathSep + curSectionNode["field-code"]}
            formData={null}
            isMultiGroup={isMulti}
            updateData={this.updateJsonData}
        />);
    }

    updateJsonData = (jsonPath, jsonValue) => {
        let newJsonData = ctxTemplateManager.updateFormDataProperty(
            this.state.formData,
            jsonPath,
            jsonValue
        );

        this.setState({
            formData: newJsonData
        });
    }

    abortTotalFlow = () => {
        this.props.history.push("/");
    }

    confirmEnrichment = () => {
        this.setState({
            promptICCDPhaseModal: true
        });
    }

    selectICCDFields = () => {
        this.setState({
            promptICCDPhaseModal: false,
            selectICCDModal: true
        });
    }

    showSummary = () => {
        this.setState({
            promptICCDPhaseModal: false,
            selectICCDModal: false,
            showRecapModal: true
        });
    }

    endCurrentFlow = () => {
        this.createNewVisibility();
    }

    render() {
        return (
            <Fragment>
                {/* V MODALE PRE-ANNULLAMENTO FLUSSO: -- modalAnnulla -- V */}
                <Modal className='edubba-modal select-template-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalAnnulla}
                    toggle={this.toggleAnnulla}
                    backdrop='static'>
                    <ModalHeader toggle={this.toggleAnnulla}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon icon={faQuestion} className='icon' />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Attenzione
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='inner-modal'>
                                    Sicuro di voler annullare la creazione del template?<br />
                                    Le modifiche saranno perse
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg='6' md='6' sm='12' className='inner-modal'>
                                    <Button className='btn-edubba' onClick={event => {
                                        event.preventDefault();
                                        this.toggleAnnulla();
                                    }}>
                                        Annulla
                                    </Button>
                                </Col>
                                <Col lg='6' md='6' sm='12' className='inner-modal'>
                                    <Button className='btn-edubba' onClick={this.abortTotalFlow}>
                                        Conferma
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
                {/* ^ MODALE PRE-ANNULLAMENTO FLUSSO: -- modalAnnulla -- ^ */}
                {/* V MODALE SELEZIONE TEMPLATE (ONLY FIRST TIME): -- modalSelectTemplate -- V */}
                {this.state.modalSelectTemplate ?
                    <SelectTemplateModal
                        toggleSelectTemplate={this.toggleSelectTemplate}
                        selectTemplate={this.selectTemplates}
                        checkWithAssign={this.state.assign_uuid}
                        singleSelect={true}
                    /> : null}
                {/* ^ MODALE SELEZIONE TEMPLATE (ONLY FIRST TIME): -- modalSelectTemplate -- ^ */}
                {/* V MODALE INFO PHASE 1 (SELECT ENRICHMENT): -- showEnrichmentPhaseModal -- V */}
                <Modal className='edubba-modal select-template-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.showEnrichmentPhaseModal}
                    toggle={null}>
                    <ModalHeader>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Attenzione
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col style={{textAlign: "left"}}>
                                    Per procedere è necessario creare una maschera di visibilità per le Schede di tipo <strong>"{this.state.core.template_name}"</strong>. In questa prima fase verranno selezionati i campi <strong>"Arricchimento del Bene"</strong> che si desiderano visualizzare durante l'attività di progetto.
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.toggleEnrichmentPhaseInfoModal}>Continua</Button>
                    </ModalFooter>
                </Modal>
                {/* ^ MODALE INFO PHASE 1 (SELECT ENRICHMENT): -- showEnrichmentPhaseModal -- ^ */}
                {/* V BODY PHASE 1 (SELECT ENRICHMENT): -- selectFieldsMode -- V */}
                {this.state.selectFieldsMode ? <Container className='SelectFields'>
                    <div className="">
                        <Row className='tab-header'>
                            <Nav justified>
                                {
                                    (this.state.headersToRender.length > 0) ?
                                        this.state.headersToRender.map((tab) => {
                                            return (
                                                <NavItem key={`header-${tab["field-edusn-srvid"]}`}>
                                                    <NavLink href={`#${tab["field-edusn-srvid"]}`}
                                                        className={classnames({ active: this.state.activeTab === tab["field-edusn-srvid"] })}
                                                        onClick={() => {
                                                            this.toggle(tab["field-edusn-srvid"]);
                                                        }} >
                                                        {tab["field-name"]}
                                                    </NavLink>
                                                </NavItem>
                                            );
                                        })
                                        : null
                                }
                            </Nav>
                        </Row>
                        <Row className='template-name-area'>
                            Template {this.state.core.template_name}
                        </Row>
                        <Row className='tab-body RootCardSection' style={{ padding: "40px 0px 30px 0px" }}>
                            <Col xs="12">
                                <div className="CardContent">
                                    <div className="CardHeader">
                                        CONTENUTO SCHEDA
                                    </div>
                                    <div className="CardBody">
                                        <TabContent activeTab={this.state.activeTab}>
                                            {
                                                this.getTabBodiesHTML()
                                            }
                                        </TabContent>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='footer-buttons'>
                            <button className='btn btn-edubba btn-edubba-red'
                                type="button"
                                onClick={this.toggleAnnulla}>
                                Annulla
                            </button>
                            <button className={'btn btn-edubba'}
                                type="submit"
                                onClick={this.confirmEnrichment}>
                                Conferma
                            </button>
                        </div>
                    </div>
                </Container> : null}
                {/* ^ BODY PHASE 1 (SELECT ENRICHMENT): -- selectFieldsMode -- ^ */}
                {/* V MODAL PHASE 2 (PROMPT ICCD): -- promptICCDPhaseModal -- V */}
                <Modal className='edubba-modal select-template-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.promptICCDPhaseModal}
                    toggle={null}>
                    <ModalHeader>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Attenzione
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col style={{textAlign: "left"}}>
                                    Infine è possibile selezionare campi ICCD propri delle Schede di tipo <strong>"{this.state.core.template_name}"</strong>. Si desidera aggiungerli all'attività di progetto?
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.showSummary}>No</Button>
                        <Button className='btn-edubba' onClick={this.selectICCDFields}>Sì</Button>
                    </ModalFooter>
                </Modal>
                {/* ^ MODAL PHASE 2 (PROMPT ICCD): -- promptICCDPhaseModal -- ^ */}
                {/* V MODAL PHASE 3 (SELECT ICCD): -- selectICCDModal -- V */}
                <Modal className='edubba-modal select-template-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.selectICCDModal}
                    toggle={null}
                    backdrop='static'
                >
                    <ModalHeader>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'></div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Selezione campi ICCD
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text ICCDModalContainer'>
                            <div className="mb-2">
                                Template {this.state.core.template_name}
                            </div>
                            <div className="ICCDSearchInput">
                                <input type="text" className="" placeholder="Cerca campo" value={this.state.iccdFilter} onChange={this.onICCDFilterChange} />
                            </div>
                            <Row>
                                <PerfectScrollbar>
                                    <Col lg='12' md='12' sm='12' className='modal-template-content'>
                                        {this.ICCDCheckTemplateParse(this.state.customTemplate)}
                                    </Col>
                                </PerfectScrollbar>
                            </Row>
                            <br />
                            <Row>
                                <Col lg='6' md='6' sm='12' className='inner-modal'>
                                    <Button className='btn-edubba' onClick={this.toggleAnnulla}>
                                        Annulla
                                    </Button>
                                </Col>
                                <Col lg='6' md='6' sm='12' className='inner-modal'>
                                    <Button className='btn-edubba' onClick={this.showSummary}>
                                        Conferma
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
                {/* ^ MODAL PHASE 3 (SELECT ICCD): -- selectICCDModal -- ^ */}
                {/* V MODAL PHASE 4 (RECAP): -- showRecapModal -- V */}
                <Modal className='edubba-modal select-template-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.showRecapModal}
                    toggle={this.toggleAnnulla}
                    backdrop='static'
                >
                    <ModalHeader toggle={this.toggleAnnulla}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Riepilogo campi scelti
                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text ICCDModalContainer'>
                            <div className="mb-2">
                                Template {this.state.core.template_name}
                            </div>
                            <div className="ICCDSearchInput">
                                <input type="text" className="" placeholder="Cerca campo" value={this.state.recapFilter} onChange={this.onRecapFilterChange} />
                            </div>
                            <Row>
                                <PerfectScrollbar>
                                    <Col lg='12' md='12' sm='12' className='modal-template-content'>
                                        {this.simpleTemplateParse(this.state.customTemplate, this.state.customVisibility)}
                                    </Col>
                                </PerfectScrollbar>
                            </Row>
                            <br />
                            <Row>
                                <Col lg='12' md='12' sm='12' className='inner-modal'>
                                    <Button className='btn-edubba' onClick={this.endCurrentFlow}>
                                        Conferma
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
                {/* ^ MODAL PHASE 4 (RECAP): -- showRecapModal -- ^ */}
            </Fragment >

        );
    }
}

class CustomAccordion extends Component {
    constructor(props) {
        super(props);
        // header
        // content

        this.state = {
            collapsed: false
        }
    }

    toggle = () => this.setState({ collapsed: !this.state.collapsed });

    render() {
        return (
            <div className="accordion-toggler-container">
                <div className="accordion-toggler" onClick={this.toggle}>
                    {this.props.header}
                </div>
                <Collapse isOpen={!this.state.collapsed}>
                    <div className="accordion-content">
                        {this.props.content}
                    </div>
                </Collapse>
            </div>
        );
    }
}

class CustomNodeSelector extends Component {
    constructor(props) {
        super(props);
        // obj: JSON
        // visibilityObj: JSON
        // onFieldVisibilityToggle: Function

        this.state = {
            checked: props.visibilityObj["field-is-visible"]
        };
    }

    getExampleString = (examplesArray) => {
        if (examplesArray && Array.isArray(examplesArray) && examplesArray.length > 0) {
            return <Fragment><b>Esempi:</b> {examplesArray.join(", ")}</Fragment>;
        }
        return "";
    }

    onFieldVisibilityToggle = () => {
        this.setState({ checked: !this.state.checked }, () => {
            this.props.onFieldVisibilityToggle(this.state.checked);
        });
    }

    render() {
        return (
            <div className="template-item-content">
                <div className="template-item-label">
                    {this.props.obj["field-name"]}
                    {!this.props.visibilityObj["field-is-required"] ? <div className="modal-checkbox">
                        <label>
                            <input
                                id={this.props.obj["field-name"] + "_check"}
                                type="checkbox"
                                checked={this.state.checked}
                                value={this.state.checked}
                                onChange={this.onFieldVisibilityToggle}
                            />
                            <span></span>
                        </label>
                    </div> : null}
                </div>
                <div className="template-item-description">
                    {this.props.obj["field-description"]}
                </div>
                <div className="template-item-example">
                    {this.getExampleString(this.props.obj["field-desc-example"])}
                </div>
            </div>
        );
    }
}

class CustomNodeRenderer extends Component {
    // obj: JSON

    getExampleString = (examplesArray) => {
        if (examplesArray && Array.isArray(examplesArray) && examplesArray.length > 0) {
            return <Fragment><b>Esempi:</b> {examplesArray.join(", ")}</Fragment>;
        }
        return "";
    }

    render() {
        return (
            <div className="template-item-content" name={this.props.obj["field-edusn-srvid"]}>
                <div className="template-item-label">
                    {this.props.obj["field-name"]}
                </div>
                <div className="template-item-description">
                    {this.props.obj["field-description"]}
                </div>
                {/*<div className="template-item-example">
                    {this.getExampleString(this.props.obj["field-desc-example"])}
                </div>*/}
            </div>
        );
    }
}
class TemplateFieldGroup extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldIndex: Number => Index array
        // fieldRealPath: String => Path calcolato con index
        // fieldChildren: Array [{}] => TemplateField
        // formData: Object {} => Data
        // isMultiGroup: Boolean
        // updateData: Function() => Salvataggio del parziale nel JSON root

        this.state = {};
    }

    render() {
        let isOdd  = this.props.fieldData["field-tree-level"] % 2 !== 0;
        let isLast = this.props.fieldData["field-last-level"];

        return (
            <div
                name={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                key={"GROUP__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                className={"TemplateFieldGroup" + (isOdd ? " TemplateFieldGroupOdd" : "")}>
                <div className={"SectionHeader" + (isOdd ? " SectionHeaderOdd" : "")}>
                    <div className="SectionName" style={{fontWeight: (isLast ? "bold" : "normal")}}>
                        {this.props.fieldData["field-name"].toLowerCase()}
                    </div>
                </div>
                <div className="SectionBody">
                    {this.props.fieldChildren.map((child) => {
                        return child;
                    })}
                </div>
            </div>
        )
    };
}

class TemplateField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // fieldMask: Object {} => Visibilità
        // fieldIndex: Number => Index array
        // fieldRealPath: String => Path calcolato con index
        // formData: Object {} => Data
        // updateData: Function() => Salvataggio del parziale nel JSON root
        // visibilityObj: Object {} => visibilityData
        // onFieldVisibilityToggle: Function

        this.state = {};
    }

    render() {
        let isOdd = this.props.fieldData["field-tree-level"] % 2 !== 0;

        return (
            <div
                name={"INPUT__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                key={"INPUT__" + this.props.fieldData["field-edusn-srvid"] + "__" + this.props.fieldIndex}
                className={"TemplateField FieldRow" + (isOdd ? " FieldOdd FieldRowOdd" : "")}>
                <TemplateCheckField
                    fieldData={this.props.fieldData}
                    visibilityObj={this.props.visibilityObj}
                    fieldRealPath={this.props.fieldRealPath}
                    onFieldVisibilityToggle={this.props.onFieldVisibilityToggle}
                />
            </div>
        )
    };
}

//----------------------------------------------------------------------| TEMPLATE FIELDS: INPUT TYPE

class TemplateCheckField extends Component {
    constructor(props) {
        super(props);
        // fieldData: Object {} => Struttura
        // visibilityObj: Object {} => visibilityData
        // fieldRealPath: String => Path calcolato con index
        // onFieldVisibilityToggle: Function

        this.state = {
            checked: props.visibilityObj["field-is-visible"]
        };
    }

    onEnrichmentToggle = () => {
        this.setState({
            checked: !this.state.checked
        }, () => {
            this.props.onFieldVisibilityToggle(this.state.checked);
        });
    }

    render() {
        return this.props.fieldData ? (
            <div className="template-field">
                <div
                    className="checkbox"
                    onClick={this.onEnrichmentToggle}>
                    <FontAwesomeIcon
                        id={this.props.fieldData["field-name"] + "_check"}
                        className={this.state.checked ? "checked" : ""}
                        icon={this.state.checked ? faCheck : faMinus}
                    />
                    <span>Campo {this.state.checked ? "s" : "non s"}elezionato per la fase di arricchimento.</span>
                </div>
                <div>
                    {this.props.fieldData["field-description"]}
                </div>
                <div>
                    {Array.isArray(this.props.fieldData["field-desc-example"]) ? this.props.fieldData["field-desc-example"].join(", ") : null}
                </div>
            </div>
        ) : null;
    };
}

export default withRouter(CardTemplate);
