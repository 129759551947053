// ------------------------------------------------------------------------- //
//
//  EDUBBA KERNEL: WEBSOCKET EMITTER
//
// ------------------------------------------------------------------------- //
//  COMMON MODULES
// ------------------------------------------------------------------------- //

import globalVars from './../../globalVars.json';
import holdDataService from './../holdDataService/holdDataService';

// ------------------------------------------------------------------------- //
//  COMMON CLASSES
// ------------------------------------------------------------------------- //

class __WebSocketSystem {
    constructor() {
        this.__ws_handlers = {
            /* empty as default */
        };

        // Storage messages
        this.__ws_messages_queue = {
            /* empty as default */
        };

        // Save flag system
        this.__ws_is_enable = globalVars.WSSEnable;

        // Generate connections
        this.__initWS();
    }

    __initWS() {
        // Check and spawn
        if (this.__ws_is_enable) {
            // Get auth object
            const auth = holdDataService.getAuthorization();

            // Loop services
            globalVars.WSSServices.map((item) => {
                // Check existence of ws handler
                if (this.__ws_handlers[item.ServiceSID]) {
                    // Skip already connected
                    return false;
                }

                // Create connection
                const __ws = new WebSocket(
                    globalVars.WSSProtocol + "://" +
                    globalVars.BEHost + ":" +
                    globalVars.BEPort +
                    item.ServiceURL +
                    "?wsuseruuid=" + auth.username +
                    "&wsuserssid=" + auth.password +
                    "&wstenant="   + globalVars.WSSTenant,
                    [
                        "websocket"
                    ]
                );

                // Save handler
                this.__ws_handlers[item.ServiceSID] = __ws;

                // Generate empty queue
                this.__ws_messages_queue[item.ServiceSID] = [];

                // Debug message
                this.__ws_handlers[item.ServiceSID].onopen = (ev) => {
                    // Save connected socket
                    this.__ws_handlers[item.ServiceSID] = ev.target;

                    // Send ack
                    ev.target.send("CONN_ACK");

                    // Send all messages from queue
                    while (true) {
                        // Get message last message
                        let p_item = this.__ws_messages_queue[
                            item.ServiceSID
                        ].pop();

                        // Cehck for send
                        if (p_item) { ev.target.send(p_item); }
                        else break;
                    }
                };

                // Debug message
                this.__ws_handlers[item.ServiceSID].onclose = () => {
                    // Selete closed socket
                    delete this.__ws_handlers[item.ServiceSID];
                };

                // Debug message
                this.__ws_handlers[item.ServiceSID].onerror = (ev) => {
                    console.error("WebSocket error observed:   ", ev);
                };

                // Always false
                return false;
            });
        }
    }

    genWsMuid() {
        // Generate uuid
        return "muid-" + ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
        ) + "-opdata-" + Date.now();
    }

    destroyAll() {
        // Get all socket keys
        const keys = Object.keys(this.__ws_handlers);

        // Loop on keys
        keys.map((key) => {
            // Close socket
            this.__ws_handlers[key].close();

            // Remove socket
            delete this.__ws_handlers[key];
            return true;
        });
    }

    exists(name) {
        // Check if exists
        const keys = Object.keys(this.__ws_handlers);
        return keys.includes(name);
    }

    getDispatchType() {
        // Send types
        return Object.freeze({
            Metered:   "METRIC_OPCODE_SEND_TYPE_METERED",
            Unmetered: "METRIC_OPCODE_SEND_TYPE_UNMETERED"
        });
    }

    dispatch(muid, type, target, payload) {
        // Check if exist
        if (!this.exists(target)) {
            // Try reconnect
            this.__initWS();

            // Check again after reconnect
            if (!this.exists(target)) {
                return;
            }
        }

        // Get handler
        const _sendToCBs = this.__ws_handlers[target];

        // DO NOT REMOVE - User Role
        const _sessionUser_IsAdmin     = holdDataService.loggedUserIsAdmin();
        const _sessionUser_IsTutor     = holdDataService.loggedUserIsTutor();
        const _sessionUser_IsStudent   = holdDataService.loggedUserIsStudent();
        const _sessionUser_IsCertifier = holdDataService.loggedUserIsCertifier();

        // DO NOT REMOVE - User Data
        const _sessionUser_UUID = holdDataService.getLoggedUserUuid();
        const _sessionUser_Data = holdDataService.getUserData();

        // Create payload
        const mpayload = JSON.stringify({
            "muid":    muid,
            "type":    type,
            "owner":   _sessionUser_UUID,
            "ssid":    _sessionUser_Data.user_ssid,
            "create":  Date.now(),
            "payload": {
                "user": {
                    "email":      _sessionUser_Data.email,
                    "profile":    _sessionUser_Data.user_profile,
                    "disclaimer": _sessionUser_Data.user_disclaimer,
                    "groups":     _sessionUser_Data.roles,
                    "roles": {
                        "is-read-only": _sessionUser_Data.read_only,
                        "is-admin":     _sessionUser_IsAdmin,
                        "is-certifier": _sessionUser_IsCertifier,
                        "is-tutor":     _sessionUser_IsTutor,
                        "is-student":   _sessionUser_IsStudent
                    }
                },
                "metric": payload
            }
        });

        // Check for socket status
        if (_sendToCBs.readyState !== WebSocket.OPEN) {
            // Save message for deleyed sending
            this.__ws_messages_queue[target].push(mpayload);
        }
        else {
            // Send all messages from queue
            while (true) {
                // Get message last message
                let item = this.__ws_messages_queue[target].pop();

                // Cehck for send
                if (item) { _sendToCBs.send(item); }
                else break;
            }

            // Send data
            _sendToCBs.send(mpayload);
        }
    }
};

// ------------------------------------------------------------------------- //
//  EXPORT SINGLETON
// ------------------------------------------------------------------------- //

/**
 * @author:      lsvanzo
 * @version:     17/03/2020
 * @description: Inizializza il sotto-sistema ad eventi.
 * @type:        Sync Function
 */

// Create singleton chain class
export default class __WebSocketSystem_Singleton {
    constructor() {
        // Check for instance
        if (!__WebSocketSystem_Singleton.instance) {
            // ... Create new
            __WebSocketSystem_Singleton.instance = new __WebSocketSystem();
        }
    }

    // Get existing instance
    getInstance() {
        return __WebSocketSystem_Singleton.instance;
    }
}

// ------------------------------------------------------------------------- //
//  END: KERNEL
// ------------------------------------------------------------------------- //
//  EXAMPLE: TRIGGER FOR METRIC STATS
// ------------------------------------------------------------------------- //
//
//    this.state = {
//      dispatchWSMetricEnable: false,
//      dispatchWSEventEnable:  false
//    };
//
// ------------------------------------------------------------------------- //
//  EXAMPLE: TRIGGER FOR METRIC STATS
// ------------------------------------------------------------------------- //
//
//    dispatchWSOpenMetric(_card_uuid, _card_view_status) {
//        // Check for already loaded
//        if (!this.state.dispatchWSMetricEnable) {
//            this.setState({
//                dispatchWSMetricEnable: true
//            },
//            () => {
//                // Get singleton instance of emitter
//                const instanceWSEmitter = new WSSystem().getInstance();
//
//                // Translator mode
//                const _getMode = () => {
//                    switch (_card_view_status) {
//                        case "E": return MetricOpCodes.Mode.Editor;
//                        case "S": return MetricOpCodes.Mode.Reviser;
//                        case "V": return MetricOpCodes.Mode.Reader;
//                        default:  return MetricOpCodes.Mode.Unknown;
//                    }
//                };
//
//                // Send message
//                instanceWSEmitter.dispatch(
//                    "ws-analyzer-stats",
//                    MetricOBJGenerator(
//                        _card_uuid,
//                        _getMode(),
//                        MetricOpCodes.Entity.Open
//                    )
//                );
//            });
//        }
//    }
//
//    dispatchWSCloseMetric() {
//        // Check for failed load
//        if (this.state.dispatchWSMetricEnable) {
//            // Get singleton instance of emitter
//            const instanceWSEmitter = new WSSystem().getInstance();
//
//            // Translator mode
//            const _getMode = () => {
//                switch (this.state.card_view_status) {
//                    case "E": return MetricOpCodes.Mode.Editor;
//                    case "S": return MetricOpCodes.Mode.Reviser;
//                    case "V": return MetricOpCodes.Mode.Reader;
//                    default:  return MetricOpCodes.Mode.Unknown;
//                }
//            }
//
//            // Send message
//            instanceWSEmitter.dispatch(
//                "ws-analyzer-stats",
//                MetricOBJGenerator(
//                    this.state.card_uuid,
//                    _getMode(),
//                    MetricOpCodes.Entity.Close
//                )
//            );
//        }
//    }
//
//    dispatchWSListenerBeforeUnLoad(event) {
//        // Block default
//        event.preventDefault();
//
//        // Force for some browser
//        (event || window.event).returnValue = null;
//
//        // Debug
//        console.log("OnBeforeUnload: fired!");
//
//        // Skip dialog from browser
//        return null;
//    }
//
//    dispatchWSListenerCloseMetric(event) {
//        // Block default
//        event.preventDefault();
//
//        // Debug
//        console.log("OnUnload: fired!");
//
//        // Delegate
//        this.dispatchWSCloseMetric();
//    }
//
//    dispatchWSAddListenerCloseMetric() {
//        // Check for already loaded
//        if (!this.state.dispatchWSEventEnable) {
//            this.setState({
//                dispatchWSEventEnable: true
//            },
//            () => {
//                // Add listener to window
//                $(window).on(
//                    "beforeunload",
//                    this.dispatchWSListenerBeforeUnLoad.bind(this)
//                );
//
//                // Add listener to window
//                $(window).on(
//                    "unload",
//                    this.dispatchWSListenerCloseMetric.bind(this)
//                );
//            });
//        }
//    }
//
//    dispatchWSDelListenerCloseMetric() {
//        // Remove all trigger
//        $(window).off("beforeunload");
//        $(window).off("unload");
//    }
//
// ------------------------------------------------------------------------- //
