import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from './../globalVars.json';
import {
    Row,
    Col,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
//import SearchBox from '../Layout/AppHeader/Components/SearchBox';
import holdDataService from '../services/holdDataService/holdDataService';
import {
    //faUser,
    //faShareAlt,
    faReply,
    faPlus,
    faEdit,
    faEye,
    //faUniversity,
    faArrowCircleLeft,
    faArrowCircleRight,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import EdubbaLoader from './../components/EdubbaLoader/EdubbaLoader';
import EdubbaDefaultImage from './../assets/utils/images/edubba_placeholder.png'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { ChromePicker } from 'react-color';
import $ from 'jquery';
import './Cards.scss';

// FEATURE CHECK FLAGS
const assignTypeEnabled = [
    "STSC0001"
];

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            cards: [],
            modalReplyCard: false,
            modalCreateNewCard: false,
            filterByPersonal: false,
            filterByShared: false,
            filterByCatalog: false,
            projects: [],
            selectedProject: "",
            selectedAssignment: "",
            modalSelectStudent: false,
            selectedGroup: {},
            group_uuid: "",
            selectedStudent: "",
            selectAssignmentValue: "",
            errorMessageTime: "",
            today: new Date().getTime(),
            startDateAssignment: new Date().getTime(),
            endDateAssignment: null,
            taskColor: "#ffffff",
            taskName: "",
            workflows: [],
            selectedFlow: "5847606e-2787-43fa-b616-c9f35c8149b6",
            selectedCard: null,
            showColorPicker: false,
            loadingCards: true,
            noTemplateModal: false,

            //----------------------------------------------| SEARCH

            searchCardValue: "",

            //----------------------------------------------| PAGING

            cardSplitButtonOpen: false,
            cardTotalEntities: 0,
            cardPageLimit: 10,
            cardPageNumber: 1,

            //----------------------------------------------| MODAL DELETE

            modalDeleteCard: false,
            modalDeleteCardUUID: null,

            //----------------------------------------------| MODAL CONFIRM
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
        };

    }

    componentDidMount() {
        this.props.setSectionTitle('Catalogo schede Personali e Condivise');
        // let allCards = this.props.match.params.flag === 'all';
        let query = '/projects';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            let projects = [];
            if (res.data.rowCount > 0) {
                projects = res.data.rows
            }
            this.setState({
                projects: projects,
            })
        }, err => {
            //
        });

        query = '/workflows';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            let workflows = [];
            if (res.data.rowCount > 0) {
                workflows = res.data.rows
            }
            this.setState({
                workflows: workflows,
            })
        }, err => {
            //
        });

        // load all cards
        this.loadCards();
    }

    loadCards = (searchValue = null) => {
        this.setState({ loadingCards: true });
        let query = '/cards?limit=' + this.state.cardPageLimit + '&page=' + this.state.cardPageNumber;
        if (searchValue) {
            query += '&searchBy=' + searchValue;
        }
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        }).then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    cardTotalEntities: res.data.rowCount,
                    cards:             res.data.rows,
                    loadingCards:      false
                });
            }
            else this.setState({
                cardPageNumber:    1,
                cardTotalEntities: 0,
                cards:             [],
                loadingCards:      false
            });
        }, err => {
            this.setState({
                cardPageNumber:    1,
                cardTotalEntities: 0,
                cards:             [],
                loadingCards:      false
            });
        });
    }

    toggleDeleteCardUUID = (card_uuid = null) => {
        this.setState({
            modalDeleteCard: !this.state.modalDeleteCard,
            modalDeleteCardUUID: card_uuid
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    goToCardPage = (pageNumber) => {
        this.setState({
            cardPageNumber: pageNumber
        }, () => {
            this.loadCards(
                this.state.searchCardValue
            );
        });
    }

    cardToggleSplit = () => {
        this.setState({
            cardSplitButtonOpen: !this.state.cardSplitButtonOpen
        });
    }

    handleCardSearch = (ev) => {
        this.setState({
            searchCardValue: ev.target.value
        })
    }

    searchCard = (ev) => {
        const keyCode = ev.which || ev.keyCode;
        if (keyCode === 13) {
            this.loadCards(
                this.state.searchCardValue
            );
        }
    }

    clearSearchCards = () => {
        this.setState({
            searchCardValue: "",
            cardPageNumber: 1
        }, () => {
            this.loadCards();
        });
    }

    toggleReplyCardModal = (event, card_body, nextStep) => {
        event.preventDefault();
        let card = null;
        if (card_body && JSON.stringify(card_body) !== "{}") {
            card = card_body;
        }
        this.setState({
            selectedCard: card,
            modalReplyCard: !this.state.modalReplyCard
        }, () => {
            if (nextStep) {
                this.toggleSelectStudentModal(event);
            }
        });
    }

    toggleSelectStudentModal = (event) => {
        event.preventDefault();
        let query = '/groups/' + this.state.group_uuid;
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
            .then(res => {
                let selectedGroup = res.data && res.data.rows ? res.data.rows[0] : {};
                this.setState({
                    selectedGroup: selectedGroup
                }, () => {
                    this.setState({
                        modalSelectStudent: !this.state.modalSelectStudent
                    });
                });
            }, err => {
                //
            });
    }

    toggleCreateNewCard = (event) => {
        event.preventDefault();
        this.setState({
            modalCreateNewCard: !this.state.modalCreateNewCard
        })
    }

    handleButtonFilter(type) {
        let nextValue = !this.state[type];
        if (type === "filterByPersonal") {
            this.setState({
                filterByPersonal: nextValue,
                filterByShared: false,
                filterByCatalog: false
            });
        }
        else if (type === "filterByShared") {
            this.setState({
                filterByPersonal: false,
                filterByShared: nextValue,
                filterByCatalog: false
            });
        }
        else if (type === "filterByCatalog") {
            this.setState({
                filterByPersonal: false,
                filterByShared: false,
                filterByCatalog: nextValue
            });
        }
    }

    handleProjectSelect = (event) => {
        const selectedProject = event.target.value;
        let { selectedAssignment, group_uuid } = (() => {
            for (let i = 0; i < this.state.projects.length; i++) {
                let currentProject = this.state.projects[i];
                if (
                    currentProject.project_uuid === selectedProject &&
                    (currentProject.project_assignments && currentProject.project_assignments.length > 0)
                ) {
                    return {
                        selectedAssignment: currentProject.project_assignments[0].assign_uuid,
                        group_uuid: currentProject.project_assignments[0].group_uuid
                    };
                }
            }
            return "";
        })();

        this.setState({
            selectedProject: selectedProject,
            selectedAssignment: selectedAssignment,
            group_uuid: group_uuid,
            selectAssignmentValue: (selectedAssignment + " " + group_uuid)
        });
    }

    handleAssignmentSelect = (event) => {
        let assignmentAndGroup = event.target.value.split(' ');
        this.setState({
            selectedAssignment: assignmentAndGroup[0],
            group_uuid: assignmentAndGroup[1],
            selectAssignmentValue: event.target.value
        });
    }

    handleStudentSelect = (event) => {
        this.setState({
            selectedStudent: event.target.value,
        });
    }

    handleTaskNameChange = (event) => {
        this.setState({
            taskName: event.target.value,
        });
    }

    handleFlowSelect = (event) => {
        this.setState({
            selectedFlow: event.target.value,
        });
    }

    handleChangeStartAssignment = (startDate) => {
        if (moment(startDate.getTime()).isAfter(moment(this.state.today))) {
            this.setState({ startDateAssignment: startDate.getTime(), errorMessageTime: '' })
        }
        else {
            this.setState({
                errorMessageTime: 'Hai inserito una data inferiore a ' +
                    moment(new Date().getTime()).format('DD MMMM YY'),
                startDate: new Date().getTime(),
            });
        }
    }

    handleChangeEndAssignment = (endDate) => {
        if (this.state.startDateAssignment) {
            if (this.state.startDateAssignment <= endDate.getTime()) {
                this.setState({ endDateAssignment: endDate.getTime(), errorMessageTime: '' })
            }
            else {
                this.setState({ errorMessageTime: 'Inserire una data di fine oltre quella di scadenza' })
            }
        }
    }

    createTask = (event) => {
        let query = '/assignments/' + this.state.selectedAssignment + '/tasks';
        let payload = {};
        payload.task_name = this.state.taskName;
        payload.task_color = this.state.taskColor;
        payload.task_date_start = this.state.startDateAssignment;
        payload.task_date_end = this.state.endDateAssignment;
        payload.user_uuid = this.state.selectedStudent;
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload, {
            auth: holdDataService.getAuthorization()
        })
            .then(res => {
                let query = '/cards/' + this.state.selectedCard.card_uuid + '/enrichments';
                let payload = {};
                payload.task_uuid = res.data.rows[0].task_uuid;
                payload.flow_uuid = this.state.selectedFlow;
                payload.status_code = "FW_CDER_0001";
                payload.enrich_data = {};
                axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, payload, {
                    auth: holdDataService.getAuthorization()
                })
                    .then(res => {
                        this.toggleSelectStudentModal(event);
                    }, err => {
                        //
                    });
            }, err => {
                //
            });
    }

    deleteBaseCard = (card_uuid) => {
        let query = '/cards/' + card_uuid;
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query, {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            // Check
            if (res && res.data && res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Scheda",
                    modalConfirmToastMessage: "Scheda selezionata rimossa con successo dalla propria libreria!",
                    modalDeleteCard: false,
                    modalDeleteCardUUID: null
                }, () => {
                    // Refresh
                    this.loadCards();
                });
            } else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Scheda",
                    modalConfirmToastMessage: "Impossibile completare la rimozione della scheda selezionata: verificare che non sia associata a qualche attività o condivisa con altri utenti della piattaforma.",
                    modalDeleteCard: false,
                    modalDeleteCardUUID: null
                });
            }
        }, err => {
            // Error
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Rimozione Scheda",
                modalConfirmToastMessage: err.message,
                modalDeleteCard: false,
                modalDeleteCardUUID: null
            });
        });
    }

    goToCardDetail = (card_uuid, mode) => {
        this.props.history.push('/card-detail/' + mode + '/' + card_uuid)
    }

    handleConceptsChange = (selectedConcepts, colorIsSet) => {
        if (!colorIsSet && selectedConcepts.length > 0 && this.state.selectedConcepts.length < selectedConcepts.length) {
            this.setRandomColorToNewConcept(selectedConcepts);
        }
        this.setState({ selectedConcepts: selectedConcepts }, function () {
            $(".concepts-creator .Select-value").map((index, obj) => {
                $(obj).find(".color-picker-div").remove();
                let colorPicker = document.createElement("span");
                $(colorPicker)
                    .addClass("float-left color-picker-div")
                    .css({ backgroundColor: selectedConcepts[index].color })
                    .bind("click", () => this.toggleColorHandler(selectedConcepts[index].color, index));
                $(obj).append(colorPicker);
                return true;
            })
        });
    }

    toggleColorHandler = (event) => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        }, function () {
            let container = $(".Cards");
            $(".colorpicker-popover").css({
                top: (container.height() / 6),
                left: (container.width() / 6)
            });
        });
    };

    handleColorChange = (color) => {
        this.setState({ taskColor: color.hex });
    };

    assignCardIfTemplateExists = (event) => {
        if (this.templateExistsForCard(this.state.selectedCard, this.state.selectedAssignment)) {
            this.toggleReplyCardModal(event, this.state.selectedCard, true);
        }
        else {
            this.setState({
                modalReplyCard:  false,
                noTemplateModal: true
            });
        }
    }

    templateExistsForCard = (cardBody, assign_uuid) => {
        if (Array.isArray(cardBody.template_visibilities) && cardBody.template_visibilities.length > 0) {
            for (let i = 0; i < cardBody.template_visibilities.length; i++) {
                if (cardBody.template_visibilities[i] && cardBody.template_visibilities[i].assign_uuid === assign_uuid) {
                    return true;
                }
            }
            return false;
        }
        return false;
    }

    handleNoTemplate = () => {
        this.props.history.push(
            "/card-template/" + this.state.selectedAssignment + "/assign",
            {
                // Always try with template UUID
                ...((
                    this.state.selectedCard               &&
                    this.state.selectedCard.template_uuid &&
                    this.state.selectedAssignment         &&
                    this.state.selectedProject
                ) ? {
                    tpl_uuid:     this.state.selectedCard.template_uuid,
                    project_uuid: this.state.selectedProject,
                    assign_uuid:  this.state.selectedAssignment
                } : {})
            }
        );
    }

     getCardOwner = (obj) => {
        if (obj &&
            obj.card_owner &&
            obj.card_owner.user_profile &&
            obj.card_owner.user_profile.firstName &&
            obj.card_owner.user_profile.lastName) {
            return (
                obj.card_owner.user_profile.firstName[0] +
                ". " +
                obj.card_owner.user_profile.lastName
            );
        }

        return "Non disponible..."
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    isValidURL = (str) => {
        // Load CPU fix (???)
        if (!(str && (
            str.toLowerCase().startsWith("http:") ||
            str.toLowerCase().startsWith("https:")))) {
            return false;
        }

        var pattern = new RegExp(
            '^(https?:\\/\\/)?'+                                   // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+    // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+                         // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                     // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+                            // query string
            '(\\#[-a-z\\d_]*)?$','i'                               // fragment locator
        );

        return !!pattern.test(str);
    }

    getCardImage = (card_image) => {
        let coreImage = EdubbaDefaultImage;

        if (this.isValidURL(card_image)) {
            coreImage = card_image;
        }

        return coreImage;
    }

    setDefaultImgSrc = (ev) => {
        ev.target.src = EdubbaDefaultImage;
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    render() {
        let cardPages = Math.ceil(
            this.state.cardTotalEntities /
            this.state.cardPageLimit
        );

        cardPages = (cardPages <= 0) ? 1 : cardPages;

        return (
            <Fragment>
                <Container className='Cards'>
                    {/* V MODAL NO TEMPLATE: -- noTemplateModal -- V */}
                    <Modal className='edubba-modal select-template-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.noTemplateModal}
                        toggle={null}
                        backdrop='static'>
                        <ModalHeader>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Attenzione
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row>
                                    <Col style={{textAlign: "left"}}>
                                        Non sono state trovate maschere di visibilità associate alla coppia <strong>"Scheda"</strong> ed <strong>"Attività"</strong> selezionata: premere su <strong>"Continua"</strong> per avviare la creazione, altrimenti <strong>"Annulla"</strong> per tornare alla lista delle schede.<br />
                                        <br />
                                        <i>Al termine della creazione della maschera di visibilità sarà necessario procedere nuovamente con l'assegnazione della scheda!</i>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={() => {
                                this.setState({
                                    noTemplateModal:    false,
                                    selectedCard:       null,
                                    selectedProject:    "",
                                    selectedAssignment: ""
                                });
                            }}>Annulla</Button>
                            <Button className='btn-edubba' onClick={this.handleNoTemplate}>Continua</Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ MODAL NO TEMPLATE: -- noTemplateModal -- ^ */}
                    {/* V ASSEGNAZIONE SCHEDA: modalReplyCard V */}
                    <Modal className='edubba-modal custom-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalReplyCard}
                        toggle={(event) => this.toggleReplyCardModal(event, this.state.selectedCard, false)}
                        backdrop='static' >
                        <ModalHeader toggle={(event) => this.toggleReplyCardModal(event, this.state.selectedCard, false)}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3'>
                                    </div>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Assegnazione Scheda
                                </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row className='custom-row'>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        <div className='name'>
                                            SELEZIONARE IL PROGETTO
                                    </div>
                                        <span className='white-row'>
                                            <select
                                                className='legacy-form-control'
                                                name='assignmentType'
                                                value={this.state.selectedProject}
                                                onChange={this.handleProjectSelect}
                                            >
                                                <option key="key-0" value="" disabled={true}>Seleziona progetto...</option>
                                                {this.state.projects.map((obj, index) => {
                                                    return <option key={obj.project_uuid} value={obj.project_uuid}>
                                                        {obj.project_name}
                                                    </option>;
                                                })}
                                            </select>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        <div className='name'>
                                            SELEZIONARE L'ATTIVITÀ
                                    </div>
                                        <span className='white-row'>
                                            <select
                                                className='legacy-form-control'
                                                name='assignmentType'
                                                value={this.state.selectAssignmentValue}
                                                onChange={this.handleAssignmentSelect}
                                            >
                                                <option key="key-0" value="" disabled={true}>Seleziona attività...</option>
                                                {this.state.projects.map((obj, index) => {
                                                    if (obj.project_uuid === this.state.selectedProject) {
                                                        return obj.project_assignments.map(assignment => {
                                                            // Check if assign is not enabled for task
                                                            if (!assignTypeEnabled.includes(
                                                                assignment.assign_type
                                                            )) { return null; }
                                                            // Otherwise show
                                                            else return <option
                                                                key={assignment.assign_uuid}
                                                                value={assignment.assign_uuid + " " + assignment.group_uuid}
                                                            >
                                                                {assignment.assign_descr}
                                                            </option>;
                                                        })
                                                    }
                                                    return false;
                                                })}
                                            </select>
                                        </span>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={(event) => this.toggleReplyCardModal(event, this.state.selectedCard, false)}>Annulla</Button>
                            <Button
                                className='btn-edubba'
                                disabled={this.state.selectAssignmentValue === "" || this.state.selectedFlow === ""}
                                onClick={this.assignCardIfTemplateExists}
                            >
                                Assegna Scheda
                                </Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ ASSEGNAZIONE SCHEDA: modalReplyCard ^ */}
                    {/* V CREAZIONE COMPITO: modalSelectStudent V */}
                    <Modal className='edubba-modal custom-modal modal-step-2'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalSelectStudent}
                        toggle={this.toggleSelectStudentModal}
                        backdrop='static' >
                        <ModalHeader toggle={this.toggleSelectStudentModal}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3'>
                                        <FontAwesomeIcon icon={faReply} className='icon reply' />
                                    </div>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Creazione Compito
                                </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row className='custom-row'>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        <div className='name'>
                                            NOME COMPITO
                                    </div>
                                        <input className="white-row"
                                            name='projectName'
                                            type="text"
                                            placeholder='Nome Compito'
                                            autoComplete='off'
                                            value={this.state.taskName}
                                            onChange={this.handleTaskNameChange} />
                                    </Col>
                                </Row>
                                <Row className='custom-row'>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        <div className='name'>
                                            COLORE DEL COMPITO
                                    </div>
                                        <div
                                            className="color-shower"
                                            style={{ backgroundColor: this.state.taskColor, cursor: "pointer" }}
                                            onClick={this.toggleColorHandler}
                                        ></div>
                                    </Col>
                                </Row>
                                <Row className='custom-row'>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                        <div className='name'>
                                            SELEZIONARE LO STUDENTE
                                    </div>
                                        <span className='white-row'>
                                            <select
                                                className='legacy-form-control'
                                                name='assignmentType'
                                                value={this.state.selectedStudent}
                                                onChange={this.handleStudentSelect}
                                            >
                                                <option key="key-0" value="" disabled={true}>Seleziona studente...</option>
                                                {Array.isArray(this.state.selectedGroup.group_members) &&
                                                    this.state.selectedGroup.group_members.map((obj, index) => {
                                                        return <option key={obj.user_uuid} value={obj.user_uuid}>
                                                            {obj.user_profile.firstName + " " + obj.user_profile.lastName}
                                                        </option>;
                                                    })}
                                            </select>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12' md='12' sm='12' className='text-center color-edubba '>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="dataInizio" className="date-label">DATA INIZIO</Label>
                                        <DatePicker
                                            name="dataInizio"
                                            className="white-row "
                                            selected={this.state.startDateAssignment}
                                            selectsStart
                                            startDate={this.state.startDateAssignment}
                                            endDate={this.state.endDateAssignment}
                                            onChange={this.handleChangeStartAssignment}
                                            dateFormat={'dd/MM/yyyy'}
                                            placeholderText={'Data Inizio ...'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="dataFine" className="date-label">DATA FINE</Label>
                                        <DatePicker
                                            name="dataFine"
                                            selected={this.state.endDateAssignment}
                                            selectsEnd
                                            className="white-row"
                                            startDate={this.state.startDateAssignment}
                                            endDate={this.state.endDateAssignment}
                                            onChange={this.handleChangeEndAssignment}
                                            dateFormat={'dd/MM/yyyy'}
                                            placeholderText={'Data Fine ...'}
                                        />
                                    </Col>
                                </Row>
                                {this.state.showColorPicker ? <div className="colorpicker-popover">
                                    <div className="colorpicker-cover"
                                        onClick={this.toggleColorHandler} />
                                    <ChromePicker
                                        color={this.state.taskColor}
                                        onChange={this.handleColorChange} />
                                </div> : null}
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.toggleSelectStudentModal}>Annulla</Button>
                            <Button
                                className='btn-edubba'
                                disabled={this.state.taskName === "" || this.state.selectedStudent === ""}
                                onClick={this.createTask}
                            >
                                Assegna Scheda
                                </Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ CREAZIONE COMPITO: modalSelectStudent ^ */}
                    {/* V CREAZIONE CARD: modalCreateNewCard V */}
                    <Modal className='edubba-modal custom-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalCreateNewCard}
                        toggle={this.toggleCreateNewCard}
                        backdrop='static' >
                        <ModalHeader toggle={this.toggleCreateNewCard}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3'>
                                        <i className='pe-7s-note2 icon pe' />
                                    </div>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Creazione Nuova Scheda
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                                <div className='name ask'>
                                    Consultare prima il Catalogo ?
                                </div>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={event => {
                                event.preventDefault();
                                this.toggleCreateNewCard(event);
                                this.props.history.push('/new_card');
                            }}>
                                Crea Nuova Scheda
                            </Button>
                            <Button className='btn-edubba' onClick={(event) => {
                                this.toggleCreateNewCard(event);
                                this.props.history.push('/cardcatalog');
                                // this.toggleCreateNewAssignment(event);
                            }}>
                                Consulta Catalogo
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ CREAZIONE CARD: modalCreateNewCard ^ */}
                    {/* v DELETE CARD: modalDeleteCard v */}
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalDeleteCard}
                        toggle={this.toggleDeleteCardUUID}
                    >
                        <ModalHeader toggle={this.toggleDeleteCardUUID}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        Rimozione di una Scheda
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                Vuoi davvero rimuovere la scheda selezionata? Eventuali arricchimenti saranno ancora fruibili,
                                ma l'operazione non sarà reversibile una volta completata!
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.toggleDeleteCardUUID}>Annulla</Button>
                            <Button
                                className='btn-edubba'
                                onClick={() => this.deleteBaseCard(this.state.modalDeleteCardUUID)}
                                disabled={this.isEmpty(this.state.modalDeleteCardUUID)}
                            >
                                Rimuovi
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ DELETE CARD: modalDeleteCard ^ */}
                    {/* v CONFIRM: modalConfirmToast v */}
                    <Modal className='edubba-modal'
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        size='md'
                        isOpen={this.state.modalConfirmToast}
                        toggle={this.closeModalConfirmToast}>
                        <ModalHeader toggle={this.closeModalConfirmToast}>
                            <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                    <div className='widget-content-left mr-3 text-center w-100'>
                                        {this.state.modalConfirmToastTitle}
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Container className='help-text'>
                                {this.state.modalConfirmToastMessage}
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                        </ModalFooter>
                    </Modal>
                    {/* ^ CONFIRM: modalConfirmToast ^ */}
                    {
                        this.state.loadingCards ?
                            <EdubbaLoader />
                        : null
                    }
                    <Fragment>
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <div className="task-container">
                                    <div className="task-container-title">Le Mie Schede:</div>
                                    <div className="task-container-search">
                                        <InputGroup>
                                            <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.cardSplitButtonOpen} toggle={this.cardToggleSplit}>
                                                <Button onClick={(event) => {
                                                    event.preventDefault();
                                                    this.props.history.push('/new_card');
                                                }}>
                                                    <FontAwesomeIcon className='icon' icon={faPlus} />
                                                </Button>
                                                <Button disabled={true}>
                                                    <b>Pagina: &nbsp;</b>{ this.state.cardPageNumber }
                                                </Button>
                                                { cardPages > 1 ? <DropdownToggle split /> : null }
                                                <DropdownMenu>
                                                    {
                                                        // Gen. pages
                                                        (() => {
                                                            let pages = [];
                                                            for (let i = 1; i <= cardPages; i++) {
                                                                if (this.state.cardPageNumber === i) {
                                                                    continue;
                                                                }
                                                                else pages.push(
                                                                    <DropdownItem
                                                                        key={"card-page-" + i}
                                                                        onClick={() => this.goToCardPage(i)}
                                                                    >
                                                                        Vai alla Pagina: &nbsp;{i}
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            return pages;
                                                        })()
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                            <Input
                                                value={this.state.searchCardValue}
                                                onChange={this.handleCardSearch}
                                                onKeyUp={this.searchCard}
                                                placeholder="Premere invio per cercare..."
                                            />
                                            {
                                                !this.isEmpty(this.state.searchCardValue) ? (
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.clearSearchCards}>
                                                            <FontAwesomeIcon className='icon' icon={faTrash} />
                                                        </Button>
                                                    </InputGroupAddon>
                                                ) : null
                                            }
                                        </InputGroup>
                                    </div>
                                    <div className="task-container-arrow-left">
                                        <Button disabled={this.state.cardPageNumber <= 1} onClick={() => this.goToCardPage(
                                            this.state.cardPageNumber - 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                        </Button>
                                    </div>
                                    <div className="task-container-arrow-right">
                                        <Button disabled={this.state.cardPageNumber >= cardPages} onClick={() => this.goToCardPage(
                                            this.state.cardPageNumber + 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                        </Button>
                                    </div>
                                    <div className="task-container-list">
                                    {
                                        this.state.cards.length > 0 ?
                                            <div className="list-cards-grid">
                                                {
                                                    this.state.cards.map((obj) => {
                                                        if (this.state.filterByPersonal) {
                                                            if (obj.card_is_shared === true) {
                                                                return false;
                                                            }
                                                        }

                                                        if (this.state.filterByShared) {
                                                            if (obj.card_is_shared === false) {
                                                                return false;
                                                            }
                                                        }

                                                        if (this.state.filterByCatalog) {
                                                            if (obj.catalog_ref_uuid === "" ||
                                                                obj.catalog_ref_uuid === null) {
                                                                return false;
                                                            }
                                                        }

                                                        return (
                                                            <Card key={obj.card_uuid}>
                                                                <CardImg
                                                                    top
                                                                    width="100%"
                                                                    src={
                                                                        this.getCardImage(obj.card_image)
                                                                    }
                                                                    onError={this.setDefaultImgSrc}
                                                                />
                                                                <div className="card-status" style={{
                                                                    backgroundColor: (
                                                                        obj.card_is_shared ?
                                                                        "#193d75" : (
                                                                            obj.catalog_ref_uuid !== "" &&
                                                                            obj.catalog_ref_uuid !== null
                                                                        ) ? "#a70000"
                                                                        : "#c67e00"
                                                                    ),
                                                                    color: "#ffffff"
                                                                }}>
                                                                {
                                                                    (obj.card_is_shared ?
                                                                        "Scheda Condivisa con Me" : (
                                                                            obj.catalog_ref_uuid !== "" &&
                                                                            obj.catalog_ref_uuid !== null
                                                                        ) ? "Scheda Personale da Catalogo"
                                                                        : "Scheda Personale Edubba"
                                                                    )
                                                                }
                                                                </div>
                                                                <CardBody>
                                                                    <CardTitle>
                                                                        {obj.card_title}
                                                                    </CardTitle>
                                                                    <CardText>
                                                                        <small className="text-muted">
                                                                            <b>Creatore:</b> {this.getCardOwner(obj)}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Creata:</b> {this.getFromNowDate(obj.card_date_create)}
                                                                        </small>
                                                                        <br />
                                                                        <small className="text-muted">
                                                                            <b>Modificata:</b> {this.getFromNowDate(obj.card_date_update)}
                                                                        </small>
                                                                    </CardText>
                                                                </CardBody>
                                                                <div className='bottom-icons'>
                                                                    <div
                                                                        onClick={(event) => this.toggleReplyCardModal(event, obj, false)}
                                                                        data-tip data-for={"TIPS_FOR_A_" + obj.card_uuid}>
                                                                        <FontAwesomeIcon
                                                                            className='icon reply'
                                                                            icon={faReply}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_A_" + obj.card_uuid}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                            Premere per assegnare la scheda a un compito.
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <div
                                                                        onClick={() => this.goToCardDetail(obj.card_uuid, "V")}
                                                                        data-tip data-for={"TIPS_FOR_V_" + obj.card_uuid}>
                                                                        <FontAwesomeIcon
                                                                            className='icon'
                                                                            icon={faEye}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_V_" + obj.card_uuid}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                            Premere per visualizzare la scheda.
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <div
                                                                        onClick={() => !obj.card_is_shared ? this.goToCardDetail(obj.card_uuid, "E") : null}
                                                                        data-tip data-for={"TIPS_FOR_E_" + obj.card_uuid}>
                                                                        <FontAwesomeIcon
                                                                            className='icon'
                                                                            icon={faEdit}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_E_" + obj.card_uuid}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                            {!obj.card_is_shared ? "Premere per editare la scheda." : "Non è posisbile editare le schede condivise da un progetto."}
                                                                        </ReactTooltip>
                                                                    </div>
                                                                    <div className="red-action-btn"
                                                                        onClick={() => !obj.card_is_shared ? this.toggleDeleteCardUUID(obj.card_uuid) : null}
                                                                        data-tip data-for={"TIPS_FOR_DELETE_" + obj.card_uuid}>
                                                                        <FontAwesomeIcon
                                                                            className='icon'
                                                                            icon={faTrash}
                                                                        />
                                                                        <ReactTooltip
                                                                            id={"TIPS_FOR_DELETE_" + obj.card_uuid}
                                                                            wrapper="span"
                                                                            place="top"
                                                                            effect="solid"
                                                                            className="tooltip-user">
                                                                                {!obj.card_is_shared ? "ATTENZIONE: Permette la rimozione della scheda!" : "Non è posisbile rimuovere schede condivise da un progetto."}
                                                                        </ReactTooltip>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : (
                                            <Row>
                                                <Col lg='12' md='12' sm='12'>
                                                    <Alert color="light" className="mt-3">
                                                        <h4 className="alert-heading">Nessuna scheda trovata!</h4>
                                                        <p>
                                                            Non è ancora stata creata una scheda su cui lavorare.<br />
                                                            Appena creata la scheda sarà visibile nella sezione corrente.
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0">
                                                            Premi sul tasto (+) disponibile nella pagina o su "Nuova Scheda" dal menù a lato.
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
            </Fragment >
        )
    }
}

export default withRouter(Cards);
