import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from '../globalVars.json';
import ReactHtmlParser from 'react-html-parser';
import {
    Row, Col, Button, Container, Form, Input, FormGroup, Label,
    Modal, ModalHeader, ModalBody
} from 'reactstrap';
import EdubbaLoader from '../components/EdubbaLoader/EdubbaLoader';
import moment from 'moment';
import 'moment/locale/it';
import holdDataService from '../services/holdDataService/holdDataService';
import {
    faPlus,
    faUserPlus,
    faTrash,
    faArrowCircleLeft,
    faArrowCircleRight,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupButtonDropdown from 'reactstrap/lib/InputGroupButtonDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Alert from 'reactstrap/lib/Alert';
import './Students.scss';
import Table from 'reactstrap/lib/Table';
import Badge from 'reactstrap/lib/Badge';
import ModalFooter from 'reactstrap/lib/ModalFooter';

class Students extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentInstitutes: [],
            currentStudents: [],
            studentsLoading: true,

            //----------------------------------------------| DELETE USER

            modalDeleteUser: false,
            modalDeleteUserUUID: null,

            //----------------------------------------------| CREATE USER

            institute: null,
            firstName: '',
            lastName: '',
            userName: '',
            password_suggest: '',
            disclaimer_accept: false,

            errorMessage: null,
            modalCreateStudent: false,

            //----------------------------------------------| SEARCH

            searchUserValue: "",
            userInstituteCodeFilter: "",
            userCatNameFilter: "",

            //----------------------------------------------| PAGING

            userCatSplitButtonOpen: false,
            userSplitButtonOpen: false,
            userTotalEntities: 0,
            userPageLimit: 15,
            userPageNumber: 1,
        };

    }

    componentWillMount() {
        this.props.setSectionTitle('Anagrafica delle Classi');
        this.loadInstitutes();
        this.loadStudents();
    }

    loadInstitutes = () => {
        let query = '/institutes';
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data.rowCount > 0) {
                this.setState({
                    currentInstitutes: res.data.rows
                });
            } else {
                this.setState({
                    currentInstitutes: []
                });
            }
        }, err => {
            this.setState({
                currentInstitutes: []
            });
        });
    }

    loadStudents = (searchValue = null, filterByCategory = null) => {
        this.setState({ studentsLoading: true })
        let query = '/institutes/users?userType=RBAC-RLCD-N0000003&limit=' + this.state.userPageLimit + '&page=' + this.state.userPageNumber;
        if (filterByCategory) {
            query += '&filterCategory=' + filterByCategory;
        }
        if (searchValue) {
            query += '&searchBy=' + searchValue;
        }
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            if (res.data && res.data.rowCount > 0) {
                this.setState({
                    userTotalEntities: res.data.rowCount,
                    currentStudents:  res.data.rows,
                    studentsLoading:  false
                });
            }
            else {
                this.setState({
                    userPageNumber:    1,
                    userTotalEntities: 0,
                    currentStudents:  [],
                    studentsLoading:  false
                });
            }
        }, err => {
            this.setState({
                userPageNumber:    1,
                userTotalEntities: 0,
                currentStudents:  [],
                studentsLoading:  false
            });
        });
    }

    isEmpty = (data) => {
        if (Array.isArray(data) &&
            data.length > 0) {
            return false;
        }
        else if (
            data &&
            data !== "" &&
            data !== 0) {
            return false;
        }

        return true;
    }

    getFromNowDate = (curDate) => {
        if (!isNaN(curDate) &&
            curDate > 0) {
            return moment(curDate).fromNow();
        }

        return "Non disponible...";
    }

    userCatToggleSplit = () => {
        this.setState({
            userCatSplitButtonOpen: !this.state.userCatSplitButtonOpen
        });
    }

    setUserCatFilter = (categoryCode, categoryName) => {
        this.setState({
            userInstituteCodeFilter: categoryCode,
            userCatNameFilter: categoryName
        }, () => {
            this.loadStudents(
                this.state.searchUserValue,
                this.state.userInstituteCodeFilter
            );
        });
    }

    goToUserPage = (pageNumber) => {
        this.setState({
            userPageNumber: pageNumber
        }, () => {
            this.loadStudents(
                this.state.searchUserValue,
                this.state.userInstituteCodeFilter
            );
        });
    }

    userToggleSplit = () => {
        this.setState({
            userSplitButtonOpen: !this.state.userSplitButtonOpen
        });
    }

    handleUserSearch = (ev) => {
        this.setState({
            searchUserValue: ev.target.value
        })
    }

    searchUser = (ev) => {
        const keyCode = ev.which || ev.keyCode;
        if (keyCode === 13) {
            this.loadStudents(
                this.state.searchUserValue,
                this.state.userInstituteCodeFilter
            );
        }
    }

    clearSearchUsers = () => {
        this.setState({
            searchUserValue: "",
            userPageNumber: 1
        }, () => {
            this.loadStudents();
        });
    }

    toggleDeleteUser = (user_uuid = null) => {
        this.setState({
            modalDeleteUser:     user_uuid ? true : false,
            modalDeleteUserUUID: user_uuid
        })
    }

    deleteUser = () => {
        this.setState({ studentsLoading: true })
        let query = '/institutes/users/' + this.state.modalDeleteUserUUID;
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            // Check
            if (res && res.data && res.data.rowCount > 0) {
                this.setState({
                    studentsLoading: false,
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Studente",
                    modalConfirmToastMessage: "Studente selezionato rimosso con successo dal proprio istituto!",
                    modalDeleteUser: false,
                    modalDeleteUserUUID: null
                }, () => {
                    // Refresh
                    this.loadStudents();
                });
            } else {
                this.setState({
                    studentsLoading: false,
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione Studente",
                    modalConfirmToastMessage: "Impossibile completare la rimozione dello studente selezionato: verificare che non sia associato a qualche attività, gruppo o classe.",
                    modalDeleteUser: false,
                    modalDeleteUserUUID: null
                });
            }
        }, err => {
            // Error
            this.setState({
                studentsLoading: false,
                modalConfirmToast: true,
                modalConfirmToastTitle: "Rimozione Studente",
                modalConfirmToastMessage: err.message,
                modalDeleteUser: false,
                modalDeleteUserUUID: null
            });
        });
    }

    toggleCreateStudent = () => {
        this.setState({
            modalCreateStudent: !this.state.modalCreateStudent,
            institute: (() => {
                // If only ONE, auto-select
                if (Array.isArray(this.state.currentInstitutes) &&
                    this.state.currentInstitutes.length === 1 &&
                    this.state.currentInstitutes[0].institute_uuid) {
                    return this.state.currentInstitutes[0].institute_uuid;
                }
                // Delegate user
                else {
                    return null;
                }
            })(),
            firstName: '',
            lastName: '',
            userName: '',
            password_suggest: '',
            disclaimer_accept: false
        })
    }

    createUser = (event) => {
        event.preventDefault();
        this.setState({ studentsLoading: true })

        let postBody = {
            "user_name": this.state.userName,
            "user_role": globalVars.UserRoles.StudentRoleUUID,
            "first_name": this.state.firstName,
            "last_name": this.state.lastName,
            "institute_uuid": this.state.institute,
            "password_suggest": this.state.password_suggest
        }

        let query = '/users';
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
        postBody,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            // Close and send
            this.setState({
                modalCreateStudent: false,
                institute: null,
                firstName: '',
                lastName: '',
                userName: '',
                password_suggest: '',
                disclaimer_accept: false
            }, () => {
                // Check
                if (res && res.data && res.data.rowCount > 0) {
                    this.setState({
                        studentsLoading: false,
                        modalConfirmToast: true,
                        modalConfirmToastTitle: "Creazione Studente",
                        modalConfirmToastMessage: "Studente creato con successo nel proprio istituto!"
                    }, () => {
                        // Refresh
                        this.loadStudents();
                    });
                } else {
                    this.setState({
                        studentsLoading: false,
                        modalConfirmToast: true,
                        modalConfirmToastTitle: "Creazione Studente",
                        modalConfirmToastMessage: (
                            (res.data.message && res.data.message.includes("duplicate key")) ?
                            "Impossibile completare la creazione dello studente: la mail '" + this.state.userName + "' è già presente in piattaforma." :
                            "Impossibile completare la creazione dello studente: riprovare verificando la correttezza dei dati inseriti."
                        )
                    });
                }
            });
        }, err => {
            // Close and send
            this.setState({
                modalCreateStudent: false,
                institute: null,
                firstName: '',
                lastName: '',
                userName: '',
                password_suggest: '',
                disclaimer_accept: false
            }, () => {
                // Error
                this.setState({
                    studentsLoading: false,
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Creazione Studente",
                    modalConfirmToastMessage: err.message
                });
            });
        });
    }

    handleUserCreateChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
            errorMessage: null
        });
    }

    handleUserCreateChkChange = (event) => {
        this.setState({
            disclaimer_accept: !this.state.disclaimer_accept,
            errorMessage: null
        });
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    render() {
        let classPages = Math.ceil(
            this.state.userTotalEntities /
            this.state.userPageLimit
        );

        classPages = (classPages <= 0) ? 1 : classPages;

        return (
            <Fragment>
                <Container className='Students'>
                    {
                        this.state.studentsLoading ?
                            <EdubbaLoader />
                        : null
                    }
                    <Fragment>
                        <Row>
                            <Col lg='12' md='12' sm='12'>
                                <div className="task-container">
                                    <div className="task-container-title">I Miei Studenti:</div>
                                    <div className="task-container-search">
                                        <InputGroup>
                                            <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.userSplitButtonOpen} toggle={this.userToggleSplit}>
                                                {
                                                    holdDataService.loggedUserIsTutor() ? (
                                                        <Button onClick={(event) => {
                                                            event.preventDefault();
                                                            this.toggleCreateStudent()
                                                        }}>
                                                            <FontAwesomeIcon className='icon' icon={faPlus} />
                                                        </Button>
                                                    ) : null
                                                }
                                                <Button disabled={true}>
                                                    <b>Pagina: &nbsp;</b>{ this.state.userPageNumber }
                                                </Button>
                                                { classPages > 1 ? <DropdownToggle split /> : null }
                                                <DropdownMenu>
                                                    {
                                                        // Gen. pages
                                                        (() => {
                                                            let pages = [];
                                                            for (let i = 1; i <= classPages; i++) {
                                                                if (this.state.userPageNumber === i) {
                                                                    continue;
                                                                }
                                                                else pages.push(
                                                                    <DropdownItem
                                                                        key={"card-page-" + i}
                                                                        onClick={() => this.goToUserPage(i)}>
                                                                        Vai alla Pagina: &nbsp;{i}
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            return pages;
                                                        })()
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                            <Input
                                                value={this.state.searchUserValue}
                                                onChange={this.handleUserSearch}
                                                onKeyUp={this.searchUser}
                                                placeholder="Premere invio per cercare..."
                                            />
                                            {
                                                !this.isEmpty(this.state.searchUserValue) ? (
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.clearSearchUsers}>
                                                            <FontAwesomeIcon className='icon' icon={faTrash} />
                                                        </Button>
                                                    </InputGroupAddon>
                                                ) : null
                                            }
                                            <InputGroupButtonDropdown
                                                className="task-container-category-filter"
                                                addonType="append"
                                                isOpen={this.state.userCatSplitButtonOpen}
                                                toggle={this.userCatToggleSplit}>
                                                <DropdownToggle split>
                                                    {
                                                        this.state.userInstituteCodeFilter !== "" &&
                                                        this.state.userCatNameFilter !== "" ?
                                                        "Filtra: " + this.state.userCatNameFilter :
                                                        "Tutti gli Istituti"
                                                    } &nbsp;
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        key={"class-category-" + 0}
                                                        onClick={() => this.setUserCatFilter("")}
                                                    >
                                                        Visualizza: &nbsp;Tutte
                                                    </DropdownItem>
                                                    {
                                                        this.state.currentInstitutes.map((item, index) => {
                                                            return (
                                                                <DropdownItem
                                                                    key={"class-category-" + (index + 1)}
                                                                    onClick={() => this.setUserCatFilter(item.institute_uuid, item.institute_name)}>
                                                                    Visualizza: &nbsp;{item.institute_name}
                                                                </DropdownItem>
                                                            );
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </InputGroupButtonDropdown>
                                        </InputGroup>
                                    </div>
                                    <div className="task-container-arrow-left">
                                        <Button disabled={this.state.userPageNumber <= 1} onClick={() => this.goToUserPage(
                                            this.state.userPageNumber - 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleLeft} />
                                        </Button>
                                    </div>
                                    <div className="task-container-arrow-right">
                                        <Button disabled={this.state.userPageNumber >= classPages} onClick={() => this.goToUserPage(
                                            this.state.userPageNumber + 1
                                        )}>
                                            <FontAwesomeIcon className='icon' icon={faArrowCircleRight} />
                                        </Button>
                                    </div>
                                    <div className="task-container-list">
                                    {
                                        this.state.currentStudents.length > 0 ?
                                            <div className="list-class-table">
                                                <Table striped>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th style={{width: "20%"}}>Email</th>
                                                            <th style={{width: "20%"}}>Cognome</th>
                                                            <th style={{width: "20%"}}>Nome</th>
                                                            <th style={{width: "20%"}}>Classi</th>
                                                            <th style={{width: "20%"}}>Gruppi</th>
                                                            <th style={{minWidth: "65px"}}>Azioni</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.currentStudents.map((obj, index) => {
                                                            return (
                                                                <tr key={"tr-key-" + (index + 1)}>
                                                                    <th scope="row">{index +1}</th>
                                                                    <td>{obj.user_name}</td>
                                                                    <td>{(
                                                                        obj.user_profile &&
                                                                        obj.user_profile.lastName
                                                                    ) ? obj.user_profile.lastName : "- - -"}</td>
                                                                    <td>{(
                                                                        obj.user_profile &&
                                                                        obj.user_profile.firstName
                                                                    ) ? obj.user_profile.firstName : "- - -"}</td>
                                                                    <td>{(
                                                                            Array.isArray(obj.user_classrooms) &&
                                                                            obj.user_classrooms.length > 0
                                                                        ) ?
                                                                            <Badge color="success" style={{
                                                                                backgroundColor: "#008440"
                                                                            }}>PRESENTI: {obj.user_classrooms.length}</Badge> :
                                                                            <Badge color="danger">NON PRESENTI</Badge>
                                                                    }</td>
                                                                    <td>{(
                                                                            Array.isArray(obj.user_groups) &&
                                                                            obj.user_groups.length > 0
                                                                        ) ?
                                                                            <Badge color="success" style={{
                                                                                backgroundColor: "#008440"
                                                                            }}>PRESENTI: {obj.user_groups.length}</Badge> :
                                                                            <Badge color="danger">NON PRESENTI</Badge>
                                                                    }</td>
                                                                    <td className="bottom-icons">
                                                                        <div className="red-action-btn"
                                                                            onClick={() => this.toggleDeleteUser(obj.user_uuid)}
                                                                            data-tip data-for={"TIPS_FOR_DELETE_" + obj.user_uuid}>
                                                                            <FontAwesomeIcon
                                                                                className='icon'
                                                                                icon={faTrash}
                                                                            />
                                                                            <ReactTooltip
                                                                                id={"TIPS_FOR_DELETE_" + obj.user_uuid}
                                                                                wrapper="span"
                                                                                place="top"
                                                                                effect="solid"
                                                                                className="tooltip-user">
                                                                                    ATTENZIONE: Permette la rimozione dello studente!
                                                                            </ReactTooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        : (
                                            <Row>
                                                <Col lg='12' md='12' sm='12'>
                                                    <Alert color="light" className="mt-3">
                                                        <h4 className="alert-heading">Nessun studente trovato!</h4>
                                                        <p>
                                                            Non è ancora stato creato uno studente su cui lavorare.<br />
                                                            Appena creato sarà visibile nella sezione corrente.
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0">
                                                            Premi sul tasto "+" presente a lato della barra di ricerca.
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
                {/* v CONFIRM: modalConfirmToast v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalConfirmToast}
                    toggle={this.closeModalConfirmToast}>
                    <ModalHeader toggle={this.closeModalConfirmToast}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    {this.state.modalConfirmToastTitle}
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            {this.state.modalConfirmToastMessage}
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                    </ModalFooter>
                </Modal>
                {/* ^ CONFIRM: modalConfirmToast ^ */}
                {/* Modale per la rimozione di studenti */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalDeleteUser}
                    toggle={() => this.toggleDeleteUser()}>
                    <ModalHeader toggle={() => this.toggleDeleteUser()}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon className='icon' icon={faUsers} />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Cancellazione dello Studente
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            Vuoi davvero rimuovere lo studente selezionato? L'operazione non sarà possibile se lo studente è coinvolto in qualche attività!
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={(event) => {
                            event.preventDefault();
                            this.toggleDeleteUser()
                        }}>Annulla</Button>
                        <Button className='btn-edubba' onClick={this.deleteUser}>Elimina Studente</Button>
                    </ModalFooter>
                </Modal>
                {/* Modale per la modifica di una classe */}
                <Modal className='edubba-modal add-user-modal create-new-user-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalCreateStudent}
                    toggle={() => this.toggleCreateStudent()}
                    backdrop='static' >
                    <ModalHeader toggle={() => this.toggleCreateStudent()}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3'>
                                    <FontAwesomeIcon className='icon' icon={faUserPlus} />
                                </div>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Creazione Studente
                            </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            {this.state.loading ? <EdubbaLoader small={true} /> :
                                <Form autoComplete="new-off">
                                    <Row>
                                        <Col lg='12' md='12' sm='12' className='text-center color-edubba'>
                                            <div className='name'>
                                                SELEZIONA ISTITUTO DELLO STUDENTE
                                            </div>
                                            <select
                                                className='legacy-form-control'
                                                name='institute'
                                                value={this.state.institute ? this.state.institute : ""}
                                                onChange={this.handleUserCreateChange}>
                                                <option key={"inst-0"} value="" disabled={true}>Selezionare un istituto...</option>
                                                {
                                                    this.state.currentInstitutes.map((item, index) => {
                                                        return (
                                                            <option key={"inst-" + index} value={item.institute_uuid}>{item.institute_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                    {this.state.institute ? (
                                        <Row className='create-user-form'>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label className='name' > NOME </Label>
                                                    <Input type="text" name="firstName" className='white-row' autoComplete='new-off' required
                                                        placeholder="Inserire il Nome..."
                                                        onChange={this.handleUserCreateChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label className='name' > COGNOME </Label>
                                                    <Input type="text" name="lastName" className='white-row' autoComplete='new-off' required
                                                        placeholder="Inserire il Cognome..."
                                                        onChange={this.handleUserCreateChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label className='name' > EMAIL </Label>
                                                    <Input type="email" name="userName" className='white-row' autoComplete='new-off' required
                                                        placeholder="Inserire l'email..."
                                                        onChange={this.handleUserCreateChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label className='name' > PASSWORD </Label>
                                                    <Input type="password" name="password_suggest" className='white-row' autoComplete='new-off' required
                                                        placeholder="Inserire una password ..."
                                                        onChange={this.handleUserCreateChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12} style={{textAlign: "left"}}>
                                                <FormGroup check>
                                                    <Label check style={{cursor:"pointer"}}>
                                                        <Input type="checkbox" name="disclaimer_accept" className='white-row' autoComplete='new-off' required onChange={this.handleUserCreateChkChange} />
                                                        Dichiaro di aver letto e accettato: "<a href="/disclaimer/DSCLMR-T02" className="link-disclaimer" target="_blank">Termini e Condizioni d'Uso</a>".
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            {this.isEmpty(this.state.errorMessage) ? (
                                                <Col md={12}>
                                                    <div className='text-center text-danger name error'>
                                                        {ReactHtmlParser(this.state.errorMessage)}
                                                    </div>
                                                </Col>
                                            ) : null}
                                        </Row>
                                    ): null}
                                </Form>
                            }
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={(event) => {
                            event.preventDefault();
                            this.toggleCreateStudent()
                        }}>Annulla</Button>
                        <Button className='btn-edubba' onClick={this.createUser} disabled={
                            this.isEmpty(this.state.institute) ||
                            this.isEmpty(this.state.firstName) ||
                            this.isEmpty(this.state.lastName) ||
                            this.isEmpty(this.state.userName) ||
                            this.isEmpty(this.state.password_suggest) ||
                            !this.state.disclaimer_accept
                        }>Crea Studente</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

export default withRouter(Students);
