import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Alert
} from 'reactstrap';
import {
    faDownload,
    faEye,
    faNewspaper,
    faExclamationTriangle,
    faFileDownload,
    faCodeBranch
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PlatformUpdate27042021 from './Contents/platform_update_27042021';
import ContentPDFDii02122019 from './Contents/Content_pdf_dii_02122019';
import ContentAvvisoCovid1928022020 from './Contents/Content_avviso_covid19_28022020';
import ContentDatabencMaterialeTP15042020 from './Contents/Content_avviso_materiale_tp_databenc_15042020';
import ContentDesiderioDArte18052020 from './Contents/Content_pdf_aoodrca_18052020';
import ContentModelloConvenzione11012021 from './Contents/Content_pdf_aoodrca_18052020';
import ConcorsoDesiderioArteEdubba17052021 from './Contents/concorso_desiderio_arte_edubba_17052021';
import MonitoraggioUtilizzoEdubba16062021 from './Contents/monitoraggio_utilizzo_edubba_16062021';

import NewsJSON from './News.json';
import './News.scss';

class News extends Component {
    constructor(props) {
        super(props);

        this.reactContentObjects = {
            "monitoraggio_utilizzo_edubba_16062021": {
                "icon": faNewspaper,
                "data": <MonitoraggioUtilizzoEdubba16062021 />
            },
            "concorso_desiderio_arte_edubba_17052021": {
                "icon": faFileDownload,
                "data": <ConcorsoDesiderioArteEdubba17052021 />
            },
            "versione_edubba_27042021": {
                "icon": faCodeBranch,
                "data": <PlatformUpdate27042021 />
            },
            "modello_convenzione_edubba_26012021": {
                "icon": faFileDownload,
                "data": <ContentModelloConvenzione11012021 />
            },
            "aoodrca_registro_ufficiale_18052020": {
                "icon": faFileDownload,
                "data": <ContentDesiderioDArte18052020 />
            },
            "content_materiale_databenc_15042020": {
                "icon": faNewspaper,
                "data": <ContentDatabencMaterialeTP15042020 />
            },
            "content_avviso_covid19_28022020": {
                "icon": faExclamationTriangle,
                "data": <ContentAvvisoCovid1928022020 />
            },
            "pdf_dii_02122019": {
                "icon": faFileDownload,
                "data": <ContentPDFDii02122019 />
            }
        };

        this.state = {
            activeStaticContent: null,
            news: NewsJSON.OrderBy.map((code) => {
                if (NewsJSON.Content[code] &&
                    !NewsJSON.Content[code].flash) {
                    this.reactContentObjects[code] = {
                        "code": code,
                        ...{
                            ...NewsJSON.Content[code],
                            ...this.reactContentObjects[code]
                        }
                    };
                    return this.reactContentObjects[code];
                } else {
                    return null;
                }
            }).filter(x => x)
        };
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].className = 'visibilities';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '0px';

        let setTitle = 'Avvisi e News';
        this.props.setSectionTitle(setTitle);

        let getContentCode = this.props.match.params.content_code;

        if (NewsJSON.OrderBy.includes(getContentCode)) {
            this.setState({
                activeStaticContent: getContentCode
            })
        }
        else this.props.history.push("/news");
    }

    componentWillReceiveProps (nextProps) {
        if (this.state.activeStaticContent !== nextProps.match.params.content_code) {
            let getContentCode = nextProps.match.params.content_code;
            if (NewsJSON.OrderBy.includes(getContentCode)) {
                this.setState({
                    activeStaticContent: getContentCode
                })
            }
            else {
                this.setState({
                    activeStaticContent: null
                })
            }
        }

        return null;
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByClassName('app-main__inner')[0].style.padding = '32px';
    }

    render() {
        return (
            <Fragment>
                <Container className='News mt-5 mb-5'>
                    {
                        this.state.activeStaticContent !== null ?
                            <Row>
                                <Col lg='12' md='12' sm='12'>
                                    {
                                        this.reactContentObjects[
                                            this.state.activeStaticContent
                                        ].data
                                    }
                                </Col>
                            </Row>
                        : this.state.news.length > 0 ?
                            <Fragment>
                                <Row>
                                    <Col lg='12' md='12' sm='12'>
                                        <Alert color="light">
                                            <h4 className="alert-heading">Contatti per Supporto:</h4>
                                            <hr />
                                            <p>
                                                <br />
                                                <h5>Per mezzo Mail</h5>
                                                <ul>
                                                    <li><b>Supporto/Segnalazioni: </b> <a className="support-link-footer" href="mailto:support.edubba@databenc.it">support.edubba@databenc.it</a></li>
                                                </ul>
                                                <br />
                                                <h5>Per mezzo Skype</h5>
                                                <ul>
                                                    <li><b>Invito Skype: </b> <a className="support-link-footer" href="https://join.skype.com/invite/BYwqFomOKh9T" target="_blank" rel="noopener noreferrer">https://join.skype.com/invite/BYwqFomOKh9T</a></li>
                                                    <li><b>Disponibilità: </b> Martedi e Giovedì dalle ore 10:00 alle 12:30</li>
                                                </ul>
                                            </p>
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12' md='12' sm='12'>
                                        <div className="list-news-grid">
                                            {
                                                this.state.news.map((obj) => {
                                                    return (
                                                        <Card key={obj.code}>
                                                            <div className="cadashboard-table">
                                                                <div className="cadashboard-table-cell">
                                                                    <FontAwesomeIcon icon={obj.icon} />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="card-status"
                                                                style={{
                                                                    backgroundColor: (obj.ispdf ? "#193d75" : "#c67e00"),
                                                                    color: "#ffffff"
                                                                }}>
                                                                {(obj.ispdf ? "MODULO PDF" : "AVVISO")}
                                                            </div>
                                                            <CardBody>
                                                                <CardTitle>
                                                                    {obj.title}
                                                                </CardTitle>
                                                                <CardText>
                                                                    <small className="text-muted">
                                                                        <b>Autore:</b> {obj.author}
                                                                    </small>
                                                                    <br />
                                                                    <small className="text-muted">
                                                                        <b>Creata:</b> {obj.date}
                                                                    </small>
                                                                </CardText>
                                                            </CardBody>
                                                            <div className={'bottom-icons' + (
                                                                (obj.url !== "" && obj.url !== null && obj.hasdata) ? '' : ' collapse-single-fr'
                                                            )}>
                                                                {obj.hasdata ?
                                                                    <Link to={"/news/" + obj.code}>
                                                                        <div
                                                                            data-tip data-for={"TIPS_FOR_V_" + obj.code}>
                                                                            <FontAwesomeIcon
                                                                                className='icon'
                                                                                icon={faEye}
                                                                            />
                                                                            <ReactTooltip
                                                                                id={"TIPS_FOR_V_" + obj.code}
                                                                                wrapper="span"
                                                                                place="top"
                                                                                effect="solid"
                                                                                className="tooltip-user">
                                                                                Premere per visualizzare la notizia.
                                                                            </ReactTooltip>
                                                                        </div>
                                                                    </Link>
                                                                : null}
                                                                {
                                                                    (obj.url !== "" && obj.url !== null) ?
                                                                        <Link to={obj.url} target="_blank" download>
                                                                            <div
                                                                                data-tip data-for={"TIPS_FOR_E_" + obj.code}>
                                                                                <FontAwesomeIcon
                                                                                    className='icon'
                                                                                    icon={faDownload}
                                                                                />
                                                                                <ReactTooltip
                                                                                    id={"TIPS_FOR_E_" + obj.code}
                                                                                    wrapper="span"
                                                                                    place="top"
                                                                                    effect="solid"
                                                                                    className="tooltip-user">
                                                                                    Premere per scaricare l'allegato della notizia.
                                                                                </ReactTooltip>
                                                                            </div>
                                                                        </Link>
                                                                    : null
                                                                }
                                                            </div>
                                                        </Card>
                                                    );
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        : (
                            <Row>
                                <Col lg='12' md='12' sm='12'>
                                    <Alert color="light" className="mt-5">
                                        <h4 className="alert-heading">Nessun Avviso o News disponibili!</h4>
                                        <p>
                                            Non sono ancora stati creati Avvisi o News da poter visionare.<br />
                                            Appena saranno disponibili saranno elencati nella sezione corrente.
                                        </p>
                                    </Alert>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </Fragment>
        )
    }
}

export default withRouter(News);
