import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import globalVars from './../../globalVars.json';
import holdDataService from '../../services/holdDataService/holdDataService';

import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link';

import {
    Button,
    Card,
    CardHeader,
    ListGroupItem,
    ListGroup,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    ModalFooter,
    FormGroup,
    Label
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './MyProjects.scss';

import EdubbaLoader from './../../components/EdubbaLoader/EdubbaLoader';
import moment from 'moment';
import 'moment/locale/it';
import {
    faPlus,
    faShareAlt,
    faShare,
    faTrash
} from '@fortawesome/free-solid-svg-icons';

class MyProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            dropdownOpen: false,
            loading: false,
            projects: [],
            // certifier
            modalShareCertifier: false,
            selectedProjectToEvaluate: null,
            selectedEvaluatorModal: "",
            evaluatorsList: [],
            // teacher
            modalShareTeacher: false,
            selectedProjectToShare: null,
            selectedTeacherModal: "",
            teachersList: [],
            // confirm
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "...",
            // delete project
            modalDeleteProject: false,
            selectedProjectToDelete: null,
        };

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ projects: nextProps.projects });
    }

    // Fix notify refresh!
    shouldComponentUpdate(nextProps, nextState) {
        return (
            JSON.stringify(this.state) !== JSON.stringify(nextState)
        );
    }

    /*componentDidMount() {
        this.loadProjects();
    }

    loadProjects = () => {
        let query = '/projects';
        this.setState({ loading: true })
        axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + query,
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let projects = [];
                if (res.data.rowCount > 0) {
                    projects = res.data.rows
                }
                this.setState({
                    projects: projects,
                    loading: false
                }, this.props.updateProjects(projects))
            }, err => {
                this.setState({ loading: false })
            });
    }*/

    formAssignmentsTree(assignments) {
        let schedaturaTree = {};
        schedaturaTree.label = 'Arricchimento Schede';
        schedaturaTree.content = [];
        let artTourTree = {};
        artTourTree.label = 'Art-Tour';
        artTourTree.content = [];
        for (let i = 0; i < assignments.length; i++) {
            let currentAssignment = assignments[i];
            currentAssignment.label = currentAssignment.assign_descr;
            currentAssignment.to = '/assignments/' + currentAssignment.assign_uuid;
            if (currentAssignment.assign_type === "STSC0001") {
                schedaturaTree.content.push(currentAssignment);
            }
            else if (currentAssignment.assign_type === "STSC0002") {
                artTourTree.content.push(currentAssignment);
            }
        }
        let tree = [];
        if (schedaturaTree.content.length > 0) {
            tree.push(schedaturaTree);
        }
        if (artTourTree.content.length > 0) {
            tree.push(artTourTree);
        }
        return tree;
    }

    getProjectOwner(project) {
        if (Array.isArray(project.project_owners)) {
            for (let i = 0; i < project.project_owners.length; i++) {
                let currentOwner = project.project_owners[i];
                if (currentOwner.user_uuid === holdDataService.getLoggedUserUuid()) {
                    return "Io";
                }
                else {
                    return currentOwner.user_profile.firstName + " " + currentOwner.user_profile.lastName;
                }
            }
        }
    }

    isProjectShared(project) {
        if (Array.isArray(project.project_owners)) {
            for (let i = 0; i < project.project_owners.length; i++) {
                let currentOwner = project.project_owners[i];
                if (currentOwner.user_uuid === holdDataService.getLoggedUserUuid()) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
    }

    getProjectsHTML = () => {
        let projects = this.state.projects;
        let projectList = [];
        projects.map((project, index) => {
            let itsOver = moment(new Date().getTime()).isAfter(moment(project.project_date_end));
            projectList.push(
                <ListGroupItem key={`project-${index}`}>
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper table-layout bounce-in">
                            <div className="widget-content-left">
                                <div className="widget-heading">
                                    {project.project_name}
                                </div>
                                <div className="widget-subheading">
                                    {itsOver ?
                                        'Terminato' :
                                        'Scade ' + moment(project.project_date_end).endOf('day').fromNow()
                                    }
                                </div>
                            </div>
                            <div className="widget-content-left">
                                {project.project_assignments && project.project_assignments.length > 0 ?
                                    <MetisMenu content={this.formAssignmentsTree(project.project_assignments)}
                                        LinkComponent={RouterLink}
                                        className="vertical-nav-menu"
                                        iconNamePrefix=""
                                        classNameStateIcon="pe-7s-angle-down"
                                    />
                                    : <Button className='btn-assignment' onClick={event => {
                                        event.preventDefault();
                                        this.props.createAssignment(project);
                                    }}
                                    >
                                        Crea Attività <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                }
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-heading">
                                    Gruppi:
                                </div>
                                <div className="widget-subheading">
                                    {project.project_groups === 0 ? "Nessun Gruppo" : project.project_groups}
                                </div>
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-heading ">
                                    Tutor:
                                </div>
                                <div className="widget-subheading">
                                    {this.getProjectOwner(project)}
                                    {/* <UserPopover key={2} id={2} userId="Mario Rossi" /> */}
                                </div>
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-subheading">
                                    {
                                        !this.isProjectShared(project) ?
                                            <Fragment>
                                                <FontAwesomeIcon
                                                    icon={faShareAlt}
                                                    className="share-icon"
                                                    data-tip data-for="share-project-with-teacher"
                                                    onClick={() => this.toggleShareWithTeacher(project.project_uuid)}
                                                />
                                                <ReactTooltip
                                                    id="share-project-with-teacher"
                                                    wrapper="span"
                                                    place="top"
                                                    effect="solid"
                                                    className="tooltip-user"
                                                >
                                                    Condividi Schede di Progetto con un Docente
                                                </ReactTooltip>
                                                <FontAwesomeIcon
                                                    icon={faShare}
                                                    className="share-icon"
                                                    data-tip data-for="share-project-with-certfier"
                                                    onClick={() => this.toggleShareWithCertifier(project.project_uuid)}
                                                />
                                                <ReactTooltip
                                                    id="share-project-with-certfier"
                                                    wrapper="span"
                                                    place="top"
                                                    effect="solid"
                                                    className="tooltip-user"
                                                >
                                                    Assegna al Progetto un Certificatore
                                                </ReactTooltip>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="share-icon"
                                                    data-tip data-for="trash-project"
                                                    onClick={() => this.toggleDeleteProjectUUID(project.project_uuid)}
                                                />
                                                <ReactTooltip
                                                    id="trash-project"
                                                    wrapper="span"
                                                    place="top"
                                                    effect="solid"
                                                    className="tooltip-user"
                                                >
                                                    ATTENZIONE: Permette la rimozione di un progetto!
                                                </ReactTooltip>
                                            </Fragment>
                                        : "Condiviso con te!"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            )
            return true;
        });

        if (projectList.length > 0) return projectList;
        else return (
            <Alert color="light" className="mt-3 ml-3 mr-3">
                <h5 className="alert-heading">Nessun progetto avviato!</h5>
                <hr />
                <p className="mb-0">
                    Non risultano progetti attivi per il tuo profilo.<br />
                    Puoi iniziare un nuovo progetto mediante il tasto dedicato ad inizio pagina.<br />
                    Creato, il progetto sarà visionabile in questa sezione.
                </p>
            </Alert>
        )
    }

    toggleShareWithTeacher = (projectUUID) => {
        if (!this.state.modalShareTeacher) {
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/users?role_code=RBAC-RLCD-N0000002",
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let users = [];
                if (res.data.rowCount > 0) {
                    users = res.data.rows
                }
                this.setState({
                    teachersList: users,
                    selectedProjectToShare: projectUUID,
                    modalShareTeacher: !this.state.modalShareTeacher
                });
            }, err => {
                //
            });
        }
        else {
            this.setState({
                teachersList: [],
                selectedProjectToShare: null,
                modalShareTeacher: !this.state.modalShareTeacher
            });
        }
    }

    toggleShareWithCertifier = (projectUUID) => {
        if (!this.state.modalShareCertifier) {
            axios.get(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/users?role_code=RBAC-RLCD-N0000004",
            {
                auth: holdDataService.getAuthorization()
            })
            .then(res => {
                let users = [];
                if (res.data.rowCount > 0) {
                    users = res.data.rows
                }
                this.setState({
                    evaluatorsList: users,
                    selectedProjectToEvaluate: projectUUID,
                    modalShareCertifier: !this.state.modalShareCertifier
                });
            }, err => {
                this.setState({
                    evaluatorsList: [],
                    selectedProjectToEvaluate: null,
                    modalShareCertifier: !this.state.modalShareCertifier
                });
            });
        }
        else {
            this.setState({
                evaluatorsList: [],
                selectedProjectToEvaluate: null,
                modalShareCertifier: !this.state.modalShareCertifier
            });
        }
    }

    toggleDeleteProjectUUID = (projectUUID) => {
        if (!this.state.modalDeleteProject) {
            this.setState({
                selectedProjectToDelete: projectUUID,
                modalDeleteProject: !this.state.modalDeleteProject
            });
        }
        else {
            this.setState({
                evaluatorsList: [],
                selectedProjectToDelete: null,
                modalDeleteProject: !this.state.modalDeleteProject
            });
        }
    }

    closeModalConfirmToast = () => {
        this.setState({
            modalConfirmToast: false,
            modalConfirmToastTitle: "...",
            modalConfirmToastMessage: "..."
        });
    }

    modalEvaluatorAssignChange = (evt) => {
        this.setState({
            selectedEvaluatorModal: evt.target.value
        });
    }

    assignCertifierToProject = (evt) => {
        evt.preventDefault();
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort +
        "/projects/" + this.state.selectedProjectToEvaluate + "/assign/evaluator/" + this.state.selectedEvaluatorModal,
        {},
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.toggleShareWithCertifier(null);

            if (res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Assegnazione del Certificatore",
                    modalConfirmToastMessage: "Contenuti del progetto assegnati al Certificatore selezionato con successo."
                });
            }
            else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Errore nell'Assegnazione!",
                    modalConfirmToastMessage: "Impossibile completare l'assegnazione dei contenuti con il Certificatore o contenuto già assegnato al Certificatore selezionato."
                });
            }
        },
        err => {
            this.toggleShareWithCertifier(null);
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Errore nell'Assegnazione!",
                modalConfirmToastMessage: "Impossibile completare l'assegnazione dei contenuti con il Certificatore. Errore: " + err.message
            });
        });
    }

    modalTeacherShareChange = (evt) => {
        this.setState({
            selectedTeacherModal: evt.target.value
        });
    }

    shareProjectToTeacher = (evt) => {
        evt.preventDefault();
        axios.post(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort +
        "/projects/" + this.state.selectedProjectToShare + "/share/teacher/" + this.state.selectedTeacherModal,
        {},
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.toggleShareWithTeacher(null);

            if (res.data.rowCount > 0) {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Condivisione del Progetto",
                    modalConfirmToastMessage: "Condivisione del progetto con il Tutor selezionato avvenuta con successo."
                });
            }
            else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Errore nella Condivisione!",
                    modalConfirmToastMessage: "Impossibile completare la condivisione del progetto con il Tutor o progetto già condiviso con il Tutor selezionato."
                });
            }
        }, err => {
            this.toggleShareWithTeacher(null);
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Errore nella Condivisione!",
                modalConfirmToastMessage: "Impossibile completare la condivisione del progetto con il Tutor. Errore: " + err.message
            });
        });
    }

    deleteProjectUUID = (evt) => {
        evt.preventDefault();

        let currentProjectUUID = this.state.selectedProjectToDelete;
        axios.delete(globalVars.Protocol + "://" + globalVars.BEHost + ":" + globalVars.BEPort + "/projects/" + currentProjectUUID,
        {
            auth: holdDataService.getAuthorization()
        })
        .then(res => {
            this.toggleDeleteProjectUUID(null);

            if (res.data.rowCount > 0) {
                let tmpArray = this.state.projects.map((item) => {
                    if (item.project_uuid === currentProjectUUID) {
                        return null;
                    }
                    else return item;
                });

                this.setState({
                    projects: tmpArray.filter(x => x),
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Rimozione del Progetto",
                    modalConfirmToastMessage: "Contenuti associati e progetto rimossi con successo."
                });
            }
            else {
                this.setState({
                    modalConfirmToast: true,
                    modalConfirmToastTitle: "Errore nella Rimozione!",
                    modalConfirmToastMessage: "Impossibile completare la rimozione dei contenuti associati e del progetto selezionato."
                });
            }
        },
        err => {
            this.toggleDeleteProjectUUID(null);
            this.setState({
                modalConfirmToast: true,
                modalConfirmToastTitle: "Errore nella Rimozione!",
                modalConfirmToastMessage: "Impossibile completare la rimozione del progetto selezionato. Errore: " + err.message
            });
        });
    }

    render() {
        return (
            <Fragment>
                <Card className="MyProjects main-card mb-3">
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon pe-7s-note2"> </i>
                            I MIEI PROGETTI ({this.state.projects.length})
                        </div>
                    </CardHeader>
                    <PerfectScrollbar>
                        <div className="p-4" style={{ scrollBehavior: 'smooth' }}>
                            <ListGroup className="todo-list-wrapper">
                                {this.state.loading ?
                                    <EdubbaLoader small={true} />
                                    : this.getProjectsHTML()}
                            </ListGroup>
                        </div>
                    </PerfectScrollbar>
                </Card>
                {/* v SHARE WITH TEACHER: modalShareTeacher v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalShareTeacher}
                    toggle={this.toggleShareWithTeacher}
                // backdrop='static'
                >
                    <ModalHeader toggle={this.toggleShareWithTeacher}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Condividi Schede con un Tutor
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            <FormGroup>
                                <Label for="exampleSelect">Selezionare un Docente:</Label>
                                <select name="select-teacher-share" className="legacy-form-control"
                                value={this.state.selectedTeacherModal}
                                onChange={this.modalTeacherShareChange}>
                                <option key="default-value" value="" disabled={true}>Seleziona docente...</option>
                                {
                                    this.state.teachersList.map((user) => {
                                        if (user.user_uuid === holdDataService.getLoggedUserUuid()) {
                                            return null;
                                        }
                                        else return <option key={user.user_uuid} value={user.user_uuid}>
                                            {user.user_profile.firstName + " " + user.user_profile.lastName}
                                        </option>;
                                    }).filter(x => x)
                                }
                                </select>
                            </FormGroup>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.toggleShareWithTeacher}>Annulla</Button>
                        <Button
                            className='btn-edubba'
                            onClick={this.shareProjectToTeacher}
                            disabled={this.state.selectedTeacherModal === ""}
                        >
                            Ok
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* ^ SHARE WITH TEACHER: modalShareTeacher ^ */}
                {/* v SHARE WITH CERTIFIER: modalShareCertifier v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalShareCertifier}
                    toggle={this.toggleShareWithCertifier}
                // backdrop='static'
                >
                    <ModalHeader toggle={this.toggleShareWithCertifier}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Assegna a un Certificatore
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            <FormGroup>
                                <Label for="exampleSelect">Selezionare un Certificatore:</Label>
                                <select name="select-certifier-project" className="legacy-form-control"
                                value={this.state.selectedEvaluatorModal}
                                onChange={this.modalEvaluatorAssignChange}>
                                <option key="default-value" value="" disabled={true}>Seleziona certificatore...</option>
                                {
                                    this.state.evaluatorsList.map((user) => {
                                        return <option key={user.user_uuid} value={user.user_uuid}>
                                            {user.user_profile.firstName + " " + user.user_profile.lastName}
                                        </option>;
                                    })
                                }
                                </select>
                            </FormGroup>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.toggleShareWithCertifier}>Annulla</Button>
                        <Button
                            className='btn-edubba'
                            onClick={this.assignCertifierToProject}
                            disabled={this.state.selectedEvaluatorModal === ""}
                        >
                            Ok
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* ^ SHARE WITH CERTIFIER: modalShareCertifier ^ */}
                {/* v DELETE PROJECT: modalDeleteProject v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalDeleteProject}
                    toggle={this.toggleDeleteProjectUUID}
                // backdrop='static'
                >
                    <ModalHeader toggle={this.toggleDeleteProjectUUID}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    Rimozione di un Progetto
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            Vuoi davvero rimuovere il progetto selezionato e i contenuti ad esso associati?
                            L'operazione non sarà reversibile una volta completata!
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.toggleDeleteProjectUUID}>Annulla</Button>
                        <Button
                            className='btn-edubba'
                            onClick={this.deleteProjectUUID}
                            disabled={this.state.selectedProjectToDelete === ""}
                        >
                            Rimuovi
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* ^ DELETE PROJECT: modalDeleteProject ^ */}
                {/* v CONFIRM: modalConfirmToast v */}
                <Modal className='edubba-modal'
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    size='md'
                    isOpen={this.state.modalConfirmToast}
                    toggle={this.closeModalConfirmToast}>
                    <ModalHeader toggle={this.closeModalConfirmToast}>
                        <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                                <div className='widget-content-left mr-3 text-center w-100'>
                                    {this.state.modalConfirmToastTitle}
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Container className='help-text'>
                            {this.state.modalConfirmToastMessage}
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-edubba' onClick={this.closeModalConfirmToast}>Chiudi</Button>
                    </ModalFooter>
                </Modal>
                {/* ^ CONFIRM: modalConfirmToast ^ */}
            </Fragment>
        )
    }

}

export default withRouter(MyProject);
